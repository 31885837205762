import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import AuthService from './services/AuthService';
import { initInterceptors } from './services/ApiService';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  debug: process.env.NODE_ENV === 'development',
  environment: process.env.REACT_APP_SENTRY_ENV || process.env.NODE_ENV,
});

const auth = new AuthService();
auth.Ready.then(() => {
  initInterceptors(auth);

  ReactDOM.render(
    <Router basename={process.env.REACT_APP_ROUTER_BASENAME}>
      <App auth={auth} />
    </Router>, document.getElementById('root'));

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: http://bit.ly/CRA-PWA
  serviceWorker.unregister();
});
