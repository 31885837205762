import React from 'react';
import { Typography, Link, Button } from '@material-ui/core';
import { RouteComponentProps, StaticContext } from 'react-router';
import Auth from '../services/AuthService';
import API from '../services/ApiService';
import { GEMINI_SUPPORT_EMAIL } from '../helpers';
import { showSuccessResultBar } from '../components/ResultSnackbar';
import PageActions from '../components/PageActions';

export interface TheLoginPageProps extends RouteComponentProps<{}, StaticContext, { from?: { pathname?: string } }> {
  auth: Auth;
}

function TheLoginPage({ auth, location }: TheLoginPageProps) {
  async function handleUpgradeClick() {
    await API.post('org/upgrade', {},
    );

    showSuccessResultBar('Your upgrade request has been recorded. We will contact you soon!');
  }

  switch (location.hash) {
    case '#failed':
      return (
        <Typography variant="h5">
          Please log in again.
        </Typography>
      );

    case '#disabledAccount':
      return (
        <Typography variant="h5">
          Your administrator has disabled your account. If this was a mistake, please <Link href={`mailto:${GEMINI_SUPPORT_EMAIL}`}>contact us</Link> or your administrator.
        </Typography>
      );

    case '#autoEnrollFailed':
      return (
        <Typography variant="h5">
          Your organization does not permit auto enrollment. Please contact your
          designated administrator to obtain access.
        </Typography>
      );

    case '#enrollmentLimitReached':
      return (
        <>
          <Typography variant="h5">
            Your organization has reached the maximum signups for its subscription. Please
            contact your administrator to disable other users or upgrade your subscription to add more user seats.
          </Typography>
          <PageActions center>
            <Button variant="contained" color="primary" onClick={handleUpgradeClick}>
              Request an Upgrade
            </Button>
            <Button variant="contained" color="primary" href={`mailto:${GEMINI_SUPPORT_EMAIL}`}>
              Contact Us
            </Button>
          </PageActions>
        </>
      );

    default:
      const fromUrl = location?.state?.from?.pathname;

      if (fromUrl) {
        auth.login(fromUrl);
      } else {
        auth.login();
      }

      return null;
  }
}

export default TheLoginPage;
