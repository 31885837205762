import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@material-ui/core';
import red from '@material-ui/core/colors/red';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { IPolicyDocDto } from '../../../backend/src/policy-doc/interfaces';
import DownloadButton from './buttons/DownloadButton';
import { geminiPurple } from '../theme';

const useStyles = makeStyles({
  cardContent: {
    paddingTop: 0,
  },
  cardHeaderAction: {
    margin: 0,
  },
  cardRoot: {
    margin: '0 0 1rem 0',
  },
  iconButton: {
    marginRight: 0,
  },
  iconGreen: {
    color: geminiPurple[500],
  },
  iconRed: {
    color: red[400],
  },
});

export interface PolicyListCardProps {
  onAcknowledgePolicies?: (policies: IPolicyDocDto[]) => void;
  onDownloadPolicy?: (policy: IPolicyDocDto) => void;
  onOpenPolicyDoc?: (policy: IPolicyDocDto) => Promise<void>;
  policies: IPolicyDocDto[];
  subtitle?: string;
  title: string;
}

export default function PolicyListCard({ onAcknowledgePolicies, onDownloadPolicy, onOpenPolicyDoc, policies, subtitle, title }: PolicyListCardProps) {
  const classes = useStyles();
  const [checked, setChecked] = useState<number[]>([]);

  useEffect(() => {
    setChecked([]);
  }, [policies]);

  const handleCheckPolicy = (idx: number) => () => {
    if (checked.includes(idx)) {
      setChecked(checked.filter(i => i !== idx));
    } else {
      setChecked(checked.concat([idx]));
    }
  };

  const handleClickAcknowledgePolicies = () => {
    if (onAcknowledgePolicies) {
      onAcknowledgePolicies(checked.map(idx => policies[idx]));
    }
  };

  const handleClickDownloadPolicy = (policy: IPolicyDocDto) => (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    if (onDownloadPolicy) {
      onDownloadPolicy(policy);
    }
  };

  const handleClickOpenPolicyDoc = (policy: IPolicyDocDto) => (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    if (onOpenPolicyDoc) {
      onOpenPolicyDoc(policy);
    }
  };

  return (
    <Card className={classes.cardRoot}>
      <CardHeader
        classes={{ action: classes.cardHeaderAction }}
        action={onAcknowledgePolicies && (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleClickAcknowledgePolicies}
            disabled={checked.length === 0}
          >
            Acknowledge
          </Button>
        )}
        subheader={subtitle}
        title={<Typography variant="h6">{title}</Typography>}
      />
      <CardContent className={classes.cardContent}>
        <List>
          {policies.map((policy, idx) => (
            <ListItem
              key={policy.id}
              button={!!onAcknowledgePolicies as any}
              onClick={onAcknowledgePolicies && handleCheckPolicy(idx)}
            >
              {policy.userAcknowledgedAt ? (
                <Tooltip
                  title={`You acknowledged this policy ${moment(policy.userAcknowledgedAt).fromNow()}`}
                >
                  <ListItemIcon>
                    <CheckCircleIcon
                      color="primary"
                      classes={{ colorPrimary: classes.iconGreen }}
                      fontSize="large"
                    />
                  </ListItemIcon>
                </Tooltip>
              ) : (
                <ListItemIcon>
                  <Checkbox
                    checked={checked.includes(idx)}
                  />
                </ListItemIcon>
              )}
              <ListItemText
                primary={policy.name}
                secondary={policy.description}
              />
              <ListItemIcon
                className={classes.iconButton}
              >
                <Tooltip
                  title={!policy.customPolicy ?
                    'View this policy in your browser' :
                    'In-browser viewing is not enabled for this policy'
                  }
                >
                  <div>
                    <IconButton
                      disabled={!!policy.customPolicy}
                      onClick={handleClickOpenPolicyDoc(policy)}
                    >
                      <DescriptionOutlinedIcon />
                    </IconButton>
                  </div>
                </Tooltip>
              </ListItemIcon>
              <ListItemIcon>
                <Tooltip title="Download this policy">
                  <div>
                    <DownloadButton
                      onClick={handleClickDownloadPolicy(policy)}
                    />
                  </div>
                </Tooltip>
              </ListItemIcon>
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
}
