import { CardHeader, withStyles } from '@material-ui/core';

export const LinedCardHeader = withStyles({
  root: {
    padding: '0.5rem',
    borderBottom: '1px solid lightgray',
    margin: '0.5rem',
  },

  action: {
    margin: '1rem 0.5rem 0 0',
  },

  title: {
    fontSize: '2em',
  },

  subheader: {
    fontSize: '1.25em',
  },

}, {
  name: 'LinedCardHeader',
})(CardHeader);
