import { TableCell, TableRow } from '@material-ui/core';
import ListAlt from '@material-ui/icons/ListAlt';
import * as Sentry from '@sentry/browser';
import React, { useEffect, useState } from 'react';
import { IInboundQuestionnaireDto } from '../../../backend/src/inbound-questionnaire/interfaces';
import API from '../services/ApiService';
import Auth from '../services/AuthService';
import DataTableTitleWithButton from '../components/DataTableTitleWithButton';
import { QuestionnaireEditDialog } from '../components/dialogs';
import { showErrorResultBar } from '../components/ResultSnackbar';
import SpioDataTable, {
  SpioDataTableColumn,
} from '../components/SpioDataTable';
import QuestionnaireDetails, {
  questionnaireStatusMap,
} from '../components/QuestionnaireDetails';
import { formatDate, truncateString } from '../helpers';

export const UNASSIGNED_DATA_STR = '(blank)';

const getTableColumns = (tableData: ITableDatum[]): SpioDataTableColumn[] => [
  {
    name: 'id',
    label: 'ID',
    options: {
      display: 'false',
      filter: false,
    },
  },
  {
    name: 'name',
    label: 'Survey Name',
    options: {
      customFilterListOptions: { render: (v) => `Name: ${v}` },
    },
  },
  {
    name: 'statusStr',
    label: 'Status',
    options: {
      customFilterListOptions: { render: (v) => `Status: ${v}` },
    },
  },
  {
    name: 'statusUpdatedAtStr',
    label: 'Last Status Update',
    options: {
      customFilterListOptions: { render: (v) => `Last Status Update: ${v}` },
    },
  },
  {
    name: 'dueDateStr',
    label: 'Due Date',
    options: {
      customFilterListOptions: {
        render: (v) => `Due Date: ${v || UNASSIGNED_DATA_STR}`,
      },
    },
  },
  {
    name: 'requestorEmail',
    label: 'Requestor Email',
    options: {
      customFilterListOptions: {
        render: (v) => `Requestor Email: ${v || UNASSIGNED_DATA_STR}`,
      },
      display: 'false',
    },
  },
  {
    name: 'requestorName',
    label: 'Requestor Name',
    options: {
      customFilterListOptions: {
        render: (v) => `Requestor Name: ${v || UNASSIGNED_DATA_STR}`,
      },
    },
  },
  {
    name: 'notes',
    label: 'Notes',
    options: {
      customBodyRenderLite: (dataIndex) =>
        truncateString(tableData[dataIndex]?.notes || ''),
      display: 'false',
      filter: false,
    },
  },
];

interface ITableDatum extends IInboundQuestionnaireDto {
  dueDateStr: string;
  statusStr: string;
  statusUpdatedAtStr: string;
}

function TheInboundQuestionnairePage({ auth }: { auth: Auth }) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState<ITableDatum[]>([]);
  const [questionnaires, setQuestionnaires] = useState<IInboundQuestionnaireDto[]>([]);
  const [questionnaireLimit, setQuestionnaireLimit] = useState(0);

  useEffect(() => {
    setIsLoading(true);

    API.get('questionnaire')
      .then((res) => setQuestionnaires(res.data?.data ?? []))
      .catch((err) => {
        showErrorResultBar('Unexpected error loading questionnaires');
        Sentry.captureException(err);
      })
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    setTableData(
      questionnaires.map((d) =>
        Object({
          ...d,
          dueDateStr: formatDate(d.dueDate),
          statusStr:
            (d.status && questionnaireStatusMap[d.status]) || 'Unknown',
          statusUpdatedAtStr: formatDate(d.statusUpdatedAt),
        })
      )
    );
  }, [questionnaires]);

  useEffect(() => {
    setQuestionnaireLimit(auth.getInboundQuestionnaireLimit());
  }, [auth]);

  const questionnaireLimitReached =
    !isLoading && questionnaires.length < questionnaireLimit ? false : true;

  const handleArchive = (selectedIdx: number) => () => {
    const updatedData = questionnaires.slice();
    updatedData.splice(selectedIdx, 1);
    setQuestionnaires(updatedData);
  };

  const handleCreate = (newQuestionnaire: IInboundQuestionnaireDto) => {
    const updatedData = [newQuestionnaire].concat(questionnaires);
    setQuestionnaires(updatedData);
  };

  const handleUpdate =
    (selectedIdx: number) => (updatedQuestionnaire: IInboundQuestionnaireDto) => {
      const updatedData = questionnaires.slice();
      updatedData[selectedIdx] = updatedQuestionnaire;
      setQuestionnaires(updatedData);
    };

  return (
    <>
      <SpioDataTable
        title={
          <DataTableTitleWithButton
            onButtonClick={() => setIsDialogOpen(true)}
            title="Inbound Questionnaires"
            limitReached={questionnaireLimitReached}
            disabled={!auth.isGranted({permission:'inbound_questionnaire:edit'})}
          />
        }
        columns={getTableColumns(tableData)}
        data={tableData}
        options={{
          print: false,
          filterType: 'multiselect',
          selectableRows: 'none',
          textLabels: {
            body: {
              noMatch: isLoading ? 'Loading...' : 'No records found',
              toolTip: 'Sort',
            },
          },
          expandableRows: true,
          expandableRowsOnClick: true,
          renderExpandableRow: (rowData, rowMeta) => {
            const colSpan = rowData.length + 1;
            const myData = questionnaires[rowMeta.dataIndex];

            return myData ? (
              <TableRow>
                <TableCell colSpan={colSpan}>
                  <QuestionnaireDetails
                    auth={auth}
                    onArchive={handleArchive(rowMeta.dataIndex)}
                    onUpdate={handleUpdate(rowMeta.dataIndex)}
                    questionnaireData={myData}
                  />
                </TableCell>
              </TableRow>
            ) : null;
          },
        }}
      />
      <QuestionnaireEditDialog
        auth={auth}
        onClose={() => setIsDialogOpen(false)}
        onSave={handleCreate}
        open={isDialogOpen}
        questionnaireData={null}
      />
    </>
  );
}

TheInboundQuestionnairePage.icon = <ListAlt />;
TheInboundQuestionnairePage.requiredAuthZ = {
  tier: 3,
  permission: 'inbound_questionnaire',
};
TheInboundQuestionnairePage.routePath = '/inbound-questionnaires';
TheInboundQuestionnairePage.title = 'Inbound Questionnaires';

export default TheInboundQuestionnairePage;
