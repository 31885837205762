import {
    Card,
    CardHeader,
    createStyles,
    Grid,
    Tab,
    TabProps,
    Tabs,
    Theme,
    withStyles,
  } from '@material-ui/core';
  import { makeStyles } from '@material-ui/styles';
  import React, { useEffect, useState } from 'react';
  import PieChartDownload from './PieChartDownload';
  import { ChartOptions } from 'chart.js';
  import { blueGrey } from '@material-ui/core/colors';
  import { truncateString } from '../../helpers';
  import { IMaterialityAssessmentResponseCountDto } from '../../../../backend/src/material-issue/interfaces/material-assessment-response-count-dto.interface';

  const useStyles = makeStyles({
    chartContainer: {
      paddingTop: '2rem',
      paddingBottom: '2rem',
      //minHeight: '360px',
    },
    tabsContainer: {
      marginTop: '-10px',
    },
  });

  const StyledTab = withStyles((theme: Theme) => createStyles({
    root: {
      fontSize: '1rem',
      fontWeight: theme.typography.fontWeightRegular,
      letterSpacing: '0.0075em',
      paddingTop: '0',
      textTransform: 'none',
      minWidth: 200,
      width: 200,
    },
    selected: {
      fontWeight: theme.typography.fontWeightMedium,
    },
  }))((props: TabProps) => <Tab {...props} />);

  export interface MaterialityAssessmentResponseNumberChartProps {
    selectedQuestionnaire: string;
    responseCounts: IMaterialityAssessmentResponseCountDto;
    filterOptions: {[key: string]: string}[] | [];
    chartColors: string[];
  }

  function MaterialityAssessmentResponseNumberChart({
    responseCounts,
    selectedQuestionnaire,
    filterOptions,
    chartColors
  }: MaterialityAssessmentResponseNumberChartProps) {
    const classes = useStyles();

    const doughnutChartOptions: ChartOptions = {
      legend: {
        position: 'right',
      },
      tooltips: {
        callbacks: {
          // TODO: fix type
          label: function(tooltipItem: any, data: any) {
            const dataset = data.datasets[tooltipItem.datasetIndex];
            const currentValue = parseFloat(dataset.data[tooltipItem.index]);
            let total = 0;
            for (let i = 0; i < dataset.data.length; i++) {
              total += parseFloat(dataset.data[i]);
            }
            const percentage = (currentValue / total * 100).toFixed(1);
            return `${currentValue} (${percentage}%)`;
          },
        }
      },
    };

    const [ selectedTab, setSelectedTab ] = useState(0);
    const [ selectedFilter, setSelectedFilter ] = useState<string>('');
    const [ filterSelections, setFilterSelections ] = useState<string[]>([]);
    const [ chartData, setChartData ] = useState<any>({});

    useEffect(() => {

      setChartData({
        labels: responseCounts?.filters[selectedFilter] ? Object.keys(responseCounts?.filters[selectedFilter]).filter((key: string) => responseCounts?.filters[selectedFilter][key] !== 0).map(filterName => truncateString(`${filterName} (${responseCounts?.filters[selectedFilter][filterName]})`)) : [],
        datasets: [{
          data: responseCounts?.filters[selectedFilter] ? Object.values(responseCounts?.filters[selectedFilter]).filter((value) => value !== 0) : [],
          backgroundColor: chartColors.slice(1,),
          hoverBackgroundColor: blueGrey[100],
        }],
      });
    }, [responseCounts, selectedFilter]);

    useEffect(() => {
      setFilterSelections(filterOptions.map(f => f.filter).filter((v, i, a) => a.indexOf(v) === i));
    }, [filterOptions]);

    useEffect(() => {
      setSelectedTab(0);
    }, [selectedQuestionnaire]);

    useEffect(() => {
      setSelectedFilter(filterSelections[selectedTab]);
    }, [selectedTab, filterSelections]);

    return (
        <Card>
            <CardHeader
            title= {`Total Number of Responses (${responseCounts?.all})`}
            className={classes.tabsContainer}
            titleTypographyProps={{variant:'h6' }}
            />
            <Tabs
                className={classes.tabsContainer}
                variant="scrollable"
                value={selectedTab}
                onChange={(_, value) => setSelectedTab(value)}
                scrollButtons="on"
            >
              {filterSelections.map(filters =>
                <StyledTab label={filters} wrapped />
              )}
            </Tabs>
            <Grid container className={classes.chartContainer}>
              <PieChartDownload data={chartData} options={doughnutChartOptions} />
            </Grid>
        </Card>
    );
  }

export default MaterialityAssessmentResponseNumberChart;
