import axios from 'axios';
import * as Sentry from '@sentry/browser';

export const API_ROOT = `${process.env.REACT_APP_API_ORIGIN || ''}${process.env.REACT_APP_API_ROOT || '/api/v1'}`;

const API = axios.create({
  baseURL: API_ROOT,
  // The auth is added via the interceptor (below) called in index.js.
  headers: {
    'Content-Type': 'application/json',
  },
});


function initInterceptors(authService) {
  API.interceptors.request.use(
    (config) => {
      const token = authService.getAccessToken();

      if (token !== null) {
        config.headers.Authorization = `Bearer ${token}`; // eslint-disable-line no-param-reassign
      }

      return config;
    },
    err => Promise.reject(err)
  );

  API.interceptors.response.use(res => res, err => {
    Sentry.captureException(err);

    return Promise.reject(err);
    }
  );
}

export { API as default, initInterceptors };

export const ErrorCodes = {
  /**
   * One or more values in the request already exist.
   */
  DUPLICATE_VALUE: 23505,
};
