import {
    Button,
    DialogActions,
    DialogContent,
    Grid,
    } from '@material-ui/core';
  import { FormikTextField } from '../forms';
  import React from 'react';
  import { DialogProps } from '@material-ui/core/Dialog';
  import ResponsiveDialog from './ResponsiveDialog';
  import StyledDialogTitle from '../StyledDialogTitle';
  import { Formik } from 'formik';
  import SaveButton from '../buttons/SaveButton';
  import * as Yup from 'yup';
  import {
    ITaskDto,
    ICreateTaskLinkDto,
  } from '../../../../backend/src/task/interfaces';

  export interface AddTaskDialogProps extends DialogProps {
    onSave: (newTaskLink: ICreateTaskLinkDto) => Promise<void> | void;
    onClose: () => void;
    open: boolean;
    taskData: ITaskDto;
  }

  function AttachTaskLinkDialog({
    children,
    onSave,
    onClose,
    open,
    taskData,
    ...dialogProps
  }: AddTaskDialogProps) {

    const linkSchema = Yup.object().shape({
      name: Yup.string().max(100, 'Maximum of 100 characters').required('Required'),
      url: Yup.string().url().required('Required'),
    });

    return (
      <>
      <ResponsiveDialog
        disableBackdropClick
        fullWidth
        maxWidth="md"
        open={open}
        onClose={onClose}
      >
        <StyledDialogTitle onClose={onClose}>
          {'Add Link to Task'}
        </StyledDialogTitle>
        <Formik
          initialValues={{
            url: '',
            name: ''
          }}
          validationSchema={linkSchema}
          onSubmit={async (values, { setSubmitting }) => {
            await onSave(values);
            setSubmitting(false);
            onClose();
          }}
        >
          {formikProps => (
            <>
              <DialogContent>
                <Grid container>
                    <FormikTextField
                        autoFocus
                        field="name"
                        formikProps={formikProps}
                        helperTextStr={'Name of link'}
                        label="Name"
                        required
                    />
                    <FormikTextField
                        autoFocus
                        field="url"
                        formikProps={formikProps}
                        helperTextStr={'Link (URL)'}
                        label="URL"
                        required
                    />
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  disabled={formikProps.isSubmitting}
                  onClick={onClose}
                  color="primary"
                >
                  {'Cancel'}
                </Button>
                <SaveButton
                  disabled={formikProps.isSubmitting}
                  onClick={e => {
                    formikProps.handleSubmit(e);
                  }}
                />
              </DialogActions>
            </>
          )}
        </Formik>
      </ResponsiveDialog>
    </>
    );
  }

  export default AttachTaskLinkDialog;
