
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Collapse,
  createStyles,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core';
import PageActions from '../components/PageActions';
import { showSuccessResultBar } from '../components/ResultSnackbar';
import CheckIcon from '@material-ui/icons/Check';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { TIER_LABELS } from '../services/AuthService';
import Auth from '../services/AuthService';
import API from '../services/ApiService';
import * as Sentry from '@sentry/browser';
import { showErrorResultBar } from '../components/ResultSnackbar';
import { ISubscriptionDto } from '../../../backend/src/subscription/interfaces';
import { geminiPurple } from '../theme';

const useStyles = makeStyles((theme: Theme) => createStyles({
  welcomeText: {
    textAlign: 'center',
  },
  featureList: {
    textAlign: 'left',
    display: 'flex',
  },
  tableHeader: {
    fontSize: '1.15em',
  },
  tableSubHeader: {
    fontSize: '.90em',
    color: 'white',
  },
  tableInfoText: {
    fontSize: '.90em',
    color: theme.palette.secondary.contrastText,
  },
  checkIcon: {
    color: theme.palette.secondary.contrastText,
  },
  tableContainer: {
    marginTop: '3em'
  },
  helpIcon: {
    'fontSize': '15px',
    'marginBottom': '-1px',
    'marginLeft': '10px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  styledCell: {
    backgroundColor: theme.palette.grey[100],
  },
  selectedHeaderCell: {
    backgroundColor: theme.palette.primary.main,
    color: 'white'
  },
  selectedCell: {
    backgroundColor: geminiPurple[100]
  }
}));

export interface IPlanFeatures {
  type: string;
  feature: string;
  description?: string;
  FREE_TIER?: boolean | string;
  STANDARD_TIER?: boolean | string;
  ENTERPRISE_TIER?: boolean | string;
}

export const planFeatures = [
  {
    type: 'string',
    feature: 'Support',
    //description: 'Step-by-step guidance on the process and key tasks for each phase - Assign tasks and monitor progress across your enterprise',
    FREE_TIER: '5 Business Days',
    STANDARD_TIER: '9-5 Chat',
    ENTERPRISE_TIER: 'Dedicated Team',
  },
  {
    type: 'string',
    feature: 'Users',
    description: 'Number of users that can be linked to your organization. Additional seats can be purchased at a monthly fee.',
    FREE_TIER: 'Single Account',
    STANDARD_TIER: 'Up to 25',
    ENTERPRISE_TIER: 'Unlimited',
  },
  {
    type: 'string',
    feature: 'ESG Implementation Resources',
    description: 'Preloaded documents, presentations, and templates for key steps in implementing ESG programs at your organization.',
    FREE_TIER: 'Educational Resources',
    STANDARD_TIER: 'Educational Resources & Templates',
    ENTERPRISE_TIER: 'Educational Resources & Templates',
  },
  {
    type: 'string',
    feature: 'Employee Training Modules',
    description: 'E-Learning modules on ESG 101, ESG Fundamentals, and training videos on how to use the Gemini platform',
    FREE_TIER: 'View',
    STANDARD_TIER: 'Track',
    ENTERPRISE_TIER: 'Track',
  },
  {
    type: 'string',
    feature: 'Core ESG Policies',
    description: 'Preloaded templates and step-by-step guidance in developing core ESG policies - Use the templates, upload your own policies, and assign and track policy training',
    FREE_TIER: 'View',
    STANDARD_TIER: 'View, Edit & Track',
    ENTERPRISE_TIER: 'View, Edit & Track',
  },
  {
    type: 'string',
    feature: 'Data Storage',
    description: 'Store all your critical ESG documents in one place - templates to guide and streamline critical processes and tasks',
    FREE_TIER: '10 GB',
    STANDARD_TIER: '100 GB',
    ENTERPRISE_TIER: '1,000 GB',
  },
  {
    type: 'boolean',
    feature: 'Impact Evidence Management',
    description: 'A digital binder to house all your ESG materials in one accessible location. Provides a central repository for managing, inventorying and tracking evidence needed for baselining',
    FREE_TIER: true,
    STANDARD_TIER: true,
    ENTERPRISE_TIER: true,
  },
  {
    type: 'boolean',
    feature: 'Dashboard Reporting',
    description: 'A visualized summary of your ESG implementation process - including progress achieved and outstanding tasks for both your company and internal working groups',
    FREE_TIER: true,
    STANDARD_TIER: true,
    ENTERPRISE_TIER: true,
  },
  {
    type: 'boolean',
    feature: 'Assignable ESG Program Tasks',
    description: 'Step-by-step guidance on the process and key tasks for each phase - Assign tasks and monitor progress across your enterprise',
    FREE_TIER: true,
    STANDARD_TIER: true,
    ENTERPRISE_TIER: true,
  },
  {
    type: 'boolean',
    feature: 'Stakeholder Surveys',
    description: 'Automated surveying and data analysis for engaging, building consensus with, and aligning efforts with your stakeholders',
    FREE_TIER: false,
    STANDARD_TIER: true,
    ENTERPRISE_TIER: true,
  },
  {
    type: 'boolean',
    feature: 'Inbound Request Management',
    description: 'Store and organize all in-bound inquiries, requests, and RFPs',
    FREE_TIER: false,
    STANDARD_TIER: true,
    ENTERPRISE_TIER: true,
  },
  {
    type: 'boolean',
    feature: 'Material Risk & Opportunity Register',
    description: 'Identify, organize and outline all your material issues so you can keep track of the risks and opportunities impacting the business, as well as your action plans to address them',
    FREE_TIER: false,
    STANDARD_TIER: true,
    ENTERPRISE_TIER: true,
  },
  {
    type: 'boolean',
    feature: 'Metrics Tracking',
    description: 'Track all organizational data that is required for assessing your ESG performance, including utility data, energy consumption, and associated Carbon Emissions',
    FREE_TIER: false,
    STANDARD_TIER: true,
    ENTERPRISE_TIER: true,
  },
  {
    type: 'boolean',
    feature: 'Custom Task Management',
    description: "Build and track custom tasks specific to your organization's process",
    FREE_TIER: false,
    STANDARD_TIER: true,
    ENTERPRISE_TIER: true,
  },
  {
    type: 'boolean',
    feature: 'Detailed Reporting',
    description: 'In-application tracking and reporting on task progress, employee training, policy acknowledgement, and evidence review status',
    FREE_TIER: false,
    STANDARD_TIER: true,
    ENTERPRISE_TIER: true,
  },
  {
    type: 'boolean',
    feature: 'Document Discovery',
    description: "Save countless hours by automatically collecting, identifying, and sorting your and your supplier's critical ESG documents, utility bills, and certifications",
    FREE_TIER: false,
    STANDARD_TIER: true,
    ENTERPRISE_TIER: true,
  },
  {
    type: 'boolean',
    feature: 'Multi-Organization Administration',
    description: "Link other organizations to your account so you can keep tabs on their ESG Progress. Useful for asset managers monitoring portfolio data, companies aggregating supplier data, and parent companies keeping an eye on their subsidiaries' ESG progress",
    FREE_TIER: false,
    STANDARD_TIER: false,
    ENTERPRISE_TIER: true,
  },
  {
    type: 'boolean',
    feature: 'Onboarding Support',
    description: 'Get hands-on support in getting your Gemini dashboard up and running',
    FREE_TIER: false,
    STANDARD_TIER: false,
    ENTERPRISE_TIER: true,
  },
  {
    type: 'boolean',
    feature: 'Account Manager',
    description: 'Single point of contact for immediate support on all your ESG questions',
    FREE_TIER: false,
    STANDARD_TIER: false,
    ENTERPRISE_TIER: true,
  },
  {
    type: 'header',
    feature: 'COMING SOON',
    description: 'Exciting features that are on the way!',
  },
  {
    type: 'boolean',
    feature: 'Metrics Reporting to Linked Accounts',
    description: 'Provide data and automatically generated reports to organizations of your choosing (e.g. your investors, companies you supply, etc.)',
    FREE_TIER: false,
    STANDARD_TIER: true,
    ENTERPRISE_TIER: true,
  },
  {
    type: 'boolean',
    feature: 'Metrics Reporting to External Sources',
    description: 'Get your data in line with top disclosure frameworks and standards such as SASB, TCFD, GRI, UNPRI, and more',
    FREE_TIER: false,
    STANDARD_TIER: true,
    ENTERPRISE_TIER: true,
  },
  {
    type: 'boolean',
    feature: 'Evidence Cleanup',
    description: 'Automatically flag duplicate and irrelevant evidence',
    FREE_TIER: false,
    STANDARD_TIER: true,
    ENTERPRISE_TIER: true,
  },
  {
    type: 'boolean',
    feature: 'Regulatory Registry',
    description: 'Curated, up-to-date summary of the existing and upcoming ESG Regulations, disclosure requirements, and relevant links',
    FREE_TIER: false,
    STANDARD_TIER: false,
    ENTERPRISE_TIER: true,
  },
  {
    type: 'boolean',
    feature: 'Regulatory Compliance Tracker',
    description: 'Efficiently coordinate compliance efforts, monitor progress, and outsource legal needs',
    FREE_TIER: false,
    STANDARD_TIER: false,
    ENTERPRISE_TIER: true,
  },
  {
    type: 'boolean',
    feature: 'Regulatory Risk Assessment',
    description: 'Identify, organize, and outline regulations for risk tracking and compliance in your ESG strategy',
    FREE_TIER: false,
    STANDARD_TIER: false,
    ENTERPRISE_TIER: true,
  },
  {
    type: 'boolean',
    feature: 'Portfolio View',
    description: 'View summary statistics and aggregated data of the portfolio of companies that are linked to your organization',
    FREE_TIER: false,
    STANDARD_TIER: false,
    ENTERPRISE_TIER: true,
  },
  {
    type: 'boolean',
    feature: 'API Access',
    description: 'Access pertinent data through our API to integrate your information into other platforms',
    FREE_TIER: false,
    STANDARD_TIER: false,
    ENTERPRISE_TIER: true,
  },
  {
    type: 'boolean',
    feature: 'SSO Support',
    description: 'Additional support for accessing your organization’s account and linked accounts with single sign-on',
    FREE_TIER: false,
    STANDARD_TIER: false,
    ENTERPRISE_TIER: true,
  },
];

export interface IUpgradesPageProps {
  auth: Auth,
}

function TheSubscriptionPage({ auth }: IUpgradesPageProps) {
  const classes = useStyles();
  const [subscription, setSubscription] = useState<ISubscriptionDto[]>([]);

  useEffect(() => {
    API.get('subscription')
      .then(res => setSubscription(res.data?.data ?? []))
      .catch((err) => {
        showErrorResultBar('Unexpected error loading subscription');
        Sentry.captureException(err);
      });
  }, []);

  function tableHeaders(header: string, tier: number) {
    return (
      <>
        {(auth.getTier() === tier) ?
          <TableCell className={classes.selectedHeaderCell} align="center">
            {header}
            <Typography className={classes.tableSubHeader}>
              (Current Plan)
            </Typography>
          </TableCell>
          :
          <TableCell className={classes.tableHeader} align="center">
            {header}
          </TableCell>
        }
      </>
    );
  };

  async function handleUpgradeClick() {
    await API.post('org/upgrade', {},
    );

    showSuccessResultBar('Your upgrade request has been recorded. We will contact you soon!');
  }

  async function handleBillingPortalClick() {
    redirectToCustomerPortal();
  }

  async function redirectToCustomerPortal() {
    try {
      const portalDetails = await API.post('subscription/customerPortal');

      window.location = portalDetails.data.data.url;
    } catch (err) {
      Sentry.captureException(err);
      showErrorResultBar('Unable to load billing portal');
    }
  }

  async function handleDowngradeClick() {
    await API.post('org/downgrade', {},
    );

    showSuccessResultBar('Your downgrade request has been recorded. We will contact you soon!');
  }

  const ExpandableBooleanRow = (row: IPlanFeatures) => {
    const [open, setOpen] = useState(false);

    return (
      <>
        <TableRow
          key={row.feature}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
            </IconButton>
          </TableCell>
          <TableCell className={classes.tableHeader} component="th" scope="row">
            {row.feature}
          </TableCell>
          <TableCell align="center" className={(auth.getTier() === TIER_LABELS.FREE_TIER) ? classes.selectedCell : undefined}>
            {row.FREE_TIER === true && (
              <CheckIcon className={classes.checkIcon} />
            )}
          </TableCell>
          <TableCell align="center" className={(auth.getTier() === TIER_LABELS.STANDARD_TIER) ? classes.selectedCell : undefined}>
            {row.STANDARD_TIER === true && (
              <CheckIcon className={classes.checkIcon} />
            )}
          </TableCell>
          <TableCell align="center" className={(auth.getTier() === TIER_LABELS.ENTERPRISE_TIER) ? classes.selectedCell : undefined}>
            {row.ENTERPRISE_TIER === true && (
              <CheckIcon className={classes.checkIcon} />
            )}
          </TableCell>
        </TableRow>
        {row.description && (
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 10 }}>
                {row.description}
              </Box>
            </Collapse>
          </TableCell>
        )}
      </>
    );
  };

  const ExpandableStringRow = (row: IPlanFeatures) => {
    const [open, setOpen] = useState(false);

    return (
      <>
        <TableRow
          key={row.feature}>
          <TableCell align="left">
            {row.description ?

              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
              </IconButton>
              :
              null
            }
          </TableCell>
          <TableCell className={classes.tableHeader} component="th" scope="row">
            {row.feature}
          </TableCell>
          <TableCell align="center" className={(auth.getTier() === TIER_LABELS.FREE_TIER) ? classes.selectedCell : undefined} >
            {row.FREE_TIER}
          </TableCell>
          <TableCell align="center" className={(auth.getTier() === TIER_LABELS.STANDARD_TIER) ? classes.selectedCell : undefined} >
            {row.STANDARD_TIER}
          </TableCell>
          <TableCell align="center" className={(auth.getTier() === TIER_LABELS.ENTERPRISE_TIER) ? classes.selectedCell : undefined} >
            {row.ENTERPRISE_TIER}
          </TableCell>
        </TableRow>
        {row.description && (
          <>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 10 }}>
                  {row.description}
                </Box>
              </Collapse>
            </TableCell>
          </>
        )}
      </>
    );
  };

  return (
    <>
      <PageActions center>
        <Typography variant="h6">
          Ready to take the next step?
        </Typography>
      </PageActions>
      <PageActions center>
        <Button variant="contained" color="primary" onClick={handleUpgradeClick}>
          Request an Upgrade
        </Button>
        <Button variant="contained" color="default" onClick={handleDowngradeClick} disabled={subscription.length === 0}>
          Request a Downgrade
        </Button>
      </PageActions>
      <PageActions center>
        <Button variant="contained" color="default" onClick={handleBillingPortalClick} disabled={(subscription.length > 0) || (!auth.isGranted({ permission: 'subscription:manage' }))}>
          Update Billing
        </Button>
      </PageActions>

      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeader}></TableCell>
              <TableCell className={classes.tableHeader}>Feature By Plan</TableCell>
              {tableHeaders('Launch', TIER_LABELS.FREE_TIER)}
              {tableHeaders('Growth', TIER_LABELS.STANDARD_TIER)}
              {tableHeaders('Enterprise', TIER_LABELS.ENTERPRISE_TIER)}
            </TableRow>
          </TableHead>
          <TableBody>
            {planFeatures.map(row => (
              (row.type === 'header') ?
                <TableRow
                  key={row.feature} className={classes.styledCell}>
                  <TableCell className={classes.tableHeader} colSpan={7} component="th" scope="row">
                    {row.feature}
                    <br />
                    <Typography className={classes.tableInfoText}>
                      {row.description}
                    </Typography>
                  </TableCell>
                </TableRow>
                :
                (row.type === 'boolean') ?
                  ExpandableBooleanRow(row)
                  :
                  ExpandableStringRow(row)
            )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

TheSubscriptionPage.title = 'Manage Subscription';
TheSubscriptionPage.routePath = '/subscription';

export default TheSubscriptionPage;
