import { createTheme } from '@material-ui/core/styles';
import defaultTheme from '@material-ui/core/styles/defaultTheme';
import { common } from '@material-ui/core/colors';

const background = '#ffffff';
const primaryDark = '#38379c';
const BODY1 = '#212b36';
const BODY2 = '#637381';

const spioTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#4B4ACF',
      dark: primaryDark,
      contrastText: common.white,
    },
    secondary: {
      main: '#000000',
      light: '#e0e0e0',
      dark: '#61849d',
      contrastText: '#38379c', // contrastText: common.black,
    },

    error: {
      main: '#e64522',
      dark: '#38379c',
    },

    background: {
      default: background,
    },

    success: {
      main: '#83e673',
    },

  },

  typography: {
    body1: { color: BODY1 },
    body2: { color: BODY2 },
    fontFamily: ['Inter', 'Segoe UI', 'Roboto', 'Ubuntu', 'Droid Sans', 'Helvetica Neue'],
  },

  props: {
    MuiGrid: {
      spacing: 1,
    },
  },

  overrides: {
    toolbar: {
      root: {
        background: primaryDark,
      },
    },

    MuiButton: {
      root: {
        marginLeft: defaultTheme.spacing(1),
      },
    },

    MuiFormControl: {
      root: {
        margin: defaultTheme.spacing(1),
        width: '100%',
      },
    },
    MuiCard: {
      root: {
        padding: defaultTheme.spacing(2),
        margin: defaultTheme.spacing(2),
      },
    },
    MuiTabs: {
      root: { color: BODY1 },
    },

    MuiDialogActions: {
      root: {
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        margin: 0,
        padding: defaultTheme.spacing(1),
      },
    },

    MuiDialogContent: {
      root: {
        margin: 0,
        padding: defaultTheme.spacing(2),
      },
    },

    MuiDrawer: {
      root: {},
      paper: {
        background: '#2a2c30',
        // background: '#e1e2e1',
      },
    },

    MUIDataTableToolbarSelect: {
      root: {
        backgroundColor: '#fff',
      },
    },

    MUIDataTableSelectCell: {
      expandDisabled: {
        visibility: 'hidden', // hides the 'expand' icon for rows whose expansion is disabled
      },
    },

  },
});

export const geminiPurple = {
  100: '#F4F4FF',
  200: '#E2E1FF',
  300: '#CBCCFF',
  400: '#ABABFF',
  500: '#8D8DFF',
  600: '#5D5DFF',
  700: '#4B4ACF',
  800: '#38379C',
  900: '#262668',
};

export default spioTheme;
