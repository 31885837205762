import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import React, { ReactElement } from 'react';
import StyledDialogTitle from '../StyledDialogTitle';

const defaultTitle = 'Are you sure?';

export interface ConfirmationDialogProps extends DialogProps {
  children?: string | ReactElement;
  onResponse: (confirmed: boolean) => void;
  title?: string;
}

function ConfirmationDialog({ children, onResponse, title, ...dialogProps }: ConfirmationDialogProps) {
  return (
    <Dialog {...dialogProps} disableEscapeKeyDown={true}>
      <StyledDialogTitle>{title || defaultTitle}</StyledDialogTitle>

      {(children) &&
      <DialogContent>
        <DialogContentText>{children}</DialogContentText>
      </DialogContent>
      }

      <DialogActions>
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={() => onResponse(true)}
        >
          <CheckIcon />
          Yes
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={() => onResponse(false)}
        >
          <CloseIcon />
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialog;
