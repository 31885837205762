import {
  Button,
  DialogActions,
  DialogContent,
  Link,
  StandardProps,
  Typography,
  InputLabel,
  MenuItem,
  FormControl,
  Select
} from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/styles';
import * as Sentry from '@sentry/browser';
import React, { useState } from 'react';
import { IStakeholderDto } from '../../../../backend/src/stakeholder/interfaces';
import API from '../../services/ApiService';
import { showErrorResultBar } from '../ResultSnackbar';
import StyledDialogTitle from '../StyledDialogTitle';
import ResponsiveDialog from './ResponsiveDialog';
import { ISendStakeholderQuestionnaireDto } from '../../../../backend/src/stakeholder/interfaces';

const useStyles = makeStyles({
  dialogContent: {
    minWidth: '400px',
  },
  selfFillLink: {
    fontSize: '0.9rem',
  },
});

export interface StakeholderSelfFillDialogProps extends StandardProps<DialogProps, 'children'> {
  handleClose: (updatedStakeholder: IStakeholderDto | undefined) => void;
  stakeholder: IStakeholderDto;
  questionnaireTemplates: Array<any>;
}

export default function StakeholderSelfFillDialog(props: StakeholderSelfFillDialogProps) {
  const { open, handleClose, stakeholder, questionnaireTemplates } = props;

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [selfFillUrl, setSelfFillUrl] = useState<string>('');
  const [updatedStakeholder, setUpdatedStakeholder] = useState<IStakeholderDto>();
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newSelection = event.target.value as string;
    setQuestionnaireType(newSelection);
  };

  const [questionnaireType, setQuestionnaireType] = React.useState(questionnaireTemplates[0].id);

  const onClickGenerateQuestionnaire = () => {
    setIsSubmitting(true);

    const stakeholderQuestionnaireUpload: ISendStakeholderQuestionnaireDto = {
      questionnaireTemplateId: questionnaireType,
      email: null,
    };

    return API
      .post(`stakeholder/${stakeholder.id}/self-fill-questionnaire`, stakeholderQuestionnaireUpload)
      .then((res) => {
        const { url, stakeholder: theUpdatedStakeholder } = res.data.data;

        setSelfFillUrl(url);
        setUpdatedStakeholder(theUpdatedStakeholder);
      })
      .catch((err) => {
        showErrorResultBar('Unexpected error generating questionnaire');
        Sentry.captureException(err);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const onCancel = () => {
    handleClose(updatedStakeholder);
  };

  const clearData = () => {
    setSelfFillUrl('');
  };

  return (
    <ResponsiveDialog
      open={open}
      onClose={onCancel}
      onExited={clearData}
      disableBackdropClick
    >
      <StyledDialogTitle
        onClose={onCancel}
      >
        Self-Fill Questionnaire
      </StyledDialogTitle>
      {!selfFillUrl ? (
        <>
          <DialogContent>
          <Typography
            variant="body1"
            gutterBottom
          >
            Larger stakeholders may not respond to questionnaires.
            For these stakeholders you may need to fill out the questionnaire yourself.
          </Typography>
          <Typography
            variant="body1"
          >
            Select the questionnaire type and click the button below to generate an external link to
            a new questionnaire for this stakeholder.
          </Typography>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Questionnaire Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={questionnaireType}
                label="Questionnaire Type"
                onChange={handleChange}
              >
            {Object.values(questionnaireTemplates).map(option => {
                      return (
                        <MenuItem key={option['id']} value={option['id']}>
                          {option['name']}
                        </MenuItem>
                      );
            })}
            </Select>
          </FormControl>
          </DialogContent>
          <DialogActions>
          <Button
            color="primary"
            size="small"
            disabled={isSubmitting}
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            color="primary"
            disabled={isSubmitting}
            onClick={onClickGenerateQuestionnaire}
          >
            Generate Questionnaire
          </Button>
          </DialogActions>
        </>
      ) : (
        <DialogContent
        className={classes.dialogContent}
      >
        <Typography
          variant="body1"
          gutterBottom
        >
          Below is the external link to a questionnaire for this stakeholder.
          You may want to retain this link, since you will not be able to retrieve it after closing this
          dialog.
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
        >
          <b>Keep this link private. Anybody with access to it will be able to fill out the questionnaire.</b>
        </Typography>
        <Typography
          variant="body1"
          className={classes.selfFillLink}
          gutterBottom
        >
          <Link
            href={selfFillUrl}
            rel="noopener noreferrer"
            target="_blank"
          >
            {selfFillUrl}
          </Link>
        </Typography>
      </DialogContent>
      )}
    </ResponsiveDialog>
  );
}
