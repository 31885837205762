import React, { useEffect, useState } from 'react';
import {
  Typography,
  Card,
  makeStyles,
  createStyles
} from '@material-ui/core';
import API from '../services/ApiService';
import Auth from '../services/AuthService';
import { IDocument, IDocumentAssigneeDto, IDocumentsResponse } from '../../../backend/src/document/interfaces';
import EvidenceDetails from '../components/EvidenceDetails';
import { showErrorResultBar } from './ResultSnackbar';
import * as Sentry from '@sentry/browser';

const useStyles = makeStyles((theme) =>
  createStyles({
    loading: {
      color: theme.typography.body1.color,
      opacity: '0.8',
    },
    card: {
      'marginTop': theme.spacing(2),
      'marginLeft': theme.spacing(1),
      'marginBottom': theme.spacing(3),
    },
  })
);

export interface EvidenceLoadingDetailsProps {
  assignees?: IDocumentAssigneeDto[];
  auth: Auth;
  isDialog?: boolean;
  onArchive: () => void;
  onCloseDialog?: () => void;
  onUpdate: (doc: IDocumentsResponse) => void;
  documentData: IDocument;
  allDocTags: string[];
  allDocNames: string[];
  documentId: string;
}

function EvidenceLoadingDetails(props: EvidenceLoadingDetailsProps) {
  const {
    assignees = [],
    isDialog = false,
    onArchive,
    onCloseDialog = () => undefined,
    onUpdate,
    allDocTags,
    auth,
    allDocNames,
    documentId,
    documentData
  } = props;

  const classes = useStyles();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [documentDataDetails, setDocumentDataDetails] = useState<IDocument | null>(null);
  const [urlLink, setUrlLink] = useState<string | null>(null);
  const [errorLoading, setErroLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    API.get(`document/evidenceDetails/${documentId}`)
        .then((res) => {
            const doc = res.data.data;
            setDocumentDataDetails(doc);
          })
          .catch((err) => {
            setErroLoading(true);
            showErrorResultBar('Error loading document details');
            Sentry.captureException(err);
          })
          .finally(() => setIsLoading(false));
  }, [documentData, documentId]);

  useEffect(() => {
    // check if a specific document id is provided before fetching documents so we don't have to load unnecessary documents
    API.get(`document/${documentId}/link?evidence`)
      .then((res) => {
        const url = res.data;
        setUrlLink(url);
      })
      .catch((err) => {
        setErroLoading(true);
        showErrorResultBar('Error loading document details');
        Sentry.captureException(err);
      })
      ;
  }, [documentId]);

  return (
    <>
    {!isLoading && urlLink !== null  && documentDataDetails !== null ?
        <EvidenceDetails
            assignees={assignees}
            auth={auth}
            documentData={documentDataDetails}
            onUpdate={onUpdate}
            allDocTags={allDocTags}
            onArchive={onArchive}
            allDocNames={allDocNames}
            urlLink={urlLink}
        />   :
        <>
          <Card>
            <div className={classes.card}>
              <Typography className={classes.loading}>
                Loading...
              </Typography>
            </div>
          </Card>
        </>
    }
    {errorLoading ?
      <>
        <Card>
          <div className={classes.card}>
            <Typography className={classes.loading}>
              Error loading details...
            </Typography>
          </div>
        </Card>
      </>
      :
      null
    }
    </>
  );
}
export default EvidenceLoadingDetails;
