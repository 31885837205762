import React, { useEffect, useState } from 'react';
// import { Link as RouterLink } from 'react-router-dom';
import {
  Button,
  createStyles,
  Grid,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import { showErrorResultBar, showSuccessResultBar } from '../components/ResultSnackbar';
import API from '../services/ApiService';
import Auth from '../services/AuthService';
import TaskTable from '../components/TaskTable';
import * as Sentry from '@sentry/browser';
import { RouteComponentProps } from 'react-router';
import { Moment } from 'moment';
import { ITaskCollectionDto, ITaskDto, ITasksResponse } from '../../../backend/src/task/interfaces';
import { IIdNameVersionDto } from '../../../backend/src/common/id-name-version-dto.interface';
import {
  FILTER_VIEW_SELECTIONS
} from '../components/FilterViewSelections';

const styles = (theme: Theme) => createStyles({
  viewLink: {
    color: theme.palette.text.primary,
    paddingTop: '1rem',
    paddingBottom: '1rem',
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

export interface TheTasksPageProps extends WithStyles<typeof styles>, RouteComponentProps<{ view: 'table' | 'cards' }> {
  auth: Auth;
}

function TheTasksPage({ auth, classes, history, match }: TheTasksPageProps) {
  // const view = match.params.view || 'table';
  const [ data, setData ] = useState<ITaskDto[]>([]);
  const [ attachableDocuments, setAttachableDocuments ] = useState<IIdNameVersionDto[]>([]);
  const [ assignees, setAssignees ] = useState([]);
  const [ isLoadingTasks, setIsLoadingTasks ] = useState(false);
  const [ expandedRows, setExpandedRows ] = useState<number[]>([]);
  const [ collections, setCollections ] = useState<ITaskCollectionDto[]>([]);

  useEffect(() => {
    setIsLoadingTasks(true);

    API
      .get('task')
      .then(res => {
        setData(res.data.data);
      })
      .catch((err) => {
        showErrorResultBar('Unexpected error loading tasks');
        Sentry.captureException(err);
      })
      .finally(() => {
        setIsLoadingTasks(false);
    });

    API
      .get('task/collections')
      .then(res => {
        setCollections(res.data.data);
      })
      .catch((err) => {
        showErrorResultBar('Unexpected error loading tasks');
        Sentry.captureException(err);
      })
      .finally(() => {
        setIsLoadingTasks(false);
    });

    API
      .get('task/allowedAssignees')
      .then(res => {
        setAssignees(res.data.data);
      })
      .catch(Sentry.captureException);

  }, []);

  useEffect(() => {
    API
      .get('document/names')
      .then(res => {
        const docs = res.data;
        setAttachableDocuments(docs);
      })
      .catch((err) => {
        showErrorResultBar('Unexpected error loading attachable documents');
        Sentry.captureException(err);
      });
  }, []);

  const handleDueDateChanged = (idx: number) => async (newDueDate: Moment | null) => {
    try {
      const res = await API.put(`task/${data[idx].id}`, { dueDate: newDueDate });

      const dataCopy = data.slice();
      dataCopy[idx] = res.data.data;
      setData(dataCopy);
      showSuccessResultBar('Due date updated successfully.');
    } catch (err) {
      Sentry.captureException(err);
      showErrorResultBar('Unexpected error while updating the due date');
    }
  };

  const handleStartDateChanged = (idx: number) => async (newStartDate: Moment | null) => {
    try {
      const res = await API.put(`task/${data[idx].id}`, { startDate: newStartDate });

      const dataCopy = data.slice();
      dataCopy[idx] = res.data.data;
      setData(dataCopy);
      showSuccessResultBar('Start date updated successfully.');
    } catch (err) {
      Sentry.captureException(err);
      showErrorResultBar('Unexpected error while updating the start date');
    }
  };

  const handleCompletedDateChanged = (idx: number) => async (newCompletedDate: Moment | null) => {
    try {
      const res = await API.put(`task/${data[idx].id}`, { completedAt: newCompletedDate });

      const dataCopy = data.slice();
      dataCopy[idx] = res.data.data;
      setData(dataCopy);
      showSuccessResultBar('Completed date updated successfully.');
    } catch (err) {
      Sentry.captureException(err);
      showErrorResultBar('Unexpected error while updating the completed date');
    }
  };

  const markStarted = (dataIdx: number) => async () => {
    const res = await API.post(`task/${data[dataIdx].id}/start`);

    if (res.status === 200) {
      const dataCopy = data.slice();
      dataCopy[dataIdx] = res.data.data;
      setData(dataCopy);
    }
  };

  const markNotRelevant = (dataIdx: number) => async () => {
    const res = await API.post(`task/${data[dataIdx].id}/notRelevant`);
    const order = data[dataIdx].taskOrder;

    if (res.status === 200) {
      const dataCopy = data.slice();
      dataCopy[dataIdx] = res.data.data;
      dataCopy.forEach((value, index) => index >= order ? dataCopy[index].taskOrder = dataCopy[index].taskOrder - 1 : null);
      setData(dataCopy);
    }
  };

  const markRelevant = (dataIdx: number) => async () => {
    const res = await API.post(`task/${data[dataIdx].id}/relevant`);
    const order = data[dataIdx].taskOrder;

    if (res.status === 200) {
      const dataCopy = data.slice();
      dataCopy[dataIdx] = res.data.data;
      dataCopy.forEach((value, index) => index >= order ? dataCopy[index].taskOrder = dataCopy[index].taskOrder + 1 : null);
      setData(dataCopy);
    }
  };

  const markComplete = (dataIdx: number) => async () => {
    const res = await API.post(`task/${data[dataIdx].id}/complete`);
    if (res.status === 200) {
      const dataCopy = data.slice();
      dataCopy[dataIdx] = res.data.data;
      setData(dataCopy);
    }
  };

  const handleUpdateTasks = async (idxs: number[], updatedTasksInfo: ITasksResponse) => {
    const dataCopy = data.slice();
    idxs.forEach((idx: number) => {
      dataCopy[idx] = {
        ...dataCopy[idx],
        ...updatedTasksInfo,
      };
    });
    setData(dataCopy);
  };

  const handleDeleteTask = (taskIdx: number) => async () => {
    await API.delete(`task/${data[taskIdx].id}`);
    const dataCopy = data.slice();
    dataCopy.splice(taskIdx, 1);

    dataCopy.forEach((d, idx) => {
      if (d.taskOrder > dataCopy[taskIdx].taskOrder && d.collection?.id === data[taskIdx].collection?.id && d.status !== 'not_relevant') {
        dataCopy[idx] = {
          ...dataCopy[idx],
          ...{taskOrder: dataCopy[idx].taskOrder - 1},
        };
      }
    });

    setData(dataCopy);
  };

  const handleAddTask = async (newTask: ITaskDto) => {
    const dataCopy = data.slice();
    dataCopy.forEach((d, idx) => {
      if (d.taskOrder >= newTask.taskOrder && d.collection?.id === newTask.collection?.id && d.status !== 'not_relevant') {
        dataCopy[idx] = {
          ...dataCopy[idx],
          ...{taskOrder: dataCopy[idx].taskOrder + 1},
        };
      }
    });

    dataCopy.splice(0, 0, newTask);
    dataCopy.sort((a,b) => (a.taskOrder > b.taskOrder) ? 1 : ((b.taskOrder > a.taskOrder) ? -1 : 0));

    setData(dataCopy);
  };

  const collapseRows = async () => {
    // TODO: only collapse row that is being sorted and reopen appropriate row
    setExpandedRows([]);
    // let expandedRowsCopy = expandedRows.slice();
    // expandedRowsCopy = expandedRowsCopy.filter(r => r !== selectedIdx);
    // setExpandedRows(expandedRowsCopy);
  };

  const handleUpdateTask = (selectedIdx: number) => async (updatedTask: ITaskDto) => {
    const dataCopy = data.slice();
    const previousTaskOrder = dataCopy[selectedIdx].taskOrder;
    const newTaskOrder = updatedTask.taskOrder;
    dataCopy[selectedIdx] = updatedTask;

    // reorder tasks
    if (previousTaskOrder < newTaskOrder) {
      dataCopy.forEach((d, idx) => {
        if (d.taskOrder > previousTaskOrder && d.taskOrder <= newTaskOrder && d.collection?.id === updatedTask.collection?.id && d.status !== 'not_relevant' && d.id !== updatedTask.id) {
          dataCopy[idx] = {
            ...dataCopy[idx],
            ...{taskOrder: dataCopy[idx].taskOrder - 1},
          };
        }
      });
    } else if (previousTaskOrder > newTaskOrder) {
      dataCopy.forEach((d, idx) => {
        if (d.taskOrder < previousTaskOrder && d.taskOrder >= newTaskOrder && d.collection?.id === updatedTask.collection?.id && d.status !== 'not_relevant' && d.id !== updatedTask.id) {
          dataCopy[idx] = {
            ...dataCopy[idx],
            ...{taskOrder: dataCopy[idx].taskOrder + 1},
          };
        }
      });
    }

    dataCopy.sort((a,b) => (a.taskOrder > b.taskOrder) ? 1 : ((b.taskOrder > a.taskOrder) ? -1 : 0));
    setData(dataCopy);

  };

  const filterViewSelectionOptions = FILTER_VIEW_SELECTIONS(collections);

  const tasksProps = {
    assignees,
    attachableDocuments,
    auth,
    handleAddTask,
    handleCompletedDateChanged,
    handleDeleteTask,
    handleDueDateChanged,
    handleStartDateChanged,
    history,
    isLoading: isLoadingTasks,
    markComplete,
    markNotRelevant,
    markRelevant,
    markStarted,
    onUpdateTasks: handleUpdateTasks,
    handleUpdateTask,
    tasks: data,
    filterViewSelectionOptions,
    expandedRows,
    setExpandedRows,
    collapseRows
  };

  return (
    <>
      {/* {view === 'cards' ? <TaskCards {...tasksProps} /> : <TaskTable {...tasksProps} />} */}
      <Grid
        container
        justifyContent="space-between"
        alignItems="flex-end"
        style={{ marginBottom: '1rem' }}
      >
      <Grid item xs={9}>
      </Grid>
      <Grid item xs={3} alignItems="flex-end">
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              history.push({
                pathname: '/tasks/report',
              })
            }
          >
            View Calendar of Tasks
          </Button>
        </Grid>
      </Grid>
      {<TaskTable {...tasksProps} />}
      {/* <footer>
        <Typography variant="caption">
          <RouterLink to={view === 'cards' ? '/tasks/table' : '/tasks/cards'} className={classes.viewLink}>
            {view === 'cards' ? 'View as Table' : 'View as Cards'}
          </RouterLink>
        </Typography>
      </footer> */}
    </>
  );
}

TheTasksPage.title = 'Tasks';
TheTasksPage.requiredAuthZ = {
  tier: 1,
  permission: 'tasks',
};

export default withStyles(styles, { withTheme: true })(TheTasksPage);
