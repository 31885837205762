import { Typography } from '@material-ui/core';
import React from 'react';
import { withRouter } from 'react-router-dom';
import API from '../services/ApiService';
import * as Sentry from '@sentry/browser';
import { USER_SEAT_VOLUME } from '../services/AuthService';

const isEmailUnverified = (err) => {
  return err.error === 'unauthorized' && err.errorDescription === 'email_not_verified';
};

const isLimitReached = (err) => {
  return err.response.data.message === 'Organization seat limit reached';
};

function TheAuthCallback({ auth, history }) {
  let redirectUrl;

  // TODO: Handle the possible cyclicity in the autoenroll.
  // (If the POST to autoenroll returns a 201 but doesn't add the org then it seems this will loop.)
  // TODO: Clean up the auth callback. (Make the paths clearer.)
  auth.handleAuthentication()
    .then(() => {
      if (auth.accessRevoked()) {
        // so a user doesn't get stuck in another account if their access is revoked while they are logged in as that account
        API.post('auth/activatePrimaryOrg');
        return true;
      };

      return false;
    })
    .then((accessRevoked) => {
      let isNewUser = auth.getOrgId() === undefined;
      redirectUrl = auth.getRedirectUrlAfterLogin();

      if (accessRevoked) {
        auth.renewSession();
        history.push('/dashboard');

        return true;
      }

      if (isNewUser) {
        // if user already has an account and tries to sign up again,
        // auth0 will merge roles, but that doesn't happen until
        // after logging in, so we want to renew the session to see if the account was merged with
        // an already existing account
        auth.renewSession();
        isNewUser = auth.getOrgId() === undefined;
      }

      if (isNewUser) {
        auth.renewSession();
        // if (auth.isGranted({ permission: 'org:create' })) {
        if (auth.isNotRegistered()) {

          // New user with permission granted to create an org (they've gone through the
          // auto-enroll branch already).

          history.push('/registration-closed');

          //history.push('/register');

          return true;
        } else {
          // Attempt to auto-enroll.
          // If auto-enroll fails, the user will receive permission to create their own org,
          // and the API will respond with a 404. That's handled below.
          // They'll automatically re-login, then the next time around
          // they'll have the permission to go straight to the /register page.
          return API.post('auth/autoEnroll', USER_SEAT_VOLUME);
        }
      }

      history.push(redirectUrl);
      return true;
    })
    .then(res => {
      if (typeof (res) === 'object') {
        if (res.status === 201) {
          return auth.login(redirectUrl);
        }
      }

      return true;
    })
    .catch(err => {
      const status = err.response && err.response.status;
      if (isEmailUnverified(err)) {
        auth.logoutAndRedirectToVerifyEmail();
        return;
      }

      switch (status) {
        case 404:
          // New users need to be logged in again so they pick up the 'org' permission:

          // auth.renewSession()
            // .then(() => history.push('/register'));
            history.push('/registration-closed');
          break;

        case 401:
          if (isLimitReached(err)) {
            // check if unauthorized error is due to autoenrollment fail or because the maximum seats have been reached
            history.push('/login#enrollmentLimitReached');
            break;
          } else {
            history.push('/login#autoEnrollFailed');
            break;
          }
        default:
          Sentry.captureException(err);
          if (err?.errorDescription === 'user is blocked') {
            history.push('/login#disabledAccount');
          } else {
            history.push('/login#failed');
          }
          break;
      }
    });

  return (
      <Typography variant="body1">
        Loading user profile...
      </Typography>
  );
}

export default withRouter(TheAuthCallback);
