import {
    Grid
  } from '@material-ui/core';
  import { IOrgMaterialIssueDto } from '../../../backend/src/material-issue/interfaces/org-material-issue-dto.interface';
  import MaterialityAssessmentBubbleChart from './widgets/MaterialityAssessmentBubbleChart';

export interface MaterialityAssessmentMatrixProps {
materialityMatrixScores: IOrgMaterialIssueDto[] | [];
}

function MaterialityAssessmentMatrix({
materialityMatrixScores
}: MaterialityAssessmentMatrixProps) {

return (
    <Grid container>
        <Grid item xs={12} md={12}>
            <MaterialityAssessmentBubbleChart
                materialityMatrixScores={materialityMatrixScores}
            />
        </Grid>
    </Grid>
);
}

export default MaterialityAssessmentMatrix;
