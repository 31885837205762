import {
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  List,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
  StandardProps,
  TextField,
  Typography,
} from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/styles';
import * as Sentry from '@sentry/browser';
import classNames from 'classnames';
import { Formik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { RoleUpdate } from '../../../../backend/src/auth/enums';
import {
  IUserInviteDto,
  IUserInviteError,
  IUserInvitesResponse,
  IUserInviteUploadDto,
} from '../../../../backend/src/user/interfaces';
import API from '../../services/ApiService';
import { helperAndErrorText } from '../forms';
import { showErrorResultBar } from '../ResultSnackbar';
import { RoleSelectionLabel, roleUpdateMap } from '../UserManagementUserDetails';
import ResponsiveDialog from './ResponsiveDialog';
import Auth from '../../services/AuthService';
import { handleError } from '../../helpers';

const useStyles = makeStyles({
  inputContainer: {
    padding: '0 2rem',
  },
  roleSelectionContainer: {
    margin: 0,
    paddingTop: '1rem',
    paddingBottom: '1rem',
    borderRadius: '4px',
  },
  selectedRoleContainer: {
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
  },
});

interface IFormValues {
  emails: string;
  role: RoleUpdate;
}

const initialFormValues: IFormValues = {
  emails: '',
  role: 'Org Trainee',
};

const createUploadData = (formValues: IFormValues): IUserInviteUploadDto => {
  return {
    emails: formValues.emails.split(/[\s,]+/).filter(email => !!email) || [],
    role: formValues.role,
  };
};

export interface UserInviteDialogProps extends StandardProps<DialogProps, 'children'> {
  onAddInvites: (invites: IUserInviteDto[]) => void;
  onClose: () => void;
  invitesLeft: number;
  auth: Auth;
}

export default function UserInviteDialog({ onAddInvites, onClose, open, invitesLeft, auth }: UserInviteDialogProps) {
  const classes = useStyles();
  const [ addedInvites, setAddedInvites ] = useState<IUserInviteDto[]>([]);
  const [ inviteErrors, setInviteErrors ] = useState<IUserInviteError[]>([]);
  const [ toShowResponseDialog, setToShowResponseDialog ] = useState(false);

  const UserInviteSchema = Yup.object().shape({
    emails: Yup
      .array()
      .max(invitesLeft, `You only have ${invitesLeft} user seats left.`)
      .transform(function (value, originalValue) {
        if (this.isType(value) && value !== null) {
          return value;
        }

        return originalValue ? originalValue.split(/[\s,]+/) : [];
      })
      .of(Yup.string().email(({ value }) => ` ${value} is not a valid email`).required())
      .required('Required.'),
    role: Yup
      .mixed()
      .oneOf(Object.keys(roleUpdateMap) as RoleUpdate[])
      .required(),
  });

  const handleSend = async (formValues: IUserInviteUploadDto) => {
    try {
      const res: IUserInvitesResponse = (await API.post('user/invite', formValues)).data?.data;
      const { errors = [], invites = [] } = res || {};

      if (errors.length === 0 && invites.length === 0) {
        showErrorResultBar('No invitations were sent');
        onClose();
      }

      setAddedInvites(invites);
      setInviteErrors(errors);
      setToShowResponseDialog(true);
      onAddInvites(invites);
    } catch (err) {
      handleError(err, 'Error sending invitations');
    }
  };

  const handleExited = () => {
    setToShowResponseDialog(false);
    setAddedInvites([]);
    setInviteErrors([]);
  };

  return (
    <ResponsiveDialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
      onExited={handleExited}
    >
      {toShowResponseDialog ? (<>
        <DialogContent>
          {addedInvites.length > 0 && (<>
            <Typography gutterBottom variant="h6">
              {addedInvites.length > 1 ?
                'Invitations will be sent to the following addresses:' :
                'An invitation will be sent to the following address:'
              }
            </Typography>
            <List disablePadding>
              {addedInvites.map(invite => (
                <ListItem key={invite.email}>
                  <ListItemText
                    primary={invite.email}
                    secondary={roleUpdateMap[invite.role]?.text || invite.role}
                  />
                </ListItem>
              ))}
            </List>
          </>)}
          {inviteErrors.length > 0 && (<>
            <Typography gutterBottom variant="h6">
              Not all of the invitations were sent:
            </Typography>
            <List disablePadding>
              {inviteErrors.map(inviteError => (
                <ListItem key={inviteError.email}>
                  <ListItemText
                    primary={inviteError.email}
                    secondary={inviteError.message}
                  />
                </ListItem>
              ))}
            </List>
          </>)}
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={onClose}
          >
            Close
          </Button>
        </DialogActions>
      </>) : (
        <Formik
          enableReinitialize
          initialValues={initialFormValues}
          validationSchema={UserInviteSchema}
          onReset={onClose}
          onSubmit={async (values, { setSubmitting }) => {
            await handleSend(createUploadData(values));
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            handleBlur,
            handleChange,
            handleReset,
            handleSubmit,
            isSubmitting,
          }) => (
            <>
              <DialogContent>
                {/* Invite emails */}
                <Typography gutterBottom variant="h6">
                  Email addresses
                </Typography>
                <TextField
                  className={classes.inputContainer}
                  autoFocus
                  id="emails"
                  name="emails"
                  value={values.emails}
                  multiline
                  placeholder="user@example.com"
                  rows={4}
                  variant="outlined"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  helperText={helperAndErrorText('Please enter a comma separated list of emails or one per line.', errors.emails)}
                  error={!!errors.emails}
                />
                {/* Role selection */}
                <FormControl>
                  <FormLabel>
                    <Typography gutterBottom variant="h6">
                      Choose a role
                    </Typography>
                  </FormLabel>
                  <RadioGroup
                    className={classes.inputContainer}
                    id="role"
                    name="role"
                    value={values.role}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  >
                    {Object.entries(roleUpdateMap).map(([ key, roleSelection ]) => (
                      <FormControlLabel
                        key={key}
                        className={classNames(
                          classes.roleSelectionContainer,
                          key === values.role && classes.selectedRoleContainer,
                        )}
                        value={key}
                        control={<Radio />}
                        label={<RoleSelectionLabel roleSelection={roleSelection} />}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  onClick={handleReset}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  disabled={isSubmitting || Object.keys(errors).length > 0}
                  size="small"
                  variant="contained"
                  onClick={() => handleSubmit()}
                >
                  Send invitations
                </Button>
              </DialogActions>
            </>
          )}
        </Formik>
      )}
    </ResponsiveDialog>
  );
}
