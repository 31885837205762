import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useRef, useState } from 'react';
import { SpioDataTableSelectedRows } from './SpioDataTable';

import Auth from '../services/AuthService';

const useStyles = makeStyles({
  buttonsContainer: {
    lineHeight: '96px',
    paddingRight: '2rem',
  },
  button: {
    marginRight: '1rem',
  },
});

interface IIdxMetricSummary {
  idx: number;
  metricSummary: any;
}

export interface MetricSummaryTableSelectedRowsToolbarProps {
  onCancel: () => void;
  onUpdate: (idxs: number[], metricSummary: any, flag: boolean) => void;
  selectedRows: SpioDataTableSelectedRows;
  metricSummary: any[];
  auth: Auth;
}

export interface IMetricSummaryUpdateDto {
  ids: string[];
  tracked: boolean;
}

export function MetricSummaryTableSelectedRowsToolbar(
  props: MetricSummaryTableSelectedRowsToolbarProps
) {
  const classes = useStyles();
  const { onUpdate, selectedRows, metricSummary, auth } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const selectedDataRef = useRef<IIdxMetricSummary[]>([]);

  const getAllSelectedData = () => {
    const allSelectedIdxs = selectedRows.data.map((row) => row.dataIndex);
    return allSelectedIdxs.map((idx) => ({
      idx,
      metricSummary: metricSummary[idx],
    }));
  };

  const handleClickAddToDashboard = () => {
    const mSelectedData = getAllSelectedData().filter(
      ({ metricSummary }) => metricSummary.saved !== true
    );
    onUpdate(
      selectedDataRef.current.map(({ idx }) => idx),
      mSelectedData,
      true
    );
    selectedDataRef.current = [];
  };

  const handleClickRemoveFromDashboard = () => {
    const mSelectedData = getAllSelectedData().filter(
      ({ metricSummary }) => metricSummary.saved === true
    );
    onUpdate(
      selectedDataRef.current.map(({ idx }) => idx),
      mSelectedData,
      false
    );
    selectedDataRef.current = [];
  };

  return (
    <>
      <div className={classes.buttonsContainer}>
        <Button
          className={classes.button}
          color="primary"
          variant="contained"
          onClick={handleClickAddToDashboard}
          disabled={isSubmitting || !auth.isGranted({ permission: 'esg_metrics_dashboard:edit'})}
        >
          Add to dashboard
        </Button>
        <Button
          className={classes.button}
          color="primary"
          variant="contained"
          onClick={handleClickRemoveFromDashboard}
          disabled={isSubmitting || !auth.isGranted({ permission: 'esg_metrics_dashboard:edit'})}
        >
          Remove from dashboard
        </Button>
      </div>
    </>
  );
}
