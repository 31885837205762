import {
  Accordion,
  AccordionDetails,
  makeStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import React, { useEffect, useState } from 'react';
import { TrainingCategory, TrainingViewStatus } from '../../../backend/src/training/enums';
import { ITrainingVideoViewDto } from '../../../backend/src/training/interfaces';
import AccordionIconSummary from './AccordionIconSummary';

const useStyles = makeStyles({
  list: {
    width: '100%',
  },
});

interface IPanelFilter {
  categories: TrainingCategory[];
  expanded?: boolean;
  title: string;
}

// NOTE: Can't use `TrainingCategory.{{CATEGORY}} due to react import error...
const PANELS_FILTERS: IPanelFilter[] = [
  {
    categories: ['essential_training'],
    expanded: true,
    title: 'Get Acquainted with ESG',
  },
  {
    categories: ['risk_training'],
    expanded: false,
    title: 'Learn the Fundamentals of Climate Risk Management',
  },
  {
    categories: ['policy_training'],
    expanded: false,
    title: 'Policy Training',
  },
  {
    categories: ['standards_training'],
    title: 'Standards and Regulations',
  },
  // {
  //   categories: ['general_training', 'program_management_training'],
  //   title: 'Miscellaneous',
  // },
];

interface ITrainingStatusInfo {
  icon: React.ReactElement<any>;
  text: string;
}

const TRAINING_STATUS_MAP: Record<TrainingViewStatus, ITrainingStatusInfo> = {
  notstarted: {
    icon: <NewReleasesIcon fontSize="large" />,
    text: 'Not Started',
  },
  started: {
    icon: <TimelapseIcon fontSize="large" />,
    text: 'Started',
  },
  completed: {
    icon: <DoneIcon fontSize="large" />,
    text: 'Completed',
  },
};

interface IVideoList {
  expanded?: boolean;
  title: string;
  videos: ITrainingVideoViewDto[];
}

export interface VideoListAccordionProps {
  videoList: IVideoList;
  onVideoSelected: (video: ITrainingVideoViewDto) => () => void;
}

const VideoListAccordion = ({ videoList, onVideoSelected }: VideoListAccordionProps) => {
  const classes = useStyles();
  const { expanded, title, videos } = videoList;
  const [isExpanded, setIsExpanded] = useState(expanded ?? false);
  return (
    <Accordion
      elevation={2}
      expanded={isExpanded}
      onChange={() => setIsExpanded(!isExpanded)}
    >
      <AccordionIconSummary title={title} />
      <AccordionDetails>
        <List className={classes.list}>
          {videos.sort((v1, v2) => v1.progression - v2.progression).map(video => (
            <ListItem
              key={video.name}
              button
              onClick={onVideoSelected(video)}
            >
              <Tooltip
                placement="top"
                title={TRAINING_STATUS_MAP[video.status].text}
              >
                <ListItemIcon>
                  {TRAINING_STATUS_MAP[video.status].icon}
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={video.name}
                secondary={
                  <>
                    <Typography
                      component="span"
                      variant="body2"
                    >
                      {video.description}
                    </Typography>
                  </>
                }
              >
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

export interface TrainingVideosProps {
  videos: ITrainingVideoViewDto[];
  onVideoSelected: (video: ITrainingVideoViewDto) => () => void;
}

export default function TrainingVideos({ videos, onVideoSelected }: TrainingVideosProps) {
  const [videoLists, setVideoLists] = useState<IVideoList[]>([]);

  useEffect(() => {
    const theVideoLists: IVideoList[] = PANELS_FILTERS.map((videoList) => {
      const vids = videos.filter(vid => videoList.categories.includes(vid.trainingCategory));

      return { expanded: videoList.expanded, title: videoList.title, videos: vids };
    });

    setVideoLists(theVideoLists.filter(videoList => videoList.videos.length > 0));
  }, [videos]);

  return (
    <>
      {videoLists.map(videoList => (
        <VideoListAccordion
          key={videoList.title}
          videoList={videoList}
          onVideoSelected={onVideoSelected}
        />
      ))}
    </>
  );
}
