import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/core/styles';
import TheNavLinkList from '../components/TheNavLinkList';

const drawerWidth = 240;

const styles = theme => ({
  toolbar: theme.mixins.toolbar,

  drawer: {
    [theme.breakpoints.up('lg')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },

  drawerPaper: {
    width: drawerWidth,
  },
});

function TheNavDrawer({ auth, badges, classes, container, onClose, open, theme }) {
   return (
    <nav className={classes.drawer}>
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden lgUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          transitionDuration={500}
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={open}
          onClose={onClose}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <TheNavLinkList
            auth={auth}
            badges={badges}
            onClick={onClose}
          />
        </Drawer>
      </Hidden>
      <Hidden mdDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          <TheNavLinkList
            auth={auth}
            badges={badges}
          />
        </Drawer>
      </Hidden>
    </nav>
  );
}

export default withStyles(styles, { withTheme: true })(TheNavDrawer);
