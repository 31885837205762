import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import React from 'react';
import { RouterLink } from '../RouterLink';

function ViewPDFButton(props: any) {
  return (
    <RouterLink
      to={`/policies/${props.url}`}
      rel="noopener noreferrer"
      target="_blank"
    >
      <IconButton aria-label="viewPDF" {...props}>
        <VisibilityIcon />
      </IconButton>
    </RouterLink>
  );
}

export default ViewPDFButton;
