import ListAlt from '@material-ui/icons/ListAlt';
import * as Sentry from '@sentry/browser';
import React, { useEffect, useState } from 'react';
import API from '../services/ApiService';
import Auth from '../services/AuthService';
import { History } from 'history';
import { showErrorResultBar } from '../components/ResultSnackbar';
import OutboundQuestionnaireTable from '../components/OutboundQuestionnaireTable';
import { IOutboundQuestionnaireDetailsDto } from '../../../backend/src/outbound-questionnaire/interfaces/outbound-questionnaire-details-dto.interface';

function TheOutboundQuestionnairePage({ auth, history }: { auth: Auth, history: History }) {
  const [isLoading, setIsLoading] = useState(false);
  const [questionnaires, setQuestionnaires] = useState<IOutboundQuestionnaireDetailsDto[]>([]);

  useEffect(() => {
    setIsLoading(true);

    API.get('outbound-questionnaire/allQuestionnaires')
      .then((res) => setQuestionnaires(res.data?.data ?? []))
      .catch((err) => {
        showErrorResultBar('Unexpected error loading questionnaire responses');
        Sentry.captureException(err);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const onUpdateQuestionnaire = (newQuestionnaires: IOutboundQuestionnaireDetailsDto[]) => {
    setQuestionnaires(newQuestionnaires);
  };

  return (
    <>
      <OutboundQuestionnaireTable
        auth={auth}
        questionnaires={questionnaires}
        history={history}
        onUpdateQuestionnaires={onUpdateQuestionnaire}
      />
    </>
  );
}

TheOutboundQuestionnairePage.icon = <ListAlt />;
TheOutboundQuestionnairePage.requiredAuthZ = {
  tier: 3,
  permission: 'outbound_questionnaire',
};
TheOutboundQuestionnairePage.title = 'Outbound Questionnaires';

export default TheOutboundQuestionnairePage;
