import React, { useEffect, useState } from 'react';
import SpioDataTable from './SpioDataTable';
import API from '../services/ApiService';
import IconButton from '@material-ui/core/IconButton';
import MetricCreateDialog from '../components/dialogs/MetricCreateDialog';
import DataTableTitleWithButton from '../components/DataTableTitleWithButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { EditButton } from './buttons';
import * as Sentry from '@sentry/browser';
import { showErrorResultBar, showSuccessResultBar } from '../components/ResultSnackbar';

export default function MetricManagementTable({
  isLoading,
  metricID,
  metricTableData,
  columnData,
  title,
  tableColumns,
  auth
}) {
  const [tableData, setTableData] = useState([]);
  const [columnFields, setColumnFields] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [rowUpdate, setRowUpdate] = useState();
  const [idUpdate, setIdUpdate] = useState();
  const [metricInputData, setMetricInputData] = useState([]);

  useEffect(() => {
    setMetricInputData(metricTableData);
    }, [metricTableData]);

  useEffect(() => {
    setTableData(
      metricInputData.map((d, idx) => {
        const fieldArray = d.org_metric_inputs.map((field) => {
          if (field.value_unit_abbreviation) {
            return {
              [field.org_metric_field_name]: `${field.value} ${field.value_unit_abbreviation}`,
            };
          }
          return {
            [field.org_metric_field_name]: field.value,
          };
        });

        var fieldValues = Object.assign.apply({}, fieldArray);
        return Object({
          ...d,
          ...fieldValues,
          actions:
          [
            <IconButton
            aria-label="Delete"
            key={`link_${idx}`}
            onClick={handleDeleteRow(idx)}
            disabled={!auth.isGranted({ permission: 'esg_metrics:edit'})}
          >
            <DeleteIcon />
          </IconButton>,
          <EditButton key={`edit_${idx}`} onClick={handleUpdateRow(idx)} disabled={!auth.isGranted({ permission: 'esg_metrics:edit'})} />,
          ],
        });
      })
    );
  }, [metricInputData]);

  const restructureFormValues = async (formValues, metricData, update=false) => {
    // TODO: not the most elegant or efficient way to restructure the data
    let payload = {
      valueStartDatetime: formValues.valueStartDatetime,
      valueEndDatetime: formValues.valueEndDatetime,
      orgProcessId: formValues.facilityId.id,
      accountingMetricId: metricID,
      notes: formValues.notes,
      documents: formValues.documents,
      fromAggregate: false,
      orgMetricInputs: []
    };

    // loop through all metric fields and add them to the payload
    for (const metricField of metricData) {
        let orgMetricInput = {
          orgMetricFieldId: metricField.metric_field_id,
          fieldValue: formValues[metricField.metric_field_id],
        };
        if (update) { //if we're updating values rather creating new row, include the metric input id
          const idx = rowUpdate?.org_metric_inputs.findIndex(input => input.org_metric_field_id === metricField.metric_field_id);
          if (idx > -1) {
            orgMetricInput.orgMetricInputId = rowUpdate.org_metric_inputs[idx].org_metric_input_id;
          };
        }
        if (metricField.data_type === 'float') { // add fields for specific data types
          orgMetricInput.valueUnitId = formValues[`${metricField.metric_field_id}-unit`];
        } else if (metricField.data_type === 'bool') {
          orgMetricInput.fieldValue = orgMetricInput.fieldValue === false ? 'false' : 'true';
        }
        payload.orgMetricInputs.push(orgMetricInput);
    }

    return payload;
  };

  const onCreate = async (newRow) => {
    try {
      const dataCopy = metricInputData.slice();
      dataCopy.unshift(newRow);
      setMetricInputData(dataCopy);
      //updateTableValues(dataCopy);

      showSuccessResultBar('Metric data created successfully.');
    } catch (err) {
      Sentry.captureException(err);
      showErrorResultBar('Unexpected error while creating metric data.');
    }
  };

  const onUpdate = async (newRow) => {
    try {
      const dataCopy = metricInputData.slice();
      dataCopy[idUpdate] = newRow;
      setMetricInputData(dataCopy);
      //updateTableValues(dataCopy);
      showSuccessResultBar('Metric data updated successfully.');
    } catch (err) {
      Sentry.captureException(err);
      showErrorResultBar('Unexpected error while updating metric data.');
      }
  };

  const handleDeleteRow = (idx) => (e) => {
    e.stopPropagation();
    deleteRow(idx);
  };

  const deleteRow = async (idx) => {
    try {
      const res = await API.delete(`org-metric-input/${metricInputData[idx].org_metric_metadata_id}`);
      onDelete(idx);
    } catch (err) {
      const errorMsg =
      err.response?.data?.error ?? 'Unexpected error deleting metric data';
      showErrorResultBar(errorMsg);
      Sentry.captureException(err);
    }
  };

  const onDelete = async (idx) => {
    try {
      const dataCopy = metricInputData.slice();
      dataCopy.splice(idx, 1);
      setMetricInputData(dataCopy);
      showSuccessResultBar('Metric data deleted successfully.');
    } catch (err) {
      Sentry.captureException(err);
      showErrorResultBar('Unexpected error while deleting metric data.');
    }
  };

  const handleUpdate = async (formValues, metricData) => {
    try {
      const payload = await restructureFormValues(formValues, metricData, true);
      const res = await API.patch(`org-metric-input/${rowUpdate.org_metric_metadata_id}`, payload);
      setIsEditDialogOpen(false);
      onUpdate(res.data?.data[0]);
    } catch (err) {
      const errorMsg =
        err.response?.data?.error ?? 'Unexpected error saving metric data';
      showErrorResultBar(errorMsg);
      Sentry.captureException(err);
    }
  };

  const handleSave = async (formValues, metricData) => {
    try {
      const payload = await restructureFormValues(formValues, metricData);
      const res = await API.post('org-metric-input', payload);

      setIsDialogOpen(false);
      onCreate(res.data[0]);
    } catch (err) {
      const errorMsg =
      err.response?.data?.error ?? 'Unexpected error adding metric data';
      showErrorResultBar(errorMsg);
      Sentry.captureException(err);
    }
  };

  const handleUpdateRow = (idx) => (e) => {
    e.stopPropagation();
    setIsEditDialogOpen(true);
    setRowUpdate(metricInputData[idx]);
    setIdUpdate(idx);
  };

  useEffect(() => {
    if (metricID) {
      API.get(`org-metric/${metricID}/metricFields`).then((res) => {
        const trackedColumns = res.data.data.filter((column) => column.tracked);
        setColumnFields(trackedColumns);
      });
    }
  }, [metricID]);

  return (
    <>
      <SpioDataTable
        title={
          <DataTableTitleWithButton
            onButtonClick={() => setIsDialogOpen(true)}
            title={title}
            disabled={!auth.isGranted({ permission: 'esg_metrics:edit'})}
          />
        }
        data={tableData}
        columns={tableColumns}
        options={{
          download: true,
          expandableRows: false,
          filterType: 'multiselect',
          // onRowClick: (_, { dataIndex: idx }) =>
          //   onOpenPolicyDoc(metricTableData[idx]),
          print: false,
          rowsPerPage: 25,
          selectableRows: 'none',
          textLabels: {
            body: {
              noMatch: isLoading ? 'Loading...' : 'No records found',
              toolTip: 'Sort',
            },
          },
        }}
      />
      <MetricCreateDialog
        open={isDialogOpen}
        columnFields={columnFields}
        accounting_metric_id = {metricID}
        onClose={() => setIsDialogOpen(false)}
        handleSave={handleSave}
        metricData={null}
        auth={auth}
      />
      {/* This is specific to edit instead of create */}
      <MetricCreateDialog
        open={isEditDialogOpen}
        columnFields={columnFields}
        accounting_metric_id = {metricID}
        onClose={() => setIsEditDialogOpen(false)}
        handleSave={handleSave}
        handleUpdate={handleUpdate}
        metricData={rowUpdate}
        auth={auth}
      />
    </>
  );
}
