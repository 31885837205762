
import React, { useEffect, useState } from 'react';
import Auth from '../services/AuthService';
import API from '../services/ApiService';
import * as Sentry from '@sentry/browser';
import { showErrorResultBar } from '../components/ResultSnackbar';
import PermissionsTable from '../components/PermissionsTable';
import { IPermissionDto, IRoleDto } from '../../../backend/src/org-share/interfaces';

export interface ThePermissionsPageProps {
  auth: Auth,
}

function ThePermissionsPage({ auth }: ThePermissionsPageProps) {
  const [permissions, setPermissions] = useState<IPermissionDto[]>([]);
  const [roles, setRoles] = useState<IRoleDto[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [tools, setTools] = useState<string[]>([]);

  // TODO: currently org share and regular roles are the same, but eventually we may want to load those as separate tabs
  useEffect(() => {
    API.get('user/permissionInfo')
      .then(res => {
        setPermissions(res.data?.data ?? []);
      })
      .catch((err) => {
        showErrorResultBar('Unexpected error loading permissions');
        Sentry.captureException(err);
      }).finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    const toolsList = [...new Set(permissions.map(p => p.tool))];
    setTools(toolsList);
  }, [permissions]);

  useEffect(() => {
    API.get('user/roles')
      .then(res => setRoles(res.data?.data ?? []))
      .catch((err) => {
        showErrorResultBar('Unexpected error loading roles');
        Sentry.captureException(err);
      });
  }, []);

  return (
    <>
      {!isLoading &&
        <PermissionsTable
          permissions={permissions}
          roles={roles}
          tools={tools}
          auth={auth}
        />
      }
    </>
  );
}

ThePermissionsPage.title = 'Permission Details';
ThePermissionsPage.routePath = '/permissions';

export default ThePermissionsPage;
