import React, { useEffect, useState } from 'react';
import { Tooltip } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import * as Sentry from '@sentry/browser';
import * as lodash from 'lodash';
import moment from 'moment';
import { TrainingViewStatus } from '../../../backend/src/training/enums';
import { ITrainingStatusDto } from '../../../backend/src/training/interfaces';
import { formatDate } from '../helpers';
import API from '../services/ApiService';
import { showErrorResultBar } from '../components/ResultSnackbar';
import SpioDataTable, { SpioDataTableColumn } from '../components/SpioDataTable';

// Used in the filename of the downloaded report:
const todaysDate = moment().format('YYYYMMDD');

export interface IViewStatusInfo {
  icon: React.ReactElement<any>;
  text: string;
}

export const VIEW_STATUS_MAP: Record<TrainingViewStatus, IViewStatusInfo> = {
  notstarted: {
    icon: <NewReleasesIcon />,
    text: 'Not Started',
  },
  started: {
    icon: <TimelapseIcon />,
    text: 'Started',
  },
  completed: {
    icon: <DoneIcon />,
    text: 'Completed',
  },
};

const getTableColumns = (tableData: ITableDatum[]): SpioDataTableColumn[] => [
  {
    name: 'blockedStr',
    label: 'Disabled',
    options: {
      customFilterListOptions: { render: v => `Disabled: ${v}` },
      display: 'false',
      filterOptions: { names: [ 'True', 'False' ] },
    },
  },
  {
    name: 'name',
    label: 'Name',
  },
  {
    name: 'email',
    label: 'Email',
  },
  {
    name: 'trainingCategoryStr',
    label: 'Category',
  },
  {
    name: 'videoName',
    label: 'Video',
  },
  {
    name: 'videoId',
    label: 'Video Id',
    options: {
      display: 'false',
      filter: false,
    },
  },
  {
    name: 'statusStr',
    label: 'Status',
    options: {
      customBodyRenderLite: (dataIndex) => {
        const statusStr = tableData[dataIndex]?.statusStr || '';
        const status = Object.values(VIEW_STATUS_MAP).find(m => m.text === statusStr);

        if (status && (status.icon && status.text)) {
          return (
            <Tooltip
              placement="top"
              title={status.text}
            >
              {status.icon}
            </Tooltip>
          );
        } else {
          return statusStr;
        }
      },
    },
  },
  {
    name: 'completedAtStr',
    label: 'Completed',
    options: {
      filter: false,
    },
  },
];

interface ITableDatum extends ITrainingStatusDto {
  blockedStr: string;
  completedAtStr: string;
  statusStr: string;
  trainingCategoryStr: string;
}

function TheTrainingReportPage() {
  const [ isLoading, setIsLoading ] = useState(false);
  const [ tableData, setTableData ] = useState<ITableDatum[]>([]);

  useEffect(() => {
    setIsLoading(true);

    API
      .get('training/report')
      .then((res) => {
        const data: ITrainingStatusDto[] = res.data?.data || [];
        setTableData(data.map(d => Object({
          ...d,
          blockedStr: d.blocked ? 'True' : 'False',
          completedAtStr: formatDate(d.completedAt),
          statusStr: VIEW_STATUS_MAP[d.status].text,
          trainingCategoryStr: lodash.startCase(lodash.startCase(d.trainingCategory)),
        })));
      })
      .catch((err) => {
        showErrorResultBar('Unexpected error loading Training Report data');
        Sentry.captureException(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      <SpioDataTable
        title="Training Coverage Report"
        columns={getTableColumns(tableData)}
        data={tableData}
        initFilters={[ [ 'False' ] ]}
        options={{
          textLabels: {
            body: {
              noMatch: isLoading ? 'Loading...' : 'No users found',
              toolTip: 'Sort',
            },
          },
          downloadOptions: {
            filename: `TrainingReport_${todaysDate}.csv`,
            filterOptions: {
              useDisplayedRowsOnly: true,
            },
          },
          filterType: 'multiselect',
          selectableRows: 'none',
        }}
      />
    </>
  );
}

TheTrainingReportPage.requiredAuthZ = {
  tier: 3,
  permission: 'training:manage',
};
TheTrainingReportPage.routePath = '/training/report';
TheTrainingReportPage.title = 'Training Report';

export default TheTrainingReportPage;
