import { Button, Card, CardHeader, FormControlLabel, Switch } from '@material-ui/core';
import classNames from 'classnames';
import { sortedUniq } from 'lodash';
import moment from 'moment';
import { MUIDataTableTextLabels } from 'mui-datatables';
import React, { useEffect, useState } from 'react';
import { UserAuditMemberStatus } from '../../../../backend/src/user-audit/enums';
import { IAzureMember, IAzureMemberInfo, IAzureOrg } from '../../../../backend/src/user-audit/interfaces';
import SpioDataTable, { SpioDataTableColumn } from '../SpioDataTable';
import {
  BOOLEAN_FILTER_SELECTIONS,
  boolToString,
  DiffTableCell,
  diffTableCellDownload,
  diffTableCellSortCompare,
  filterLogicKeyedStringArrays,
  filterLogicStringArray,
  getAgeInDays,
  getOrgAuditStatus,
  getTableSubtitleText,
  NameArrayDetails,
  nameArrayDownload,
  numberNullSortCompare,
  StatusCell,
  statusDownload,
  useUserAuditTableStyles,
} from './UserAuditTableHelpers';

export interface IAzureGroupsByType {
  o365: string[];
  roles: string[];
  security: string[];
}

export class IAzureFlattenedMember {
  public static fromOrgMember(orgMember: IAzureMember) {
    return new IAzureFlattenedMember(orgMember);
  }

  accountEnabled: (string | null | undefined)[] = [];
  id: string;
  email: string[] = [];
  groups: IAzureGroupsByType[] = [];
  name: string[] = [];
  passwordAge: number | null;
  passwordPolicies: (string | null | undefined)[] = [];
  status: UserAuditMemberStatus;
  userPrincipalName: string[] = [];
  userType: string[] = [];

  protected constructor (orgMember: IAzureMember) {
    this.id = orgMember.member_id;
    this.passwordAge = getAgeInDays(orgMember.data?.lastPasswordChangeDateTime ?? orgMember.prev_data?.lastPasswordChangeDateTime);
    this.status = orgMember.status;
    this.pushFlattenedData(orgMember.data);
    this.pushFlattenedData(orgMember.prev_data);
  }

  // Data for which we want diffs displayed:
  protected pushFlattenedData(data: IAzureMemberInfo | null = null) {
    if (data) {
      this.accountEnabled.push(boolToString(data.accountEnabled));
      this.email.push(data.email || '');
      this.groups.push({
        o365: data.o365Groups || [],
        roles: data.roles || [],
        security: data.securityGroups || [],
      });
      this.name.push(data.name || '');
      this.passwordPolicies.push(data.passwordPolicies ?? '');
      this.userPrincipalName.push(data.userPrincipalName || '');
      this.userType.push(data.userType || '');
    }
  }
}

function DiffTableCellGroups({ groups, isModified, toShowNames }: { groups: IAzureGroupsByType[]; isModified: boolean; toShowNames: boolean; }) {
  const currNames: IAzureGroupsByType = groups[0] || {};
  const prevNames: IAzureGroupsByType = groups[1] || {};

  return (
    <div>
      <NameArrayDetails
        label="O365 Groups"
        isModified={isModified}
        currNames={(currNames.o365 || []).sort()}
        prevNames={(prevNames.o365 || []).sort()}
        toShowNames={toShowNames}
      />
      <NameArrayDetails
        label="Security Groups"
        isModified={isModified}
        currNames={(currNames.security || []).sort()}
        prevNames={(prevNames.security || []).sort()}
        toShowNames={toShowNames}
      />
      <NameArrayDetails
        label="Roles"
        isModified={isModified}
        currNames={(currNames.roles || []).sort()}
        prevNames={(prevNames.roles || []).sort()}
        toShowNames={toShowNames}
      />
    </div>
  );
}

const getAuditTableColumns = (
  allEmails: string[],
  allGroups: string[],
  allNames: string[],
  allUPNs: string[],
  allUserTypes: string[],
  tableData: IAzureFlattenedMember[],
  toExpandDetails: boolean,
): SpioDataTableColumn[] => {
  return [
    {
      name: 'status',
      label: 'Changed',
      options: {
        customBodyRenderLite: dataIndex => <StatusCell status={tableData[dataIndex]?.status} />,
        customDownloadRender: dataIndex => statusDownload(tableData[dataIndex]?.status),
        filter: false,
        searchable: false,
      },
    },
    {
      name: 'id',
      label: 'Id',
      options: {
        customFilterListOptions: { render: (v: string) => `Id: ${v}` },
        display: 'false',
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const member = tableData[dataIndex];

          return member && <DiffTableCell values={member.name} isModified={member.status === 'modified'}/>;
        },
        customDownloadRender: dataIndex => diffTableCellDownload(tableData[dataIndex]?.name, tableData[dataIndex]?.status === 'modified'),
        customFilterListOptions: { render: (v: string) => `Name: ${v}` },
        filterOptions: {
          names: allNames,
          logic: filterLogicStringArray,
        },
        sortCompare: diffTableCellSortCompare,
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const member = tableData[dataIndex];

          return member && <DiffTableCell values={member.email} isModified={member.status === 'modified'}/>;
        },
        customDownloadRender: dataIndex => diffTableCellDownload(tableData[dataIndex]?.email, tableData[dataIndex]?.status === 'modified'),
        customFilterListOptions: { render: (v: string) => `Email: ${v}` },
        filterOptions: {
          names: allEmails,
          logic: filterLogicStringArray,
        },
        sortCompare: diffTableCellSortCompare,
      },
    },
    {
      name: 'groups',
      label: 'Groups/Roles',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const member = tableData[dataIndex];

          return member && <DiffTableCellGroups
            groups={member.groups}
            isModified={member.status === 'modified'}
            toShowNames={toExpandDetails}
          />;
        },
        customFilterListOptions: { render: (v: string) => `Group: ${v}` },
        download: false,
        filterOptions: {
          names: allGroups,
          logic: filterLogicKeyedStringArrays,
        },
        sort: false,
      },
    },
    {
      name: 'groups',
      label: 'O365 Groups',
      options: {
        customDownloadRender: (dataIndex) => {
          const member = tableData[dataIndex];
          const groups = member?.groups || [];

          return nameArrayDownload(member.status === 'modified', groups[0]?.o365, groups[1]?.o365);
        },
        display: 'excluded',
        download: true,
        filter: false,
        searchable: false,
        viewColumns: false,
      },
    },
    {
      name: 'groups',
      label: 'Security Groups',
      options: {
        customDownloadRender: (dataIndex) => {
          const member = tableData[dataIndex];
          const groups = member?.groups || [];

          return nameArrayDownload(member.status === 'modified', groups[0]?.security, groups[1]?.security);
        },
        display: 'excluded',
        download: true,
        filter: false,
        searchable: false,
        viewColumns: false,
      },
    },
    {
      name: 'groups',
      label: 'Roles',
      options: {
        customDownloadRender: (dataIndex) => {
          const member = tableData[dataIndex];
          const groups = member?.groups || [];

          return nameArrayDownload(member.status === 'modified', groups[0]?.roles, groups[1]?.roles);
        },
        display: 'excluded',
        download: true,
        filter: false,
        searchable: false,
        viewColumns: false,
      },
    },
    {
      name: 'userPrincipalName',
      label: 'UPN',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const member = tableData[dataIndex];

          return member && <DiffTableCell values={member.userPrincipalName} isModified={member.status === 'modified'}/>;
        },
        customDownloadRender: dataIndex => diffTableCellDownload(tableData[dataIndex]?.userPrincipalName, tableData[dataIndex]?.status === 'modified'),
        customFilterListOptions: { render: (v: string) => `UPN: ${v}` },
        display: 'false',
        filterOptions: {
          names: allUPNs,
          logic: filterLogicStringArray,
        },
        sortCompare: diffTableCellSortCompare,
      },
    },
    {
      name: 'userType',
      label: 'User Type',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const member = tableData[dataIndex];

          return member && <DiffTableCell values={member.userType} isModified={member.status === 'modified'}/>;
        },
        customDownloadRender: dataIndex => diffTableCellDownload(tableData[dataIndex]?.userType, tableData[dataIndex]?.status === 'modified'),
        customFilterListOptions: { render: (v: string) => `User Type: ${v}` },
        display: 'true',
        filterOptions: {
          names: allUserTypes,
          logic: filterLogicStringArray,
        },
        sortCompare: diffTableCellSortCompare,
      },
    },
    {
      name: 'accountEnabled',
      label: 'Account Enabled?',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const member = tableData[dataIndex];

          return member && <DiffTableCell values={member.accountEnabled} isModified={member.status === 'modified'}/>;
        },
        customDownloadRender: dataIndex => diffTableCellDownload(tableData[dataIndex]?.accountEnabled, tableData[dataIndex]?.status === 'modified'),
        customFilterListOptions: { render: (v: string) => `Account Enabled: ${v}` },
        filterOptions: {
          names: BOOLEAN_FILTER_SELECTIONS,
        },
        sortCompare: diffTableCellSortCompare,
      },
    },
    {
      name: 'passwordAge',
      label: 'Password Age',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const age = tableData[dataIndex]?.passwordAge;

          return (age === undefined || age === null) ? '' : `${age}d`;
        },
        filter: false,
        sortCompare: numberNullSortCompare,
      },
    },
    {
      name: 'passwordPolicies',
      label: 'Password Policy Exceptions',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const member = tableData[dataIndex];

          return member && <DiffTableCell values={member.passwordPolicies} isModified={member.status === 'modified'}/>;
        },
        customDownloadRender: dataIndex => diffTableCellDownload(tableData[dataIndex]?.passwordPolicies, tableData[dataIndex]?.status === 'modified'),
        customFilterListOptions: { render: (v: string) => `Password Policy Exceptions: ${v}` },
        sortCompare: diffTableCellSortCompare,
      },
    },
  ];
};

interface UserAuditAzureTableProps {
  handleConfirmSelected: () => Promise<void>;
  isLoading: boolean;
  orgs: IAzureOrg[];
  selectedOrgIdx: number;
}

export default function UserAuditAzureTable(props: UserAuditAzureTableProps) {
  const classes = useUserAuditTableStyles();
  const { handleConfirmSelected, isLoading, orgs, selectedOrgIdx } = props;

  const [ isDiffView, setIsDiffView ] = useState<boolean>(false);
  const [ selectedOrg, setSelectedOrg ] = useState<IAzureOrg>();
  const [ orgsMembers, setOrgsMembers ] = useState<IAzureFlattenedMember[][]>([]);
  const [ membersToDisplay, setMembersToDisplay ] = useState<IAzureFlattenedMember[]>([]);
  const [ toExpandDetails, setToExpandDetails ] = useState<boolean>(false);

  const [ allEmails, setAllEmails ] = useState<string[]>([]);
  const [ allNames, setAllNames ] = useState<string[]>([]);
  const [ allGroups, setAllGroups ] = useState<string[]>([]);
  const [ allUPNs, setAllUPNs ] = useState<string[]>([]);
  const [ allUserTypes, setAllUserTypes ] = useState<string[]>([]);

  useEffect(() => {
    setSelectedOrg(orgs[selectedOrgIdx]);
  }, [ orgs, selectedOrgIdx ]);

  useEffect(() => {
    const orgsFlattenedMembers = orgs.map((org) => {
      const latestSync = org.syncs[0];
      const orgMembers: IAzureMember[] = latestSync ? latestSync.membersDiffData : [];

      return orgMembers.map(IAzureFlattenedMember.fromOrgMember);
    });

    setOrgsMembers(orgsFlattenedMembers);
  }, [ orgs ]);

  // Pre-filters before sending the data to the table: diff view.
  // (We don't want chips for these.)
  useEffect(() => {
    let orgMembers = orgsMembers[selectedOrgIdx] || [];

    if (isDiffView) {
      orgMembers = orgMembers.filter(orgMember => orgMember.status !== 'unchanged');
    }

    setMembersToDisplay(orgMembers);
  }, [ orgsMembers, isDiffView, selectedOrgIdx ]);

  // Consolidate the names, etc for display in the filters.
  // (Each user may have e.g. two names: a current name and a previous name. We want both in the filter.)
  useEffect(() => {
    const emailsWithDuplicates = membersToDisplay.flatMap(member => member.email).sort();
    setAllEmails(sortedUniq(emailsWithDuplicates));

    const groupsWithDuplicates = membersToDisplay.flatMap((member) => {
      return member.groups.flatMap(groupsByType => Object.values(groupsByType).flat());
    }).sort();
    setAllGroups(sortedUniq(groupsWithDuplicates));

    const namesWithDuplicates = membersToDisplay.flatMap(member => member.name).sort();
    setAllNames(sortedUniq(namesWithDuplicates));

    const upnsWithDuplicates = membersToDisplay.flatMap(member => member.userPrincipalName).sort();
    setAllUPNs(sortedUniq(upnsWithDuplicates));

    const userTypesWithDuplicates = membersToDisplay.flatMap(member => member.userType).sort();
    setAllUserTypes(sortedUniq(userTypesWithDuplicates));
  }, [ membersToDisplay ]);

  const clickToggleDiffView = () => {
    setIsDiffView(!isDiffView);
  };

  const clickToggleToShowDetails = () => {
    setToExpandDetails(!toExpandDetails);
  };

  const clickConfirmSelected = async () => {
    handleConfirmSelected();
  };

  const TableTitle = () => {
    if (isLoading) {
      return null;
    }

    return (
      <div>
        {getOrgAuditStatus(selectedOrg) === 'pending' && (
          <FormControlLabel
            control={
              <Switch
                checked={isDiffView}
                onChange={clickToggleDiffView}
              />
            }
            label="Only show changed users"
          />
        )}
        {allGroups.length > 0 && (
          <FormControlLabel
            control={
              <Switch
                checked={toExpandDetails}
                onChange={clickToggleToShowDetails}
              />
            }
            label="Expand details"
          />
        )}
      </div>
    );
  };

  return (
    <Card
      className={classes.tableCard}
      variant="outlined"
    >
      <CardHeader
        classes={{
          action: classes.tableCardHeaderAction,
        }}
        action={getOrgAuditStatus(selectedOrg) === 'pending' && (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={clickConfirmSelected}
            disabled={isLoading}
          >
            Confirm
          </Button>
        )}
        title={selectedOrg && `${selectedOrg.name} Members`}
        subheader={getTableSubtitleText(selectedOrg)}
      />
      <SpioDataTable
        className={classes.dataTable}
        title={<TableTitle />}
        columns={getAuditTableColumns(
          allEmails,
          allGroups,
          allNames,
          allUPNs,
          allUserTypes,
          membersToDisplay,
          toExpandDetails,
        )}
        data={membersToDisplay || []}
        options={{
          elevation: 1,
          textLabels: {
            body: {
              noMatch: isLoading ? 'Loading...' : (isDiffView ? 'No changes' : 'No users found'),
            },
          } as MUIDataTableTextLabels,
          downloadHeader: [
            [ selectedOrg?.name ?? '' ],
            [ getTableSubtitleText(selectedOrg) ],
            [],
          ],
          downloadOptions: {
            filename: `UserAudit_AzureAD_${moment().format('YYYYMMDD')}.csv`,
          },
          filterType: 'multiselect',
          print: false,
          selectableRows: 'none',
          sort: true,
          sortOrder: {
            name: 'name',
            direction: 'asc',
          },
          setRowProps: (row, index) => {
            const status = membersToDisplay[index]?.status;

            return {
              className: classNames(
                status === 'added' && classes.newUserRow,
                status === 'deleted' && classes.deletedUserRow,
                ),
            };
          },
        }}
      />
    </Card>
  );
}
