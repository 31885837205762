import {
  Card,
  CardContent,
  CardHeader,
  createStyles,
  Grid,
  Hidden,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Theme,
  Tooltip,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import AttachIcon from '@material-ui/icons/AttachFile';
import DeleteIcon from '@material-ui/icons/Delete';
import HelpIcon from '@material-ui/icons/HelpOutline';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles } from '@material-ui/styles';
import * as Sentry from '@sentry/browser';
import React, { useEffect, useState } from 'react';
import { IDocument } from '../../../backend/src/document/interfaces/document.interface';
import { VendorAuthStatus, VendorStatus } from '../../../backend/src/vendor/enums';
import { IVendorAttachment, IVendorDto } from '../../../backend/src/vendor/interfaces';
import * as Helpers from '../helpers';
import API from '../services/ApiService';
import * as DocService from '../services/DocService';
import { FileUploadDialog, RemoveFileDialog } from './dialogs';
import { isDataResellerSelections, isSubprocessorSelections, vendorStatusSelections } from './dialogs/VendorEditDialog';
import { showErrorResultBar, showSuccessResultBar } from './ResultSnackbar';
import VendorQuestionnaireResponse from './VendorQuestionnaireResponse';
import { handleError } from '../helpers';

const styles = (theme: Theme) => createStyles({
  buttonLink: {
    'color': 'inherit',
    'textDecoration': 'underline',
    'cursor': 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  cardHeader: {
    paddingBottom: 0,
  },
  helpIcon: {
    'fontSize': '18px',
    'marginBottom': '-1px',
    'marginLeft': theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  linkItem: {
    '& a': {
      color: theme.typography.body1.color,
      cursor: 'pointer',
    },
    '&:nth-child(n+2)': {
      paddingTop: '4px',
    },
  },
  primaryColumn: {
    color: theme.typography.body1.color,
    fontSize: '14px',
  },
  referenceHeading: {
    fontSize: '1rem',
    color: theme.typography.body1.color,
  },
  referencesContainer: {
    paddingLeft: '1rem',
    paddingBottom: '0.8rem',
  },
  referenceResponse: {
    color: 'rgba(0,0,0,0.54)',
    whiteSpace: 'pre-line',
  },
  secondaryColumn: {
    backgroundColor: '#f5f5f5',
    opacity: '0.8',
    fontSize: '0.8125rem',
  },
  smallIconButton: {
    'height': '14px',
    'width': '14px',
    'marginTop': '-6px',
    'marginLeft': '0.5rem',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  smallIconButtonIcon: {
    fontSize: '18px',
  },
  smallIconButtonLabel: {
    height: '0',
  },
});

const useMissingInfoStyles = makeStyles({
  missingDataText: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
});

export interface IVendorUrlInfo {
  value: string;
  text: string;
  href?: string | null;
}

export const vendorUrlMap: IVendorUrlInfo[] = [
  {
    value: 'urlMain',
    text: 'Main website',
  },
  {
    value: 'urlCompliance',
    text: 'Compliance page',
  },
  {
    value: 'urlPrivacy',
    text: 'Privacy policy',
  },
  {
    value: 'urlSecurity',
    text: 'Security policy',
  },
  {
    value: 'urlSupport',
    text: 'Support page',
  },
  {
    value: 'urlTerms',
    text: 'Terms of service',
  },
];

export const authStatusMap: { [key in VendorAuthStatus]: string } = {
  enforced: 'Enforced',
  enabled: 'Enabled',
  available: 'Available',
  unavailable: 'Unavailable',
  not_applicable: 'Not applicable',
  unknown: 'Unknown',
};

export const vendorStatusMap: { [key in VendorStatus]: string } = {
  ...vendorStatusSelections,
  archived: 'Archived',
};

const getStatusString = (vendorData: IVendorDto) => {
  const { approvedAt, status } = vendorData;
  const statusStr = (status && vendorStatusMap[status]) || 'Unknown';

  let dateStr = '';
  if (status === 'approved' && approvedAt) {
    dateStr = ` (on ${Helpers.formatDate(approvedAt)})`;
  }

  return `Status: ${statusStr}${dateStr}`;
};

export function MissingDataText({ message }: { message?: string }) {
  const classes = useMissingInfoStyles();

  return <em className={classes.missingDataText}>{message || 'None provided'}</em>;
}

export interface SecondaryFieldProps {
  title: string;
  value: number | string;
}

export function SecondaryField({ title, value }: SecondaryFieldProps) {
  return (<>
    <Hidden smUp>
      <Grid container>
        <Grid item xs={3}>{title}</Grid>
        <Grid item xs={9}>{value}</Grid>
      </Grid>
    </Hidden>
    <Hidden xsDown>
      <Grid container justifyContent="space-between" wrap="nowrap">
        <Grid item>{title}</Grid>
        <Grid item>{value}</Grid>
      </Grid>
    </Hidden>
  </>);
}

export interface VendorDetailsProps extends WithStyles<typeof styles> {
  vendorData: IVendorDto;
  onClickArchive: () => void;
  onClickEdit: () => void;
  onClickSelfFillQuestionnaire: () => void;
  onClickSendQuestionnaire: () => void;
  onUpdateVendor: (newVendorData: IVendorDto) => void;
}

function VendorDetailsInfo(props: VendorDetailsProps) {
  const {
    classes,
    onClickArchive,
    onClickEdit,
    onClickSelfFillQuestionnaire,
    onClickSendQuestionnaire,
    onUpdateVendor,
    vendorData,
  } = props;

  const [documentToArchive, setDocumentToArchive] = useState<IVendorAttachment | null>(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [toShowFileUploadDialog, setToShowFileUploadDialog] = useState(false);
  const [toShowRemoveEvidenceDialog, setToShowRemoveEvidenceDialog] = useState(false);
  const [urls, setUrls] = useState<IVendorUrlInfo[]>([]);

  useEffect(() => {
    const mUrls: IVendorUrlInfo[] = [];

    if (vendorData && vendorData.urls) {
      const rawUrls: { [key: string]: string | null } = Object.assign(vendorData.urls);

      vendorUrlMap.forEach((urlInfo) => {
        const href = rawUrls[urlInfo.value];
        if (href) {
          mUrls.push({ ...urlInfo, href });
        }
      });
    }

    setUrls(mUrls);
  }, [vendorData]);

  const handleAttachFile = async (newDoc: IDocument[]) => {
    for (const doc of newDoc) {
      try {
        const res = await API.post(`vendor/${vendorData.id}/documents/${doc.id}`);
        onUpdateVendor(res.data.data);
        showSuccessResultBar('File attached to vendor');
      } catch (err) {
        handleError(err, 'Unexpected error while attaching file');
      }
    }
  };

  const handleClickRemoveAttachment = (document: IVendorAttachment) => () => {
    setDocumentToArchive(document);
    setToShowRemoveEvidenceDialog(true);
  };

  const handleRemoveAttachment = async (docId: string) => {
    try {
      const res = await API.delete(`vendor/${vendorData.id}/documents/${docId}`);
      onUpdateVendor(res.data.data);
      showSuccessResultBar('File archived.');

      return true;
    } catch (err) {
      Sentry.captureException(err);
      showErrorResultBar('Unexpected error while archiving file. Please try again.');

      return false;
    }
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const handleClickMenuItem = (handler: () => void) => () => {
    handleCloseMenu();
    handler();
  };

  return (
    <>
      <Card>
        <CardHeader
          action={<>
            <IconButton onClick={({ currentTarget }) => setMenuAnchorEl(currentTarget)}>
              <MoreVertIcon />
            </IconButton>
          </>}
          className={classes.cardHeader}
          title={vendorData.vendorName || 'Vendor details'}
          subheader={getStatusString(vendorData)}
        />
        <Menu
          anchorEl={menuAnchorEl}
          keepMounted
          open={Boolean(menuAnchorEl)}
          onClose={handleCloseMenu}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <MenuItem onClick={handleClickMenuItem(onClickEdit)}>
            Edit
          </MenuItem>
          <MenuItem onClick={handleClickMenuItem(() => setToShowFileUploadDialog(true))}>
            Attach a file
          </MenuItem>
          <MenuItem onClick={handleClickMenuItem(onClickSendQuestionnaire)}>
            Send questionnaire
          </MenuItem>
          <MenuItem onClick={handleClickMenuItem(onClickSelfFillQuestionnaire)}>
            Self fill questionnaire
          </MenuItem>
          <MenuItem onClick={handleClickMenuItem(onClickArchive)}>
            Archive
          </MenuItem>
        </Menu>
        <CardContent>
          <Grid container alignItems="flex-start" spacing={4}>
            {/* Primary column */}
            <Grid item xs={12} sm={8} md={9} className={classes.primaryColumn}>
              {/* Data */}
              <Typography
                variant="h6"
                className={classes.referenceHeading}
                gutterBottom
              >
                Data handled
              </Typography>
              <div className={classes.referencesContainer}>
                <Grid container>
                  <Grid item xs={3}>Description:</Grid>
                  <Grid item xs={9} className={classes.referenceResponse}>
                    {vendorData.dataHandled ? (
                      vendorData.dataHandled
                    ) : (
                      <MissingDataText message="No description provided" />
                    )}
                  </Grid>
                  <Grid item xs={3}>Data tier:</Grid>
                  <Grid item xs={9} className={classes.referenceResponse}>
                    {typeof vendorData.dataTier === 'number' ? vendorData.dataTier : <MissingDataText message="Data tier not indicated" />}
                  </Grid>
                  {vendorData.isSubprocessor && (<>
                    <Grid item xs={3}>Is a subprocessor?</Grid>
                    <Grid item xs={9} className={classes.referenceResponse}>{isSubprocessorSelections[vendorData.isSubprocessor]}</Grid>
                  </>)}
                  {vendorData.isDataReseller && (<>
                    <Grid item xs={3}>Sells data?</Grid>
                    <Grid item xs={9} className={classes.referenceResponse}>{isDataResellerSelections[vendorData.isDataReseller]}</Grid>
                  </>)}
                </Grid>
              </div>
              {/* Authentication */}
              <Typography
                variant="h6"
                className={classes.referenceHeading}
                gutterBottom
              >
                Authentication
                <Tooltip
                  title={<>
                    <div>Details of how your internal users log in to the vendor's system.</div>
                    <div>
                      Is multi-factor authentication (MFA) available? If so, is it enabled/enforced?
                      Is single sign-on (SSO) available/enabled/enforced?
                    </div>
                  </>}
                >
                  <HelpIcon color="action" className={classes.helpIcon} />
                </Tooltip>
              </Typography>
              <div className={classes.referencesContainer}>
                <Grid container>
                  <Grid item xs={3}>MFA:</Grid>
                  <Grid item xs={9} className={classes.referenceResponse}>
                    {(vendorData.mfaStatus && authStatusMap[vendorData.mfaStatus]) || 'Unknown'}
                  </Grid>
                  <Grid item xs={3}>SSO:</Grid>
                  <Grid item xs={9} className={classes.referenceResponse}>
                    {(vendorData.ssoStatus && authStatusMap[vendorData.ssoStatus]) || 'Unknown'}
                  </Grid>
                </Grid>
              </div>
              {/* Notes */}
              <Typography
                variant="h6"
                className={classes.referenceHeading}
                gutterBottom
              >
                Additional notes
              </Typography>
              <div className={classes.referencesContainer}>
                {vendorData.notes ? (
                  <span className={classes.referenceResponse}>{vendorData.notes}</span>
                ) : (
                  <MissingDataText message="None" />
                )}
              </div>
            </Grid>
            {/* Secondary column */}
            <Grid item xs={12} sm={4} md={3} className={classes.secondaryColumn}>
              {/* Internal tracking */}
              <Typography
                variant="h6"
                className={classes.referenceHeading}
                gutterBottom
              >
                Internal tracking
              </Typography>
              <div className={classes.referencesContainer}>
                {vendorData.implementedAt && <SecondaryField title="Start date:" value={Helpers.formatDate(vendorData.implementedAt) || 'n/a'} />}
                {vendorData.reviewedAt && <SecondaryField title="Last review date:" value={Helpers.formatDate(vendorData.reviewedAt) || 'n/a'} />}
                {vendorData.retiredAt && <SecondaryField title="Retirement date:" value={Helpers.formatDate(vendorData.retiredAt) || 'n/a'} />}
                {vendorData.ownerEmail && <SecondaryField title="Owner:" value={vendorData.ownerEmail} />}
                {vendorData.department && <SecondaryField title="Department:" value={vendorData.department} />}
                {vendorData.externalId && <SecondaryField title="External ID:" value={vendorData.externalId} />}
              </div>
              {/* Contact info */}
              <Typography
                variant="h6"
                className={classes.referenceHeading}
                gutterBottom
              >
                Vendor contact
              </Typography>
              <div className={classes.referencesContainer}>
                {(vendorData.contactEmail || vendorData.contactName || vendorData.contactPhone || vendorData.contactTitle) ? (
                  <>
                    {vendorData.contactEmail && <SecondaryField title="Email:" value={vendorData.contactEmail} />}
                    {vendorData.contactName && <SecondaryField title="Name:" value={vendorData.contactName} />}
                    {vendorData.contactPhone && <SecondaryField title="Phone:" value={vendorData.contactPhone} />}
                    {vendorData.contactTitle && <SecondaryField title="Title/Role:" value={vendorData.contactTitle} />}
                  </>
                ) : (
                  <MissingDataText />
                )}
              </div>
              {/* URLs */}
              <Typography
                variant="h6"
                className={classes.referenceHeading}
                gutterBottom
              >
                Links
              </Typography>
              <div className={classes.referencesContainer}>
                {urls.length === 0 ? (
                  <MissingDataText />
                ) : (
                  urls.map(urlInfo => (
                    <div
                      key={urlInfo.value}
                      className={classes.linkItem}
                    >
                      <Link
                        className={classes.buttonLink}
                        href={urlInfo.href || ''}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {urlInfo.text}
                      </Link>
                    </div>
                  ))
                )}
              </div>
              {/* Attachments */}
              <Typography
                variant="h6"
                className={classes.referenceHeading}
                gutterBottom
              >
                Attachments
                <Tooltip
                  title="Attach a file"
                >
                  <IconButton
                    classes={{
                      root: classes.smallIconButton,
                      label: classes.smallIconButtonLabel,
                    }}
                    onClick={() => setToShowFileUploadDialog(true)}
                  >
                    <AttachIcon className={classes.smallIconButtonIcon} />
                  </IconButton>
                </Tooltip>
              </Typography>
              <div className={classes.referencesContainer}>
                {vendorData.documents.length > 0 ? (
                  vendorData.documents.map(doc => (
                    <Grid key={doc.id} container justify="space-between">
                      <Grid item>
                        <Link
                          className={classes.buttonLink}
                          onClick={DocService.documentDownloadHandler(doc.id)}
                        >
                          {Helpers.truncateString(doc.name)}
                        </Link>
                      </Grid>
                      <Grid item>
                        <Tooltip
                          title="Remove this attachment"
                        >
                          <IconButton
                            classes={{
                              root: classes.smallIconButton,
                              label: classes.smallIconButtonLabel,
                            }}
                            onClick={handleClickRemoveAttachment(doc)}
                          >
                            <DeleteIcon className={classes.smallIconButtonIcon} />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  ))
                ) : (
                  <MissingDataText message="None" />
                )}
              </div>
            </Grid>
            {/* Questionnaires */}
            <Grid item xs={12}>
              {vendorData.questionnaires && vendorData.questionnaires.length > 0 && <>
                <Typography
                  variant="h6"
                  className={classes.referenceHeading}
                  gutterBottom
                >
                  Vendor Questionnaires
                </Typography>
                <div className={classes.referencesContainer}>
                  {vendorData.questionnaires.map((questionnaire) => (
                    <VendorQuestionnaireResponse
                      key={questionnaire.id}
                      onUpdateVendor={onUpdateVendor}
                      questionnaire={questionnaire}
                      vendorData={vendorData}
                    />
                  ))}
                </div>
              </>}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <FileUploadDialog
        dialogHeaderText={`Attach a file to '${vendorData.vendorName}'`}
        documentCategory="evidence/vendor"
        onAddDocuments={handleAttachFile}
        onClose={() => setToShowFileUploadDialog(false)}
        open={toShowFileUploadDialog}
        currentDocuments={[]}
      />
      {documentToArchive &&
        <RemoveFileDialog
          document={documentToArchive}
          onClose={() => setToShowRemoveEvidenceDialog(false)}
          onExited={() => setDocumentToArchive(null)}
          onRemoveFile={handleRemoveAttachment}
          open={toShowRemoveEvidenceDialog}
        />
      }
    </>
  );
}

export default withStyles(styles, { withTheme: true })(VendorDetailsInfo);
