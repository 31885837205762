import { createStyles, StyledComponentProps, withStyles } from '@material-ui/core';
import React from 'react';
import docDiscovery from '../images/file_structure.gif';

const styles = createStyles({
  img: {
    width: '100%',
    maxWidth: '420px',
  },
});

function DocDiscoveryImage({ classes }: StyledComponentProps) {
  return (
    <img className={classes!.img} src={docDiscovery} alt="" />
  );
}

export default withStyles(styles, { name: 'DocDiscoveryImage' })(DocDiscoveryImage);
