import { createStyles, StandardProps, Theme, withStyles } from '@material-ui/core';
import Paper, { PaperClassKey, PaperProps } from '@material-ui/core/Paper';
import classNames from 'classnames';
import * as React from 'react';

const styles = (theme: Theme) => createStyles({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },

  center: {
    textAlign: 'center',
  },
});

export interface PageProps extends StandardProps<PaperProps, PageClassKey> {
  center?: boolean;
}

export type PageClassKey = PaperClassKey |
  'center';

function Page({ center, children, classes, ...other }: PageProps) {
  return (
    <Paper className={classNames(classes!.root, center && classes!.center)} {...other}>
      {children}
    </Paper>
  );
}

export default withStyles(styles, { name: 'Page', withTheme: true })(Page);
