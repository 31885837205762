import { DialogContent, StandardProps } from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/styles';
import { History } from 'history';
import React from 'react';
import Auth from '../../services/AuthService';
import { IMaterialIssueContextDto } from '../../../../backend/src/material-issue-context/interfaces';
import MaterialityIssueDetails from '../MaterialityIssueDetails';
import ResponsiveDialog from './ResponsiveDialog';

const useStyles = makeStyles({
  dialogContent: {
    'padding': '0',
    'overflowY': 'hidden',
    '&:first-child': {
      paddingTop: '0',
    },
  },
});

export interface MaterialityDetailsDialogProps extends StandardProps<DialogProps, 'children'> {
  auth: Auth;
  history: History;
  onArchive: () => void;
  onClose: () => void;
  onUpdate: (issue: IMaterialIssueContextDto) => void;
  issueData: IMaterialIssueContextDto;
}

export default function MaterialityDetailsDialog({ auth, history, onArchive, onClose, onUpdate, open, issueData }: MaterialityDetailsDialogProps) {
  const classes = useStyles();

  return (
    <ResponsiveDialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
    >
      <DialogContent className={classes.dialogContent}>
        <MaterialityIssueDetails
          auth={auth}
          disableTaskClickThrough
          history={history}
          isDialog
          onArchive={onArchive}
          onCloseDialog={onClose}
          onUpdate={onUpdate}
          issueData={issueData}
          allMaterialIssues={[]}
        />
      </DialogContent>
    </ResponsiveDialog>
  );
}
