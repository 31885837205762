import { Button } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

const useIconStyles = makeStyles({
  leftIcon: {
    marginRight: '0.5rem',
  },
});

export interface FileUploadButtonProps extends ButtonProps {
  showIcon?: boolean;
}

export default function FileUploadButtonDialog({ children, showIcon = false, ...rest }: FileUploadButtonProps) {
  const iconClasses = useIconStyles();

  return (
    <Button
      {...rest}
    >
      {!!showIcon && <InsertDriveFileIcon className={iconClasses.leftIcon} />}
      {children}
    </Button>
  );
}
