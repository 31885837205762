import { IIdNameDto } from '../../../backend/src/common/id-name-dto.interface';
import { ITaskCollectionDto } from '../../../backend/src/task/interfaces';
import { TaskStatus } from '../../../backend/src/task/task-status.enum';
import { IFilters } from './TaskTable';

export const TASK_STATUS_MAP: Record<TaskStatus, string> = {
  not_started: 'Not Started',
  completed: 'Completed',
  past_due: 'Past Due',
  in_progress: 'In Progress',
  not_relevant: 'Not Relevant',
};

export const OPEN_TASKS_FILTERS = [
  TASK_STATUS_MAP.not_started,
  TASK_STATUS_MAP.in_progress,
  TASK_STATUS_MAP.past_due,
];

export const RELEVANT_TASKS_FILTERS = [
  TASK_STATUS_MAP.not_started,
  TASK_STATUS_MAP.in_progress,
  TASK_STATUS_MAP.past_due,
  TASK_STATUS_MAP.completed
];

export interface IFilterViewSelection {
  text: string;
  value: string;
  filter?: IFilters;
}

export const FILTER_VIEW_IDENTIFIERS = {
  CUSTOM_TASKS: 0,
  ALL_TASKS: 1,
  MY_OPEN_TASKS: 3
};

export const FILTER_VIEW_SELECTIONS = (collections: (ITaskCollectionDto | undefined)[]): IFilterViewSelection[] => {
  const filters = [
    {
      text: 'Custom',
      value: 'custom',
    },
    {
      text: 'All Tasks',
      value: 'all_tasks',
      filter: {},
    },
    {
      text: 'All Relevant Tasks',
      value: 'all_relevant_tasks',
      filter: {
        statusStr: RELEVANT_TASKS_FILTERS,
      },
    },
    {
      text: 'My Open Tasks',
      value: 'my_open_tasks',
      filter: {
        // Note that the assigneeId needs to be filled in dynamically before this filter is used.
        assigneeId: [],
        statusStr: OPEN_TASKS_FILTERS,
      },
    },
    {
      text: 'All Open Tasks',
      value: 'all_open_tasks',
      filter: {
        statusStr: OPEN_TASKS_FILTERS,
      },
    }
  ];



  for (const [idx, collection] of collections.entries()) {
    if (collection !== undefined) {
      filters.push(Object({
        text: `Collection ${idx + 1}: ${collection.name}`,
        value: collection.id,
        filter: {
          // statusStr: OPEN_TASKS_FILTERS,
          collectionName: [collection.name],
        }
      }));
      let gIdx = 1;
      if (collection.taskGroups) {
        for (const [_, group] of collection.taskGroups.entries()) {
          if (group !== undefined) {
            filters.push(Object({
              text: `Group ${gIdx}: ${group.name}`,
              value: group.id,
              filter: {
                // statusStr: OPEN_TASKS_FILTERS,
                groupName: [group.name],
                collectionName: [collection.name]
              }
            }));
          }
          gIdx = gIdx + 1;
        }
      }
    }
  }

  return filters;
};

export const DEFAULT_VIEW = FILTER_VIEW_SELECTIONS([])[FILTER_VIEW_IDENTIFIERS.CUSTOM_TASKS];
