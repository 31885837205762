import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  StandardProps,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Select
} from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import EmailIcon from '@material-ui/icons/Email';
import { makeStyles } from '@material-ui/styles';
import * as Sentry from '@sentry/browser';
import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { IStakeholderDto } from '../../../../backend/src/stakeholder/interfaces';
import API from '../../services/ApiService';
import { showErrorResultBar, showSuccessResultBar } from '../ResultSnackbar';
import ResponsiveDialog from './ResponsiveDialog';
import { ISendStakeholderQuestionnaireDto } from '../../../../backend/src/stakeholder/interfaces';
import { handleError } from '../../helpers';

const useStyles = makeStyles({
  leftIcon: {
    marginRight: '4px',
  },
});

const StakeholderQuestionnaireEmailSchema = Yup.object().shape({
  questionnaireEmail: Yup
    .string()
    .label('Email')
    .email('Must be a valid email address')
    .required('Required'),
});

export interface StakeholderSendQuestionnaireDialogProps extends StandardProps<DialogProps, 'children'> {
  onClose: () => void;
  onUpdateStakeholder: (newStakeholderData: IStakeholderDto) => void;
  stakeholderData: IStakeholderDto;
  questionnaireTemplates: Array<any>;
}

export default function StakeholderSendQuestionnaireDialog(props: StakeholderSendQuestionnaireDialogProps) {
  const classes = useStyles();
  const {
    onClose,
    onUpdateStakeholder,
    open,
    stakeholderData,
    questionnaireTemplates,
  } = props;

  const [questionnaireType, setQuestionnaireType] = React.useState(questionnaireTemplates[0].id);

  const handleQuestionnaireChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newSelection = event.target.value as string;
    setQuestionnaireType(newSelection);
  };

  const handleSendQuestionnaire = async (email: string, questionnaireType: string) => {
    try {
      const stakeholderEmailUpload: ISendStakeholderQuestionnaireDto = {
        questionnaireTemplateId: questionnaireType,
        email: email,
      };

      const res = await API.post(`stakeholder/${stakeholderData.id}/send-questionnaire`, stakeholderEmailUpload);
      onUpdateStakeholder(res.data.data);
      showSuccessResultBar('Questionnaire sent successfully');
    } catch (err) {
      handleError(err, 'Error sending questionnaire');
    }
  };

  return (
    <ResponsiveDialog
      open={open}
      onClose={onClose}
      disableBackdropClick
    >
      <Formik
        enableReinitialize
        initialValues={{
          questionnaireEmail: stakeholderData.contactEmail || ''
        }}
        validationSchema={StakeholderQuestionnaireEmailSchema}
        onSubmit={async (values, { setSubmitting }) => {
          await handleSendQuestionnaire(values.questionnaireEmail, questionnaireType);
          setSubmitting(false);
          onClose();
        }}
        onReset={onClose}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleReset,
          handleSubmit,
          isSubmitting,
        }) => (
          <>
            <DialogTitle>Questionnaire Information</DialogTitle>
            <DialogContent>
            <DialogContentText>
                Please select the type of questionnaire you would like to send and the email address of the recipient.
              </DialogContentText>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Questionnaire Type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={questionnaireType}
                  label="Questionnaire Type"
                  onChange={handleQuestionnaireChange}
                >
                {Object.values(questionnaireTemplates).map(option => {
                          return (
                            <MenuItem key={option['id']} value={option['id']}>
                              {option['name']}
                            </MenuItem>
                          );
                })}
                </Select>
              </FormControl>
              <TextField
                id="questionnaire-email"
                name="questionnaireEmail"
                label="Email"
                value={values.questionnaireEmail}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth={true}
                margin="normal"
                helperText={errors.questionnaireEmail}
                error={!!errors.questionnaireEmail}
              />
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                size="small"
                disabled={isSubmitting}
                onClick={handleReset}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                size="small"
                variant="contained"
                disabled={isSubmitting || Object.keys(errors).length > 0}
                onClick={(e: any) => handleSubmit(e)}
              >
                <EmailIcon className={classes.leftIcon} />
                Send Questionnaire
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </ResponsiveDialog>
  );
}
