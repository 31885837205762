import {
    Button,
    Card,
    CardActions,
    CardContent,
    TextField,
   } from '@material-ui/core';
  import { makeStyles } from '@material-ui/styles';
  import { Formik } from 'formik';
  import React from 'react';
  import * as Yup from 'yup';
  import { ISubTaskDto } from '../../../backend/src/task/interfaces';

  const useStyles = makeStyles({
    leftButton: {
      marginLeft: 'auto',
    },
  });

  const SubTaskSchema = Yup.object().shape({
    title: Yup
      .string()
      .required('Required')
      .max(255),
    description: Yup
      .string()
      .max(255),
  });

  export interface ISubTaskInputData {
    id?: string;
    title: string;
    description: string
  }

  export interface SubTaskInputProps {
    subTask?: ISubTaskDto;
    onCancelSubTask: () => void;
    onSaveSubTask: (formData: ISubTaskInputData) => Promise<void>;
  }

  export default function SubTaskInput({ onCancelSubTask, onSaveSubTask, subTask }: SubTaskInputProps) {
    const classes = useStyles();

    return (
      <Card
        elevation={5}
      >
        <Formik
          enableReinitialize
          initialValues={{
            id: subTask ? subTask.id : undefined,
            title: subTask ? (subTask.title || '') : '',
            description: subTask ? (subTask.description || '') : '',
          }}
          validationSchema={SubTaskSchema}
          onSubmit={async (values, { setSubmitting }) => {
            await onSaveSubTask(values);
            //setSubmitting(false);
          }}
          onReset={_ => {
            onCancelSubTask();
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            handleReset,
            handleSubmit,
            isSubmitting,
          }) => (
            <>
              <CardContent>
                <TextField
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  margin="dense"
                  multiline
                  placeholder={'SubTask Name'}
                  helperText={errors.title}
                  error={!!errors.title}
                />
                <TextField
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  margin="dense"
                  multiline
                  placeholder={'SubTask Description'}
                  helperText={errors.description}
                  error={!!errors.description}
                />
              </CardContent>
              <CardActions>
                <Button
                  color="primary"
                  size="small"
                  disabled={isSubmitting}
                  onClick={handleReset}
                  className={classes.leftButton}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  size="small"
                  variant="contained"
                  //disabled={isSubmitting || Object.keys(errors).length > 0}
                  onClick={(e: any) => handleSubmit(e)}
                >
                  {subTask?.id ? 'Update SubTask' : 'Add SubTask'}
                </Button>
              </CardActions>
            </>
          )}
        </Formik>
      </Card>
    );
  }
