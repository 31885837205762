import { TableCell, TableRow, Tooltip, Grid } from '@material-ui/core';
import ListAlt from '@material-ui/icons/ListAlt';
import * as Sentry from '@sentry/browser';
import React, { useEffect, useState } from 'react';
import API from '../services/ApiService';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import DoneIcon from '@material-ui/icons/Done';
import { showErrorResultBar } from '../components/ResultSnackbar';
import SpioDataTable from '../components/SpioDataTable';
import MetricDetails from '../components/MetricDetails';
import { MetricTableSelectedRowsToolbar } from '../components/MetricTableSelectedRowsToolbar';
import { formatDate } from '../helpers';

import AirEmissionsIcon from '../images/metric_topic_icons/air_emissions.svg';
import EnergyManagementIcon from '../images/metric_topic_icons/energy_management.svg';
import FleetFuelIcon from '../images/metric_topic_icons/fleet_fuel.svg';
import GreenHouseGasIcon from '../images/metric_topic_icons/greenhouse_gas_emissions.svg';
import WasteIcon from '../images/metric_topic_icons/waste_and_hazardous_materials.svg';
import WaterIcon from '../images/metric_topic_icons/water_management.svg';

export const UNASSIGNED_DATA_STR = '(blank)';

const TOPIC_ICONS_MAP = {
  'Air Emissions': <AirEmissionsIcon />,
  'Energy Management': <EnergyManagementIcon />,
  'Fleet Fuel Management': <FleetFuelIcon />,
  'Greenhouse Gas Emissions': <GreenHouseGasIcon />,
  'Waste & Hazardous Materials Management': <WasteIcon />,
  'Water Management': <WaterIcon />,
};

const getTableColumns = (tableData) => [
  {
    name: 'tracked',
    label: 'Track Status',
    options: {
      customFilterListOptions: {
        render: (v) => (v ? 'Tracked' : 'Untracked'),
      },
      customBodyRenderLite: (dataIndex) => {
        return tableData[dataIndex]?.tracked ? (
          <DoneIcon color="primary" style={{ marginLeft: '1.2rem' }} />
        ) : null;
      },
    },
  },
  {
    name: 'id',
    label: 'ID',
    options: {
      display: 'false',
      filter: false,
    },
  },
  {
    name: 'description',
    label: 'Metric Name',
    options: {
      filter: false,
    },
  },
  {
    name: 'industries',
    label: 'Industry',
    options: {
      display: 'false',
      filter: true,
    },
  },
  {
    name: 'sectors',
    label: 'Sector',
    options: {
      display: 'false',
      filter: true,
      customBodyRenderLite: (dataIndex) => {
        const statusStr = tableData[dataIndex]?.sectors || '';
        //  remove duplicate sectors
        let uniqueSectors = [...new Set(statusStr)];
        return uniqueSectors;
      },
    },
  },
  {
    name: 'topic',
    label: 'Topic',
    options: {
      filter: false,
      // customFilterListOptions: {
      //   render: v => `Topic: ${v}`,
      // },
      customBodyRenderLite: (dataIndex) => {
        const statusStr = tableData[dataIndex]?.topics || '';
        //  remove duplicate topics
        let uniqueTopics = [...new Set(statusStr)];
        return (
          <Grid container wrap="nowrap">
            {uniqueTopics.map((topic, index) => {
              if (TOPIC_ICONS_MAP[topic]) {
                return (
                  <Tooltip title={topic} placement="top" key={index}>
                    <img
                      src={TOPIC_ICONS_MAP[topic].type}
                      alt={topic}
                      style={{ width: '40px', marginRight: '1.2rem' }}
                    />
                  </Tooltip>
                );
              }
              return null;
            })}
          </Grid>
        );
      },
    },
  },
  // {
  //   name: 'topics',
  //   label: 'Topic',
  //   options: {
  //     display: 'false',
  //     filter: true,
  //   },
  // },
  {
    name: 'standards',
    label: 'Standards',
    options: {
      filter: true,
    },
  },
];

function TheMetricTrackingPage({ auth, history }) {
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [metrics, setMetrics] = useState([]);
  const [rowsSelected, setRowsSelected] = useState([]);

  useEffect(() => {
    setIsLoading(true);

    API.get('org-metric/allMetrics')
      .then((res) => {
        setMetrics(res.data?.data ?? []);
      })
      .catch((err) => {
        showErrorResultBar('Unexpected error loading metrics');
        Sentry.captureException(err);
      })
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    setTableData(
      metrics.map((d) =>
        Object({
          ...d,
          dueDateStr: formatDate(d.dueDate),
          statusUpdatedAtStr: formatDate(d.statusUpdatedAt),
          industries: d.categories
            ? d.categories.map((category) => category.industry_name).sort()
            : [],
          sectors: d.categories
            ? d.categories.map((category) => category.sector_name + ' ').sort()
            : [],
          topics: d.categories
            ? d.categories.map((category) => category.topic_name).sort()
            : [],
          standards: d.standards
            ? d.standards.map((standard) => standard.abbreviation).sort()
            : [],
        })
      )
    );
  }, [metrics]);

  const handleUpdateMetrics = async (idxs, updatedMetricsInfo) => {
    const dataCopy = metrics.slice();
    idxs.forEach((idx) => {
      dataCopy[idx] = {
        ...dataCopy[idx],
        ...updatedMetricsInfo,
      };
    });
    setMetrics(dataCopy);
    setRowsSelected([]);
  };

  return (
    <>
      <SpioDataTable
        title="Metric Tracking"
        columns={getTableColumns(tableData)}
        data={tableData}
        options={{
          print: false,
          filterType: 'multiselect',
          rowsSelected,
          selectableRows: 'multiple',
          selectableRowsHeader: !isLoading,
          onRowSelectionChange: (_, allRowsSelected) => {
            setRowsSelected(allRowsSelected.map((row) => row.dataIndex));
          },
          customToolbarSelect: (selectedRows) => (
            <MetricTableSelectedRowsToolbar
              onCancel={() => setRowsSelected([])}
              onUpdate={handleUpdateMetrics}
              selectedRows={selectedRows}
              metrics={metrics}
              auth={auth}
            />
          ),
          textLabels: {
            body: {
              noMatch: isLoading ? 'Loading...' : 'No records found',
              toolTip: 'Sort',
            },
          },
          expandableRows: true,
          expandableRowsOnClick: true,
          renderExpandableRow: (rowData, rowMeta) => {
            const colSpan = rowData.length + 1;
            const myData = metrics[rowMeta.dataIndex];
            const fieldData = metrics[rowMeta.dataIndex]?.fields;

            return myData ? (
              <TableRow>
                <TableCell colSpan={colSpan}>
                  <MetricDetails metricData={myData} history={history} fieldData={fieldData} auth={auth}/>
                </TableCell>
              </TableRow>
            ) : null;
          },
        }}
      />
    </>
  );
}

TheMetricTrackingPage.icon = <ListAlt />;
TheMetricTrackingPage.requiredAuthZ = {
  tier: 3,
  permission: 'esg_metrics',
};
TheMetricTrackingPage.routePath = '/metricTracking';
TheMetricTrackingPage.title = 'Metric Tracking';

export default TheMetricTrackingPage;
