import { Button, Card, CardHeader, FormControlLabel, Switch } from '@material-ui/core';
import classNames from 'classnames';
import { sortedUniq } from 'lodash';
import moment from 'moment';
import { MUIDataTableTextLabels } from 'mui-datatables';
import React, { useEffect, useState } from 'react';
import { GoogleRole, UserAuditMemberStatus } from '../../../../backend/src/user-audit/enums';
import { IGoogleMember, IGoogleMemberInfo, IGoogleOrg } from '../../../../backend/src/user-audit/interfaces';
import { formatDate } from '../../helpers';
import SpioDataTable, { SpioDataTableColumn } from '../SpioDataTable';
import {
  BOOLEAN_FILTER_SELECTIONS,
  boolToString,
  DiffTableCell,
  diffTableCellDownload,
  diffTableCellSortCompare,
  filterLogicStringArray,
  filterLogicStringDoubleArray,
  getOrgAuditStatus,
  getTableSubtitleText,
  nameArrayDownload,
  NameArrayDetails,
  StatusCell,
  statusDownload,
  useUserAuditTableStyles,
} from './UserAuditTableHelpers';

export class IGoogleFlattenedMember {
  public static fromOrgMember(orgMember: IGoogleMember) {
    return new IGoogleFlattenedMember(orgMember);
  }

  archived: (string | null | undefined)[] = [];
  email: string[] = [];
  lastLoginTime: string;
  id: string;
  isEnforcedIn2Sv: (string | null | undefined)[] = [];
  isEnrolledIn2Sv: (string | null | undefined)[] = [];
  name: string[] = [];
  orgUnitPath: (string | null | undefined)[] = [];
  role: GoogleRole[] = [];
  roleAssignments: string[][] = [];
  status: UserAuditMemberStatus;
  suspended: (string | null | undefined)[] = [];

  protected constructor (orgMember: IGoogleMember) {
    this.id = orgMember.member_id;
    this.lastLoginTime = orgMember.data?.lastLoginTime || orgMember.prev_data?.lastLoginTime || '';
    this.status = orgMember.status;
    this.pushFlattenedData(orgMember.data);
    this.pushFlattenedData(orgMember.prev_data);
  }

  // Data for which we want diffs displayed:
  protected pushFlattenedData(data: IGoogleMemberInfo | null = null) {
    if (data) {
      this.archived.push(boolToString(data.archived));
      this.email.push(data.email || '');
      this.isEnforcedIn2Sv.push(boolToString(data.isEnforcedIn2Sv));
      this.isEnrolledIn2Sv.push(boolToString(data.isEnrolledIn2Sv));
      this.name.push(data.name || '');
      this.orgUnitPath.push(data.orgUnitPath);
      this.role.push(data.role || '');
      this.roleAssignments.push(data.roleAssignments || []);
      this.suspended.push(boolToString(data.suspended));
    }
  }
}

interface DiffTableCellRoleAssignmentsProps {
  roleAssignments: string[][];
  isModified: boolean;
  toShowLabel: boolean;
  toShowNames: boolean;
}

function DiffTableCellRoleAssignments({ roleAssignments, isModified, toShowLabel, toShowNames }: DiffTableCellRoleAssignmentsProps) {
  const currRoleAssignments = (roleAssignments[0] || []).sort();
  const prevRoleAssignments = (roleAssignments[1] || []).sort();

  return (
    <div>
      <NameArrayDetails
        label="Role Assignments"
        isModified={isModified}
        currNames={currRoleAssignments}
        prevNames={prevRoleAssignments}
        toShowLabel={toShowLabel}
        toShowNames={toShowNames}
      />
    </div>
  );
}

const getAuditTableColumns = (
  allEmails: string[],
  allNames: string[],
  allRoles: string[],
  allRoleAssignments: string[],
  tableData: IGoogleFlattenedMember[],
  toExpandDetails: boolean,
): SpioDataTableColumn[] => {
  return [
    {
      name: 'status',
      label: 'Changed',
      options: {
        customBodyRenderLite: dataIndex => <StatusCell status={tableData[dataIndex]?.status} />,
        customDownloadRender: dataIndex => statusDownload(tableData[dataIndex]?.status),
        filter: false,
        searchable: false,
      },
    },
    {
      name: 'id',
      label: 'User Id',
      options: {
        customFilterListOptions: { render: (v: string) => `User Id: ${v}` },
        display: 'false',
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const member = tableData[dataIndex];

          return member && <DiffTableCell values={member.name} isModified={member.status === 'modified'}/>;
        },
        customDownloadRender: dataIndex => diffTableCellDownload(tableData[dataIndex]?.name, tableData[dataIndex]?.status === 'modified'),
        customFilterListOptions: { render: (v: string) => `Name: ${v}` },
        filterOptions: {
          names: allNames,
          logic: filterLogicStringArray,
        },
        sortCompare: diffTableCellSortCompare,
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const member = tableData[dataIndex];

          return member && <DiffTableCell values={member.email} isModified={member.status === 'modified'}/>;
        },
        customDownloadRender: dataIndex => diffTableCellDownload(tableData[dataIndex]?.email, tableData[dataIndex]?.status === 'modified'),
        customFilterListOptions: { render: (v: string) => `Email: ${v}` },
        filterOptions: {
          names: allEmails,
          logic: filterLogicStringArray,
        },
        sortCompare: diffTableCellSortCompare,
      },
    },
    {
      name: 'role',
      label: 'Role',
      options: {
        customFilterListOptions: { render: (v: string) => `Role: ${v}` },
        filterOptions: {
          names: allRoles,
          logic: filterLogicStringArray,
        },
        customBodyRenderLite: (dataIndex) => {
          const member = tableData[dataIndex];
          if (!member) {
            return null;
          }

          const { role, roleAssignments, status } = member;
          const toShowLabel = role[0] === 'Delegated Admin' || role[1] === 'Delegated Admin';

          return <>
            <DiffTableCell values={role} isModified={status === 'modified'}/>
            <DiffTableCellRoleAssignments
              roleAssignments={roleAssignments}
              isModified={status === 'modified'}
              toShowLabel={toShowLabel || toExpandDetails}
              toShowNames={toExpandDetails}
            />
          </>;
        },
        customDownloadRender: dataIndex => diffTableCellDownload(tableData[dataIndex]?.role, tableData[dataIndex]?.status === 'modified'),
        sortCompare: diffTableCellSortCompare,
      },
    },
    {
      name: 'roleAssignments',
      label: 'Role Assignments',
      options: {
        customDownloadRender: (dataIndex) => {
          const member = tableData[dataIndex];
          const roleAssignments = member?.roleAssignments || [];

          return nameArrayDownload(member.status === 'modified', roleAssignments[0], roleAssignments[1]);
        },
        customFilterListOptions: { render: (v: string) => `Role Assignment: ${v}` },
        display: 'excluded',
        download: true,
        filterOptions: {
          names: allRoleAssignments,
          logic: filterLogicStringDoubleArray,
        },
        viewColumns: false,
      },
    },
    {
      name: 'lastLoginTime',
      label: 'Last Login',
      options: {
        customBodyRenderLite: dataIndex => formatDate(tableData[dataIndex]?.lastLoginTime),
        customDownloadRender: dataIndex => formatDate(tableData[dataIndex]?.lastLoginTime) ?? '',
        filter: false,
      },
    },
    {
      name: 'orgUnitPath',
      label: 'OU',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const member = tableData[dataIndex];

          return member && <DiffTableCell values={member.orgUnitPath} isModified={member.status === 'modified'}/>;
        },
        customDownloadRender: dataIndex => diffTableCellDownload(tableData[dataIndex]?.orgUnitPath, tableData[dataIndex]?.status === 'modified'),
        customFilterListOptions: { render: (v: string) => `OU: ${v}` },
        sortCompare: diffTableCellSortCompare,
      },
    },
    {
      name: 'isEnrolledIn2Sv',
      label: 'MFA Enabled?',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const member = tableData[dataIndex];

          return member && <DiffTableCell values={member.isEnrolledIn2Sv} isModified={member.status === 'modified'}/>;
        },
        customDownloadRender: dataIndex => diffTableCellDownload(tableData[dataIndex]?.isEnrolledIn2Sv, tableData[dataIndex]?.status === 'modified'),
        customFilterListOptions: { render: (v: string) => `MFA Enabled: ${v}` },
        filterOptions: {
          names: BOOLEAN_FILTER_SELECTIONS,
        },
        sortCompare: diffTableCellSortCompare,
      },
    },
    {
      name: 'isEnforcedIn2Sv',
      label: 'MFA Enforced?',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const member = tableData[dataIndex];

          return member && <DiffTableCell values={member.isEnforcedIn2Sv} isModified={member.status === 'modified'}/>;
        },
        customDownloadRender: dataIndex => diffTableCellDownload(tableData[dataIndex]?.isEnforcedIn2Sv, tableData[dataIndex]?.status === 'modified'),
        customFilterListOptions: { render: (v: string) => `MFA Enforced: ${v}` },
        filterOptions: {
          names: BOOLEAN_FILTER_SELECTIONS,
        },
        sortCompare: diffTableCellSortCompare,
      },
    },
    {
      name: 'suspended',
      label: 'Suspended?',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const member = tableData[dataIndex];

          return member && <DiffTableCell values={member.suspended} isModified={member.status === 'modified'}/>;
        },
        customDownloadRender: dataIndex => diffTableCellDownload(tableData[dataIndex]?.suspended, tableData[dataIndex]?.status === 'modified'),
        customFilterListOptions: { render: (v: string) => `Suspended: ${v}` },
        filterOptions: {
          names: BOOLEAN_FILTER_SELECTIONS,
        },
        sortCompare: diffTableCellSortCompare,
      },
    },
    {
      name: 'archived',
      label: 'Archived?',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const member = tableData[dataIndex];

          return member && <DiffTableCell values={member.archived} isModified={member.status === 'modified'}/>;
        },
        customDownloadRender: dataIndex => diffTableCellDownload(tableData[dataIndex]?.archived, tableData[dataIndex]?.status === 'modified'),
        customFilterListOptions: { render: (v: string) => `Archived: ${v}` },
        filterOptions: {
          names: BOOLEAN_FILTER_SELECTIONS,
        },
        sortCompare: diffTableCellSortCompare,
      },
    },
  ];
};

interface UserAuditGoogleTableProps {
  handleConfirmSelected: () => Promise<void>;
  isLoading: boolean;
  orgs: IGoogleOrg[];
  selectedOrgIdx: number;
}

export default function UserAuditGoogleTable(props: UserAuditGoogleTableProps) {
  const classes = useUserAuditTableStyles();
  const { handleConfirmSelected, isLoading, orgs, selectedOrgIdx } = props;

  const [ isDiffView, setIsDiffView ] = useState<boolean>(false);
  const [ selectedOrg, setSelectedOrg ] = useState<IGoogleOrg>();
  const [ orgsMembers, setOrgsMembers ] = useState<IGoogleFlattenedMember[][]>([]);
  const [ membersToDisplay, setMembersToDisplay ] = useState<IGoogleFlattenedMember[]>([]);
  const [ toExpandDetails, setToExpandDetails ] = useState<boolean>(false);

  const [ allEmails, setAllEmails ] = useState<string[]>([]);
  const [ allNames, setAllNames ] = useState<string[]>([]);
  const [ allRoles, setAllRoles ] = useState<string[]>([]);
  const [ allRoleAssignments, setAllRoleAssignments ] = useState<string[]>([]);

  useEffect(() => {
    setSelectedOrg(orgs[selectedOrgIdx]);
  }, [ orgs, selectedOrgIdx ]);

  useEffect(() => {
    const orgsFlattenedMembers = orgs.map((org) => {
      const latestSync = org.syncs[0];
      const orgMembers: IGoogleMember[] = latestSync ? latestSync.membersDiffData : [];

      return orgMembers.map(IGoogleFlattenedMember.fromOrgMember);
    });

    setOrgsMembers(orgsFlattenedMembers);
  }, [ orgs ]);

  // Pre-filters before sending the data to the table: diff view.
  // (We don't want chips for these.)
  useEffect(() => {
    let orgMembers = orgsMembers[selectedOrgIdx] || [];

    if (isDiffView) {
      orgMembers = orgMembers.filter(orgMember => orgMember.status !== 'unchanged');
    }

    setMembersToDisplay(orgMembers);
  }, [ orgsMembers, isDiffView, selectedOrgIdx ]);

  // Consolidate the names, etc for display in the filters.
  // (Each user may have e.g. two names: a current name and a previous name. We want both in the filter.)
  useEffect(() => {
    const emailsWithDuplicates = membersToDisplay.flatMap(member => member.email ? member.email : []).sort();
    const emails = sortedUniq(emailsWithDuplicates);
    setAllEmails(emails);

    const namesWithDuplicates = membersToDisplay.flatMap(member => member.name ? member.name : []).sort();
    const names = sortedUniq(namesWithDuplicates);
    setAllNames(names);

    const rolesWithDuplicates = membersToDisplay.flatMap(member => member.role ? member.role : []).sort();
    const roles = sortedUniq(rolesWithDuplicates);
    setAllRoles(roles);

    const roleAssignmentsWithDuplicates = membersToDisplay.flatMap(member => member.roleAssignments ? member.roleAssignments.flat() : []).sort();
    const roleAssignments = sortedUniq(roleAssignmentsWithDuplicates);
    setAllRoleAssignments(roleAssignments);
  }, [ membersToDisplay ]);

  const clickToggleDiffView = () => {
    setIsDiffView(!isDiffView);
  };

  const clickToggleToShowDetails = () => {
    setToExpandDetails(!toExpandDetails);
  };

  const clickConfirmSelected = async () => {
    handleConfirmSelected();
  };

  const TableTitle = () => {
    if (isLoading) {
      return null;
    }

    return (
      <div>
        {getOrgAuditStatus(selectedOrg) === 'pending' && (
          <FormControlLabel
            control={
              <Switch
                checked={isDiffView}
                onChange={clickToggleDiffView}
              />
            }
            label="Only show changed users"
          />
        )}
        {allRoleAssignments.length > 0 && (
          <FormControlLabel
            control={
              <Switch
                checked={toExpandDetails}
                onChange={clickToggleToShowDetails}
              />
            }
            label="Expand details"
          />
        )}
      </div>
    );
  };

  return (
    <Card
      className={classes.tableCard}
      variant="outlined"
    >
      <CardHeader
        classes={{
          action: classes.tableCardHeaderAction,
        }}
        action={getOrgAuditStatus(selectedOrg) === 'pending' && (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={clickConfirmSelected}
            disabled={isLoading}
          >
            Confirm
          </Button>
        )}
        title={selectedOrg && `${selectedOrg.name} Members`}
        subheader={getTableSubtitleText(selectedOrg)}
      />
      <SpioDataTable
        className={classes.dataTable}
        title={<TableTitle />}
        columns={getAuditTableColumns(allEmails, allNames, allRoles, allRoleAssignments, membersToDisplay, toExpandDetails)}
        data={membersToDisplay || []}
        options={{
          elevation: 1,
          textLabels: {
            body: {
              noMatch: isLoading ? 'Loading...' : (isDiffView ? 'No changes' : 'No users found'),
            },
          } as MUIDataTableTextLabels,
          downloadHeader: [
            [ selectedOrg?.name ?? '' ],
            [ getTableSubtitleText(selectedOrg) ],
            [],
          ],
          downloadOptions: {
            filename: `UserAudit_GoogleGSuite_${moment().format('YYYYMMDD')}.csv`,
          },
          filterType: 'multiselect',
          print: false,
          selectableRows: 'none',
          sort: true,
          sortOrder: {
            name: 'name',
            direction: 'asc',
          },
          setRowProps: (row, index) => {
            const status = membersToDisplay[index]?.status;

            return {
              className: classNames(
                status === 'added' && classes.newUserRow,
                status === 'deleted' && classes.deletedUserRow,
                ),
            };
          },
        }}
      />
    </Card>
  );
}
