import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FormikProps, FormikValues } from 'formik';
import React from 'react';

const useStyles = makeStyles({
  radioFormControl: {
    marginTop: '0.5rem',
    marginRight: 0,
    marginLeft: 0,
  },
  radioFormLabel: {
    lineHeight: 1.5,
  },
  radioSelections: {
    paddingLeft: '25px',
  },
});

interface FormikRadioFieldProps<Values extends FormikValues> {
  field: keyof Values;
  formikProps: FormikProps<Values>;
  label: string;
  selections: { [key: string]: string };
}

export default function FormikRadioField<Values extends FormikValues>(
  props: FormikRadioFieldProps<Values>
) {
  const classes = useStyles();
  const { field, formikProps, label, selections } = props;
  const { values, handleChange, handleBlur } = formikProps;
  const fieldStr = field.toString();
  return (
    <FormControl className={classes.radioFormControl}>
      <FormLabel className={classes.radioFormLabel}>{label}</FormLabel>
      <RadioGroup
        className={classes.radioSelections}
        id={fieldStr}
        name={fieldStr}
        value={values[field].toString()}
        onBlur={handleBlur}
        onChange={handleChange}
      >
        {Object.entries(selections).map(([key, radioLabel]) => (
          <FormControlLabel
            key={key}
            control={<Radio />}
            label={radioLabel}
            value={key}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
