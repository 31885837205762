import { Button, DialogActions, DialogTitle, Link, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import * as Sentry from '@sentry/browser';
import { history } from 'prosemirror-history';
import { EditorState, Selection, AllSelection } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import React, { ChangeEvent, MouseEvent, useRef, useEffect, useReducer, useState } from 'react';
import { showErrorResultBar } from '../../ResultSnackbar';
import ResponsiveDialog from '../../dialogs/ResponsiveDialog';
import StyledDialogTitle from '../../StyledDialogTitle';
import { getKeyCommands } from '../lib/keyCommands';
import { getMarkdownParser } from '../lib/markdown/parser';
import { getMarkdownSerializer } from '../lib/markdown/serializer';
import { getMarkdownMenuItems } from '../lib/markdownMenuItems';
import { schema as mySchema } from '../lib/schema';
import { EditorElement } from './EditorElement';
import { LinkEditor } from './LinkEditor';
import { MenuBar } from './MenuBar';

const useStyles = makeStyles({
  // Stylings that match DialogContent:
  editorContainer: {
    overflowY: 'auto',
    flex: '1 1 auto',
    padding: '16px',
    paddingTop: 0,
    margin: 0,
  },
  menuBarContainer: {
    padding: '6px 1rem',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    margin: 0,
  },
  toggleViewLink: {
    'color': 'inherit',
    'cursor': 'pointer',
    'textDecoration': 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  toggleViewLinkContainer: {
    marginRight: 'auto',
  },
});

const parser = getMarkdownParser(mySchema);
const serializer = getMarkdownSerializer();

const safeParse = (docContent: string) => {
  try {
    return parser.parse(docContent);
  } catch (err) {
    showErrorResultBar('Unexpected error when parsing the policy contents');
    Sentry.captureException(err);

    return parser.parse('');
  }
};

const createState = (docContent: string) => EditorState.create({
  doc: safeParse(docContent),
  schema: mySchema,
  plugins: [
    history(),
    getKeyCommands(mySchema),
  ],
});

export interface EditorDialogProps {
  isLoading: boolean;
  markdownContent: string;
  onClose: () => void;
  onSave: (newContent: string) => Promise<void>;
  open: boolean;
  title: string;
}

export function EditorDialog({ isLoading, markdownContent, onClose, onSave, open, title }: EditorDialogProps) {
  const classes = useStyles();
  const contentContainerRef = useRef<HTMLDivElement>(null);

  const [ , forceUpdate] = useReducer(x => x + 1, 0); // needed b/c the prosemirror state isn't watched by React
  const [ content, setContent ] = useState(markdownContent);
  const [ isMarkdownView, setIsMarkdownView ] = useState(false);
  const [ isAddingNewLink, setIsAddingNewLink ] = useState(false);
  const [ view ] = useState(
    new EditorView(undefined, {
      state: createState(markdownContent),
      dispatchTransaction: (transaction) => {
        const { state: newState } = view.state.applyTransaction(transaction);
        view.updateState(newState);
        forceUpdate();
        view.focus();
      },
   }),
  );

  // Initialization:
  useEffect(() => {
    setContent(markdownContent);
    // Need to explicitly create and assign a new state, else the history is retained across open/close of the dialog:
    view.updateState(createState(markdownContent));
  }, [ markdownContent, view ]);

  // Switching b/t Markdown and Editor view (and also the initialization):
  useEffect(() => {
    if (!isMarkdownView) {
      try {
        // The content has changed and we are entering the editor view (from Markdown view or upon initialization).

        const origDoc = view.state.doc;
        const newDoc = parser.parse(content).slice(0); // from the Markdown; this may throw

        // If the newDoc (from the Markdown edits) differs from the origDoc then explicitly replace
        // the origDoc with the newDoc via a transaction, to enter this change into the undo history.
        if (!origDoc.content.eq(newDoc.content)) {
          const selectEntireDoc = new AllSelection(origDoc);
          view.dispatch(view.state.tr.setSelection(selectEntireDoc).replaceSelection(newDoc));
        }

        // Set the cursor to the beginning of the doc.
        const selectBegDoc = Selection.atStart(view.state.doc);
        view.dispatch(view.state.tr.setSelection(selectBegDoc));
      } catch (err) {
        showErrorResultBar('Unexpected error when parsing the policy contents');
        Sentry.captureException(err);
      }
    }
  }, [ content, isMarkdownView, view ]);

  const handleClickToggleViewLink = (e: MouseEvent) => {
    e.preventDefault();
    if (!isMarkdownView) {
      setContent(getMarkdownFromEditor());
    }
    setIsMarkdownView(!isMarkdownView);
  };

  const getMarkdownFromEditor = () => {
    return serializer.serialize(view.state.doc);
  };

  const handleClickSave = async () => {
    await onSave(isMarkdownView ? content : getMarkdownFromEditor());
  };

  return (
    <ResponsiveDialog
      disableBackdropClick
      disableEnforceFocus // necessary to be able to focus on the link editor input
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
    >
      {/* The main dialog title: */}
      <StyledDialogTitle onClose={onClose}>
        {title}
      </StyledDialogTitle>

      {/* The editor bar, if we're in editing view: */}
      {!isMarkdownView && (
        <DialogTitle
          className={classes.menuBarContainer}
          disableTypography
        >
          <MenuBar
            menu={getMarkdownMenuItems(mySchema)}
            view={view}
            onAddLink={() => setIsAddingNewLink(true)}
          />
        </DialogTitle>
      )}

      {/* What would normally be the 'DialogContent', here we use a div so we can grab its ref: */}
      <div
        ref={contentContainerRef}
        className={classes.editorContainer}
      >
        {isMarkdownView ? (
          // When in MarkdownView, we just use a textarea input for the content:
          <TextField
            className="spio-md-editor"
            multiline
            value={content}
            variant="outlined"
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setContent(e.target.value)}
          />
        ) : (
          <>
            {/* The LinkEditor is a Dialog/Popper that appears near the selected link: */}
            <LinkEditor
              container={contentContainerRef.current}
              isAddingNewLink={isAddingNewLink}
              onCancelAddNewLink={() => setIsAddingNewLink(false)}
              view={view}
            />
            {/* The container that holds the ProseMirror view: */}
            <EditorElement view={view} />
          </>
        )}
      </div>

      <DialogActions>
        {/* The toggle b/t Markdown and Editor view: */}
        <Typography
          className={classes.toggleViewLinkContainer}
          variant="subtitle2"
        >
          <Link
            className={classes.toggleViewLink}
            onClick={handleClickToggleViewLink}
          >
            {isMarkdownView ? 'Switch to Editor' : 'Switch to Markdown'}
          </Link>
        </Typography>

        {/* The cancel/save buttons: */}
        <Button
          color="primary"
          size="small"
          disabled={isLoading}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          size="small"
          color="primary"
          disabled={isLoading}
          onClick={handleClickSave}
        >
          Save
        </Button>
      </DialogActions>
    </ResponsiveDialog>
  );
}
