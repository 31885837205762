import {
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { toggleMark } from 'prosemirror-commands';
import { EditorView } from 'prosemirror-view';
import React, { ChangeEvent, useState } from 'react';

const useStyles = makeStyles({
  card: {
    margin: 0,
    padding: 0,
    paddingRight: '0.5rem',
    paddingLeft: '0.5rem',
    minWidth: '450px',
  },
  editLinkCardContent: {
    paddingLeft: 0,
  },
});

export interface NewLinkDialogProps {
  isAddingNewLink: boolean;
  onCancelAddNewLink: () => void;
  view: EditorView;
}

export function NewLinkDialog({ isAddingNewLink, onCancelAddNewLink, view }: NewLinkDialogProps) {
  const classes = useStyles();
  const [ inputHref, setInputHref ] = useState('');
  const { dispatch, state } = view;

  const handleChangeInputLink = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setInputHref(e.target.value.trim());
  };

  const handleApplyNewLink = () => {
    const markType = state.schema.marks.link;
    toggleMark(markType, { href: inputHref })(state, dispatch);
    onCancelAddNewLink();
  };

  return (
    <Dialog open={isAddingNewLink} onClose={() => onCancelAddNewLink()}>
      <Card
        className={classes.card}
      >
        <CardContent
          className={classes.editLinkCardContent}
        >
          <TextField
            id="existing-text"
            value={state.doc.cut(state.selection.from, state.selection.to).textContent}
            fullWidth
            margin="dense"
            label="Text"
            variant="outlined"
            disabled
          />
          <TextField
            id="input-href"
            autoFocus
            value={inputHref}
            fullWidth
            margin="dense"
            label="Link"
            placeholder="Enter or paste a link"
            variant="outlined"
            onChange={handleChangeInputLink}
          />
        </CardContent>
        <CardActions>
          <Button
            color="primary"
            size="small"
            onClick={onCancelAddNewLink}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            size="small"
            variant="contained"
            onClick={handleApplyNewLink}
            disabled={!inputHref}
          >
            Apply
          </Button>
        </CardActions>
      </Card>
    </Dialog>
  );
}
