import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import ReactPlayer from 'react-player';
import { Slider, withStyles } from '@material-ui/core';
import * as moment from 'moment';
import screenfull from 'screenfull';
import Button from '@material-ui/core/Button';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import * as PropTypes from 'prop-types';
import VolumeDown from '@material-ui/icons/VolumeDown';
import VolumeUp from '@material-ui/icons/VolumeUp';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { formatSeconds } from '../helpers';
import Hidden from '@material-ui/core/Hidden';

const DEFAULT_VOLUME = 0.85;
const UPDATE_POLL_SEC = 5;

const styles = theme => ({
    playerWrapper: {
      height: '40vh',
    },

    reactPlayer: {
      background: theme.palette.background,
    },
  })
;

const PlayerButton = withStyles({
  root: {
    minWidth: 0,
    marginLeft: 0,
  },
})(Button);

class VideoPlayer extends Component {
  state = {
    inErrorRecovery: false,
    isInit: true,
    isPolling: false,
    lastPoll: moment(),
    light: false,
    maxPlayed: this.props.played || 0,
    played: this.props.played || 0,
    playedSeconds: 0,
    playing: true,
    volume: DEFAULT_VOLUME,
  };

  classes = this.props.classes;
  _isMounted = false;

  constructor(props) {
    super(props);
    this.logVideoStop = this.logVideoStop.bind(this);
  }

  onError = (error) => {
    let played;

    if (this.player && this.player.getDuration() !== 0) {
      played = this.player.getCurrentTime() / this.player.getDuration();
    } else {
      played = 0;
    }

    this.setState({
      inErrorRecovery: true,
      playing: false,
      played,
      wasPlaying: this.state.playing,
    });

    if (this.props.onError) {
      this.props.onError(error);
    }
  };

  onPlay = () => {
    this.setState({ playing: true });
  };

  onPause = () => {
    this.setState({ playing: false });
    this.logVideoUpdate();
  };

  onProgress = progressState => {
    const { played, playedSeconds } = progressState;
    const maxPlayed = Math.max(played, this.state.maxPlayed);

    if (this.state.playing && moment().diff(this.state.lastPoll, 'seconds') >= UPDATE_POLL_SEC) {
      this.logVideoUpdate();
    }

    this.setState({
      maxPlayed,
      played,
      playedSeconds,
    });
  };

  onReady = (player) => {
    this.player = player;
    // After the initial 'onReady', seek to the user's last location.
    // ('onReady' seems to also be called after every 'onSeek'.)
    if (this.state.isInit) {
      this.player.seekTo(this.state.played, 'fraction');
      this.setState({ isInit: false });
    } else if (this.state.inErrorRecovery) {
      this.player.seekTo(this.state.played, 'fraction');
      this.setState({ inErrorRecovery: false, playing: this.state.wasPlaying, wasPlaying: undefined });
    }
  };

  onClickSkipPrevious = () => {
    this.player.seekTo(0, 'fraction');
    this.setState({
      played: 0,
      playedSeconds: 0,
    });
  };

  onClickPlayPause = () => {
    this.setState({ playing: !this.state.playing });
  };

  onClickFullscreen = async () => {
    await screenfull.request(findDOMNode(this.player));
  };

  logVideoStop(event) {
    if (event) {
      event.preventDefault();
      event.returnValue = '';
    }

    this.logVideoUpdate();
  }

  logVideoUpdate() {
    if (!this.props.onUpdateVideo || this.state.isPolling) {
      return;
    }

    if (this._isMounted) {
      this.setState({ isPolling: true });
    }

    const updateInfo = {
      maxPlayed: this.state.maxPlayed,
      played: this.state.played,
    };

    this.props.onUpdateVideo(updateInfo)
      .then(() => {
        if (this._isMounted) {
          this.setState({ lastPoll: moment() });
        }
      })
      .finally(() => this._isMounted && this.setState({ isPolling: false }));
  }

  componentDidMount() {
    this._isMounted = true;
    window.addEventListener('beforeunload', this.logVideoStop);
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('beforeunload', this.logVideoStop);
    this.logVideoStop();
  }

  render() {
    const { playing, light } = this.state;

    return (
      <>
        <div className={this.classes.playerWrapper}>
          <ReactPlayer
            className={this.classes.reactPlayer}
            width="100%"
            height="100%"
            url={this.props.url}
            playing={playing}
            controls={false}
            light={light}
            loop={false}
            onReady={this.onReady}
            onPlay={this.onPlay}
            onProgress={this.onProgress}
            onPause={this.onPause}
            onError={this.onError}
            volume={this.state.volume}
          />
        </div>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={3}>
            <Grid container alignItems="center" justify="flex-start">
              <Grid item xs={3}><PlayerButton onClick={this.onClickSkipPrevious}
                                              disabled={this.state.played === 0}><SkipPreviousIcon /></PlayerButton></Grid>
              <Grid item xs={3}><PlayerButton onClick={this.onClickPlayPause}>{playing ? <PauseIcon /> :
                <PlayArrowIcon />}</PlayerButton></Grid>
              <Grid item xs={6} />
            </Grid>
          </Grid>

          <Grid item xs={6} md={5}>
            <Grid container alignItems="center">
              <Grid item>
                <Typography variant="body2">{formatSeconds(this.state.playedSeconds)}</Typography>
              </Grid>
              <Grid item xs>
                <Slider min={0} max={this.player && this.player.getDuration()} value={this.state.playedSeconds} />
              </Grid>
              <Grid item>
                <Typography variant="body2">{formatSeconds(this.player && this.player.getDuration())}</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={3} md={4}>
            <Grid container justify="space-around" alignItems="center">
              <Hidden smDown>
                <Grid item md={9}>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                      <VolumeDown />
                    </Grid>
                    <Grid item xs>
                      <Slider min={0} max={1} step={0.02} value={this.state.volume}
                              onChange={(_, newValue) => this.setState({ volume: newValue })}
                              aria-labelledby="continuous-slider" />
                    </Grid>
                    <Grid item>
                      <VolumeUp />
                    </Grid>
                  </Grid>
                </Grid>
              </Hidden>
              <Grid item xs={12} md={3}>
                <PlayerButton style={{ float: 'right' }}
                              onClick={this.onClickFullscreen}><FullscreenIcon /></PlayerButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}

VideoPlayer.propTypes = {
  onError: PropTypes.func,
  onUpdateVideo: PropTypes.func,
  classes: PropTypes.object,
  played: PropTypes.number,
  url: PropTypes.string.isRequired,
};

export default withStyles(styles, { withTheme: true })(VideoPlayer);
