import { Button, Card, CardHeader, FormControlLabel, Switch } from '@material-ui/core';
import classNames from 'classnames';
import { capitalize, sortedUniq, union } from 'lodash';
import moment from 'moment';
import { MUIDataTableTextLabels } from 'mui-datatables';
import React, { useEffect, useState } from 'react';
import { UserAuditMemberStatus } from '../../../../backend/src/user-audit/enums';
import {
  IGithubMember,
  IGithubMemberInfo,
  IGithubOrg,
  IGithubTeamsByRole,
} from '../../../../backend/src/user-audit/interfaces';
import SpioDataTable, { SpioDataTableColumn } from '../SpioDataTable';
import {
  BOOLEAN_FILTER_SELECTIONS,
  boolToString,
  DiffTableCell,
  diffTableCellDownload,
  diffTableCellSortCompare,
  filterLogicKeyedStringArrays,
  filterLogicStringArray,
  getOrgAuditStatus,
  getTableSubtitleText,
  NameArrayDetails,
  nameArrayDownload,
  StatusCell,
  statusDownload,
  useUserAuditTableStyles,
} from './UserAuditTableHelpers';

const roleMap = (rawGithubOrgRole: string) => {
  switch (rawGithubOrgRole) {
    case 'ADMIN':
      return 'Owner'; // To match GitHub's UI.
    case 'MEMBER':
      return 'Member';
    default:
      return capitalize(rawGithubOrgRole);
  }
};

export class IGithubFlattenedMember {
  public static fromOrgMember(orgMember: IGithubMember) {
    return new IGithubFlattenedMember(orgMember);
  }

  email: string[] = [];
  id: string;
  login: string[] = [];
  mfaEnabled: (string | null | undefined)[] = [];
  name: string[] = [];
  role: string[] = [];
  status: UserAuditMemberStatus;
  teams: IGithubTeamsByRole[] = [];

  protected constructor (orgMember: IGithubMember) {
    this.id = orgMember.member_id;
    this.status = orgMember.status;
    this.pushFlattenedData(orgMember.data);
    this.pushFlattenedData(orgMember.prev_data);
  }

  // Data for which we want diffs displayed:
  protected pushFlattenedData(data: IGithubMemberInfo | null = null) {
    if (data) {
      this.email.push(data.email || '');
      this.login.push(data.login || '');
      this.mfaEnabled.push(boolToString(data.mfaEnabled, 'N/Av'));
      this.name.push(data.name || '');
      this.role.push(data.role ? roleMap(data.role) : '');
      this.teams.push(data.teams || {});
    }
  }
}

const teamsByRoleToTeamsArray = (teamsByRole: IGithubTeamsByRole) => {
  return Object.entries(teamsByRole).flatMap(([role, teams]) => teams.map(team => `${team} (${capitalize(role)})`));
};

function DiffTableCellTeams({ teams, isModified, toShowTeamNames }: { teams: IGithubTeamsByRole[]; isModified: boolean; toShowTeamNames: boolean; }) {
  const currTeamsByRole = teams[0] || {};
  const prevTeamsByRole = teams[1] || {};

  const currRoles = Object.keys(currTeamsByRole).sort();
  const prevRoles = Object.keys(prevTeamsByRole).sort();

  const roles = isModified ? union(currRoles, prevRoles) : currRoles;

  return (
    <div>
      {roles.map((role) => (
        <div key={role}>
          <NameArrayDetails
            label={capitalize(role)}
            isModified={isModified}
            currNames={currTeamsByRole[role] || []}
            prevNames={prevTeamsByRole[role] || []}
            toShowNames={toShowTeamNames}
          />
        </div>
      ))}
    </div>
  );
}

const getAuditTableColumns = (
  allLogins: string[],
  allRoles: string[],
  allNames: string[],
  allEmails: string[],
  allTeams: string[],
  tableData: IGithubFlattenedMember[],
  toExpandDetails: boolean,
): SpioDataTableColumn[] => {
  return [
    {
      name: 'status',
      label: 'Changed',
      options: {
        customBodyRenderLite: dataIndex => <StatusCell status={tableData[dataIndex]?.status} />,
        customDownloadRender: dataIndex => statusDownload(tableData[dataIndex]?.status),
        filter: false,
        searchable: false,
      },
    },
    {
      name: 'id',
      label: 'Id',
      options: {
        customFilterListOptions: { render: (v: string) => `Id: ${v}` },
        display: 'false',
      },
    },
    {
      name: 'login',
      label: 'User',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const member = tableData[dataIndex];

          return member && <DiffTableCell values={member.login} isModified={member.status === 'modified'}/>;
        },
        customDownloadRender: dataIndex => diffTableCellDownload(tableData[dataIndex]?.login, tableData[dataIndex]?.status === 'modified'),
        customFilterListOptions: { render: (v: string) => `User: ${v}` },
        filterOptions: {
          names: allLogins,
          logic: filterLogicStringArray,
        },
        sortCompare: diffTableCellSortCompare,
      },
    },
    {
      name: 'role',
      label: 'Role',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const member = tableData[dataIndex];

          return member && <DiffTableCell values={member.role} isModified={member.status === 'modified'}/>;
        },
        customDownloadRender: dataIndex => diffTableCellDownload(tableData[dataIndex]?.role, tableData[dataIndex]?.status === 'modified'),
        customFilterListOptions: { render: (v: string) => `Role: ${v}` },
        filterOptions: {
          names: allRoles,
          logic: filterLogicStringArray,
        },
        sortCompare: diffTableCellSortCompare,
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        display: 'excluded',
        customDownloadRender: dataIndex => diffTableCellDownload(tableData[dataIndex]?.name, tableData[dataIndex]?.status === 'modified'),
        customFilterListOptions: { render: (v: string) => `Name: ${v}` },
        filterOptions: {
          names: allNames,
          logic: filterLogicStringArray,
        },
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        display: 'excluded',
        customDownloadRender: dataIndex => diffTableCellDownload(tableData[dataIndex]?.email, tableData[dataIndex]?.status === 'modified'),
        customFilterListOptions: { render: (v: string) => `Email: ${v}` },
        filterOptions: {
          names: allEmails,
          logic: filterLogicStringArray,
        },
      },
    },
    {
      name: 'info',
      label: 'Info',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const member = tableData[dataIndex];
          const isModified = member?.status === 'modified';

          return member && <>
            <DiffTableCell values={member.name} isModified={isModified} />
            <DiffTableCell values={member.email} isModified={isModified} />
          </>;
        },
        download: false,
        filter: false,
        searchable: false,
        sort: false,
      },
    },
    {
      name: 'mfaEnabled',
      label: 'MFA Enabled?',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const member = tableData[dataIndex];

          return member && <DiffTableCell values={member.mfaEnabled} isModified={member.status === 'modified'}/>;
        },
        customDownloadRender: dataIndex => diffTableCellDownload(tableData[dataIndex]?.mfaEnabled, tableData[dataIndex]?.status === 'modified'),
        customFilterListOptions: { render: (v: string) => `MFA Enabled: ${v}` },
        filterOptions: {
          names: BOOLEAN_FILTER_SELECTIONS,
        },
        sortCompare: diffTableCellSortCompare,
      },
    },
    {
      name: 'teams',
      label: 'Teams',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const member = tableData[dataIndex];

          return member && (
            <DiffTableCellTeams
              teams={member.teams}
              isModified={member.status === 'modified'}
              toShowTeamNames={toExpandDetails}
            />
          );
        },
        customDownloadRender: (dataIndex) => {
          const member = tableData[dataIndex];
          const teams = member?.teams || [];
          const currTeamsByRole = teams[0] || {};
          const prevTeamsByRole = teams[1] || {};

          return nameArrayDownload(member.status === 'modified', teamsByRoleToTeamsArray(currTeamsByRole), teamsByRoleToTeamsArray(prevTeamsByRole));
        },
        customFilterListOptions: { render: (v: string) => `Team: ${v}` },
        filterOptions: {
          names: allTeams,
          logic: filterLogicKeyedStringArrays,
        },
        sort: false,
      },
    },
  ];
};

interface UserAuditGithubTableProps {
  handleConfirmSelected: () => Promise<void>;
  isLoading: boolean;
  orgs: IGithubOrg[];
  selectedOrgIdx: number;
}

export default function UserAuditGithubTable(props: UserAuditGithubTableProps) {
  const classes = useUserAuditTableStyles();
  const { handleConfirmSelected, isLoading, orgs, selectedOrgIdx } = props;

  const [ isDiffView, setIsDiffView ] = useState<boolean>(false);
  const [ selectedOrg, setSelectedOrg ] = useState<IGithubOrg>();
  const [ orgsMembers, setOrgsMembers ] = useState<IGithubFlattenedMember[][]>([]);
  const [ membersToDisplay, setMembersToDisplay ] = useState<IGithubFlattenedMember[]>([]);
  const [ toExpandDetails, setToShowTeamNames ] = useState<boolean>(false);

  const [ allEmails, setAllEmails ] = useState<string[]>([]);
  const [ allLogins, setAllLogins ] = useState<string[]>([]);
  const [ allNames, setAllNames ] = useState<string[]>([]);
  const [ allRoles, setAllRoles ] = useState<string[]>([]);
  const [ allTeams, setAllTeams ] = useState<string[]>([]);

  useEffect(() => {
    setSelectedOrg(orgs[selectedOrgIdx]);
  }, [orgs, selectedOrgIdx]);

  useEffect(() => {
    const orgsFlattenedMembers = orgs.map((org) => {
      const latestSync = org.syncs[0];
      const orgMembers: IGithubMember[] = latestSync ? latestSync.membersDiffData : [];

      return orgMembers.map(IGithubFlattenedMember.fromOrgMember);
    });

    setOrgsMembers(orgsFlattenedMembers);
  }, [orgs]);

  // Pre-filters before sending the data to the table: diff view.
  // (We don't want chips for these.)
  useEffect(() => {
    let orgMembers = orgsMembers[selectedOrgIdx] || [];

    if (isDiffView) {
      orgMembers = orgMembers.filter(orgMember => orgMember.status !== 'unchanged');
    }

    setMembersToDisplay(orgMembers);
  }, [orgsMembers, isDiffView, selectedOrgIdx]);

  // Consolidate the names, etc for display in the filter.
  // (Each user may have e.g. two names: a current name and a previous name. We want both in the filter.)
  useEffect(() => {
    const emailsWithDuplicates = membersToDisplay.flatMap(member => member.email ? member.email : []).sort();
    const emails = sortedUniq(emailsWithDuplicates);
    setAllEmails(emails);

    const loginsWithDuplicates = membersToDisplay.flatMap(member => member.login ? member.login : []).sort();
    const logins = sortedUniq(loginsWithDuplicates);
    setAllLogins(logins);

    const namesWithDuplicates = membersToDisplay.flatMap(member => member.name ? member.name : []).sort();
    const names = sortedUniq(namesWithDuplicates);
    setAllNames(names);

    const rolesWithDuplicates = membersToDisplay.flatMap(member => member.role ? member.role : []).sort();
    const roles = sortedUniq(rolesWithDuplicates);
    setAllRoles(roles);

    const teamsWithDuplicates = membersToDisplay.flatMap((member) => {
      if (member.teams) {
        return member.teams.flatMap(teamsByRole => Object.values(teamsByRole).flat());
      } else {
        return [];
      }
    }).sort();
    const teams = sortedUniq(teamsWithDuplicates);
    setAllTeams(teams);

  }, [membersToDisplay]);

  const clickToggleDiffView = () => {
    setIsDiffView(!isDiffView);
  };

  const clickToggleToShowTeamNames = () => {
    setToShowTeamNames(!toExpandDetails);
  };

  const clickConfirmSelected = async () => {
    handleConfirmSelected();
  };

  const TableTitle = () => {
    if (isLoading) {
      return null;
    }

    return (
      <div>
        {getOrgAuditStatus(selectedOrg) === 'pending' && (
          <FormControlLabel
            control={
              <Switch
                checked={isDiffView}
                onChange={clickToggleDiffView}
              />
            }
            label="Only show changed users"
          />
        )}
        {allTeams.length > 0 && (
          <FormControlLabel
            control={
              <Switch
                checked={toExpandDetails}
                onChange={clickToggleToShowTeamNames}
              />
            }
            label="Expand details"
          />
        )}
      </div>
    );
  };

  return (
    <Card
      className={classes.tableCard}
      variant="outlined"
    >
      <CardHeader
        classes={{
          action: classes.tableCardHeaderAction,
        }}
        action={getOrgAuditStatus(selectedOrg) === 'pending' && (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={clickConfirmSelected}
            disabled={isLoading}
          >
            Confirm
          </Button>
        )}
        title={selectedOrg && `${selectedOrg.name} Members`}
        subheader={getTableSubtitleText(selectedOrg)}
      />
      <SpioDataTable
        className={classes.dataTable}
        title={<TableTitle />}
        columns={getAuditTableColumns(allLogins, allRoles, allNames, allEmails, allTeams, membersToDisplay, toExpandDetails)}
        data={membersToDisplay || []}
        options={{
          elevation: 1,
          textLabels: {
            body: {
              noMatch: isLoading ? 'Loading...' : (isDiffView ? 'No changes' : 'No users found'),
            },
          } as MUIDataTableTextLabels,
          filterType: 'multiselect',
          print: false,
          downloadHeader: [
            [ selectedOrg?.name ?? '' ],
            [ getTableSubtitleText(selectedOrg) ],
            [],
          ],
          downloadOptions: {
            filename: `UserAudit_GitHub_${moment().format('YYYYMMDD')}.csv`,
          },
          selectableRows: 'none',
          sort: true,
          sortOrder: {
            name: 'login',
            direction: 'asc',
          },
          setRowProps: (row, index) => {
            const status = membersToDisplay[index]?.status;

            return {
              className: classNames(
                status === 'added' && classes.newUserRow,
                status === 'deleted' && classes.deletedUserRow,
                ),
            };
          },
        }}
      />
    </Card>
  );
}
