import MUIDataTable, {
  debounceSearchRender,
  Display,
  MUIDataTableColumn,
  MUIDataTableColumnOptions,
  MUIDataTableOptions,
  MUIDataTableProps,
  MUIDataTableState,
} from 'mui-datatables';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { sanitizeForCSV } from '../helpers';

// Theme property overrides are not working with MUIDataTable, so a wrapper component is easiest way to handle it.

export interface SpioDataTableSelectedRowIndex {
  index: number;
  dataIndex: number;
}

export interface SpioDataTableSelectedRows {
  data: SpioDataTableSelectedRowIndex[];
  lookup: { [key: number]: boolean };
}

export type SortDirection = 'asc' | 'desc' | 'none';

export interface SpioDataTableOptions extends MUIDataTableOptions {
  // For adding rows to the top of your CSV file.
  // Usage: downloadHeader = [ [ 'row1 col1 text', 'row1 col2 text' ], [ 'row2 col1 text' ], ... ]
  // In a given row if you include more columns than are in the table itself, they'll get cut off.
  // It's fine to include fewer columns than are in the table, it'll get padded to match.
  downloadHeader?: string[][];
}

export interface SpioDataTableProps extends MUIDataTableProps {
  className?: string;
  columns: SpioDataTableColumn[];
  initFilters?: string[][];
  options?: SpioDataTableOptions;
}

// The published typescript defs have an overly permissive 'display'.
// The published typescript defs are missing 'sortCompare'.
export interface SpioDataTableColumnOptions extends MUIDataTableColumnOptions {
  customDownloadRender?: (dataIndex: number) => string;
  display?: Display;

  sortCompare?: (order: SortDirection) => (arg1: { data: any }, arg2: { data: any }) => number;
}

export interface SpioDataTableColumn extends MUIDataTableColumn {
  options?: SpioDataTableColumnOptions;
}

// v3 notes:
// There no longer seems to be a need to store the searchText.
// Continue to store the filters so that we can use our 'initFilters' prop.
// (The built-in 'filterList' is too aggressive at bringing back cleared filters.)
// Continue to store the rowsExpanded and dataLengthCache state, else all rows collapse when we click 'New Entry'.
// Continue to store the display else the viewColumns get reset when we click 'New Entry'.
function SpioDataTable(props: SpioDataTableProps) {
  const [dataLengthCache, setDataLengthCache] = useState<number>(0);
  const [display, setDisplay] = useState<Display[]>(props.columns.map(column => column.options?.display ?? 'true'));
  const [filters, setFilters] = useState<string[][]>(props.initFilters || []);
  const [rowsExpanded, setRowsExpanded] = useState<number[]>([]);
  const [tableColumns, setTableColumns] = useState<SpioDataTableColumn[]>([]);
  const [ rowsPerPage, setRowsPerPage ] = useState<number>(25);
  useEffect(() => {
    if (display.length !== props.columns.length) {
      // When we push additional columns, we need to rerender display to get them to appear
      setDisplay(props.columns.map(column => column.options?.display ?? 'true'));
    }
    setTableColumns(props.columns.map((colInfo, i) => ({
      ...colInfo,
      options: {
        filterList: filters[i], // persists the filters upon re-render
        ...(colInfo.options),
        display: display[i], // persists the viewed columns upon re-render
      },
    })));
  }, [display, filters, props.columns]);

  const spioDataTableOptions: SpioDataTableOptions = {
    caseSensitive: false,
    customSearchRender: debounceSearchRender(500),
    expandableRowsHeader: false,
    responsive: 'standard',
    rowsPerPage: rowsPerPage,
    onChangeRowsPerPage: (numberOfRows) => {
      setRowsPerPage(numberOfRows);
    },
    rowsPerPageOptions: [10, 25, 50, 100],
    rowsExpanded,
    onFilterChange: (_, filterList: string[][]) => setFilters(filterList),
    onDownload: (buildHead, buildBody, columns, data) => {
      const headerNames = columns.map((col: any) => {
        return {
          name: col.label,
          download: col.download,
        };
      });

      const downloadData = data.map((row: any) => {
        return {
          index: row.index,
          data: row.data.map((d: any, i: number) => {
            return columns[i].customDownloadRender
              ? columns[i].customDownloadRender(row.index)
              : d?.toString() ?? '';
          }),
        };
      });

      let header = '';
      if (props.options?.downloadHeader) {
        const downloadHeaderRows = props.options.downloadHeader || [];
        const numCols = columns.filter((col: any) => col.download).length;

        const csvRows = downloadHeaderRows.map((row: string[]) => {
          // Sanitizes the cell contents and enforces that there are exactly 'numCols' columns:
          return row
            .map((d) => `"${sanitizeForCSV(d)}"`)
            .concat(Array(numCols))
            .slice(0, numCols)
            .join(',');
        });
        header = csvRows.join('\r\n').concat('\r\n');
      }

      const head = buildHead(headerNames);
      const body = buildBody(downloadData);

      return `${header}${head}${body}`.trim();
    },
    onRowExpansionChange: (currentRowsExpanded, allRowsExpanded) =>
      setRowsExpanded(allRowsExpanded.map((row) => row.dataIndex)),

    ...props.options,

    onViewColumnsChange: (changedColumn: string, action: string) => {
      const colIndex = props.columns.findIndex(
        (col) => col.name === changedColumn
      );
      if (colIndex >= 0 && colIndex < display.length) {
        const newDisplay = display.slice();
        newDisplay[colIndex] = action === 'add' ? 'true' : 'false';
        setDisplay(newDisplay);
      }

      if (props.options?.onViewColumnsChange) {
        props.options.onViewColumnsChange(changedColumn, action);
      }
    },
    onTableChange: (action: string, tableState: MUIDataTableState) => {
      // Closes any expanded rows if we're changing the data array length.
      // (B/c the expanded rows are sticky-to-index, not sticky-to-data.)
      // TODO: Investigate better ways of handling expanded rows on data length changes.

      const newDataLength = tableState.data.length;

      if (newDataLength !== dataLengthCache) {
        setRowsExpanded([]);
        setDataLengthCache(newDataLength);
      }

      if (props.options?.onTableChange) {
        props.options.onTableChange(action, tableState);
      }
    },
  };

  return (
    <MuiThemeProvider theme={createTheme({ overrides: { MuiTableRow: { root: { cursor: 'pointer' } } } })}>
      <MUIDataTable
        {...props}
        columns={tableColumns}
        options={spioDataTableOptions}
      />
    </MuiThemeProvider>
  );
}

SpioDataTable.propTypes = MUIDataTable.propTypes;
SpioDataTable.defaultProps = MUIDataTable.defaultProps;
SpioDataTable.displayName = MUIDataTable.displayName;

export default SpioDataTable;
