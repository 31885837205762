import React, { useState } from 'react';
import {
  createStyles,
  Accordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Grid,
  Link,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import CommentIcon from '@material-ui/icons/ModeCommentOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as Sentry from '@sentry/browser';
import { ICommentDto } from '../../../backend/src/comment/interfaces';
import API from '../services/ApiService';
import Auth from '../services/AuthService';
import CommentDetails from './CommentDetails';
import CommentInput, { ICommentInputData } from './CommentInput';
import { showErrorResultBar, showSuccessResultBar } from './ResultSnackbar';
import { handleError } from '../helpers';

const AccordionSummary = withStyles({
  root: {
    'borderBottom': '1px solid rgba(0, 0, 0, .125)',
    'marginBottom': -1,
    'minHeight': 0,
    '&$expanded': {
      minHeight: 0,
    },
  },
  content: {
    'margin': '2px 0',
    '&$expanded': {
      margin: '2px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const styles = (theme: Theme) => createStyles({
  addCommentLink: {
    cursor: 'pointer',
    display: 'inline-block',
    paddingTop: '1rem',
    paddingLeft: '1rem',
  },
  disabledAddCommentLink: {
    color: '#bfbfbf',
    cursor: 'pointer',
    '&:hover': {
      color: '#bfbfbf',
    },
    display: 'inline-block',
    paddingTop: '1rem',
    paddingLeft: '1rem',
  },
  commentsExpansionSummary: {
    'padding': 0,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  commentsExpansionDetails: {
    padding: 0,
  },
  commentIcon: {
    fontSize: '0.875rem',
  },
  commentNumberText: {
    paddingLeft: '2px',
    fontSize: '0.875rem',
  },
  commentHeading: {
    fontWeight: 'bold',
    marginTop: '0.5rem',
    color: 'inherit',
  },
  commentNbHeading: {
    marginLeft: '1rem',
    marginTop: '0.5rem',
    color: 'inherit',
  },
});

export interface TaskCommentsProps extends WithStyles<typeof styles> {
  auth: Auth;
  comments?: ICommentDto[];
  onUpdateComments: (updatedComments: ICommentDto[]) => void;
  taskId: string;
  readOnly: boolean;
}

function TaskComments(props: TaskCommentsProps) {
  const {
    auth,
    classes,
    comments = [],
    onUpdateComments,
    taskId,
    readOnly
  } = props;
  const [ isCommenting, setIsCommenting ] = useState(false);
  const [ commentIdEditing, setCommentIdEditing ] = useState<string>();

  const nbComments = comments ? comments.length : 0;

  async function clickOnAddComment(formData: ICommentInputData) {
    await onSaveComment(formData);
    setIsCommenting(false);
  }

  async function clickOnUpdateComment(formData: ICommentInputData) {
    await onSaveComment(formData);
    setCommentIdEditing(undefined);
  }

  const handleDeleteComment = async (commentId: string) => {
    try {
      await API.delete(`task/${taskId}/comment/${commentId}`);
      const updatedComments = comments.slice().filter(comment => comment.id !== commentId);
      onUpdateComments(updatedComments);
      showSuccessResultBar('Comment deleted.');
    } catch (err) {
      Sentry.captureException(err);
      handleError(err, 'Error deleting comment');
    }
  };

  const onSaveComment = async (formData: ICommentInputData) => {
    let updatedComments;
    const commentId = formData.id; // 'undefined' if new comment

    try {
      if (commentId) {
        // Updating an existing comment
        const newComment = (await API.patch(`task/${taskId}/comment/${commentId}`, { text: formData.comment })).data.data;
        const commentIdx = comments.findIndex(comment => comment.id === commentId);
        updatedComments = comments.slice();
        updatedComments[commentIdx] = newComment;
      } else {
        // Adding a new comment
        const newComment = (await API.post(`task/${taskId}/comment`, { text: formData.comment })).data.data;
        updatedComments = [ newComment ].concat(comments);
      }

      onUpdateComments(updatedComments);
    } catch (err) {
      Sentry.captureException(err);
      showErrorResultBar('There was an error while saving your comment.');
    }
  };

  return (
    <Accordion
      elevation={0}
      defaultExpanded={true}
    >
      <AccordionSummary
        className={classes.commentsExpansionSummary}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography
          variant="body1"
          className={classes.commentHeading}
        >
          Comments
        </Typography>
        {nbComments > 0 &&
        <Typography
          variant="body1"
          className={classes.commentNbHeading}
        >
          <CommentIcon className={classes.commentIcon} />
          <span className={classes.commentNumberText}>
              {nbComments}
            </span>
        </Typography>
        }
      </AccordionSummary>
      <AccordionDetails
        className={classes.commentsExpansionDetails}
      >
        <Grid container>
          <Grid item xs={12}>
            {isCommenting ? (
              <CommentInput
                onCancelComment={() => setIsCommenting(false)}
                onSaveComment={clickOnAddComment}
              />
            ) : (
              <Link
                component="button"
                variant="body2"
                onClick={() => setIsCommenting(true)}
                disabled={readOnly}
                className={readOnly ? classes.disabledAddCommentLink : classes.addCommentLink}
              >
                Add a comment
              </Link>
            )}
          </Grid>
          <Grid item xs={12}>
            {comments.map((comment) => (
              comment.id === commentIdEditing ?
                <CommentInput
                  key={comment.id}
                  comment={comment}
                  onCancelComment={() => setCommentIdEditing(undefined)}
                  onSaveComment={clickOnUpdateComment}
                />
                :
                <CommentDetails
                  auth={auth}
                  key={comment.id}
                  comment={comment}
                  onClickDelete={() => handleDeleteComment(comment.id)}
                  onClickEdit={() => setCommentIdEditing(comment.id)}
                  readOnly={readOnly}
                />
            ))}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

export default withStyles(styles, { withTheme: true })(TaskComments);
