import { Tooltip } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import * as React from 'react';
import { NetworkScanStatus } from '../../../backend/src/network-scan/network-scan-status.enum';

const scanStatusMap = [
  {
    value: 'requested',
    icon: <NewReleasesIcon />,
    text: 'Requested',
  },
  {
    value: 'completed',
    icon: <DoneIcon />,
    text: 'Completed',
  },
  {
    value: 'started',
    icon: <TimelapseIcon />,
    text: 'Started',
  },
];

export interface NetworkScanStatusIconProps {
  status: NetworkScanStatus;
}

function NetworkScanStatusIcon({ status }: NetworkScanStatusIconProps) {
  const statusDetails = scanStatusMap.find(s => s.value === status);

  if (!statusDetails) {
    throw Error('Unexpected status provided');
  }

  return (
    <Tooltip title={statusDetails.text} placement="right">
      {statusDetails.icon}
    </Tooltip>
  );
}

export default NetworkScanStatusIcon;
