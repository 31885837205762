import normalizeUrl from 'normalize-url'; // note that this package is aliased, fixed at v4 b/c of regex lookbehind/looka

// TODO: Improve normalization of user-input urls in the browser.
export const normalizeLink = (url: string) => {
  let normalized = '';

  try {
    normalized = normalizeUrl(url);

    if (!/^https?:\/\//i.test(normalized)) {
      // normalized = 'http://' + normalized;
      normalized = '';
    }

    return normalized;
  } catch (err) {
    return '';
  }
};
