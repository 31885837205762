import { createStyles, WithStyles, withStyles } from '@material-ui/core';
import React from 'react';
import CircleCheckIcon from './CircleCheckIcon';

const styles = createStyles({
  icon: {
    color: '#bf9d4c',
    stroke: '#bf9d4c',
  },

});

function PendingIcon({ classes }: WithStyles<typeof styles>) {
  return (
    <CircleCheckIcon
      className={classes.icon}
      text="Pending"
    />
  );
}

export default withStyles(styles)(PendingIcon);
