import React, { useEffect, useState } from 'react';
import { createStyles, Link, makeStyles, Typography } from '@material-ui/core';
import { showErrorResultBar } from '../components/ResultSnackbar';
import StakeholderQuestionnaireDetails from '../components/StakeholderQuestionnaireDetails';
import API from '../services/ApiService';
import { RouteComponentProps } from 'react-router';
import { handleError } from '../helpers';

const useStyles = makeStyles(theme => createStyles({
    root: {
        color: theme.typography.body1.color,
        width: '100%',
        textAlign: 'center',
        margin: 'auto',
    },
}));

const LoadingMessage = () => {
    return (
        <Typography variant="h6">
            Loading form...
        </Typography>
    );
};

const InvalidTokenMessage = () => {
    return (
        <>
            <Typography variant="h6">
                Invalid token.
            </Typography>
            <Typography
                variant="body1"
                gutterBottom
            >
                The form may already have been submitted or the link may have expired.
            </Typography>
            <Typography variant="body1">
                Contact us at <Link href="mailto:support@rhoimpact.com">support@rhoimpact.com</Link> if
                you are receiving this message in error.
            </Typography>
        </>
    );
};

const ThankYouMessage = () => {
    return (
        <>
            <Typography variant="h2">
                Thank you!
            </Typography>
            <br />
            <br />
            <Typography
                variant="h5"
                gutterBottom
            >
                We have received your responses. If you provided your email, you will receive a confirmation email containing your responses.
            </Typography>
            <Typography variant="body1">
                Contact <Link href="mailto:support@rhoimpact.com">support@rhoimpact.com</Link> if
                you have any problems or concerns.
            </Typography>
        </>
    );
};

export interface TheStakeholderQuestionnairePageParams {
    id: string;
}

function TheStakeholderQuestionnairePage({ match }: RouteComponentProps<TheStakeholderQuestionnairePageParams>) {
    const classes = useStyles();
    const tokenId = match.params.id;
    const [isValidToken, setIsValidToken] = useState(false);
    const [questionnaireTemplate, setQuestionnaireTemplate] = useState();
    const [savedValues, setSavedValues] = useState();
    const [orgName, setOrgName] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    useEffect(() => {
        (async () => {
            setIsLoading(true);

            try {
                const res = await API.get(`stakeholder-questionnaire/${tokenId}`);
                const isValid = (res.data && res.data.data) ? res.data.data : false;
                const questionnaireTemplate = (res.data && res.data.questionnaireTemplate) ? res.data.questionnaireTemplate : false;
                const orgName = (res.data && res.data.orgName) ? res.data.orgName : 'your company';
                const savedValues = (res.data && res.data.savedValues) ? res.data.savedValues : null;

                setIsValidToken(isValid);
                setQuestionnaireTemplate(questionnaireTemplate);
                setSavedValues(savedValues);
                setOrgName(orgName);
            } catch {
                setIsValidToken(false);
            } finally {
                setIsLoading(false);
            }
        })();
    }, [tokenId]);

    const submitResponses = async (formValues: Record<string, any>, anonymousResponse: boolean) => {
        let errorMsg;

        try {
            await API.post(`stakeholder-questionnaire/${tokenId}`, {formValues: formValues, anonymousResponse: anonymousResponse});
            setIsSuccess(true);
        } catch (err) {
            handleError(err, 'Error submitting questionnaire');
        }

        if (errorMsg) {
            showErrorResultBar(errorMsg);
        }
    };

    const saveResponses = async (formValues: Record<string, any>) => {
        let errorMsg;

        try {
            await API.post(`stakeholder-questionnaire/${tokenId}/save`, formValues);
        } catch (err) {
            handleError(err, 'Error saving questionnaire');
        }

        if (errorMsg) {
            showErrorResultBar(errorMsg);
        }
    };

    let page;

    if (isLoading) {
        page = <LoadingMessage />;
    } else if (isSuccess) {
        page = <ThankYouMessage />;
    } else if (isValidToken) {
        page = (
            <StakeholderQuestionnaireDetails
                isValidToken
                tokenId={tokenId}
                submitResponses={submitResponses}
                saveResponses={saveResponses}
                savedValues={savedValues}
                questionnaireTemplate={questionnaireTemplate}
                orgName={orgName}
                anonymousResponse={false}
            />
        );
    } else {
        page = <InvalidTokenMessage />;
    }

    return (
        <div className={classes.root}>
            {page}
        </div>
    );
}

TheStakeholderQuestionnairePage.title = 'Stakeholder Questionnaire';

export default TheStakeholderQuestionnairePage;
