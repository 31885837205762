import React, { Fragment, useEffect, useState } from 'react';
import { Button, TableCell, TableRow } from '@material-ui/core';
import { LimitReachedDialog } from '../components/dialogs';
import { FileUploadButtonDialog } from '../components/dialogs';
import SpioDataTable from '../components/SpioDataTable';
import {
  formatDate,
  formatFileTitle,
  formatFileSize,
  truncateString,
} from '../helpers';
import API from '../services/ApiService';
import EvidenceLoadingDetails from '../components/EvidenceLoadingDetails';
import { DownloadButton } from '../components/buttons';
import * as DocService from '../services/DocService';
import { EvidenceTableSelectedRowsToolbar } from '../components/EvidenceTableSelectedRowsToolbar';
import DoneIcon from '@material-ui/icons/Done';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import {
  showErrorResultBar,
  showInfoResultBar,
} from '../components/ResultSnackbar';
import * as Sentry from '@sentry/browser';

export const UNASSIGNED_DATA_STR = '(blank)';

export const DOCUMENT_STATUS_ICONS = {
  //active: <Brightness1OutlinedIcon />,
  Completed: <DoneIcon />,
  'Needs Review': <PriorityHighIcon />,
  'In Progress': <TimelapseIcon />,
};

export const DOCUMENT_STATUS_MAP = {
  active: 'Not Started',
  completed: 'Completed',
  review: 'Needs Review',
  in_progress: 'In Progress',
};

const getTableColumns = (tableData) => [
  {
    name: 'id',
    label: 'ID',
    options: {
      display: 'false',
      filter: false,
    },
  },
  {
    name: 'mappedStatus',
    label: 'Status',
    options: {
      customBodyRenderLite: (dataIndex) => {
        const mappedStatus = tableData[dataIndex]?.mappedStatus;

        return DOCUMENT_STATUS_ICONS[mappedStatus];
      },
      display: 'true',
      customFilterListOptions: { render: (v) => `${v}` },
    },
  },
  {
    name: 'name',
    label: 'Document Name',
    options: {
      customBodyRenderLite: (dataIndex) =>
        truncateString(formatFileTitle(tableData[dataIndex]?.name || '')),
      filter: false,
    },
  },
  {
    name: 'originalFileName',
    label: 'Original File Name',
    options: {
      customBodyRenderLite: (dataIndex) =>
        truncateString(
          formatFileTitle(tableData[dataIndex]?.originalFileName || '')
        ),
      filter: false,
      display: false,
      download: true,
    },
  },
  {
    name: 'assigneeName',
    label: 'Assigned To',
    options: {
      customFilterListOptions: { render: (v) => `Assigned To: ${v}` },
      display: 'true',
    },
  },
  {
    name: 'createdByName',
    label: 'Created By',
    options: {
      customFilterListOptions: { render: (v) => `Created By: ${v}` },
      display: false,
      filter: true,
    },
  },
  {
    name: 'updatedByName',
    label: 'Updated By',
    options: {
      customFilterListOptions: { render: (v) => `Updated By: ${v}` },
      display: false,
      filter: true,
    },
  },
  {
    name: 'createdAt',
    label: 'Date Created',
    options: {
      customBodyRenderLite: (dataIndex) =>
        formatDate(tableData[dataIndex]?.createdAt || ''),
      customFilterListOptions: { render: (v) => `Date Created: ${v}` },
      filter: true,
    },
    display: true,
  },
  {
    name: 'updatedAt',
    label: 'Last Updated',
    options: {
      customBodyRenderLite: (dataIndex) =>
        formatDate(tableData[dataIndex]?.updatedAt || ''),
      customFilterListOptions: { render: (v) => `Last Updated: ${v}` },
      filter: true,
      display: true,
    },
    // display: 'false',
  },
  {
    name: 'tagNames',
    label: 'Tags',
    options: {
      download: false,
      display: true,
      customBodyRenderLite: (dataIndex) => {
        let tagNames = tableData[dataIndex]?.tagNames || [];
        if (tagNames.length > 2) {
          tagNames = tagNames.slice(0, 2);
          tagNames.push('...');
        }
        tagNames = tagNames.map((t) =>
          t.length < 21 ? t : `${t.substring(0, 20)}\u2026`
        );
        return tagNames.map((tagName) => <div key={tagName}>{tagName}</div>);
      },
      filter: true,
      searchable: true,
      setCellProps: () => Object({ nowrap: 'true' }),
      sort: false,
    },
  },
  {
    name: 'tagList',
    label: 'Tags',
    options: {
      download: true,
      display: false,
      filter: false,
      searchable: false,
      sort: false,
      viewColumns: false,
    },
  },
  {
    // The underlying column value should be a string or a number; here we (arbitrarily) use the id.
    name: 'id',
    label: 'Actions',
    options: {
      filter: false,
      searchable: false,
      sort: false,
      setCellProps: () => ({ nowrap: 'true' }),
      customBodyRenderLite: (dataIndex) => tableData[dataIndex]?.actions,
    },
  },
];

function TheEvidencePage({ auth, match }) {
  const userDoc = match.params.doc;
  const [reports, setReports] = useState([]);
  const [isLimitDialogOpen, setIsLimitDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [assignees, setAssignees] = useState([]);
  const [allDocTags, setAllDocTags] = useState([]);
  const [rowsSelected, setRowsSelected] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);
  const [filters, setFilters] = useState([]);
  const [dataIdxs, setDataIdxs] = useState([]);
  const [updateData, setUpdateData] = useState(false);
  // const handleOpenUpgrade = () => {
  //   setIsLimitDialogOpen(true);
  // };

  const handleCloseUpgrade = () => {
    setIsLimitDialogOpen(false);
  };

  const handleArchive = (selectedIdx) => () => {
    const updatedData = reports.slice();
    updatedData.splice(selectedIdx, 1);
    setDataIdxs(dataIdxs.splice(selectedIdx, 1));
    setReports(updatedData);
  };

  const handleArchiveDocs = async (idxs) => {
    const dataCopy = reports.filter(function (value, index, arr) {
      return !idxs.includes(index);
    });
    setReports(dataCopy);
    updateTable(dataCopy);
    setRowsSelected([]);
    //setDataIdxs([...Array(dataCopy.length).keys()]);
  };

  useEffect(() => {
    setIsLoading(true);
    // check if a specific document id is provided before fetching documents so we don't have to load unnecessary documents
    if (userDoc) {
      API.get(`document/evidence/${userDoc}`)
        .then((res) => {
          const updatedDocs = [res.data.data];
          setReports(updatedDocs);
        })
        .catch((err) => {
          showErrorResultBar('No document found');
          Sentry.captureException(err);
          setReports([]);
        })
        .finally(() => setIsLoading(false));
    } else {
      API.get('document/evidence')
        .then((res) => {
          const updatedDocs = res.data;
          setReports(updatedDocs);
          setDataIdxs([...Array(updatedDocs.length).keys()]);
        })
        .finally(() => setIsLoading(false));
    }
  }, []);

  useEffect(() => {
    API.get('document-tag').then((res) => {
      setAllDocTags(res.data ? res.data.data : []);
    });
  }, []);

  useEffect(() => {
    API.get('document/allowedAssignees')
      .then((res) => {
        setAssignees(res.data.data);
      })
      .catch(Sentry.captureException);
  }, []);

  const handleClickDownloadDocument = (id) => (e) => {
    DocService.documentManagementDownload(id);
    e.stopPropagation();
  };

  const updateTable = (docs) => {
    setTableData(
      docs.map((d, idx) =>
        Object({
          ...d,
          mappedStatus: DOCUMENT_STATUS_MAP[d.status],
          tagNames: d.documentTags
            ? d.documentTags.map((tag) => tag.name).sort()
            : [],
          tagList: d.documentTags
            ? d.documentTags
                .map((tag) => tag.name.replace(';', ''))
                .sort()
                .join('; ')
            : [], // this is only for the download so we can separate tags by semicolons instead of commas
          actions: (
            <DownloadButton
              key={`download_${idx}`}
              onClick={handleClickDownloadDocument(d.id)}
              disabled={!auth.isGranted({ permission: 'evidence:download' })}
            />
          ),
        })
      )
    );
    setUpdateData(true); // flag to update displayed data info for selection
  };

  useEffect(() => {
    updateTable(reports);
  }, [reports]);

  const getStorageSize = () => {
    return reports
      .map((r) => (r.size ? parseFloat(r.size) : 0))
      .reduce((a, b) => a + b, 0);
  };

  const handleUpdate = (selectedIdx) => (doc) => {
    const dataCopy = reports.slice();
    dataCopy[selectedIdx] = {
      ...dataCopy[selectedIdx],
      ...doc,
    };
    updateTable(reports);
    setReports(dataCopy);

    // get updated available tags in case one was removed or added
    API.get('document-tag').then((res) => {
      setAllDocTags(res.data ? res.data.data : []);
    });
  };

  function onAddDocuments(newDocs) {
    let dataCopy = reports.slice();
    if (newDocs.length > 0) {
      if (newDocs ?? [0].orgId === auth.getOrgId()) {
        dataCopy = newDocs.concat(dataCopy);
      }
    } else {
      showErrorResultBar('Error uploading documents.');
    }
    updateTable(dataCopy);
    setReports(dataCopy);
  }

  const handleUpdateDocs = async (idxs, updatedDocInfo) => {
    const dataCopy = reports.slice();
    idxs.forEach((idx) => {
      dataCopy[idx] = {
        ...dataCopy[idx],
        ...updatedDocInfo,
      };
    });
    setReports(dataCopy);
    updateTable(dataCopy);
    setRowsSelected([]);
  };

  const onRunDiscovery = () => {
    API.post('document/evidence/run_discovery')
      .then((res) => {
        const doc_count = res.data.data.document_count;
        if (doc_count > 0) {
          showInfoResultBar(`Processing ${doc_count} documents`);
        } else {
          showInfoResultBar('No documents found for discovery analysis');
        }
      })
      .catch((err) => {
        showErrorResultBar('Request failed');
      });
  };

  return (
    <>
      <LimitReachedDialog
        open={isLimitDialogOpen}
        onClose={handleCloseUpgrade}
        text={
          'Your subscription plan does not include access to this document. You will need to upgrade your plan in order to download it.'
        }
      ></LimitReachedDialog>
      <SpioDataTable
        title={
          auth.isGranted({ permission: 'evidence:upload' }) ? (
            <Fragment>
              <FileUploadButtonDialog
                buttonText="Upload New Evidence"
                onAddDocuments={onAddDocuments}
                showIcon
                showCategorySelection={false}
                documentCategory={'evidence'}
                multipleFiles={true}
                buttonProps={userDoc ? { disabled: true } : null}
                allTags={allDocTags.map((dt) => dt.name)}
                currentDocuments={
                  reports
                    ? reports.map((r) => Object({ name: r.name, size: r.size }))
                    : []
                }
              />
              {/* <Button color="primary" onClick={onRunDiscovery} disabled={!auth.isGranted({ permission: 'admin:development'})}>
                Run Document Discovery
              </Button> */}
            </Fragment>
          ) : (
            'Documents'
          )
        }
        columns={getTableColumns(tableData)}
        data={tableData}
        options={{
          print: false,
          filterType: 'multiselect',
          selectableRows: userDoc ? 'none' : 'multiple',
          selectableRowsHeader: !isLoading,
          textLabels: {
            body: {
              noMatch: isLoading ? 'Loading...' : 'No records found',
              toolTip: 'Sort',
            },
            selectedRows: {
              text: (
                <>
                  {' '}
                  row(s) selected
                  <div>
                    <Button
                      color="primary"
                      onClick={() => setRowsSelected(dataIdxs)}
                    >
                      Select All {dataIdxs.length} Rows
                    </Button>
                  </div>
                </>
              ),
            },
          },
          onChangeRowsPerPage: (numberOfRows) => {
            setRowsPerPage(numberOfRows);
          },
          onChangePage: (currentPage) => {
            setCurrentPage(currentPage);
          },
          filter: userDoc ? false : true,
          rowsSelected: rowsSelected,
          rowsPerPage: rowsPerPage,
          onRowSelectionChange: (_, allRowsSelected) => {
            setRowsSelected(allRowsSelected.map((row) => row.dataIndex));
            // only select rows on page
            if (allRowsSelected.length === dataIdxs.length) {
              setRowsSelected(
                allRowsSelected
                  .slice(
                    currentPage * rowsPerPage,
                    (currentPage + 1) * rowsPerPage > tableData.length
                      ? tableData.length
                      : (currentPage + 1) * rowsPerPage
                  )
                  .map((row) => row.dataIndex)
              );
            }
          },
          onFilterChange: (
            _,
            filterList,
            type,
            changedColumnIndex,
            displayData
          ) => {
            setFilters(filterList);
            setDataIdxs(displayData.map((row) => row.dataIndex));
          },
          onTableChange: (_, tableState) => {
            // this should be cleaned up, we need to update the dataIdxs when the table data is updated in case of filtering, so I just used a flag to indicate whether we should update it
            if (updateData) {
              setDataIdxs(tableState.displayData.map((row) => row.dataIndex));
              setUpdateData(false);
            }
          },
          customToolbarSelect: (selectedRows) => (
            <EvidenceTableSelectedRowsToolbar
              assignees={assignees}
              onCancel={() => setRowsSelected([])}
              onUpdate={handleUpdateDocs}
              selectedRows={selectedRows}
              documents={reports}
              onArchive={handleArchiveDocs}
              auth={auth}
            />
          ),
          expandableRows: true,
          expandableRowsOnClick: true,
          renderExpandableRow: (rowData, rowMeta) => {
            const colSpan = rowData.length + 1;
            const myData = reports[rowMeta.dataIndex];

            return myData ? (
              <TableRow>
                <TableCell colSpan={colSpan}>
                  <EvidenceLoadingDetails
                    assignees={assignees}
                    auth={auth}
                    documentData={myData}
                    documentId={myData.id}
                    onUpdate={handleUpdate(rowMeta.dataIndex)}
                    allDocTags={allDocTags.map((t) => t.name)}
                    onArchive={handleArchive(rowMeta.dataIndex)}
                    allDocNames={reports.map((doc) => doc.name)}
                  />
                </TableCell>
              </TableRow>
            ) : null;
          },
        }}
      />
      {formatFileSize(getStorageSize())} Used
    </>
  );
}

TheEvidencePage.title = 'Evidence';
TheEvidencePage.requiredAuthZ = {
  tier: 1,
  permission: 'evidence',
};

export default TheEvidencePage;
