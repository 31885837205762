import React from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  MenuItem,
  StandardProps,
} from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import * as Sentry from '@sentry/browser';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { IssueImpact, IssueProbability, IssueStatus, RiskOpportunityType } from '../../../../backend/src/material-issue-context/enums';
import { MaterialIssueCategory } from '../../../../backend/src/material-issue/enums';
import { IMaterialIssueContextDto, IMaterialIssueContextUpdateDto, IMaterialIssueContextCreateDto } from '../../../../backend/src/material-issue-context/interfaces';
import { IMaterialIssueDto } from '../../../../backend/src/material-issue/interfaces/material-issue-dto.interface';
// import { preventNonNumericInput } from '../../helpers';
import API from '../../services/ApiService';
import { SaveButton } from '../buttons';
import { FormGridRow, FormikAutocompleteTextField, FormikAutocompleteIdNameField, FormikDatePicker, FormikTextField } from '../forms';
import { showErrorResultBar, showSuccessResultBar } from '../ResultSnackbar';
import StyledDialogTitle from '../StyledDialogTitle';
import ResponsiveDialog from './ResponsiveDialog';
import moment from 'moment';
import { handleError } from '../../helpers';

const MAX_ISSUE_SCORE = 5;
const DEFAULT_ISSUE_CATS = [
  'Reputational',
  'Operational',
  'Strategic',
  'Financial',
  'Stakeholder Value',
  'Regulatory',
];

export type IssueRank = IssueProbability | IssueImpact;
export type IssueRankStr = 'High' | 'Medium' | 'Low' | 'Unknown';

export interface IIssueRankInfo {
  score: number;
  text: IssueRankStr;
}

export const rankMap: { [key in IssueRank]: IIssueRankInfo } = {
  high: {
    text: 'High',
    score: MAX_ISSUE_SCORE,
  },
  medium: {
    text: 'Medium',
    score: MAX_ISSUE_SCORE / 2,
  },
  low: {
    text: 'Low',
    score: 1,
  },
  unknown: {
    text: 'Unknown',
    score: 0, // ie, higher than 'high'
  },
};

export const issueStatusSelections: Omit<{ [key in IssueStatus]: string }, 'archived'> = {
  identified: 'Identified (under review)',
  planning: 'Planning (developing a plan to address the risk/opportunity)',
  in_progress: 'In Progress (plan in place)',
  mitigated: 'Mitigated (plan successful)',
  reassess: 'Reassess (partially addressed, further review required)',
};

export const issueStatusSelectionsAbbreviated: Omit<{ [key in IssueStatus]: string }, 'archived'> = {
  identified: 'Identified',
  planning: 'Planning',
  in_progress: 'In Progress',
  mitigated: 'Mitigated',
  reassess: 'Reassess',
};

export const typeSelections: Omit<{ [key in RiskOpportunityType]: string }, 'archived'> = {
  risk: 'Risk',
  opportunity: 'Opportunity'
};

export const materialIssueCategorySelection: Omit<{ [key in MaterialIssueCategory]: string }, 'archived'> = {
  environmental: 'Environmental Issue',
  social: 'Social Issue',
  governance: 'Governance Issue',
  unknown: 'Unknown'
};

const IssueSchema = Yup.object().shape({
  issueCategory: Yup
    .mixed()
    .oneOf(Object.keys(materialIssueCategorySelection)),
  issueType: Yup
    .string()
    .nullable(),
  RiskOpportunityType: Yup
    .string()
    .oneOf(Object.keys(typeSelections)),
  materialIssue: Yup
    .object()
    .required('- Required'),
  details: Yup
    .string(),
  impact: Yup
    .mixed()
    .oneOf(Object.keys(rankMap)),
  preparationPlan: Yup
    .string(),
  owner: Yup
    .string()
    .max(255),
  probability: Yup
    .mixed()
    .oneOf(Object.keys(rankMap)),
  actionPlan: Yup
    .string(),
  materialRelevance: Yup
    .string()
    .trim()
    .required('- Required'),
  status: Yup
    .mixed()
    .oneOf(Object.keys(issueStatusSelections)),
  reportedAt: Yup
    .date(),
  reviewedAt: Yup
    .date(),
});

export const HELPER_TEXT = {
  issueCategory: 'The issue category of the risk/opportunity',
  riskOpportunityType: 'Is this a issue a risk or an opportunity?',
  issueType: 'The type of risk/opportunity',
  materialIssue: 'The material issue. You can choose from the list of examples or write your own.',
  details: 'Why has this risk/opportunity been identified? What proves that this is important to your business?',
  impact: 'Estimate of the significance of risk/opportunity',
  preparationPlan: 'Actions to be taken to prepare for this risk/opportunity',
  owner: 'Responsible party for this risk/opportunity',
  probability: 'Estimate of the likelihood of occurence',
  actionPlan: 'Actions to be taken to address for this risk/opportunity',
  materialRelevance: 'A brief name describing how and why the issue is relevant to the company',
  status: 'Identified (under review); Planning (developing a plan to mitigate); In Progress (mitigation plan in place); Mitigated (mitigation plan successful); Reassess (partially mitigated, further review required)',
  score: `Estimate of the importance of the material issue from 1 (low) to ${MAX_ISSUE_SCORE} (high)`,
  reportedAt: 'The date when the risk/opportunity was first reported',
  reviewedAt: 'The date when the risk/opportunity was last reviewed',
};

// const calcScore = (probabilityVal: IssueProbability, impactVal: IssueImpact) => {
//   const probability = rankMap[probabilityVal];
//   const impact = rankMap[impactVal];

//   const probabilityScore = probability ? probability.score : 0;
//   const impactScore = impact ? impact.score : 0;
//   const aveScoreInt = Math.floor(0.5 * (probabilityScore + impactScore));

//   return Math.max(1, aveScoreInt);
// };

export interface MaterialityEditDialogProps extends StandardProps<DialogProps, 'children'> {
  issueData: IMaterialIssueContextDto | null;
  onClose: () => void;
  onUpdate: (issue: IMaterialIssueContextDto) => void;
  allMaterialIssues: IMaterialIssueDto[];
}

export default function MaterialityEditDialog({ issueData, onClose, onUpdate, open, allMaterialIssues }: MaterialityEditDialogProps) {
  // const [toAutoScore, setToAutoScore] = useState(true);

  // useEffect(() => {
  //   if (issueData === null) {
  //     setToAutoScore(true);
  //   } else {
  //     const { score, probability, impact } = issueData;
  //     setToAutoScore(score === calcScore(probability, impact));
  //   }
  // }, [issueData]);

  const handleSave = async (formValues: IMaterialIssueContextUpdateDto | IMaterialIssueContextCreateDto) => {
    try {
      const res = issueData === null ?
        await API.post('materiality', formValues) :
        await API.patch(`materiality/${issueData.id}`, formValues);
      onUpdate(res.data?.data);
      showSuccessResultBar('Materiality register updated successfully');

      if (issueData === null) {
        onClose();
      }
    } catch (err) {
      handleError(err, 'Error saving material issue');
    }
  };
  return (
    <ResponsiveDialog
      disableBackdropClick
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
    >
      <StyledDialogTitle onClose={onClose}>
        {issueData === null ? 'Create new entry' : 'Edit entry'}
      </StyledDialogTitle>
      <Formik
        enableReinitialize
        initialValues={{
          //issueCategory: issueData?.issueCategory || 'unknown',
          riskOpportunityType: issueData?.riskOpportunityType || 'risk',
          materialIssue: issueData?.materialIssue || null,
          issueType: issueData?.issueType || '',
          details: issueData?.details || '',
          impact: issueData?.impact || 'unknown',
          preparationPlan: issueData?.preparationPlan || '',
          owner: issueData?.owner || '',
          probability: issueData?.probability || 'unknown',
          actionPlan: issueData?.actionPlan || '',
          materialRelevance: issueData?.materialRelevance || '',
          //score: issueData?.score || 1,
          status: issueData?.status || 'identified',
          reportedAt: issueData?.reportedAt || moment().toDate(),
          reviewedAt: issueData?.reviewedAt || moment().toDate(),
        }}
        validationSchema={IssueSchema}
        onReset={onClose}
        onSubmit={async (values, { setSubmitting }) => {
          await handleSave(values);
          setSubmitting(false);
        }}
      >
        {formikProps => (
          <>
            <DialogContent>
              <Grid container>
                {/* 'Risk identification' section */}
                <FormGridRow
                  divider
                  title="Issue identification and tracking"
                >
                <FormikAutocompleteIdNameField
                  options={allMaterialIssues}
                  field="materialIssue"
                  formikProps={formikProps}
                  helperTextStr={HELPER_TEXT.materialIssue}
                  label="Material Issue"
                />
                  <FormikTextField
                    autoFocus
                    field="materialRelevance"
                    formikProps={formikProps}
                    helperTextStr={HELPER_TEXT.materialRelevance}
                    label="Material Relevance"
                    required
                  />
                  <FormikTextField
                    field="riskOpportunityType"
                    formikProps={formikProps}
                    helperTextStr={HELPER_TEXT.riskOpportunityType}
                    label="Risk or Opportunity"
                    select
                  >
                    {Object.entries(typeSelections).map(([key, label]) => (
                      <MenuItem
                        key={key}
                        value={key}
                      >
                        {label}
                      </MenuItem>
                    ))}
                  </FormikTextField>
                  <FormikTextField
                    field="status"
                    formikProps={formikProps}
                    helperTextStr={HELPER_TEXT.status}
                    label="Status"
                    select
                  >
                    {Object.entries(issueStatusSelections).map(([key, label]) => (
                      <MenuItem
                        key={key}
                        value={key}
                      >
                        {label}
                      </MenuItem>
                    ))}
                  </FormikTextField>
                  <FormikTextField
                    field="owner"
                    formikProps={formikProps}
                    helperTextStr={HELPER_TEXT.owner}
                    label="Owner"
                  />
                  <FormikAutocompleteTextField
                    autocompleteProps={{
                      freeSolo: true,
                      groupBy: () => 'Examples:',
                      options: DEFAULT_ISSUE_CATS,
                    }}
                    field="issueType"
                    formikProps={formikProps}
                    helperTextStr={HELPER_TEXT.issueType}
                    label="Risk/Opportunity Type"
                  />
                  <FormikTextField
                    field="details"
                    formikProps={formikProps}
                    helperTextStr={HELPER_TEXT.details}
                    label="Key Indicator Description"
                    multiline
                  />
                  <FormikDatePicker
                    field="reportedAt"
                    formikProps={formikProps}
                    helperTextStr={HELPER_TEXT.reportedAt}
                    label="Reported Date"
                    disableFuture
                  />
                  <FormikDatePicker
                    field="reviewedAt"
                    formikProps={formikProps}
                    helperTextStr={HELPER_TEXT.reviewedAt}
                    label="Reviewed Date"
                    minDate={formikProps.values['reportedAt']}
                    minDateMessage="Reviewed date cannot be before Reported date."
                    disableFuture
                  />
                </FormGridRow>
                <FormGridRow
                  divider
                  title="Analysis"
                >
                  <FormikTextField
                    field="probability"
                    formikProps={formikProps}
                    helperTextStr={HELPER_TEXT.probability}
                    label="Probability"
                    select
                    onChange={e => {
                      // if (toAutoScore) {
                      //   const newProbability = e.target.value as IssueProbability;
                      //   const impact = formikProps.values.impact;
                      //   const newScore = calcScore(newProbability, impact);

                      //   formikProps.setFieldValue('score', newScore);
                      // }

                      formikProps.handleChange(e);
                    }}
                  >
                    {Object.entries(rankMap).map(([key, rankInfo]) => (
                      <MenuItem
                        key={key}
                        value={key}
                      >
                        {rankInfo.text}
                      </MenuItem>
                    ))}
                  </FormikTextField>
                  <FormikTextField
                    field="impact"
                    formikProps={formikProps}
                    helperTextStr={HELPER_TEXT.impact}
                    label="Impact"
                    select
                    onChange={e => {
                      // if (toAutoScore) {
                      //   const newImpact = e.target.value as IssueImpact;
                      //   const probability = formikProps.values.probability;
                      //   const newScore = calcScore(probability, newImpact);

                      //   formikProps.setFieldValue('score', newScore);
                      // }

                      formikProps.handleChange(e);
                    }}
                  >
                    {Object.entries(rankMap).map(([key, rankInfo]) => (
                      <MenuItem
                        key={key}
                        value={key}
                      >
                        {rankInfo.text}
                      </MenuItem>
                    ))}
                  </FormikTextField>
                  {/* <FormikTextField
                    field="score"
                    formikProps={formikProps}
                    helperTextStr={HELPER_TEXT.score}
                    label="Risk/Opportunity score"
                    required
                    type="number"
                    onChange={e => {
                      // const newScore = parseInt(e.target.value, 10);
                      // const probability = formikProps.values.probability;
                      // const impact = formikProps.values.impact;
                      // setToAutoScore(newScore === calcScore(probability, impact));
                      formikProps.handleChange(e);
                    }}
                    onKeyPress={preventNonNumericInput}
                  /> */}
                  {/* {!toAutoScore && <div>
                    <Link
                      component="button"
                      variant="body2"
                      onClick={() => {
                        const impact = formikProps.values.impact;
                        const probability = formikProps.values.probability;
                        formikProps.setFieldValue('score', calcScore(probability, impact));
                        setToAutoScore(true);
                      }}
                    >
                      Reset to the default score calculation
                    </Link>
                    <br />
                    <Typography variant="caption">
                      The default score is the average of the probability and impact, with high/unknown=1, medium={MAX_ISSUE_SCORE / 2} and low={MAX_ISSUE_SCORE}.
                    </Typography>
                  </div>} */}
                </FormGridRow>
                <FormGridRow
                  title="Preparation and Action"
                >
                  <FormikTextField
                    field="preparationPlan"
                    formikProps={formikProps}
                    helperTextStr={HELPER_TEXT.preparationPlan}
                    label="Preparation plan"
                    multiline
                  />
                  <FormikTextField
                    field="actionPlan"
                    formikProps={formikProps}
                    helperTextStr={HELPER_TEXT.actionPlan}
                    label="Action plan"
                    multiline
                  />
                </FormGridRow>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                disabled={formikProps.isSubmitting}
                onClick={formikProps.handleReset}
                color="primary"
              >
                {issueData === null ? 'Cancel' : 'Close'}
              </Button>
              <SaveButton
                disabled={formikProps.isSubmitting || Object.values(formikProps.errors).filter(v => !!v).length > 0}
                onClick={formikProps.handleSubmit}
              />
            </DialogActions>
          </>
        )}
      </Formik>
    </ResponsiveDialog>
  );
}
