import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import React from 'react';

const styles = createStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(2),
  }
}));

export interface EditButtonProps extends Omit<IconButtonProps, 'classes'>, WithStyles<typeof styles> {
  disableColor?: boolean;
  disabled?: boolean;
}

function EditButton({onClick, disableColor, disabled = false}: EditButtonProps) {
  return (
    (disableColor ?
      <>
        <IconButton
          aria-label="Edit"
          onClick={onClick}
          disabled={disabled}
          >
          <EditIcon style={{ color: '#bfbfbf' }} />
        </IconButton>
      </>
    :
      <>
        <IconButton
          aria-label="Edit"
          onClick={onClick}
          disabled={disabled}
        >
          <EditIcon />
        </IconButton>
      </>
  ));
}

export default withStyles(styles, { withTheme: true })(EditButton);
