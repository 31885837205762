import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  StandardProps,
  TextField,
} from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import EmailIcon from '@material-ui/icons/Email';
import { makeStyles } from '@material-ui/styles';
import * as Sentry from '@sentry/browser';
import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { IVendorDto } from '../../../../backend/src/vendor/interfaces';
import API from '../../services/ApiService';
import { showErrorResultBar, showSuccessResultBar } from '../ResultSnackbar';
import ResponsiveDialog from './ResponsiveDialog';
import { handleError } from '../../helpers';

const useStyles = makeStyles({
  leftIcon: {
    marginRight: '4px',
  },
});

const VendorQuestionnaireEmailSchema = Yup.object().shape({
  questionnaireEmail: Yup
    .string()
    .label('Email')
    .email('Must be a valid email address')
    .required('Required'),
});

export interface VendorSendQuestionnaireDialogProps extends StandardProps<DialogProps, 'children'> {
  onClose: () => void;
  onUpdateVendor: (newVendorData: IVendorDto) => void;
  vendorData: IVendorDto;
}

export default function VendorSendQuestionnaireDialog(props: VendorSendQuestionnaireDialogProps) {
  const classes = useStyles();
  const {
    onClose,
    onUpdateVendor,
    open,
    vendorData,
  } = props;

  const handleSendQuestionnaire = async (email: string) => {
    try {
      const res = await API.post(`vendor/${vendorData.id}/send-questionnaire`, { email });
      onUpdateVendor(res.data.data);
      showSuccessResultBar('Questionnaire sent successfully');
    } catch (err) {
      handleError(err, 'Error sending questionnaire');
    }
  };

  return (
    <ResponsiveDialog
      open={open}
      onClose={onClose}
      disableBackdropClick
    >
      <Formik
        enableReinitialize
        initialValues={{
          questionnaireEmail: vendorData.contactEmail || '',
        }}
        validationSchema={VendorQuestionnaireEmailSchema}
        onSubmit={async (values, { setSubmitting }) => {
          await handleSendQuestionnaire(values.questionnaireEmail);
          setSubmitting(false);
          onClose();
        }}
        onReset={onClose}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleReset,
          handleSubmit,
          isSubmitting,
        }) => (
          <>
            <DialogTitle>Questionnaire confirmation</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please confirm the email to which the questionnaire should be sent.
              </DialogContentText>
                <TextField
                  id="questionnaire-email"
                  name="questionnaireEmail"
                  label="Email"
                  value={values.questionnaireEmail}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth={true}
                  margin="normal"
                  helperText={errors.questionnaireEmail}
                  error={!!errors.questionnaireEmail}
                />
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                size="small"
                disabled={isSubmitting}
                onClick={handleReset}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                size="small"
                variant="contained"
                disabled={isSubmitting || Object.keys(errors).length > 0}
                onClick={(e: any) => handleSubmit(e)}
              >
                <EmailIcon className={classes.leftIcon} />
                Send Questionnaire
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </ResponsiveDialog>
  );
}
