import * as Sentry from '@sentry/browser';
import React, { useState } from 'react';
import { IVendorDto } from '../../../backend/src/vendor/interfaces';
import API from '../services/ApiService';
import { ArchiveDialog, VendorEditDialog, VendorSelfFillDialog, VendorSendQuestionnaireDialog } from './dialogs';
import { showErrorResultBar, showSuccessResultBar } from './ResultSnackbar';
import VendorDetailsInfo from './VendorDetailsInfo';
import { handleError } from '../helpers';

export interface VendorDetailsProps {
  onArchive: () => void;
  onUpdateVendor: (newVendorData: IVendorDto) => void;
  vendorData: IVendorDto;
}

export default function VendorDetails({ onArchive, onUpdateVendor, vendorData }: VendorDetailsProps) {
  const [ toShowArchiveDialog, setToShowArchiveDialog ] = useState(false);
  const [ toShowEditDialog, setToShowEditDialog ] = useState(false);
  const [ toShowSelfFillDialog, setToShowSelfFillDialog ] = useState(false);
  const [ toShowSendQuestionnaireDialog, setToShowSendQuestionnaireDialog ] = useState(false);

  const handleCloseSelfFillDialog = (updatedVendor?: IVendorDto) => {
    setToShowSelfFillDialog(false);

    if (updatedVendor) {
      onUpdateVendor(updatedVendor);
    }
  };

  const handleArchive = async () => {
    vendorData.status = 'archived';

    try {
      await API.patch(`vendor/${vendorData.id}`, vendorData);
      onArchive();
      showSuccessResultBar('Entry archived');
    } catch (err) {
      handleError(err, 'Error archiving entry');
    } finally {
      setToShowArchiveDialog(false);
    }
  };

  return !vendorData ? null : (
    <>
      <VendorDetailsInfo
        onClickArchive={() => setToShowArchiveDialog(true)}
        onClickEdit={() => setToShowEditDialog(true)}
        onClickSelfFillQuestionnaire={() => setToShowSelfFillDialog(true)}
        onClickSendQuestionnaire={() => setToShowSendQuestionnaireDialog(true)}
        onUpdateVendor={onUpdateVendor}
        vendorData={vendorData}
      />
      <ArchiveDialog
        open={toShowArchiveDialog}
        onArchive={handleArchive}
        onClose={() => setToShowArchiveDialog(false)}
        title={`Archive the vendor '${vendorData?.vendorName}'?`}
      />
      <VendorEditDialog
        onClose={() => setToShowEditDialog(false)}
        onSave={onUpdateVendor}
        open={toShowEditDialog}
        vendorData={vendorData}
      />
      <VendorSelfFillDialog
        open={toShowSelfFillDialog}
        handleClose={handleCloseSelfFillDialog}
        vendor={vendorData}
      />
      <VendorSendQuestionnaireDialog
        open={toShowSendQuestionnaireDialog}
        onClose={() => setToShowSendQuestionnaireDialog(false)}
        onUpdateVendor={onUpdateVendor}
        vendorData={vendorData}
      />
    </>
  );
}
