import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import * as Sentry from '@sentry/browser';
import React, { useState, useEffect } from 'react';
import API from '../services/ApiService';
import Auth from '../services/AuthService';
import * as DocService from '../services/DocService';
import {
  ArchiveDialog,
  DocumentEditDialog,
  DocumentLinkDialog,
  InformationDialog,
} from './dialogs';
import { showErrorResultBar, showSuccessResultBar } from './ResultSnackbar';
import { DetailSectionHeading } from './MaterialityIssueDetails';
// TODO move this to shared space since also used in Stakeholder?
import { MissingDataText, SecondaryField } from './VendorDetailsInfo';
import { CloseButton } from './buttons';
import { IDocument, IDocumentHistory } from '../../../backend/src/document/interfaces';
// import { DocumentDto } from '../../../backend/src/document/dtos';
import {
  getFileExtension,
  formatFileTitle,
  formatDate,
  formatFileSize,
  truncateString,
} from '../helpers';
import { Dictionary } from 'lodash';
import DocumentComments from './DocumentComments';
import DocumentVersions from './DocumentVersions';
import { handleError } from '../helpers';

const useStyles = makeStyles((theme) =>
  createStyles({
    buttonLink: {
      color: 'inherit',
      textDecoration: 'underline',
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    cardHeader: {
      paddingBottom: 0,
    },
    linkItem: {
      '& a': {
        color: theme.typography.body1.color,
        cursor: 'pointer',
      },
      '&:nth-child(n+2)': {
        paddingTop: '4px',
      },
      fontSize: '0.8125rem',
    },
    primaryColumn: {
      color: theme.typography.body1.color,
      fontSize: '14px',
    },
    referencesContainer: {
      paddingLeft: '1rem',
      paddingBottom: '0.8rem',
    },
    referenceResponse: {
      color: 'rgba(0,0,0,0.54)',
      whiteSpace: 'pre-line',
    },
    secondaryColumn: {
      backgroundColor: '#f5f5f5',
      opacity: '0.8',
      fontSize: '0.8125rem',
    },
    smallIconButton: {
      height: '14px',
      width: '14px',
      marginTop: '-6px',
      marginLeft: '0.5rem',
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    smallIconButtonIcon: {
      fontSize: '18px',
    },
    smallIconButtonLabel: {
      height: '0',
    },
    tagChip: {
      margin: '4px',
      color: theme.palette.primary.main,
      background: theme.palette.grey[200],
    },
  })
);

export const FILE_EX_LOOKUP: Dictionary<string> = {
  csv: 'comma-Separated Values (csv)',
  doc: 'Word Document (doc)',
  docx: 'Word Document (docx)',
  html: 'Web Page Document (html)',
  htm: 'Web Page Document (htm)',
  odt: 'Open Source Word processor Document (odt)',
  pdf: 'Portable Document Format (pdf)',
  xls: 'Excel Spreadsheet (xls)',
  xlsx: 'Excel Spreadsheet (xlsx)',
  ods: 'Open Source Spreadsheet (ods)',
  ppt: 'PowerPoint Presentation (ppt)',
  pptx: 'PowerPoint Presentation (pptx)',
  txt: 'Text Document (txt)',
};

interface IDocumentTableDatum extends IDocument {
  tagNames: string[];
  historyId: string;
  latestVersion: IDocumentHistory
}

export interface DocumentDetailsProps {
  auth: Auth;
  isDialog?: boolean;
  onArchive: () => void;
  onCloseDialog?: () => void;
  onUpdate: (doc: IDocument) => void;
  documentData: IDocumentTableDatum;
  allDocTags: string[];
  allDocNames: string[];
}

function DocumentDetails(props: DocumentDetailsProps) {
  const {
    isDialog = false,
    onArchive,
    onCloseDialog = () => undefined,
    onUpdate,
    documentData,
    allDocTags,
    auth,
    allDocNames,
  } = props;

  const classes = useStyles();
  const [toShowEditDialog, setToShowEditDialog] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [toShowArchiveDialog, setToShowArchiveDialog] = useState(false);
  const [toShowArchiveVersionDialog, setToShowArchiveVersionDialog] = useState(false);
  const [toShowLinkDialog, setToShowLinkDialog] = useState(false);
  const [toShowArchiveErrorDialog, setToShowArchiveErrorDialog] = useState(false);

  const handleArchive = async () => {
    try {
      await API.patch(`document/${documentData.id}`, { status: 'archived' });
      onArchive();
      showSuccessResultBar('Entry archived');
    } catch (err) {
      handleError(err, `Unexpected error while archiving: ${err || 'unspecified error'}`);
    }
  };

  const handleClickMenuItem = (handler: () => void) => () => {
    handleCloseMenu();
    handler();
  };

  const handleArchiveVersion = async (versionId: string) => {
    try {
      const res = (await API.delete(`document/${documentData.id}/version/${versionId}`)).data.data;
      onUpdate(res);
      showSuccessResultBar('Version archived.');
    } catch (err) {
      handleError(err, `Unexpected error while archiving version: ${err || 'unspecified error'}`);
    }
  };

  const handleCloseArchiveDialog = () => setToShowArchiveDialog(false);

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const handleConfirmArchive = async () => {
    handleArchive();
    handleCloseArchiveDialog();
  };

  const handleArchiveDialogOpen = async () => {
    if (documentData.history && documentData.history.filter(version => version.status === 'active').filter(version => version.version !== 'latest').length > 0) {
      setToShowArchiveVersionDialog(true);
    } else if (documentData.latestVersion && (documentData.latestVersion.tasks || []).length > 0) {
      setToShowArchiveErrorDialog(true);
    } else {
      setToShowArchiveDialog(true);
    }
  };

  return (
    <>
      <Card>
        <CardHeader
          action={
            <>
              <IconButton
                onClick={({ currentTarget }) => setMenuAnchorEl(currentTarget)}
              >
                <MoreVertIcon />
              </IconButton>
              {isDialog && <CloseButton onClick={onCloseDialog} />}
            </>
          }
          className={classes.cardHeader}
          title={
            <>
              {formatFileTitle(documentData.name || 'Document details')}{' '}
              {documentData.documentTags ? (
                documentData.documentTags
                  .map((tag) => (
                    <Chip
                      className={classes.tagChip}
                      label={tag.name}
                      size="small"
                    ></Chip>
                  ))
                  .sort()
              ) : (
                <></>
              )}
            </>
          }
        />
        <Menu
          anchorEl={menuAnchorEl}
          keepMounted
          open={Boolean(menuAnchorEl)}
          onClose={handleCloseMenu}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {/* Only show edit if the document is not marked as completed */}
          <MenuItem
            onClick={handleClickMenuItem(() => setToShowEditDialog(true))}
            disabled={!auth.isGranted({ permission: 'documents:edit' })}
          >
            Edit
          </MenuItem>
          <MenuItem
            onClick={handleArchiveDialogOpen}
            disabled={!auth.isGranted({ permission: 'documents:edit' })}
          >
            Archive
          </MenuItem>
          <MenuItem
            onClick={DocService.documentDownloadHandler(documentData.id)}
            disabled={!auth.isGranted({ permission: 'documents:download' })}
          >
            Download
          </MenuItem>
          <MenuItem
            onClick={() => setToShowLinkDialog(true)}
            disabled={!auth.isGranted({ permission: 'documents:view' }) && !auth.isGranted({ permission: 'documents:download' })}
          >
            Get Link
          </MenuItem>
        </Menu>
        <CardContent>
          {/* {documentData.documentTags ? documentData.documentTags.map(tag => <Chip className={classes.tagChip} label={tag.name} size="small"></Chip>).sort() : <></>} */}
          <Grid container alignItems="flex-start" spacing={4}>
            {/* Creator */}
            <Grid item xs={12} sm={8} md={9} className={classes.primaryColumn}>
              <DetailSectionHeading>
                Additional Information
              </DetailSectionHeading>
              <div className={classes.referencesContainer}>
                <Grid container>
                  <Grid item xs={2}>
                    Created By:
                  </Grid>
                  <Grid item xs={10} className={classes.referenceResponse}>
                    {documentData.createdByName ? (
                      <Grid container>{documentData.createdByName}</Grid>
                    ) : (
                      <MissingDataText message="None" />
                    )}
                  </Grid>
                  <Grid item xs={2}>
                    Original File Name:
                  </Grid>
                  <Grid item xs={10} className={classes.referenceResponse}>
                    {documentData?.originalFileName}
                  </Grid>
                  <Grid item xs={2}>
                    Link to Document:
                  </Grid>
                  <Grid item xs={10} className={classes.referenceResponse}>
                    <Link
                      to={documentData.historyId ? `/deliverables/${documentData.historyId}` : `/deliverables/${documentData.id}`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      View Document
                    </Link>
                  </Grid>
                </Grid>
              </div>
              {/* Notes */}
              <DetailSectionHeading>Description</DetailSectionHeading>
              <div className={classes.referencesContainer}>
                {documentData.description ? (
                  <Grid container>{documentData.description}</Grid>
                ) : (
                  <MissingDataText message="None" />
                )}
              </div>
              {/* Versions */}
              <Grid item xs={12}>
                    <DocumentVersions
                      auth={auth}
                      versions={documentData.history}
                      previousVersionId={documentData.id}
                      onUpdate={onUpdate}
                      handleArchiveVersion={handleArchiveVersion}
                      documentData={documentData}
                    />
              </Grid>
              {/* Comments */}
              <Grid item>
                <DocumentComments
                  auth={auth}
                  comments={documentData.comments}
                  documentId={documentData.id}
                  onUpdate={onUpdate}
                  readOnly={auth.isGranted({ permission: 'documents:edit'})}
                />
              </Grid>
            </Grid>
            {/* Secondary column */}
            <Grid
              item
              xs={12}
              sm={4}
              md={3}
              className={classes.secondaryColumn}
            >
              {/* Links */}
              <SecondaryField
                title="Uploaded At:"
                value={formatDate(documentData?.createdAt) || ''}
              />
              <SecondaryField
                title="Updated At:"
                value={formatDate(documentData?.updatedAt) || ''}
              />
              <SecondaryField
                title="File Type:"
                value={
                  FILE_EX_LOOKUP.hasOwnProperty(
                    getFileExtension(documentData?.name)
                  )
                    ? FILE_EX_LOOKUP[getFileExtension(documentData?.name)]
                    : 'Unknown'
                }
              />
              <SecondaryField
                title="File Size:"
                value={formatFileSize(documentData?.size || 'N/A')}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {/* Edit dialog */}
      <DocumentEditDialog
        open={toShowEditDialog}
        onClose={() => setToShowEditDialog(false)}
        onUpdate={onUpdate}
        documentData={documentData}
        allDocTags={allDocTags}
        allDocNames={allDocNames}
      />
      {/* Archive dialog */}
      <ArchiveDialog
        open={toShowArchiveDialog}
        onClose={handleCloseArchiveDialog}
        onArchive={handleConfirmArchive}
        title={`Archive the document '${truncateString(
          documentData?.name,
          30
        )}'?`}
      />
      <InformationDialog
        open={toShowArchiveVersionDialog}
        onClose={() => {setToShowArchiveVersionDialog(false);}}
        title={'Cannot Archive Document'}
        text={'All previous versions must be archived before document can be archived.'}
      />
      <DocumentLinkDialog
        open={toShowLinkDialog}
        onClose={() => setToShowLinkDialog(false)}
        title={`Link to '${truncateString(documentData?.name, 40)}'`}
        docId={documentData?.id}
      />
      <InformationDialog
        open={toShowArchiveErrorDialog}
        onClose={() => {setToShowArchiveErrorDialog(false);}}
        title={'Cannot Archive Document'}
        text={'This document version is attached to a task and cannot be archived. First remove document from task to archive.'}
      />
    </>
  );
}

export default DocumentDetails;
