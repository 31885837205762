import React, { useState, useEffect } from 'react';
import { RouterLink } from '../components/RouterLink';
import {
  // Accordion,
  // AccordionSummary,
  // AccordionDetails,
  Breadcrumbs,
  Button,
  Backdrop,
  Paper,
  FormControl,
  Grid,
  IconButton,
  CircularProgress,
  MenuItem,
  Typography,
  Select,
  Tooltip,
  InputLabel,
  withStyles,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Checkbox from '@material-ui/core/Checkbox';
import moment from 'moment';
import { formatDate } from '../helpers';
import * as Sentry from '@sentry/browser';
import API from '../services/ApiService';
import Page from '../components/Page';

import ClearIcon from '@material-ui/icons/Clear';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import AirEmissionsIcon from '../images/metric_topic_icons/air_emissions.svg';
import EnergyManagementIcon from '../images/metric_topic_icons/energy_management.svg';
import FleetFuelIcon from '../images/metric_topic_icons/fleet_fuel.svg';
import GreenHouseGasIcon from '../images/metric_topic_icons/greenhouse_gas_emissions.svg';
import WasteIcon from '../images/metric_topic_icons/waste_and_hazardous_materials.svg';
import WaterIcon from '../images/metric_topic_icons/water_management.svg';

import {
  showErrorResultBar,
} from '../components/ResultSnackbar';
import { getMetricSummaryName } from './TheMetricSummaryPage';

const TOPIC_ICONS_MAP = {
  'Air Emissions': <AirEmissionsIcon />,
  'Energy Management': <EnergyManagementIcon />,
  'Fleet Fuel Management': <FleetFuelIcon />,
  'Greenhouse Gas Emissions': <GreenHouseGasIcon />,
  'Waste & Hazardous Materials Management': <WasteIcon />,
  'Water Management': <WaterIcon />,
};

const capitalizeFirstLetterOfEveryWord = (text) => {
  return text.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
};

const styles = (theme) => ({
  breadcrumb: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  backdrop: {
    // color: theme.palette.primary.main,
    // color: 'white',
    zIndex: theme.zIndex.drawer + 1,
  },
  circularProgress: {
    zIndex: theme.zIndex.drawer + 2,
    position: 'absolute',
    left: '50%',
    top: '50%',
  },
  fields: {
    paddingLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  primary_results: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    padding: theme.spacing(2, 1),
    marginTop: theme.spacing(3),
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  primaryCards: {
    padding: theme.spacing(1, 1),
  },
  formControl: {
    marginTop: '15px',
  },

  title: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginLeft: theme.spacing(1),
    },
    '&:hover': {
      '& > *': {
        transform: 'translateX(10px)',
        transition: 'all 0.2s ease-out',
      },
    },
  },
});

const TheMetricDashboardPage = ({ match, classes, auth, history }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isPayloadEmpty, setPayloadEmpty] = useState(false);
  const [dashboardData, setDashboardData] = useState([]);
  const [orgProcessId, setOrgProcessId] = React.useState('');
  const [facilities, setFacilities] = useState([]);
  const [startDateTime, setStartDateTime] = React.useState(null);
  const [endDateTime, setEndDateTime] = React.useState(null);

  const handleEndDateChange = async (date) => {
    setEndDateTime(date);

    const payloadParams = {
      startDatetime: startDateTime ? formatDate(startDateTime) : null,
      endDatetime: formatDate(date),
      orgProcessId: orgProcessId.length > 0 ? orgProcessId : null,
    };

    if (date < startDateTime && startDateTime !== null) {
      showErrorResultBar('End Date must be after Start Date');
      setEndDateTime(null);
    } else {
      const res = await API.get('org-metric-summary', {
        params: payloadParams,
      });

      try {
        setIsLoading(true);
        const groups = res.data.data.reduce((groups, item) => {
          const group = groups[item.accountingMetricId] || [];
          group.push(item);
          groups[item.accountingMetricId] = group;
          return groups;
        }, {});
        setDashboardData(groups);
        setIsLoading(false);
      } catch (err) {
        showErrorResultBar('Unexpected error loading metric fields');
        Sentry.captureException(err);
      }
    }
  };

  function handleEndClr(e) {
    e.stopPropagation();
    handleEndDateChange(null);
  }

  const handleStartDateChange = async (date) => {
    setStartDateTime(date);

    const payloadParams = {
      startDatetime: formatDate(date),
      endDatetime: endDateTime ? formatDate(endDateTime) : null,
      orgProcessId: orgProcessId.length > 0 ? orgProcessId : null,
    };

    if (date > endDateTime && endDateTime !== null) {
      showErrorResultBar('Start Date must be before End Date');
      setStartDateTime(null);
    } else {
      const res = await API.get('org-metric-summary', {
        params: payloadParams,
      });

      try {
        setIsLoading(true);
        const groups = res.data.data.reduce((groups, item) => {
          const group = groups[item.accountingMetricId] || [];
          group.push(item);
          groups[item.accountingMetricId] = group;
          return groups;
        }, {});
        setDashboardData(groups);
        setIsLoading(false);
      } catch (err) {
        showErrorResultBar('Unexpected error loading metric fields');
        Sentry.captureException(err);
      }
    }
  };

  function handleStartClr(e) {
    e.stopPropagation();
    handleStartDateChange(null);
  }

  const handleFacilityChange = async (event) => {
    const facilityID = event.target.value;
    setOrgProcessId(facilityID);

    const payloadParams = {
      startDatetime: startDateTime ? formatDate(startDateTime) : null,
      endDatetime: endDateTime ? formatDate(endDateTime) : null,
      orgProcessId: facilityID.length > 0 ? facilityID : null,
    };

    const res = await API.get('org-metric-summary', { params: payloadParams });

    try {
      setIsLoading(true);
      const groups = res.data.data.reduce((groups, item) => {
        const group = groups[item.accountingMetricId] || [];
        group.push(item);
        groups[item.accountingMetricId] = group;
        return groups;
      }, {});
      setDashboardData(groups);
      setIsLoading(false);
    } catch (err) {
      showErrorResultBar('Unexpected error loading metric fields');
      Sentry.captureException(err);
    }
  };

  useEffect(() => {
    async function fetchTrackingData() {
      setIsLoading(true);
      const res = await API.get('org-metric-summary');
      try {
        // Group dataset by accountingMetricId
        const groups = res.data.data.reduce((groups, item) => {
          const group = groups[item.accountingMetricId] || [];
          group.push(item);
          groups[item.accountingMetricId] = group;
          return groups;
        }, {});
        setDashboardData(groups);
        if (Object.keys(groups).length > 0) setPayloadEmpty(false);
        else {
          setPayloadEmpty(true);
        }
        setIsLoading(false);
      } catch (err) {
        showErrorResultBar('Unexpected error loading metric');
        Sentry.captureException(err);
      }
    }
    async function fetchMetricFacilities() {
      const res = await API.get('org-process');
      try {
        setIsLoading(true);
        if (res.data.data) {
          setFacilities(
            res.data.data.map((facility) => ({
              name: facility.name,
              id: facility.id,
            }))
          );
        }
        setIsLoading(false);
      } catch (err) {
        showErrorResultBar('Unexpected error loading facilities');
      }
    }
    fetchTrackingData();
    fetchMetricFacilities();
  }, []);

  if (isLoading) {
    return (
      <>
        <Backdrop className={classes.backdrop} open={isLoading} timeout={500} />
        <CircularProgress
          className={classes.circularProgress}
          color="primary"
          size={60}
        />
      </>
    );
  }

  return (
    <>
      <Paper>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          style={{ padding: '0 1rem' }}
        >
          <Grid item xs={3}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              margin="normal"
              id="startDate"
              label="Start Date"
              value={startDateTime}
              onChange={(e) => handleStartDateChange(e)}
              InputProps={
                startDateTime
                  ? {
                      startAdornment: (
                        <IconButton onClick={(e) => handleStartClr(e)}>
                          <ClearIcon />
                        </IconButton>
                      ),
                    }
                  : {}
              }
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              // format="MM/dd/yyyy"
              margin="normal"
              id="endDate"
              label="End Date"
              value={endDateTime}
              onChange={(e) => handleEndDateChange(e)}
              InputProps={
                endDateTime
                  ? {
                      startAdornment: (
                        <IconButton onClick={(e) => handleEndClr(e)}>
                          <ClearIcon />
                        </IconButton>
                      ),
                    }
                  : {}
              }
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <FormControl className={classes.formControl}>
              <InputLabel
                shrink
                id="demo-simple-select-placeholder-label-label"
              >
                Facility
              </InputLabel>
              <Select
                labelId="Location Filter"
                id="location-filter"
                displayEmpty
                value={orgProcessId}
                onChange={(e) => {
                  handleFacilityChange(e);
                }}
              >
                <MenuItem value={''}>All</MenuItem>
                {facilities.map((facility) => (
                  <MenuItem value={facility.id} key={facility.id}>
                    {facility.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Paper>
      {Object.values(dashboardData).length < 1 && !isPayloadEmpty && (
        <Page center style={{ marginTop: '5vh' }}>
          <Typography variant="h5" gutterBottom color="primary">
            Fetching Metric Fields
          </Typography>
          <Typography gutterBottom>
            We're getting your tracked Metric Fields. Your Dashboard will be
            ready in a moment
          </Typography>
        </Page>
      )}
      {Object.values(dashboardData).length < 1 && isPayloadEmpty && (
        <Page center style={{ marginTop: '5vh' }}>
          <Typography variant="h5" gutterBottom color="primary">
            You currently aren't tracking any metric fields on your Dashboard.
          </Typography>
          <Typography gutterBottom>
            Please go to the{' '}
            <RouterLink to="/metricTracking">Metric Tracking Page</RouterLink>,
            and visit the metric summary for any given metric to add or remove
            specific fields you would like to track on the Dashboard
          </Typography>
        </Page>
      )}
      {Object.values(dashboardData).length > 0 && (
        <Grid container alignItems="stretch">
          {Object.values(dashboardData).map((metricGroup, index) => {
            const accountingMetic = metricGroup[0].accountingMetricDescription;
            const topics = metricGroup[0].topics;
            return (
              <Grid item xs={4} key={index}>
                <Paper
                  elevation={3}
                  className={classes.primary_results}
                  key={index}
                >
                  <Grid
                    container
                    // justifyContent="space-between"
                    alignItems="center"
                  >
                    {topics?.length > 0 &&
                      topics.map((topic, topicIndex) => {
                        if (TOPIC_ICONS_MAP[topic.name]) {
                          return (
                            <Grid item>
                              <Tooltip
                                title={topic.name}
                                placement="top"
                                key={topicIndex}
                              >
                                <img
                                  src={TOPIC_ICONS_MAP[topic.name].type}
                                  alt={topic}
                                  style={{ width: '45px', marginRight: '15px' }}
                                />
                              </Tooltip>
                            </Grid>
                          );
                        }
                        return null;
                      })}
                    <Grid item>
                      <RouterLink
                        color="inherit"
                        to={`/metricTracking/metric/${metricGroup[0].accountingMetricId}/summary`}
                        style={{ textDecoration: 'none' }}
                      >
                        <Typography
                          color="primary"
                          component="div"
                          variant="h5"
                          gutterBottom
                          className={classes.title}
                        >
                          {capitalizeFirstLetterOfEveryWord(accountingMetic)}
                          <ArrowForwardIcon className={classes.arrowIcon} />
                        </Typography>
                      </RouterLink>
                    </Grid>
                  </Grid>
                  {metricGroup.map((primaryMetric, index) => {
                    return (
                      <div
                        key={index}
                        style={{ display: 'block', paddingRight: '1.5rem' }}
                      >
                        <Tooltip
                          title={
                            primaryMetric.unit
                              ? `${primaryMetric.unit.singularName} (${primaryMetric.unit.category})`
                              : ''
                          }
                          placement="top"
                        >
                          <Typography
                            variant="body2"
                            component="div"
                            color="textPrimary"
                          >
                            {getMetricSummaryName({
                              aggregation: primaryMetric.aggregation,
                              secondaryMetricFields:
                                primaryMetric.secondaryMetricFields.length > 0
                                  ? primaryMetric.secondaryMetricFields.map(
                                      (sm) =>
                                        sm.metricField.dataType === 'bool'
                                          ? Object({
                                              metricFieldName: sm.fieldValue,
                                              fieldValue: sm.metricField.name,
                                            })
                                          : Object({
                                              metricFieldName: sm.fieldValue,
                                              fieldValue: sm.fieldValue,
                                            })
                                    )
                                  : null,
                              primaryMetricField:
                                primaryMetric.primaryMetricField,
                            })}

                            {': '}
                            {primaryMetric.value !== null &&
                              <Typography
                                variant="body1"
                                component="span"
                                color="primary"
                              >
                                  {primaryMetric.aggregation.includes('percent')
                                    ? Math.round(primaryMetric.value * 100)
                                    : primaryMetric.value.toLocaleString('en-US')}
                                  {primaryMetric.unit && primaryMetric.unit.abbreviation !== 'unitless'
                                    ? ` ${primaryMetric.unit.abbreviation}`
                                    : null}
                                  {primaryMetric.aggregation.includes('percent')
                                    ? '%'
                                    : ''}
                              </Typography>
                          }
                          {primaryMetric.value === null &&
                              <Typography
                                variant="body1"
                                component="span"
                                color="primary"
                              >
                                  N/A
                              </Typography>
                          }
                          </Typography>
                        </Tooltip>
                      </div>
                    );
                  })}
                </Paper>
              </Grid>
            );
          })}
        </Grid>
      )}
    </>
  );
};

TheMetricDashboardPage.title = 'Metric Dashboard';
TheMetricDashboardPage.requiredAuthZ = {
  tier: 3,
  permission: 'esg_metrics_dashboard',
};
TheMetricDashboardPage.routePath = '/metricDashboard';

export default withStyles(styles, { withTheme: true })(TheMetricDashboardPage);
