import React, { useState } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Formik } from 'formik';
import { FormikProps } from 'formik';
import FormikSelectPermissions from './forms/FormikSelectPermissions';
import * as Yup from 'yup';
import { SaveButton } from './buttons';
import { ConfirmationDialogOrReset } from './dialogs';
import { IOrgRBACJoinDto, IRoleDto } from '../../../backend/src/org-share/interfaces';
import { RouterLink } from './RouterLink';

const useStyles = makeStyles({
  cardHeader: {
    paddingTop: '1rem',
    paddingBottom: 0,
  },
  inputContainer: {
    margin: '0 2rem',
  },
  roleSelectionDescription: {
    paddingLeft: '0.5rem',
    fontWeight: 'normal',
  },
  roleSelectionName: {
    fontWeight: 500,
  },
});

const RequestSchema =
  Yup.object({
    checked: Yup.array().required()
  })
  .test({
    test: function(values: Record<string, any>) {
      const isValid = values.checked.includes(true);

      if(isValid) return true;
      return this.createError({
        path: 'checked',
        message: 'At least one permission must be checked',
      });
    }
  });

export function RoleSelectionLabel({roleSelection }: any) {
  const classes = useStyles();

  return (
    <>
      <Typography
        className={classes.roleSelectionName}
        variant="subtitle1"
      >
        {roleSelection.name}
        {/* {isSelected && ' (current role)'} */}
      </Typography>
      <Typography
        className={classes.roleSelectionDescription}
        variant="subtitle2"
      >
        {roleSelection.description}
      </Typography>
    </>
  );
}

export interface OrgOutgoingAccessPermissionsDetailsProps {
  allRoles: IRoleDto[];
  orgData: IOrgRBACJoinDto;
  onClickSave: (values: string[]) => Promise<void>
}

interface FormValues {
  checked: boolean[];
}

export function OrgOutgoingAccessPermissionsDetails({ allRoles, orgData, onClickSave }: OrgOutgoingAccessPermissionsDetailsProps) {
  const [ toShowConfirmPermissions, setToShowConfirmPermissions] = useState(false);
  const classes = useStyles();

  const handleConfirmPermissions = async (values: Record<string, any>) => {
    const orgRoles: string[] = [];
    allRoles.forEach((r, idx) => values.checked[idx] ? orgRoles.push(r.id) : null);
    await onClickSave(orgRoles);
    setToShowConfirmPermissions(false);
  };

  const handleReset = async (formikProps: FormikProps<FormValues>) => {
    setToShowConfirmPermissions(false);
    formikProps.setFieldValue('checked', allRoles.map(r => orgData.roles.map(role => role.id).includes(r.id) ? true : false));
  };

  return (
    <>
    <Formik
    enableReinitialize
    initialValues={{
      checked: allRoles.map(r => orgData.roles.map(role => role.id).includes(r.id) ? true : false),
    }}
    validationSchema={RequestSchema}
    onSubmit={async (values) => {
      handleConfirmPermissions(values);
    }}
  >
    {(formikProps) => (
    <>
      <Card>
        <CardHeader
          className={classes.cardHeader}
          title={`Update Permissions Granted to ${orgData.toOrg.name}`}
          subheader={
            <Typography variant="body2">
              These are the roles of permissions that {orgData.toOrg.name} will be able to grant to their employees. Visit the <RouterLink to="/permissions">Permissions page</RouterLink> for more information on permissions assigned to each role.
            </Typography>
          }
          titleTypographyProps={{
            variant: 'h6',
          }}
          subheaderTypographyProps={{
            variant: 'button',
          }}
        />
        <CardContent>
          <div className={classes.inputContainer}>
            <FormikSelectPermissions
                allRoles={allRoles}
                formikProps={formikProps}
              />
          </div>
        </CardContent>
        <CardActions>
          <Button
            onClick={() => {handleReset(formikProps);}}
            color="primary"
          >
            Cancel
          </Button>
          <SaveButton
            disabled={formikProps.isSubmitting || Object.keys(formikProps.errors).length > 0}
            onClick={formikProps.handleSubmit}
            text="Update permissions"
          />
        </CardActions>
      </Card>
      <ConfirmationDialogOrReset
          onYes={() => handleConfirmPermissions(formikProps)}
          onNo={() => {handleReset(formikProps);}}
          open={toShowConfirmPermissions}
          title={`Are you sure you want to update permissions for ${orgData.toOrg.name}?`}
          children={`If any roles were revoked, this will automatically remove permissions for any of ${orgData.toOrg.name}'s employees and cannot be reversed.`}
      />
      </>
    )}
  </Formik>

  </>
  );
}
