import { Button, DialogActions, DialogContent, DialogProps, StandardProps } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { IDocument, IDocumentsResponse, IDocumentsUpdateDto, IDocumentUpdateDto } from '../../../../backend/src/document/interfaces';
import API from '../../services/ApiService';
import { showErrorResultBar, showSuccessResultBar } from '../ResultSnackbar';
import StyledDialogTitle from '../StyledDialogTitle';
import ResponsiveDialog from './ResponsiveDialog';
import FormikAutocompleteIdNameField from '../forms/FormikAutocompleteIdNameField';
import { SaveButton } from '../buttons';
import * as Sentry from '@sentry/browser';


const documentSchema = Yup.object({
    document: Yup.object().shape({
        name: Yup.string(),
        id: Yup.string(),
      }).required()
});

export interface IDocumentNameId {
    document: {
        name: string;
        id: string;
    } | null
}

export const HELPER_TEXT = {
    availableDocuments: 'Available documents to attach to evidence'
  };

export interface DocumentAttachDialogProps extends StandardProps<DialogProps, 'children'> {
    onClose: () => void;
    onUpdate: (updatedDocumentsInfo: IDocumentsResponse) => void;
    evidenceData: IDocument[];
}

export default function DocumentAttachDialog(props: DocumentAttachDialogProps) {
    const { onClose, onUpdate, open , evidenceData} = props;
    const [documents, setDocuments] = useState([]);
    useEffect(() => {
        async function fetchDocuments() {
            const res = await API.get(
                'document'
            );
            try {
                if (res.data) {
                    setDocuments(
                        res.data.map((document: any) => ({name: document.name, id: document.id}))
                    );
                }
            } catch (err) {
                showErrorResultBar('Unexpected error loading documents');
            }
        }

    fetchDocuments();
    }, []);

    const handleSave = async (formValue: IDocumentNameId ) => {
        const updateDto: IDocumentsUpdateDto = {
            documentIds: formValue.document ? [formValue.document.id] : [],
            ids: evidenceData.map(documents => documents.id)
        };

        try {
            const res: IDocumentsResponse = (await API.patch('document/attach', updateDto)).data.data;
            onUpdate(res);
            onClose();
            showSuccessResultBar(`Evidence assignment${documents.length > 1 ? 's' : ''} updated.`);
        } catch (err) {
            showErrorResultBar('Unexpected error attaching document');
            Sentry.captureException(err);
        }
    };

    return (
        <ResponsiveDialog
            open={open}
            onClose={onClose}>
            <StyledDialogTitle onClose={onClose}>
            Attach Document
            </StyledDialogTitle>
            <Formik
                enableReinitialize
                initialValues={{
                    document: null
                }}
                validationSchema={documentSchema}
                onReset={onClose}
                onSubmit={async (value, { setSubmitting }) => {
                    await handleSave(value);
                    setSubmitting(false);
                  }}>
                    {formikProps => (
                        <>
                        <DialogContent>
                        <div>
                        <div style={{width: '95%'}}>
                            <FormikAutocompleteIdNameField
                            options={documents}
                            field="document"
                            formikProps={formikProps}
                            helperTextStr={HELPER_TEXT.availableDocuments}
                            label="Document"
                            />
                        </div></div>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                disabled={formikProps.isSubmitting}
                                onClick={formikProps.handleReset}
                                color="primary"
                            >
                                {evidenceData === null ? 'Cancel' : 'Close'}
                            </Button>
                            <SaveButton
                                disabled={formikProps.isSubmitting || Object.values(formikProps.errors).filter(v => !!v).length > 0}
                                onClick={formikProps.handleSubmit}
                            />
                        </DialogActions>
                        </>
                    )}
            </Formik>
        </ResponsiveDialog>
    );
}
