import { Button, DialogActions, DialogContent, DialogContentText, StandardProps } from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/styles';
import * as Sentry from '@sentry/browser';
import React, { useState } from 'react';
import { IIdNameDto } from '../../../../backend/src/common/id-name-dto.interface';
import API from '../../services/ApiService';
import { showErrorResultBar } from '../ResultSnackbar';
import StyledDialogTitle from '../StyledDialogTitle';
import ResponsiveDialog from './ResponsiveDialog';
import { handleError } from '../../helpers';

const useStyles = makeStyles({
  cancelButton: {
    marginRight: 'auto',
  },
  dialogContent: {
    minWidth: '400px',
  },
});

export interface RemoveRiskFromTaskDialogProps extends StandardProps<DialogProps, 'children'> {
  risk: IIdNameDto;
  task: IIdNameDto;
  onClose: () => void;
  onExited: () => void;
  onRemoveRisk: (toArchive: boolean) => void;
}

export default function RemoveRiskFromTaskDialog(props: RemoveRiskFromTaskDialogProps) {
  const classes = useStyles();
  const { risk, task, onClose, onExited, onRemoveRisk, open } = props;
  const [ isSubmitting, setIsSubmitting ] = useState<boolean>(false);

  const handleArchive = async () => {
    try {
      await API.patch(`materiality/${risk.id}`, { status: 'archived'});
    } catch (err) {
      handleError(err, 'Error archiving the material issue');
    }
  };

  const handleRemove = async () => {
    try {
      await API.delete(`task/${task.id}/materiality/${risk.id}`);
    } catch (err) {
      handleError(err, 'Error removing the material issue from the task');
    }
  };

  const handleRemoveAndConditionallyArchive = (toArchive: boolean) => async () => {
    setIsSubmitting(true);
    await handleRemove();
    if (toArchive) {
      await handleArchive();
    }
    onRemoveRisk(toArchive);
    setIsSubmitting(false);
  };

  return (
    <ResponsiveDialog
      disableBackdropClick={true}
      open={open}
      onClose={onClose}
      onExited={onExited}
    >
      <StyledDialogTitle
        onClose={onClose}
      >
        Remove this risk?
      </StyledDialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText>
          The risk '{risk.name}' will be unlinked from this task.
          In addition, do you wish to archive this risk?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.cancelButton}
          color="primary"
          disabled={isSubmitting}
          onClick={onClose}
          size="small"
        >
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={isSubmitting}
          onClick={handleRemoveAndConditionallyArchive(false)}
          size="small"
        >
          Remove only
        </Button>
        <Button
          color="primary"
          disabled={isSubmitting}
          onClick={handleRemoveAndConditionallyArchive(true)}
          size="small"
        >
          Remove and archive
        </Button>
      </DialogActions>
    </ResponsiveDialog>
  );
}
