import { TableCell, TableRow } from '@material-ui/core';
import * as Sentry from '@sentry/browser';
import React, { useEffect, useState } from 'react';
import { IStakeholderDto } from '../../../backend/src/stakeholder/interfaces';
import API from '../services/ApiService';
import Auth from '../services/AuthService';
import DataTableTitleWithButton from '../components/DataTableTitleWithButton';
import { StakeholderEditDialog } from '../components/dialogs';
import { showErrorResultBar } from '../components/ResultSnackbar';
import SpioDataTable, { SpioDataTableColumn } from '../components/SpioDataTable';
import StakeholderDetails from '../components/StakeholderDetails';
import { stakeholderReasonMap, stakeholderStatusMap, stakeholderTypeMap } from '../components/StakeholderDetailsInfo';
import { truncateString } from '../helpers';

export const UNASSIGNED_DATA_STR = '(blank)';

const getTableColumns = (tableData: ITableDatum[]): SpioDataTableColumn[] => [
    {
        name: 'id',
        label: 'ID',
        options: {
            display: 'false',
            filter: false,
        },
    },
    {
        name: 'stakeholderName',
        label: 'Stakeholder',
        options: {
            filter: false,
        },
    },
    {
        name: 'typeStr',
        label: 'Type',
    },
    {
        name: 'reasonStr',
        label: 'Reason',
    },
    {
        name: 'statusStr',
        label: 'Status',
    },
    {
        name: 'dateEngaged',
        label: 'Date Engaged',
        options: {
            customFilterListOptions: { render: v => `Date Engaged: ${v || UNASSIGNED_DATA_STR}` },
            display: 'true',
        },
    },
    {
        name: 'reviewedAt',
        label: 'Last Review Date',
        options: {
            customFilterListOptions: { render: v => `Last Review: ${v || UNASSIGNED_DATA_STR}` },
            display: 'false',
        },
    },
    {
        name: 'retiredAt',
        label: 'Retirement Date',
        options: {
            customFilterListOptions: { render: v => `Retirement Date: ${v || UNASSIGNED_DATA_STR}` },
            display: 'false',
        },
    },
    {
        name: 'ownerEmail',
        label: 'Owner Email',
        options: {
            filter: false,
            display: 'false',
        },
    },
    {
        name: 'department',
        label: 'Department',
        options: {
            filter: false,
            display: 'false',
        },
    },
    {
        name: 'questionnaireNumber',
        label: 'Number of Questionnaires Sent',
        options: {
            filter: false,
            display: 'false',
        },
    },
    {
        name: 'externalId',
        label: 'External ID',
        options: {
            customFilterListOptions: { render: v => `External ID: ${v || UNASSIGNED_DATA_STR}` },
            display: 'false',
        },
    },
    {
        name: 'contactEmail',
        label: 'Contact Email',
        options: {
            filter: false,
            display: 'false',
        },
    },
    {
        name: 'contactName',
        label: 'Contact Name',
        options: {
            filter: false,
            display: 'false',
        },
    },
    {
        name: 'contactPhone',
        label: 'Contact Phone',
        options: {
            filter: false,
            display: 'false',
        },
    },
    {
        name: 'contactTitle',
        label: 'Contact Title',
        options: {
            filter: false,
            display: 'false',
        },
    },
    {
        name: 'notes',
        label: 'Notes',
        options: {
            customBodyRenderLite: dataIndex => truncateString(tableData[dataIndex]?.notes || ''),
            display: 'false',
            filter: false,
        },
    },
    {
        name: 'nbAttachments',
        label: 'Attachments',
        options: {
            display: 'false',
            filter: false,
            searchable: false,
        },
    },
];

interface ITableDatum extends IStakeholderDto {
    nbAttachments: string;
    reasonStr: string;
    statusStr: string;
    typeStr: string;
}

export interface TheStakeholderTrackingPageProps {
    auth: Auth;
  }

function TheStakeholderTrackingPage({ auth }: TheStakeholderTrackingPageProps) {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState<ITableDatum[]>([]);
    const [stakeholders, setStakeholders] = useState<IStakeholderDto[]>([]);
    const [stakeholderLimit, setStakeholderLimit] = useState(0);

    useEffect(() => {
        setIsLoading(true);

        API.get('stakeholder')
            .then(res => setStakeholders(res.data?.data ?? []))
            .catch((err) => {
                showErrorResultBar('Unexpected error loading stakeholders');
                Sentry.captureException(err);
            })
            .finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        setStakeholderLimit(auth.getStakeholderLimit());
    }, [auth]);

    const stakeholderLimitReached = (!isLoading && stakeholders.length < stakeholderLimit ? false : true);

    useEffect(() => {
        setTableData(stakeholders.map(d => Object({
            ...d,
            nbAttachments: d.documents?.length ? d.documents.length.toString() : '',
            reasonStr: (d.reason && stakeholderReasonMap[d.reason]) || 'Unknown',
            statusStr: (d.status && stakeholderStatusMap[d.status]) || 'Unknown',
            typeStr: (d.type && stakeholderTypeMap[d.type]) || 'Unknown',
            questionnaireNumber: d.questionnaires.length
        })));
    }, [stakeholders]);

    const handleArchiveStakeholder = (selectedIdx: number) => () => {
        const updatedStakeholders = stakeholders.slice();
        updatedStakeholders.splice(selectedIdx, 1);
        setStakeholders(updatedStakeholders);
    };

    const handleCreateStakeholder = (newStakeholder: IStakeholderDto) => {
        const updatedStakeholderData = [newStakeholder].concat(stakeholders);
        setStakeholders(updatedStakeholderData);
    };

    const handleUpdateStakeholder = (selectedIdx: number) => (updatedStakeholder: IStakeholderDto) => {
        const updatedStakeholders = stakeholders.slice();
        updatedStakeholders[selectedIdx] = updatedStakeholder;
        setStakeholders(updatedStakeholders);
    };

    const [questionnaireTemplates, setQuestionnaireTemplates] = useState([]);

    useEffect(() => {
        API.get('stakeholder-questionnaire-template')
          .then((res) => {
            const questionnaireTemplates = res.data.data;
            setQuestionnaireTemplates(questionnaireTemplates);
          });
      }, []);

    return (
        <>
            <SpioDataTable
                title={<DataTableTitleWithButton
                    onButtonClick={() => setIsDialogOpen(true)}
                    title="Stakeholder Engagement"
                    limitReached={stakeholderLimitReached}
                    disabled={!auth.isGranted({ permission: 'stakeholder_engagement:edit'})}
                />}
                columns={getTableColumns(tableData)}
                data={tableData}
                options={{
                    print: false,
                    filterType: 'multiselect',
                    selectableRows: 'none',
                    textLabels: {
                        body: {
                            noMatch: isLoading ? 'Loading...' : 'No records found',
                            toolTip: 'Sort',
                        },
                    },
                    expandableRows: true,
                    expandableRowsOnClick: true,
                    renderExpandableRow: (rowData, rowMeta) => {
                        const colSpan = rowData.length + 1;
                        const myData = stakeholders[rowMeta.dataIndex];

                        return myData ? (
                            <TableRow>
                                <TableCell colSpan={colSpan}>
                                    <StakeholderDetails
                                        stakeholderData={myData}
                                        onArchive={handleArchiveStakeholder(rowMeta.dataIndex)}
                                        onUpdateStakeholder={handleUpdateStakeholder(rowMeta.dataIndex)}
                                        questionnaireTemplates={questionnaireTemplates}
                                        auth={auth}
                                    />
                                </TableCell>
                            </TableRow>
                        ) : null;
                    },
                }}
            />
            <StakeholderEditDialog
                onClose={() => setIsDialogOpen(false)}
                onSave={handleCreateStakeholder}
                open={isDialogOpen}
                stakeholderData={null}
            />
        </>
    );
}

TheStakeholderTrackingPage.requiredAuthZ = {
    tier: 3,
    permission: 'stakeholder_engagement',
};
TheStakeholderTrackingPage.routePath = '/stakeholder-engagement';
TheStakeholderTrackingPage.title = 'Stakeholder Engagement';

export default TheStakeholderTrackingPage;
