import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function DeleteRowRaw() {
  return (
    <path d="M20.365,8.135L10.55,8L12.55,10L14,10L14,11.45L16,13.45L16,10L20,10L20,14L16.55,14L18.55,16L20,16C21.264,16.041 21.886,15.42 21.865,14.135L21.865,10.135C21.865,9.035 21.465,8.135 20.365,8.135ZM1.27,1.27L0,2.55L5.365,8.135L3.641,8.135C2.66,8.135 1.865,8.93 1.865,9.911C1.865,11.681 1.865,14.135 1.865,14.135C1.865,15.235 2.765,16.135 3.865,16.135L12.865,16.135L21.46,24L22.73,22.73L1.27,1.27ZM10,12.55L11.45,14L10,14L10,12.55ZM8,14L4,14L4,10L7.45,10L8,10.55L8,14Z" />
  );
}

function InsertRowAboveRaw() {
  return (
    <>
      <path d="M20,14L4,14C2.9,14 2,14.9 2,16L2,20C2,21.1 2.9,22 4,22L20,22C21.1,22 22,21.1 22,20L22,16C22,14.9 21.1,14 20,14ZM8,20L4,20L4,16L8,16L8,20ZM14,20L10,20L10,16L14,16L14,20ZM20,20L16,20L16,16L20,16L20,20Z" />
      <g transform="matrix(0.642857,0,0,0.846154,4.20328,-0.538462)">
          <path d="M19,9L15,9L15,3L9,3L9,9L5,9L12,16L19,9Z"/>
      </g>
    </>
  );
}

export function InsertRowAboveIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <InsertRowAboveRaw />
    </SvgIcon>
  );
}

export function InsertRowBelowIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g transform="rotate(180,12,12)">
        <InsertRowAboveRaw />
      </g>
    </SvgIcon>
  );
}

export function InsertColumnBeforeIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g transform="rotate(-90,12,12)">
        <InsertRowAboveRaw />
      </g>
    </SvgIcon>
  );
}

export function InsertColumnAfterIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g transform="rotate(90,12,12)">
        <InsertRowAboveRaw />
      </g>
    </SvgIcon>
  );
}

export function DeleteRowIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <DeleteRowRaw />
    </SvgIcon>
  );
}

export function DeleteColumnIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g transform="rotate(90,12,12)">
        <DeleteRowRaw />
      </g>
    </SvgIcon>
  );
}
