import { Button, Link, makeStyles, Typography, createStyles, Theme } from '@material-ui/core';
import * as Sentry from '@sentry/browser';
import React, { useState } from 'react';
import { ITrackViewStart, ITrainingViewDto } from '../../../backend/src/training/interfaces';
import { Link as RouterLink } from 'react-router-dom';
import BuildingBlocksImage from '../components/BuildingBlocksImage';
import Page from '../components/Page';
import PageActions from '../components/PageActions';
import { showErrorResultBar } from '../components/ResultSnackbar';
import StylizedName from '../components/StylizedName';
import { GEMINI_SUPPORT_EMAIL, StaticLinks, TrainingVids } from '../helpers';
import API from '../services/ApiService';
import Auth from '../services/AuthService';

const useStyles = makeStyles((theme: Theme) => createStyles({
  welcomeText: {
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '1.5rem',
    maxWidth: '40rem',
    paddingLeft: '1em',
    paddingRight: '1em',
  },
  welcomeTextBold: {
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '1.5rem',
    maxWidth: '40rem',
    paddingLeft: '1em',
    paddingRight: '1em',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  secondButton: {
    marginLeft: '1rem',
  },
  siteName: {
    color: '#000000',
  }
}));

export interface TheRegistrationClosedPageProps {
  auth: Auth;
}

function TheRegistrationClosedPage({ auth }: TheRegistrationClosedPageProps) {
  const classes = useStyles();

  return (
    <Page center>
      <BuildingBlocksImage />
      <Typography
        variant="h4"
        gutterBottom
      >
        Thank you for your interest in <StylizedName className={classes.siteName} />!
      </Typography>

      <Typography
        variant="h6"
        gutterBottom
      >
        We are not currently accepting new registrations at this time. If you are interested in Rho Impact's advisory services, please <Link href={`mailto:${GEMINI_SUPPORT_EMAIL}`}>contact us</Link>.
      </Typography>
    </Page>
  );
}

TheRegistrationClosedPage.title = 'Welcome';

export default TheRegistrationClosedPage;
