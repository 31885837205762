import { Button, Card, CardActions, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { IOrg } from '../../pages/TheOrganizationPage';
import { SubscriptionPlanGrid } from '../org/OrgSubscriptionSettings';

const useStyles = makeStyles({
  switchField: {
    marginLeft: 0,
  },
  headerCard: {
    width: '100%',
    margin: 0,
  },
  leftIcon: {
    marginRight: '0.5rem',
  },
  addOrgButton: {
    marginTop: 0,
    marginBottom: 0,
  },
});

export interface RegSubscriptionSettingsProps {
  onClickGoBack: () => void;
  onEnterPaymentMethodClick: () => void;
  onUpdatePlanId: (planId: number) => void;
  org: IOrg;
}

export default function RegSubscriptionSettings({ onClickGoBack, onEnterPaymentMethodClick, onUpdatePlanId, org }: RegSubscriptionSettingsProps) {
  const classes = useStyles();
  const [ selectedPlanId, setSelectedPlanId ] = useState<number>(org.tier);

  function handleClickPlan(planId: number) {
    setSelectedPlanId(planId);
    onUpdatePlanId(planId);
  }

  function handleClickGoBack() {
    onClickGoBack();
  }

  return (
    <>
      <Card className={classes.headerCard}>
        <CardContent>
          <SubscriptionPlanGrid
            onClickPlan={handleClickPlan}
            isEditing={true}
            selectedPlanId={selectedPlanId}
          />
        </CardContent>
        <CardActions>
          <Button
            color="primary"
            onClick={handleClickGoBack}
          >
            Go back
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={selectedPlanId === 0}
            onClick={onEnterPaymentMethodClick}
          >
            Enter payment method
          </Button>
        </CardActions>
      </Card>
    </>
  );
}
