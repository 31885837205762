import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Divider,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  withStyles,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { TheOrganizationPage, TheOrgSharePage, TheSubscriptionPage, TheUserManagementPage } from '../pages';
import ChangeOrgMenu from './ChangeOrgMenu';
import API from '../services/ApiService';
import { showErrorResultBar } from './ResultSnackbar';
import * as Sentry from '@sentry/browser';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import SyncAlt from '@material-ui/icons/SyncAlt';
import { showSuccessResultBar } from './ResultSnackbar';

const styles = theme => ({
  navMenuButton: {
    marginRight: 20,
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  divider: {
    marginTop: '4px',
    marginBottom: '4px',
  },
  grow: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  alertIcon: {
    marginRight: 16,

  },
  siteName: {
    // color: theme.palette.primary.contrastText,
  },
  italic: {
    fontStyle: 'italic'
  }
});

const MENU_LINKS = [
  {
    path: TheOrganizationPage.routePath,
    text: 'Organization Settings',
    requiredAuthZ: TheOrganizationPage.requiredAuthZ,
  },
  {
    path: TheUserManagementPage.routePath,
    text: 'Users',
    requiredAuthZ: TheUserManagementPage.requiredAuthZ,
  },
  {
    path: TheSubscriptionPage.routePath,
    text: 'Manage Subscription',
    requiredAuthZ: TheSubscriptionPage.requiredAuthZ,
  },
  {
    path: TheOrgSharePage.routePath,
    text: 'Connected Accounts',
    requiredAuthZ: TheOrgSharePage.requiredAuthZ,
  }
];

function TheAppBar({ auth, classes, handleDrawerToggle, history, onSwitchOrg, title }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [subAnchorEl, setSubAnchorEl] = useState(null);
  const [menuLinks, setMenuLinks] = useState([]);

  useEffect(() => {
    setMenuLinks(MENU_LINKS.filter(link => auth.isGranted(link.requiredAuthZ) || auth.isGranted({permission: 'admin:change_org'})));
  }, [auth]);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSubMenuOpen = (event) => {
    setSubAnchorEl(event.currentTarget);
  };

  const handleSubMenuClose = () => {
    setSubAnchorEl(null);
  };

  const handleSwitchOrg = (orgId) => {
    handleFullMenuClose();
    onSwitchOrg(orgId);
  };

  const handleReturnOrg = async ()=> {
    showSuccessResultBar(`Returning to ${auth.getPrimaryOrgName()}`);
    try {
      await API.post(`auth/activateOrg/${auth.getPrimaryOrgId()}`);
      await auth.renewSession();
      history.push('/dashboard');
      showSuccessResultBar(`Switched org to ${auth.getPrimaryOrgName()}`);
    } catch (err) {
      const errMessage = err?.response?.data?.error ?? 'Error switching orgs';
      showErrorResultBar(errMessage);
      Sentry.captureException(err);
    }
  };

  const handleFullMenuClose = () => {
    handleSubMenuClose();
    handleMenuClose();
  };

  const handleMenuClick = (path) => () => {
    history.push(path);
    handleMenuClose();
  };

  const handleLogout = () => {
    auth.logout();
    handleMenuClose();
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  async function redirectToCustomerPortal() {
    try {
      const portalDetails = await API.post('subscription/customerPortal');

      window.location = portalDetails.data.data.url;
    } catch (err) {
      Sentry.captureException(err);
      showErrorResultBar('Unable to load billing portal');
    }
  }

  return (
    <>
    <AppBar
      position="sticky"
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="Open drawer"
          onClick={handleDrawerToggle}
          className={classes.navMenuButton}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          variant="h6"
          color="inherit"
          className={classes.grow}
        >
          {title}
        </Typography>
        <Hidden
          smDown>
          <Typography
            variant="h6"
            color="inherit"
          >
            {auth.getOrgName()}
            <span style={{ fontWeight: 900 }}>&nbsp;| </span>
          </Typography>
        </Hidden>
        <Hidden
          smDown>
          <Typography
            variant="body2"
            color="inherit"
            className={classes.italic}
          >
            {auth.getUserRole()}
          </Typography>
          <Typography
            variant="h6"
            color="inherit"
          >
            <span style={{ fontWeight: 900 }}>&nbsp;| </span>
          </Typography>
        </Hidden>
        <IconButton
          aria-owns={anchorEl ? 'material-appbar' : undefined}
          aria-haspopup="true"
          onClick={handleProfileMenuOpen}
          color="inherit"
        >
          <img src={auth.getUserAvatarLink()} alt="Avatar" height="40px" />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          PaperProps={{
            style: {
              minWidth: 170,
            },
          }}
        >
          {menuLinks.map(link => ((auth.isPrimaryOrg() || auth.isGranted({ permission: 'admin:change_org'})) &&
              <MenuItem
                key={link.path}
                onClick={handleMenuClick(link.path)}
              >
                {link.text}
              </MenuItem>
          ))}
          { (auth.isPrimaryOrg()) &&
            <Divider className={classes.divider} />
          }
          {((auth.isGranted({ permission: 'subscription' }) && auth.hasBilling() && auth.isPrimaryOrg())  || auth.isGranted({ permission: 'admin:change_org'})) &&
            <MenuItem onClick={redirectToCustomerPortal}>Billing</MenuItem>
          }
          {(auth.isGranted({ permission: 'admin:change_org' }) || auth.isGranted({ permission: 'partner:change_org' })) &&
            <MenuItem
              onClick={handleSubMenuOpen}
            >
              Switch Org
            </MenuItem>
          }
          {auth.isGranted({ permission: 'admin:change_org' }) &&
            <Divider className={classes.divider} />
          }
          {(auth.isGranted({ permission: 'admin:change_org' }) || auth.isGranted({ permission: 'partner:change_org' })) &&
            <ChangeOrgMenu
              auth={auth}
              onClickOrg={handleFullMenuClose}
              onSwitchOrg={handleSwitchOrg}
              anchorEl={subAnchorEl}
              open={Boolean(subAnchorEl)}
              onClose={handleSubMenuClose}
              history={history}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              PaperProps={{
                style: {
                  maxHeight: 500,
                  minWidth: 200,
                },
              }}
            />
          }
          {!auth.isPrimaryOrg() &&
            <MenuItem onClick={handleReturnOrg}>Switch to Primary Account</MenuItem>
          }
          <MenuItem
            onClick={handleLogout}
          >
            Logout
          </MenuItem>
        </Menu>
      </Toolbar>
    {!auth.isPrimaryOrg() &&
      <AppBar
        position="sticky"
        color="default"
      >
        <Toolbar>
          <span className={classes.grow}>
          <ErrorOutlineIcon className={classes.alertIcon} />
          <Typography
              variant="body1"
              color="inherit"
            >
              {`You are currently viewing ${auth.getOrgName()}'s account.`}
            </Typography>
            </span>
            <Typography
              variant="body2"
              color="inherit"
            >
              {`Return to ${auth.getPrimaryOrgName()}'s account.`}
            </Typography>
            <IconButton
              color="inherit"
              aria-label="Switch org"
              onClick={handleReturnOrg}
            >
              <SyncAlt />
            </IconButton>
        </Toolbar>
      </AppBar>
    }
    </AppBar>
    </>
  );
}

export default withStyles(styles, { withTheme: true })(TheAppBar);
