import { TableCell, TableRow } from '@material-ui/core';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import { MUIDataTableTextLabels } from 'mui-datatables';
import React, { useEffect, useState } from 'react';
import { RoleUpdate } from '../../../backend/src/auth/enums';
import { IUserDownloadDto } from '../../../backend/src/user/interfaces';
import SpioDataTable, { SpioDataTableColumn } from './SpioDataTable';
import StyledDataTableTitle from './StyledDataTableTitle';
import UserManagementUserDetails, { IUserManagementFormValues, roleDownloadMap } from './UserManagementUserDetails';
import Auth from '../services/AuthService';

const useStyles = makeStyles({
  nameCellContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatarContainer: {
    display: 'inline-block',
    height: '25px',
    width: '25px',
  },
  nameContainer: {
    marginLeft: '10px',
  },
  blockedContainer: {
    borderTop: '2px solid black',
    borderBottom: '2px solid black',
    marginLeft: '10px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  contain: {
    objectFit: 'contain',
    width: '100%',
    height: '100%',
  },
});

interface INameTableCellProps {
  isBlocked?: boolean;
  isExternal?: boolean;
  name: string;
  pictureUrl?: string;
}

const NameTableCell = ({ isBlocked, isExternal, name, pictureUrl }: INameTableCellProps) => {
  const classes = useStyles();

  return (
    <div className={classes.nameCellContainer}>
      <span className={classes.avatarContainer}>
        {pictureUrl ? (
          <img src={pictureUrl} alt="" className={classes.contain} />
        ) : (
          <PermIdentityIcon />
        )}
      </span>
      <span className={classes.nameContainer}>{name}</span>
      <span className={classes.blockedContainer}>{isBlocked && 'Disabled'}</span>
      <span className={classes.blockedContainer}>{isExternal && 'External'}</span>
    </div>
  );
};

const getTableColumns = (tableData: ITableDatum[]): SpioDataTableColumn[] => {
  return [
    {
      name: 'name',
      label: 'Name',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const { blocked, external, name, picture } = tableData[dataIndex] || {};

          return <NameTableCell
            isBlocked={blocked}
            isExternal={external}
            name={name || ''}
            pictureUrl={picture}
          />;
        },
      },
    },
    {
      name: 'email',
      label: 'Email',
    },
    {
      name: 'lastLoginStr',
      label: 'Last Login',
      options: {
        filter: false,
      },
    },
    {
      name: 'rolesStrArray',
      label: 'Role',
      options: {
        sort: false,
        customBodyRenderLite: dataIndex => tableData[dataIndex]?.rolesStrArray?.map(role => <div key={role}>{role}</div>),
        customFilterListOptions: { render: v => `Role: ${v}` },
      },
    },
    {
      name: 'id',
      label: 'User Id',
      options: {
        display: 'false',
        filter: false,
      },
    },
    {
      name: 'blockedStr',
      label: 'Disabled',
      options: {
        display: 'false',
        filterOptions: { names: [ 'True', 'False' ] },
        customFilterListOptions: { render: v => `Disabled: ${v}` },
      },
    },
    {
      name: 'externalStr',
      label: 'External',
      options: {
        display: 'false',
        filterOptions: { names: [ 'True', 'False' ] },
        customFilterListOptions: { render: v => `External: ${v}` },
      },
    },
  ];
};

export interface UserManagementUsersTableProps {
  isLoading: boolean;
  selfUserId: string;
  toggleUserBlock: (userIdx: number) => Promise<void>;
  updateUserRole: (userIdx: number, newRole: RoleUpdate) => Promise<void>;
  users: IUserDownloadDto[];
  limitReached: boolean;
  auth: Auth;
}

interface ITableDatum extends IUserDownloadDto {
  blockedStr: string;
  externalStr: string;
  lastLoginStr: string;
  rolesStrArray: string[];
}

export default function UserManagementUsersTable(props: UserManagementUsersTableProps) {
  const { isLoading, selfUserId, toggleUserBlock, updateUserRole, users, limitReached, auth } = props;
  const [ tableData, setTableData ] = useState<ITableDatum[]>([]);

  useEffect(() => {
    setTableData(users.map((user) => Object({
      ...user,
      blockedStr: user.blocked ? 'True' : 'False',
      externalStr: user.external ? 'True' : 'False',
      lastLoginStr: moment(user.lastLogin).fromNow(),
      rolesStrArray: user.roles?.map(role => roleDownloadMap[role].text || role) || [],
    })));
  }, [ users ]);

  const clickToggleUserBlock = (userIdx: number) => async () => {
    return toggleUserBlock(userIdx);
  };

  const clickUpdateUserRole = (userIdx: number) => async (values: IUserManagementFormValues) => {
    const newRole = values.role;

    return updateUserRole(userIdx, newRole);
  };

  return (
    <SpioDataTable
      title={
        <StyledDataTableTitle
          title="Users"
          limitReached={limitReached}
        />}
      columns={getTableColumns(tableData)}
      data={tableData}
      options={{
        elevation: 0,
        textLabels: {
          body: {
            noMatch: isLoading ? 'Loading...' : 'No users found',
          },
        } as MUIDataTableTextLabels,
        filterType: 'multiselect',
        print: false,
        download: false,
        selectableRows: 'none',
        sort: true,
        expandableRows: true,
        expandableRowsOnClick: true,
        renderExpandableRow: (rowData, rowMeta) => {
          const colSpan = rowData.length + 1;
          const userData = users[rowMeta.dataIndex];

          return userData ? (
            <TableRow>
              <TableCell colSpan={colSpan}>
                <UserManagementUserDetails
                  isUserSelf={selfUserId === userData.id}
                  onClickToggleUserBlock={clickToggleUserBlock(rowMeta.dataIndex)}
                  onClickSave={clickUpdateUserRole(rowMeta.dataIndex)}
                  userData={userData}
                  limitReached={limitReached}
                  auth={auth}
                />
              </TableCell>
            </TableRow>
          ) : null;
        },
      }}
    />
  );
}
