import {
  TableCell,
  TableRow
} from '@material-ui/core';
import { MUIDataTableTextLabels } from 'mui-datatables';
import React, { useEffect, useState } from 'react';
import { IUserDownloadDto } from '../../../backend/src/user/interfaces';
import SpioDataTable, { SpioDataTableColumn } from './SpioDataTable';
import Auth from '../services/AuthService';
import OrgOutgoingAccessDetails from '../components/OrgOutgoingAccessDetails';
import DataTableTitleWithButton from '../components/DataTableTitleWithButton';
import OrgShareRequestOutboundDialog from './dialogs/OrgShareRequestOutboundDialog';
import { IOrgRBACJoinDto, IRoleDto } from '../../../backend/src/org-share/interfaces';
// import { AccessType, OrgShareStatus } from '../../../backend/src/org-share/enums';
import { AxiosResponse } from 'axios';

const getTableColumns = (tableData: ITableDatum[]): SpioDataTableColumn[] => {
  return [
    {
      name: 'orgName',
      label: 'Organization Name',
    },
    {
      name: 'orgTier',
      label: 'Subscription Type',
    },
    {
      name: 'accessGranted',
      label: 'Access Granted',
      options: {
          download: true,
          display: 'true',
          filter: true,
          customBodyRenderLite: dataIndex => capitalizeFirstLetter(tableData[dataIndex]?.accessGranted),
      },
    },
  ];
};

function capitalizeFirstLetter(str: string) {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  } else {
    return null;
  }
}

interface ITableDatum extends IUserDownloadDto {
    orgName: string;
    actions: React.ReactElement[];
    accessGranted: string;
}

export interface OrgOutgoingAccessTableProps {
  auth: Auth;
  addOutboundOrg: (apiResponse: AxiosResponse<any>) => void;
  allRoles: IRoleDto[];
  handleApproveAccess: (orgRBACId: string, roleIds: string[]) => void;
  isLoading: boolean;
  orgs: IOrgRBACJoinDto[];
  updateOutboundOrg: (apiResponse: AxiosResponse<any>, successMsg: string) => void;
}

export default function OrgOutgoingAccessTable(props: OrgOutgoingAccessTableProps) {
  const { isLoading, orgs, auth, addOutboundOrg, updateOutboundOrg, allRoles, handleApproveAccess } = props;
  const [ tableData, setTableData ] = useState<ITableDatum[]>([]);
  const [ isDialogOpen, setIsDialogOpen ] = useState(false);

  function translateTier(tier: number) {
    if (tier < 2) {
      return 'Free';
    } else if (tier < 5) {
      return 'Standard';
    } else {
      return 'Enterprise';
    }
  }

  useEffect(() => {
    setTableData(orgs.map((org) => Object({
      ...org,
      orgName: org.toOrg?.name || '',
      orgTier: translateTier(org.toOrg.tier),
      permissionsGranted: org.roles.map(role => role.name),
      accessGranted: org.status === 'active' ? org.accessType : org.status,
    })));
  }, [ orgs ]);

  return (
    <>
      <SpioDataTable
        title={<DataTableTitleWithButton
          onButtonClick={() => setIsDialogOpen(true)}
          title={`Organizations with Access to ${auth.getOrgName()}`}
          limitReached={false}
          buttonTitle={'Share access'}
          disabled={!auth.isGranted({ permission: 'org_share:admin' })}
        />}
        columns={getTableColumns(tableData)}
        data={tableData}
        options={{
          elevation: 0,
          textLabels: {
            body: {
              noMatch: isLoading ? 'Loading...' : `No organizations have access to ${auth.getOrgName()}`,
            },
          } as MUIDataTableTextLabels,
          filterType: 'multiselect',
          print: false,
          download: false,
          selectableRows: 'none',
          sort: true,
          expandableRows: true,
          expandableRowsOnClick: true,
          renderExpandableRow: (rowData, rowMeta) => {
            const colSpan = rowData.length + 1;
            const orgData = orgs[rowMeta.dataIndex];

            return orgData ? (
              <TableRow>
                <TableCell colSpan={colSpan}>
                  <OrgOutgoingAccessDetails
                    orgData={orgData}
                    auth={auth}
                    allRoles={allRoles}
                    updateOutboundOrg={updateOutboundOrg}
                    handleApproveAccess={handleApproveAccess}
                  />
                </TableCell>
              </TableRow>
            ) : null;
          },
        }}
      />
      <OrgShareRequestOutboundDialog
        onClose={() => setIsDialogOpen(false)}
        open={isDialogOpen}
        addOutboundOrg={addOutboundOrg}
        allRoles={allRoles}
      />
  </>
  );
}
