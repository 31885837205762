import {
  Card,
  CardContent,
  CardHeader,
  createStyles,
  Grid,
  IconButton,
  Link,
  makeStyles,
  Menu,
  MenuItem,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import AttachIcon from '@material-ui/icons/AttachFile';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import * as Sentry from '@sentry/browser';
import React, { useState } from 'react';
import { IIdNameDto } from '../../../backend/src/common/id-name-dto.interface';
import { IDocument } from '../../../backend/src/document/interfaces/document.interface';
import { QuestionnaireStatus } from '../../../backend/src/inbound-questionnaire/enums';
import { IInboundQuestionnaireDto } from '../../../backend/src/inbound-questionnaire/interfaces';
import { formatDate } from '../helpers';
import API from '../services/ApiService';
import Auth from '../services/AuthService';
import * as DocService from '../services/DocService';
import { ArchiveDialog, FileUploadDialog, QuestionnaireEditDialog, RemoveFileDialog } from './dialogs';
import { showErrorResultBar, showSuccessResultBar } from './ResultSnackbar';
import { DetailSectionHeading } from './MaterialityIssueDetails';
// TODO move this to shared space since also used in Stakeholder?
import { MissingDataText, SecondaryField } from './VendorDetailsInfo';
import { handleError } from '../helpers';

const useStyles = makeStyles((theme: Theme) => createStyles({
  buttonLink: {
    'color': 'inherit',
    'textDecoration': 'underline',
    'cursor': 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  cardHeader: {
    paddingBottom: 0,
  },
  linkItem: {
    '& a': {
      color: theme.typography.body1.color,
      cursor: 'pointer',
    },
    '&:nth-child(n+2)': {
      paddingTop: '4px',
    },
    fontSize: '0.8125rem',
  },
  primaryColumn: {
    color: theme.typography.body1.color,
    fontSize: '14px',
  },
  referencesContainer: {
    paddingLeft: '1rem',
    paddingBottom: '0.8rem',
  },
  referenceResponse: {
    color: 'rgba(0,0,0,0.54)',
    whiteSpace: 'pre-line',
  },
  secondaryColumn: {
    backgroundColor: '#f5f5f5',
    opacity: '0.8',
    fontSize: '0.8125rem',
  },
  smallIconButton: {
    'height': '14px',
    'width': '14px',
    'marginTop': '-6px',
    'marginLeft': '0.5rem',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  smallIconButtonIcon: {
    fontSize: '18px',
  },
  smallIconButtonLabel: {
    height: '0',
  },
}));

export const questionnaireStatusMap: { [key in QuestionnaireStatus]: string } = {
  new: 'New',
  in_progress: 'In Progress',
  in_review: 'Under Review',
  saved: 'Saved',
  completed: 'Completed',
  archived: 'Archived',
};

export interface QuestionnaireDetailsProps {
  auth: Auth,
  onArchive: () => void;
  onUpdate: (newData: IInboundQuestionnaireDto) => void;
  questionnaireData: IInboundQuestionnaireDto;
}

function QuestionnaireDetails({ auth, onArchive, onUpdate, questionnaireData }: QuestionnaireDetailsProps) {
  const classes = useStyles();
  const [documentToArchive, setDocumentToArchive] = useState<IIdNameDto | null>(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [toShowArchiveDialog, setToShowArchiveDialog] = useState(false);
  const [toShowEditDialog, setToShowEditDialog] = useState(false);
  const [toShowFileUploadDialog, setToShowFileUploadDialog] = useState(false);
  const [toShowRemoveEvidenceDialog, setToShowRemoveEvidenceDialog] = useState(false);

  const handleAttachFile = async (newDoc: IDocument[]) => {
    for (const doc of newDoc) {
      try {
        const res = await API.post(`questionnaire/${questionnaireData.id}/documents/${doc.id}`);
        onUpdate(res.data.data);
        showSuccessResultBar('File uploaded');
      } catch (err) {
        handleError(err, 'Error uploading file');
      }
    }
  };

  const handleArchive = async () => {
    try {
      await API.patch(`questionnaire/${questionnaireData.id}`, { status: 'archived' });
      onArchive();
      showSuccessResultBar('Questionnaire archived');
      setToShowArchiveDialog(false);
    } catch (err) {
      handleError(err);
    }
  };

  const handleClickRemoveAttachment = (document: IIdNameDto) => () => {
    setDocumentToArchive(document);
    setToShowRemoveEvidenceDialog(true);
  };

  const handleRemoveAttachment = async (docId: string) => {
    try {
      const res = await API.delete(`questionnaire/${questionnaireData.id}/documents/${docId}`);
      onUpdate(res.data.data);
      showSuccessResultBar('File archived.');

      return true;
    } catch (err) {
      Sentry.captureException(err);
      showErrorResultBar('Unexpected error while archiving file. Please try again.');

      return false;
    }
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const handleClickMenuItem = (handler: () => void) => () => {
    handleCloseMenu();
    handler();
  };

  return (
    <>
      <Card>
        <CardHeader
          action={<>
            <IconButton onClick={({ currentTarget }) => setMenuAnchorEl(currentTarget)}>
              <MoreVertIcon />
            </IconButton>
          </>}
          className={classes.cardHeader}
          title={questionnaireData.name || 'Questionnaire details'}
        />
        <Menu
          anchorEl={menuAnchorEl}
          keepMounted
          open={Boolean(menuAnchorEl)}
          onClose={handleCloseMenu}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <MenuItem onClick={handleClickMenuItem(() => setToShowEditDialog(true))} disabled={!auth.isGranted({permission:'inbound_questionnaire:edit'})}>
            Edit
          </MenuItem>
          <MenuItem onClick={handleClickMenuItem(() => setToShowFileUploadDialog(true))} disabled={!auth.isGranted({permission:'inbound_questionnaire:edit'})}>
            Attach a file
          </MenuItem>
          <MenuItem onClick={handleClickMenuItem(() => setToShowArchiveDialog(true))} disabled={!auth.isGranted({permission:'inbound_questionnaire:edit'})}>
            Archive
          </MenuItem>
        </Menu>
        <CardContent>
          <Grid container alignItems="flex-start" spacing={4}>
            {/* Primary column */}
            <Grid item xs={12} sm={8} md={9} className={classes.primaryColumn}>
              {/* Requestor info */}
              <DetailSectionHeading>
                Requestor info
              </DetailSectionHeading>
              <div className={classes.referencesContainer}>
                {(questionnaireData.requestorEmail || questionnaireData.requestorName) ? (
                  <Grid container>
                    {questionnaireData.requestorEmail && (<>
                      <Grid item xs={2}>Email:</Grid>
                      <Grid item xs={10} className={classes.referenceResponse}>{questionnaireData.requestorEmail}</Grid>
                    </>)}
                    {questionnaireData.requestorName && (<>
                      <Grid item xs={2}>Name:</Grid>
                      <Grid item xs={10} className={classes.referenceResponse}>{questionnaireData.requestorName}</Grid>
                    </>)}
                  </Grid>
                ) : (
                  <MissingDataText />
                )}
              </div>
              {/* Notes */}
              <DetailSectionHeading>
                Notes
              </DetailSectionHeading>
              <div className={classes.referencesContainer}>
                {questionnaireData.notes ? (
                  <span className={classes.referenceResponse}>{questionnaireData.notes}</span>
                ) : (
                  <MissingDataText message="None" />
                )}
              </div>
            </Grid>
            {/* Secondary column */}
            <Grid item xs={12} sm={4} md={3} className={classes.secondaryColumn}>
              {/* Status */}
              <DetailSectionHeading>
                Status: {questionnaireStatusMap[questionnaireData.status]}
              </DetailSectionHeading>
              <div className={classes.referencesContainer}>
                <SecondaryField title="Last status update:"
                  value={formatDate(questionnaireData.statusUpdatedAt) || 'n/a'} />
                <SecondaryField title="Due date:" value={formatDate(questionnaireData.dueDate) || 'n/a'} />
              </div>
              {/* Links */}
              <DetailSectionHeading>
                Link
              </DetailSectionHeading>
              <div className={classes.referencesContainer}>
                {questionnaireData.url ? (
                  <Typography className={classes.linkItem} noWrap>
                    <Link
                      className={classes.buttonLink}
                      href={questionnaireData.url || ''}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {questionnaireData.url}
                    </Link>
                  </Typography>
                ) : <MissingDataText />
                }
              </div>
              {/* Attachments */}
              <DetailSectionHeading>
                Attachments
                <Tooltip
                  title="Attach a file"
                >
                  <IconButton
                    classes={{
                      root: classes.smallIconButton,
                      label: classes.smallIconButtonLabel,
                    }}
                    onClick={() => setToShowFileUploadDialog(true)}
                    disabled={!auth.isGranted({permission:'inbound_questionnaire:edit'})}
                  >
                    <AttachIcon className={classes.smallIconButtonIcon} />
                  </IconButton>
                </Tooltip>
              </DetailSectionHeading>
              <div className={classes.referencesContainer}>
                {questionnaireData.documents?.length ? (
                  questionnaireData.documents.map(doc => (
                    <Grid key={doc.id} container justify="space-between" spacing={0}>
                      <Grid item xs={10}>
                        <Typography className={classes.linkItem} noWrap={true}>
                          <Link
                            className={classes.buttonLink}
                            onClick={DocService.documentDownloadHandler(doc.id)}
                          >
                            {doc.name}
                          </Link>
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Tooltip
                          title="Remove this attachment"
                        >
                          <IconButton
                            classes={{
                              root: classes.smallIconButton,
                              label: classes.smallIconButtonLabel,
                            }}
                            onClick={handleClickRemoveAttachment(doc)}
                          >
                            <DeleteIcon className={classes.smallIconButtonIcon} />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  ))
                ) : (
                  <MissingDataText message="None" />
                )}
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <FileUploadDialog
        dialogHeaderText={`Attach a file to '${questionnaireData.name}'`}
        documentCategory="questionnaire"
        onAddDocuments={handleAttachFile}
        onClose={() => setToShowFileUploadDialog(false)}
        open={toShowFileUploadDialog}
        currentDocuments={[]}
      />
      {documentToArchive &&
        <RemoveFileDialog
          document={documentToArchive}
          onClose={() => setToShowRemoveEvidenceDialog(false)}
          onExited={() => setDocumentToArchive(null)}
          onRemoveFile={handleRemoveAttachment}
          open={toShowRemoveEvidenceDialog}
        />
      }
      {/* Edit dialog */}
      <QuestionnaireEditDialog
        auth={auth}
        open={toShowEditDialog}
        onClose={() => setToShowEditDialog(false)}
        onSave={onUpdate}
        questionnaireData={questionnaireData}
      />
      {/* Archive dialog */}
      <ArchiveDialog
        open={toShowArchiveDialog}
        onClose={() => setToShowArchiveDialog(false)}
        onArchive={handleArchive}
        title={`Archive the questionnaire '${questionnaireData?.name}'?`}
      />
    </>
  );
}

export default QuestionnaireDetails;
