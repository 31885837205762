import React, { useState } from 'react';
import {
  createStyles,
  Accordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Grid,
  Link,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import CommentIcon from '@material-ui/icons/ModeCommentOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as Sentry from '@sentry/browser';
import { ICommentDto } from '../../../backend/src/comment/interfaces';
import API from '../services/ApiService';
import Auth from '../services/AuthService';
import CommentDetails from './CommentDetails';
import CommentInput, { ICommentInputData } from './CommentInput';
import { showSuccessResultBar } from './ResultSnackbar';
import { IDocument } from '../../../backend/src/document/interfaces';
import { handleError } from '../helpers';

const AccordionSummary = withStyles({
  root: {
    'borderBottom': '1px solid rgba(0, 0, 0, .125)',
    'marginBottom': -1,
    'minHeight': 0,
    '&$expanded': {
      minHeight: 0,
    },
  },
  content: {
    'margin': '2px 0',
    '&$expanded': {
      margin: '2px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const styles = (theme: Theme) => createStyles({
  addCommentLink: {
    cursor: 'pointer',
    display: 'inline-block',
    paddingTop: '1rem',
    paddingLeft: '1rem',
  },
  commentsExpansionSummary: {
    'padding': 0,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  commentsExpansionDetails: {
    padding: 0,
  },
  commentIcon: {
    fontSize: '0.875rem',
  },
  commentNumberText: {
    paddingLeft: '2px',
    fontSize: '0.875rem',
  },
  commentHeading: {
    fontWeight: 'bold',
    marginTop: '0.5rem',
    color: 'inherit',
  },
  commentNbHeading: {
    marginLeft: '1rem',
    marginTop: '0.5rem',
    color: 'inherit',
  },
});

export interface DocumentCommentsProps extends WithStyles<typeof styles> {
  auth: Auth;
  comments?: ICommentDto[];
  documentId: string;
  onUpdate: (doc: IDocument) => void;
  readOnly?: boolean;
}

function DocumentComments(props: DocumentCommentsProps) {
  const {
    auth,
    classes,
    comments = [],
    documentId,
    onUpdate,
    readOnly
  } = props;
  const [ isCommenting, setIsCommenting ] = useState(false);
  const [ commentIdEditing, setCommentIdEditing ] = useState<string>();

  const nbComments = comments ? comments.length : 0;

  async function clickOnAddComment(formData: ICommentInputData) {
    await onSaveComment(formData);
    setIsCommenting(false);
  }

  async function clickOnUpdateComment(formData: ICommentInputData) {
    await onSaveComment(formData);
    setCommentIdEditing(undefined);
  }

  const handleDeleteComment = async (commentId: string) => {
    try {
      const res = (await API.delete(`document/${documentId}/comment/${commentId}`)).data.data;
      onUpdate(res);
      showSuccessResultBar('Comment deleted.');
    } catch (err) {
      handleError(err, `Unexpected error while deleting comment: ${err || 'unspecified error'}`);
    }
  };

  const onSaveComment = async (formData: ICommentInputData) => {
    const commentId = formData.id; // 'undefined' if new comment

    try {
      if (commentId) {
        // Updating an existing comment
        const res = (await API.patch(`document/${documentId}/comment/${commentId}`, { text: formData.comment })).data.data;
        onUpdate(res);
      } else {
        // Adding a new comment
        const res = (await API.post(`document/${documentId}/comment`, { text: formData.comment })).data.data;
        onUpdate(res);
      }

      //onUpdate(updatedComments);
    } catch (err) {
      handleError(err, `Unexpected error while saving comment: ${err || 'unspecified error'}`);
    }
  };

  return (
    <Accordion
      elevation={0}
    >
      <AccordionSummary
        className={classes.commentsExpansionSummary}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography
          variant="body1"
          className={classes.commentHeading}
        >
          Comments
        </Typography>
        {nbComments > 0 &&
        <Typography
          variant="body1"
          className={classes.commentNbHeading}
        >
          <CommentIcon className={classes.commentIcon} />
          <span className={classes.commentNumberText}>
              {nbComments}
            </span>
        </Typography>
        }
      </AccordionSummary>
      <AccordionDetails
        className={classes.commentsExpansionDetails}
      >
        <Grid container>
          <Grid item xs={12}>
            {isCommenting ? (
              <CommentInput
                onCancelComment={() => setIsCommenting(false)}
                onSaveComment={clickOnAddComment}
              />
            ) : (
              <Link
                component="a"
                variant="body2"
                onClick={() => setIsCommenting(true)}
                className={classes.addCommentLink}
              >
                Add a comment
              </Link>
            )}
          </Grid>
          <Grid item xs={12}>
            {comments.map((comment) => (
              comment.id === commentIdEditing ?
                <CommentInput
                  key={comment.id}
                  comment={comment}
                  onCancelComment={() => setCommentIdEditing(undefined)}
                  onSaveComment={clickOnUpdateComment}
                />
                :
                <CommentDetails
                  auth={auth}
                  key={comment.id}
                  comment={comment}
                  onClickDelete={() => handleDeleteComment(comment.id)}
                  onClickEdit={() => setCommentIdEditing(comment.id)}
                  readOnly={readOnly}
                />
            ))}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

export default withStyles(styles, { withTheme: true })(DocumentComments);
