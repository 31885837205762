import { IconButton } from '@material-ui/core';
import { IconButtonProps } from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/Save';
import * as React from 'react';

function SaveIconButton(props: IconButtonProps) {
  return (
    <IconButton {...props} type="submit">
      <SaveIcon />
    </IconButton>
  );
}

export default SaveIconButton;
