import React from 'react';
import { Route } from 'react-router-dom';
import {
  AppBar,
  Button,
  Link,
  Toolbar,
  Typography,
  withStyles,
} from '@material-ui/core';
import StylizedName from '../components/StylizedName';
import TheTermsFooter from '../components/TheTermsFooter';
import { StaticLinks } from '../helpers';

const styles = theme => ({
  root: {
    flexGrow: 1,
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },

  grow: {
    flexGrow: 1,
  },

  toolbar: theme.mixins.toolbar,

  main: {
    flex: '1 0 auto',
    padding: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3),
    },
  },

  siteName: {
    // color: theme.palette.primary.contrastText,
  },

  footer: {
    padding: theme.spacing(2),
    backgroundColor: 'transparent',
    flexShrink: 0,
  },
});

function TheQuestionnaireLayout({ classes, auth, component: Component, ...rest }) {
  const clickLogin = () => {
    auth.login();
  };

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <div className={classes.root}>
          <AppBar
            position="static"
            className={classes.appBar}
          >
            <Toolbar>
              <Typography
                variant="h6"
                color="inherit"
                className={classes.grow}
              >
                <Link
                  color="inherit"
                  href={StaticLinks.marketing}
                  target="_blank"
                >
                  <StylizedName className={classes.siteName} />
                </Link>
                <span style={{ fontWeight: 900 }}>&nbsp;| </span>
                {Component.title}
              </Typography>
              <Button
                color="inherit"
                onClick={clickLogin}
              >
                Login
              </Button>
            </Toolbar>
          </AppBar>

          <main className={classes.main}>
            <Component
              {...matchProps}
              auth={auth}
            />
          </main>

          <footer className={classes.footer}>
            <TheTermsFooter />
          </footer>
        </div>
      )}
    />
  );
}

export default withStyles(styles, { withTheme: true })(TheQuestionnaireLayout);
