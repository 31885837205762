import {
    createStyles,
    withStyles,
    WithStyles,
    Typography,
    Tab,
    Tabs,
    Button,
    FormControl,
    Select,
    MenuItem,
    Dialog,
    DialogActions,
    Box,
    DialogContent,
    DialogTitle,
    Tooltip
} from '@material-ui/core';
import { History } from 'history';
import Page from '../components/Page';
import React, { useEffect, useState } from 'react';
import { showErrorResultBar } from '../components/ResultSnackbar';
import API from '../services/ApiService';
import Auth from '../services/AuthService';
import MaterialityAssessmentIssuesTable from '../components/MaterialityAssessmentIssuesTable';
import MaterialityAssessmentFreeResponseTable from '../components/MaterialityAssessmentFreeResponseTable';
import MaterialityAssessmentOverview from '../components/MaterialityAssessmentOverview';
import MaterialityAssessmentMatrix from '../components/MaterialityAssessmentMatrix';
import { RouterLink } from '../components/RouterLink';
import { IOrgMaterialIssueDto } from '../../../backend/src/material-issue/interfaces/org-material-issue-dto.interface';

const styles = createStyles({
    iconContainer: {
        paddingLeft: '1rem',
        textAlign: 'left',
    },
    scoreContainer: {
        paddingLeft: '1rem',
        textAlign: 'left',
    },
    formControl: {
        margin: 0,
        fullWidth: true,
        display: 'flex',
        wrap: 'nowrap'
    },
    disabledButton: {
      color: 'black'
    }
});

export interface TheMaterialityAssessmentPageProps extends WithStyles<typeof styles> {
auth: Auth;
history: History;
}

  function TheMaterialityAssessmentPage({ auth, classes, history }: TheMaterialityAssessmentPageProps) {
    const [ isLoading, setIsLoading ] = useState(true);
    const [ questionnaires, setQuestionnaires ] = useState<any[]>([]);
    const [ selectedTabIdx, setSelectedTabIdx ] = useState(0);
    const [ selectedQuestionnaire, setSelectedQuestionnaire ] = useState<string>('');
    const [ selectedQuestionnaireIdx, setSelectedQuestionnaireIdx ] = useState<number>(0);
    const [ selectedQuestionnaireName, setSelectedQuestionnaireName ] = useState<string>('');
    const [ dialogOpen, setDialogOpen ] = useState<boolean>(false);
    const [ filterOptions, setFilterOptions ] = useState<{[key: string]: string}[] | []>([]);
    const [ noResponsePage, setNoResponsePage ] = useState<boolean>(false);
    const [ materialityMatrixScores, setMaterialityMatrixScores ] = useState<IOrgMaterialIssueDto[]>([]);

    useEffect(() => {
        setIsLoading(true);

        API.get('material-issue/summary')
          .then(res => setQuestionnaires((res.data && res.data.data) || []))
          .catch(() => {
            showErrorResultBar('Unexpected error loading materiality assessment data');
          })
          .finally(() => setIsLoading(false));
      }, []);

      useEffect(() => {
        API.get('material-issue/materialityMatrix')
          .then(res => setMaterialityMatrixScores((res.data && res.data.data) || []))
          .catch(() => {
            showErrorResultBar('Unexpected error loading materiality assessment data');
          });
      }, [selectedQuestionnaire]);

    useEffect(() => {
        const idx = questionnaires.findIndex(q => q.id === selectedQuestionnaire);
        if (idx > -1) {
            setSelectedQuestionnaireName(questionnaires[idx].name);
            setSelectedQuestionnaireIdx(idx);
        }
    }, [selectedQuestionnaire, questionnaires]);

    useEffect(() => {
      if (!isLoading && questionnaires.length > 0) {
        setNoResponsePage(false);
        const activeIdx = questionnaires.findIndex(q => q.active === true);
        if (questionnaires[activeIdx]) {
          setSelectedQuestionnaire(questionnaires[activeIdx].id);
        }
        else {
          setSelectedQuestionnaire(questionnaires[0].id);
        }
      } else if (!isLoading) {
        setNoResponsePage(true);
      }
    }, [questionnaires, noResponsePage, isLoading]);

    useEffect(() => {
      if (questionnaires && questionnaires[selectedQuestionnaireIdx]?.summary?.issues[0].filters) {
        const allFilterOptions = [];
        for (const filter of Object.keys(questionnaires[selectedQuestionnaireIdx]?.summary?.issues[0].filters)) {
          for (const option of Object.keys(questionnaires[selectedQuestionnaireIdx]?.summary?.issues[0].filters[filter])) {
            allFilterOptions.push(Object({option: option, filter: filter}));
          }
        }
        setFilterOptions(allFilterOptions);
      }
    }, [questionnaires, selectedQuestionnaireIdx]);

    function onViewChange(event: React.ChangeEvent<{ value: unknown }>) {
      const newSelection = event.target.value as string;
      API.post(`material-issue/activeAssessment/${newSelection}`).then(() => {
        // wait to update selected questionnaire until scores are updated, so we pull updated scores for materiality matrix
        setSelectedQuestionnaire(newSelection);
      }).catch(() => {
        showErrorResultBar('Unexpected error updating data');
      });
    }

    const handleClickOpen = () => {
      setDialogOpen(true);
    };

    const handleClose = () => {
      setDialogOpen(false);
    };

    const handleClickAllResponseView = () => {
        history.push({
          pathname: `/outbound-questionnaire/responses/${selectedQuestionnaire}`,
        });
    };

    return (<>
    {noResponsePage ?
    <>
    <Page center>
    <Typography
      variant="h5"
      gutterBottom
    >
      Your Organization does not have any materiality assessment responses yet.
    </Typography>
    <Typography
      gutterBottom
    >
      If you haven't yet engaged your stakeholders through a materiality assessment,
      you can send them a questionnaire directly using the  <RouterLink to="/stakeholder-engagement">Stakeholder Tool</RouterLink>
      , or you can generate a survey link in the <RouterLink to="/outbound-questionnaire">Outbound Questionnaire Tool</RouterLink>{' '}
      to engage stakeholders through a method of your choosing. If you'd like help generating a custom materiality survey,
      or if you need support in how to best engage your stakeholders, you can <RouterLink to="/subscription">upgrade</RouterLink>{' '}
      to the Enterprise Tier and we will tailor this to your organization's unique needs.
    </Typography>
    </Page>
    </>
    :
      <>
      <Button onClick={handleClickOpen} size="small" disabled={!auth.isGranted({permission:'materiality_assessment:edit'})}>
        <Tooltip title="Select materiality assessment">
          <Typography variant="h5"  component="h4" className={classes.disabledButton}>
              {selectedQuestionnaireName}
          </Typography>
        </Tooltip>
      </Button>
      <Dialog disableEscapeKeyDown open={dialogOpen} onClose={handleClose}>
        <DialogTitle>
            Set Active Materiality Assessment Survey
        </DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
          <FormControl className={classes.formControl}>
                <Select
                    labelId="questionnaire-select"
                    id="questionnaire-select"
                    value={selectedQuestionnaire ? selectedQuestionnaire : null}
                    label="Questionnaire"
                    onChange={onViewChange}
                    disableUnderline
                >
                    {Object.values(questionnaires).map((q) => (
                    <MenuItem key={q.id} value={q.id}>
                        {q.name}
                    </MenuItem>
                    ))}
                </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose}>Ok</Button>
        </DialogActions>
      </Dialog>
      <Tabs
        value={selectedTabIdx}
        onChange={(e, tabIdx) => setSelectedTabIdx(tabIdx)}
      >
        <Tab label="Results Overview" />
        <Tab label="All Material Issues" />
        <Tab label="Materiality Matrix" />
        <Tab label="Free Responses" />
        {auth.isGranted({permission: 'outbound_questionnaire:responses' }) ?
          <Button
            variant="outlined"
            color="primary"
            //disabled={!isComplete}
            onClick={handleClickAllResponseView}
          >
            View All Responses
          </Button>
        :
          <></>
        }
      </Tabs>
      {selectedTabIdx === 0 &&
        <MaterialityAssessmentOverview
        issues={questionnaires[selectedQuestionnaireIdx]?.summary?.issues?.sort((a: any, b: any) => (a.id < b.id) ? 1 : -1).sort((a: any, b: any) => (a.all < b.all) ? 1 : -1)}
        isLoading={false}
        filterOptions={filterOptions}
        responseCounts={questionnaires[selectedQuestionnaireIdx]?.summary.responseCount}
        selectedQuestionnaire={selectedQuestionnaire}
    />
      }
      {selectedTabIdx === 1 &&
        <MaterialityAssessmentIssuesTable
            issues={questionnaires[selectedQuestionnaireIdx].summary.issues.sort((a: any, b: any) => (a.id < b.id) ? 1 : -1).sort((a: any, b: any) => (a.all < b.all) ? 1 : -1)}
            responseCount={questionnaires[selectedQuestionnaireIdx].summary.responseCount}
            filterOptions={filterOptions}
        />
      }
      {selectedTabIdx === 2 &&
        <MaterialityAssessmentMatrix
        materialityMatrixScores={materialityMatrixScores}
        //isLoading={false}
    />
      }
      {selectedTabIdx === 3 &&
        <MaterialityAssessmentFreeResponseTable
          filterOptions={filterOptions}
          freeResponses={questionnaires[selectedQuestionnaireIdx].summary.freeResponses}
        />
      }
      </>
    }
    </>);
  }

  TheMaterialityAssessmentPage.title = 'Materiality Assessment';
  TheMaterialityAssessmentPage.requiredAuthZ = {
    tier: 3,
    permission: 'materiality_assessment',
  };

  export default withStyles(styles)(TheMaterialityAssessmentPage);
