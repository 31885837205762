import { Tooltip } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import * as Sentry from '@sentry/browser';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { IPolicyAckReportEntryDto } from '../../../backend/src/policy-doc/interfaces';
import { formatDate } from '../helpers';
import API from '../services/ApiService';
import SpioDataTable, { SpioDataTableColumn } from '../components/SpioDataTable';

// Used in the filename of the downloaded report:
const todaysDate = moment().format('YYYYMMDD');

const getTableColumns = (tableData: ITableDatum[]): SpioDataTableColumn[] => [
  {
    name: 'blockedStr',
    label: 'Disabled',
    options: {
      display: 'false',
      filterOptions: { names: [ 'True', 'False' ] },
      customFilterListOptions: { render: v => `Disabled: ${v}` },
    },
  },
  {
    name: 'name',
    label: 'Name',
  },
  {
    name: 'email',
    label: 'Email',
  },
  {
    name: 'policyName',
    label: 'Policy',
  },
  {
    name: 'policyId',
    label: 'Policy ID',
    options: {
      display: 'false',
      filter: false,
    },
  },
  {
    name: 'versionId',
    label: 'Policy Version ID',
    options: {
      display: 'false',
      filter: false,
    },
  },
  {
    name: 'isAcknowledgedStr',
    label: 'Acknowledged',
    options: {
      customBodyRenderLite: (dataIndex) => {
        const isAcknowledged = tableData[dataIndex]?.isAcknowledgedStr === 'True';

        return <Tooltip
          placement="top"
          title={isAcknowledged ? 'Acknowledged' : 'Not yet acknowledged'}
        >
          {isAcknowledged ? <DoneIcon /> : <NewReleasesIcon />}
        </Tooltip>;
      },
      filterOptions: { names: [ 'True', 'False' ] },
      customFilterListOptions: { render: v => `Acknowledged: ${v}` },
    },
  },
  {
    name: 'acknowledgedAtStr',
    label: 'Acknowledged On',
    options: {
      filter: false,
      customFilterListOptions: { render: v => `Acknowledged On: ${v}` },
    },
  },
];

interface ITableDatum extends IPolicyAckReportEntryDto {
  acknowledgedAtStr: string;
  blockedStr: string;
  isAcknowledgedStr: string;
}

function ThePolicyAckReportPage() {
  const [ isLoading, setIsLoading ] = useState(false);
  const [ tableData, setTableData ] = useState<ITableDatum[]>([]);

  useEffect(() => {
    setIsLoading(true);

    API
      .get('policyDoc/report')
      .then((res) => {
        const data: IPolicyAckReportEntryDto[] = res.data?.data || [];
        setTableData(data.map(d => Object({
          ...d,
          acknowledgedAtStr: formatDate(d.acknowledgedAt),
          blockedStr: d.blocked ? 'True' : 'False',
          isAcknowledgedStr: !!d.acknowledgedAt ? 'True' : 'False',
        })));
      })
      .catch(err => Sentry.captureException(err))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <SpioDataTable
      title="Policy Acknowledgement Coverage Report"
      columns={getTableColumns(tableData)}
      data={tableData}
      initFilters={[['False']]}
      options={{
        textLabels: {
          body: {
            noMatch: isLoading ? 'Loading...' : 'No users found',
            toolTip: 'Sort',
          },
        },
        downloadOptions: {
          filename: `PolicyAcknowledgementReport_${todaysDate}.csv`,
          filterOptions: {
            useDisplayedRowsOnly: true,
          },
        },
        filterType: 'multiselect',
        selectableRows: 'none',
      }}
    />
  );
}

ThePolicyAckReportPage.requiredAuthZ = {
  tier: 1,
  permission: 'policies:report',
};
ThePolicyAckReportPage.routePath = '/policies/report';
ThePolicyAckReportPage.title = 'Policy Acknowledgement Report';

export default ThePolicyAckReportPage;
