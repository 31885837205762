import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import PermScanWifi from '@material-ui/icons/PermScanWifi';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import * as Sentry from '@sentry/browser';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { IDocument } from '../../../backend/src/document/interfaces/document.interface';
import { INetworkScanDto } from '../../../backend/src/network-scan/interfaces';
import { NetworkScanStatus } from '../../../backend/src/network-scan/network-scan-status.enum';
import { FileUploadButtonDialog } from '../components/dialogs';
import NetworkScanStatusIcon from '../components/NetworkScanStatusIcon';
import { showErrorResultBar, showSuccessResultBar } from '../components/ResultSnackbar';
import SpioDataTable, { SpioDataTableColumn } from '../components/SpioDataTable';
import { truncateString } from '../helpers';
import API from '../services/ApiService';
import * as DocService from '../services/DocService';

const getTableColumns = (tableData: ITableDatum[]): SpioDataTableColumn[] => [
  {
    name: 'target.org.name',
    label: 'Org Name',
    options: {
      customFilterListOptions: { render: (v: string) => `Org Name: ${v}` },
    },
  },
  {
    name: 'target.name',
    label: 'Target',
    options: {
      customFilterListOptions: { render: (v: string) => `Target: ${v}` },
    },
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      customBodyRenderLite: (dataIndex) => {
        const { status } = tableData[dataIndex] ?? {};

        return status && <NetworkScanStatusIcon status={status as NetworkScanStatus} />;
      },
      customFilterListOptions: { render: (v: string) => `Status: ${v}` },
    },
  },
  {
    name: 'createdAtStr',
    label: 'Requested',
    options: {
      customFilterListOptions: { render: (v: string) => `Requested: ${v}` },
    },
  },
  {
    name: 'startedAtStr',
    label: 'Started',
    options: {
      customFilterListOptions: { render: (v: string) => `Started: ${v}` },
    },
  },
  {
    name: 'toNotifyStr',
    label: 'Notify',
    options: {
      customBodyRenderLite: dataIndex => tableData[dataIndex]?.target?.notify ? <ThumbUpIcon /> : <ThumbDownIcon />,
      customFilterListOptions: { render: (v: string) => `Notify: ${v}` },
      filterOptions: { names: [ 'True', 'False' ] },
    },
  },
];

const getDateStr = (date?: Date) => date ? moment(date).local().fromNow() : '';

interface ScanDetailRowProps {
  data: INetworkScanDto;
  onAddDocuments: (newDoc: IDocument[]) => void;
  onMarkCompleted: React.ReactEventHandler<{}>;
  onMarkStarted: React.ReactEventHandler<{}>;
}

function ScanDetailRow({ data, onAddDocuments, onMarkCompleted, onMarkStarted }: ScanDetailRowProps) {
  return (
    <TableRow>
      <TableCell colSpan={7}>
        <Card>
          <CardContent>
            <Grid container>
              <Grid item xs={2}>
                <Typography variant="body1">
                  Addresses:
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <Typography variant="body2">
                  {data.target.addresses && data.target.addresses.join(', ')}
                </Typography>
              </Grid>
              {data.documents && data.documents.length > 0 &&
              <>
                <Grid item xs={2}>
                  <Typography variant="body1">
                    Documents:
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  {data.documents.map((d, i) => (
                    <Tooltip
                      key={i}
                      title={d.name || ''}
                    >
                      <Button
                        size="small"
                        onClick={DocService.documentDownloadHandler(d.id)}
                      >
                        {truncateString(d.name || d.id)}
                      </Button>
                    </Tooltip>
                  ))
                  }
                </Grid>
              </>
              }
            </Grid>
          </CardContent>

          <CardActions>
            <Button
              disabled={data.status !== 'requested'}
              onClick={onMarkStarted}
            >
              Mark In Progress
            </Button>
            <Button
              disabled={data.status !== 'started'}
              onClick={onMarkCompleted}
            >
              Mark Complete
            </Button>
            {onAddDocuments &&
            <FileUploadButtonDialog
              buttonProps={{ size: 'small' }}
              buttonText="Attach Report"
              documentCategory="report/scan"
              documentOrgId={data.target.orgId}
              onAddDocuments={onAddDocuments}
            />
            }
          </CardActions>
        </Card>
      </TableCell>
    </TableRow>
  );
}

interface ITableDatum extends INetworkScanDto {
  createdAtStr: string;
  endedAtStr: string;
  startedAtStr: string;
  toNotifyStr: string;
}

function TheNetworkScanRequestPage() {
  const [ scanRequests, setScanRequests ] = useState<INetworkScanDto[]>([]);
  const [ tableData, setTableData ] = useState<ITableDatum[]>([]);

  useEffect(() => {
    API.get('networkScan/scans')
      .then(res => {
        setScanRequests(res.data);
      })
      .catch(err => {
        Sentry.captureException(err);
        showErrorResultBar('Error occurred while loading scan requests');
      });
  }, []);

  useEffect(() => {
    setTableData(scanRequests.map((scan) => Object({
      ...scan,
      createdAtStr: getDateStr(scan.createdAt),
      startedAtStr: getDateStr(scan.startedAt),
      toNotifyStr: scan.target?.notify ? 'True' : 'False',
    })));
  }, [ scanRequests ]);

  function replaceScanRequest(idx: number, newScanRequest: INetworkScanDto) {
    const newScanRequests = scanRequests.slice();

    newScanRequests[idx] = newScanRequest;
    setScanRequests(newScanRequests);
  }

  function handleMarkCompleted(idx: number) {
    return () => {
      API.patch(`networkScan/scans/${scanRequests[idx].id}`, {
        status: 'completed',
        endedAt: new Date(),
      })
        .then(res => replaceScanRequest(idx, res.data))
        .catch(err => {
          Sentry.captureException(err);
          showErrorResultBar('Unexpected error occurred while marking scan as completed.');
        });
    };
  }

  function handleMarkStarted(idx: number) {
    return () => {
      API.patch(`networkScan/scans/${scanRequests[idx].id}`, {
        status: 'started',
        startedAt: new Date(),
      })
        .then(res => replaceScanRequest(idx, res.data))
        .catch(err => {
          Sentry.captureException(err);
          showErrorResultBar('Unexpected error occurred while marking scan as started.');
        });
    };
  }

  function handleAddDocument(idx: number) {
    return async (newDoc: IDocument[]) => {
      for (const doc of newDoc) {
        try {
          await API.put(`networkScan/scans/${scanRequests[idx].id}/attach/${doc.id}`);
          const newScanRequests = scanRequests.slice();

          if (!newScanRequests[idx].documents) {
            newScanRequests[idx].documents = [];
          }

          newScanRequests[idx]?.documents!.push({ id: doc.id, name: doc.name || '' });
          setScanRequests(newScanRequests);
          showSuccessResultBar('Document attached successfully.');
        } catch (err) {
          showErrorResultBar();
          throw err;
        }
      }
    };
  }

  return (
    <SpioDataTable
      title="Scan Requests"
      columns={getTableColumns(tableData)}
      data={tableData}
      options={{
        enableNestedDataAccess: '.',
        expandableRows: true,
        expandableRowsOnClick: true,
        renderExpandableRow: (rowData: string[], rowMeta: { dataIndex: number; rowIndex: number }) => {
          const myData = scanRequests[rowMeta.dataIndex];

          return myData ? (
            <ScanDetailRow
              data={myData}
              onAddDocuments={handleAddDocument(rowMeta.dataIndex)}
              onMarkCompleted={handleMarkCompleted(rowMeta.dataIndex)}
              onMarkStarted={handleMarkStarted(rowMeta.dataIndex)}
            />
          ) : null;
        },
        selectableRows: 'none',
      }}
    />);
}

TheNetworkScanRequestPage.icon = <PermScanWifi />;
TheNetworkScanRequestPage.requiredAuthZ = {
  tier: 1,
  permission: 'admin:network_scan_requests',
};
TheNetworkScanRequestPage.routePath = '/admin/networkScan/requests';
TheNetworkScanRequestPage.title = 'Scan Requests';

export default TheNetworkScanRequestPage;
