import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { RoleDownload, RoleUpdate } from '../../../backend/src/auth/enums';
import { IUserDownloadDto } from '../../../backend/src/user/interfaces';
import { SaveButton } from './buttons';
import { RouterLink } from './RouterLink';
import { LimitReachedDialog } from '../components/dialogs';
import Auth from '../services/AuthService';

const REENABLE_USER_TOOLTIP_TEXT = `
  Reinstates the user, allowing them to once again log in to your organization with this email address
`;
const DISABLE_USER_TOOLTIP_TEXT = `
  Removes the user's access to your organization
  by preventing them from logging in or creating a new account with this email address
`;

const useStyles = makeStyles({
  cardHeader: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  selectedRoleContainer: {
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
  },
  roleSelectionContainer: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
    borderRadius: '4px',
  },
  roleSelectionName: {
    fontWeight: 500,
  },
  roleSelectionDescription: {
    paddingLeft: '0.5rem',
    fontWeight: 'normal',
  },
  inputContainer: {
    margin: '0 2rem',
  },
  leftIcon: {
    marginRight: '0.5rem',
  },
  disabled: {
    color: '#bfbfbf',
    backgroundColor: '#fafafa'
  },
});

export type RoleStr =  'Manager' | 'Owner' | 'Trainee' | 'Employee' | 'Data Entry';

export interface IRoleSelection {
  text: RoleStr;
  description: string;
  privileges: string[];
}

// The order here is used for the radio select order (least to most privileges):
export const roleUpdateMap: {[ key in RoleUpdate ]: IRoleSelection} = {
  'Org Trainee': {
    text: 'Trainee',
    description: `
      Least privileged. Recommended for most users.
     `,
    privileges: [
      'Can watch training videos',
      'Can read and download approved policies',
    ],
  },
  'Org Manager': {
    text: 'Manager',
    description: "For users who need to be able to manage the organization's ESG program.",
    privileges: [
      'Read and write access to the Dashboard, Tasks, Policies, Training, Documents, Stakeholders, Materiality Register, Materiality Assessment Results, Inbound & Outbound Questionnaires, and User Management',
      'No access to billing',
    ],
  },
  'Org Employee': {
    text: 'Employee',
    description: 'For users who need to be able to view tools, but not make updates to program.',
    privileges: [
      'Read access to the Dashboard, Policies, Training, Evidence, Materiality Register, Materiality Assessment Results, and ESG Metrics',
      'Read and write access to Tasks and Documents',
      'No access to Stakeholders or Individual Questionnaire Responses',
    ],
  },
  'Data Entry': {
    text: 'Data Entry',
    description: "For users that are entering data, but should not access other aspects of an organization's ESG Program",
    privileges: [
      // update
      'Read and write access to Evidence and Metrics',
      'Access to view Evidence, but not download',
    ],
  },
};

export const roleDownloadMap: {[ key in RoleDownload ]: IRoleSelection} = {
  ...roleUpdateMap,
  'Org Owner': {
    text: 'Owner',
    description: "For users who need to be able to manage billing in addition to the organization's security program.",
    privileges: [
      'Full access to Billing, the Dashboard, Tasks, Policies, Training, Documents, all Tools, and User Management',
    ],
  },
};

const VendorSchema = Yup.object().shape({
  role: Yup
    .mixed()
    .label('Role')
    .oneOf(Object.keys(roleUpdateMap)),
});

export interface RoleSelectionLabelProps {
  isSelected?: boolean;
  roleSelection: IRoleSelection;
}

export function RoleSelectionLabel({ isSelected = false, roleSelection }: RoleSelectionLabelProps) {
  const classes = useStyles();

  return (
    <>
      <Typography
        className={classes.roleSelectionName}
        variant="subtitle1"
      >
        {roleSelection.text}
        {isSelected && ' (current role)'}
      </Typography>
      <Typography
        className={classes.roleSelectionDescription}
        variant="subtitle2"
      >
        {roleSelection.description}
      </Typography>
      <Typography
        className={classes.roleSelectionDescription}
        variant="subtitle2"
      >
        {roleSelection.privileges.join('; ')}.
      </Typography>
    </>
  );
}

function UserDetailsForExternal({ userData }: { userData: IUserDownloadDto }) {
  const classes = useStyles();

  return (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        title={`${userData.name} (External)`}
        titleTypographyProps={{
          variant: 'h6',
        }}
      />
      <CardContent>
        This user has been granted external access to your organization.
      </CardContent>
    </Card>
  );
}

function UserDetailsForSelf({ userData }: { userData: IUserDownloadDto }) {
  const classes = useStyles();

  return (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        title={`${userData.name} (You)`}
        titleTypographyProps={{
          variant: 'h6',
        }}
      />
      <CardContent>
        If you need to update your own role settings please ask another Manager
        within your organization, or feel free to <RouterLink to="/support">contact us</RouterLink>.
      </CardContent>
    </Card>
  );
}

export interface IUserManagementFormValues {
  role: RoleUpdate;
}

export interface UserManagementUserDetailsProps {
  isUserSelf: boolean;
  onClickToggleUserBlock: () => Promise<void>;
  onClickSave: (values: IUserManagementFormValues) => Promise<void>;
  userData: IUserDownloadDto;
  limitReached: boolean;
  auth: Auth;
}

export default function UserManagementUserDetails({ isUserSelf, onClickToggleUserBlock, onClickSave, userData, limitReached, auth }: UserManagementUserDetailsProps) {
  const classes = useStyles();
  const [ currRole, setCurrRole ] = useState<RoleUpdate>();
  const [ isLimitDialogOpen, setIsLimitDialogOpen ] = useState(false);

  const handleClose = () => {
    setIsLimitDialogOpen(false);
  };

  useEffect(() => {
    const userRoles = userData.roles || [];

    // Need to account for the possibility that a user has more than one Role.
    // Indicate their most privileged role as their current Role in the radio.
    if (userRoles.includes('Org Manager')) {
      setCurrRole('Org Manager');
    } else if (userRoles.includes('Org Trainee')) {
      setCurrRole('Org Trainee');
    } else if (userRoles.includes('Org Employee')) {
      setCurrRole('Org Employee');
    } else if (userRoles.includes('Data Entry')) {
      setCurrRole('Data Entry');
    }
  }, [userData]);

  if (isUserSelf) {
    return <UserDetailsForSelf userData={userData} />;
  } else if (userData.external) {
    return <UserDetailsForExternal userData={userData} />;
  } else {
    return (
      <Formik
        enableReinitialize
        initialValues={{
          role: currRole || 'Org Trainee',
        }}
        validationSchema={VendorSchema}
        onSubmit={async (values, { setSubmitting }) => {
          await onClickSave(values);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleReset,
          handleSubmit,
          isSubmitting,
        }) => (
        <>
          <Card>
            <CardHeader
              className={classes.cardHeader}
              title={`Change role${userData.name ? ` for ${userData.name}` : ''}`}
              titleTypographyProps={{
                variant: 'h6',
              }}
              subheader={userData.blocked && 'Disabled'}
              subheaderTypographyProps={{
                variant: 'button',
              }}
            />
            <CardContent>
              <div className={classes.inputContainer}>
                <FormControl
                  margin="dense"
                >
                  <RadioGroup
                    id="role"
                    name="role"
                    value={values.role}
                    onChange={handleChange}
                  >
                    {Object.entries(roleUpdateMap).map(([ key, roleSelection ]) => (
                      <FormControlLabel
                        key={key}
                        disabled={!auth.isGranted({ permission: 'user_management:edit'})}
                        className={classNames(
                          classes.roleSelectionContainer,
                          key === values.role && classes.selectedRoleContainer,
                        )}
                        value={key}
                        control={<Radio />}
                        label={<RoleSelectionLabel
                          isSelected={key === currRole}
                          roleSelection={roleSelection}
                        />}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </div>
            </CardContent>
            <CardActions>
              <Button
                disabled={isSubmitting || values.role === currRole || !auth.isGranted({ permission: 'user_management:edit'})}
                onClick={handleReset}
                color="primary"
              >
                Cancel
              </Button>
              <Tooltip
                title={userData.blocked ? REENABLE_USER_TOOLTIP_TEXT : DISABLE_USER_TOOLTIP_TEXT}
              >
                {userData.blocked ?
                  <>
                  {limitReached ?
                    <Button
                      onClick={() => setIsLimitDialogOpen(true)}
                      className={classes.disabled}
                      disabled={!auth.isGranted({ permission: 'user_management:edit'})}
                    >
                      Reenable user
                    </Button>
                    :
                    <Button
                      onClick={onClickToggleUserBlock}
                      color="primary"
                      disabled={isSubmitting || !auth.isGranted({ permission: 'user_management:edit'})}

                    >
                      Reenable user
                    </Button>
                  }
                  </>
                  :
                  <Button
                  disabled={isSubmitting || !auth.isGranted({ permission: 'user_management:edit'})}
                  onClick={onClickToggleUserBlock}
                    color="primary"
                  >
                    Disable User
                  </Button>
                }
              </Tooltip>
              <SaveButton
                disabled={isSubmitting || values.role === currRole || Object.keys(errors).length > 0 || !auth.isGranted({ permission: 'user_management:edit'})}
                onClick={handleSubmit}
                text="Update role"
              />
            </CardActions>
          </Card>
          <LimitReachedDialog
            open={isLimitDialogOpen}
            onClose={handleClose}
            text={'You have reached the maximum seats available for your subscription plan. In order to enable additional users, either upgrade your subscription or disable existing users.'}
          >
          </LimitReachedDialog>
        </>
        )}
      </Formik>
    );
  }
}
