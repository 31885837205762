import { createStyles, SvgIcon, Theme, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import classNames from 'classnames';
import { CircleCheckIconProps } from './CircleCheckIcon';

const styles = (theme: Theme) => createStyles({
  caption: {
    color: theme.typography.body1.color,
    fontSize: '8pt',
    lineHeight: '0.8',
    paddingTop: '1px',
  },

  circle: {
    height: '24px',
    width: '24px',
    borderRadius: '50%',
    display: 'inline-block',
    border: 'solid 2px',
    borderColor: theme.palette.success.main,
    justifyContent: 'center',
    fontWeight: 'bold',
    fontSize: '12pt',
  },

  root: {
    justifyContent: 'center',
    textAlign: 'center',
    color: theme.palette.text.primary,
    stroke: theme.palette.text.primary,
  },

});

function CircleXIcon({ classes, text, className: classNameProp }: CircleCheckIconProps) {
  return (
    <div className={classNames(classes.root, classNameProp)}>
      <SvgIcon>
        <circle cx="12" cy="12" r="8" strokeWidth="2" fillOpacity="0"/>

        <g transform="matrix(0.823823,-0.823823,0.707107,0.707107,1.10851,15.1133)">
            <path d="M13,5.5C13,4.948 12.615,4.5 12.142,4.5L4.858,4.5C4.385,4.5 4,4.948 4,5.5C4,6.052 4.385,6.5 4.858,6.5L12.142,6.5C12.615,6.5 13,6.052 13,5.5Z" />
        </g>
        <g transform="matrix(0.823541,0.824105,-0.707349,0.706865,8.88919,1.10828)">
            <path d="M13,5.5C13,4.948 12.615,4.5 12.142,4.5L4.858,4.5C4.385,4.5 4,4.948 4,5.5C4,6.052 4.385,6.5 4.858,6.5L12.142,6.5C12.615,6.5 13,6.052 13,5.5Z" />
        </g>

        <circle cx="12" cy="12" r="11" strokeWidth="2" fillOpacity="0"/>
      </SvgIcon>

      {text &&
        <Typography display="block" variant="caption" className={classes.caption}>{text}</Typography>
      }
    </div>
  );
}

export default withStyles(styles, { withTheme: true })(CircleXIcon);
