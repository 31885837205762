import { TextField, FormControl, Tooltip } from '@material-ui/core';
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import { BaseTextFieldProps } from '@material-ui/core/TextField';
import Autocomplete, { AutocompleteProps } from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/styles';
import { FormikProps, FormikValues } from 'formik';
import React, { useState } from 'react';
import { helperAndErrorText } from '.';
import AddIcon from '@material-ui/icons/Add';
import { AddFacilityDialog } from '../dialogs';

const useStyles = makeStyles({
  inputField: {
    marginRight: 0,
    marginLeft: 0,
  },
  flexSpan: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
  }
});

interface FormikFacilityFieldProps<Values extends FormikValues> extends BaseTextFieldProps {
  autocompleteProps: Omit<AutocompleteProps<string, boolean | undefined, boolean | undefined, boolean | undefined>, 'renderInput'>;
  field: keyof Values;
  formikProps: FormikProps<Values>;
  helperTextStr?: string; // can be overridden with the usual 'helperText' prop
  options: any[];
  handleSaveFacility: (formContent: object) => Promise<void>;
}

export default function FormikFacilityField<Values extends FormikValues>(props: FormikFacilityFieldProps<Values>) {
  const classes = useStyles();
  const { autocompleteProps, children, field, handleSaveFacility, formikProps, helperTextStr, options, variant = 'filled', ...otherTextFieldProps } = props;
  const { errors, values, handleChange, handleBlur } = formikProps;
  const fieldStr = field.toString();
  const [ value, setValue ] = useState<string | null | string[]>(values[field]);
  const [ addFacilityDialog, setAddFacilityDialog ] = useState(false);

  const handleOpen = () => {
    setAddFacilityDialog(true);
  };

  return (
    <>
    <div className={classes.flexSpan}>
      <div style={{width: '95%'}}>
        <Autocomplete
          autoComplete
          openOnFocus
          // {...autocompleteProps}
          freeSolo={false}
          id={fieldStr}
          options={options}
          value={value}
          getOptionLabel={(option) => option.name || ''}
          onChange={(e, v) => {
            formikProps.setFieldValue(fieldStr, v); // handles clearing and selection of an option
            setValue(v); // seems necessary for the option filtering to work
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              className={classes.inputField}
              name={fieldStr}
              onBlur={handleBlur}
              onChange={handleChange} // handles free input
              //fullWidth
              variant={variant as any}
              error={!!errors[field]}
              helperText={helperAndErrorText(helperTextStr, errors[field]?.toString())}
              {...otherTextFieldProps}
            >
              {children}
            </TextField>
          )}
        />
      </div>
      <div style={{paddingTop: '10pt'}}>
      <Tooltip title="Add facility">
        <IconButton
        aria-label="Add Facility"
        key={'add_facility'}
        onClick={handleOpen}
      >
        <AddIcon />
      </IconButton>
    </Tooltip>
    </div>
  </div>
  <AddFacilityDialog
    open={addFacilityDialog}
    onClose={() => {setAddFacilityDialog(false);}}
    handleSave={handleSaveFacility}
  />
  </>
  );
}
