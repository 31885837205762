import { Button, DialogActions, DialogContent, DialogContentText, Divider, StandardProps } from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import React from 'react';
import { ITrainingVideoViewDto, ITrainingViewUpdateDto } from '../../../../backend/src/training/interfaces';
import StyledDialogTitle from '../StyledDialogTitle';
import VideoPlayer from '../VideoPlayer';
import ResponsiveDialog from './ResponsiveDialog';

export interface IVideoViewInfo extends Pick<ITrainingVideoViewDto, 'description' | 'name'> {
  played: number;
  url: string;
}

export interface VideoDialogProps extends StandardProps<DialogProps, 'children'> {
  videoViewInfo: IVideoViewInfo;
  onClose: () => void;
  onError?: (err: any) => void;
  onUpdateVideo?: (updateInfo: ITrainingViewUpdateDto) => Promise<void>;
}

export default function VideoDialog({ open, onClose, onError, onUpdateVideo, videoViewInfo }: VideoDialogProps) {
  const { description, name, played, url } = videoViewInfo;

  return (
    <ResponsiveDialog
      disableBackdropClick
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
      onContextMenu={e => e.preventDefault()}
    >
      <StyledDialogTitle id="customized-dialog-title" onClose={onClose}>
        {name}
      </StyledDialogTitle>
      <DialogContent>
        {url &&
        <VideoPlayer
          played={played}
          url={url}
          onError={onError}
          onUpdateVideo={onUpdateVideo}
        />
        }
      </DialogContent>
      {description && <>
      <Divider variant="middle" />
      <DialogContent>
        <DialogContentText>
          {description}
        </DialogContentText>
      </DialogContent>
      </>}
      <DialogActions>
        <Button
          color="primary"
          onClick={onClose}
        >
          Close
        </Button>
      </DialogActions>
    </ResponsiveDialog>
  );
}
