import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { RouterLink } from '../components/RouterLink';
import {
  Breadcrumbs,
  Backdrop,
  Grid,
  Button,
  CircularProgress,
  Typography,
  withStyles,
} from '@material-ui/core';
import * as Sentry from '@sentry/browser';
import API from '../services/ApiService';
import Auth from '../services/AuthService';

import {
  showErrorResultBar,
  showInfoResultBar,
} from '../components/ResultSnackbar';

import MetricManagementTable from '../components/MetricManagementTable';
import { errorParser } from '../helpers';

const styles = (theme) => ({
  breadcrumb: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  backdrop: {
    // color: theme.palette.primary.main,
    // color: 'white',
    zIndex: theme.zIndex.drawer + 1,
  },
  circularProgress: {
    zIndex: theme.zIndex.drawer + 2,
    position: 'absolute',
    left: '50%',
    top: '50%',
  },
  fields: {
    paddingLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    '& > *': {
      margin: theme.spacing(1),
    },
  },
});

const getTableColumns = (tableData) => {
  const trackedColumns = tableData.filter((field) => field.tracked);
  let columns = [
    {
      name: 'value_start_datetime',
      label: 'Reporting Period Start',
      options: {
        customBodyRender: (v) => v && moment(v).format('YYYY-MM-DD'),
        display: 'true',
        filter: true,
      },
    },
    {
      name: 'value_end_datetime',
      label: 'Reporting Period End',
      options: {
        customBodyRender: (v) => v && moment(v).format('YYYY-MM-DD'),
        filter: true,
        display: true,
      },
    },
    {
      name: 'name',
      label: 'Facility Name',
      options: {
        filter: true,
        display: false,
      },
    },
  ];

  if (trackedColumns.length > 0) {
    trackedColumns.forEach((column) =>
      columns.push({
        name: column.name,
        label: column.name,
        options: {
          display: true,
          filter: true,
          searchable: true,
          sort: true,
        },
      })
    );
  }

  columns.push({
    // The underlying column value should be a string or a number; here we (arbitrarily) use the id.
    name: 'notes',
    label: 'Notes',
    options: {
      display: 'false',
      filter: false,
      searchable: false,
      sort: false,
      //setCellProps: () => ({ nowrap: 'true' }),
    },
  });

  columns.push({
    // The underlying column value should be a string or a number; here we (arbitrarily) use the id.
    name: 'documents',
    label: '# of Evidences',
    options: {
      display: 'false',
      filter: false,
      searchable: false,
      sort: false,
      customBodyRender: (v) => (v[0].document_id ? v.length : 0),
      //setCellProps: () => ({ nowrap: 'true' }),
    },
  });

  columns.push({
    name: 'actions',
    label: 'Actions',
    options: {
      filter: false,
      searchable: false,
      sort: false,
      setCellProps: () => ({ nowrap: 'true' }),
      // customBodyRenderLite: dataIndex => tableData[dataIndex]?.actions || '',
      display: true,
    },
  });

  return columns;
};

const TheMetricDetailPage = ({ match, classes, auth, history }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [fields, setFields] = useState([]);
  const [metric, setMetric] = useState([]);
  const [metricTableData, setMetricTableData] = useState([]);

  const handleClick = async (field, index) => {
    const updatedFields = [...fields];
    updatedFields[index]['tracked'] = !field.tracked;
    const trackedFields = updatedFields
      .filter((field) => field.tracked)
      .map((field) => {
        return {
          id: field.metric_field_id,
          name: field.name,
        };
      });
    try {
      const res = await API.patch(`org-metric/${metric.id}`, {
        fields: trackedFields,
      });
      setFields(updatedFields);
    } catch (err) {
      showErrorResultBar('Unexpected error adding the fields');
    }
  };

  useEffect(() => {
    async function fetchMetricFields() {
      const res = await API.get(`org-metric/${match.params.id}/metricFields`);
      try {
        setFields(res.data?.data ?? []);
      } catch (err) {
        showErrorResultBar('Unexpected error loading metric fields');
        Sentry.captureException(err);
      }
    }

    async function fetchMetricDetails() {
      const res = await API.get('org-metric/allMetrics');
      try {
        setMetric(
          res.data.data.find((metric) => metric.id === match.params.id)
        );
      } catch (err) {
        showErrorResultBar('Unexpected error loading metric');
        Sentry.captureException(err);
      }
    }

    async function fetchMetricTableData() {
      const res = await API.get(`org-metric-input/${match.params.id}`);
      try {
        setMetricTableData(res.data.data);
      } catch (err) {
        showErrorResultBar('Unexpected error loading metric');
        Sentry.captureException(err);
      }
    }

    setIsLoading(true);
    fetchMetricFields();
    fetchMetricDetails();
    fetchMetricTableData();
    setIsLoading(false);
  }, [match.params.id]);

  if (isLoading) {
    return (
      <>
        <Backdrop className={classes.backdrop} open={isLoading} timeout={500} />
        <CircularProgress
          className={classes.circularProgress}
          color="primary"
          size={60}
        />
      </>
    );
  }
  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ marginBottom: '1.5rem' }}
      >
        <Grid item>
          <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
            <RouterLink color="inherit" to="/metricTracking">
              Tracked Metrics
            </RouterLink>
            <Typography color="textPrimary">{metric.description}</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              history.push({
                pathname: `/metricTracking/metric/${match.params.id}/summary`,
              })
            }
            disabled={!auth.isGranted({ permission: 'esg_metrics:summary'})}
          >
            View Metric Summary
          </Button>
        </Grid>
      </Grid>
      {/* <Typography variant="h4" color="primary" gutterBottom>
          {metric.description}
        </Typography> */}
      {/* <Typography variant="body1">Available Fields for Tracking</Typography>
        <Box
          border={1}
          borderRadius="10px"
          py={2}
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          className={classes.fields}
        >
          {fields.map((field, index) => {
            return (
              <Chip
                key={index}
                variant={field.tracked ? 'default' : 'outlined'}
                size="small"
                color={field.tracked ? 'primary' : 'secondary'}
                label={field.name}
                // onClick={() => handleClick(field, index)}
                // onDelete={
                //   field.tracked ? () => handleClick(field, index) : null
                // }
                // deleteIcon
              />
            );
          })}
        </Box> */}
      <MetricManagementTable
        isLoading={isLoading}
        metricID={metric.id}
        metricTableData={metricTableData}
        columnData={fields}
        title={metric.description}
        tableColumns={getTableColumns(fields)}
        auth={auth}
      />
    </>
  );
};

TheMetricDetailPage.title = 'Metric Tracking';

export default withStyles(styles, { withTheme: true })(TheMetricDetailPage);
