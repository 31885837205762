import {
  Button
  } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import * as Sentry from '@sentry/browser';
import React, { useRef, useState } from 'react';
import API from '../services/ApiService';
import { ConfirmationDialog, DocumentAssignmentDialog, ArchiveDialog } from './dialogs';
import { showErrorResultBar, showSuccessResultBar } from './ResultSnackbar';
import { SpioDataTableSelectedRows } from './SpioDataTable';
import { IDocument, IDocumentAssigneeDto, IDocumentsResponse } from '../../../backend/src/document/interfaces';
import DocumentAttachDialog from './dialogs/DocumentAttachDialog';
import Auth from '../services/AuthService';

const useStyles = makeStyles({
  buttonsContainer: {
    lineHeight: '96px',
    paddingRight: '2rem',
  },
  button: {
      marginRight: '1rem',
    },
});

interface IIdxDoc {
  idx: number;
  document: IDocument;
}

interface IPartialUpdate {
  status: DocumentStatus;
}

export interface EvidencetTableSelectedRowsToolbarProps {
  assignees: IDocumentAssigneeDto[];
  onCancel: () => void;
  onUpdate: (idxs: number[], doc: IDocumentsResponse) => void;
  onArchive: (idxs: number[]) => void;
  selectedRows: SpioDataTableSelectedRows;
  documents: IDocument[];
  auth: Auth;
}

export enum DocumentStatus {
  Placeholder = 'placeholder',
  New = 'new',
  Active = 'active',
  InProgress = 'in_progress',
  Completed = 'completed',
  Review = 'review',
  Archived = 'archived',
}

export interface IDocumentsUpdateDto {
  ids: string[];
  status: DocumentStatus;
}

export function EvidenceTableSelectedRowsToolbar(props: EvidencetTableSelectedRowsToolbarProps) {
  const classes = useStyles();
  const { assignees, onCancel, onUpdate, selectedRows, documents, onArchive, auth} = props;
  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const [ toShowConfirmMarkStarted, setToShowConfirmMarkStarted ] = useState(false);
  const [ toShowAssignDialog, setToShowAssignDialog ] = useState(false);
  const [ toShowAttachDocDialog, setToShowAttachDocDialog ] = useState(false);
  const [ toShowArchiveConfirmationDialog, setToShowArchiveConfirmationDialog ] = useState(false);
  const selectedDataRef = useRef<IIdxDoc[]>([]);

  const handleUpdateStatus = async (updateInfo: IPartialUpdate) => {
    const selectedData = selectedDataRef.current;
    const docIds = selectedData.map(({ document }) => document.id);

    if (docIds.length === 0) {
      const msg = updateInfo.status ?
        `All of the selected documents were already ${updateInfo.status === 'active' ? 'started' : 'completed'}.` :
        'None of the selected documents needed to be updated.';
      showSuccessResultBar(msg);
      onCancel();

      return;
    }

    const updateDto: IDocumentsUpdateDto = {
      ids: docIds,
      status: updateInfo.status,
    };

    try {
      setIsSubmitting(true);
      const res: IDocument = (await API.patch('document', updateDto)).data.data;
      if (updateInfo.status === 'archived') {
        showSuccessResultBar('Documents archived.');
        onArchive(selectedData.map(({ idx }) => idx));
      } else {
        showSuccessResultBar('Documents updated.');
        onUpdate(selectedData.map(({ idx }) => idx), res);
      }
    } catch (err) {
      Sentry.captureException(err);
      showErrorResultBar('Error updating documents.');
      setIsSubmitting(false);
    } finally {
      selectedDataRef.current = [];
    }
  };

  const getAllSelectedData = () => {
    const allSelectedIdxs = selectedRows.data.map((row) => row.dataIndex);
    return allSelectedIdxs.map(idx => ({ idx, document: documents[idx] }));
  };

  const handleClickMarkStarted = async () => {
    // Don't update the Start dates for tasks that are already 'in_progress'.
    const mSelectedData = getAllSelectedData().filter(({ document }) => document.status !== 'in_progress');
    selectedDataRef.current = mSelectedData;

    // Prompt before re-opening 'Completed' tasks.
    if (mSelectedData.some(({ document }) => document.status === 'completed')) {
      setToShowConfirmMarkStarted(true);
    } else {
      return handleUpdateStatus({ status: DocumentStatus.InProgress });
    }
  };

  const handleClickMarkComplete = async () => {
    // Don't update the Completed dates for tasks that are already 'completed'.
    selectedDataRef.current = getAllSelectedData().filter(({ document }) => document.status !== 'completed');

    return handleUpdateStatus({ status: DocumentStatus.Completed });
  };

  const handleClickMarkReview = async () => {
      // Don't update the Completed dates for tasks that are already 'completed'.
      selectedDataRef.current = getAllSelectedData().filter(({ document }) => document.status !== 'review');

      return handleUpdateStatus({ status: DocumentStatus.Review });
    };

  const handleConfirmMarkStarted = async (isConfirmed: boolean) => {
    setToShowConfirmMarkStarted(false);

    if (isConfirmed) {
      handleUpdateStatus({ status: DocumentStatus.InProgress });
    }
  };

  const handleConfirmArchiveEvidence = async () => {
    setToShowArchiveConfirmationDialog(false);
    handleUpdateStatus({ status: DocumentStatus.Archived });
  };

  const handleClickAssign = () => {
    selectedDataRef.current = getAllSelectedData();
    setToShowAssignDialog(true);
  };

  const handleClickAttachDoc = () => {
    selectedDataRef.current = getAllSelectedData();
    setToShowAttachDocDialog(true);
  };

  const handleArchiveEvidence = () => {
    selectedDataRef.current = getAllSelectedData();
    setToShowArchiveConfirmationDialog(true);
  };

  const handleCloseArchiveDialog = () => setToShowArchiveConfirmationDialog(false);

  const handleUpdateEvidence = (updatedDocumentsInfo: IDocumentsResponse) => {
    onUpdate(selectedDataRef.current.map(({ idx }) => idx), updatedDocumentsInfo);
  };

  return (
    <>
      <div className={classes.buttonsContainer}>
        <Button className={classes.button}
          color="primary"
          variant="contained"
          onClick={handleClickMarkComplete}
          disabled={isSubmitting || (!auth.isGranted({ permission: 'evidence:edit' }) && !auth.isGranted({ permission: 'evidence:status' }))}
        >
          Mark Complete
        </Button>
        <Button className={classes.button}
          color="primary"
          variant="contained"
          onClick={handleArchiveEvidence}
          disabled={isSubmitting || !auth.isGranted({ permission: 'evidence:edit' })}
        >
          Archive Evidence
        </Button>
        <Button className={classes.button}
          color="primary"
          variant="contained"
          onClick={handleClickAssign}
          disabled={isSubmitting || (!auth.isGranted({ permission: 'evidence:edit' }) && !auth.isGranted({ permission: 'evidence:assign' }))}
        >
          Assign Evidence
        </Button>
        <Button className={classes.button}
          color="primary"
          variant="contained"
          onClick={handleClickAttachDoc}
          disabled={isSubmitting || !auth.isGranted({ permission: 'evidence:edit' })}
        >
          Attach Document
        </Button>
      </div>
      <ConfirmationDialog
        onResponse={handleConfirmMarkStarted}
        open={toShowConfirmMarkStarted}
        title="Reopen closed evidence?"
      >
        Some of the evidence you've selected is marked completed. Do you want to reopen them?
      </ConfirmationDialog>
      <ArchiveDialog
        open={toShowArchiveConfirmationDialog}
        onClose={handleCloseArchiveDialog}
        onArchive={handleConfirmArchiveEvidence}
        title={'Bulk archive evidence?'}
        text={'Are you sure you want to archive all selected evidence? '}
      />
      <DocumentAssignmentDialog
        assignees={assignees}
        onClose={() => setToShowAssignDialog(false)}
        onUpdate={handleUpdateEvidence}
        open={toShowAssignDialog}
        documents={selectedDataRef.current.map(({ document }) => document)}
      />
      <DocumentAttachDialog
        onClose={() => setToShowAttachDocDialog(false)}
        onUpdate={handleUpdateEvidence}
        open={toShowAttachDocDialog}
        evidenceData={selectedDataRef.current.map(({ document }) => document)}
      />
    </>
  );
}
