import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import DownloadIcon from '@material-ui/icons/GetApp';
import React from 'react';

function DownloadButton(props: IconButtonProps) {
  return (
    <IconButton
      aria-label="Download"
      {...props}
    >
      <DownloadIcon />
    </IconButton>
  );
}

export default DownloadButton;
