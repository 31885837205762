import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import Ballot from '@material-ui/icons/Ballot';
import BookIcon from '@material-ui/icons/Book';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import FolderIcon from '@material-ui/icons/Folder';
import GroupIcon from '@material-ui/icons/Group';
import HelpIcon from '@material-ui/icons/Help';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import EditCalendarIcon from '@material-ui/icons/CalendarViewDay';
import PermIdentity from '@material-ui/icons/PermIdentity';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import {
  Divider,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  withStyles,
} from '@material-ui/core';
import logo from '../images/rho-gemini-logo.svg';
import Badge from '@material-ui/core/Badge';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import { geminiPurple } from '../theme';
import {
  TheDashboardPage,
  TheDocDiscoveryPage,
  TheDocumentsPage,
  TheESGReportsPage,
  TheEvidencePage,
  TheInboundQuestionnairePage,
  TheMaterialityAssessmentPage,
  TheMaterialityRegisterPage,
  TheMetricDashboardPage,
  TheMetricTrackingPage,
  TheOutboundQuestionnairePage,
  ThePermissionsPage,
  ThePolicyPage,
  TheResourcePage,
  TheStakeholderPage,
  TheSupportPage,
  TheTasksPage,
  TheTasksReportPage,
  TheTrainingPage,
} from '../pages';

const styles = (theme) => ({
  upgrade: {
    color: theme.palette.error.main,
  },

  listItems: {
    color: '#f2f1ed',
  },

  categoryHeader: {
    color: '#8D8DFF',
    textTransform: 'uppercase',
  },

  navLink: {
    textDecoration: 'none',
    '&[disabled]': {
      cursor: 'default',
      pointerEvents: 'none',
    },
  },

  betaMarker: {
    color: theme.palette.error.main,
    fontSize: 'smaller',
    textTransform: 'lowercase',
    fontWeight: 'bold',
    paddingLeft: '1em',
    cursor: 'pointer',
    pointerEvents: 'all',
    position: 'relative',
    top: '-0.5rem',
    left: '-0.5rem',
  },

  toolbarIcon: {
    ...theme.mixins.toolbar,
    textAlign: 'center',
  },

  logo: {
    height: '55px',
    textAlign: 'center',
    align: 'center',
    marginTop: '7px',
  },
  selectedListItems: {
    color: 'white',
  },
  subList: {
    '&:last-child': { marginBottom: theme.spacing(3) },
  },
  nested: {
    paddingLeft: theme.spacing(10),
  },
  nestedBackgroundCol: {
    paddingLeft: theme.spacing(10),
    backgroundColor: geminiPurple[900],
  },
  backgroundCol: {
    backgroundColor: geminiPurple[900],
  },
});

const LINKS = {
  dashboard: {
    path: '/dashboard',
    text: 'Dashboard',
    icon: <DashboardIcon />,
    requiredAuthZ: TheDashboardPage.requiredAuthZ,
  },
  disclosures: {
    path: '/disclosures',
    text: 'Disclosures',
    icon: <LibraryBooksIcon />,
    requiredAuthZ: { tier: 3, permission: 'disclosures' },
  },
  docDiscovery: {
    path: '/document-discovery',
    text: 'Doc Discovery',
    icon: <FindInPageIcon />,
    requiredAuthZ: TheDocDiscoveryPage.requiredAuthZ,
  },
  documents: {
    path: '/deliverables',
    text: 'Deliverables',
    icon: <FolderIcon />,
    requiredAuthZ: TheDocumentsPage.requiredAuthZ,
  },
  evidence: {
    path: '/evidence',
    text: 'Evidence',
    icon: <InsertDriveFileIcon />,
    requiredAuthZ: TheEvidencePage.requiredAuthZ,
  },
    // impactMaps: {
  //   path: '/impact-mapping',
  //   text: 'Impact Mapping',
  //   icon: <AccountTreeIcon />,
  //   requiredAuthZ: TheImpactMappingPage.requiredAuthZ,
  // },
  inboundQuestionnaires: {
    icon: TheInboundQuestionnairePage.icon,
    path: TheInboundQuestionnairePage.routePath,
    requiredAuthZ: TheInboundQuestionnairePage.requiredAuthZ,
    text: TheInboundQuestionnairePage.title,
  },
  materialityAssessment: {
    path: '/materiality-assessment',
    text: 'Materiality Assessment',
    icon: <Ballot />,
    requiredAuthZ: TheMaterialityAssessmentPage.requiredAuthZ,
  },
  materialityRegister: {
    path: '/materiality-register',
    text: 'Materiality Register',
    icon: <AssignmentLateIcon />,
    requiredAuthZ: TheMaterialityRegisterPage.requiredAuthZ,
  },
  metricsDashboard: {
    path: TheMetricDashboardPage.routePath,
    text: 'Metrics Dashboard',
    icon: <ViewModuleIcon />,
    requiredAuthZ: TheMetricDashboardPage.requiredAuthZ,
  },
  outboundQuestionnaires: {
    icon: TheOutboundQuestionnairePage.icon,
    path: '/outbound-questionnaire',
    requiredAuthZ: TheOutboundQuestionnairePage.requiredAuthZ,
    text: TheOutboundQuestionnairePage.title,
  },
  permissions: {
    path: '/permissions',
    text: 'Permissions',
    icon: <PermIdentity />,
    requiredAuthZ: ThePermissionsPage.requiredAuthZ,
  },
  policies: {
    path: '/policies',
    text: 'Policies',
    icon: <BookIcon />,
    requiredAuthZ: ThePolicyPage.requiredAuthZ,
  },
  questionnaires: {
    text: 'Questionnaires',
    icon: TheOutboundQuestionnairePage.icon,
    items: [
      {
        path: TheInboundQuestionnairePage.routePath,
        requiredAuthZ: TheInboundQuestionnairePage.requiredAuthZ,
        text: TheInboundQuestionnairePage.title,
      },
      {
        path: '/outbound-questionnaire',
        requiredAuthZ: TheOutboundQuestionnairePage.requiredAuthZ,
        text: TheOutboundQuestionnairePage.title,
      }
    ],
  },
  reporting: {
    path: '/reporting',
    text: 'Reporting',
    icon: <MenuBookIcon />,
    requiredAuthZ: TheESGReportsPage.requiredAuthZ,
  },
  resources: {
    path: '/resources',
    text: 'Resources',
    icon: <LibraryBooksIcon />,
    requiredAuthZ: TheResourcePage.requiredAuthZ,
  },
  stakeholderEngagement: {
    path: '/stakeholder-engagement',
    text: 'Stakeholder Engagement',
    icon: <GroupIcon />,
    requiredAuthZ: TheStakeholderPage.requiredAuthZ,
  },
  support: {
    path: '/support',
    text: 'Support',
    icon: <HelpIcon />,
    requiredAuthZ: TheSupportPage.requiredAuthZ,
  },
  tasks: {
    path: '/tasks',
    text: 'Tasks',
    icon: <FormatListBulletedIcon />,
    requiredAuthZ: TheTasksPage.requiredAuthZ,
  },
  gantt: {
    path: '/tasks/report',
    text: 'Task Calendar',
    icon: <EditCalendarIcon />,
    requiredAuthZ: TheTasksReportPage.requiredAuthZ,
  },
  trackedMetrics: {
    path: '/metricTracking',
    text: 'Tracked Metrics',
    icon: <AssessmentIcon />,
    requiredAuthZ: TheMetricTrackingPage.requiredAuthZ,
  },
  training: {
    path: '/training',
    text: 'Training',
    icon: <LocalLibraryIcon />,
    requiredAuthZ: TheTrainingPage.requiredAuthZ,
  },
  updates: {
    path: '/updates',
    text: 'Updates',
    icon: <DynamicFeedIcon />,
    requiredAuthZ: { tier: 3, permission: 'updates' },
  },
  vendorTracking: {
    path: '/vendor-tracking',
    text: 'Vendor Tracking',
    icon: <EmojiTransportationIcon />,
    requiredAuthZ: { tier: 3, permission: 'not_yet_available' },
    // requiredAuthZ: TheVendorTrackingPage.requiredAuthZ,
  },
};

const menuTier0User = [
  {
    header: 'Program',
    isBeta: false,
    links: [
      LINKS.dashboard,
      LINKS.tasks,
      LINKS.gantt,
      LINKS.policies,
      LINKS.training,
      LINKS.resources,
    ],
  },
  {
    header: 'Tools',
    isBeta: false,
    hideUnAuthLinks: false,
    links: [
      LINKS.stakeholderEngagement,
      LINKS.materialityAssessment,
      LINKS.materialityRegister,
      LINKS.questionnaires,
      // LINKS.inboundQuestionnaires,
      // LINKS.outboundQuestionnaires,
      LINKS.documents,
      LINKS.evidence,
      LINKS.trackedMetrics,
      // LINKS.vendorTracking,
    ],
  },
  {
    header: 'Services',
    isBeta: false,
    links: [
      LINKS.docDiscovery,
      //LINKS.impactMaps,
      LINKS.reporting,
      // LINKS.disclosures,
      // LINKS.updates,
    ],
  },
  {
    header: 'Support',
    isBeta: false,
    links: [
      LINKS.support,
      LINKS.permissions
    ],
  }
];

// This is for development purposes only to hide specific tools in the navigation from users
const menuDev = [
  {
    header: 'Program',
    isBeta: false,
    links: [
      LINKS.dashboard,
      LINKS.tasks,
      LINKS.gantt,
      LINKS.policies,
      LINKS.training,
      LINKS.resources,
    ],
  },
  {
    header: 'Tools',
    isBeta: false,
    hideUnAuthLinks: false,
    links: [
      LINKS.stakeholderEngagement,
      LINKS.materialityAssessment,
      LINKS.materialityRegister,
      LINKS.questionnaires,
      // LINKS.inboundQuestionnaires,
      // LINKS.outboundQuestionnaires,
      LINKS.documents,
      LINKS.evidence,
      LINKS.trackedMetrics,
      LINKS.metricsDashboard,
      // LINKS.vendorTracking,
    ],
  },
];

// const menuTier1Trainee = [
//   {
//     header: '',
//     links: [
//       LINKS.training,
//       LINKS.policies,
//       LINKS.support,
//     ],
//   },
// ];

const menuTier1Trainee = menuTier0User;
const menuTier1Manager = menuTier0User;

function TheNavLinkList({ auth, badges, classes, onClick, theme }) {
  const [activeLink, setActiveLink] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClick = (e) => {
    e.stopPropagation();
    setOpen((prev) => !prev);
  };

  let navMenuToShow;

  const checkActive = (match, location) => {
    setActiveLink(location.pathname);
  };

  if (auth.isGranted({ permission: 'esg_metrics' })) {
    navMenuToShow = menuDev;
  } else if (auth.isGranted({ tier: 1, permission: 'org:edit' })) {
    navMenuToShow = menuTier1Manager;
  } else if (auth.isGranted({ tier: 1, permission: 'training:learn' })) {
    navMenuToShow = menuTier1Trainee;
  } else {
    navMenuToShow = menuTier0User;
  }

  return (
    <div onClick={onClick}>
      <div className={classes.toolbarIcon}>
        <NavLink to="/welcome">
          <img
            src={auth.getResellerLogo() || logo}
            alt="Gemini"
            className={classes.logo}
          />
        </NavLink>
      </div>

      <Divider />

      <List>
        {navMenuToShow
          .filter(
            (subMenu) =>
              subMenu.requiredAuthZ === undefined ||
              auth.hasMatchingPermissions(subMenu.requiredAuthZ)
          )
          .map((subMenu) => (
            <div key={subMenu.header}>
              <ListItem className={classes.listItems}>
                <ListItemText>
                  <Typography
                    component="span"
                    className={classes.categoryHeader}
                  >
                    {subMenu.header}
                    {subMenu.isBeta && (
                      <NavLink
                        isActive={checkActive}
                        to={`/beta/${subMenu.header}`}
                        className={classes.navLink}
                      >
                        <span className={classes.betaMarker}>beta</span>
                      </NavLink>
                    )}
                  </Typography>
                </ListItemText>
              </ListItem>
              <List component="div" disablePadding>
                {subMenu.links.map((link) => {
                  const isDisabled = !auth.isGranted(link.requiredAuthZ);
                  if (!subMenu.hideUnAuthLinks || !isDisabled) {
                    if (link.items) {
                      return (
                        <>
                          <ListItem button onClick={(e) => handleClick(e)}>
                            <ListItemIcon className={classes.listItems}>
                              {link.icon}
                            </ListItemIcon>
                            <ListItemText
                              classes={
                                link.path === activeLink
                                  ? {
                                    primary: classes.selectedListItems,
                                  }
                                  : { primary: classes.listItems }
                              }
                            >
                              {link.text}{' '}
                              {link.isBeta && (
                                <span className={classes.betaMarker}>beta</span>
                              )}
                            </ListItemText>
                            {open ? (
                              <ExpandLessIcon className={classes.listItems} />
                            ) : (
                              <ExpandMoreIcon className={classes.listItems} />
                            )}
                          </ListItem>
                          <Collapse in={open} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding className={classes.subList}>
                              {link.items.map((child, key) => (
                                <NavLink
                                  isActive={checkActive}
                                  to={child.path}
                                  key={child.text}
                                  className={classes.navLink}
                                  disabled={!child.isBeta && !auth.isGranted(child.requiredAuthZ)}
                                >
                                  <ListItem
                                    button
                                    disabled={!auth.isGranted(child.requiredAuthZ)}
                                    className={
                                      child.path === activeLink
                                        ? classes.nestedBackgroundCol
                                        : classes.nested
                                    }
                                  >
                                    <ListItemText
                                      classes={{ primary: classes.listItems }}
                                    >
                                      {child.text}{' '}
                                    </ListItemText>
                                  </ListItem>
                                </NavLink>
                              ))}
                            </List>
                          </Collapse>
                        </>
                      );
                    }

                    return (
                      <NavLink
                        isActive={checkActive}
                        to={
                          link.isBeta && isDisabled
                            ? `/beta/${link.text}`
                            : link.path
                        }
                        key={link.text}
                        className={classes.navLink}
                        disabled={!link.isBeta && isDisabled}
                      >
                        <ListItem
                          button
                          disabled={!auth.isGranted(link.requiredAuthZ)}
                          className={
                            link.path === activeLink
                              ? classes.backgroundCol
                              : null
                          }
                        >
                          <ListItemIcon className={classes.listItems}>
                            <Badge
                              badgeContent={(badges && badges[link.path]) || 0}
                              color="secondary"
                            >
                              {link.icon}
                            </Badge>
                          </ListItemIcon>
                          <ListItemText
                            classes={
                              link.path === activeLink
                                ? { primary: classes.selectedListItems }
                                : { primary: classes.listItems }
                            }
                          >
                            {link.text}{' '}
                            {link.isBeta && (
                              <span className={classes.betaMarker}>beta</span>
                            )}
                          </ListItemText>
                        </ListItem>
                      </NavLink>
                    );
                  } else {
                    return null;
                  }
                })}
              </List>
            </div>
          ))}
      </List>
    </div>
  );
}

export default withStyles(styles, { name: 'TheNavLinkList', withTheme: true })(
  TheNavLinkList
);
