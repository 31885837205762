import React, { useState } from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { SaveButton } from '../buttons';
import StyledDialogTitle from '../StyledDialogTitle';
import ResponsiveDialog from './ResponsiveDialog';
import { makeStyles } from '@material-ui/styles';
import FormikSelectPermissions from '../forms/FormikSelectPermissions';
import { RouterLink } from '../RouterLink';

const useStyles = makeStyles({
  selectedRoleContainer: {
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
  },
  roleSelectionContainer: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
    borderRadius: '4px',
  },
  gridContainer: {
    paddingLeft: '2rem',
    paddingRight: '2rem',
    paddingTop: '1rem',
  },
  roleSelectionName: {
    fontWeight: 500,
  },
  roleSelectionDescription: {
    paddingLeft: '0.5rem',
    fontWeight: 'normal',
  },
  error: {
    paddingTop: '0.5rem',
    color: 'red',
    fontSize: '12px'
  },
});

const RequestSchema =
  Yup.object({
    checked: Yup.array().required()
  })
  .test({
    checked: 'atLeastOneRequired',
    test: function(values) {
      const isValid = values.checked.includes(true);

      if(isValid) return true;
      return this.createError({
        path: 'checked',
        message: 'At least one permission must be checked',
      });
    }
  });

export function RoleSelectionLabel({roleSelection }) {
  const classes = useStyles();

  return (
    <>
      <Typography
        className={classes.roleSelectionName}
        variant="subtitle1"
      >
        {roleSelection.name}
        {/* {isSelected && ' (current role)'} */}
      </Typography>
      <Typography
        className={classes.roleSelectionDescription}
        variant="subtitle2"
      >
        {roleSelection.description.replace("the organization's", 'your').replace('user', 'organization')}
      </Typography>
    </>
  );
}

export default function OrgShareApproveAccessDialog({ onClose, open, allRoles, orgData, handleSave }) {
  const classes = useStyles();

  const handleReset = () => {
    onClose();
  };

  return (
    <ResponsiveDialog
      disableBackdropClick
      fullWidth
      maxWidth="md"
      open={open}
      onClose={() => {handleReset();}}
    >
      <StyledDialogTitle onClose={() => {handleReset();}}>
        Approve Access
      </StyledDialogTitle>
      <Formik
        initialValues={{
          checked: allRoles.map((r) => orgData.roles.map((role) => role.id).includes(r.id) ? true : false),
        }}
        onSubmit={async (values, { setSubmitting }) => {
            // get roles that organization can access
            const orgRoles = [];
            allRoles.forEach((r, idx) => values.checked[idx] ? orgRoles.push(r.id) : null);
            await handleSave(orgData.id, orgRoles);
            setSubmitting(false);
            onClose();
        }}
        onReset={(_) => {
          handleReset();
          }}
        validateOnBlur={false}
        validateOnChange={true}
        validationSchema={RequestSchema}
      >
        {formikProps => (
        <>
          <DialogContent>
            <Grid container>
              <Grid container className={classes.gridContainer}>
                <Typography variant="h6">
                  {`Are you sure you want to grant ${orgData.accessType} access to ${orgData.toOrg.name}?`}
                </Typography>
                <Typography variant="body1">
                  {orgData.toOrg.name} will be able to manage your account, including viewing all information and entering new information (up to the selected permissions). Select the specific permissions that {orgData.toOrg.name} will have access to below.
                  Visit the <RouterLink to="/permissions">Permissions page</RouterLink> for more information on permissions assigned to each role.
                </Typography>
                <FormikSelectPermissions
                  allRoles={allRoles}
                  formikProps={formikProps}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <SaveButton
                disabled={formikProps.isSubmitting || Object.values(formikProps.errors).filter(v => !!v).length > 0}
                onClick={formikProps.handleSubmit}
                text={'Approve Access'}
            />
            <Button
                disabled={formikProps.isSubmitting}
                onClick={formikProps.handleReset}
                color="primary"
            >
                Cancel
            </Button>
          </DialogActions>
        </>
        )}
      </Formik>
    </ResponsiveDialog>
  );
}
