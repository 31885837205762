import React, { useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Box,
  Paper,
  Radio,
  RadioGroup,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  withStyles,
  Tooltip,
  Snackbar,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import HelpIcon from '@material-ui/icons/HelpOutline';
import Rating from '@material-ui/lab/Rating';
import { Formik } from 'formik';
import * as PropTypes from 'prop-types';
import Brightness1Outlined from '@material-ui/icons/Brightness1Outlined';
import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked';
import API from '../services/ApiService';
import { showInfoResultBar } from '../components/ResultSnackbar';

const styles = theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      width: '60%',
    },
    textAlign: 'left',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  questionTextContainer: {
    marginTop: theme.spacing(1),
  },
  ratingTextContainer: {
    marginTop: 0,
    marginBottom: 0,
    fontSize: 16,
  },
  tooltipText: {
    fontSize: 12,
  },
  inputContainer: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  actionsContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  helpIcon: {
    'fontSize': '18px',
    'marginBottom': '-1px',
    'marginLeft': theme.spacing(1),
    '&:hover': {
        cursor: 'pointer',
    },
},
});

const defaultRatingLabels = {
  1: 'Not Important',
  2: 'Somewhat Important',
  3: 'Important',
  4: 'Very Important',
  5: 'Extremely Important',
};

const RadioOptions = ({ classes, formikProps, question }) => {
  const { values, handleChange } = formikProps;

  const optionList = [];

  for (let i = 0; i < question.options.length; i++) {
    var option = question.options[i];
    optionList.push(<FormControlLabel value={option} control={<Radio />} label={option} key={i}/>);
  };

  return (
    <>
      <Typography className={classes.questionTextContainer}>
        {question.text}
      </Typography>
      <div className={classes.inputContainer}>
        <FormControl
          component="fieldset"
          margin="dense"
        >
          <RadioGroup
            id={question.id}
            name={question.id}
            value={values[question.id]}
            onChange={handleChange}
          >
            {optionList}
          </RadioGroup>
        </FormControl>
      </div>
    </>
  );
};

const MultiSelect = ({ classes, formikProps, question }) => {
  const { values, handleChange } = formikProps;

  const optionList = [];

  for (let i = 0; i < question.options.length; i++) {
    var option = question.options[i];
    let checkFlag = values[question.id].includes(option);
    optionList.push(<FormControlLabel value={option} checked={checkFlag} control={<Checkbox name={question.id} key={i}/>} label={option} />);
  };

  return (
    <>
      <Typography className={classes.questionTextContainer}>
        {question.text}
      </Typography>
      <div className={classes.inputContainer}>
        <FormControl
          component="fieldset"
          margin="dense"
        >
          <FormGroup
            id={question.id}
            name={question.id}
            value={values[question.id]}
            onChange={handleChange}
          >
            {optionList}
          </FormGroup>
        </FormControl>
      </div>
    </>
  );
};

const RadioYesNo = ({ classes, formikProps, question }) => {
  const { values, handleChange } = formikProps;

  return (
    <>
      <Typography className={classes.questionTextContainer}>
        {question.text}
      </Typography>
      <div className={classes.inputContainer}>
        <FormControl
          component="fieldset"
          margin="dense"
        >
          <RadioGroup
            id={question.id}
            name={question.id}
            value={values[question.id]}
            onChange={handleChange}
          >
            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="No" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
      </div>
    </>
  );
};

function TextInputQuestion(classes, formikProps, question) {
  const { values, handleBlur, handleChange } = formikProps;

  return (
    <>
      {!!question.text &&
        <Typography className={classes.questionTextContainer}>
          {question.text}
        </Typography>
      }
      <div className={classes.inputContainer}>
        <TextField
          id={question.id}
          key={question.id}
          name={question.id}
          value={values[question.id]}
          label={question.label || ''}
          placeholder={question.placeholder || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          fullWidth={true}
          margin="dense"
          InputLabelProps={{
            shrink: (!!question.placeholder) || (!!values[question.id]),
          }}
        />
      </div>
    </>
  );
};

const StyledRating = withStyles({
  iconFilled: {
    color: 'black'
  },
  iconHover: {
    color: 'grey'
  }
})(Rating);

const RatingQuestion = ({ classes, formikProps, question, ratingLabels }) => {
  const { values, handleChange } = formikProps;
  const [hover, setHover] = React.useState(-1);
  return (
    <>
      <div className={classes.inputContainer}>
      <FormControl
        component="fieldset"
        margin="dense"
      >
        <Box className={classes.ratingTextContainer}>{question.text}
        {question.tip && (
          <Tooltip title={<><Typography variant="caption">{question.tip}</Typography> </>}>
            <HelpIcon color="action" className={classes.helpIcon} />
          </Tooltip>
        )}
        </Box>
        <Box
          sx={{
            width: 400,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <StyledRating
            id={question.id}
            name={question.id}
            icon={<RadioButtonChecked fontSize="inherit" />}
            emptyIcon={<Brightness1Outlined fontSize="inherit"/>}
            onChange={handleChange}
            value={values[question.id] || ''}
            onChangeActive={(event, newHover) => {
              setHover(newHover);
            }}
          />
          {values[question.id] !== null && (
            <Box sx={{ ml: 1 }}>{ratingLabels[hover !== -1 ? hover : values[question.id]]}</Box>
          )}
        </Box>
      </FormControl>
      </div>
    </>
  );
};

const getStepBlocks = (questionnaireTemplate, orgName) => {
  let blockList = [];

  for (let b = 0; b < questionnaireTemplate.blocks.length; b++) {
    let block = questionnaireTemplate.blocks[b];

    blockList.push(
      {
        label: block.label,
        questions: block.questions,
        validation: block.questions.map(question => Object({type: question.type, required: question.required ? true : false, id: question.type === 'rating' ? question.issues.map(issue => issue.id) : [question.id]})),
        component: function Component(classes, index, formikProps, questions) {

          const questionList = [];

          for (let q = 0; q < questions.length; q++) {
            if ('text' in questions[q]) {
              questions[q].text = questions[q].text.replace('<COMPANY>', orgName);
            };
            if (questions[q].type === 'rating') {
              questionList.push(
                <Typography className={classes.questionTextContainer}>
                  {questions[q].text}
                </Typography>
              );
              let ratingLabels = null;
              if (questions[q].ratingLabels != null && typeof(questions[q].ratingLabels) !== 'undefined') {
                ratingLabels = questions[q].ratingLabels;
              } else {
                ratingLabels = defaultRatingLabels;
              };
              for (let i = 0; i < questions[q].issues.length; i++) {
                questionList.push(
                  <RatingQuestion
                    classes={classes}
                    formikProps={formikProps}
                    question={questions[q].issues[i]}
                    ratingLabels={ratingLabels}
              />);
                }
            } else if (questions[q].type === 'text') {
              questionList.push(
                TextInputQuestion(classes, formikProps, questions[q])
              );
            } else if (questions[q].type === 'radioOptions'){
              questionList.push(
                <RadioOptions
                  classes={classes}
                  formikProps={formikProps}
                  question={questions[q]}
                />
              );
            } else if (questions[q].type === 'radioYesNo'){
              questionList.push(
                <RadioYesNo
                  classes={classes}
                  formikProps={formikProps}
                  question={questions[q]}
                />
              );
            } else if (questions[q].type === 'multiSelect'){
              questionList.push(
                <MultiSelect
                  classes={classes}
                  formikProps={formikProps}
                  question={questions[q]}
                />
              );
            };
          }
          return (
            <>
              {questionList}
            </>
          );
        }
      }
    );
  };

  return blockList;
};



const StepActions = withStyles(styles, { name: 'StepActions', withTheme: true })(props => {
  const { classes, activeStep, nbSteps, handleBack, handleNext, valid } = props;
  return (
    <div className={classes.actionsContainer}>
      <div>
        <Button
          size="small"
          disabled={activeStep === 0}
          onClick={handleBack}
          className={classes.button}
        >
          Back
        </Button>
        {(activeStep === nbSteps - 1) ?
        null:
          <>
          {valid ?
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleNext}
                  className={classes.button}
                  disabled={activeStep === nbSteps - 1 || !valid}
                >
                  {activeStep === nbSteps - 1 ? 'Finished!' : 'Next'}
                </Button>
          :
            <Tooltip title="Provide responses to all required questions to move onto next section.">
              <span>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleNext}
                  className={classes.button}
                  disabled={activeStep === nbSteps - 1 || !valid}
                >
                  {activeStep === nbSteps - 1 ? 'Finished!' : 'Next'}
                </Button>
              </span>
            </Tooltip>
          }
          </>
        }
      </div>
    </div>
  );
});

const QuestionnaireStepper = withStyles(styles, { name: 'QuestionnaireStepper', withTheme: true })(props => {
  const { classes, formikProps, questionnaireTemplate, orgName, saveResponses } = props;
  const [activeStep, setActiveStep] = useState(0);
  const [isComplete, setIsComplete] = useState(false);
  const { values } = formikProps;
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);

  const steps = getStepBlocks(questionnaireTemplate, orgName);

  function handleValidation(values, validationList) {
    let valid = true;
    for (const validate of validationList) {
      if (validate.required === true) {
        for (const id of validate.id) {
          if (values[id] === '' || values[id] === undefined || values[id] === null)
          {
            valid = false;
          }
        }
      }
    }
    return valid;
  }

  function handleNext() {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  }

  function handleBack() {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  useEffect(() => {
    setIsComplete(prevIsComplete => prevIsComplete || activeStep === steps.length - 1);
  }, [activeStep, steps]);

  return (
    <>
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
      >
        {steps.map((step, index) => {
          const Component = step.component;
          const questions = step.questions;
          const validation = handleValidation(values, step.validation);
          return (
            <Step key={step.label}>
              <StepLabel>{step.label}</StepLabel>
              <StepContent>
                {Component(classes, index, formikProps, questions)}
                <StepActions
                  activeStep={activeStep}
                  handleBack={handleBack}
                  handleNext={handleNext}
                  nbSteps={steps.length}
                  valid={validation}
                />
              </StepContent>
            </Step>
          );
        }
        )}
      </Stepper>
      <Paper square elevation={0} className={classes.resetContainer}>
      { saveResponses ? (
        <Typography>
          Please note that once you submit, you will not be able to go back
          and change your responses. If you would like to modify your responses later,
          click save.
        </Typography>
      ):(
          <Typography>
          Please note that once you submit, you will not be able to go back
          and change your responses.
        </Typography>
      )}
        <Button
          variant="contained"
          color="primary"
          disabled={!isComplete}
          onClick={formikProps.handleSubmit}
          className={classes.button}
        >
          Submit
        </Button>
        { saveResponses ? (
                <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={async () => {
                  await saveResponses(values);
                  setSnackbarOpen(true);
                }}
              >
                Save Progress
              </Button>
        ) : null
        }

      </Paper>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        message="Responses saved."
        action={action}
      />
    </>
  );
});

function StakeholderQuestionnaireDetails({ classes, submitResponses, saveResponses, savedValues, questionnaireTemplate, orgName, tokenId, anonymousResponse = false }) {

  useEffect(() => {
    API.get(`stakeholder-questionnaire/${tokenId}/previous-response`)
      .then(res => {
        if (res.data.data) {
          showInfoResultBar('You have previously submitted a response to this questionnaire. If you submit your responses at the end of this questionnaire, your previous response will be overwritten.');
        }
      }
      );
  }, []);

  const STAKEHOLDER_QUESTIONS = getStepBlocks(questionnaireTemplate, orgName).reduce((acc, curr) => acc.concat(curr.questions), []);

  let initValues = STAKEHOLDER_QUESTIONS.reduce((acc, curr) => {
    acc[curr.id] = '';
    return acc;
  }, {});

  if (savedValues) {
    initValues = savedValues;
  }

  return (
    <Paper
      className={classes.root}
      elevation={2}
    >
      <Formik
        initialValues={initValues}
        onSubmit={async (values, { setSubmitting }) => {
          await submitResponses(values, anonymousResponse);
          setSubmitting(false);
        }}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {formikProps => (
          <QuestionnaireStepper
            formikProps={formikProps}
            questionnaireTemplate={questionnaireTemplate}
            orgName={orgName}
            saveResponses={saveResponses}
          />
        )}
      </Formik>
    </Paper>
  );
}

StakeholderQuestionnaireDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  isValidToken: PropTypes.bool,
  submitResponses: PropTypes.func,
  saveResponses: PropTypes.func,
  questionnaireTemplate: PropTypes.object,
  orgName: PropTypes.string,
  anonymousResponse: PropTypes.bool,
  tokenId: PropTypes.string,
};

StakeholderQuestionnaireDetails.defaultProps = {
  submitResponses: () => { },
  saveResponses: () => { },
  // onClickSave: () => {},
};

export default withStyles(styles, { withTheme: true })(StakeholderQuestionnaireDetails);
