import { TableCell, TableRow, withStyles, WithStyles, createStyles, Theme, Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { IInboundQuestionnaireDto } from '../../../backend/src/inbound-questionnaire/interfaces';
import Auth from '../services/AuthService';
import OutboundQuestionnaireDetails from '../components/OutboundQuestionnaireDetails';
import SpioDataTable, {
  SpioDataTableColumn,
} from '../components/SpioDataTable';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { OutboundQuestionnaireStatus } from '../../../backend/src/outbound-questionnaire/enums/outbound-questionnaire-status.enum';
import { IOutboundQuestionnaireDetailsDto } from '../../../backend/src/outbound-questionnaire/interfaces/outbound-questionnaire-details-dto.interface';
import { History } from 'history';
import API from '../services/ApiService';
import { Moment } from 'moment';
import { showErrorResultBar, showSuccessResultBar } from '../components/ResultSnackbar';
import * as Sentry from '@sentry/browser';

const styles = (theme: Theme) =>
  createStyles({
    [theme.breakpoints.up('md')]: {
      commentCell: {
        textAlign: 'center',
      },
      commentCellText: {
        paddingLeft: '2px',
      },
    },
});

export const UNASSIGNED_DATA_STR = '(blank)';

export const QUESTIONNAIRE_RESPONDENT_MAP = {
    other: 'Other',
    completed: 'Completed',
    review: 'Needs Review',
    in_progress: 'In Progress',
  };

const getTableColumns = (tableData: ITableDatum[]): SpioDataTableColumn[] => [
  {
    name: 'id',
    label: 'ID',
    options: {
      display: 'false',
      filter: false,
    },
  },
  {
    name: 'name',
    label: 'Questionnaire Name',
    options: {
      //customFilterListOptions: { render: (v) => `Name: ${v}` },
    },
  },
  {
    name: 'version',
    label: 'Questionnaire Version',
  },
  {
    name: 'questionnaireStatus',
    label: 'Status of Questionnaire',
    options: {
      // customFilterListOptions: { render: (v) => `Status: ${v}` },
    },
  },
  {
    name: 'responseCount',
    label: 'Number of Responses',
    options: {
      // customFilterListOptions: { render: (v) => `Status: ${v}` },
    },
  },
];

export const questionnaireStatusMap: { [key in OutboundQuestionnaireStatus]: string } = {
  open: 'Open',
  closed: 'Closed',
  archived: 'Archived'
};

interface OutboundQuestionnaireTableProps extends WithStyles<typeof styles> {
    questionnaires: IOutboundQuestionnaireDetailsDto[];
    auth: Auth;
    history: History;
    onUpdateQuestionnaires: (newQuestionnaires: IOutboundQuestionnaireDetailsDto[]) => void;
  }

interface ITableDatum extends IInboundQuestionnaireDto {
    responseCount: number;
    actions: string | React.ReactElement<any>;
}

function OutboundQuestionnaireTable(props: OutboundQuestionnaireTableProps) {
  const {
    questionnaires,
    auth,
    history,
    onUpdateQuestionnaires
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState<ITableDatum[]>([]);

  useEffect(() => {
    setTableData(
      //  TODO: move this to function
        questionnaires.map((d, idx) =>
        Object({
          ...d,
          questionnaireStatus: d.outboundQuestionnaire[0]?.status ? d.outboundQuestionnaire[0].status : 'closed',
          responseCount: d.outboundQuestionnaire[0] ? d.outboundQuestionnaire[0]?.allowMultipleResponses ? d.multipleResponseCount : d.singleResponseCount : '0'
        })
      )
    );
  }, [questionnaires]);

  const markOpen = (dataIdx: number) => async () => {
    const res = await API.patch(`outbound-questionnaire/${questionnaires[dataIdx].id}/open`);

    if (res.status === 200) {
      const dataCopy = questionnaires.slice();
      dataCopy[dataIdx].outboundQuestionnaire = [res.data.data];
      onUpdateQuestionnaires(dataCopy);
    }
  };

  const markClose = (dataIdx: number) => async () => {
    const res = await API.patch(`outbound-questionnaire/${questionnaires[dataIdx].id}/close`);

    if (res.status === 200) {
      const dataCopy = questionnaires.slice();
      dataCopy[dataIdx].outboundQuestionnaire = [res.data.data];
      onUpdateQuestionnaires(dataCopy);
    }
  };

  const handleCloseDateChanged = (dataIdx: number) => async (newCloseDate: Moment | null) => {
    try {

      const res = await API.put(`outbound-questionnaire/${questionnaires[dataIdx].id}`, { closeDate: newCloseDate });

      const dataCopy = questionnaires.slice();
      dataCopy[dataIdx].outboundQuestionnaire = [res.data.data];
      onUpdateQuestionnaires(dataCopy);
      showSuccessResultBar('Close date updated successfully.');
    } catch (err) {
      Sentry.captureException(err);
      showErrorResultBar('Unexpected error while updating the questionnaire close date.');
    }
  };

  const handleAnonymousToggle = (dataIdx: number) => async (anonymous: boolean | null) => {
    try {

      const res = await API.put(`outbound-questionnaire/${questionnaires[dataIdx].id}`, { allowAnonymous: anonymous });
      const dataCopy = questionnaires.slice();
      dataCopy[dataIdx].outboundQuestionnaire = [res.data.data];
      onUpdateQuestionnaires(dataCopy);
      showSuccessResultBar('Anonymous responses updated successfully.');
    } catch (err) {
      Sentry.captureException(err);
      showErrorResultBar('Unexpected error while updating the anonymous responses.');
    }
  };

  const handleMultipleResponseToggle = (dataIdx: number) => async (multipleResponse: boolean | null) => {
    try {

      const res = await API.put(`outbound-questionnaire/${questionnaires[dataIdx].id}`, { allowMultipleResponses: multipleResponse });

      const dataCopy = questionnaires.slice();
      dataCopy[dataIdx].outboundQuestionnaire = [res.data.data];
      onUpdateQuestionnaires(dataCopy);
      showSuccessResultBar('Multiple responses updated successfully.');
    } catch (err) {
      Sentry.captureException(err);
      showErrorResultBar('Unexpected error while updating allowing multiple responses.');
    }
  };

  return (
    <>
      <SpioDataTable
        title={'Outbound Questionnaires'}
        columns={getTableColumns(tableData)}
        data={tableData}
        options={{
          print: false,
          filterType: 'multiselect',
          selectableRows: 'none',
          textLabels: {
            body: {
              noMatch: isLoading ? 'Loading...' : 'No records found',
              toolTip: 'Sort',
            },
          },
          expandableRows: true,
          expandableRowsOnClick: true,
          renderExpandableRow: (rowData, rowMeta) => {
            const colSpan = rowData.length + 1;
            const myData = questionnaires[rowMeta.dataIndex];
            const rowDataIndex = rowMeta.dataIndex;

            return myData ? (
              <TableRow>
                <TableCell colSpan={colSpan}>
                  <OutboundQuestionnaireDetails
                    auth={auth}
                    questionnaireData={myData}
                    history={history}
                    markOpen={markOpen(rowDataIndex)}
                    markClose={markClose(rowDataIndex)}
                    questionnaireStatus={myData.outboundQuestionnaire.length > 0 ? myData.outboundQuestionnaire[0].status : 'closed'}
                    onCloseDateChange={handleCloseDateChanged(rowDataIndex)}
                    onAnonymousToggle={handleAnonymousToggle(rowDataIndex)}
                    onMultipleResponseToggle={handleMultipleResponseToggle(rowDataIndex)}
                  />
                </TableCell>
              </TableRow>
            ) : null;
          },
        }}
      />
    </>
  );
}

export default withStyles(styles, { withTheme: true })(OutboundQuestionnaireTable);
