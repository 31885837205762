import { Button, DialogActions, DialogContent, Link, StandardProps, TextField, Typography } from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/styles';
import { Formik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { IAwsOrg, IAwsRoleArnUploadDto } from '../../../../backend/src/user-audit/interfaces';
import API from '../../services/ApiService';
import { SaveButton } from '../buttons';
import { showErrorResultBar, showSuccessResultBar } from '../ResultSnackbar';
import StyledDialogTitle from '../StyledDialogTitle';
import ResponsiveDialog from './ResponsiveDialog';

export const AWS_SPIO_ROLE_ARN_REGEX = /^arn:aws(-cn|-us-gov)?:iam::(\d{12}):role\/(\w*\/)?spio/;

const useStyles = makeStyles({
  instructionList: {
    color: 'rgb(33, 43, 54, 0.8)',
    fontSize: '0.95rem',
    lineHeight: '1.5rem',
  },
  instructionNestedList: {
    listStyleType: 'lower-alpha',
  },
  toggleViewLink: {
    'cursor': 'pointer',
    'textDecoration': 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

const AWSRoleArnSchema = Yup.object().shape({
  roleArn: Yup
    .string()
    .label('AWS Role ARN')
    .matches(AWS_SPIO_ROLE_ARN_REGEX, 'Incorrect ARN format')
    .required('Required'),
});

const AWSOrgNameSchema = Yup.object().shape({
  name: Yup
    .string()
    .trim()
    .max(255, 'AWS organization names are limited to 255 characters')
    .label('AWS Org name')
    .required('Required'),
});

interface UploadARNFormProps {
  externalId: string;
  onCancel: () => void;
  onValidateRoleARN: (formValues: { roleArn: string }) => Promise<void>;
}

function UploadARNForm({ externalId, onCancel, onValidateRoleARN }: UploadARNFormProps) {
  const classes = useStyles();
  const [ isGovCloud, setIsGovCloud ] = useState(false);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        roleArn: '',
      }}
      validationSchema={AWSRoleArnSchema}
      onSubmit={async (values, { setSubmitting }) => {
        await onValidateRoleARN(values);
        setSubmitting(false);
      }}
      onReset={_ => {
        onCancel();
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        handleReset,
        handleSubmit,
        isSubmitting,
      }) => (
        <>
          <DialogContent>
            <Typography
              variant="body1"
              gutterBottom
            >
              For each AWS account you wish to audit, we require a Role ARN.
            </Typography>
            <Typography
              variant="caption"
              gutterBottom
            >
              For a{isGovCloud ? ' non-' : 'n AWS '}GovCloud account,
              please <Link
                className={classes.toggleViewLink}
                onClick={() => setIsGovCloud(!isGovCloud)}
              >
                click here
              </Link> before proceeding.
            </Typography>
            <ol className={classes.instructionList}>
              <li>
                In the AWS console, navigate
                to <Link
                  href={`https://console.${isGovCloud ? 'amazonaws-us-gov' : 'aws.amazon'}.com/iam/home#/roles`}
                  rel="noopener noreferrer"
                  target="_blank"
                >Roles</Link>.
              </li>
              <li>Click the <em>Create role</em> button.</li>
              <li>For the type of trusted entity, select <em>Another AWS account</em>.</li>
              <li>
                For the Account ID,
                use: <em>{isGovCloud ? process.env.REACT_APP_AWS_GOVCLOUD_SPIO_ACCOUNT_ID : process.env.REACT_APP_AWS_SPIO_ACCOUNT_ID}</em>.
              </li>
              <li>Click the checkbox <em>Require external ID</em>.</li>
              <li>For the External ID, use: <em>{externalId}</em></li>
              <li>Click <em>Next: Permissions</em>.</li>
              <li>In the <em>Filter policies</em> box, type <em>IAM</em>.</li>
              <li>Click the checkbox next to <em>IAMReadOnlyAccess</em> to select it.</li>
              <li>Click <em>Next: Tags</em> and add any tags you might want.</li>
              <li>Click <em>Next: Review</em>.</li>
              <li>
                <b>Important</b>: Name the role <em>spio</em>. (You can add additional characters after "spio", but
                the role name must start with "spio".)
              </li>
              <li>Click <em>Create role</em>.</li>
              <li>
                Once back on the Roles screen, click the name of your newly-created "spio" role
                to bring up its Summary.
              </li>
              <li>Copy the <em>Role ARN</em> and paste it into the input below.</li>
            </ol>
            <Typography
              variant="body1"
              gutterBottom
            >
              After the ARN is validated you will choose a name for the associated AWS account.
            </Typography>
            <Typography
              variant="caption"
              gutterBottom
            >
              Note:
              Other managers on your team will be able to run the audit functionality, but
              they <b>will not</b> have direct access to this role.
            </Typography>
            <TextField
              name="roleArn"
              value={values.roleArn || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              margin="dense"
              multiline
              placeholder="arn:aws:iam::<account-id>:role/spio"
              helperText={errors.roleArn}
              error={!!errors.roleArn}
            />
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              size="small"
              disabled={isSubmitting}
              onClick={handleReset}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              color="primary"
              disabled={isSubmitting || Object.keys(errors).length > 0}
              onClick={() => handleSubmit()}
            >
              Validate
            </Button>
          </DialogActions>
        </>
      )}
    </Formik>
  );
}

interface IAWSSaveOrg { name: string; }

interface UploadAWSOrgNameFormProps {
  onCancel: () => void;
  onSaveOrg: (formValues: IAWSSaveOrg) => Promise<void>;
}

function UploadAWSOrgNameForm({ onCancel, onSaveOrg }: UploadAWSOrgNameFormProps) {
  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: '',
      }}
      validationSchema={AWSOrgNameSchema}
      onSubmit={async (values, { setSubmitting }) => {
        await onSaveOrg(values);
        setSubmitting(false);
      }}
      onReset={_ => {
        onCancel();
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        handleReset,
        handleSubmit,
        isSubmitting,
      }) => (
        <>
          <DialogContent>
            <Typography
              variant="body1"
              gutterBottom
            >
              To complete the process please assign a name to this AWS organization.
            </Typography>
            <TextField
              name="name"
              value={values.name || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              // margin="dense"
              // multiline
              placeholder="Enter name here"
              helperText={errors.name}
              error={!!errors.name}
            />
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              size="small"
              disabled={isSubmitting}
              onClick={handleReset}
            >
              Cancel
            </Button>
            <SaveButton
              disabled={isSubmitting || Object.keys(errors).length > 0}
              onClick={handleSubmit}
            />
          </DialogActions>
        </>
      )}
    </Formik>
  );
}

export interface UserAuditAwsSetupDialogProps extends StandardProps<DialogProps, 'children'> {
  handleClose: () => void;
  handleNewOrg: (newOrg: IAwsOrg) => void;
}

function UserAuditAwsSetupDialog({ open, handleClose, handleNewOrg }: UserAuditAwsSetupDialogProps) {
  const [ externalId, setExternalId ] = useState<string>('');
  const [ isValidArn, setIsValidArn ] = useState<boolean>(false);

  // Generates a new external id:
  const onEnter = async () => {
    let errorMsg: string;

    API.post('userAudit/aws/org')
      .then((res) => {
        if (!res.data || !res.data.data) {
          errorMsg = 'Unexpected error retrieving external ID';
        } else {
          setExternalId(res.data.data);
        }
      })
      .catch((err) => {
        errorMsg = (err.response && err.response.data && err.response.data.error) || 'Unexpected error retrieving external ID';
      })
      .finally(() => {
        if (errorMsg) {
          showErrorResultBar(errorMsg);
        }
      });
  };

  const onValidateRoleARN = async (formValues: IAwsRoleArnUploadDto) => {
    // TODO: Add something to the error message like 'If you just saved the Role in AWS, wait a few seconds and try again.'
    let errorMsg: string;

    API.patch(`userAudit/aws/org/${externalId}/arn`, formValues)
      .then(() => {
        setIsValidArn(true);
      })
      .catch((err) => {
        errorMsg = (err.response && err.response.data && err.response.data.error) || 'Unexpected error checking the AWS Role ARN';
      })
      .finally(() => {
        if (errorMsg) {
          showErrorResultBar(errorMsg);
        }
      });
  };

  const onSaveOrg = async (formValues: IAWSSaveOrg) => {
    let errorMsg: string;
    let successMsg: string;

    API.patch(`userAudit/aws/org/${externalId}/name`, formValues)
      .then((res) => {
        if (!res.data || !res.data.data) {
          errorMsg = 'Unexpected error saving AWS org details';
        }

        const createdOrg: IAwsOrg = res.data.data;
        successMsg = `${createdOrg.name} successfully created`;

        handleNewOrg(createdOrg);
        onCancel();
      })
      .catch((err) => {
        errorMsg = (err.response && err.response.data && err.response.data.error) || 'Unexpected error while saving AWS org';
      })
      .finally(() => {
        if (errorMsg) {
          showErrorResultBar(errorMsg);
        } else if (successMsg) {
          showSuccessResultBar(successMsg);
        }
      });
  };

  const onCancel = () => {
    handleClose();
  };

  const clearData = () => {
    setIsValidArn(false);
    setExternalId('');
  };

  return (
    <ResponsiveDialog
      open={open}
      onClose={onCancel}
      onEnter={onEnter}
      onExited={clearData}
      disableBackdropClick
    >
      <StyledDialogTitle
        onClose={onCancel}
      >
        AWS Account Setup
      </StyledDialogTitle>
      {!isValidArn ? (
          <UploadARNForm
            externalId={externalId}
            onCancel={onCancel}
            onValidateRoleARN={onValidateRoleARN}
          />
        ) : (
          <UploadAWSOrgNameForm
            onCancel={onCancel}
            onSaveOrg={onSaveOrg}
          />
      )}
    </ResponsiveDialog>
  );
}

export default UserAuditAwsSetupDialog;
