import {
    Button,
    DialogActions,
    DialogContent,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    createTheme,
    MuiThemeProvider,
    Typography
} from '@material-ui/core';
import { FormikRadioField } from '../forms';
import { Formik } from 'formik';
import { DialogProps } from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/styles';
import * as Sentry from '@sentry/browser';
import React, { ReactElement, useState } from 'react';
import { showErrorResultBar, showSuccessResultBar } from '../ResultSnackbar';
import StyledDialogTitle from '../StyledDialogTitle';
import ResponsiveDialog from './ResponsiveDialog';
import SaveButton from '../buttons/SaveButton';
import {
    ISubTaskDto,
    ISubTaskUpdateDto
  } from '../../../../backend/src/task/interfaces';
import Theme from '../../theme';
import { handleError } from '../../helpers';

const useStyles = makeStyles({
    root: {
        margin: 0,
        padding: 0,
        whiteSpace: 'unset',
        wordBreak: 'break-all'
    },
    switchField: {
      marginLeft: 0,
      marginRight: 0,
      marginTop: -10,

    },
    inputField: {
        marginRight: 0,
        marginLeft: 0,
    },
    tagField: {
        marginLeft: -10,
        marginRight: 0,
    },
    selectContainer: {
        marginTop: 35,
    }
});

const dtTheme = () => createTheme({
    overrides: {
        MuiRadio: {
          colorSecondary: {
            '&$checked': {
              color: Theme.palette.primary.main,
            },
          },
        },
    },
});

export const HELPER_TEXT = {
    nextSubtask: 'Select the existing subtask that the new subtask should come before or after.'
};

export interface SubtaskReorderDialogProps extends DialogProps {
    children?: string | ReactElement;
    title?: string;
    onSave: (updateDto: ISubTaskUpdateDto) => void;
    onClose: () => void;
    open: boolean;
    subtasks: ISubTaskDto[];
    subtaskData: ISubTaskDto;
}

export default function SubtaskReorderDialog(props: SubtaskReorderDialogProps) {
  const classes = useStyles();
  const { onClose, onSave, open, subtasks, subtaskData } = props;
  const [ showSubmitErrors, setShowSubmitErors ] = useState<boolean>(false);

  // const taskSchema = Yup.object().shape({
  //   nextSubtask: Yup
  //     .mixed()
  //     .test({
  //       test: async function(values: Record<string, any>) {
  //         let isValid = false;
  //         if (values) {
  //           if (subtasks.map(st => st.id).includes(values.id)) {
  //             isValid = true;
  //           }
  //         }
  //         if(isValid) return true;
  //         return this.createError({
  //           path: 'nextSubtask',
  //           message: 'Please select a valid subtask.',
  //         });
  //       }
  //     },
  //     )
  //     .required('Select a subtask.'),
  // });

  const initOrder = (subtasks: ISubTaskDto[], subtaskData: ISubTaskDto) => {
      const taskIdx = subtasks.map(t => t.id).indexOf(subtaskData.id);
      if (taskIdx < 1) {
        return 'before';
      } else {
        return 'after';
      }
  };

  const initNext = (subtasks: ISubTaskDto[], subtaskData: ISubTaskDto) => {
    const taskIdx = subtasks.map(t => t.id).indexOf(subtaskData.id || '');
    if (taskIdx < 0) {
      return '';
    } else if (taskIdx === 0) {
      return subtasks[1];
    } else {
      return subtasks[taskIdx - 1];
    }
};

  const handleClose = () => {
    onClose();
  };

  const invalidForm = () => {
    setShowSubmitErors(true);
    showErrorResultBar('One or more errors on form.');
  };

  const handleSave = async (formValues: Record<string, any>) => {
    let newOrder = 0;
    // find new order
    if (subtaskData.subtaskOrder < formValues.nextSubtask.subtaskOrder) {
      if (formValues.subtaskOrder === 'before') {
        newOrder = formValues.nextSubtask.subtaskOrder - 1;
      } else {
        newOrder = formValues.nextSubtask.subtaskOrder;
      }
    } else {
      if (formValues.subtaskOrder === 'before') {
        newOrder = formValues.nextSubtask.subtaskOrder;
      } else {
        newOrder = formValues.nextSubtask.subtaskOrder + 1;
      }
    }

    try {
      let updateDto: ISubTaskUpdateDto = {
          subtaskOrder: newOrder
      };
      onSave(updateDto);
      showSuccessResultBar('Subtask reordered successfully');
    } catch (err) {
      handleError(err, 'Error saving subtask order');
    }
  };

  return (
    <>
    <ResponsiveDialog
      disableBackdropClick
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
    >
      <MuiThemeProvider theme={dtTheme}>
      <StyledDialogTitle onClose={onClose}>
        {'Move subtask'}
      </StyledDialogTitle>
      <Formik
        enableReinitialize
        initialValues={{
          nextSubtask: initNext(subtasks, subtaskData),
          subtaskOrder: initOrder(subtasks, subtaskData),
        }}
        // validationSchema={taskSchema}
        onSubmit={async (values, { setSubmitting }) => {
          await handleSave(values);
          setSubmitting(false);
          handleClose();
        }}
      >
        {formikProps => (
          <>
            <DialogContent>
              <Grid container>
                <Typography variant={'subtitle1'}>Subtask should come:</Typography>

                    <Grid container alignItems="flex-start" spacing={0}>
                      <Grid item xs={12} sm={2} md={3}>
                        <FormikRadioField
                            field="subtaskOrder"
                            formikProps={formikProps}
                            label=""
                            selections={{before: 'before', after: 'after'}}
                          />
                      </Grid>
                      <Grid item xs={12} sm={10} md={8} className={classes.selectContainer}>
                        <FormControl
                          className={classes.switchField}
                          variant={'filled'}
                          style={{maxWidth: 400}}
                        >
                          <InputLabel>
                            {'Existing Subtask'}
                          </InputLabel>
                          <Select
                            id={'nextSubtask'}
                            name={'nextSubtask'}
                            value={formikProps.values['nextSubtask']}
                            onChange={formikProps.handleChange}
                          >
                            {subtasks.map((item) => (
                                <MenuItem value={item as any} key={item.id} disabled={item.id === subtaskData?.id}>
                                    {item.title}
                                </MenuItem>
                             ))
                            }
                          </Select>
                          {HELPER_TEXT.nextSubtask ? <FormHelperText>{HELPER_TEXT.nextSubtask}</FormHelperText> : null}
                          {formikProps.errors.nextSubtask && showSubmitErrors ? <FormHelperText error={true}>{formikProps.errors.nextSubtask}</FormHelperText> : null}
                        </FormControl>
                      </Grid>
                    </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                disabled={formikProps.isSubmitting}
                onClick={handleClose}
                color="primary"
              >
                {'Cancel'}
              </Button>
              <SaveButton
                disabled={formikProps.isSubmitting}
                onClick={e => {
                  formikProps.isValid ?
                  formikProps.handleSubmit(e)
                  : invalidForm();
                }}
              />
            </DialogActions>
          </>
        )}
      </Formik>
      </MuiThemeProvider>
    </ResponsiveDialog>
  </>
  );
}
