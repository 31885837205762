import React, { useEffect, useState } from 'react';
import { Button, createStyles, Theme, WithStyles, withStyles } from '@material-ui/core';
import { Gantt, Task, ViewMode } from 'gantt-task-react';
import 'gantt-task-react/dist/index.css';
import GanttViewSwitcher from './GanttViewSwitcher';
import { geminiPurple } from '../theme';
import { grey } from '@material-ui/core/colors';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      boxSizing: 'border-box',
    },
    center: {
      textAlign: 'center',
    },
    scrollableContainer: {
      width: '80vw',
      overflow: 'auto',
      boxSizing: 'border-box',
    },
    toggleButton: {
      marginBottom: theme.spacing(2),
    },
  });

export interface IGanttTask extends Task {
  projectGroup: string;
  projectCollection: string;
  isDisabled: boolean;
  hideChildren: boolean;
  projectType: 'group' | 'collection';
  displayOrder: number;
}

interface GanttChartProps extends WithStyles<typeof styles> {
  center?: boolean;
  taskData: IGanttTask[];
  handleUpdateTasks: (tasks: IGanttTask[]) => void;
}

const adjustToStartOfDay = (date: Date): Date => {
  const newDate = new Date(date);
  newDate.setHours(1, 0, 0, 0);
  return newDate;
};
const adjustToEndOfDay = (date: Date): Date => {
  const newDate = new Date(date);
  newDate.setHours(23, 0, 0, 0);
  return newDate;
};

function GanttChart(props: GanttChartProps) {
  const { classes, taskData, handleUpdateTasks } = props;
  const [view, setView] = useState<ViewMode>(ViewMode.Day);
  const [viewCollection, setViewCollection] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const [tasks, setTasks] = useState<IGanttTask[]>(taskData);
  const [ganttTasks, setGanttTasks] = useState<Task[]>(taskData);
  const [editsMade, setEditsMade] = useState<boolean>(false);

  let columnWidth = 60;
  if (view === ViewMode.Month) {
    columnWidth = 300;
  } else if (view === ViewMode.Week) {
    columnWidth = 250;
  }

  useEffect(() => {
    let taskCopy = taskData.map((task) => ({
      ...task,
      isDisabled: !isEditMode,
    }));

    const updatedTasks = addDependencies(viewCollection, taskCopy);

    setTasks(updatedTasks);
  }, [taskData, isEditMode]);

  const addDependencies = (viewCollection: boolean, updateTasks: IGanttTask[]) => {
    // Add dependencies based on the viewCollection state
    if (!viewCollection) {
      // When viewing collections, make each task dependent on the previous one in the collection
      const collectionTasks: Record<string, IGanttTask[]> = updateTasks.reduce<Record<string, IGanttTask[]>>((acc, task) => {
        if (task.type === 'task') {
          if (!acc[task.projectCollection]) {
            acc[task.projectCollection] = [];
          }
          acc[task.projectCollection].push(task);
        }
        return acc;
      }, {});

      Object.values(collectionTasks).forEach(collectionTasks => {
        let previousTaskId: string | null = null;
        collectionTasks.forEach((task) => {
          if (previousTaskId) {
            task.dependencies = [previousTaskId];
          } else {
            task.dependencies = [];
          }
          previousTaskId = task.id;
        });
      });
    } else {
      // When viewing groups, make each task within a group dependent on the previous task in the group
      const groupedTasks: Record<string, IGanttTask[]> = updateTasks.reduce<Record<string, IGanttTask[]>>((acc, task) => {
        if (task.type === 'task') {
          if (!acc[task.projectGroup]) {
            acc[task.projectGroup] = [];
          }
          acc[task.projectGroup].push(task);
        }
        return acc;
      }, {});

      Object.values(groupedTasks).forEach(groupTasks => {
        let previousTaskId: string | null = null;
        groupTasks.forEach((task) => {
          if (previousTaskId) {
            task.dependencies = [previousTaskId];
          } else {
            task.dependencies = [];
          }
          previousTaskId = task.id;
        });
      });
    }

    return updateTasks;
  };

  useEffect(() => {
    const updatedTasks = tasks
      .filter((t) =>
        viewCollection
          ? t.projectType === 'group' || t.type === 'task'
          : t.projectType === 'collection' || t.type === 'task'
      )
      .map((t) => ({
        ...t,
        project: viewCollection ? t.projectGroup : t.projectCollection,
      }));
    setGanttTasks(updatedTasks);
  }, [tasks, viewCollection]);

  const handleTaskChange = (task: Task) => {
    setEditsMade(true);

    // find existing task to update
    const taskIdx = tasks.findIndex((t) => t.id === task.id);
    const taskToUpdate = tasks[taskIdx];
    // Adjust the task's start and end to the start of the day
    const adjustedTask = {
      ...taskToUpdate,
      start: adjustToStartOfDay(task.start),
      end: adjustToEndOfDay(task.end),
    };

    const oldTasks = tasks.slice();
    let newTasks = oldTasks.map((t) => (t.id === task.id ? adjustedTask : t));

    const updateCollectionDates = (projectTasks: IGanttTask[], projectId: string) => {
      const [start, end] = getStartEndDateForCollection(projectTasks, projectId);
      const newProjectTasks = projectTasks.map((t: any) => {
        if (t.id === projectId) {
          return { ...t, start, end };
        }
        return t;
      });
      return newProjectTasks;
    };

    const updateGroupDates = (projectTasks: IGanttTask[], projectId: string) => {
      const [start, end] = getStartEndDateForGroup(projectTasks, projectId);
      return projectTasks.map((t) => {
        if (t.id === projectId) {
          return { ...t, start, end };
        }
        return t;
      });
    };

    // Calculate the duration change
    const taskIndex = tasks.findIndex((t) => t.id === task.id);
    const originalTask = tasks[taskIndex];
    const durationChange =
      (adjustedTask.start.getTime() - originalTask.start.getTime()) / (1000 * 60 * 60 * 24);

    // Function to shift dependent tasks recursively
    const shiftDependentTasks = (taskId: string, shift: number) => {
      newTasks = newTasks.map((t) => {
        if (t.dependencies && t.dependencies.includes(taskId)) {
          const newStart = adjustToStartOfDay(
            new Date(t.start.getTime() + shift * (1000 * 60 * 60 * 24))
          );
          const newEnd = adjustToEndOfDay(
            new Date(t.end.getTime() + shift * (1000 * 60 * 60 * 24))
          );
          return { ...t, start: newStart, end: newEnd };
        }
        return t;
      });

      // Recursively shift tasks that depend on the current task
      newTasks.forEach((t) => {
        if (t.dependencies && t.dependencies.includes(taskId)) {
          shiftDependentTasks(t.id, shift);
        }
      });
    };

    // Shift dependent tasks
    shiftDependentTasks(adjustedTask.id, durationChange);

    // Ensure group/project start and end dates are updated after all adjustments
    if (adjustedTask.projectCollection) {
      newTasks = updateCollectionDates(newTasks, adjustedTask.projectCollection);
    }
    if (adjustedTask.projectGroup) {
      newTasks = updateGroupDates(newTasks, adjustedTask.projectGroup);
    }

    setTasks(newTasks);
  };

  const getStartEndDateForGroup = (startEndTasks: IGanttTask[], projectId: string) => {
    const projectTasks = startEndTasks.filter((t) => t.projectGroup === projectId);
    let start = projectTasks[0].start;
    let end = projectTasks[0].end;

    for (let i = 1; i < projectTasks.length; i++) {
      const task = projectTasks[i];
      if (start.getTime() > task.start.getTime()) {
        start = task.start;
      }
      if (end.getTime() < task.end.getTime()) {
        end = task.end;
      }
    }

    return [start, end];
  };

  const getStartEndDateForCollection = (startEndTasks: IGanttTask[], projectId: string) => {
    const projectTasks = startEndTasks.filter((t) => t.projectCollection === projectId);

    let start = projectTasks[0].start;
    let end = projectTasks[0].end;

    for (let i = 1; i < projectTasks.length; i++) {
      const task = projectTasks[i];
      if (start.getTime() > task.start.getTime()) {
        start = task.start;
      }
      if (end.getTime() < task.end.getTime()) {
        end = task.end;
      }
    }

    return [start, end];
  };

  const handleExpanderClick = (task: Task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task as IGanttTask : t)));
  };

  const handleRevertChanges = () => {
    setTasks(taskData);
    setIsEditMode(false);
  };

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  const handleSaveChanges = (tasks: IGanttTask[]) => {
    handleUpdateTasks(tasks);
    setEditsMade(false);
    setIsEditMode(false);
  };

  const changeViewCollection = (newViewcollection: boolean) => {
    setViewCollection(newViewcollection);
    const taskCopy = tasks.slice();
    setTasks(addDependencies(newViewcollection, taskCopy));
  };

  return (
    <div className={classes.root}>
      <div className={classes.scrollableContainer}>
        {!isEditMode && (
          <Button
            variant="contained"
            color="primary"
            onClick={toggleEditMode}
            className={classes.toggleButton}
          >
            {'Edit Mode'}
          </Button>
        )}
        {isEditMode && (
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {handleSaveChanges(tasks);}}
              className={classes.toggleButton}
              disabled={!editsMade}
            >
              {'Save Changes'}
            </Button>
            <Button
              variant="contained"
              color="inherit"
              onClick={handleRevertChanges}
              className={classes.toggleButton}
            >
              {editsMade ? 'Revert Changes' : 'Cancel'}
            </Button>
          </div>
        )}
        <GanttViewSwitcher
          onViewModeChange={(viewMode) => setView(viewMode)}
          onViewListChange={setIsChecked}
          isChecked={isChecked}
          viewCollection={viewCollection}
          onViewCollectionChange={changeViewCollection}
        />
        <Gantt
          tasks={ganttTasks}
          viewMode={view}
          onDateChange={handleTaskChange}
          onExpanderClick={handleExpanderClick}
          listCellWidth={isChecked ? '175px' : ''}
          columnWidth={columnWidth}
          projectProgressColor={geminiPurple[700]}
          projectBackgroundColor={grey[400]}
          projectProgressSelectedColor={geminiPurple[800]}
          projectBackgroundSelectedColor={grey[500]}
        />
      </div>
    </div>
  );
}

export default withStyles(styles, { withTheme: true })(GanttChart);
