import {
    createStyles,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme,
  } from '@material-ui/core';
import React from 'react';
  import CheckIcon from '@material-ui/icons/Check';
import { IPermissionDto, IRoleDto } from '../../../backend/src/org-share/interfaces';
import Auth from '../services/AuthService';
import { geminiPurple } from '../theme';

const useStyles = makeStyles((theme: Theme) => createStyles({
    checkIcon: {
      color: theme.palette.secondary.contrastText,
    },
    selectedIcon: {
      color: 'black',
    },
    tableHeader: {
      fontSize: '1.15em',
    },
    tableSubHeader: {
        fontSize: '1.1em',
        paddingTop: '5px',
        paddingBottom: '5px',
        color: theme.palette.secondary.contrastText,
    },
    tableTitle: {
        fontSize: '1.35em',
    },
    selectedCell: {
      backgroundColor: geminiPurple[100]
    }
  }));

interface PermissionsTableProps {
  permissions: IPermissionDto[];
  roles: IRoleDto[];
  tools: string[];
  showMyRole?: boolean;
  auth: Auth;
}

function PermissionsTable(props: PermissionsTableProps) {
  const {
    permissions,
    roles,
    tools,
    showMyRole = true,
    auth
  } = props;

  const classes = useStyles();
  const ExpandableBooleanRow = (permission: IPermissionDto) => {
    return (
      <>
        <TableRow
          key={permission.id}>
          <TableCell>
          </TableCell>
          <TableCell className={classes.tableHeader} component="th" scope="row">
            {permission.description}
          </TableCell>
          {showMyRole &&
                <TableCell align="center" className={classes.selectedCell}>
                    {auth.isGranted({ permission: permission.name}) ?
                      <CheckIcon className={classes.selectedIcon} />
                    :
                      <></>
                    }
                </TableCell>
            }
            {roles.map((role, idx) => (
                <TableCell align="center">
                    {permission.roles?.map(r=>r.id).includes(role.id) ?
                      <CheckIcon className={classes.checkIcon} />
                    :
                      <></>
                    }
                </TableCell>
            ))}
        </TableRow>
      </>
    );
  };

  return (
    <>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableTitle} colSpan={2}>Permissions</TableCell>
                {showMyRole &&
                  <TableCell key={'My Role'} className={classes.tableHeader} align="center">
                    My Current Permissions
                  </TableCell>
                }
                {roles.map((role, idx) => (
                  <>
                    <TableCell key={role.id} className={classes.tableHeader} align="center">
                        {role.name}
                    </TableCell>
                  </>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tools.map(tool => (
              <>
              {tool !== 'admin' && tool !== 'role' &&
                <>
                <TableCell className={classes.tableSubHeader} colSpan={roles.length + 3}>
                    {tool.toUpperCase()}
                </TableCell>
                {permissions.map(permission => (
                    <>
                    {permission.tool === tool &&
                        <>
                            {(permission.roles?.some(r => roles.map(role => role.name).indexOf(r.name) >= 0) && !permission.name.includes('is_role')) &&
                                ExpandableBooleanRow(permission)
                            }
                        </>
                    }
                    </>
                ))}
                </>
                }
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
    </>
  );
}

export default PermissionsTable;
