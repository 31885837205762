import React, { useState } from 'react';
import {
  createStyles,
  Accordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Grid,
  Link,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import Assignment from '@material-ui/icons/Assignment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as Sentry from '@sentry/browser';
import {
  ISubTaskDto,
  ITaskAssigneeDto
} from '../../../backend/src/task/interfaces';
import API from '../services/ApiService';
import Auth from '../services/AuthService';
import SubTaskInput from './SubTaskInput';
import { showErrorResultBar } from './ResultSnackbar';
import SubTaskList from './SubTaskList';
import { TaskStatus } from '../../../backend/src/task/task-status.enum';

const AccordionSummary = withStyles({
  root: {
    'borderBottom': '1px solid rgba(0, 0, 0, .125)',
    'marginBottom': -1,
    'minHeight': 0,
    '&$expanded': {
      minHeight: 0,
    },
  },
  content: {
    'margin': '2px 0',
    '&$expanded': {
      margin: '2px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const styles = (theme: Theme) => createStyles({
  addSubTaskLink: {
    cursor: 'pointer',
    display: 'inline-block',
    paddingTop: '1rem',
    paddingLeft: '1rem',
  },
  disabledAddSubTaskLink: {
    color: '#bfbfbf',
    cursor: 'pointer',
    '&:hover': {
      color: '#bfbfbf',
    },
    display: 'inline-block',
    paddingTop: '1rem',
    paddingLeft: '1rem',
  },
  subTasksExpansionSummary: {
    'padding': 0,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  subTasksExpansionDetails: {
    padding: 0,
  },
  subTaskIcon: {
    fontSize: '0.875rem',
  },
  subTaskNumberText: {
    paddingLeft: '2px',
    fontSize: '0.875rem',
  },
  subTaskHeading: {
    fontWeight: 'bold',
    marginTop: '0.5rem',
    color: 'inherit',
  },
  nbSubTasksHeading: {
    marginLeft: '1rem',
    marginTop: '0.5rem',
    color: 'inherit',
  },
});

export interface ISubTaskInputData {
  id?: string;
  title: string;
  description: string;
}

export interface SubTasksProps extends WithStyles<typeof styles> {
  auth: Auth;
  subTasks?: ISubTaskDto[];
  onUpdateSubTasks: (updatedSubTasks: ISubTaskDto[]) => void;
  updateProgress: (checked: (boolean | undefined)[], subTaskUpdates?: ISubTaskDto[] | undefined) => void;
  taskId: string;
  taskStatus: TaskStatus;
  markStarted: () => void;
  readOnly: boolean;
  assignees: ITaskAssigneeDto[];
}

function SubTasks(props: SubTasksProps) {
  const {
    classes,
    subTasks = [],
    taskId,
    onUpdateSubTasks,
    updateProgress,
    taskStatus,
    markStarted,
    auth,
    readOnly,
    assignees
  } = props;
  const [ isAddingSubtask, setIsAddingSubtask ] = useState(false);
  const [checked, setChecked] = React.useState(subTasks.map(st => st.completed));

  const nbSubTasks = subTasks ? subTasks.length : 0;

  const onSaveSubTask = async (formData: ISubTaskInputData) => {
    let updatedSubTasks;
    const subTaskId = formData.id; // 'undefined' if new subtask
    try {
      if (subTaskId) {
        // Updating an existing subtask
        const newSubTask = (await API.patch(`task/${taskId}/subTask/${subTaskId}`, { title: formData.title, description: formData.description })).data.data;
        const subTaskIdx = subTasks.findIndex(subTask => subTask.id === subTaskId);
        updatedSubTasks = subTasks.slice();
        updatedSubTasks[subTaskIdx] = newSubTask;
      } else {
        // Adding a new subtask
        const newSubTask = (await API.post(`task/${taskId}/subTask`,  { title: formData.title, description: formData.description })).data.data;
        subTasks.push(newSubTask);
        const newChecked = checked.concat(false);
        setChecked(newChecked);
        updatedSubTasks = subTasks;
        updateProgress(newChecked, subTasks);
      };
      onUpdateSubTasks(updatedSubTasks);
    } catch (err) {
      Sentry.captureException(err);
      showErrorResultBar('There was an error while saving your subtask.');
    }
  };

  async function clickOnAddSubTask(formData: ISubTaskInputData) {
    await onSaveSubTask(formData);
    setIsAddingSubtask(false);
  }

  return (
    <Accordion
      elevation={0}
      defaultExpanded={true}
    >
      <AccordionSummary
        className={classes.subTasksExpansionSummary}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography
          variant="body1"
          className={classes.subTaskHeading}
        >
          SubTasks
        </Typography>
        {nbSubTasks > 0 &&
        <Typography
          variant="body1"
          className={classes.nbSubTasksHeading}
        >
          <Assignment className={classes.subTaskIcon} />
          <span className={classes.subTaskNumberText}>
              {nbSubTasks}
            </span>
        </Typography>
        }
      </AccordionSummary>
      <AccordionDetails
        className={classes.subTasksExpansionDetails}
      >
        <Grid container>
          <Grid item xs={12}>
            <SubTaskList
              subTasks={subTasks}
              taskId={taskId}
              checked={checked}
              setChecked={setChecked}
              updateProgress={updateProgress}
              taskStatus={taskStatus}
              markStarted={markStarted}
              onAddSubTask={clickOnAddSubTask}
              readOnly={readOnly}
              assignees={assignees}
              onUpdateSubTasks={onUpdateSubTasks}
            />
          </Grid>
          <Grid item xs={12}>
              {isAddingSubtask  ? (
                <SubTaskInput
                  onCancelSubTask={() => setIsAddingSubtask(false)}
                  onSaveSubTask={clickOnAddSubTask}
                />
              ) : (
                <>
                {taskStatus !== 'completed' ? (
                  <Link
                    component="button"
                    variant="body2"
                    onClick={() => setIsAddingSubtask(true)}
                    disabled={readOnly}
                    className={readOnly ? classes.disabledAddSubTaskLink : classes.addSubTaskLink}
                  >
                    Add a subtask
                  </Link>
                ) : (
                  null
                )}
                </>
              )}
            </Grid>
          </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

export default withStyles(styles, { withTheme: true })(SubTasks);
