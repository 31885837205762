import {
  Button,
  Grid,
  Typography,
  Menu,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  FormControlLabel,
  Checkbox,
  ListItemSecondaryAction,
  IconButton,
  TextField,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import React, { ReactElement, useState, useEffect } from 'react';
import StyledDialogTitle from '../StyledDialogTitle';
import { Formik } from 'formik';
import ResponsiveDialog from './ResponsiveDialog';
import SaveButton from '../buttons/SaveButton';
import FilterListIcon from '@material-ui/icons/FilterList';
import * as Yup from 'yup';
import { truncate } from 'fs/promises';

const evidenceSchema = Yup.object({
  name: Yup.string().required(),
});

export interface AddEvidenceDialogProps extends DialogProps {
  children?: string | ReactElement;
  title?: string;
  handleSave: (formContent: object) => void;
  evidences: any;
  attachedEvidences: any;
  tags: any; //TODO: fix type
  onClose: () => void;
}

function AddEvidenceDialog({
  children,
  title,
  open,
  onClose,
  handleSave,
  attachedEvidences,
  evidences,
  tags,
  ...dialogProps
}: AddEvidenceDialogProps) {
  const [attachedEvidence, setAttachedEvidence] = useState<any[]>([]);
  const [unattachedEvidence, setUnattachedEvidence] = useState<any[]>([]);
  const [activeTags, setActiveTags] = useState<any>([]);
  const [query, setQuery] = useState('');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    let tempAttached: any = [];
    if (attachedEvidences[0]) {
      attachedEvidences.forEach((document: any) => {
        if (document.id)
          tempAttached.push(evidences.find((x: any) => x.id === document.id));
        else if (document.document_id) {
          tempAttached.push(
            evidences.find((x: any) => x.id === document.document_id)
          );
        } else return;
      });
      setAttachedEvidence(tempAttached);
      const unAttached: any = evidences.filter(
        (el: any) => !tempAttached.includes(el)
      );
      setUnattachedEvidence(unAttached);
      return;
    }

    if (evidences.length > 0) {
      setAttachedEvidence([]);
      setUnattachedEvidence(evidences);
    }
  }, [attachedEvidences]);

  useEffect(() => {
    let tempTag: any = {};
    tags.forEach((tag: any) => {
      tempTag[tag.name] = false;
    });
    setActiveTags(tempTag);
  }, [tags]);

  const handleAttachEvidence = (index: number) => {
    const tempArray = [...attachedEvidence];
    let copyWithoutEvidence = [...unattachedEvidence];
    tempArray.push(unattachedEvidence[index]);
    setAttachedEvidence(tempArray);
    copyWithoutEvidence.splice(index, 1);
    setUnattachedEvidence(copyWithoutEvidence);
  };
  const handleRemoveAttachedEvidence = (index: number) => {
    const tempArray = [...attachedEvidence];
    let copyWithoutEvidence = [...unattachedEvidence];
    copyWithoutEvidence.push(attachedEvidence[index]);
    setUnattachedEvidence(copyWithoutEvidence);
    tempArray.splice(index, 1);
    setAttachedEvidence(tempArray);
  };

  const handleFilter = (e: any, tag?: boolean) => {
    const unAttached: any = evidences.filter(
      (el: any) => !attachedEvidence.includes(el)
    );
    let unAttachedFiltered: any = unAttached;

    if (tag || activeTags) {
      const tempTags: any = activeTags;

      if (tag) {
        tempTags[e.target.name] = e.target.checked;
      }

      let filterTags: any = [];
      for (const [key, value] of Object.entries(tempTags)) {
        if (value) {
          filterTags.push(key);
        }
      }
      filterTags.forEach((tag: string) => {
        unAttachedFiltered = unAttachedFiltered.filter((newVal: any) => {
          const newValTags: any = newVal.documentTags.map(
            (tagDetails: any) => tagDetails.name
          );
          return newValTags.includes(tag);
        });
      });
      setActiveTags({ ...activeTags, [e.target.name]: e.target.checked });
    }

    const results = unAttachedFiltered.filter((filteredEvidence: any) => {
      if (e.target.value === '' && query.length === 1) {
        setQuery(e.target.value);
        return filteredEvidence.name
          .toLowerCase()
          .includes(e.target.value.toLowerCase());
      } else if (e.target.value === '' && query.length > 0) {
        setQuery(query);
        return filteredEvidence.name
          .toLowerCase()
          .includes(query.toLowerCase());
      } else {
        setQuery(e.target.value);
        return filteredEvidence.name
          .toLowerCase()
          .includes(e.target.value.toLowerCase());
      }
    });
    setUnattachedEvidence(results);
  };

  return (
    <ResponsiveDialog
      disableBackdropClick={true}
      fullWidth
      open={open}
      onClose={onClose}
      scroll="paper"
    >
      <StyledDialogTitle onClose={onClose}>Add Evidence</StyledDialogTitle>
      <Formik
        enableReinitialize
        initialValues={attachedEvidences}
        onSubmit={async (values, { setSubmitting }) => {
          handleSave(attachedEvidence);
          setSubmitting(false);
          onClose();
        }}
        onReset={(_) => {
          onClose();
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleReset,
          handleSubmit,
          isSubmitting,
        }) => (
          <>
            <DialogContent>
              <Grid
                container
                justifyContent="space-between"
                alignItems="flex-end"
                style={{ marginBottom: '2rem' }}
              >
                <Grid item xs={9}>
                  <TextField
                    autoFocus
                    // className={classes.textField}

                    id="evidence-name"
                    label="Search by Name"
                    name="filter"
                    type="text"
                    value={query}
                    onBlur={handleBlur}
                    onChange={(e) => handleFilter(e)}
                    error={!!errors.name}
                  />
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    aria-label="filter"
                    color="primary"
                    size="medium"
                    onClick={handleClick}
                    style={{ marginBottom: '-10px' }}
                  >
                    <FilterListIcon fontSize="inherit" />
                  </IconButton>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    {tags.map((tag: any, index: number) => (
                      <MenuItem key={index}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={tag.name}
                              checked={activeTags.name}
                              onChange={(e) => handleFilter(e, true)}
                              color="primary"
                            />
                          }
                          label={tag.name}
                        />
                      </MenuItem>
                    ))}
                  </Menu>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" color="primary">
                  Attached Evidences
                </Typography>
                <List>
                  {!attachedEvidence[0] && (
                    <ListItem>
                      <ListItemText
                        primary="No Evidence is attached"
                        primaryTypographyProps={{ color: 'textSecondary' }}
                      />
                    </ListItem>
                  )}
                  {attachedEvidence.length > 0 &&
                    attachedEvidence[0] &&
                    attachedEvidence.map(
                      (attachedEvidence: any, index: number) => {
                        return (
                          <ListItem key={index}>
                            <ListItemText
                              primary={attachedEvidence.name}
                              primaryTypographyProps={{ color: 'primary' }}
                              secondary={`Created By: ${attachedEvidence.createdByName}`}
                            />
                            <ListItemSecondaryAction>
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() =>
                                  handleRemoveAttachedEvidence(index)
                                }
                              >
                                <CloseIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        );
                      }
                    )}
                </List>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" color="primary">
                  Available Evidences
                </Typography>
                <List dense>
                  {unattachedEvidence.length > 0 &&
                    unattachedEvidence.map((evidence: any, index: number) => {
                      return (
                        <ListItem key={index}>
                          <ListItemText
                            primary={evidence.name}
                            primaryTypographyProps={{ color: 'textPrimary' }}
                            // secondary={`Created By: ${evidence.createdByName}`}
                            // secondaryTypographyProps={{ color: 'textPrimary' }}
                          />
                          <ListItemSecondaryAction>
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => handleAttachEvidence(index)}
                            >
                              <AddIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    })}
                </List>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                size="small"
                disabled={isSubmitting}
                onClick={handleReset}
              >
                Cancel
              </Button>
              <SaveButton
                disabled={isSubmitting || Object.keys(errors).length > 0}
                onClick={handleSubmit}
              />
            </DialogActions>
          </>
        )}
      </Formik>
    </ResponsiveDialog>
  );
}

export default AddEvidenceDialog;
