import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { PolicyStatus } from '../../../backend/src/policy-doc/policy-status.enum';
import { IPolicyDocDto } from '../../../backend/src/policy-doc/interfaces';
import { DownloadButton, EditButton, ViewPDFButton } from './buttons';
import { ApprovedIcon, ConsiderIcon, PendingIcon, RejectedIcon } from './icons';
import SpioDataTable, { SpioDataTableColumn } from './SpioDataTable';

interface IPolicyStatusInfo {
  icon: React.ReactElement<any>;
  text: string;
}

const POLICY_STATUS_MAP: Record<PolicyStatus, IPolicyStatusInfo> = {
  approved: {
    icon: <ApprovedIcon />,
    text: 'Approved',
  },
  draft: {
    icon: <ConsiderIcon />,
    text: 'Consider',
  },
  pending: {
    icon: <PendingIcon />,
    text: 'Pending',
  },
  rejected: {
    icon: <RejectedIcon />,
    text: 'Rejected',
  },
};

const getTableColumns = (tableData: ITableDatum[]): SpioDataTableColumn[] => [
  {
    name: 'statusStr',
    label: 'Status',
    options: {
      customFilterListOptions: {
        render: (v) => `Status: ${v}`,
      },
      customBodyRenderLite: (dataIndex) => {
        const statusStr = tableData[dataIndex]?.statusStr || '';
        const status = Object.values(POLICY_STATUS_MAP).find(
          (m) => m.text === statusStr
        );

        return status?.icon ?? statusStr;
      },
    },
  },
  {
    name: 'name',
    label: 'Policy',
    options: {
      filterType: 'textField',
    },
  },
  {
    name: 'updatedAt',
    label: 'Last Updated',
    options: {
      customBodyRender: (v) => v && moment(v).format('YYYY-MM-DD'),
      display: false,
      filter: false,
    },
  },
  {
    name: 'approvedAt',
    label: 'Approved',
    options: {
      customBodyRender: (v) => v && moment(v).format('YYYY-MM-DD'),
      filter: false,
    },
  },
  {
    name: 'inForceAt',
    label: 'In Force',
    options: {
      customBodyRender: (v) => v && moment(v).format('YYYY-MM-DD'),
      filter: false,
    },
  },
  {
    name: 'isCustomizedStr',
    label: 'Is Customized',
    options: {
      display: 'false',
    },
  },
  {
    // The underlying column value should be a string or a number; here we (arbitrarily) use the id.
    name: 'id',
    label: 'Actions',
    options: {
      filter: false,
      searchable: false,
      sort: false,
      setCellProps: () => ({ nowrap: 'true' }),
      customBodyRenderLite: (dataIndex) => tableData[dataIndex]?.actions || '',
    },
  },
];

export interface PolicyManagementTableProps {
  isLoading: boolean;
  onDownloadPolicy: (policy: IPolicyDocDto) => void;
  onOpenDetails: (policy: IPolicyDocDto) => void;
  onOpenPolicyDoc: (policy: IPolicyDocDto) => Promise<void>;
  disableEdit: boolean;
  policies: IPolicyDocDto[];
}

interface ITableDatum extends IPolicyDocDto {
  actions: string | React.ReactElement<any>[];
  isCustomizedStr: string;
  statusStr: string;
}

export default function PolicyManagementTable({
  isLoading,
  onDownloadPolicy,
  onOpenDetails,
  onOpenPolicyDoc,
  policies,
  disableEdit,
}: PolicyManagementTableProps) {
  const [tableData, setTableData] = useState<ITableDatum[]>([]);

  useEffect(() => {
    const handleClickDownloadPolicy =
      (idx: number) => (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.stopPropagation();
        onDownloadPolicy(policies[idx]);
      };

    const handleClickOpenDetails =
      (idx: number) => (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.stopPropagation();
        onOpenDetails(policies[idx]);
      };

    const handleClickOpenPDF =
      (idx: number) => (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.stopPropagation();
      };

    setTableData(
      policies.map((d, idx) =>
        Object({
          ...d,
          actions: d.id && [
            <ViewPDFButton
              key={`view_${idx}`}
              url={tableData[idx]?.versionId}
              onClick={handleClickOpenPDF(idx)}
            />,
            <DownloadButton
              key={`download_${idx}`}
              onClick={handleClickDownloadPolicy(idx)}
            />,
            <EditButton
              disableColor={disableEdit}
              key={`edit_${idx}`}
              onClick={handleClickOpenDetails(idx)}
            />,
          ],
          isCustomizedStr: !!d.customPolicy || d.isEdited ? 'True' : 'False',
          statusStr: POLICY_STATUS_MAP[d.status].text,
        })
      )
    );
  }, [onDownloadPolicy, onOpenDetails, policies, disableEdit]);

  return (
    <SpioDataTable
      title="Policies"
      data={tableData}
      columns={getTableColumns(tableData)}
      options={{
        download: false,
        expandableRows: false,
        filterType: 'multiselect',
        onRowClick: (_, { dataIndex: idx }) => onOpenPolicyDoc(policies[idx]),
        print: false,
        rowsPerPage: 25,
        selectableRows: 'none',
        textLabels: {
          body: {
            noMatch: isLoading ? 'Loading...' : 'No records found',
            toolTip: 'Sort',
          },
        },
      }}
    />
  );
}
