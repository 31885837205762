import { IconButton, TableCell, TableRow, Tooltip } from '@material-ui/core';
import { MUIDataTableTextLabels } from 'mui-datatables';
import React, { useEffect, useState } from 'react';
import { IUserDownloadDto } from '../../../backend/src/user/interfaces';
import SpioDataTable, { SpioDataTableColumn } from './SpioDataTable';
import Auth from '../services/AuthService';
import SyncAlt from '@material-ui/icons/SyncAlt';
import OrgIncomingAccessDetails from '../components/OrgIncomingAccessDetails';
import API from '../services/ApiService';
import { showErrorResultBar, showSuccessResultBar } from '../components/ResultSnackbar';
import * as Sentry from '@sentry/browser';
import DataTableTitleWithButton from '../components/DataTableTitleWithButton';
import OrgShareRequestInboundDialog from './dialogs/OrgShareRequestInboundDialog';
import { IOrgDto } from '../../../backend/src/org/interfaces';
import { IAccessibleRoleDto, IOrgRBACJoinDto, IRoleDto } from '../../../backend/src/org-share/interfaces';
import { AxiosResponse, AxiosError } from 'axios';
import { History } from 'history';
import { handleError } from '../helpers';

const getTableColumns = (tableData: ITableDatum[]): SpioDataTableColumn[] => {
  return [
    {
      name: 'orgName',
      label: 'Organization Name',
    },
    {
      name: 'orgTier',
      label: 'Subscription Type',
    },

    {
      name: 'accessGranted',
      label: 'Access Granted',
      options: {
          download: true,
          display: 'true',
          filter: true,
          customBodyRenderLite: dataIndex => capitalizeFirstLetter(tableData[dataIndex]?.accessGranted),
      },
    },
    {
        name: 'id',
        label: 'Actions',
        options: {
          filter: false,
          searchable: false,
          sort: false,
          setCellProps: () => ({ nowrap: 'true' }),
          customBodyRenderLite: dataIndex => tableData[dataIndex]?.actions,
        },
    },
  ];
};

function capitalizeFirstLetter(str: string) {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  } else {
    return null;
  }
}

interface ITableDatum extends IUserDownloadDto {
    orgName: string;
    actions: React.ReactElement[];
    accessGranted: string;
}

export interface OrgIncomingAccessTableProps {
  auth: Auth;
  addInboundOrg: (apiResponse: AxiosResponse<any>) => void;
  allRoles: IRoleDto[];
  isLoading: boolean;
  onRemoveUser: (orgRBACId: string, userIdx: number, successMsg: string) => void;
  orgs: IOrgRBACJoinDto[];
  updateInboundOrg: (apiResponse: AxiosResponse<any>, successMsg: string) => void;
  updateUser: (orgRBACId: string, userIdx: number | null, apiResponse: AxiosResponse<any>, successMsg: string) => void;
  history: History;
}

export default function OrgIncomingAccessTable(props: OrgIncomingAccessTableProps) {
  const { isLoading, orgs, auth, updateUser, onRemoveUser, addInboundOrg, updateInboundOrg, allRoles, history } = props;
  const [ tableData, setTableData ] = useState<ITableDatum[]>([]);
  const [ isDialogOpen, setIsDialogOpen ] = useState(false);
  const [ accessibleOrgs, setAccessibleOrgs ] = useState<IAccessibleRoleDto[]>([]);

  useEffect(() => {
    API.get('org-share/inboundAccess')
      .then(res => setAccessibleOrgs(res.data?.data ?? []))
      .catch(err => handleError(err, 'Error org access'));
  }, []);

  function translateTier(tier: number) {
    if (tier < 2) {
      return 'Free';
    } else if (tier < 5) {
      return 'Standard';
    } else {
      return 'Enterprise';
    }
  }

  useEffect(() => {
    setTableData(orgs.map((org) => Object({
      ...org,
      orgName: org.fromOrg.name,
      orgTier: translateTier(org.fromOrg.tier),
      permissionsGranted: org.roles.map(role => role.name),
      accessGranted: org.status === 'active' ? org.accessType : org.status,
      actions:
        <Tooltip title="switch to org">
          <div>
            <IconButton
                aria-label="download resource"
                onClick={handleSwitchOrg(org.fromOrg)}
                size="small"
                disabled={!((org.status === 'active' && (org.accessType === 'Manager' || org.accessType === 'Owner' )) && accessibleOrgs.map(ao => ao.id).includes(org.id))}
            >
                <SyncAlt fontSize="small" />
            </IconButton>
            </div>
        </Tooltip>,
    })));
  }, [ orgs, accessibleOrgs ]);

  const handleSwitchOrg = (org: IOrgDto) => async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    showSuccessResultBar(`Switching to ${org.name}`);
    try {
      await API.post(`auth/activateOrg/${org.id}`);
      await auth.renewSession();
      history.push('/dashboard');
      showSuccessResultBar(`Switched org to ${org.name}`);
    } catch (err) {
      showErrorResultBar('Error switching orgs');
      Sentry.captureException(err);
    }
  };

  return (
    <>
    <SpioDataTable
      title={<DataTableTitleWithButton
          onButtonClick={() => setIsDialogOpen(true)}
          title={`Organizations Shared with ${auth.getOrgName()}`}
          limitReached={false}
          buttonTitle={'Request access'}
          disabled={!(auth.isGranted({ permission: 'org_share:admin' }) || auth.isGranted({ permission: 'org_share:inbound' }))}
        />}
      columns={getTableColumns(tableData)}
      data={tableData}
      options={{
        elevation: 0,
        textLabels: {
          body: {
            noMatch: isLoading ? 'Loading...' : 'No organizations shared with you',
          },
        } as MUIDataTableTextLabels,
        filterType: 'multiselect',
        print: false,
        download: false,
        selectableRows: 'none',
        sort: true,
        expandableRows: true,
        expandableRowsOnClick: true,
        renderExpandableRow: (rowData, rowMeta) => {
          const colSpan = rowData.length + 1;
          const orgData = orgs[rowMeta.dataIndex];
          const orgAccessIdx = accessibleOrgs.map(org => org.id).indexOf(orgData.id);
          let orgAccess = null;
          if (orgAccessIdx > -1) {
            orgAccess = accessibleOrgs[orgAccessIdx].role;
          }

          return orgData ? (
            <TableRow>
              <TableCell colSpan={colSpan}>
                <OrgIncomingAccessDetails
                  orgData={orgData}
                  auth={auth}
                  allRoles={allRoles}
                  updateUser={updateUser}
                  onRemoveUser={onRemoveUser}
                  updateInboundOrg={updateInboundOrg}
                  orgAccess={orgAccess}
                  handleSwitchOrg={handleSwitchOrg}
                />
              </TableCell>
            </TableRow>
          ) : null;
        },
      }}
    />
    <OrgShareRequestInboundDialog
      onClose={() => setIsDialogOpen(false)}
      open={isDialogOpen}
      addInboundOrg={addInboundOrg}
      allRoles={allRoles}
    />
    </>
  );
}
