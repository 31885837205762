import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  Grid,
} from '@material-ui/core';
import { BaseTextFieldProps } from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/styles';
import { FormikProps, FormikValues } from 'formik';
import { closeSingleQuote } from 'prosemirror-inputrules';
import React from 'react';
import { helperAndErrorText } from './';

const useStyles = makeStyles({
  inputField: {
    marginRight: 0,
    marginLeft: 0,
  },
});

interface FormikTextFieldProps<Values extends FormikValues>
  extends BaseTextFieldProps {
  fields: any;
  unitValues: any;
  formikProps: FormikProps<Values>;
  helperTextStr?: string; // can be overridden with the usual 'helperText' prop
  onChange?: (e: React.ChangeEvent<any>) => void;
}

export default function FormikTextField<Values extends FormikValues>(
  props: FormikTextFieldProps<Values>
) {
  const classes = useStyles();
  const {
    children,
    fields,
    formikProps,
    unitValues,
    helperTextStr,
    variant = 'filled',
    ...otherTextFieldProps
  } = props;
  const { errors, values, handleChange, handleBlur } = formikProps;
  const fieldStr = fields[0].toString();
  const unitStr = fields[1].toString();

  return (
    <Grid container>
      <Grid item xs={8}>
        <TextField
          className={classes.inputField}
          id={fieldStr}
          name={fieldStr}
          value={values[fields[0]]}
          onBlur={handleBlur}
          onChange={handleChange}
          variant={variant as any}
          error={!!errors[fields[0]]}
          helperText={helperAndErrorText(
            helperTextStr,
            errors[fields[0]]?.toString()
          )}
          {...otherTextFieldProps}
        >
          {children}
        </TextField>
      </Grid>
      <Grid item xs={4}>
        <Select
          id={unitStr}
          name={unitStr}
          value={values[fields[1]]}
          onChange={handleChange}
        >
          {unitValues.map((unitValue: any) => (
            <MenuItem value={unitValue.accounting_unit_id}>
              {unitValue.accounting_unit_abbrev}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  );
}
