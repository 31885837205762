import React, { useEffect, useState } from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from '@material-ui/core';
import * as Sentry from '@sentry/browser';
import { Formik } from 'formik';
import * as Yup from 'yup';
import API from '../../services/ApiService';
import { SaveButton } from '../buttons';
import {
  FormGridRow,
  FormikAttachEvidenceField,
  FormikDatePicker,
  FormikSwitchField,
  FormikTextField,
  FormikTextFieldWithUnit,
  FormikSelectField,
  FormikFacilityField,
} from '../forms';
import { showErrorResultBar, showSuccessResultBar } from '../ResultSnackbar';
import StyledDialogTitle from '../StyledDialogTitle';
import ResponsiveDialog from './ResponsiveDialog';
import moment from 'moment';

const MetricSchema = Yup.object({
  facilityId: Yup.object()
    .shape({
      name: Yup.string(),
      id: Yup.string(),
    })
    .required(),
  documents: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string(),
        id: Yup.string(),
        orgId: Yup.string(),
        objectKey: Yup.string(),
        status: Yup.string(),
        size: Yup.string(),
      })
    )
    .nullable(),
  notes: Yup.string(),
  valueStartDatetime: Yup.date(),
  valueEndDatetime: Yup.date(),
});

export const HELPER_TEXT = {
  facilityId: 'The facility name or location',
  evidence: 'Attach any related documents for your metric',
  notes: 'Any additional information; e.g Unit Conversion Details',
  impact: 'Estimate of the significance of risk/opportunity',
  ghgEmissions: '',
  valueStartDatetime: 'Start date for reported data',
  valueEndDatetime: 'End date for reported data',
};

export default function MetricCreateDialog({
  columnFields,
  accounting_metric_id,
  onClose,
  open,
  handleSave,
  handleUpdate,
  metricData,
  auth,
}) {
  const [metricSchema, setMetricSchema] = useState({});
  const [facilities, setFacilities] = useState([]);
  const [evidences, setEvidences] = useState([]);

  useEffect(() => {
    async function fetchMetricFields() {
      const res = await API.get('org-process');
      try {
        if (res.data.data) {
          setFacilities(
            res.data.data.map((facility) => ({
              name: facility.name,
              id: facility.id,
            }))
          );
        }
      } catch (err) {
        showErrorResultBar('Unexpected error loading facilities');
      }
    }
    async function fetchEvidenceDocs() {
      const res = await API.get('document/evidence');
      try {
        setEvidences(res.data);
      } catch (err) {
        showErrorResultBar('Unexpected error loading facilities');
      }
    }

    if (accounting_metric_id) {
      fetchMetricFields();
      fetchEvidenceDocs();
    }

    if (columnFields) {
      let tempSchema = {};
      columnFields.forEach((optionalMetric) => {
        let type;
        switch (optionalMetric.data_type) {
          case 'bool':
            type = Yup.bool();
            break;
          default:
            type = Yup.string();
        }

        tempSchema[optionalMetric.name.replace(/\s/g, '-')] = type;
        const schemaB = Yup.object(tempSchema);
        const merged = MetricSchema.concat(schemaB);
        setMetricSchema(merged);
      });
    }
  }, [accounting_metric_id, columnFields]);

  const renderDynamicFields = (fieldData, formikProps, HELPER_TEXT) => {
    return (
      <FormGridRow divider title="Additional Metric Inputs">
        {fieldData.map((field, index) => {
          if (field.data_type === 'float') {
            return (
              <FormikTextFieldWithUnit
                key={index}
                fields={[
                  field.metric_field_id,
                  `${field.metric_field_id}-unit`,
                ]}
                formikProps={formikProps}
                helperTextStr={HELPER_TEXT[field.name.replace(/\s/g, '')]}
                unitValues={field.units}
                label={field.name}
              />
            );
          } else if (field.data_type === 'bool') {
            return (
              <FormikSwitchField
                key={index}
                field={field.metric_field_id}
                formikProps={formikProps}
                helperTextStr={field.label}
                label={field.name}
              />
            );
          } else if (field.data_type === 'enum') {
            return (
              <FormikSelectField
                key={index}
                options={field.enums.map(
                  (option) => option.metric_field_enum_name
                )}
                field={field.metric_field_id}
                formikProps={formikProps}
                helperTextStr={field.label}
                label={field.name} // Assuming 'label' is the correct prop for setting the label
              />
            );
          }
          return (
            <FormikTextField
              key={index}
              field={field.metric_field_id}
              formikProps={formikProps}
              helperTextStr={HELPER_TEXT[field.name.replace(/\s/g, '')]}
              label={field.name}
            />
          );
        })}
      </FormGridRow>
    );
  };

  const renderInitialValues = (metricData, columnFields) => {
    const documentCheck = metricData?.documents[0] ? metricData.documents : [];
    let initialValues = {
      facilityId:
        facilities.findIndex(
          (facility) => facility.id === metricData?.org_process_id
        ) > -1
          ? facilities[
              facilities.findIndex(
                (facility) => facility.id === metricData?.org_process_id
              )
            ]
          : null,
      notes: metricData?.notes || '',
      valueStartDatetime: metricData?.value_start_datetime || moment().toDate(),
      valueEndDatetime: metricData?.value_end_datetime || moment().toDate(),
      documents: documentCheck,
    };

    for (const field of columnFields) {
      const idx = metricData?.org_metric_inputs.findIndex(
        (input) => input.org_metric_field_id === field.metric_field_id
      );

      // add unit specifically for float types
      if (field.data_type === 'bool') {
        if (idx > -1) {
          // boolean comes back as string, so convert to boolean
          if (
            (metricData.org_metric_inputs[idx].value === 'true') |
            (metricData.org_metric_inputs[idx].value === 'false')
          ) {
            initialValues[field.metric_field_id] =
              metricData.org_metric_inputs[idx].value === 'true';
          } else {
            initialValues[field.metric_field_id] =
              metricData.org_metric_inputs[idx].value;
          }
        } else {
          initialValues[field.metric_field_id] = false;
        }
      } else {
        if (idx > -1) {
          // boolean comes back as string, so convert to boolean
          initialValues[field.metric_field_id] =
            metricData.org_metric_inputs[idx].value;
        } else {
          initialValues[field.metric_field_id] = '';
        }
      }

      // add unit specifically for float types
      if (field.data_type === 'float') {
        if (idx > -1) {
          initialValues[`${field.metric_field_id}-unit`] =
            metricData.org_metric_inputs[idx].value_unit_id;
        } else {
          initialValues[`${field.metric_field_id}-unit`] = null;
        }
      }
    }
    return initialValues;
  };

  const handleSaveFacility = async (formValues) => {
    try {
      if (formValues?.name) {
        formValues['orgId'] = auth.getOrgId();
        const res = await API.post('org-process', formValues);
        const dataCopy = facilities.slice();
        dataCopy.unshift({ name: res.data.name, id: res.data.id });
        setFacilities(dataCopy);
      }
      showSuccessResultBar('Facility added successfully');
    } catch (err) {
      const errorMsg =
        err.response?.data?.error ?? 'Unexpected error adding facility';
      showErrorResultBar(errorMsg);
      Sentry.captureException(err);
    }
  };


  if (facilities && metricSchema) {
    return (
      <ResponsiveDialog
        disableBackdropClick
        fullWidth
        maxWidth="md"
        open={open}
        onClose={onClose}
      >
        <StyledDialogTitle onClose={onClose}>Add New Entry</StyledDialogTitle>
        <Formik
          enableReinitialize
          initialValues={renderInitialValues(metricData, columnFields)}
          validationSchema={metricSchema}
          onReset={onClose}
          onSubmit={async (values, { setSubmitting }) => {
            if (metricData) {
              await handleUpdate(values, columnFields);
            } else {
              await handleSave(values, columnFields);
            }
            setSubmitting(false);
          }}
        >
          {(formikProps) => {
            return (
              <>
                <DialogContent>
                  <Grid container>
                    {/* 'Risk identification' section */}
                    <FormGridRow divider title="Default Metric Inputs">
                      <FormikDatePicker
                        field="valueStartDatetime"
                        formikProps={formikProps}
                        helperTextStr={HELPER_TEXT.valueStartDatetime}
                        label="Value Period Start"
                        disableFuture
                      />
                      <FormikDatePicker
                        field="valueEndDatetime"
                        formikProps={formikProps}
                        helperTextStr={HELPER_TEXT.valueEndDatetime}
                        label="Value Period End"
                        minDate={formikProps.values['valueStartDatetime']}
                        minDateMessage="Reviewed date cannot be before Reported date."
                        disableFuture
                      />
                      <FormikFacilityField
                        options={facilities}
                        field="facilityId"
                        formikProps={formikProps}
                        helperTextStr={HELPER_TEXT.facilityId}
                        label="Facility"
                        handleSaveFacility={handleSaveFacility}
                      />
                      <FormikTextField
                        field="notes"
                        formikProps={formikProps}
                        helperTextStr={HELPER_TEXT.notes}
                        label="Notes"
                        multiline
                      />
                    </FormGridRow>
                  </Grid>
                  <Grid container>
                    {columnFields.length > 0 &&
                      renderDynamicFields(
                        columnFields,
                        formikProps,
                        HELPER_TEXT
                      )}
                  </Grid>
                  <Grid container style={{ marginTop: '1rem' }}>
                    <FormGridRow divider title="Evidence Attachments">
                      <FormikAttachEvidenceField
                        options={evidences}
                        field="documents"
                        formikProps={formikProps}
                        helperTextStr={HELPER_TEXT.evidence}
                        label="Attach Evidence"
                      />
                      {formikProps.values.documents &&
                        (formikProps.values.documents[0]?.document_id ||
                          formikProps.values.documents[0]?.id) &&
                        formikProps.values.documents.map((document, index) => {
                          return (
                            <Typography
                              key={index}
                              variant="subtitle2"
                              color="textSecondary"
                            >
                              {document.document_name
                                ? document.document_name
                                : document.name}
                            </Typography>
                          );
                        })}
                    </FormGridRow>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button
                    disabled={formikProps.isSubmitting}
                    onClick={formikProps.handleReset}
                    color="primary"
                  >
                    {columnFields === null ? 'Cancel' : 'Close'}
                  </Button>
                  <SaveButton
                    disabled={
                      formikProps.isSubmitting ||
                      Object.values(formikProps.errors).filter((v) => !!v)
                        .length > 0
                    }
                    onClick={() => {
                      formikProps.handleSubmit();
                    }}
                  />
                </DialogActions>
              </>
            );
          }}
        </Formik>
      </ResponsiveDialog>
    );
  }
  return <div />;
}
