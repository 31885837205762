import {
  Backdrop,
  CircularProgress,
  createStyles,
  StyledComponentProps,
  Theme,
  withStyles,
} from '@material-ui/core';
import * as Sentry from '@sentry/browser';
import { useEffect, useState } from 'react';
import { ICreateOrgDto, IOrgDto, IUpdateOrgDto } from '../../../backend/src/org/interfaces';
import OrgGeneralSettings from '../components/org/OrgGeneralSettings';
import { RegReviewConfirm } from '../components/register';
import { showErrorResultBar } from '../components/ResultSnackbar';
import API, { ErrorCodes as ApiErrorCodes } from '../services/ApiService';
import Auth from '../services/AuthService';
import { IOrg, IOrgForm } from './TheOrganizationPage';
// import { ISubscriptionCreateDto } from '../../../backend/src/subscription/interfaces';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { StripeCardCapture } from '../components/StripeCardCapture';
import { AxiosResponse } from 'axios';
import Stripe from 'stripe';
import { useHistory } from 'react-router-dom';
import { trackLocation } from '../hooks/useTracking';

const styles = (theme: Theme) => createStyles({
  backdrop: {
    // color: theme.palette.primary.main,
    // color: 'white',
    zIndex: theme.zIndex.drawer + 1,
  },
  circularProgress: {
    zIndex: theme.zIndex.drawer + 2,
    position: 'absolute',
    left: '50%',
    top: '50%',
  },
  stepperContainer: {
    width: '90%',
    margin: 'auto',
  },
  stepper: {
    marginBottom: '0.5rem',
  },
});

enum Steps {
  // noinspection JSUnusedGlobalSymbols
  CONFIRMATION = 2,
  ORG_DETAILS = 0,
  PLAN_SELECTION = 1,
}

const handleCreateOrgError = (err: any) => {
  const code = (err.response && err.response.data && err.response.data.statusCode) || -1;

  switch (code) {
    case ApiErrorCodes.DUPLICATE_VALUE:
      const dupDetails = err.response.data.data;
      const errorMessage = `That organization ${dupDetails.field} is already taken.\nPlease choose another.`;
      showErrorResultBar(errorMessage);
      break;

    default:
      showErrorResultBar('An error occurred while saving your settings.');
  }
};

export interface TheRegisterPageProps extends StyledComponentProps {
  auth: Auth;
}

function TheRegisterPage({ auth, classes }: TheRegisterPageProps) {
  const [org, setOrg] = useState<IOrg>({
    autoEnroll: !auth.getIsEmailFree() && auth.isGranted({ tier: 3 }),
    kmsKeyArn: '',
    name: '',
    notifications: true,
    policyApprover: '',
    policyOwner: '',
    tier: 0,
  });
  const [paymentMethod, setPaymentMethod] = useState<Stripe.PaymentMethod>();
  const [activeStep, setActiveStep] = useState<number>(Steps.ORG_DETAILS);
  const [isPaymentDialogOpen, setIsPaymentDialogOpen] = useState(false);
  const [isDeletable, setIsDeletable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  // Initial page load:
  useEffect(() => {
    setIsLoading(true);
    (async function initRegisterPage() {
      const hasOrg = auth.getOrgId() !== undefined;
      const defaultOwner: string = auth.getUserName();

      if (hasOrg) {
        try {
          const res = await API.get(`org/${auth.getOrgId()}`);

          const theOrg: IOrgDto = (res.data && res.data.data) ? res.data.data : {};
          const { policyOwner = defaultOwner, policyApprover = defaultOwner } = theOrg;
          setOrg(prevOrg => ({ ...prevOrg, ...theOrg, policyOwner, policyApprover }));
          setActiveStep(Steps.PLAN_SELECTION);
        } catch (err) {
          Sentry.captureException(err);
          showErrorResultBar('An error occurred while loading your organization\'s details');
        }
      } else {
        setOrg(prevOrg => ({ ...prevOrg, policyOwner: defaultOwner, policyApprover: defaultOwner }));
      }

      setIsLoading(false);
    })();

  }, [auth]);

  useEffect(() => {
    if (activeStep > Steps.ORG_DETAILS) {
      setIsDeletable(true);
    }

    // GA tracking
    // Use '/register' to represent 'step_1' so that it matches the tracking url
    // of the page itself (which may also get sent, and sometimes after this).
    // For steps 2 and 3 include an artificial hash fragment: '/register#step_2' and '/register#step_3'.
    const { href, pathname } = window.location;
    const hashFragment = activeStep > 0 ? `#step_${activeStep + 1}` : '';

    trackLocation({
      page_location: `${href}${hashFragment}`,
      page_path: `${pathname}${hashFragment}`,
    });
  }, [activeStep]);

  const handleSaveOrg = async (formValues: IOrgForm) => {
    try {
      setIsLoading(true);
      let res: AxiosResponse<any>;

      if (org.ownerId === undefined) {
        // Create new org
        const newOrg: ICreateOrgDto = { ...formValues, ownerId: auth.getUserId() };
        res = await API.post('org', newOrg);
      } else {
        // Update existing org
        const updateOrg: IUpdateOrgDto = { ...formValues };
        res = await API.patch(`org/${auth.getOrgId()}`, updateOrg);
      }

      const theOrg = (res.data && res.data.data) ? res.data.data : {};
      setOrg(theOrg);

      await auth.renewSession();
      goToNextStep();
    } catch (err) {
      handleCreateOrgError(err);
    } finally {
      setIsLoading(false);
    }
  };

  // const handleUpdatePlanId = async (planId: number) => {
  //   setOrg(prevOrg => ({ ...prevOrg, tier: planId }));
  // };

  // async function handleConfirm() {
  //   try {
  //     if (!paymentMethod) {
  //       showErrorResultBar('Update payment details before proceeding.');
  //     } else {
  //       setIsLoading(true);
  //       const newSubDetails: ISubscriptionCreateDto = {
  //         paymentMethod,
  //         tier: org.tier,
  //       };

  //       await API.post('subscription', newSubDetails);
  //       await auth.renewSession();
  //       await new Promise(r => setTimeout(r, 2000));
  //       history.push('/welcome');
  //     }
  //   } catch (err) {
  //     showErrorResultBar('Unexpected error create subscription. Please try again.');
  //     Sentry.captureException(err);
  //     setIsLoading(false);
  //   }
  // }

  async function handleConfirmWithoutPayment() {
    try {
      setIsLoading(true);
      await API.post('subscription/newSub');
      await auth.renewSession();
      await new Promise(r => setTimeout(r, 2000));
      history.push('/welcome');
    } catch (err) {
      showErrorResultBar('Unexpected error create subscription. Please try again.');
      Sentry.captureException(err);
      setIsLoading(false);
    }
  }

  const goToNextStep = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const goToPreviousStep = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleDialogSave = (newPaymentMethod: Stripe.PaymentMethod) => {
    setIsPaymentDialogOpen(false);
    setPaymentMethod(newPaymentMethod);

    if (activeStep === Steps.PLAN_SELECTION) {
      goToNextStep();
    }
  };

  // function handleEnterPaymentMethodClick() {
  //   setIsPaymentDialogOpen(true);
  // }

  return (
    <>
      <div className={classes!.stepperContainer}>

        {activeStep === 0 &&
          <OrgGeneralSettings
            auth={auth}
            isDeletable={isDeletable}
            isLoading={isLoading}
            isRegistered={false}
            onClickSave={handleSaveOrg}
            org={org}
          />
        }
        {/* {activeStep === 1 &&
        <>
          <RegSubscriptionSettings
            onClickGoBack={goToPreviousStep}
            onEnterPaymentMethodClick={handleEnterPaymentMethodClick}
            onUpdatePlanId={handleUpdatePlanId}
            org={org}
          />
        </>
        } */}
        {activeStep === 1 &&
          <RegReviewConfirm
            isLoading={isLoading}
            onClickGoBack={goToPreviousStep}
            onClickConfirm={handleConfirmWithoutPayment}
            // onEditPaymentSource={handleEnterPaymentMethodClick}
            org={org}
          // paymentMethod={paymentMethod}
          />
        }
      </div>
      < StripeCardCapture open={isPaymentDialogOpen} onClose={() => setIsPaymentDialogOpen(false)}
        afterSave={handleDialogSave} />
      {isLoading &&
        <>
          <Backdrop className={classes!.backdrop} open={isLoading} timeout={500} />
          <CircularProgress className={classes!.circularProgress} color="primary" size={60} />
        </>
      }
    </>
  );
}

const StyledTheRegisterPage = withStyles(styles, { name: TheRegisterPage.name, withTheme: true })(
  (props: TheRegisterPageProps) => (
    <Elements stripe={loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '')}>
      <TheRegisterPage {...props} />
    </Elements>
  ));

// TheRegisterPage.requiredAuthZ = {
//   tier: 0,
//   permission: 'org:create',
// };
// StyledTheRegisterPage.routePath = '/register';
// StyledTheRegisterPage.title = 'Registration';

export default StyledTheRegisterPage;
