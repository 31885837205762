import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Switch,
} from '@material-ui/core';
import { FormControlProps } from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/styles';
import { FormikProps, FormikValues } from 'formik';
import React from 'react';

const useStyles = makeStyles({
  switchField: {
    marginLeft: 0,
    marginRight: 0,
  },
});

interface FormikSwitchFieldProps<Values extends FormikValues>
  extends FormControlProps {
  field: keyof Values;
  formikProps: FormikProps<Values>;
  helperTextStr?: string;
  label: string;
}

export default function FormikSwitchField<Values extends FormikValues>(
  props: FormikSwitchFieldProps<Values>
) {
  const classes = useStyles();
  const {
    field,
    formikProps,
    helperTextStr,
    label,
    variant = 'filled',
    ...otherFormControlProps
  } = props;
  const { values, handleChange } = formikProps;
  const fieldStr = field.toString();

  return (
    <FormControl
      className={classes.switchField}
      variant={variant as any}
      {...otherFormControlProps}
    >
      <FormControlLabel
        control={
          <Switch
            checked={values[field]}
            id={fieldStr}
            name={fieldStr}
            color="primary"
            onChange={handleChange}
          />
        }
        label={label}
      />
      {helperTextStr ? <FormHelperText>{helperTextStr}</FormHelperText> : null}
    </FormControl>
  );
}
