import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';

function CloseButton(props: IconButtonProps) {
  const { 'aria-label': ariaLabel, ...otherProps } = props;
  return (
    <IconButton
      aria-label={ariaLabel || 'close'}
      {...otherProps}
    >
      <CloseIcon />
    </IconButton>
  );
}

export default CloseButton;
