import { Button, Card, CardActions, CardContent, CardHeader, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { IOrg } from '../../pages/TheOrganizationPage';
// import { PaymentMethodCard } from '../PaymentMethodCard';
import { RegOrgReviewCard } from './RegOrgReviewCard';
// import Stripe from 'stripe';
// import SubscriptionPlanCard, { ISubscriptionPlanDetails, SUBSCRIPTION_PLANS } from '../SubscriptionPlanCard';

const useStyles = makeStyles({
  switchField: {
    marginLeft: 0,
  },
  headerCard: {
    width: '100%',
    margin: 0,
  },
  leftIcon: {
    marginRight: '0.5rem',
  },
  addOrgButton: {
    marginTop: 0,
    marginBottom: 0,
  },
});

export interface RegReviewConfirmProps {
  isLoading: boolean;
  onClickGoBack: () => void;
  onClickConfirm: () => void;
  org: IOrg;
  //paymentMethod?: Stripe.PaymentMethod;
  //onEditPaymentSource: () => void;
}

export default function RegReviewConfirm({ isLoading, onClickGoBack, onClickConfirm, org }: RegReviewConfirmProps) {
  const classes = useStyles();

  function handleClickGoBack() {
    onClickGoBack();
  }

  function handleClickConfirm() {
    onClickConfirm();
  }

  return (
    <Card className={classes.headerCard}>
      <CardHeader
        title="Ready to start building your ESG program?"
        subheader="You may go back to make changes or update these values after you log in."
      />
      <CardContent>
        <Grid
          container
          alignItems="stretch"
        >
          <Grid
            item
            xs={12}
            md={12}
          >
            <RegOrgReviewCard
              org={org}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
          >
            {/* <SubscriptionPlanCard
              isEditing={false}
              isSelected={false}
              plan={SUBSCRIPTION_PLANS.find(p => p.id === org.tier) || {} as ISubscriptionPlanDetails}
            /> */}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
          >
            {/* <PaymentMethodCard
              isLoading={false}
              paymentMethod={paymentMethod}
              onEditClick={onEditPaymentSource}
            /> */}
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Button
          color="primary"
          onClick={handleClickGoBack}
          disabled={isLoading}
        >
          Go back
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleClickConfirm}
          disabled={isLoading}
        >
          Confirm
        </Button>
      </CardActions>
    </Card>
  );
}
