import { createStyles, SvgIcon, Theme, Typography, WithStyles, withStyles } from '@material-ui/core';
import React from 'react';
import classNames from 'classnames';

const styles = (theme: Theme) => createStyles({
  caption: {
    color: theme.typography.body1.color,
    fontSize: '8pt',
    lineHeight: '0.8',
    paddingTop: '1px',
  },

  circle: {
    height: '24px',
    width: '24px',
    borderRadius: '50%',
    display: 'inline-block',
    border: 'solid 2px',
    borderColor: theme.palette.success.main,
    justifyContent: 'center',
    fontWeight: 'bold',
    fontSize: '12pt',
  },

  root: {
    justifyContent: 'center',
    textAlign: 'center',
    color: theme.palette.text.primary,
    stroke: theme.palette.text.primary,
  },

});

export interface CircleCheckIconProps extends WithStyles<typeof styles> {
  className?: string;
  text?: string;
}

function CircleCheckIcon({ classes, text, className: classNameProp }: CircleCheckIconProps) {
  return (
    <div className={classNames(classes.root, classNameProp)}>
      <SvgIcon>
        <circle cx="12" cy="12" r="8" strokeWidth="2" fillOpacity="0" />

        <path d="
        M 15 9
        l -4.29 4.3-1.65-1.65
        a 1 1 0 1 0-1.41 1.41l2.35 2.36
        a 1 1 0 0 0 1.41 0
        l 5-5
        a 1 1 0 1 0-1.41-1.42z
    " />

        <circle cx="12" cy="12" r="11" strokeWidth="2" fillOpacity="0" />
      </SvgIcon>

      {text &&
      <Typography display="block" variant="caption" className={classes.caption}>{text}</Typography>
      }
    </div>
  );
}

export default withStyles(styles, { withTheme: true })(CircleCheckIcon);
