import {
  createStyles,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  Theme,
  Tooltip,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import CommentIcon from '@material-ui/icons/ModeCommentOutlined';
import DoneIcon from '@material-ui/icons/Done';
import ErrorIcon from '@material-ui/icons/Error';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import * as Sentry from '@sentry/browser';
import { History } from 'history';
import { cloneDeep, findKey } from 'lodash';
import moment, { Moment } from 'moment';
import { MUIDataTableColumn } from 'mui-datatables';
import React, { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import {
  ITaskAssigneeDto,
  ITaskDto,
  ITaskGroupDto,
  ITasksResponse,
} from '../../../backend/src/task/interfaces';
import { TaskStatus } from '../../../backend/src/task/task-status.enum';
import Auth from '../services/AuthService';
import { TASK_STATUS_MAP, FILTER_VIEW_IDENTIFIERS, IFilterViewSelection } from './FilterViewSelections';
import SpioDataTable, { SpioDataTableColumn } from './SpioDataTable';
import TaskDetails from './TaskDetails';
import { TaskTableSelectedRowsToolbar } from './TaskTableSelectedRowsToolbar';
import { IIdNameDto } from '../../../backend/src/common/id-name-dto.interface';
import { CaptionedAddButton } from './buttons';
import AddTaskDialog from './dialogs/AddTaskDialog';
import API from '../services/ApiService';
import { showErrorResultBar } from '../components/ResultSnackbar';
import { IIdNameVersionDto } from '../../../backend/src/common/id-name-version-dto.interface';
import { AxiosError } from 'axios';

const styles = (theme: Theme) =>
  createStyles({
    [theme.breakpoints.up('md')]: {
      commentCell: {
        textAlign: 'center',
      },
      commentCellText: {
        paddingLeft: '2px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      hideDn: {
        display: 'none',
      },
    },
    commentCellIcon: {
      fontSize: '0.875rem',
    },
    invalidAssigneeIcon: {
      marginLeft: '4px',
      verticalAlign: 'bottom',
    },
    percentCompleteCell: {
      display: 'inline-block',
      minWidth: '50px',
      textAlign: 'right',
    },
    titleContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
    titleText: {
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    viewFormControl: {
      minWidth: 400,
    },
  });

// const theme = createTheme({ overrides:  { MUIDataTableBodyCell:  { root:  { backgroundColor: 'blue', color: 'red', } } } });

export const UNASSIGNED_TASK_STR = 'Unassigned';

export const TASK_STATUS_ICONS: Record<TaskStatus, React.ReactNode> = {
  not_started: <NewReleasesIcon />,
  completed: <DoneIcon />,
  past_due: <PriorityHighIcon />,
  in_progress: <TimelapseIcon />,
  not_relevant: <RemoveCircle />,
};

export type FilterColumn =
  | 'assigneeId'
  | 'assigneeName'
  | 'collectionName'
  | 'dueDate'
  | 'groupName'
  | 'id'
  | 'name'
  | 'policies'
  | 'startDate'
  | 'statusStr'
  | 'subtaskAssignees'
  | 'tagNames';

export type IFilters = Partial<Record<FilterColumn, string[]>>;

const FilterSchema = Yup.object()
  .noUnknown()
  .shape({
    assigneeId: Yup.array().of(Yup.string()),
    assigneeName: Yup.array().of(Yup.string()),
    collectionName: Yup.array().of(Yup.string()),
    dueDate: Yup.array().of(Yup.string()),
    groupName: Yup.array().of(Yup.string()),
    id: Yup.array().of(Yup.string()).max(1),     // TODO: Add .uuid() check to id after upgrading Yup
    name: Yup.array().of(Yup.string()).max(1),
    policies: Yup.array().of(Yup.string()),
    statusStr: Yup.array().of(Yup.mixed().oneOf(Object.values(TASK_STATUS_MAP))),
    subtaskAssignees: Yup.array().of(Yup.string()),
    tagNames: Yup.array().of(Yup.string()),
  })
  .required();

export interface TaskTableProps extends WithStyles<typeof styles> {
  assignees: ITaskAssigneeDto[];
  auth: Auth;
  handleDueDateChanged: (idx: number) => (newDate: Moment | null) => Promise<void>;
  handleStartDateChanged: (idx: number) => (newDate: Moment | null) => Promise<void>;
  handleCompletedDateChanged: (idx: number) => (newDate: Moment | null) => Promise<void>;
  history: History;
  isLoading: boolean;
  markComplete: (idx: number) => () => Promise<void>;
  markStarted: (idx: number) => () => Promise<void>;
  markNotRelevant: (idx: number) => () => Promise<void>;
  markRelevant: (idx: number) => () => Promise<void>;
  onUpdateTasks: (idxs: number[], updatedTasksInfo: ITasksResponse) => Promise<void>;
  handleUpdateTask: (idx: number) => (updatedTasksInfo: ITaskDto) => Promise<void>;
  handleDeleteTask: (idx: number) => () => Promise<void>;
  handleAddTask: (newTask: ITaskDto) => Promise<void>;
  tasks: ITaskDto[];
  attachableDocuments: IIdNameVersionDto[];
  filterViewSelectionOptions: IFilterViewSelection[];
  expandedRows: number[];
  setExpandedRows: (idxs: number[]) => void;
  collapseRows: () => void;
}

interface ITableDatum extends ITaskDto {
  nbComments: number;
  policies: string[];
  statusStr: string;
  tagNames: string[];
  groupName: string[];
  collectionName: string[];
  subtaskAssignees: string[];
}

function TaskTable(props: TaskTableProps) {
  const {
    assignees,
    attachableDocuments,
    auth,
    classes,
    filterViewSelectionOptions,
    handleAddTask,
    handleCompletedDateChanged,
    handleDeleteTask,
    handleDueDateChanged,
    handleStartDateChanged,
    handleUpdateTask,
    history,
    isLoading,
    markComplete,
    markNotRelevant,
    markRelevant,
    markStarted,
    onUpdateTasks,
    tasks,
    expandedRows,
    setExpandedRows,
    collapseRows
  } = props;
  const [taskTags, setTaskTags] = useState<string[]>([]);
  const [allTaskTags, setAllTaskTags] = useState<IIdNameDto[]>([]);
  const [groups, setGroups] = useState<ITaskGroupDto[]>([]);
  const [collections, setCollections] = useState<IIdNameDto[]>([]);
  const [filterViewSelections, setFilterViewSelections] = useState(filterViewSelectionOptions);
  // On init start with the 'Custom' view; the others have the side effect of overwriting the filters:
  const [filterViewSelection, setFilterViewSelection] = useState(filterViewSelectionOptions[FILTER_VIEW_IDENTIFIERS.CUSTOM_TASKS].value);
  const [filters, setFilters] = useState<any>({});
  const [rowsSelected, setRowsSelected] = useState<number[]>([]);
  const [tableData, setTableData] = useState<ITableDatum[]>([]);
  const [newSearchText, setNewSearchText] = useState<string | undefined>(auth.getTasksSearch());
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  function updateFilterViewSelection(newSelection: string) {
    setFilterViewSelection(newSelection);
    auth.setTasksView(newSelection);
  }

  const getDefaultTasksView = (viewString: string) => {
    return filterViewSelectionOptions.map(f => f.value).includes(viewString) ?
    viewString : filterViewSelectionOptions[FILTER_VIEW_IDENTIFIERS.ALL_TASKS].value;
  };

  const getDefaultTasksFilters = (filters: object) => {
    try {
      // return filters;
      return FilterSchema.validateSync(filters);
    } catch (err) {
      const axiosError = err as AxiosError;
      console.error(`Invalid filter value: ${axiosError.message}`);
      Sentry.captureException(err);

      return {};
    }
  };

  const updateFilters = useCallback(
    (newFilters: IFilters) => {
      setFilters(newFilters);
      auth.setTasksFilters(newFilters);
    },
    [auth]
  );

  const handleUpdateTaskProperties =
    (idx: number) => (updatedTaskInfo: ITasksResponse) => {
      onUpdateTasks([idx], updatedTaskInfo);
  };

  const handleUpdateTasks = (
    idxs: number[],
    updatedTasksInfo: ITasksResponse
  ) => {
    onUpdateTasks(idxs, updatedTasksInfo);
    setRowsSelected([]);
  };

  useEffect(() => {
    setTableData(
      tasks.map((d) =>
        Object({
          ...d,
          assigneeName: d.assigneeName || UNASSIGNED_TASK_STR,
          nbComments: d.comments?.length ?? 0,
          statusStr: TASK_STATUS_MAP[d.status],
          groupName: d.group?.name || '',
          collectionName: d.collection?.name || '',
          tagNames: d.taskTags
            ? d.taskTags.map((taskTag) => taskTag.name).sort()
            : [],
          subtaskAssignees: [...new Set(d.subTasks?.map(s => s.assigneeName))].filter(s => s)
        })
      )
    );
  }, [tasks]);

  // Consolidate the tags for display in the filter:
  useEffect(() => {
    const taskTagSet = new Set<string>([]);

    tasks.forEach((task) => {
      if (task.taskTags) {
        task.taskTags.forEach((taskTag) => {
          taskTagSet.add(taskTag.name);
        });
      }
    });

    const orderedTags = [...taskTagSet].sort();
    setTaskTags(orderedTags);
  }, [tasks]);

  useEffect(() => {
    API.get('task/tags')
      .then(res => {
        setAllTaskTags(res.data.data.filter((tag: IIdNameDto ) => !tag.name.includes('round')));
      })
      .catch(_ => {
        showErrorResultBar('Unexpected error fetching tags');
      });
  }, []);

  useEffect(() => {
    API.get('task/groups')
      .then(res => {
        setGroups(res.data.data);
      })
      .catch(_ => {
        showErrorResultBar('Unexpected error fetching tags');
      });

    API.get('task/collections')
      .then(res => {
        setCollections(res.data.data);
      })
      .catch(_ => {
        showErrorResultBar('Unexpected error fetching tags');
      });
  }, []);

  // Set the view and filters; use cached values, else defaults:
  useEffect(() => {
    // Dynamically add the user's id to filter:
    const filterViewSels = filterViewSelectionOptions;
    filterViewSels.forEach((elem, idx) => elem.filter && elem.filter.assigneeId ? filterViewSels[idx].filter?.assigneeId?.push(auth.getUserId()) : null);
    setFilterViewSelections(filterViewSels);

    //TOOD: fix this type, it should be IFilters
    const defaultFilters = getDefaultTasksFilters(auth.getTasksFilters());
    defaultFilters && setFilters(defaultFilters);

    setFilterViewSelection(getDefaultTasksView(auth.getTasksView()));
  }, [auth, filterViewSelectionOptions]);

  // When the predefined view changes, update the filters accordingly:
  useEffect(() => {
    // 'Custom' means the user is changing the filters by hand so don't change them here.
    if (filterViewSelection === filterViewSelections[0].value) {
      return;
    }

    const newFilterView = filterViewSelections.find(
      (sel) => sel.value === filterViewSelection
    );

    if (newFilterView && newFilterView.filter) {
      updateFilters(newFilterView.filter);
    }
  }, [filterViewSelections, filterViewSelection, updateFilters]);

  const tableHeaders: SpioDataTableColumn[] = [
    {
      name: 'assigneeId',
      label: '',
      options: {
        customFilterListOptions: {
          render: (v: string) => (v === auth.getUserId() ? 'My tasks' : v),
        },
        download: false,
        display: 'excluded',
        filter: false,
        filterList: filters.assigneeId || [],
      },
    },
    {
      name: 'id',
      label: 'Task ID',
      options: {
        customFilterListOptions: { render: (v: string) => `ID: ${v}` },
        display: 'false',
        filter: false,
        filterList: filters.id || [],
      },
    },
    {
      name: 'taskOrder',
      label: 'Order',
      options: {
        display: 'true',
        filter: false,
        customBodyRenderLite: (dataIndex) => {
          const status = tableData[dataIndex]?.status || 'not_started';
          return status === 'not_relevant' ? '-' : tableData[dataIndex]?.taskOrder || '0';
        },
      },
    },
    {
      name: 'statusStr',
      label: 'Status',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const statusStr = tableData[dataIndex]?.statusStr;
          const statusInfo = (findKey(
            TASK_STATUS_MAP,
            (v) => v === statusStr
          ) || 'not_started') as TaskStatus;

          return TASK_STATUS_ICONS[statusInfo];
        },
        filterList: filters.statusStr || [],
        display: 'false',
      },
    },
    {
      name: 'collectionName',
      label: 'Collection',
      options: {
        filter: true,
        filterList: filters.collectionName || [],
        display: 'false',
      },
    },
    {
      name: 'groupName',
      label: 'Group',
      options: {
        filter: true,
        filterList: filters.groupName || [],
        display: 'true',
      },
    },
    {
      name: 'name',
      label: 'Title',
      options: {
        filter: true,
        filterType: 'textField',
        filterList: filters.name || [],
        display: 'true',
      },
    },
    {
      name: 'assigneeName',
      label: 'Assigned To',
      options: {
        customFilterListOptions: { render: (v: string) => `Assigned To: ${v}` },
        customBodyRenderLite: (dataIndex) => {
          const { assigneeId, assigneeName } = tableData[dataIndex] ?? {};

          if (!assigneeName || assigneeName === UNASSIGNED_TASK_STR) {
            return null;
          }

          // The first condition prevents the flash from 'invalid' to 'valid' while the assignees list is still loading:
          const isValidAssignee =
            assignees.length === 0 ||
            assignees.some((a) => a.id === assigneeId);

          return (
          tableData[dataIndex]?.status !== 'not_relevant' ?
            <>
              <span>{assigneeName}</span>
              {!isValidAssignee && (
                <Tooltip title="This user no longer has access to tasks">
                  <ErrorIcon
                    className={classes.invalidAssigneeIcon}
                    fontSize="small"
                  />
                </Tooltip>
              )}
            </>
          :
            null
          );
        },
        filterList: filters.assigneeName || [],
        filterOptions: {
          logic: (value, filterVals) => {
            const searchableName = value === null ? UNASSIGNED_TASK_STR : value;

            return !filterVals.includes(searchableName);
          },
        },
        display: 'true',
      },
    },
    {
      name: 'subtaskAssignees',
      label: 'Subtask Assignees',
      options: {
        download: true,
        display: 'false',
        filter: true,
        filterList: filters.subtaskAssignees || [],
        // filterOptions: {
        //   names: subtaskAssignees,
        //   logic(subtaskAssigneesProp: any, filterVals) {
        //     const subtaskAssignees = subtaskAssigneesProp as string[];

        //     try {
        //       return filterVals
        //         .map((f) => new RegExp(`^${f}$`))
        //         .every((f) => subtaskAssignees.every((t) => !f.test(t)));
        //     } catch (err) {
        //       return false;
        //     }
        //   },
        // },
        searchable: true,
        // setCellProps: () => Object({ nowrap: 'true' }),
        sort: false,
      },
    },
    {
      name: 'startDate',
      label: 'Start',
      options: {
        customFilterListOptions: { render: (v: string) => `Start: ${v}` },
        filterList: filters.startDate || [],
        display: 'true',
        customBodyRenderLite: (dataIndex) => (
          tableData[dataIndex]?.status !== 'not_relevant' ?
          tableData[dataIndex]?.startDate
          :
          null
        ),
      },
    },
    {
      name: 'dueDate',
      label: 'Due',
      options: {
        customFilterListOptions: { render: (v: string) => `Due: ${v}` },
        filterList: filters.dueDate || [],
        display: 'true',
        customBodyRenderLite: (dataIndex) => (
          tableData[dataIndex]?.status !== 'not_relevant' ?
          tableData[dataIndex]?.dueDate
          :
          null
        ),
      },
    },
    {
      name: 'percentComplete',
      label: 'Percent Complete',
      options: {
        customBodyRenderLite: (dataIndex) => (
          tableData[dataIndex]?.status !== 'not_relevant' ?
          <span className={classes.percentCompleteCell}>
            {tableData[dataIndex]?.percentComplete}%
          </span>
          :
          null
        ),
        display: 'true',
        filter: false,
        searchable: false,
      },
    },
    {
      name: 'nbComments',
      label: 'Comments',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const nbComments = tableData[dataIndex]?.nbComments ?? 0;

          return (
            !!nbComments && (
              <div className={classes.commentCell}>
                <CommentIcon
                  className={`${classes.commentCellIcon} ${classes.hideDn}`}
                />
                <span className={classes.commentCellText}>{nbComments}</span>
              </div>
            )
          );
        },
        setCellProps: () => Object({ nowrap: 'true' }),
        download: false,
        display: false,
        filter: false,
        searchable: false,
      },
    },
    {
      name: 'tagNames',
      label: 'Tags',
      options: {
        download: true,
        display: 'false',
        customBodyRenderLite: (dataIndex) => {
          const tagNames = tableData[dataIndex]?.tagNames || [];

          return tagNames.map((tagName) => <div key={tagName}>{tagName}</div>);
        },
        filter: true,
        filterList: filters.tagNames || [],
        filterOptions: {
          names: taskTags,
          logic(tagNamesProp: any, filterVals) {
            const tagNames = tagNamesProp as string[];

            try {
              return filterVals
                .map((f) => new RegExp(`^${f}$`))
                .every((f) => tagNames.every((t) => !f.test(t)));
            } catch (err) {
              return false;
            }
          },
        },
        searchable: true,
        setCellProps: () => Object({ nowrap: 'true' }),
        sort: false,
      },
    },
    {
      name: 'description',
      label: 'Description',
      options: {
        download: false,
        display: 'excluded',
        filter: false,
      },
    },
  ];

  function onViewChange(event: React.ChangeEvent<{ value: unknown }>) {
    const newSelection = event.target.value as string;
    updateFilterViewSelection(newSelection);
  }

  function onManualFilterChange(
    changedColumn: string | MUIDataTableColumn | null,
    filterList: string[][]
  ) {
    // This is fired when a user deletes a filter chip or modifies the filters directly.
    // (It's not fired when the predefined view changes.)
    //
    if (changedColumn === null) {
      // User has clicked the 'Reset' button in the filter dialog.
      // (MuiDataTable sets the 'changedColumn' to 'null' in this case.)
      updateFilterViewSelection(filterViewSelections[1].value); // all tasks

      return;
    }

    // Set the filters by hand.
    // (Using the native filtering-change mechanism is faster, but can result in conflicts.)
    // Changing the 'filters' object causes the 'filterList's to update in 'tableHeaders'
    // which in turn fires the table's built in filtering.
    const changedCol = (
      typeof changedColumn === 'string' ? changedColumn : changedColumn.name
    ) as FilterColumn;
    const newFilters = cloneDeep(filters);
    const idx = tableHeaders.findIndex((col) => col.name === changedCol);
    newFilters[changedCol] = filterList[idx];
    updateFilters(newFilters);
    updateFilterViewSelection(filterViewSelections[0].value);
  }

  const Title = withStyles(styles, { withTheme: true })(() => {
    return (
      <>
        <div className={classes.titleContainer}>
          <Typography variant="h6" className={classes.titleText}>
            Tasks
          </Typography>
          <CaptionedAddButton
            disabled={!auth.isGranted({permission: 'tasks:edit'})}
            onClick={() => {setIsDialogOpen(true);}}
          >
            Add task
          </CaptionedAddButton>
          <FormControl className={classes.viewFormControl}>
            <InputLabel htmlFor="filter-view-select">
              Predefined Filter View
            </InputLabel>
            <Select
              value={filterViewSelection}
              onChange={onViewChange}
              inputProps={{
                name: 'filter-view-select',
                id: 'filter-view-select',
              }}
            >
              {filterViewSelections.map((filterView) => (
                <MenuItem key={filterView.value} value={filterView.value}>
                  {filterView.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </>
    );
  });

  return (
    <>
    <SpioDataTable
      title={<Title />}
      columns={tableHeaders}
      data={tableData}
      options={{
        textLabels: {
          body: {
            noMatch: isLoading ? 'Loading...' : 'No tasks found',
            toolTip: 'Sort',
          },
        },
        onFilterChange: onManualFilterChange,
        filterType: 'multiselect',
        rowsSelected,
        selectableRows: 'multiple',
        selectableRowsHeader: !isLoading,
        onRowSelectionChange: (_, allRowsSelected) => {
          setRowsSelected(allRowsSelected.map((row) => row.dataIndex));
        },
        // By default the search is skipped for columns with display 'false' or 'excluded'.
        // We want to include those columns and only skip those with searchable 'false'.
        customSearch: (searchQuery, currentRow, columns) => {
          return columns.some(
            (col, i) =>
              col.searchable &&
              currentRow[i]
                ?.toString()
                .toLowerCase()
                .indexOf(searchQuery.toLowerCase()) >= 0
          );
        },
        onSearchChange: (text) => {
          setNewSearchText(text ?? undefined);
          auth.setTasksSearch(text);
        },
        searchText: newSearchText,
        customToolbarSelect: (selectedRows) => (
          <TaskTableSelectedRowsToolbar
            assignees={assignees}
            onCancel={() => setRowsSelected([])}
            onUpdate={handleUpdateTasks}
            selectedRows={selectedRows}
            tasks={tasks}
          />
        ),
        print: false,
        download: true,
        downloadOptions: {
          filename: `Tasks_${moment().format('YYYYMMDD')}.csv`,
          filterOptions: {
            useDisplayedRowsOnly: true,
          },
        },
        setRowProps: (row, dataIndex, rowIndex) => {
          if (tasks[dataIndex]?.status === 'not_relevant') {
            return {
              style: {
                fontWeight: 900,
              },
            };
          } else {
              return {};
          }
        },
        expandableRows: true,
        expandableRowsOnClick: true,
        rowsExpanded: expandedRows,
        onRowExpansionChange: (_, allRowsExpanded: any[], __) => {
          setExpandedRows(allRowsExpanded.map(r => r.dataIndex));
        },
        renderExpandableRow: (rowData, rowMeta) => {
          if (rowMeta) {
            const colSpan = rowData.length + 1;
            const myData = tasks[rowMeta.dataIndex];
            const rowDataIndex = rowMeta.dataIndex;

            return myData ? (
              <TableRow>
                <TableCell colSpan={colSpan}>
                  <TaskDetails
                    assignees={assignees}
                    auth={auth}
                    history={history}
                    markComplete={markComplete(rowDataIndex)}
                    markStarted={markStarted(rowDataIndex)}
                    markNotRelevant={markNotRelevant(rowDataIndex)}
                    markRelevant={markRelevant(rowDataIndex)}
                    onDueDateChange={handleDueDateChanged(rowDataIndex)}
                    onStartDateChange={handleStartDateChanged(rowDataIndex)}
                    onCompletedDateChange={handleCompletedDateChanged(
                      rowDataIndex
                    )}
                    onUpdateTaskProperties={handleUpdateTaskProperties(rowDataIndex)}
                    onUpdateTask={handleUpdateTask(rowDataIndex)}
                    onDeleteTask={handleDeleteTask(rowDataIndex)}
                    taskData={myData}
                    tagOptions={allTaskTags}
                    collections={collections}
                    groups={groups}
                    tasks={tasks}
                    attachableDocuments={attachableDocuments}
                    editTask={true}
                    collapseRows={collapseRows}
                  />
                </TableCell>
              </TableRow>
            ) : null;
          }
        },
      }}
    />
    <AddTaskDialog
      open={isDialogOpen}
      onClose={()=>{setIsDialogOpen(false);}}
      tagOptions={allTaskTags}
      collections={collections}
      groups={groups}
      tasks={tasks}
      taskData={null}
      onSave={handleAddTask}
      collapseRows={collapseRows}
    />
    </>
  );
}

export default withStyles(styles, { withTheme: true })(TaskTable);
