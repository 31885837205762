import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  Button,
  createStyles,
  Divider,
  Grid,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import * as Sentry from '@sentry/browser';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { INetworkScanDto, INetworkScanTargetDto, INetworkScanTargetUpdateDto } from '../../../backend/src/network-scan/interfaces';
import { NetworkScanStatus } from '../../../backend/src/network-scan/network-scan-status.enum';
import CaptionedAddButton from '../components/buttons/CaptionedAddButton';
import EditButton from '../components/buttons/EditButton';
import NetworkScanTargetDialog from '../components/dialogs/NetworkScanTargetDialog';
import AccordionIconSummary from '../components/AccordionIconSummary';
import NetworkScanStatusIcon from '../components/NetworkScanStatusIcon';
import { showErrorResultBar, showSuccessResultBar } from '../components/ResultSnackbar';
import SpioDataTable, { SpioDataTableColumn } from '../components/SpioDataTable';
import { formatDate, truncateString } from '../helpers';
import API from '../services/ApiService';
import * as DocService from '../services/DocService';

const useStyles = makeStyles((theme: Theme) => createStyles({
  headerText: {
    color: theme.typography.body1.color,
  },
}));

const useEditButtonStyles = makeStyles({
  root: {
    marginLeft: '1em',
  },
});

const useTargetAccordionStyles = makeStyles({
  heading: {
    fontWeight: 'bold',
    marginRight: '0.5rem',
  },
});

const getScanTableColumns = (tableData: ITableDatum[]): SpioDataTableColumn[] => [
  {
    name: 'status',
    label: 'Status',
    options: {
      customBodyRenderLite: (dataIndex) => {
        const { status } = tableData[dataIndex] ?? {};

        return status && <NetworkScanStatusIcon status={status as NetworkScanStatus} />;
      },
      customFilterListOptions: { render: (v: string) => `Status: ${v}` },
    },
  },
  {
    name: 'createdAtStr',
    label: 'Requested',
    options: {
      customFilterListOptions: { render: (v: string) => `Requested: ${v}` },
    },
  },
  {
    name: 'startedAtStr',
    label: 'Started',
    options: {
      customFilterListOptions: { render: (v: string) => `Started: ${v}` },
    },
  },
  {
    name: 'endedAtStr',
    label: 'Completed',
    options: {
      customFilterListOptions: { render: (v: string) => `Completed: ${v}` },
    },
  },
  {
    name: 'documentsStr',
    label: 'Documents',
    options: {
      customBodyRenderLite: dataIndex => tableData[dataIndex]?.documents?.map(doc => (
        <Tooltip
          key={doc.id}
          title={doc.name || ''}
          placement="bottom"
        >
          <Button
            size="small"
            onClick={DocService.documentDownloadHandler(doc.id)}
          >
            {truncateString(doc.name || doc.id, 24)}
          </Button>
        </Tooltip>
      )),
      filter: false,
      sort: false,
    },
  },
];

const getDateStr = (date?: Date) => date ? moment(date).local().fromNow() : '';

export interface TargetAccordionProps {
  onEditClick: React.ReactEventHandler<{}>;
  onRequestScan: React.ReactEventHandler<{}>;
  target: INetworkScanTargetDto;
}

interface ITableDatum extends INetworkScanDto {
  createdAtStr: string;
  documentsStr: string;
  endedAtStr: string;
  startedAtStr: string;
}

function TargetAccordion({ onEditClick, onRequestScan, target }: TargetAccordionProps) {
  const classes = useTargetAccordionStyles();
  const editButtonClasses = useEditButtonStyles();
  const [ tableData, setTableData ] = useState<ITableDatum[]>([]);

  useEffect(() => {
    const scans = target.scans || [];
    setTableData(scans.map((scan) => Object({
      ...scan,
      createdAtStr: getDateStr(scan.createdAt),
      documentsStr: scan.documents?.map(doc => doc.name).join(',') || '',
      endedAtStr: getDateStr(scan.endedAt),
      startedAtStr: getDateStr(scan.startedAt),
    })));
  }, [ target ]);

  return (
    <Accordion
      defaultExpanded={true}
      elevation={2}
    >
      <AccordionIconSummary>
        <Typography variant="h6">
          {target.name}
          <EditButton
            classes={editButtonClasses}
            onClick={onEditClick}
          />
        </Typography>
      </AccordionIconSummary>

      <Divider />

      <AccordionDetails>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              className={classes.heading}
              display="inline"
              color="textPrimary"
              component="span"
            >
              Addresses:
            </Typography>
            <Typography
              component="span"
              display="inline"
              variant="body2"
            >
              {target.addresses.join(', ')}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography
              variant="body1"
              className={classes.heading}
              color="textPrimary"
              component="span"
              display="inline"
            >
              Created:
            </Typography>
            <Typography
              component="span"
              display="inline"
              variant="body2"
            >
              {formatDate(target.createdAt)}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography
              variant="body1"
              className={classes.heading}
              color="textPrimary"
              component="span"
              display="inline"
            >
              Updated:
            </Typography>
            <Typography
              component="span"
              display="inline"
              variant="body2"
            >
              {formatDate(target.updatedAt)}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography
              variant="body1"
              className={classes.heading}
              color="textPrimary"
              component="span"
              display="inline"
            >
              Last Scanned:
            </Typography>
            <Typography
              component="span"
              display="inline"
              variant="body2"
            >
              {formatDate(target.lastScannedAt)}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <AccordionActions>
              <Button
                color="primary"
                disabled={target.scans && target.scans.some(s => [ 'requested', 'started' ].includes(s.status))}
                onClick={onRequestScan}
              >
                Request Scan
              </Button>
            </AccordionActions>
          </Grid>
        </Grid>
      </AccordionDetails>
      {target.scans && target.scans.length > 0 &&
      <SpioDataTable
        title="Scans"
        columns={getScanTableColumns(tableData)}
        data={tableData}
        options={{
          viewColumns: false,
          download: false,
          print: false,
          selectableRows: 'none',
          sort: true,
        }}
      />
      }

    </Accordion>
  );
}

const apiRoot = 'networkScan/target';

function TheNetworkScanPage() {
  const classes = useStyles();
  const [ isDialogOpen, setIsDialogOpen ] = useState(false);
  const [ targets, setTargets ] = useState<INetworkScanTargetDto[]>([]);
  const [ selectedTarget, setSelectedTarget ] = useState<INetworkScanTargetDto | null>(null);
  const [ selectedTargetIdx, setSelectedTargetIdx ] = useState(-1);

  useEffect(() => {
    API.get<INetworkScanTargetDto[]>(apiRoot)
      .then(res => setTargets(res.data))
      .catch(err => {
        showErrorResultBar('Unable to load scan targets.');
        Sentry.captureException(err);
      });
  }, []);

  function replaceTarget(idx: number, newTarget: INetworkScanTargetDto) {
    const newTargets = targets.slice();
    newTargets[idx] = { ...newTarget };

    setTargets(newTargets);
  }

  function handleEditClick(targetIdx: number) {
    return (e: React.SyntheticEvent<{}>) => {
      e.stopPropagation();
      setSelectedTargetIdx(targetIdx);
      setSelectedTarget(targets[targetIdx]);
      setIsDialogOpen(true);
    };
  }

  function handleRequestScan(targetIdx: number) {
    return () => {
      API.post<INetworkScanDto>(`${apiRoot}/${targets[targetIdx].id}/requestScan`, {
        status: 'requested',
      })
        .then(res => {
          const newTarget = targets[targetIdx];
          newTarget.scans = (newTarget.scans || []).concat(res.data);
          replaceTarget(targetIdx, newTarget);
        })
        .catch(err => {
          Sentry.captureException(err);
          showErrorResultBar('Unable to request a new scan for this target.');
        });
    };
  }

  async function handleTargetSave(updatedTarget: INetworkScanTargetUpdateDto) {
    setIsDialogOpen(false);
    if (selectedTarget === null || selectedTargetIdx === -1) {
      API.post<INetworkScanTargetDto>(`${apiRoot}`, updatedTarget)
        .then(res => {
          showSuccessResultBar('Scan target successfully added.');
          setTargets([ res.data ].concat(targets));
        })
        .catch(err => {
          Sentry.captureException(err);
          showErrorResultBar('Unable to add new target, please try again later.');
        });
    } else {
      API.patch<INetworkScanTargetDto>(`${apiRoot}/${selectedTarget.id}`, updatedTarget)
        .then(res => {
          showSuccessResultBar('Scan target updated successfully.');
          replaceTarget(selectedTargetIdx, res.data);
        })
        .catch(err => {
          Sentry.captureException(err);
          showErrorResultBar('Unable to update target, please try again later.');
        });
    }
  }

  function handleAddOnClick() {
    setSelectedTarget(null);
    setSelectedTargetIdx(-1);
    setIsDialogOpen(true);
  }

  return (
    <>
      <Typography
        className={classes.headerText}
        variant="h5"
      >
        {TheNetworkScanPage.title}
      </Typography>

      <CaptionedAddButton
        onClick={handleAddOnClick}
      >
        New Target
      </CaptionedAddButton>

      {targets && targets.length > 0 && targets.map((target, idx) =>
        <TargetAccordion
          key={idx}
          onEditClick={handleEditClick(idx)}
          onRequestScan={handleRequestScan(idx)}
          target={target} />,
      )}

      <NetworkScanTargetDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onSave={handleTargetSave}
        targetData={selectedTarget}
      />
    </>
  );
}

TheNetworkScanPage.title = 'Network Scans';
TheNetworkScanPage.requiredAuthZ = {
  tier: 1,
  permission: 'scans',
};

export default TheNetworkScanPage;
