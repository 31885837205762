import React from 'react';
import {
  Button,
  ButtonGroup,
  createStyles,
  FormControlLabel,
  Switch,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import { ViewMode } from 'gantt-task-react';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
      width: '100%',
    },
    buttonGroup: {
      marginRight: theme.spacing(2),
      boxShadow: 'none',
    },
    button: {
      boxShadow: 'none',
    },
    switch: {
      display: 'flex',
      alignItems: 'center',
    },
    switchLabel: {
      marginLeft: theme.spacing(1),
    },
  });

type ViewSwitcherProps = WithStyles<typeof styles> & {
  isChecked: boolean;
  onViewListChange: (isChecked: boolean) => void;
  onViewModeChange: (viewMode: ViewMode) => void;
  viewCollection: boolean;
  onViewCollectionChange: (viewCollection: boolean) => void;
};

const ViewSwitcher: React.FC<ViewSwitcherProps> = ({
  classes,
  onViewModeChange,
  onViewListChange,
  isChecked,
  viewCollection,
  onViewCollectionChange,
}) => {
  return (
    <div className={classes.root}>
      <ButtonGroup
        size="small"
        variant="contained"
        color="inherit"
        className={classes.buttonGroup}
      >
        <Button
          className={classes.button}
          onClick={() => onViewModeChange(ViewMode.Day)}
        >
          Day
        </Button>
        <Button
          className={classes.button}
          onClick={() => onViewModeChange(ViewMode.Week)}
        >
          Week
        </Button>
        <Button
          className={classes.button}
          onClick={() => onViewModeChange(ViewMode.Month)}
        >
          Month
        </Button>
        <Button
          className={classes.button}
          onClick={() => onViewModeChange(ViewMode.Year)}
        >
          Year
        </Button>
      </ButtonGroup>
      <div className={classes.switch}>
        <FormControlLabel
          control={
            <Switch
              checked={isChecked}
              onChange={() => onViewListChange(!isChecked)}
              color="primary"
            />
          }
          label="Show Task List"
          className={classes.switchLabel}
        />
      </div>
      <div>
        <FormControlLabel
          control={
            <Switch
              checked={viewCollection}
              onChange={() => onViewCollectionChange(!viewCollection)}
              color="primary"
            />
          }
          label="View Groups"
          className={classes.switchLabel}
        />
      </div>
    </div>
  );
};

export default withStyles(styles)(ViewSwitcher);
