import { TextField } from '@material-ui/core';
import { BaseTextFieldProps } from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/styles';
import { FormikProps, FormikValues } from 'formik';
import React from 'react';
import { helperAndErrorText } from './';

const useStyles = makeStyles({
  inputField: {
    marginRight: 0,
    marginLeft: 0,
  },
});

interface FormikTextFieldProps<Values extends FormikValues> extends BaseTextFieldProps {
  field: keyof Values;
  formikProps: FormikProps<Values>;
  helperTextStr?: string; // can be overridden with the usual 'helperText' prop
  onChange?: (e: React.ChangeEvent<any>) => void;
}

export default function FormikTextField<Values extends FormikValues>(props: FormikTextFieldProps<Values>) {
  const classes = useStyles();
  const { children, field, formikProps, helperTextStr, variant = 'filled', ...otherTextFieldProps } = props;
  const { errors, values, handleChange, handleBlur } = formikProps;
  const fieldStr = field.toString();

  return (
    <TextField
      className={classes.inputField}
      id={fieldStr}
      name={fieldStr}
      value={values[field]}
      onBlur={handleBlur}
      onChange={handleChange}
      fullWidth
      variant={variant as any}
      error={!!errors[field]}
      helperText={helperAndErrorText(helperTextStr, errors[field]?.toString())}
      {...otherTextFieldProps}
    >
      {children}
    </TextField>
  );
}
