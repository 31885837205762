import {
  Card,
  createStyles,
  Grid,
  Tab,
  TabProps,
  Theme,
  withStyles,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { ChartOptions } from 'chart.js';
import { Bubble } from 'react-chartjs-2';
import { IOrgMaterialIssueDto } from '../../../../backend/src/material-issue/interfaces/org-material-issue-dto.interface';

const useStyles = makeStyles({
  chartContainer: {
    paddingTop: '2rem',
    paddingBottom: '2rem',
    minHeight: '360px',
  },
  tabsContainer: {
    marginTop: '-10px',
  },
});

export interface MaterialityAssessmentBubbleChartProps {
  materialityMatrixScores: IOrgMaterialIssueDto[] | [];
}

interface issueData {
  x: string;
  y: string;
  name: string;
}

function MaterialityAssessmentBubbleChart({
  materialityMatrixScores,
}: MaterialityAssessmentBubbleChartProps) {
  const classes = useStyles();

  const [ chartData, setChartData ] = useState<any>({});
  const [ environmentalData, setEnvironmentalData ] = useState<issueData[]>([]);
  const [ socialData, setSocialData ] = useState<issueData[]>([]);
  const [ governanceData, setGovernanceData ] = useState<issueData[]>([]);
  const [ chartLabels, setChartLabels ] = useState<string[][]>([]);

  useEffect(() => {
      setEnvironmentalData(materialityMatrixScores.filter(issue => issue.materialIssue?.issueCategory === 'environmental').map(issue => Object({x: issue.businessScore, y: issue.stakeholderScore || 0, name: issue.materialIssue?.name})));
      setSocialData(materialityMatrixScores.filter(issue => issue.materialIssue?.issueCategory === 'social').map(issue => Object({x: issue.businessScore, y: issue.stakeholderScore || 0, name: issue.materialIssue?.name})));
      setGovernanceData(materialityMatrixScores.filter(issue => issue.materialIssue?.issueCategory === 'governance').map(issue => Object({x: issue.businessScore, y: issue.stakeholderScore || 0, name: issue.materialIssue?.name})));
    }, [materialityMatrixScores]);

  useEffect(() => {
      setChartData({
          datasets: [
            {
              label: 'Environmental Issue',
              data: environmentalData.map(issue => Object({x: issue.x, y: issue.y, r: radius})),
              backgroundColor: 'rgba(18, 255, 26, 0.5)',
            },
            {
              label: 'Social Issue',
              data: socialData.map(issue => Object({x: issue.x, y: issue.y, r: radius})),
              backgroundColor: 'rgb(252, 255, 45, 0.5)',
            },
            {
              label: 'Governance Issue',
              data: governanceData.map(issue=> Object({x: issue.x, y: issue.y, r: radius})),
              backgroundColor: 'rgb(255, 36, 36, .5)',
            },
          ],
        });
        setChartLabels([
          environmentalData.map(issue => issue.name),
          socialData.map(issue => issue.name),
          governanceData.map(issue => issue.name)
      ]);
    }, [environmentalData, socialData, governanceData]);

  const bubbleChartOptions: ChartOptions = {
      tooltips: {
          callbacks: {
              label: function(t: any, d: any) {
                return `${chartLabels[t.datasetIndex][t.index]} - (${Math.round(t.xLabel*10)/10}, ${Math.round(t.yLabel*10)/10})`;
              }
          }
        },
      responsive: true,
      scales: {
        xAxes: [{
          ticks: {
              beginAtZero: true,
              precision: 0,
              max: 5,
              fontColor: 'rgba(0, 0, 0, 0.87)',
              fontSize: 13,
            },
          scaleLabel: {
            display: true,
            labelString: 'Importance to Stakeholders (Least Important (1) to Most Important (5))',
            fontSize: 14,
          }
        }],
        yAxes: [{
          ticks: {
            beginAtZero: true,
            precision: 0,
            max: 5,
            fontColor: 'rgba(0, 0, 0, 0.87)',
            fontSize: 13,
          },
          scaleLabel: {
              display: true,
              labelString: 'Importance to Business (Least Important (1) to Most Important (5))',
              fontSize: 14,
          }
        }],
      }
    };

    const radius = 10;

  return (
      <Card>
          <Grid container className={classes.chartContainer}>
          <Bubble options={bubbleChartOptions} data={chartData} />
          </Grid>
      </Card>
  );
}

export default MaterialityAssessmentBubbleChart;
