import React, { useEffect, useState } from 'react';
import {
  DialogContent,
  Grid,
  RadioGroup,
  Radio,
  StandardProps,
  Checkbox,
  FormGroup,
  Typography,
  FormControl,
  FormControlLabel,
  FormLabel,
  Button,
  DialogActions,
} from '@material-ui/core';
import { RouterLink } from '../RouterLink';
import * as Sentry from '@sentry/browser';
import { Formik } from 'formik';
import * as Yup from 'yup';
import API from '../../services/ApiService';
import { SaveButton } from '../buttons';
import { FormGridRow, FormikTextField } from '../forms';
import { showErrorResultBar, showSuccessResultBar } from '../ResultSnackbar';
import StyledDialogTitle from '../StyledDialogTitle';
import ResponsiveDialog from './ResponsiveDialog';
// import { AccessType } from '../../../../backend/src/org-share/enums';
import { makeStyles } from '@material-ui/styles';
import FormikSelectPermissions from '../forms/FormikSelectPermissions';

const useStyles = makeStyles({
  selectedRoleContainer: {
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
  },
  roleSelectionContainer: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
    borderRadius: '4px',
  },
  gridContainer: {
    paddingLeft: '2rem',
    paddingRight: '2rem',
    paddingTop: '1rem',
  },
  roleSelectionName: {
    fontWeight: 500,
  },
  roleSelectionDescription: {
    paddingLeft: '0.5rem',
    fontWeight: 'normal',
  },
  formInformation: {
    paddingBottom: '1rem',
  },
  error: {
    paddingTop: '0.5rem',
    color: 'red',
    fontSize: '12px'
  },
});

export const accessTypeSelection = {
  'Data Sharing': {
    text: 'Data Sharing',
    description: 'Organization will have access to view specific pieces of information or data'
  },
  Manager: {
    text: 'Manager',
    description: 'Organization will be able to manage your account, including viewing all information and entering new information'
  },
  Owner: {
    text: 'Owner',
    description: 'Organization will be able to manage your account, including viewing all information and entering new information, and will pay for your account'
  },
};

const RequestSchema = [
  Yup.object().shape({
    orgName: Yup.string()
      .label('Organization Name')
      .required(),
    ownerEmail: Yup.string()
      .label('Organization Owner Email')
      .email('Invalid email format.')
      .required('Organization owner email required'),
  }),
  Yup.object().shape({
    role: Yup
    .mixed()
    .label('accessType')
    .oneOf(Object.keys(accessTypeSelection))
  }),
  Yup.object({
    checked: Yup.array().required()
  })
  .test({
    checked: 'atLeastOneRequired',
    test: function(values) {
      const isValid = values.checked.includes(true);

      if(isValid) return true;
      return this.createError({
        path: 'checked',
        message: 'At least one permission must be checked',
      });
    }
  })
];


export function AccessTypeSelectionLabel({ isSelected = false, accessSelection }) {
  const classes = useStyles();
  return (
    <>
      <Typography
        className={classes.roleSelectionName}
        variant="subtitle1"
      >
        {accessSelection.text}
        {/* {isSelected && ' (current role)'} */}
      </Typography>
      <Typography
        className={classes.roleSelectionDescription}
        variant="subtitle2"
      >
        {accessSelection.description}
      </Typography>
    </>
  );
}

export function RoleSelectionLabel({roleSelection }) {
  const classes = useStyles();

  return (
    <>
      <Typography
        className={classes.roleSelectionName}
        variant="subtitle1"
      >
        {roleSelection.name}
        {/* {isSelected && ' (current role)'} */}
      </Typography>
      <Typography
        className={classes.roleSelectionDescription}
        variant="subtitle2"
      >
        {roleSelection.description}
      </Typography>
    </>
  );
}

export default function OrgShareRequestOutboundDialog({ onClose, open, addOutboundOrg, allRoles }) {
  const [ activeStep, setActiveStep ] = useState(0);
  const [ validOrg, setValidOrg ] = useState(true);
  const [ orgError, setOrgError ] = useState('');

  const classes = useStyles();
  const nbSteps = 3;

  const HELPER_TEXT = {
    orgName: 'Name of the organization you are sharing access with',
    ownerEmail: 'Email address of organization owner',
    accessType: '',
  };

  const goToNextStep = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const goToPreviousStep = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    onClose();
    setActiveStep(0);
  };

  const handleSave = async (formValues) => {
    const orgRoles = [];
    allRoles.forEach((r, idx) => formValues.checked[idx] ? orgRoles.push(r.id) : null);
    const orgShareRequestDto = {
        orgName: formValues.orgName,
        orgOwnerEmail: formValues.ownerEmail,
        accessType: formValues.accessType,
        roleIds: orgRoles,
        accessDirection: 'outbound'
    };

    try {
      const res = await API.post('org-share/outboundRequest', orgShareRequestDto);
      showSuccessResultBar('Org access shared. The org owner will be notified of the updated access.');
      addOutboundOrg(res);
    } catch (err) {
      const errorMsg = err.response?.data?.error ?? 'Unexpected error sharing org access';
      showErrorResultBar(errorMsg);
      Sentry.captureException(err);
    }
  };

  const checkOrgExists = async (orgName, ownerEmail) => {
    const orgShareRequestDto = {
      orgName: orgName,
      orgOwnerEmail: ownerEmail,
      direction: 'outbound'
    };
    try {
      const res = await API.post('org-share/validateOrg', orgShareRequestDto);
      return res.data?.data;
    } catch (err) {
      const errorMsg = err.response?.data?.message ?? 'No org exists';
      setOrgError(errorMsg);
      return false;
    }
  };

  const handleBlur = async () => {
    setValidOrg(true);
  };

  return (
    <ResponsiveDialog
      disableBackdropClick
      fullWidth
      maxWidth="md"
      open={open}
      onClose={() => {handleReset();}}
    >
      <StyledDialogTitle onClose={() => {handleReset();}}>
        Share Access with Org
      </StyledDialogTitle>
      <Formik
        initialValues={{
          orgName: '',
          ownerEmail: '',
          accessType: 'Manager',
          checked: Array(allRoles.length).fill(false)
        }}
        onSubmit={async (values, { setSubmitting }) => {
          if (activeStep === nbSteps - 1) {
            await handleSave(values);
            setSubmitting(false);
            onClose();
          } else if (activeStep === 0) {
            const valid = await checkOrgExists(values.orgName, values.ownerEmail);
            if (valid) {
              goToNextStep();
            } else {
              setValidOrg(false);
            }
          } else {
            goToNextStep();
          }
        }}
        onReset={(_) => {
          handleReset();
          }}
        validateOnBlur={false}
        validateOnChange={true}
        validationSchema={RequestSchema[activeStep]}
      >
        {formikProps => (
        <>
          <DialogContent>
            <Grid container>
            {activeStep === 0 &&
            <Grid container className={classes.gridContainer}>
              <Grid container className={classes.formInformation}>
                <Grid item>
                  <Typography variant="h6">
                    Organization Information
                  </Typography>
                  <Typography variant="caption">
                    An organization can find this information on their <RouterLink color="primary" to="/org">Organization Settings</RouterLink> page. The information must be an exact match.
                  </Typography>
                </Grid>
              </Grid>
                <FormikTextField
                  autoFocus
                  field="orgName"
                  formikProps={formikProps}
                  onBlur={() => handleBlur()}
                  helperTextStr={HELPER_TEXT.orgName}
                  label="Organization Name"
                  required
                />
                <FormikTextField
                  autoFocus
                  field="ownerEmail"
                  formikProps={formikProps}
                  onBlur={() => handleBlur()}
                  helperTextStr={HELPER_TEXT.ownerEmail}
                  label="Organization Owner's Email"
                  required
                />
              {!validOrg &&
                <Typography className={classes.error}>
                  {orgError}
                </Typography>
              }
              </Grid>
            }
            {activeStep === 1 &&
            <Grid container className={classes.gridContainer}>
            <Typography variant="h6">
              Access Type
            </Typography>
                {/* Role selection */}
                <FormControl>
                  <FormLabel>
                  </FormLabel>
                  <RadioGroup
                    id="accessType"
                    name="accessType"
                    value={formikProps.values.accessType}
                    onBlur={formikProps.handleBlur}
                    onChange={formikProps.handleChange}
                  >
                    {Object.entries(accessTypeSelection).map(([ key, accessSelection ]) => (
                      <FormControlLabel
                        key={key}
                        disabled={accessSelection.text !== 'Manager'}
                        value={key}
                        control={<Radio />}
                        label={<AccessTypeSelectionLabel accessSelection={accessSelection} />}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>
            }
            {activeStep === 2 &&
            <Grid className={classes.gridContainer}>
                <Typography variant="h6">
                  Permissions Granted
                </Typography>
                <Typography variant="caption">
                  These are the specific permissions that the recieving organization will be able to access. Visit the <RouterLink to="/permissions">Permissions page</RouterLink> for more information on permissions assigned to each role.
                </Typography>
              <FormikSelectPermissions
                allRoles={allRoles}
                formikProps={formikProps}
              />
              </Grid>
            }
            </Grid>
          </DialogContent>
          <DialogActions>
          <Button
            size="small"
            disabled={activeStep === 0}
            onClick={goToPreviousStep}
            className={classes.button}
          >
            Back
          </Button>
            <SaveButton
                disabled={formikProps.isSubmitting || Object.values(formikProps.errors).filter(v => !!v).length > 0}
                onClick={formikProps.handleSubmit}
                text={(activeStep === nbSteps - 1) ? 'Grant Access' : 'Next'}
            />
            <Button
                disabled={formikProps.isSubmitting}
                onClick={formikProps.handleReset}
                color="primary"
            >
                Cancel
            </Button>

          </DialogActions>
        </>
        )}
      </Formik>
    </ResponsiveDialog>
  );
}
