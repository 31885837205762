import { Button, Grid, Typography, TextField, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import React, { ReactElement } from 'react';
import StyledDialogTitle from '../StyledDialogTitle';
import { Formik } from 'formik';
import ResponsiveDialog from './ResponsiveDialog';
import SaveButton from '../buttons/SaveButton';
import * as Yup from 'yup';

const facilitySchema = Yup.object({
    name: Yup.string().required(),
  });

export interface AddFacilityDialogProps extends DialogProps {
  children?: string | ReactElement;
  title?: string;
  handleSave: (formContent: object) => Promise<void>;
  onClose: () => void;
}

function AddFacilityDialog({ children, title, open, onClose, handleSave, ...dialogProps }: AddFacilityDialogProps) {
  return (
    <ResponsiveDialog
      disableBackdropClick={true}
      fullWidth
      open={open}
      onClose={onClose}
    >
      <StyledDialogTitle
        onClose={onClose}
      >
        Add Facility
      </StyledDialogTitle>
      <Formik
        enableReinitialize
        initialValues={{
          name: null
        }}
        //validationSchema={facilitySchema}
        onSubmit={async (values, { setSubmitting }) => {
          await handleSave(values);
          setSubmitting(false);
          onClose();
        }}
        onReset={_ => {
          onClose();
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleReset,
          handleSubmit,
          isSubmitting,
        }) => (
          <>
            <DialogContent>
                  <TextField
                    autoFocus
                    // className={classes.textField}
                    fullWidth
                    id="facility-name"
                    label="Facility Name"
                    name="name"
                    type="text"
                    value={values.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    helperText={errors.name}
                    error={!!errors.name}
                  />
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                size="small"
                disabled={isSubmitting}
                onClick={handleReset}
              >
                Cancel
              </Button>
              <SaveButton
                disabled={isSubmitting || Object.keys(errors).length > 0}
                onClick={handleSubmit}
              />
            </DialogActions>
          </>
        )}
      </Formik>
    </ResponsiveDialog>
  );
}

export default AddFacilityDialog;
