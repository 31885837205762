import { createStyles, Theme, Typography, withStyles, WithStyles } from '@material-ui/core';
import { TypographyProps } from '@material-ui/core/Typography';
import classNames from 'classnames';

const styles = (theme: Theme) => createStyles({
  partOne: {
    // color: theme.palette.secondary.main,
    color: '#d9e3ea',
    fontSize: 'inherit',
    fontWeight: 300,
    display: 'inline',
  },

  partTwo: {
    // color: theme.palette.secondary.contrastText,
    // color: theme.palette.secondary.main,
    color: '#d9e3ea',
    fontSize: 'inherit',
    fontWeight: 300,
    display: 'inline',
  },
});

interface StylizedNameProps extends Pick<TypographyProps, 'className' | 'variant'>, WithStyles<typeof styles> { }

function StylizedName({ classes, className, variant }: StylizedNameProps) {
  return (
    <>
      <Typography component="span" variant={variant}
        className={classNames(className, classes.partOne)}>Gemini</Typography>
    </>
  );
}

export default withStyles(styles, { name: 'StylizedName', withTheme: true })(StylizedName);
