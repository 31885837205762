import React, { useState } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  createStyles,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import Auth from '../services/AuthService';
import API from '../services/ApiService';
import { showErrorResultBar, showSuccessResultBar } from '../components/ResultSnackbar';
import * as Sentry from '@sentry/browser';
import { SecondaryField } from './VendorDetailsInfo';
import { OrgOutgoingAccessPermissionsDetails } from './OrgOutgoingAccessPermissionsDetails';
import { ConfirmationDialog } from './dialogs';
import OrgShareApproveAccessDialog from './dialogs/OrgShareApproveAccessDialog';
import { IOrgRBACJoinDto, IRoleDto } from '../../../backend/src/org-share/interfaces';
import { AxiosResponse } from 'axios';
import { handleError } from '../helpers';

const useStyles = makeStyles((theme) =>
  createStyles({
    cardHeader: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    primaryColumn: {
      color: theme.typography.body1.color,
      fontSize: '14px',
    },
    referenceResponse: {
      color: 'rgba(0,0,0,0.54)',
      //whiteSpace: 'pre-line',
    },
    secondaryColumn: {
      backgroundColor: '#f5f5f5',
      opacity: '0.8',
      fontSize: '0.8125rem',
    }
  })
);

export interface OrgIncomingAccessDetailsProps {
  orgData: IOrgRBACJoinDto;
  auth: Auth;
  allRoles: IRoleDto[];
  updateOutboundOrg: (apiResponse: AxiosResponse<any>, successMsg: string) => void;
  handleApproveAccess: (orgRBACId: string, roleIds: string[]) => void;
}

export default function OrgOutgoingAccessDetails({ orgData, auth, allRoles, updateOutboundOrg, handleApproveAccess }: OrgIncomingAccessDetailsProps) {
  const [ toShowConfirmMarkRevoked, setToShowConfirmMarkRevoked ] = useState(false);
  const [ toShowConfirmMarkApproved, setToShowConfirmMarkApproved] = useState(false);
  const [ toShowConfirmMarkDenied, setToShowConfirmMarkDenied] = useState(false);

  const classes = useStyles();

  const updateStatus = async (status: string) => {
      try {
        const uploadData = {
          status: status
        };
        const res = await API.patch(`org-share/${orgData.id}`, uploadData);
        if (status === 'active') {
            updateOutboundOrg(res, 'Org access successfully approved');
        } else if (status === 'revoked') {
            updateOutboundOrg(res, 'Org access successfully revoked');
        } else if (status === 'denied') {
            updateOutboundOrg(res, 'Org access successfully denied');
        }
      } catch (err) {
        handleError(err,'Error updating org access');
      }
  };

  const updateOrgRoles = async (roleIds: string[]) => {
    if (roleIds.length === 0) {
      showErrorResultBar('Org must be granted at least one assignable role');
    } else {
      try {
        const uploadData = {
          roleIds: roleIds
        };
        await API.patch(`org-share/outbound/${orgData.id}`, uploadData);
        showSuccessResultBar('Permissions updated successfully');
      } catch (err) {
        handleError(err,'Error updating org access');
      }
    }
};

const handleConfirmMarkRevoked = (confirmed: boolean) => {
  if (confirmed) {
    updateStatus('revoked');
  }
  setToShowConfirmMarkRevoked(false);
};

const handleConfirmMarkDenied = (confirmed: boolean) => {
  if (confirmed) {
    updateStatus('denied');
  }
  setToShowConfirmMarkDenied(false);
};

  const getDetailsByStatus = (status: string, accessType: string) => {
    switch (status) {
      case 'pending':
        return (
              <>
              {/* Access requested */}
                <Card>
                <CardHeader
                  className={classes.cardHeader}
                  title={orgData.toOrg.name}
                  subheader={`Pending ${orgData.accessType} Access`}
                />
                <CardContent>
                  <Grid container alignItems="flex-start" spacing={4}>
                    <Grid item xs={12} sm={7} md={8} className={classes.primaryColumn}>
                      <Typography variant="body1">
                        {orgData.toOrg.name} has requested {orgData.accessType.toLowerCase()} access to your organization.
                      </Typography>
                      </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={5}
                        md={4}
                        className={classes.secondaryColumn}
                      >
                        <SecondaryField
                          title="Access requested on:"
                          value={orgData.requestedDate.toString() || 'N/A'}
                        />
                        <SecondaryField
                          title="Access requested by:"
                          value={orgData.requestedByName || 'N/A'}
                        />
                      </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                  <Grid container>
                  <Grid item>
                      <CardActions>
                            <Button color="primary" variant="contained" size="small" onClick={() => {setToShowConfirmMarkApproved(true);}} disabled={!auth.isGranted({ permission: 'org_share:admin' })}>
                              Approve Request
                            </Button>
                            <Button color="inherit" variant="outlined" size="small" onClick={() => {setToShowConfirmMarkDenied(true);}} disabled={!auth.isGranted({ permission: 'org_share:admin' })}>
                              Deny Request
                            </Button>
                        </CardActions>
                    </Grid>
                  </Grid>
                </Card>
                <OrgShareApproveAccessDialog
                  allRoles={allRoles}
                  onClose={() => {setToShowConfirmMarkApproved(false);}}
                  open={toShowConfirmMarkApproved}
                  orgData={orgData}
                  handleSave={handleApproveAccess}
                />
                <ConfirmationDialog
                  onResponse={handleConfirmMarkDenied}
                  open={toShowConfirmMarkDenied}
                  title="Deny access?"
                >
                  {`Are you sure you want to deny ${orgData.toOrg.name}'s request to access your organization?`}
                </ConfirmationDialog>
            </>
            );
      case 'active':
        return (
          <>
            <Card>
            <CardHeader
              className={classes.cardHeader}
              title={orgData.toOrg.name}
              subheader={`${orgData.accessType} Access`}
            />
            <CardContent>
              <Grid container alignItems="flex-start" spacing={4}>
                <Grid item xs={12} sm={7} md={8} className={classes.primaryColumn}>
                  <Grid container>
                    <Grid item xs={3}>Access description:</Grid>
                      <Grid item xs={9} className={classes.referenceResponse}>
                        Your organization has granted {orgData.accessType.toLowerCase()} access to {orgData.toOrg.name}.
                        They have access up to the granted permissions. They will not be able to access billing or org sharing for your organization.
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={5}
                    md={4}
                    className={classes.secondaryColumn}
                  >
                    <SecondaryField
                      title="Access approved on:"
                      value={orgData.approvedDate.toString() || 'N/A'}
                    />
                    <SecondaryField
                      title="Access approved by:"
                      value={orgData.approvedByName || 'N/A'}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <OrgOutgoingAccessPermissionsDetails
                    orgData={orgData}
                    allRoles={allRoles}
                    onClickSave={updateOrgRoles}
                  />
                </Grid>
            </CardContent>
            <Divider />
            <Grid container>
              <Grid item>
                <CardActions>
                    <Button color="inherit" variant="outlined" size="small" onClick={() => {setToShowConfirmMarkRevoked(true);}} disabled={!auth.isGranted({ permission: 'org_share:admin' })}>
                      Revoke Access
                    </Button>
                </CardActions>
              </Grid>
            </Grid>
            </Card>
            <ConfirmationDialog
              onResponse={handleConfirmMarkRevoked}
              open={toShowConfirmMarkRevoked}
              title="Revoke access?"
            >
              {`Are you sure you want to revoke ${orgData.toOrg.name}'s access to ${orgData.fromOrg.name}?`}
            </ConfirmationDialog>
          </>
        );
        case 'denied':
            return (
              <>
                <Card>
                <CardHeader
                  className={classes.cardHeader}
                  title={orgData.toOrg.name}
                  subheader={'Denied Access'}
                />
                <CardContent>
                  <Grid container alignItems="flex-start" spacing={4}>
                    <Grid item xs={12} sm={7} md={8} className={classes.primaryColumn}>
                      <Typography variant="body1">
                        Your organization has denied the requested access by {orgData.toOrg.name}. {orgData.toOrg.name} will not be able to access the {orgData.fromOrg.name} account.
                      </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={5}
                        md={4}
                        className={classes.secondaryColumn}
                      >
                        <SecondaryField
                          title="Access requested on:"
                          value={orgData.requestedDate.toString() || 'N/A'}
                        />
                        <SecondaryField
                          title="Access requested by:"
                          value={orgData.requestedByName || 'N/A'}
                        />
                      </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                  <Grid container>
                    <Grid item>
                        <CardActions>
                          <Button color="primary" variant="contained" size="small" onClick={() => {setToShowConfirmMarkApproved(true);}} disabled={!auth.isGranted({ permission: 'org_share:admin' })}>
                            Approve Access
                          </Button>
                        </CardActions>
                    </Grid>
                  </Grid>
                </Card>
                <OrgShareApproveAccessDialog
                  allRoles={allRoles}
                  onClose={() => {setToShowConfirmMarkApproved(false);}}
                  open={toShowConfirmMarkApproved}
                  orgData={orgData}
                  handleSave={handleApproveAccess}
                />
                </>
            );
        case 'revoked':
            return (
              <>
                <Card>
                <CardHeader
                  className={classes.cardHeader}
                  title={orgData.toOrg.name}
                  subheader={'Revoked Access'}
                />
                <CardContent>
                  <Grid container alignItems="flex-start" spacing={4}>
                    <Grid item xs={12} sm={7} md={8} className={classes.primaryColumn}>
                      <Typography variant="body1">
                        Your organization has revoked access to {orgData.toOrg.name}. They will no longer be able to access the {orgData.fromOrg.name} account.
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={5}
                      md={4}
                      className={classes.secondaryColumn}
                    >
                      <SecondaryField
                        title="Access revoked on:"
                        value={orgData.approvedDate.toString() || 'N/A'}
                      />
                      <SecondaryField
                        title="Access revoked by:"
                        value={orgData.approvedByName || 'N/A'}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                  <Grid container>
                  <Grid item>
                      <CardActions>
                            <Button color="primary" variant="contained" size="small" onClick={() => {setToShowConfirmMarkApproved(true);}} disabled={!auth.isGranted({ permission: 'org_share:admin' })}>
                              Re-Establish Access
                            </Button>
                        </CardActions>
                    </Grid>
                  </Grid>
                </Card>
                <OrgShareApproveAccessDialog
                  allRoles={allRoles}
                  onClose={() => {setToShowConfirmMarkApproved(false);}}
                  open={toShowConfirmMarkApproved}
                  orgData={orgData}
                  handleSave={handleApproveAccess}
                />
              </>
            );
      default:
        return (
            <Card>
                <CardHeader
                  className={classes.cardHeader}
                  title={orgData.toOrg.name}
                  subheader={`${orgData.accessType} Access`}
                />
            </Card>
        );
    }
  };

    return (getDetailsByStatus(orgData.status, orgData.accessType));
}
