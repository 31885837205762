import { KeyboardDatePicker, KeyboardDatePickerProps } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/styles';
import { FormikProps, FormikValues } from 'formik';
import moment from 'moment';
import React from 'react';
import { helperAndErrorText } from '.';

const useStyles = makeStyles({
  inputField: {
    marginRight: 0,
    marginLeft: 0,
  },
});

interface FormikDatePickerProps<Values extends FormikValues> extends Partial<KeyboardDatePickerProps> {
  field: keyof Values;
  formikProps: FormikProps<Values>;
  helperTextStr?: string; // can be overridden with the usual 'helperText' prop
  initValue?: Date | null; // don't use Formik's values[field] for this b/c it gets updated with each 'onChange'
  nullable?: boolean;
}

export default function FormikDatePicker<Values extends FormikValues>(props: FormikDatePickerProps<Values>) {
  const classes = useStyles();
  const { children, field, formikProps, helperTextStr, initValue = null, nullable = false, variant = 'filled', ...otherTextFieldProps } = props;
  const { errors, setFieldError, setFieldValue, values } = formikProps;
  const fieldStr = field.toString();

  return (
    <KeyboardDatePicker
      className={classes.inputField}
      id={fieldStr}
      name={fieldStr}
      placeholder="YYYY-MM-DD"
      value={values[field]}
      onBlur={(e) => {
        if (!e.target.value) {
          setFieldValue(fieldStr, nullable ? null : (initValue ?? moment()), false);
        }
      }}
      onChange={val => setFieldValue(fieldStr, val, false)}
      onError={(error) => {
        if (error !== errors[field]) {
          setFieldError(fieldStr, error?.toString() ?? '');
        }
      }}
      fullWidth
      inputVariant={variant as any}
      format="YYYY-MM-DD"
      invalidDateMessage="Invalid date format"
      error={!!errors[field]}
      helperText={helperAndErrorText(helperTextStr, errors[field]?.toString())}
      {...otherTextFieldProps}
    />
  );
}
