import {
    Breadcrumbs,
    Backdrop,
    CircularProgress,
    TableCell,
    TableRow,
    Theme,
    Typography,
    withStyles,
    WithStyles,
} from '@material-ui/core';
import { RouterLink } from '../components/RouterLink';
import * as Sentry from '@sentry/browser';
import React, { useEffect, useState } from 'react';
import { IInboundQuestionnaireDto } from '../../../backend/src/inbound-questionnaire/interfaces';
import API from '../services/ApiService';
import Auth from '../services/AuthService';
import { showErrorResultBar } from '../components/ResultSnackbar';
import SpioDataTable, {
  SpioDataTableColumn,
  SpioDataTableColumnOptions
} from '../components/SpioDataTable';
import { formatDate } from '../helpers';
import { stakeholderTypeMap } from '../components/StakeholderDetailsInfo';
import { StakeholderType } from '../../../backend/src/stakeholder/enums/stakeholder-type.enum';
import { RouteComponentProps } from 'react-router';
import { IStakeholderQuestionnaireTemplateDto } from '../../../backend/src/stakeholder/interfaces';
import OutboundQuestionnaireResponseDetails from '../components/OutboundQuestionnaireResponseDetails';
import { IStakeholderQuestionnaireDto } from '../../../backend/src/stakeholder/interfaces';

const styles = (theme: Theme) => ({
    breadcrumb: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    backdrop: {
        // color: theme.palette.primary.main,
        // color: 'white',
        zIndex: theme.zIndex.drawer + 1,
    },
    circularProgress: {
        zIndex: theme.zIndex.drawer + 2,
        left: '50%',
        top: '50%',
    },
});

export const UNASSIGNED_DATA_STR = '(blank)';

export const QUESTIONNAIRE_RESPONDENT_MAP = {
    other: 'Other',
    completed: 'Completed',
    review: 'Needs Review',
    in_progress: 'In Progress',
  };

const getTableColumns = (tableData: ITableDatum[], stakeholderQuestions: StakeholderQuestionProps[]): SpioDataTableColumn[] => {
  let columns = [
    {
        name: 'id',
        label: 'ID',
        options: {
            display: false,
            filter: false,
        },
    },
    {
        name: 'stakeholderName',
        label: 'Respondent Name',
        options: {
          display: true,
          filter: true,
        },
    },
    {
      name: 'responseCreatedAt',
      label: 'Response Date',
      options: {
        customBodyRenderLite: (dataIndex: number) => {
          return formatDate(tableData[dataIndex]?.responseCreatedAt || '');
        },
        display: true,
        filter: true,
      },
    },
    {
        name: 'typeStr',
        label: 'Respondent Type',
        options: {
          display: true,
          filter: true,
        },
    },
    {
        name: 'questionnaireVersion',
        label: 'Questionnaire Version',
        options: {
          display: true,
          filter: true,
        },
    },
  ];

  if (stakeholderQuestions.length > 0) {
    stakeholderQuestions.forEach((column) =>
      columns.push({
        name: column.id,
        label: column.text,
        options: {
          display: false,
          filter: false,
        },
      })
    );}
    return columns;
  };

export interface StakeholderQuestionProps {
  text: string;
  id: string;
  label: string
}

interface ITableDatum extends IInboundQuestionnaireDto {
  dueDateStr: string;
  statusStr: string;
  statusUpdatedAtStr: string;
  responseCreatedAt?: string;
  typeStr: string;
}

export interface TheQuestionnaireResponseDetailPageProps extends WithStyles<typeof styles>, RouteComponentProps<{ id: string }> {
    auth: Auth;
  }

  function TheQuestionnaireResponseDetailPage({ auth, history, match, classes }: TheQuestionnaireResponseDetailPageProps) {

  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState<ITableDatum[]>([]);
  const [questionnaire, setQuestionnaire] = useState<IStakeholderQuestionnaireTemplateDto>();
  const [questionnaireName, setQuestionnaireName] = useState('');
  const [orgName, setOrgName] = useState('company');
  const [questionnaireResponses, setQuestionnaireResponses] = useState<IStakeholderQuestionnaireDto[]>([]);

  const questionnaireId = match.params.id;

  const respondentType = (type: StakeholderType) => {
    return stakeholderTypeMap[type];
  };

  useEffect(() => {
    setIsLoading(true);

    API.get(`outbound-questionnaire/questionnaire/${questionnaireId}`)
      .then((res) => setQuestionnaire(res.data?.data))
      .catch((err) => {
        showErrorResultBar('Cannot get questionnaire name');
        Sentry.captureException(err);
      })
      .finally(() => setIsLoading(false));
  }, [setQuestionnaireName, questionnaireId]);

  useEffect(() => {
    setIsLoading(true);

    API.get(`outbound-questionnaire/${questionnaireId}/responses`)
      .then((res) => {
        setQuestionnaireResponses(res.data?.data ?? []);
        setOrgName(res.data?.orgName ?? 'company');
      })
      .catch((err) => {
        showErrorResultBar('Unexpected error loading questionnaire responses');
        Sentry.captureException(err);
      })
      .finally(() => setIsLoading(false));
  }, [setQuestionnaireResponses, questionnaireId, setOrgName]);

  useEffect(() => {
    setTableData(
      questionnaireResponses.map((d) => {
        return Object({
          ...d,
          stakeholderName: d.stakeholder?.stakeholderName || '',
          stakeholderEmail: d.stakeholder?.contactEmail || '',
          responseCreatedAt: d.response.createdAt,
          questionnaireName: d.questionnaireTemplate.name,
          questionnaireVersion: d.questionnaireTemplate.version,
          typeStr: (d.stakeholder?.type ? respondentType(d.stakeholder.type) : 'Other'),
          ...d.response.response,
        });
      })
    );
  }, [questionnaireResponses]);

  // TODO: This is not a good way to do this, should be optimized more
    let STAKEHOLDER_QUESTIONS:StakeholderQuestionProps[] = [];
    const questionnaires = questionnaire ? questionnaire.template['blocks'] : [];
    for (let bb = 0; bb < questionnaires.length; bb++) {
      for (let qq = 0; qq < questionnaires[bb].questions.length; qq++) {
        if (Object.keys(questionnaires[bb].questions[qq]).indexOf('id') > -1) {
          STAKEHOLDER_QUESTIONS.push(
            {
              'id': questionnaires[bb].questions[qq].id  || '',
              'text': questionnaires[bb].questions[qq].text.replace('<COMPANY>', orgName),
              'label': questionnaires[bb].questions[qq].label || ''
            }
          );
        } else if (Object.keys(questionnaires[bb].questions[qq]).indexOf('issues') > -1) {
          for (let ii = 0; ii < questionnaires[bb].questions[qq].issues.length; ii++) {
            STAKEHOLDER_QUESTIONS.push(
              {
                'id': questionnaires[bb].questions[qq].issues[ii].id,
                'text': questionnaires[bb].questions[qq].issues[ii].text.replace('<COMPANY>', orgName),
                'label': questionnaires[bb].questions[qq].label || ''
              }
            );
          };
        }
      }
    };

  if (isLoading) {
    return (
      <>
        <Backdrop className={classes.backdrop} open={isLoading} timeout={500} />
        <CircularProgress
          className={classes.circularProgress}
          color="primary"
          size={60}
        />
      </>
    );
  }

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
        <RouterLink color="inherit" to="/outbound-questionnaire">
          Outbound Questionnaires
        </RouterLink>
        <Typography color="textPrimary"> Questionnaire Responses</Typography>
      </Breadcrumbs>
      <SpioDataTable
        title={`${questionnaire?.name} Questionnaire Responses` || 'Questionnaire Responses'}
        columns={getTableColumns(tableData, STAKEHOLDER_QUESTIONS)}
        data={tableData}
        options={{
          print: false,
          filterType: 'multiselect',
          selectableRows: 'none',
          textLabels: {
            body: {
              noMatch: isLoading ? 'Loading...' : 'No records found',
              toolTip: 'Sort',
            },
          },
          expandableRows: true,
          expandableRowsOnClick: true,
          renderExpandableRow: (rowData, rowMeta) => {
            const colSpan = rowData.length + 1;
            const myData = questionnaireResponses[rowMeta.dataIndex];

            return myData ? (
              <TableRow>
                <TableCell colSpan={colSpan}>
                <OutboundQuestionnaireResponseDetails
                      questionnaire={myData}
                      stakeholderData={myData.stakeholder || null}
                      questionnaireTemplate={myData.questionnaireTemplate}
                      response={myData.response}
                      auth={auth}
                      StakeholderQuestions={STAKEHOLDER_QUESTIONS}
                  />
                </TableCell>
              </TableRow>
            ) : null;
          },
        }}
      />
    </>
  );
};

TheQuestionnaireResponseDetailPage.requiredAuthZ = {
  tier: 3,
  permission: 'outbound_questionnaire:responses',
};
TheQuestionnaireResponseDetailPage.title = 'Outbound Questionnaire Responses';

export default withStyles(styles, { withTheme: true })(TheQuestionnaireResponseDetailPage);
