import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  List,
  DialogContent
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import API from '../services/ApiService';
import * as Sentry from '@sentry/browser';
import { showErrorResultBar, showSuccessResultBar } from './ResultSnackbar';
import { TaskStatus } from '../../../backend/src/task/task-status.enum';
import SubTaskInput from './SubTaskInput';
import SubTaskListItem from './SubTaskListItem';
import {
  ISubTaskDto,
  ITaskAssigneeDto,
  ISubTaskUpdateDto
} from '../../../backend/src/task/interfaces';

const useStyles = makeStyles({
  leftButton: {
    marginLeft: 'auto',
  },
  checkBox: {
      color: 'purple'
  }
});

export interface ISubTaskInputData {
  id?: string;
  title: string;
  description: string;
}

export interface SubTaskInputProps {
  assignees: ITaskAssigneeDto[];
  checked: (boolean | undefined)[]
  markStarted: () => void;
  onAddSubTask: (formData: ISubTaskInputData) => Promise<void>;
  onUpdateSubTasks: (updatedSubTasks: ISubTaskDto[]) => void;
  readOnly: boolean;
  setChecked: (checked: (boolean | undefined)[]) => void;
  subTasks: ISubTaskDto[];
  taskId: string;
  taskStatus: TaskStatus;
  updateProgress: (checked: (boolean | undefined)[], subTaskUpdates?: ISubTaskDto[] | undefined) => void;
}

export default function SubTaskList(props: SubTaskInputProps) {
    const {
      assignees,
      checked,
      markStarted,
      onAddSubTask,
      onUpdateSubTasks,
      readOnly,
      setChecked,
      subTasks,
      taskId,
      taskStatus,
      updateProgress,
    } = props;
    const classes = useStyles();
    const [ subTaskToDelete, setSubTaskToDelete ] = useState<string | undefined>(); // If defined, opens the 'Delete tag?' dialog.
    const [ subTaskToComplete, setSubTaskToComplete ] = useState<string | undefined>(); // If defined, opens the 'Delete tag?' dialog.
    const [ subTaskIdEditing, setSubTaskIdEditing ] = useState<string>();

    const closeDeleteDialog = () => {
        setSubTaskToDelete(undefined);
    };

    const closeCompleteDialog = () => {
      setSubTaskToComplete(undefined);
     };

    const handleUpdateSubtask = (subTaskIdx: number) => async (updateDto: ISubTaskUpdateDto) => {
      const res: ISubTaskDto = (await API.patch(`task/${taskId}/subTask/${subTasks[subTaskIdx].id}`, updateDto)).data.data;
      const currSubtask = subTasks[subTaskIdx];
      let updatedSubTasks: ISubTaskDto[];
      updatedSubTasks = subTasks.slice();
      updatedSubTasks[subTaskIdx] = res;
      if (updateDto.subtaskOrder !== undefined) {
        // update order of all subtasks if subtasks were reordered
        const newsubtaskOrder: number = updateDto.subtaskOrder;
        if (currSubtask.subtaskOrder < newsubtaskOrder) {
          updatedSubTasks.forEach((s, idx) => {
            if (s.subtaskOrder > currSubtask.subtaskOrder && s.subtaskOrder <= newsubtaskOrder && s.id !== currSubtask.id) {
              updatedSubTasks[idx] = {
                ...updatedSubTasks[idx],
                ...{subtaskOrder: updatedSubTasks[idx].subtaskOrder - 1},
              };
            }
          });
        } else {
          updatedSubTasks.forEach((s, idx) => {
            if (s.subtaskOrder < currSubtask.subtaskOrder && s.subtaskOrder >= newsubtaskOrder && s.id !== currSubtask.id) {
              updatedSubTasks[idx] = {
                ...updatedSubTasks[idx],
                ...{subtaskOrder: updatedSubTasks[idx].subtaskOrder + 1},
              };
            }
          });
        }
        // resort
        updatedSubTasks = updatedSubTasks.sort((a,b) => a.subtaskOrder - b.subtaskOrder);
      }
      onUpdateSubTasks(updatedSubTasks);
    };

    const deleteSubTask = async (subTaskId: string) => {
        closeDeleteDialog();
        try {
            API.delete(`task/${taskId}/subTask/${subTaskId}`);
            const updatedSubTasks = subTasks.slice().filter(st => st.id !== subTaskId);
            const subTaskIdx = subTasks.findIndex(subTask => subTask.id === subTaskId);
            onUpdateSubTasks(updatedSubTasks);
            const dataCopy = checked.slice();
            if (subTaskIdx > -1) {
                dataCopy.splice(subTaskIdx, 1);
            }
            setChecked(dataCopy);
            updateProgress(dataCopy, updatedSubTasks);
            showSuccessResultBar('Subtask successfully deleted.');
        } catch (err) {
            Sentry.captureException(err);
            showErrorResultBar('There was an error while deleting your subtask.');
        }
    };

    const handleToggle = async (subTaskId: string) => {
      const currentIndex = subTasks.findIndex(subTask => subTask.id === subTaskId);
      closeCompleteDialog();
        try {
            if (taskStatus === 'not_started') {
              markStarted();
            }
            let newChecked = [...checked];
            let completed = true;
            if (checked[currentIndex]) {
              completed = false;
            };
            const subTaskUpdateDto = {
              'completed': completed
            };
            newChecked[currentIndex] = completed;

            await API.patch(`task/${taskId}/subTask/${subTasks[currentIndex].id}`, subTaskUpdateDto).then((res) => {
              let updatedSubTasks = subTasks.slice();
              updatedSubTasks[currentIndex] = res.data.data;
              onUpdateSubTasks(updatedSubTasks);
              setChecked(newChecked);
              updateProgress(newChecked, updatedSubTasks);
            });
            if (completed) {
                showSuccessResultBar('Subtask marked as completed.');
            } else {
                showSuccessResultBar('Subtask marked as not completed.');
            }
          } catch (err) {
            Sentry.captureException(err);
            showErrorResultBar('There was an error while updating your subtask.');
          }
    };

    return (
        <>
        <List dense={true}>
        {subTasks.map((subtask, idx) => (
              subtask.id === subTaskIdEditing ?
                <SubTaskInput
                    subTask={subtask}
                    onCancelSubTask={() => setSubTaskIdEditing(undefined)}
                    onSaveSubTask={onAddSubTask}
                />
              :
                <SubTaskListItem
                  // key={subtask.id}
                  readOnly={readOnly}
                  subtask={subtask}
                  subtasks={subTasks}
                  // handleToggle={handleToggle(idx)}
                  taskStatus={taskStatus}
                  checked={checked[idx]}
                  assignees={assignees}
                  handleUpdateSubtask={handleUpdateSubtask(idx)}
                  setSubtaskToDelete={setSubTaskToDelete}
                  setSubtaskToComplete={setSubTaskToComplete}
                  setSubTaskIdEditing={setSubTaskIdEditing}
                />
         ))}
         </List>
        <Dialog
            open={subTaskToDelete !== undefined}
            onClose={closeDeleteDialog}
            >
            <DialogTitle>{'Are you sure you want to delete this subtask?'}</DialogTitle>
                <DialogActions>
                    <Button onClick={closeDeleteDialog} disabled={readOnly}>
                    Cancel
                    </Button>
                    <Button onClick={() => deleteSubTask(subTaskToDelete!)} disabled={readOnly}>
                    Delete
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
              open={subTaskToComplete !== undefined}
              onClose={closeDeleteDialog}
            >
            <DialogTitle>{'Approve as complete?'}</DialogTitle>
                <DialogContent>
                Are you sure you want to mark this subtask as complete? This action cannot be undone.
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeCompleteDialog} disabled={readOnly}>
                      Cancel
                    </Button>
                    <Button onClick={() => handleToggle(subTaskToComplete!)} disabled={readOnly}>
                      Mark Complete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
