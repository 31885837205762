import { Button, DialogActions, DialogContent, Grid, MenuItem, StandardProps, Typography } from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/styles';
import * as Sentry from '@sentry/browser';
import React, { useState } from 'react';
import API from '../../services/ApiService';
import { showErrorResultBar, showSuccessResultBar } from '../ResultSnackbar';
import StyledDialogTitle from '../StyledDialogTitle';
import ResponsiveDialog from './ResponsiveDialog';
import * as Yup from 'yup';
import { Formik } from 'formik';
import SaveButton from '../buttons/SaveButton';
import { FormGridRow, FormikTextField } from '../forms';
import { IOrgRBACJoinDto, IOrgShareUserAssigneeDto, IUserRBACDto} from '../../../../backend/src/org-share/interfaces';
import { IAvailableRoles } from '../OrgIncomingAccessDetails';
import { AxiosResponse } from 'axios';

const useStyles = makeStyles({
  dialogContent: {
    width: '600px',
  },
  dropdownItemEmail: {
    fontSize: '0.8rem',
  },
  gridStyle: {
    marginTop: '20px',
    paddingBottom: '20px',
  },
});

// This gets used as the 'value' for the 'Unassigned' dropdown choice.
const UNASSIGNED_ID = '';

export interface OrgShareUserAssignmentDialogProps extends StandardProps<DialogProps, 'children'> {
  users: IOrgShareUserAssigneeDto[];
  onClose: () => void;
  onUpdate: (orgRBACId: string, userIdx: number | null, apiResponse: AxiosResponse<any>, successMsg: string) => void;
  orgData: IOrgRBACJoinDto;
  roles: IAvailableRoles[];
  addUser: (newUser: IUserRBACDto) => void;
}

export default function AddOrgShareUserDialog(props: OrgShareUserAssignmentDialogProps) {
  const classes = useStyles();
  const { users, onClose, onUpdate, open, orgData, roles, addUser } = props;
  const [ roleDescription, setRoleDescription ] = useState<string>('');

  const userSchema = Yup.object().shape({
    user: Yup
      .mixed()
      .label('User')
      .oneOf(users.map(u => u.id), '')
      .required(),
    role: Yup
      .mixed()
      .label('Role')
      .oneOf(roles.map(r => r.id), '')
      .required(),
  });

  const clickAddUserRole = async (formValues: Record<string, any>) => {
    const userIdx = users.map(r => r.id).indexOf(formValues.user);
    let userName = '';
    if (userIdx > -1) {
        userName = users[userIdx].name || '';
    }

    const userRBAC = {
        orgRBACId: orgData.id,
        userName: userName,
        userId: formValues.user,
        roleId: formValues.role
    };

    if (formValues.user !== UNASSIGNED_ID) {
        try {
            const res = await API.post('org-share/user', userRBAC);
            onUpdate(orgData.id, null, res, 'User added');
            addUser(res.data.data);
            onClose();
            showSuccessResultBar('User added');
        } catch (err) {
            Sentry.captureException(err);
            showErrorResultBar('Unable to add user.');
        }
    }
  };

  return (
    <ResponsiveDialog
      disableBackdropClick={true}
      open={open}
      onClose={onClose}
    >
      <StyledDialogTitle
        onClose={onClose}
      >
        {`Grant User Access to ${orgData.fromOrg?.name}`}
      </StyledDialogTitle>
      <Formik
        enableReinitialize
        initialValues={{
          user: UNASSIGNED_ID,
          role: roles[0]?.id,
        }}
        validationSchema={userSchema}
        onReset={onClose}
        onSubmit={async (values, { setSubmitting }) => {
          await clickAddUserRole(values);
          setSubmitting(false);
        }}
      >
        {formikProps => (
          <>
            <DialogContent
              className={classes.dialogContent}
            >
              <FormGridRow
                  divider
                  title="Add User"
              >
              <Grid className={classes.gridStyle}>
                <FormikTextField
                  field="user"
                  formikProps={formikProps}
                  helperTextStr={'Please select a user to add'}
                  label="User Name"
                  select
                  fullWidth
                >
                    <MenuItem
                        value={UNASSIGNED_ID}
                    >
                    </MenuItem>
                    {users.map(currUser => (
                        <MenuItem
                            key={currUser.id}
                            value={currUser.id}
                        >
                            {currUser.name}
                            {currUser.name !== currUser.email &&
                                <span className={classes.dropdownItemEmail}>&nbsp;({currUser.email})</span>
                            }
                        </MenuItem>
                    ))}
                </FormikTextField>
              </Grid>
            </FormGridRow>
            {/*Role dropdown*/}
            <FormGridRow
                divider
                title="Assign Role"
            >
              <Grid className={classes.gridStyle}>
                <FormikTextField
                  field="role"
                  formikProps={formikProps}
                  helperTextStr={'Please select a user role'}
                  label="User Role"
                  select
                  fullWidth
                >
                    {/* <MenuItem
                        value={roles[0]}
                    >
                        No assigned role
                    </MenuItem> */}
                    {roles.map(r => (
                        <MenuItem
                            key={r.id}
                            value={r.id}
                            disabled={!r.allowed}
                        >
                            {r.name}
                        </MenuItem>
                    ))}
                </FormikTextField>
                <Typography variant="body2">
                  {roleDescription}
                </Typography>
              </Grid>
            </FormGridRow>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={formikProps.isSubmitting}
              onClick={formikProps.handleReset}
              color="primary"
            >
              Cancel
            </Button>
            <SaveButton
                  disabled={formikProps.isSubmitting || Object.values(formikProps.errors).filter(v => !!v).length > 0}
                  onClick={formikProps.handleSubmit}
                />
          </DialogActions>
        </>
        )}
      </Formik>
    </ResponsiveDialog>
  );
}
