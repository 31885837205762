import {
    Card,
    CardContent,
    CardHeader,
    createStyles,
    Grid,
    makeStyles,
    Typography,
  } from '@material-ui/core';
  import { DetailSectionHeading } from './MaterialityIssueDetails';
  import { SecondaryField } from './VendorDetailsInfo';
  import { IMaterialityAssessmentResponseIssuesDto } from '../../../backend/src/material-issue/interfaces/material-assessment-response-dto.interface';

  const useStyles = makeStyles((theme) =>
    createStyles({
      buttonLink: {
        color: 'inherit',
        textDecoration: 'underline',
        cursor: 'pointer',
        '&:hover': {
          color: theme.palette.primary.main,
        },
      },
      cardHeader: {
        paddingBottom: 0,
      },
      hidden: {
        display: 'none',
      },
      linkItem: {
        '& a': {
          color: theme.typography.body1.color,
          cursor: 'pointer',
        },
        '&:nth-child(n+2)': {
          paddingTop: '4px',
        },
        fontSize: '0.8125rem',
      },
      prependSmallIcon: {
        fontSize: '14px',
        height: '14px',
        marginRight: '5px',
        marginBottom: '-1px',
      },
      primaryColumn: {
        color: theme.typography.body1.color,
        fontSize: '14px',
      },
      referencesContainer: {
        paddingLeft: '1rem',
        paddingBottom: '0.8rem',
      },
      referenceResponse: {
        color: 'rgba(0,0,0,0.54)',
        whiteSpace: 'pre-line',
      },
      secondaryColumn: {
        backgroundColor: '#f5f5f5',
        opacity: '0.8',
        fontSize: '0.8125rem',
      },
      smallIconButton: {
        height: '14px',
        width: '14px',
        marginTop: '-6px',
        marginLeft: '0.5rem',
        '&:hover': {
          color: theme.palette.primary.main,
        },
      },
      switchField: {
        marginLeft: 0,
      },
      smallIconButtonIcon: {
        fontSize: '18px',
      },
      viewButton: {
        fontSize: '18px',
        textAlign: 'center',
        alignItems: 'center'
      },
      smallIconButtonLabel: {
        height: '0',
      },
      tagChip: {
      margin: '4px',
      color: theme.palette.primary.main,
      background: theme.palette.grey[200]
    },
    questionnaireDescription: {
      color: theme.typography.body1.color,
      fontSize: '14px',
    },
    })
  );

  export interface MaterialityAssessmentIssuesDetailsProps {
    issueDetails: IMaterialityAssessmentResponseIssuesDto;
    allIssues: IMaterialityAssessmentResponseIssuesDto[];
  }

  function MaterialityAssessmentIssuesDetails(props: MaterialityAssessmentIssuesDetailsProps) {
    const {
      issueDetails,
      allIssues
    } = props;

    const allScores = () => {

        const scoreList = [];
        for (const filter of Object.keys(issueDetails.filters)) {
            for (const option of Object.keys(issueDetails.filters[filter])) {
              if (issueDetails.filters[filter][option]) {
                scoreList.push(
                  <SecondaryField
                      title={option + ' Score:'}
                      value={issueDetails.filters[filter][option] ? Math.round((issueDetails.filters[filter][option] || 0)*100)/100  : 'N/A' }
                  />
                );
              }
            }
        };
        return scoreList;
    };

    const issueRank = () => {

        const rankList = [];
        const allOrderedList = allIssues.map((issue) => issue.all).sort((a: number, b: number) => (a < b) ? 1 : -1);
        rankList.push(
            <>
              Overall Issue Rank: #{allOrderedList.findIndex((score) => score === issueDetails.all) + 1}
              <br />
            </>
        );

        for (const filter of Object.keys(issueDetails.filters)) {
            for (const option of Object.keys(issueDetails.filters[filter])) {
                const orderedList = allIssues.map((issue) => issue.filters[filter][option]).sort((a: number | null, b: number | null) => ((a || 0) < (b || 0)) ? 1 : -1);
                if (issueDetails.filters[filter][option]) {
                  rankList.push(
                      <>
                        {option} Issue Rank: {issueDetails.filters[filter][option] ? `#${orderedList.findIndex((score) => score === issueDetails.filters[filter][option]) + 1}` : ' N/A'}
                        <br />
                      </>
                  );
                }
            }
        };
        return rankList;
    };

    const classes = useStyles();

    return (
      <>
      <Card>
        <CardHeader
          className={classes.cardHeader}
          title={issueDetails.info.name}
          subheader={issueDetails.info.category + ' Issue '}
        />
        <CardContent>
          <Grid container alignItems="flex-start" spacing={4}>
            <Grid item xs={12} sm={8} md={9} className={classes.primaryColumn}>
                {issueDetails.info.description ? (
                 <>
                <Typography
                    className={classes.questionnaireDescription}
                >
                    {issueDetails.info.description}
                </Typography>
                <br />
                </>
                ) : (
                 <></>
                )}
                  <DetailSectionHeading>Issue Ranking</DetailSectionHeading>
                  <div className={classes.referencesContainer}>
                    {issueRank()}
                  </div>
              </Grid>
            {/* Secondary column */}
            <Grid
              item
              xs={12}
              sm={4}
              md={3}
              className={classes.secondaryColumn}
            >
              {/* Links */}
              <SecondaryField
                  title="Overall Score:"
                  value={Math.round(issueDetails.all*100)/100}
              />
              {allScores()}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      </>
    );
  }

  export default MaterialityAssessmentIssuesDetails;
