import { createStyles, StyledComponentProps, withStyles } from '@material-ui/core';
import React from 'react';
import esgReport from '../images/esg_report_2.png';

const styles = createStyles({
  img: {
    width: '100%',
    maxWidth: '700px',
  },
});

function ESGReportImage({ classes }: StyledComponentProps) {
  return (
    <img className={classes!.img} src={esgReport} alt="" />
  );
}

export default withStyles(styles, { name: 'ESGReportImage' })(ESGReportImage);
