import React from 'react';
import Typography from '@material-ui/core/Typography';

function TheVerifyEmailPage() {
  return (
    <>
      <Typography variant="h6">
        Check your email for a verification token.
        You'll need to verify your email address before logging in.
      </Typography>
    </>
  );
}

export default TheVerifyEmailPage;
