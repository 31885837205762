import { Button, DialogActions, DialogContent, StandardProps } from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import { Formik } from 'formik';
import * as React from 'react';
import * as Yup from 'yup';
import { INetworkScanTargetDto, INetworkScanTargetUpdateDto } from '../../../../backend/src/network-scan/interfaces';
import { SaveButton } from '../buttons';
import { FormikSwitchField, FormikTextField } from '../forms';
import StyledDialogTitle from '../StyledDialogTitle';
import ResponsiveDialog from './ResponsiveDialog';

const NetworkScanTargetSchema = Yup.object().shape({
  addresses: Yup
    .array()
    .transform(function (value, originalValue) {
      if (this.isType(value) && value !== null) {
        return value;
      }

      return originalValue ? originalValue.split(/[\s,]+/).filter((address: string) => !!address) : [];
    })
    .of(Yup.string().required())
    .required('- At least one address is required.'),
  name: Yup
    .string()
    .trim()
    .required('- Required'),
  notify: Yup
    .boolean()
    .required(),
});

interface IFormValues extends Omit<INetworkScanTargetUpdateDto, 'addresses'> {
  addresses: string;
}

const createUploadData = (formValues: IFormValues): INetworkScanTargetUpdateDto => {
  const allAddresses: string[] = formValues.addresses.split(/[\s,]+/).filter(address => !!address) || [];

  return {
    ...formValues,
    addresses: [...(new Set(allAddresses))],
  };
};

export interface NetworkScanRequestDialogProps extends StandardProps<DialogProps, 'children'> {
  onClose: () => void;
  onSave: (updatedTarget: INetworkScanTargetUpdateDto) => Promise<void>;
  targetData: INetworkScanTargetDto | null;
}

export default function NetworkScanTargetDialog({ onClose, onSave, open, targetData }: NetworkScanRequestDialogProps) {
  return (
    <ResponsiveDialog
      disableBackdropClick
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
    >
      <StyledDialogTitle onClose={onClose}>
        {targetData === null ? 'Create new scan target' : 'Edit scan target details'}
      </StyledDialogTitle>
      <Formik
        enableReinitialize
        initialValues={{
          addresses: targetData?.addresses.join('\n') ?? '',
          name: targetData?.name ?? '',
          notify: targetData?.notify ?? true,
        }}
        validationSchema={NetworkScanTargetSchema}
        onReset={onClose}
        onSubmit={async (values, { setSubmitting }) => {
          await onSave(createUploadData(values));
          setSubmitting(false);
        }}
      >
        {formikProps => (<>
          <DialogContent>
            <FormikTextField
              autoFocus
              field="name"
              formikProps={formikProps}
              helperTextStr="Name which identifies this target list"
              label="Target name"
              required
            />
            <FormikTextField
              field="addresses"
              formikProps={formikProps}
              helperTextStr="Please enter a comma separated list of host addresses or one per line"
              label="Host addresses"
              multiline
              placeholder="example.com"
              rows={4}
              required
            />
            <FormikSwitchField
              field="notify"
              formikProps={formikProps}
              helperTextStr="Send a notification when the scan starts"
              label="Notify"
            />
          </DialogContent>
          <DialogActions>
            <Button
              disabled={formikProps.isSubmitting}
              onClick={formikProps.handleReset}
              color="primary"
            >
              {targetData === null ? 'Cancel' : 'Close'}
            </Button>
            <SaveButton
              disabled={formikProps.isSubmitting || Object.keys(formikProps.errors).length > 0}
              onClick={formikProps.handleSubmit}
            />
          </DialogActions>
        </>)}
      </Formik>
    </ResponsiveDialog>
  );
}
