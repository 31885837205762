import { StandardProps } from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import * as Sentry from '@sentry/browser';
import React from 'react';
import { IDocument } from '../../../../backend/src/document/interfaces/document.interface';
import { IPolicyDocDto } from '../../../../backend/src/policy-doc/interfaces';
import { errorParser } from '../../helpers';
import API from '../../services/ApiService';
import { showErrorResultBar, showSuccessResultBar } from '../ResultSnackbar';
import { FileUploadDialog } from '.';

export interface UploadCustomPolicyDialogProps extends StandardProps<DialogProps, 'children'> {
  onClose: () => void;
  onUpdate: (newData: IPolicyDocDto) => void;
  policy: IPolicyDocDto;
}

export default function UploadCustomPolicyDialog({ onClose, onUpdate, open, policy }: UploadCustomPolicyDialogProps) {
  const handleUploadCustomPolicy = async (newPolicyDoc: IDocument[]) => {
    for (const doc of newPolicyDoc) {
      try {
        const res = await API.patch(`policyDoc/${policy.versionId}/upload/${doc.id}`);
        onUpdate(res.data.data);
        showSuccessResultBar('Custom policy successfully uploaded');
      } catch (err) {
        showErrorResultBar(errorParser(err, 'Unexpected error while uploading custom policy'));
        Sentry.captureException(err);
      }
    }
  };

  return (
    <FileUploadDialog
      dialogHeaderText="Upload custom policy"
      documentCategory="custom_policy"
      onAddDocuments={handleUploadCustomPolicy}
      onClose={onClose}
      open={open}
      currentDocuments={[]}
    />
  );
}
