import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
 } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import React, { useState } from 'react';
import { ICommentDto } from '../../../backend/src/comment/interfaces';
import Auth from '../services/AuthService';
import DeleteButton from './buttons/DeleteButton';

const useStyles = makeStyles({
  commentText: {
    color: 'rgba(0, 0, 0, 0.87);',
    whiteSpace: 'pre-line',
  },

  header: {
    margin: 0,
    padding: 0,
  },
});

export const COMMENT_TIMESTAMP_FORMAT = 'dddd, MMMM Do, YYYY, h:mm a';

function CommentSubheader({ comment }: { comment: ICommentDto }) {
  const isEdited = moment(comment.updatedAt).isAfter(comment.createdAt, 'second');

  return (
    <span>
      <Tooltip
        title={moment(comment.createdAt).format(COMMENT_TIMESTAMP_FORMAT)}
      >
       <span>commented {moment(comment.createdAt).fromNow()}</span>
      </Tooltip>
      { isEdited && (
        <Tooltip
          title={`Edited ${moment(comment.updatedAt).format(COMMENT_TIMESTAMP_FORMAT)}`}
        >
        <span> (edited)</span>
        </Tooltip>
      )}
    </span>
  );
}

export interface CommentDetailsProps {
  auth: Auth;
  comment: ICommentDto;
  onClickDelete: () => Promise<void>;
  onClickEdit: () => void;
  readOnly?: boolean;
}

export default function CommentDetails({ auth, comment, onClickDelete, onClickEdit, readOnly = true }: CommentDetailsProps) {
  const classes = useStyles();
  const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null);
  const [ toShowDeleteDialog, setToShowDeleteDialog] = useState(false);

  function openEditMenu(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function closeEditMenu() {
    setAnchorEl(null);
  }

  function openDeleteDialog() {
    closeEditMenu();
    setToShowDeleteDialog(true);
  }

  function closeDeleteDialog() {
    setToShowDeleteDialog(false);
  }

  async function onClickConfirmDelete() {
    await onClickDelete();
  }

  return (
    <>
      <Card>
        <CardHeader
          className={classes.header}
          action={
            auth.getUserId() === comment.authorId && (
              <IconButton
                aria-label="more"
                aria-controls="edit-menu"
                aria-haspopup="true"
                onClick={openEditMenu}
              >
                <MoreVertIcon />
              </IconButton>
            )
          }
          title={comment.authorName}
          titleTypographyProps={{
            variant: 'subtitle2',
          }}
          subheader={<CommentSubheader comment={comment} />}
          subheaderTypographyProps={{
            variant: 'caption',
          }}
        />
        <Menu
          id="edit-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={closeEditMenu}
        >
          <MenuItem onClick={onClickEdit} disabled={readOnly}>Edit</MenuItem>
          <MenuItem onClick={openDeleteDialog} disabled={readOnly}>Delete</MenuItem>
        </Menu>
        <CardContent>
          <Typography
            variant="body2"
            className={classes.commentText}
          >
            {comment.text}
          </Typography>
        </CardContent>
      </Card>
      {/* Delete comment confirmation dialog */}
      <Dialog
        open={toShowDeleteDialog}
        onClose={closeDeleteDialog}
      >
        <DialogTitle>Are you sure you want to delete this comment?</DialogTitle>
        <DialogActions>
          <Button
            color="primary"
            size="small"
            onClick={closeDeleteDialog}
          >
            Cancel
          </Button>
          <DeleteButton
            onClick={onClickConfirmDelete}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}
