import React, { useState } from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  StandardProps,
  Chip
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import { DialogProps } from '@material-ui/core/Dialog';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { IDocument } from '../../../../backend/src/document/interfaces';
import API from '../../services/ApiService';
import { SaveButton } from '../buttons';
import {  FormikTextField } from '../forms';
import { showSuccessResultBar } from '../ResultSnackbar';
import StyledDialogTitle from '../StyledDialogTitle';
import ResponsiveDialog from './ResponsiveDialog';
import { formatFileTitle, getFileExtension } from '../../helpers';
import { handleError } from '../../helpers';

export const HELPER_TEXT = {
    name: 'The name of your file as you want displayed (does not have to match the uploaded file name).',
    description: 'Optional field to describe this file.',
    category: 'The document type.',
    tags: 'Select from existing tags or create a new tag. To create a new tag, you must hit ENTER.',
  };

export interface DocumentEditDialogProps extends StandardProps<DialogProps, 'children'> {
  documentData: IDocument | null;
  onClose: () => void;
  onUpdate: (doc: IDocument) => void;
  allDocTags: string[];
  allDocNames: string[];
}

export default function DocumentEditDialog({ documentData, onClose, onUpdate, open, allDocTags, allDocNames }: DocumentEditDialogProps) {
    const [tags, setTags] = useState((documentData?.documentTags || []).map(t => t.name));

    const DocSchema = Yup.object().shape({
      name: Yup
      .string()
      .trim()
      .matches(
          /^[^<>^:;,?"*|/.]+$/,
          'Filename cannot contain certain special characters'
        )
      .test('name', 'This name already exists, please choose another.', function (name) {
          return fileNameExists(name);
      })
      .required('- Required'),
      description: Yup
        .string()
        .nullable(),
      id: Yup
        .string(),
      //tag: Yup.array().of(Yup.string()).max(255).nullable()
    });

    const fileNameExists = (fileName: string | undefined) => {
      if (fileName) {
        const fullFileName = fileName + '.' + getFileExtension(documentData?.name || '');
        return (!allDocNames.includes(fullFileName)) || (fullFileName === documentData?.name);
      } else {
        return true;
      }
    };

    const handleSave = async (formValues: any, tags: string[]) => {
      //AG
    try {
        if (documentData) {
            let updateDocument = {
                ...formValues,
                tagNames: tags,
            };

            const res = await API.patch(`document/${documentData.id}`, updateDocument);
            onUpdate(res.data?.data);
            showSuccessResultBar('Document updated successfully');
        } else {onClose();}

    } catch (err) {
      handleError(err, 'Error saving document information');
    }
    onClose();
  };

  return (
    <ResponsiveDialog
      disableBackdropClick
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
    >
        <StyledDialogTitle onClose={onClose}>
         {'Edit Document Data'}
        </StyledDialogTitle>
      <Formik
        enableReinitialize
        initialValues={{
          category: documentData?.category || 'evidence',
          description: documentData?.description || '',
          name: formatFileTitle(documentData?.name || ''),
          status: documentData?.status || 'active',
          extension: getFileExtension(documentData?.name || '')
        }}
        validationSchema={DocSchema}
        onReset={onClose}
        onSubmit={async (values, { setSubmitting }) => {
          await handleSave(values, tags);
          setSubmitting(false);
        }}
      >
        {formikProps => (
          <>
            <DialogContent>
              {/* 'Risk identification' section */}
              <FormikTextField
                    autoFocus
                    field="name"
                    formikProps={formikProps}
                    helperTextStr={HELPER_TEXT.name}
                    label="File Name"
                    required
                  />
                  <FormikTextField
                    autoFocus
                    field="description"
                    formikProps={formikProps}
                    helperTextStr={HELPER_TEXT.description}
                    label="Description"
                  />
                <Autocomplete
                    multiple
                    id="tags-filled"
                    options={allDocTags.map((option) => option).sort()}
                    value={tags}
                    freeSolo
                    renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                    ))
                    }
                    onChange={(e, value) => {setTags(value);}}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="filled"
                        label="Tags"
                        helperText={HELPER_TEXT.tags}
                    />
                )}
            />
            </DialogContent>
            <DialogActions>
              <Button
                disabled={formikProps.isSubmitting}
                onClick={formikProps.handleReset}
                color="primary"
              >
                {documentData === null ? 'Cancel' : 'Close'}
              </Button>
              <SaveButton
                //disabled={formikProps.isSubmitting || Object.values(formikProps.errors).filter(v => !!v).length > 0}
                onClick={formikProps.handleSubmit}
              />
            </DialogActions>
          </>
        )}
      </Formik>
    </ResponsiveDialog>
  );
}
