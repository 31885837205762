import {
    FormControl,
    FormControlLabel,
    FormHelperText,
    Select,
    MenuItem,
    InputLabel
  } from '@material-ui/core';
  import { FormControlProps } from '@material-ui/core/FormControl';
  import { makeStyles } from '@material-ui/styles';
  import { FormikProps, FormikValues } from 'formik';
  import React from 'react';

  const useStyles = makeStyles({
    switchField: {
      marginLeft: 0,
      marginRight: 0,
    },
  });

  interface FormikSelectFieldProps<Values extends FormikValues>
    extends FormControlProps {
    field: keyof Values;
    formikProps: FormikProps<Values>;
    helperTextStr?: string;
    label: string;
    options: string[];
  }

  export default function FormikSelectField<Values extends FormikValues>(
    props: FormikSelectFieldProps<Values>
  ) {
    const classes = useStyles();
    const {
      field,
      formikProps,
      helperTextStr,
      label,
      variant = 'filled',
      options,
      ...otherFormControlProps
    } = props;
    const { values, handleChange } = formikProps;
    const fieldStr = field.toString();

    return (
      <FormControl
        className={classes.switchField}
        variant={variant as any}
        {...otherFormControlProps}
      >
          <InputLabel
            //variant="standard"
          >
                {label}
            </InputLabel>
            <Select
            id={fieldStr}
            name={fieldStr}
            value={values[field]}
            onChange={handleChange}
            fullWidth
          >
            {options.map((item: any) => (
              <MenuItem value={item} key={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        {helperTextStr ? <FormHelperText>{helperTextStr}</FormHelperText> : null}
      </FormControl>
    );
  }
