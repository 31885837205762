import axios from 'axios';
import API from './ApiService';
import { IDocument } from '../../../backend/src/document/interfaces/document.interface';
import { IUploadPathDto } from '../../../backend/src/document/interfaces/upload-path-dto.interface';
import { showInfoResultBar } from '../components/ResultSnackbar';
import { FileWithPath } from 'react-dropzone';

//
// Download helpers
//

function initiateDownload(url: string, newWindow: boolean = false) {
  const link = document.createElement('a');

  link.href = url;
  if (newWindow) {
    link.setAttribute('target', '_blank');
  }

  document.body.appendChild(link);
  link.click();
  link.remove();
}

async function getPresignedDocumentUrl(apiEndpoint: string) {
  // Allow this to throw, else the 'initiateDownload' attempts to open a new window with a bad result.
  const { data } = await API.get(apiEndpoint);

  return (data.data && data.data.url) || data;
}

async function getUrlWithoutDownload(
  apiEndpoint: string,
  newWindow: boolean = false
) {
  // TODO: Add error handling to callers of document download.
  let url;
  await getPresignedDocumentUrl(apiEndpoint).then((response) => {
    url = response;
  });
  return url;
}

async function getUrlAndDownload(
  apiEndpoint: string,
  newWindow: boolean = false
) {
  // TODO: Add error handling to callers of document download.
  const url = await getPresignedDocumentUrl(apiEndpoint);
  initiateDownload(url, newWindow);
}

export async function downloadEvidencePDF(id: string) {
  return getUrlWithoutDownload(`document/${id}/download`, true);
}

export async function downloadPolicyPDF(id: string) {
  return getUrlWithoutDownload(`policyDoc/${id}/download`, true);
}

export async function downloadDocumentPDF(id: string) {
  return getUrlWithoutDownload(`document/${id}/download`, true);
}

export async function downloadDocumentVersionPDF(id: string) {
  return getUrlWithoutDownload(`document/${id}/download?version`, true);
}

export async function downloadResourcePDF(id: string) {
  return getUrlWithoutDownload(`document/template/${id}/download`, true);
}

export async function viewResourcePDF(id: string) {
  return getUrlWithoutDownload(`document/template/${id}/view`, true);
}

export async function downloadDocument(id: string) {
  return getUrlAndDownload(`document/${id}/download`, true);
}

export async function downloadDocumentVersion(id: string) {
  return getUrlAndDownload(`document/${id}/download?version`, true);
}

export async function downloadDocumentTemplate(id: string) {
  return getUrlAndDownload(`document/template/${id}/download`, true);
}

export const documentDownloadVersionHandler = (documentVersionId: string) => () =>
  downloadDocumentVersion(documentVersionId);

export const documentDownloadHandler = (documentId: string) => () =>
  downloadDocument(documentId);

export const documentManagementDownload = (documentId: string) => {
  downloadDocument(documentId);
};

export const documentTemplateDownloadHandler =
  (documentTemplateId: string) => () => {
    return downloadDocumentTemplate(documentTemplateId);
  };

export async function downloadMarketingDocument(docKey: string) {
  return getUrlAndDownload(`marketingBundle/${docKey}/download`);
}

export const marketingBundleDocDownloadHandler = (docKey: string) => () => {
  showInfoResultBar(
    'Preparing your document. It should start downloading soon.'
  );

  return downloadMarketingDocument(docKey);
};

export async function downloadPolicy(id: string) {
  return getUrlAndDownload(`policyDoc/${id}/download`);
}

//
// Upload helpers
//

export const uploadDocument = async (
  file: File,
  uploadPath: IUploadPathDto,
  onUploadProgress?: (progress: number) => void
): Promise<IDocument> => {
  return API.post('document/create', uploadPath)
    .then(({ data }) => sendAwsPresignedPost(file, data, onUploadProgress))
    .then(getKeyFromAwsResponseXML)
    .then(activateFile)
    .then(({ data }) => data);
};

export const uploadDocumentVersion = async (
  file: File,
  uploadPath: IUploadPathDto,
  docId: string,
  onUploadProgress?: (progress: number) => void,
): Promise<IDocument> => {
  return API.post(`document/${docId}/version/create`, uploadPath)
    .then(({ data }) => sendAwsPresignedPost(file, data, onUploadProgress))
    .then(getKeyFromAwsResponseXML)
    .then(activateFile)
    .then(({ data }) => data);
};

// TODO: Improve the AWS types in the following functions.
const getKeyFromAwsResponseXML = (responseXML: any) => {
  return responseXML.documentElement.getElementsByTagName('Key')[0].textContent;
};

const initPresignedFormData = (fields: { [key: string]: any }, file: File) => {
  const formData = new FormData();

  Object.entries(fields).forEach(([field, value]) => {
    formData.append(field, value);
  });

  formData.append('file', file);

  return formData;
};

const sendAwsPresignedPost = async (
  file: File,
  { url, fields }: { url: string; fields: { [key: string]: any } },
  onUploadProgress?: (progress: number) => void
) => {
  const formData = initPresignedFormData(fields, file);
  return axios
    .post(url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress: (progressEvent: any) => {
        //TODO: Fix the type of progressEvent
        if (onUploadProgress && progressEvent.lengthComputable) {
          onUploadProgress((progressEvent.loaded / progressEvent.total) * 100);
        }
      },
    })
    .then((res) => {
      if (onUploadProgress) {
        onUploadProgress(100);
      }

      return res.request && res.request.responseXML;
    })
    .catch((err) => {
      if (onUploadProgress) {
        onUploadProgress(0);
      }

      throw err;
    });
};

const activateFile = async (objectKey: string) => {
  return API.put('document/activate', {
    objectKey,
  });
};
