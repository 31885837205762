import {
    TableCell,
    TableRow,
    withStyles,
    WithStyles,
    createStyles,
    Theme,
    createTheme,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    Grid
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { IInboundQuestionnaireDto } from '../../../backend/src/inbound-questionnaire/interfaces';
import SpioDataTable, {
  SpioDataTableColumn,
} from '../components/SpioDataTable';

const styles = (theme: Theme) =>
  createStyles({
    [theme.breakpoints.up('md')]: {
      commentCell: {
        textAlign: 'center',
      },
      commentCellText: {
        paddingLeft: '2px',
      },
      title: {
        marginTop: '16px',
        marginBottom: '16px',
      },
    },
});

  const getTableColumns = (tableData: ITableDatum[], columnFilters: string[] | []): SpioDataTableColumn[] => {
    let columns =
    [
        {
            name: 'id',
            label: 'ID',
            options: {
                display: false,
                filter: false,
            },
        },
        {
          name: 'response',
          label: 'Response',
          options: {
            display: true,
            filter: true,
        },
        },
      ];
      if (columnFilters.length > 0) {
        for (const filter of columnFilters) {
          columns.push({
              name: filter,
              label: filter,
              options: {
                  display: true,
                  filter: true
              },
          });
        }
    };
     return columns;
};

interface MaterialityAssessmentFreeResponseTableProps extends WithStyles<typeof styles> {
  filterOptions: {[key: string]: string}[] | [];
  freeResponses: {[key: string]: string}[][] | [];
}

interface ITableDatum extends IInboundQuestionnaireDto {
  category: string;
  name: string;
}

function MaterialityAssessmentFreeResponseTable(props: MaterialityAssessmentFreeResponseTableProps) {
  const {
    filterOptions,
    freeResponses,
    classes
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState<ITableDatum[]>([]);
  const [question, setQuestion] = React.useState('');
  const [allQuestions, setAllQuestions] = useState<string[]>([]);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newSelection = event.target.value as string;
    setQuestion(newSelection);
  };

  useEffect(() => {
    // get unique sete of questions that have responses
    const questionsSet = freeResponses.flat(2).filter((elem) => {return elem.response !== '';}).map((response) => response.question);
    setAllQuestions(questionsSet.filter((item: string, i: number, ar: string[]) => ar.indexOf(item) === i));
  }, [ freeResponses ]);

  useEffect(() => {
    const allData = freeResponses ? freeResponses.flat(2).filter((elem) => {return elem.response !== '';}).filter((elem) => {return elem.question === question;}): [];
    setTableData(allData.map((d) => Object({
      ...d,
    })));
  }, [ freeResponses, question ]);

  // get unique sete of all filter categories
  const columnFilters = () => filterOptions.map(filters => filters.filter).filter((item: string, i: number, ar: string[]) => ar.indexOf(item) === i);

  return (
    <>

      <SpioDataTable
        title={<Grid className={classes.title}><Typography variant="h6">Responses to Question: <br/></Typography>      <FormControl fullWidth>
        <InputLabel id="question-select">Select Question</InputLabel>
        <Select
          labelId="question-select"
          id="question-select"
          value={question}
          defaultValue={allQuestions[0]}
          label="Question"
          onChange={handleChange}
        >
          {allQuestions.map(q =>
              <MenuItem value={q} key={q}>{q}</MenuItem>
            )}
        </Select>
      </FormControl></Grid>}
        columns={getTableColumns(tableData, columnFilters())}
        data={tableData}
        options={{
          print: false,
          filterType: 'multiselect',
          selectableRows: 'none',
          textLabels: {
            body: {
              noMatch: isLoading ? 'Loading...' : 'No Question Selected',
              toolTip: 'Sort',
            },
          },
          expandableRows: false,
          expandableRowsOnClick: false,
          expandableRowsHeader: false,
        }}
      />
    </>
  );
}

export default withStyles(styles, { withTheme: true })(MaterialityAssessmentFreeResponseTable);
