import { Card, CardContent, Typography } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { LinedCardHeader } from './cards';

export interface ISubscriptionPlanDetails {
  id: number;
  name: string;
  tier: number;
  price: string;
  subheader?: string;
  items: Array<string | string[]>;
}

export const SUBSCRIPTION_PLANS: ISubscriptionPlanDetails[] = [
  {
    id: 1,
    name: 'Free',
    tier: 1,
    price: 'Trial',
    subheader: 'Your ESG Program starts here.',
    items: [
      'Management Dashboard',
      'Standards-Aware Tasks, e.g.',
      ['SASB', 'TCFD'],
      'Vetted Policy Templates',
      'Employee and Management Training',
      ['ESG Awareness', 'Standards & Regulations', 'Policy Training', 'Materiality'],
      'Important Document Templates',
      'Risk Registry',
      'Email Support',
    ],
  },
  {
    id: 2,
    name: 'Startup',
    tier: 3,
    price: '(up to 10 employees)',
    subheader: 'Your ESG Program starts here.',
    items: [
      'Management Dashboard',
      'Standards-Aware Tasks, e.g.',
      ['SASB', 'TCFD'],
      'Vetted Policy Templates',
      'Employee and Management Training',
      ['ESG Awareness', 'Standards & Regulations', 'Policy Training', 'Materiality'],
      'Important Document Templates',
      'Risk Registry',
      'Email Support',
    ],
  },
  {
    id: 3,
    name: 'Medium',
    tier: 5,
    price: '(up to 50 employees)',
    subheader: 'Everything in Startup, plus...',
    items: [
      'ESG Maturity Dashboard',
      'Stakeholder Engagement Management',
      ['Managed Surveys', 'Stakeholder Categories', 'Key Metrics'],
      'Additional Standards, e.g.',
      ['UNPRI', 'GRI', 'GRESB'],
      'Email & Slack Support',
    ],
  },
  {
    id: 4,
    name: 'Enterprise',
    tier: 7,
    price: '(up to 100 employees)',
    subheader: 'Everything in Platform, plus...',
    items: [
      'Gemini Manages your Implementation',
      'Gemini Assists With',
      [
        'Strategic Planning',
        'Management Progress Report',
        'Inbound Questionnaire and Survey Requests',
        'Stakeholder Engagement Process',
      ],
      'Report Preparation and Review',
      'Policy Review',
      'Implementation Guidance',
      'Discounted Carbon Accounting and Emissions Forecasting',
    ],
  },
];

const useStyles = makeStyles({
  clickable: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  card: {
    margin: 0,
    padding: 0,
    height: '100%',
  },
  cardContent: {
    padding: '0.5rem',
    margin: '0.5rem',
  },
  cardItemList: {
    paddingLeft: '0.5rem',
    listStylePosition: 'inside',
  },
  cardItemSubList: {
    paddingLeft: '1rem',
    listStylePosition: 'inside',
  },
  cardItem: {
    display: 'list-item',
    fontSize: '1.15em',
  },
});

export interface SubscriptionPlanCardProps {
  isEditing: boolean;
  isSelected: boolean;
  onClick?: () => void;
  plan: ISubscriptionPlanDetails;
}

export default function SubscriptionPlanCard({ isEditing, isSelected, onClick, plan }: SubscriptionPlanCardProps) {
  const classes = useStyles();
  const [isRaised, setIsRaised] = useState<boolean>(false);
  const [elevation, setElevation] = useState<number>(1);

  useEffect(() => {
    if (isRaised) {
      setElevation(10);
    } else if (isEditing) {
      setElevation(3);
    } else if (isSelected) {
      setElevation(10);
    } else {
      setElevation(1);
    }
  }, [isEditing, isSelected, isRaised]);

  return (
    <Card
      className={classNames(
        classes.card,
        isEditing && classes.clickable,
      )}
      elevation={elevation}
      onMouseOver={() => setIsRaised(isEditing && true)}
      onMouseOut={() => setIsRaised(false)}
      onClick={onClick}
    >
      <LinedCardHeader
        title={plan.name}
        subheader={plan.price}
        action={isSelected &&
          <CheckBoxIcon
            fontSize="large"
            color="secondary"
          />
        }
      />
      <CardContent className={classes.cardContent}>
        <Typography
          variant="body2"
          color="textPrimary"
          component="p"
        >
          {plan.subheader}
        </Typography>
        <ul className={classes.cardItemList}>
          {plan.items.map((planItem, idx) => Array.isArray(planItem) ? (
            <ul
              key={idx}
              className={classes.cardItemSubList}
            >
              {planItem.map(planSubItem => (
                <Typography
                  key={planSubItem}
                  className={classes.cardItem}
                  variant="body2"
                  color="textPrimary"
                  component="li"
                >
                  {planSubItem}
                </Typography>
              ))}
            </ul>
          ) : (
            <Typography
              key={idx}
              className={classes.cardItem}
              variant="body2"
              color="textPrimary"
              component="li"
            >
              {planItem}
            </Typography>
          ))}
        </ul>
      </CardContent>
    </Card>
  );
}
