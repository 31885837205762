import { Tab, Tabs, Tooltip } from '@material-ui/core';
import * as Sentry from '@sentry/browser';
import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { TrainingViewStatus } from '../../../backend/src/training/enums';
import { ITrackViewStart, ITrainingVideoViewDto, ITrainingViewDto, ITrainingViewUpdateDto } from '../../../backend/src/training/interfaces';
import { VideoDialog } from '../components/dialogs';
import { showErrorResultBar } from '../components/ResultSnackbar';
import TrainingVideoLists from '../components/TrainingVideoLists';
import { TrainingCoverageChart } from '../components/widgets';
import API from '../services/ApiService';
import Auth from '../services/AuthService';
import { handleError } from '../helpers';

export interface TheTrainingPageProps extends RouteComponentProps<{ vid?: string }> {
  auth: Auth;
}

function TheTrainingPage({ auth, history, match }: TheTrainingPageProps) {
  const userVid = match.params.vid;
  const [videos, setVideos] = useState<ITrainingVideoViewDto[]>([]);
  const [selectedVideo, setSelectedVideo] = useState<ITrainingVideoViewDto | null>(null);
  const [selectedTabIdx, setSelectedTabIdx] = useState(0);
  const [videoUrl, setVideoUrl] = useState<string | null>(null); // The truthiness of the url opens/closes the modal.
  const [videoView, setVideoView] = useState<ITrainingViewDto | null>(null); // The truthiness of the url opens/closes the modal.

  // Fetch the videos when first navigating to this page.
  useEffect(() => {
    API.get('training')
      .then(res => setVideos(res.data?.data ?? []))
      .catch((err) => {
        showErrorResultBar('Unexpected error loading videos');
        Sentry.captureException(err);
      });
  }, []);

  // Select the video based on the :vid url parameter.
  useEffect(() => {
    const video: ITrainingVideoViewDto | undefined = userVid ? videos.find(v => v.videoId === userVid) : undefined;
    setSelectedVideo(video ?? null);
  }, [videos, userVid]);

  // Once a video is selected, get its url and view tracking info.
  const handleStartVideo = useCallback(async () => {
    if (selectedVideo) {
      try {
        const videoId = selectedVideo.videoId;
        const trackViewInfo: ITrackViewStart = (await API.post(`training/${videoId}/view`))?.data?.data;

        setVideoUrl(trackViewInfo.url);
        setVideoView(trackViewInfo.view);
      } catch (err) {
        Sentry.captureException(err);
        showErrorResultBar('Something went wrong obtaining a link for the video. Please try again later.');
        setVideoUrl(null);
        setVideoView(null);
      }
    } else {
      setVideoUrl(null);
      setVideoView(null);
    }
  }, [selectedVideo]);

  useEffect(() => {
    handleStartVideo();
  }, [handleStartVideo]);

  // Handle the case where the video link expires and we need to reset:
  const handlePlaybackError = (error: any) => {
    Sentry.captureException(error);
    handleStartVideo();
  };

  // The modal will close once selectedVideo is set to 'undefined'.
  const handleCloseVideo = () => {
    history.push('/training');
  };

  // The modal will open after the selectedVideo is set.
  const handleOpenVideo = (video: ITrainingVideoViewDto) => () => {
    history.push(`/training/${video.videoId}`);
  };

  const updateDisplayedVideoViewStatus = async (videoId: string, status: TrainingViewStatus) => {
    const idx = videos.findIndex(v => v.videoId === videoId);
    if (idx >= 0) {
      const updatedVideos = videos.slice();
      updatedVideos[idx].status = status;
      setVideos(updatedVideos);
    }
  };

  const handleUpdateVideo = async (updateInfo: ITrainingViewUpdateDto) => {
    try {
      if (selectedVideo && videoView) {
        const res = await API.patch(`training/view/${videoView.id}`, updateInfo);
        const updatedStatus: TrainingViewStatus = res?.data?.data;
        updateDisplayedVideoViewStatus(selectedVideo.videoId, updatedStatus);
      }
    } catch (err) {
      handleError(err, 'Unexpected error updating video view');

      // If the auth token has expired then tracking updates won't work. Ask the user to refresh their browser.
      const axiosError = err as any;
      if (axiosError.response?.data?.statusCode === 401) {
        handleCloseVideo();
        showErrorResultBar('Something went wrong during video playback. You may need to refresh your browser.');
      }
    }
  };

  return <>
    {auth.isGranted(TrainingCoverageChart.requiredAuthZ) ? (<>
      {/* Training Manager view */}
      <Tabs
        value={selectedTabIdx}
        onChange={(e, tabIdx) => setSelectedTabIdx(tabIdx)}
      >
        <Tab label="Training Videos" />
        {auth.isFreeTier() ?
          <Tooltip title="Upgrade subscription to access">
            <div>
              <Tab label="Progress Report" disabled={true} />
            </div>
          </Tooltip>
        :
          <Tab label="Progress Report" />
      }
      </Tabs>
      {selectedTabIdx === 0 &&
        <TrainingVideoLists
          videos={videos}
          onVideoSelected={handleOpenVideo}
        />
      }
      {selectedTabIdx === 1 &&
        <TrainingCoverageChart
        />
      }
    </>) : (<>
      {/* Trainee view */}
      <TrainingVideoLists
        videos={videos}
        onVideoSelected={handleOpenVideo}
      />
    </>)}
    {/* Dialog for both Training Managers and Trainees */}
    {selectedVideo && videoUrl && videoView &&
      <VideoDialog
        open={!!videoUrl}
        videoViewInfo={{
          description: selectedVideo.description,
          name: selectedVideo.name,
          played: videoView.played,
          url: videoUrl,
        }}
        onClose={handleCloseVideo}
        onError={handlePlaybackError}
        onUpdateVideo={handleUpdateVideo}
      />
    }
  </>;
}

TheTrainingPage.title = 'Training';
TheTrainingPage.requiredAuthZ = {
  tier: 1,
  permission: 'training',
};

export default TheTrainingPage;
