import {
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  StandardProps,
  Typography,
  Chip,
  TextField,
  FormControlLabel,
  Switch,
  Tooltip
} from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import * as Sentry from '@sentry/browser';
import { startCase } from 'lodash';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { FileWithPath } from 'react-dropzone';
import { IDocument } from '../../../../backend/src/document/interfaces/document.interface';
import { IUploadPathDto } from '../../../../backend/src/document/interfaces/upload-path-dto.interface';
import { DocumentCategory } from '../../../../backend/src/document/document-category.enum';
import { uploadDocument, uploadDocumentVersion } from '../../services/DocService';
import { showErrorResultBar } from '../ResultSnackbar';
import StyledDialogTitle from '../StyledDialogTitle';
import StyledDropzone from '../StyledDropzone';
import ResponsiveDialog from './ResponsiveDialog';
import { truncateString } from '../../helpers';
import { Autocomplete } from '@material-ui/lab';
import { INameSizeDto } from './FileUploadButtonDialog';

const useStyles = makeStyles({
  commentText: {
    color: 'rgba(0, 0, 0, 0.87);',
    whiteSpace: 'pre-line',
  },

  header: {
    margin: 0,
    padding: 0,
  },
});

export interface FileUploadDialogProps extends StandardProps<DialogProps, 'children'> {
  dialogHeaderText: string;
  documentCategory?: DocumentCategory;
  documentOrgId?: string;
  onAddDocuments?: (docs: IDocument[]) => void | Promise<void>;
  onClose: () => void;
  showCategorySelection?: boolean;
  showIcon?: boolean;
  multipleFiles?: boolean;
  allTags?: string[];
  currentDocuments: INameSizeDto[] | [];
  version?: boolean;
  previousVersionId?: string;
}

export interface FileWithPathProgress extends FileWithPath {
  uploadProgress: number;
}

export default function FileUploadDialog(props: FileUploadDialogProps) {
  const {
    dialogHeaderText,
    documentCategory = 'evidence',
    documentOrgId,
    onAddDocuments,
    onClose,
    open,
    showCategorySelection = false,
    multipleFiles,
    allTags = [],
    currentDocuments = [],
    version = false,
    previousVersionId
  } = props;

  const [selectedCategory, setSelectedCategory] = useState(documentCategory);
  const [selectedFiles, setSelectedFiles] = useState<FileWithPath[]>();
  const [filesCompleted, setFilesCompleted] = useState<number>(0);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [toDisable, setToDisable] = useState(false);
  const [addDoc, setAddDoc] = useState<IDocument[]>([]);
  const [filesRejected, setFilesRejected] = useState<string[]|null>(null);
  const [tags, setTags] = useState<string[]>([]);
  const [directoryToggle, setDirectoryToggle] = useState<boolean>(false);
  const [duplicateToggle, setDuplicateToggle] = useState<boolean>(false);
  const [allDocuments, setAllDocuments] = useState<INameSizeDto[]>(currentDocuments);
  const [description, setDescription] = useState<string>('');

  const maxFileUpload = multipleFiles ? 500 : 1;

  const onClickClose = () => {
    setUploadProgress(0);
    setFilesCompleted(0);
    setDirectoryToggle(false);
    setDuplicateToggle(false);
    setSelectedFiles([]);
    setTags([]);
    setAddDoc([]);
    onClose();
  };

  useEffect(() => {
    setAllDocuments(currentDocuments);
  }, [currentDocuments]);

  const createProgressBar = (files: FileWithPath[]) => {
    const progressBar = [];
    for (const [idx, file] of files.entries()) {
      let skip = false;
      if (allDocuments.length > 0 && duplicateToggle) {
         const nameSizeFile = {name: file.name, size: String(file.size)};
         if (allDocuments.some(currentDoc => currentDoc.size === nameSizeFile.size && currentDoc.name === nameSizeFile.name)) {
           skip = true;
         };
      };
      if (skip) {
        progressBar.push(
          <>
          {file.path && (
            <DialogContent>
              <Typography
                variant="body1"
                color="textSecondary"
              >
                {truncateString(file.path, 50)}
              </Typography>
            </DialogContent>
          )}
          </>
        );
      } else {
        progressBar.push(
          <>
          {file.path && (
            <DialogContent>
              <Typography
                variant="body1"
              >
                {truncateString(file.path, 50)}
              </Typography>
              <LinearProgress
                variant="determinate"
                value={idx < filesCompleted ? 100 : idx > filesCompleted ? 0 : uploadProgress}
              />
            </DialogContent>
          )}
          </>
        );
      }
    }
    return progressBar;
  };

  const createErrorsList = (errors: string[]) => {
    const errorList = [];
    for (const error of errors) {
        switch (error) {
          case 'Too many files':
            errorList.push(
            <Typography style={{color:'red'}}>
              {error} - max file limit is {maxFileUpload}
            </Typography>
            );
            break;
          default:
            errorList.push(
              <Typography style={{color:'red'}}>
                {error}
              </Typography>
              );
            break;
        }
    }
    return errorList;
  };

  const fileNameExists = (fileName: string) => {
    return allDocuments.map(doc => doc.name).includes(fileName);
  };

  const submitDoc = async (fileWithPath: FileWithPath) => {
    const directoryTags = fileWithPath.path?.replace(fileWithPath.name,'').split('/').filter(s => s) ?? [];
    const file = fileWithPath as File;

    // if name already exists then update
    let fileName = file.name;
    let nameIdx = 0;
    while (fileNameExists(fileName)) {
      const extension = file.name.split('.').pop();
      if (extension) {
        fileName = `${file.name.replace('.' + extension, '')} (${nameIdx}).${extension}`;
      } else {
        fileName = `${file.name} (${nameIdx})`;
      }
      ++nameIdx;
    }

    const uploadPath: IUploadPathDto = {
      category: selectedCategory,
      documentName: fileName,
      originalFileName: fileWithPath.path,
      orgId: documentOrgId,
      size: file.size,
      tagNames: directoryToggle ? tags.concat(directoryTags) : tags,
      changeDescription: description !== '' ? description : undefined
    };

        if (version && previousVersionId) {
          const doc = await uploadDocumentVersion(file, uploadPath, previousVersionId, setUploadProgress)
            .catch((err) => {
              showErrorResultBar('There was a problem issuing your request.');
              Sentry.captureException(err);
            });
            if (doc) {
              addDoc.push(doc);
            }
        } else {
          const doc = await uploadDocument(file, uploadPath, setUploadProgress)
            .catch((err) => {
              showErrorResultBar('There was a problem issuing your request.');
              Sentry.captureException(err);
            });
            if (doc) {
              addDoc.push(doc);
            }
        }
};


  const submitDocs = async() => {
    if (selectedFiles && !toDisable) {
      setToDisable(true);

      for (const [filesCompleted, file] of selectedFiles.entries()) {
        let skip = false;
        // skip duplicate documents upload
        if (allDocuments.length > 0 && duplicateToggle) {
           const nameSizeFile = {name: file.name, size: String(file.size)};
           if (allDocuments.some(currentDoc => currentDoc.size === nameSizeFile.size && currentDoc.name === nameSizeFile.name)) {
             skip = true;
           };
        };
        if (!skip) {
          await submitDoc(file).then(() => {
            setFilesCompleted(filesCompleted + 1);
            setUploadProgress(0);
          });
        } else {
          setFilesCompleted(filesCompleted + 1);
          setUploadProgress(0);
        }
      }
    }
  };

  const onClickSubmit = async () => {
    setToDisable(true);
    await submitDocs().then(() => {
      setToDisable(false);
      if (onAddDocuments) {
        onAddDocuments(addDoc);
      }
      onClickClose();
    });
  };

  const handleTextInputChange = (event: any) => {
    setDescription(event.target.value);
};

  return (
    <ResponsiveDialog
      open={open}
      maxWidth="sm"
      // fullWidth
      onClose={onClickClose}
    >
      <StyledDialogTitle
        id="form-dialog-title"
        onClose={onClickClose}
      >
        {dialogHeaderText}
      </StyledDialogTitle>
      <span>
      {showCategorySelection && (
        <DialogContent>
          <FormControl>
            <InputLabel htmlFor="upload-path">Category</InputLabel>
            <Select
              value={selectedCategory}
              onChange={e => setSelectedCategory(e.target?.value as DocumentCategory || 'report')}
              inputProps={{
                name: 'upload-path',
                id: 'upload-path',
              }}
            >
              {['report'].map(cat => (
                <MenuItem key={cat} value={cat}>{startCase(cat)}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
      )}
      </span>
      {allTags.length > 0 && (
        <DialogContent>
          <Tooltip title={'If a file is located in a folder on upload, the folder name will be automatically added as a tag.'}>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={directoryToggle}
                  onChange={(e, value) => {setDirectoryToggle(value);}}
                  name="folderToTag"
                />
              }
              label="Convert Folder Directories to Tags"
            />
          </Tooltip>
          {multipleFiles && (
            <Tooltip title={'If a file of the same name and size is already uploaded, the document will be skipped during upload.'}>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={duplicateToggle}
                    onChange={(e, value) => {setDuplicateToggle(value);}}
                    name="folderToTag"
                  />
                }
                label="Skip Duplicate Documents in Upload"
              />
            </Tooltip>
          )}
          <Autocomplete
              multiple
              id="tags-filled"
              options={allTags.map((option) => option).sort()}
              value={tags}
              freeSolo
              renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                  <Chip variant="outlined" label={option} {...getTagProps({ index })} />
              ))
              }
              onChange={(e, value) => {setTags(value);}}
              renderInput={(params) => (
              <TextField
                  {...params}
                  variant="filled"
                  label="Tags"
                  helperText={'This will tag all uploaded documents. Select from existing tags or create a new tag. To create a new tag, you must hit ENTER.'}
              />
          )}
      />
        </DialogContent>
      )}

      <span>
      <DialogContent>
        <StyledDropzone
          disabled={toDisable}
          onUpdateFile={setSelectedFiles}
          maxFileUpload={maxFileUpload}
          setErrors={setFilesRejected}
        />
      {filesRejected &&
        createErrorsList(filesRejected)
      }
      </DialogContent>
      {version && (
        <DialogContent>
          <FormControl>
            <TextField
                variant="filled"
                label="Description of Revision"
                helperText={'Description of change from previous version (optional).'}
                multiline
                minRows="2"
                // fullWidth
                onInput={handleTextInputChange}
              />
          </FormControl>
        </DialogContent>
      )}
      {selectedFiles &&
        createProgressBar(selectedFiles)
      }
      </span>
      <DialogActions>
        <Button
          color="primary"
          onClick={onClickClose}
        >
          Cancel
        </Button>
        <Button
          disabled={toDisable}
          color="primary"
          onClick={onClickSubmit}
        >
          Submit
        </Button>
      </DialogActions>
    </ResponsiveDialog>
  );
}
