import React, { useEffect, useState, Component } from 'react';
import { saveAs } from 'file-saver';
import { Doughnut as DoughnutChart } from 'react-chartjs-2';
import DownloadIcon from '@material-ui/icons/GetApp';
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import {
    Tooltip,
    Grid,
    withStyles,
    Box
} from '@material-ui/core';

import * as PropTypes from 'prop-types';

const  styles = theme => ({
    smallIconButton: {
        height: '10px',
        width: '10px',
        marginTop: '-1rem',
        marginRight: '1.5rem',
        marginBottom: '-1rem',
        '&:hover': {
          color: theme.palette.primary.main,
        },
      },
      smallIconButtonLabel: {
        height: '0',
      },
      smallIconButtonIcon: {
        fontSize: '12px',
      },
  });

class BarChartDownload extends Component {
    classes = this.props.classes;
    props = this.props;
    _isMounted = false;
    saveCanvas() {
        //save to png
        const canvasSave = document.getElementById('pieChart');
        canvasSave.toBlob(function (blob) {
            saveAs(blob,  'Materiality Assessment Responses Pie Chart.png');
        });
    }
    render() {
        const plugin = {
            beforeDraw: (chartCtx) => {
              const ctx = chartCtx.canvas.getContext('2d');
              ctx.save();
              ctx.globalCompositeOperation = 'destination-over';
              ctx.fillStyle = 'white';
              ctx.fillRect(0, 0, chartCtx.width, chartCtx.height);
              ctx.restore();
            }
        };
        return (
            <Grid container>
              <Grid item xs={12}>
                <Box
                    display="flex"
                    justifyContent="flex-end"
                >
                    <Tooltip title="Download chart as png">
                        <IconButton
                            onClick={this.saveCanvas}
                            classes={{
                                root: this.classes.smallIconButton,
                                label: this.classes.smallIconButtonLabel,
                            }}
                        >
                            <DownloadIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <DoughnutChart id="pieChart" data={this.props.data} options={this.props.options} plugins={[plugin]} />
              </Grid>
            </Grid>
        );
    }
 }

 export default withStyles(styles, { withTheme: true })(BarChartDownload);
