import { Divider, Menu, MenuItem } from '@material-ui/core';
import { MenuProps } from '@material-ui/core/Menu';
import React, { useEffect, useState } from 'react';
import { IOrgDto } from '../../../backend/src/org/interfaces';
import { showSuccessResultBar } from './ResultSnackbar';
import API from '../services/ApiService';
import Auth from '../services/AuthService';
import { History } from 'history';
import { handleError } from '../helpers';

export interface ChangeOrgMenuProps extends MenuProps {
  auth: Auth;
  onClickOrg: () => void;
  onSwitchOrg: (orgId: string) => void;
  history: History;
}

export default function ChangeOrgMenu({ auth, onClickOrg, onSwitchOrg, history, ...menuProps }: ChangeOrgMenuProps) {
  const [ activeOrgId, setActiveOrgId ] = useState<string>(auth.getOrgId());
  const [ additionalOrgs, setAdditionalOrgs ] = useState<IOrgDto[]>([]);
  const [ primaryOrg, setPrimaryOrg ] = useState<IOrgDto>();

  useEffect(() => {
    (async function fetchOrgs() {
      try {
        const res = await API.get('org');
        const allOrgs: IOrgDto[] = res.data?.data || [];
        const primaryOrgId = auth.getPrimaryOrgId();
        setAdditionalOrgs(allOrgs.filter(org => org.id !== primaryOrgId));
        setPrimaryOrg(allOrgs.find(org => org.id === primaryOrgId));
      } catch (err) {
        handleError(err, 'Error retrieving cross-org list');
      }
    })();
  }, [ auth ]);

  const handleSwitchOrg = (org: IOrgDto) => async () => {
    showSuccessResultBar(`Switching to ${org.name}`);
    onClickOrg();

    try {
      await API.post(`auth/activateOrg/${org.id}`);
      await auth.renewSession();
      history.push('/dashboard');
      showSuccessResultBar(`Switched org to ${org.name}`);
      setActiveOrgId(org.id);
      onSwitchOrg(org.id);
    } catch (err) {
      handleError(err, 'Error switching orgs');
    }
  };

  return  (
    <Menu
      {...menuProps}
    >
      {!primaryOrg && additionalOrgs.length === 0 && (
        <MenuItem disabled>
          No available orgs
        </MenuItem>
      )}
      {primaryOrg && (
        <MenuItem
          onClick={handleSwitchOrg(primaryOrg)}
          disabled={primaryOrg.id === activeOrgId}
          selected={primaryOrg.id === activeOrgId}
        >
          {primaryOrg.name}
        </MenuItem>
      )}
      {primaryOrg && additionalOrgs.length > 0 && <Divider />}
      {additionalOrgs.map(org => (
        <MenuItem
          key={org.id}
          onClick={handleSwitchOrg(org)}
          disabled={org.id === activeOrgId}
          selected={org.id === activeOrgId}
        >
          {org.name}
        </MenuItem>
      ))}
    </Menu>
  );
}
