import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InfoIcon from '@material-ui/icons/Info';
import ErrorIcon from '@material-ui/icons/Error';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import { Snackbar } from '@material-ui/core';
import { amber } from '@material-ui/core/colors';
import Theme from '../theme';
import CloseButton from './buttons/CloseButton';
import { geminiPurple } from '../theme';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles = theme => ({
  success: {
    backgroundColor: geminiPurple[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

function ResultSnackbar({ autoHideDuration, anchorOrigin, classes, className, message, open, onClose, variant, ...other }) {
  const Icon = variantIcon[variant];

  return (
    <Snackbar
      anchorOrigin={anchorOrigin || {
        vertical: 'top',
        horizontal: 'center',
      }}
      open={open}
      autoHideDuration={autoHideDuration || 6000}
      onClose={onClose}
      {...other}
    >
      <SnackbarContent
        className={classNames(classes[variant], className)}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={classNames(classes.icon, classes.iconVariant)} />
            {message}
          </span>
        }
        action={[
          <CloseButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={onClose}
          />,
        ]}
        {...other}
      />
    </Snackbar>
  );
}

ResultSnackbar.propTypes = {
  ...Snackbar.propTypes,

  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

const StyledResultBar = withStyles(styles, { name: 'ResultSnackbar', withTheme: true })(ResultSnackbar);

export default StyledResultBar;

const resultBarContainerId = 'autoStyledResultBarContainer';

function AutoStyledResultBar({ variant, message, ...otherProps }) {
  const [open, setOpen] = useState(true);

  return (
    <MuiThemeProvider theme={Theme}>
      <StyledResultBar
        variant={variant}
        message={message}
        open={open}
        onClose={() => setOpen(false)}

        {...otherProps}
      />
    </MuiThemeProvider>
  );
}

export function showResultBar(message, variant) {
  const resultBar = React.createElement(AutoStyledResultBar, {
    variant: variant || 'success',
    message,
  });

  let container = document.getElementById(resultBarContainerId);
  if (container) {
    container.remove();
  }

  container = document.createElement('span');
  container.id = resultBarContainerId;
  document.body.appendChild(container);

  ReactDOM.render(resultBar, container);
}

export function showSuccessResultBar(message) {
  showResultBar(message || 'Operation completed successfully');
}

export function showErrorResultBar(message) {
  showResultBar(message || 'An unexpected error encountered. Try again later', 'error');
}

export function showInfoResultBar(message) {
  showResultBar(message, 'info');
}
