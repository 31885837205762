import { InputBase, MenuItem, Select } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/styles';
import { EditorView } from 'prosemirror-view';
import classnames from 'classnames';
import React, { ChangeEvent, MouseEvent, ReactElement, ReactNode } from 'react';
import { MarkdownMenuItem } from '../lib/markdownMenuItems';

const useStyles = makeStyles({
  disabledMenuItem: {
    'color': 'rgba(0, 0, 0, 0.26) !important',
    'fontStyle': 'italic',
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
});

const StyledInput = withStyles(() => ({
  root: {
    verticalAlign: 'middle',
  },
  disabled: {
    'color': 'rgba(0, 0, 0, 0.26) !important',
    'fontStyle': 'italic',
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
  input: {
    'color': 'rgba(0, 0, 0, 0.75)',
    'fontWeight': 500,
    'fontSize': '14px',
    'letterSpacing': '0.02857em',
    'minWidth': '80px',
    'padding': '6px 26px 6px 12px',
    'borderRadius': 4,
    '&:hover': {
      borderRadius: '2px',
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
}))(InputBase);

export interface MenuDropdownGroupProps {
  items: MarkdownMenuItem[];
  view: EditorView;
}

export function MenuDropdownGroup({ view, items }: MenuDropdownGroupProps) {
  const classes = useStyles();
  const { state, dispatch } = view;

  const activeSelection = items.find(item => (!!item.active && item.active(state)));

  let buttonText: ReactNode = '';
  if (activeSelection) {
    buttonText = activeSelection.content || '';
  } else if (items.length > 0) {
    buttonText = items[0].content || '';
  }

  // If every MenuItem is disabled, then disable the entire Select:
  const isDisabled = items.every(item => !!item.enable && !item.enable(state));

  const handleChange = (e: ChangeEvent<{ value: unknown }>, child: ReactNode) => {
    if (child) {
      (child as ReactElement).props.onClick(e);
    }
  };

  const handleClick = (item: MarkdownMenuItem) => (e: MouseEvent) => {
    e.preventDefault();
    item.run(state, dispatch);
  };

  return (
    <>
      <Select
        value={buttonText}
        input={<StyledInput />}
        onChange={handleChange}
        disabled={isDisabled}
      >
        {items.map(item => (
          <MenuItem
            key={item.key}
            className={classnames(item.enable && !item.enable(state) && classes.disabledMenuItem)}
            value={typeof item.content === 'string' ? item.content : ''}
            onClick={handleClick(item)}
          >
            {item.content}
          </MenuItem>
        ))}
      </Select>
    </>
  );
}
