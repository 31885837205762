import { createStyles, StandardProps, StyledComponentProps, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import * as React from 'react';

const styles = createStyles({
  actionPanel: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
    margin: '1rem',
  },

  center: {
    justifyContent: 'center',
  },

  action: {
    margin: '0 4px',
  },
});

export interface PageActionsProps extends StandardProps<StyledComponentProps, PageActionsClassKey> {
  center?: boolean;
}

export type PageActionsClassKey =
  'actionPanel'
  | 'center'
  | 'action';

const PageActions: React.FC<PageActionsProps> =
  ({ center, children, classes }) =>
    <div className={classNames(classes!.actionPanel, center && classes!.center)}>
      {children}
    </div>;

export default withStyles(styles, { name: 'PageActions', withTheme: false })(PageActions);
