import { Button, Card, CardHeader, FormControlLabel, Switch } from '@material-ui/core';
import classNames from 'classnames';
import { sortedUniq } from 'lodash';
import moment from 'moment';
import { MUIDataTableTextLabels } from 'mui-datatables';
import React, { useEffect, useState } from 'react';
import { UserAuditMemberStatus } from '../../../../backend/src/user-audit/enums';
import { IAwsAccessKey, IAwsAccessKeyLastUsedAt, IAwsMember, IAwsMemberInfo, IAwsOrg } from '../../../../backend/src/user-audit/interfaces';
import { formatDate } from '../../helpers';
import SpioDataTable, { SpioDataTableColumn } from '../SpioDataTable';
import {
  BOOLEAN_FILTER_SELECTIONS,
  boolToString,
  DiffTableCell,
  diffTableCellDownload,
  diffTableCellSortCompare,
  filterLogicKeyedStringArrays,
  filterLogicStringArray,
  filterLogicStringDoubleArray,
  getAgeInDays,
  getOrgAuditStatus,
  getTableSubtitleText,
  NameArrayDetails,
  nameArrayDownload,
  numberNullSortCompare,
  StatusCell,
  statusDownload,
  useUserAuditTableStyles,
} from './UserAuditTableHelpers';

interface IAwsPoliciesByType {
  direct: string[];
  inline: string[];
}

const getAccessKeyAgeInDays = (accessKeys?: IAwsAccessKey[] | null): number | null => {
  // We want the oldest access key's age.
  // The string 'sort' should work since the dates are actually datetime strings.
  if (accessKeys && accessKeys.length > 0) {
    const dates = accessKeys.map(v => v.createdAt).filter(v => !!v).sort();

    return getAgeInDays(dates[0] ?? null);
  }

  return null;
};

const getAccessKeyLastUsedAt = (accessKeysLastUsedAt?: IAwsAccessKeyLastUsedAt[] | null): string | null => {
  if (accessKeysLastUsedAt && accessKeysLastUsedAt.length > 0) {
    const dates = accessKeysLastUsedAt.map(v => v.lastUsedAt).filter(v => !!v).sort();
    const latestDate = dates.pop();

    return formatDate(latestDate) ?? null;
  }

  return null;
};

export class IAwsFlattenedMember {
  public static fromOrgMember(orgMember: IAwsMember) {
    return new IAwsFlattenedMember(orgMember);
  }

  accessKeyAge: number | null;
  accessKeys: IAwsAccessKey[][] = [];
  consoleAccess: (string | null | undefined)[] = [];
  groups: string[][] = [];
  lastLogin: string | null; // the last time the password was used to log in
  lastProgrammaticAccess: string | null;
  mfaEnabled: (string | null | undefined)[] = [];
  passwordAge: number | null;
  policies: IAwsPoliciesByType[] = [];
  status: UserAuditMemberStatus;
  userId: string;
  userName: string[] = [];

  protected constructor (orgMember: IAwsMember) {
    this.accessKeyAge = getAccessKeyAgeInDays(orgMember.data?.accessKeys ?? orgMember.prev_data?.accessKeys);
    this.lastLogin = formatDate(orgMember.data?.passwordLastUsedAt ?? orgMember.prev_data?.passwordLastUsedAt) ?? '';
    this.lastProgrammaticAccess = getAccessKeyLastUsedAt(orgMember.data?.accessKeysLastUsedAt ?? orgMember.prev_data?.accessKeysLastUsedAt) ?? '';
    this.passwordAge = getAgeInDays(orgMember.data?.passwordCreatedAt ?? orgMember.prev_data?.passwordCreatedAt);
    this.status = orgMember.status;
    this.userId = orgMember.member_id;
    this.pushFlattenedData(orgMember.data);
    this.pushFlattenedData(orgMember.prev_data);
  }

  // Data for which we want diffs displayed:
  protected pushFlattenedData(data: IAwsMemberInfo | null = null) {
    if (data) {
      this.accessKeys.push(data.accessKeys ?? []);
      this.consoleAccess.push(boolToString(data.consoleAccess));
      this.groups.push(data.groups || []);
      this.mfaEnabled.push(boolToString(data.mfaEnabled));
      this.policies.push({
        direct: data.directPolicies || [],
        inline: data.inlinePolicies || [],
      });
      this.userName.push(data.userName || '');
    }
  }
}

const getAccessKeyDisplayInfo = (accessKey: IAwsAccessKey) => {
  const {
    id,
    status = 'Unknown status',
    createdAt,
  } = accessKey;
  const truncatedId = id ? id.toString().slice(-4) : 'Unknown ID';
  const createdAtStr = createdAt ? ` (${formatDate(createdAt)})` : '';

  return `${status}: ...${truncatedId}${createdAtStr}`;
};

function DiffTableCellAccessKeys({ accessKeys, isModified, toShowNames }: { accessKeys: IAwsAccessKey[][]; isModified: boolean; toShowNames: boolean; }) {
  const currAccessKeys = (accessKeys[0] || []).map(getAccessKeyDisplayInfo);
  const prevAccessKeys = (accessKeys[1] || []).map(getAccessKeyDisplayInfo);

  return (
    <div>
      <NameArrayDetails
        label="Access Keys"
        isModified={isModified}
        currNames={currAccessKeys}
        prevNames={prevAccessKeys}
        toShowNames={toShowNames}
      />
    </div>
  );
}

function DiffTableCellGroups({ groups, isModified, toShowGroupNames }: { groups: string[][]; isModified: boolean; toShowGroupNames: boolean; }) {
  const currGroups = (groups[0] || []).sort();
  const prevGroups = (groups[1] || []).sort();

  return (
    <div>
      <NameArrayDetails
        label="Groups"
        isModified={isModified}
        currNames={currGroups}
        prevNames={prevGroups}
        toShowNames={toShowGroupNames}
      />
    </div>
  );
}

function DiffTableCellPolicies({ policies, isModified, toShowPolicyNames }: { policies: IAwsPoliciesByType[]; isModified: boolean; toShowPolicyNames: boolean; }) {
  const currPolicies: IAwsPoliciesByType = policies[0] || {};
  const prevPolicies: IAwsPoliciesByType = policies[1] || {};

  return (
    <div>
      <NameArrayDetails
        label="Direct"
        isModified={isModified}
        currNames={(currPolicies.direct || []).sort()}
        prevNames={(prevPolicies.direct || []).sort()}
        toShowNames={toShowPolicyNames}
      />
      <NameArrayDetails
        label="Inline"
        isModified={isModified}
        currNames={(currPolicies.inline || []).sort()}
        prevNames={(prevPolicies.inline || []).sort()}
        toShowNames={toShowPolicyNames}
      />
    </div>
  );
}

const getAuditTableColumns = (
  allNames: string[],
  allGroups: string[],
  allPolicies: string[],
  tableData: IAwsFlattenedMember[],
  toExpandDetails: boolean,
): SpioDataTableColumn[] => {
  return [
    {
      name: 'status',
      label: 'Changed',
      options: {
        customBodyRenderLite: dataIndex => <StatusCell status={tableData[dataIndex]?.status} />,
        customDownloadRender: dataIndex => statusDownload(tableData[dataIndex]?.status),
        filter: false,
        searchable: false,
      },
    },
    {
      name: 'userId',
      label: 'User Id',
      options: {
        customFilterListOptions: { render: (v: string) => `User Id: ${v}` },
        display: 'false',
      },
    },
    {
      name: 'userName',
      label: 'User Name',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const member = tableData[dataIndex];

          return member ? <DiffTableCell values={member.userName} isModified={member.status === 'modified'}/> : '';
        },
        customDownloadRender: dataIndex => diffTableCellDownload(tableData[dataIndex]?.userName, tableData[dataIndex]?.status === 'modified'),
        customFilterListOptions: { render: (v: string) => `User Name: ${v}` },
        filterOptions: {
          names: allNames,
          logic: filterLogicStringArray,
        },
        sortCompare: diffTableCellSortCompare,
      },
    },
    {
      name: 'groups',
      label: 'Groups',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const member = tableData[dataIndex];

          return member ? (
            <DiffTableCellGroups
              groups={member.groups}
              isModified={member.status === 'modified'}
              toShowGroupNames={toExpandDetails}
            />
          ) : '';
        },
        customDownloadRender: (dataIndex) => {
          const member = tableData[dataIndex];
          const groups = member?.groups || [];

          return nameArrayDownload(member.status === 'modified', groups[0], groups[1]);
        },
        customFilterListOptions: { render: (v: string) => `Group: ${v}` },
        filterOptions: {
          names: allGroups,
          logic: filterLogicStringDoubleArray,
        },
        sort: false,
      },
    },
    {
      name: 'policies',
      label: 'Policies',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const member = tableData[dataIndex];

          return member ? (
            <DiffTableCellPolicies
              policies={member.policies}
              isModified={member.status === 'modified'}
              toShowPolicyNames={toExpandDetails}
            />
          ) : '';
        },
        customFilterListOptions: { render: (v: string) => `Policies: ${v}` },
        download: false,
        filterOptions: {
          names: allPolicies,
          logic: filterLogicKeyedStringArrays,
        },
        sort: false,
      },
    },
    {
      name: 'policies',
      label: 'Direct Policies',
      options: {
        customDownloadRender: (dataIndex) => {
          const member = tableData[dataIndex];
          const policies = member?.policies || [];

          return nameArrayDownload(member.status === 'modified', policies[0]?.direct, policies[1]?.direct);
        },
        display: 'excluded',
        download: true,
        filter: false,
        searchable: false,
        viewColumns: false,
      },
    },
    {
      name: 'policies',
      label: 'Inline Policies',
      options: {
        customDownloadRender: (dataIndex) => {
          const member = tableData[dataIndex];
          const policies = member?.policies || [];

          return nameArrayDownload(member.status === 'modified', policies[0]?.inline, policies[1]?.inline);
        },
        display: 'excluded',
        download: true,
        filter: false,
        searchable: false,
        viewColumns: false,
      },
    },
    {
      name: 'consoleAccess',
      label: 'Console Access?',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const member = tableData[dataIndex];

          return member && <DiffTableCell values={member.consoleAccess} isModified={member.status === 'modified'}/>;
        },
        customDownloadRender: dataIndex => diffTableCellDownload(tableData[dataIndex]?.consoleAccess, tableData[dataIndex]?.status === 'modified'),
        customFilterListOptions: { render: (v: string) => `Console Access: ${v}` },
        filterOptions: {
          names: BOOLEAN_FILTER_SELECTIONS,
        },
        sortCompare: diffTableCellSortCompare,
      },
    },
    {
      name: 'passwordAge',
      label: 'Password Age',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const age = tableData[dataIndex]?.passwordAge;

          return (age === undefined || age === null) ? '' : `${age}d`;
        },
        filter: false,
        sortCompare: numberNullSortCompare,
      },
    },
    {
      name: 'lastLogin',
      label: 'Last Login',
      options: {
        filter: false,
      },
    },
    {
      name: 'accessKeys',
      label: 'Access Keys',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const member = tableData[dataIndex];

          return member ? (
            <DiffTableCellAccessKeys
              accessKeys={member.accessKeys}
              isModified={member.status === 'modified'}
              toShowNames={toExpandDetails}
            />
          ) : '';
        },
        customDownloadRender: (dataIndex) => {
          const member = tableData[dataIndex];
          const accessKeys = member?.accessKeys || [];
          const currAccessKeys = (accessKeys[0] || []).map(getAccessKeyDisplayInfo);
          const prevAccessKeys = (accessKeys[1] || []).map(getAccessKeyDisplayInfo);

          return nameArrayDownload(member.status === 'modified', currAccessKeys, prevAccessKeys);
        },
        filter: false,
        sort: false,
      },
    },
    {
      name: 'accessKeyAge',
      label: 'Access Key Age',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const age = tableData[dataIndex]?.accessKeyAge;

          return (age === undefined || age === null) ? '' : `${age}d`;
        },
        filter: false,
        hint: 'Days since the oldest active access key was created',
        sortCompare: numberNullSortCompare,
      },
    },
    {
      name: 'lastProgrammaticAccess',
      label: 'Last Access Key Use',
      options: {
        filter: false,
      },
    },
    {
      name: 'mfaEnabled',
      label: 'MFA Enabled?',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const member = tableData[dataIndex];

          return member && <DiffTableCell values={member.mfaEnabled} isModified={member.status === 'modified'}/>;
        },
        customDownloadRender: dataIndex => diffTableCellDownload(tableData[dataIndex]?.mfaEnabled, tableData[dataIndex]?.status === 'modified'),
        customFilterListOptions: { render: (v: string) => `MFA Enabled: ${v}` },
        filterOptions: {
          names: BOOLEAN_FILTER_SELECTIONS,
        },
        sortCompare: diffTableCellSortCompare,
      },
    },
  ];
};

interface UserAuditAwsTableProps {
  handleConfirmSelected: () => Promise<void>;
  isLoading: boolean;
  orgs: IAwsOrg[];
  selectedOrgIdx: number;
}

export default function UserAuditAwsTable(props: UserAuditAwsTableProps) {
  const classes = useUserAuditTableStyles();
  const { handleConfirmSelected, isLoading, orgs, selectedOrgIdx } = props;

  const [ isDiffView, setIsDiffView ] = useState<boolean>(false);
  const [ selectedOrg, setSelectedOrg ] = useState<IAwsOrg>();
  const [ orgsMembers, setOrgsMembers ] = useState<IAwsFlattenedMember[][]>([]);
  const [ membersToDisplay, setMembersToDisplay ] = useState<IAwsFlattenedMember[]>([]);
  const [ toExpandDetails, setToExpandDetails ] = useState<boolean>(false);

  const [ allNames, setAllNames ] = useState<string[]>([]);
  const [ allGroups, setAllGroups ] = useState<string[]>([]);
  const [ allPolicies, setAllPolicies ] = useState<string[]>([]);

  useEffect(() => {
    setSelectedOrg(orgs[selectedOrgIdx]);
  }, [ orgs, selectedOrgIdx ]);

  useEffect(() => {
    const orgsFlattenedMembers = orgs.map((org) => {
      const latestSync = org.syncs[0];
      const orgMembers: IAwsMember[] = latestSync ? latestSync.membersDiffData : [];

      return orgMembers.map(IAwsFlattenedMember.fromOrgMember);
    });

    setOrgsMembers(orgsFlattenedMembers);
  }, [ orgs ]);

  // Pre-filters before sending the data to the table: diff view.
  // (We don't want chips for these.)
  useEffect(() => {
    let orgMembers = orgsMembers[selectedOrgIdx] || [];

    if (isDiffView) {
      orgMembers = orgMembers.filter(orgMember => orgMember.status !== 'unchanged');
    }

    setMembersToDisplay(orgMembers);
  }, [ orgsMembers, isDiffView, selectedOrgIdx ]);

  // Consolidate the names, etc for display in the filters.
  // (Each user may have e.g. two names: a current name and a previous name. We want both in the filter.)
  useEffect(() => {
    const namesWithDuplicates = membersToDisplay.flatMap(member => member.userName ? member.userName : []).sort();
    const names = sortedUniq(namesWithDuplicates);
    setAllNames(names);

    const groupsWithDuplicates = membersToDisplay.flatMap(member => member.groups ? member.groups.flat() : []).sort();
    const groups = sortedUniq(groupsWithDuplicates);
    setAllGroups(groups);

    const policiesWithDuplicates = membersToDisplay.flatMap((member) => {
      if (member.policies) {
        return member.policies.flatMap(policiesByType => Object.values(policiesByType).flat());
      } else {
        return [];
      }
    }).sort();
    const policies = sortedUniq(policiesWithDuplicates);
    setAllPolicies(policies);

  }, [ membersToDisplay ]);

  const clickToggleDiffView = () => {
    setIsDiffView(!isDiffView);
  };

  const clickToggleToShowGroupAndPolicyNames = () => {
    setToExpandDetails(!toExpandDetails);
  };

  const clickConfirmSelected = async () => {
    handleConfirmSelected();
  };

  const TableTitle = () => {
    if (isLoading) {
      return null;
    }

    return (
      <div>
        {getOrgAuditStatus(selectedOrg) === 'pending' && (
          <FormControlLabel
            control={
              <Switch
                checked={isDiffView}
                onChange={clickToggleDiffView}
              />
            }
            label="Only show changed users"
          />
        )}
        <FormControlLabel
          control={
            <Switch
              checked={toExpandDetails}
              onChange={clickToggleToShowGroupAndPolicyNames}
            />
          }
          label="Expand details"
        />
      </div>
    );
  };

  return (
    <Card
      className={classes.tableCard}
      variant="outlined"
    >
      <CardHeader
        classes={{
          action: classes.tableCardHeaderAction,
        }}
        action={getOrgAuditStatus(selectedOrg) === 'pending' && (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={clickConfirmSelected}
            disabled={isLoading}
          >
            Confirm
          </Button>
        )}
        title={selectedOrg && `${selectedOrg.name} Members`}
        subheader={getTableSubtitleText(selectedOrg)}
      />
      <SpioDataTable
        className={classes.dataTable}
        title={<TableTitle />}
        columns={getAuditTableColumns(allNames, allGroups, allPolicies, membersToDisplay, toExpandDetails)}
        data={membersToDisplay || []}
        options={{
          elevation: 1,
          textLabels: {
            body: {
              noMatch: isLoading ? 'Loading...' : (isDiffView ? 'No changes' : 'No users found'),
            },
          } as MUIDataTableTextLabels,
          downloadHeader: [
            [ selectedOrg?.name ?? '' ],
            [ getTableSubtitleText(selectedOrg) ],
            [],
          ],
          downloadOptions: {
            filename: `UserAudit_AWS_${moment().format('YYYYMMDD')}.csv`,
          },
          filterType: 'multiselect',
          print: false,
          selectableRows: 'none',
          sort: true,
          sortOrder: {
            name: 'userName',
            direction: 'asc',
          },
          setRowProps: (row, index) => {
            const status = membersToDisplay[index]?.status;

            return {
              className: classNames(
                status === 'added' && classes.newUserRow,
                status === 'deleted' && classes.deletedUserRow,
                ),
            };
          },
        }}
      />
    </Card>
  );
}
