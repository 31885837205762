import React from 'react';
import { Button, createStyles, Theme, WithStyles, withStyles } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { ButtonProps } from '@material-ui/core/Button';

const styles = (theme: Theme) => createStyles({
  leftIcon: {
    marginRight: theme.spacing(0.5),
  },
});

export interface SaveButtonProps extends Omit<ButtonProps, 'classes' | 'onClick'>, WithStyles<typeof styles> {
  onClick?: (e: any) => void;
  text?: string;
}

function SaveButton({ classes, disabled = false, onClick, text }: SaveButtonProps) {
  return (
    <Button
      variant="contained"
      size="small"
      color="primary"
      disabled={disabled}
      onClick={onClick}
    >
      <SaveIcon className={classes.leftIcon} />
      {text || 'Save'}
    </Button>
  );
}

export default withStyles(styles, { name: 'SaveButton', withTheme: true })(SaveButton);
