import {
  Card,
  CardContent,
  CardHeader,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Chip,
  Button,
  Tooltip,
  Link,
  Typography,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import React, { useEffect, useState } from 'react';
import API from '../services/ApiService';
import Auth from '../services/AuthService';
import * as DocService from '../services/DocService';
import {
  ArchiveDialog,
  DocumentEditDialog,
  DocumentLinkDialog,
  DocumentAssignmentDialog,
} from './dialogs';
import { showSuccessResultBar } from './ResultSnackbar';
import { DetailSectionHeading } from './MaterialityIssueDetails';
// TODO move this to shared space since also used in Stakeholder?
import { MissingDataText, SecondaryField } from './VendorDetailsInfo';
import { CloseButton } from './buttons';
import {
  IDocument,
  IDocumentAssigneeDto,
  IDocumentsResponse,
} from '../../../backend/src/document/interfaces';
import {
  getFileExtension,
  formatFileTitle,
  formatDate,
  formatFileSize,
  truncateString,
} from '../helpers';
import { Dictionary } from 'lodash';
import DocumentComments from './DocumentComments';
import ErrorIcon from '@material-ui/icons/Error';
import DocumentAttachDialog from './dialogs/DocumentAttachDialog';
import { IIdNameDto } from '../../../backend/src/common/id-name-dto.interface';
import { handleError } from '../helpers';

const useStyles = makeStyles((theme) =>
  createStyles({
    buttonLink: {
      color: 'inherit',
      textDecoration: 'underline',
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    cardHeader: {
      paddingBottom: 0,
    },
    linkItem: {
      '& a': {
        color: theme.typography.body1.color,
        cursor: 'pointer',
      },
      '&:nth-child(n+2)': {
        paddingTop: '4px',
      },
      fontSize: '0.8125rem',
    },
    primaryColumn: {
      color: theme.typography.body1.color,
      fontSize: '14px',
    },
    referencesContainer: {
      paddingLeft: '1rem',
      paddingBottom: '0.8rem',
    },
    referenceResponse: {
      color: 'rgba(0,0,0,0.54)',
      whiteSpace: 'pre-line',
    },
    secondaryColumn: {
      backgroundColor: '#f5f5f5',
      opacity: '0.8',
      fontSize: '0.8125rem',
    },
    smallIconButton: {
      height: '14px',
      width: '14px',
      marginTop: '-6px',
      marginLeft: '0.5rem',
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    smallIconButtonIcon: {
      fontSize: '18px',
    },
    smallIconButtonLabel: {
      height: '0',
    },
    tagChip: {
      margin: '4px',
      color: theme.palette.primary.main,
      background: theme.palette.grey[200],
    },
    invalidAssigneeIcon: {
      marginLeft: '4px',
      verticalAlign: 'middle',
    },
    disabledButtonLink: {
      color: '#bfbfbf',
      textDecoration: 'underline',
      cursor: 'pointer',
      '&:hover': {
        color: '#bfbfbf',
      },
    },
  })
);

export const FILE_EX_LOOKUP: Dictionary<string> = {
  csv: 'comma-Separated Values (csv)',
  doc: 'Word Document (doc)',
  docx: 'Word Document (docx)',
  html: 'Web Page Document (html)',
  htm: 'Web Page Document (htm)',
  odt: 'Open Source Word processor Document (odt)',
  pdf: 'Portable Document Format (pdf)',
  xls: 'Excel Spreadsheet (xls)',
  xlsx: 'Excel Spreadsheet (xlsx)',
  ods: 'Open Source Spreadsheet (ods)',
  ppt: 'PowerPoint Presentation (ppt)',
  pptx: 'PowerPoint Presentation (pptx)',
  txt: 'Text Document (txt)',
};

export interface EvidenceDetailsProps {
  assignees?: IDocumentAssigneeDto[];
  auth: Auth;
  isDialog?: boolean;
  onArchive: () => void;
  onCloseDialog?: () => void;
  onUpdate: (doc: IDocumentsResponse) => void;
  documentData: IDocument;
  allDocTags: string[];
  allDocNames: string[];
  urlLink: string;
}

function EvidenceDetails(props: EvidenceDetailsProps) {
  const {
    assignees = [],
    isDialog = false,
    onArchive,
    onCloseDialog = () => undefined,
    onUpdate,
    documentData,
    allDocTags,
    auth,
    allDocNames,
    urlLink,
  } = props;

  const [assignee, setAssignee] = useState<IDocumentAssigneeDto | null>(null);
  const [documents, setDocuments] = useState(documentData.documents || []);
  const [isDocumentAssigned, setIsDocumentAssigned] = useState(false);
  const [isValidAssignee, setIsValidAssignee] = useState(true);
  const [toShowAssignDialog, setToShowAssignDialog] = useState(false);
  const classes = useStyles();
  const [toShowEditDialog, setToShowEditDialog] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [toShowArchiveDialog, setToShowArchiveDialog] = useState(false);
  const [toShowLinkDialog, setToShowLinkDialog] = useState(false);
  const [toShowAttachDialog, setToShowAttachDialog] = useState(false);

  const handleArchive = async () => {
    try {
      await API.patch(`document/${documentData.id}`, { status: 'archived' });
      onArchive();
      showSuccessResultBar('Entry archived');
    } catch (err) {
      handleError(err, 'Unexpected error while archiving');
    }
  };

  const handleClickMenuItem = (handler: () => void) => () => {
    handleCloseMenu();
    handler();
  };

  const handleCloseArchiveDialog = () => setToShowArchiveDialog(false);

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const handleConfirmArchive = async () => {
    handleArchive();
    handleCloseArchiveDialog();
  };

  const handleClickRemoveDocument = (doc: IIdNameDto) => async () => {
    try {
      const res = await API.delete(
        `document/${documentData.id}/attach/${doc.id}`
      );
      onUpdate(res ? res.data.data : doc);
      showSuccessResultBar('Document removed');
    } catch (err) {
      handleError(err, 'Unexpected error while removing document');
    }
  };

  const markNotStarted = async (doc: IDocument) => {
    try {
      const res = await API.patch(`document/${doc.id}`, { status: 'active' });
      onUpdate(res ? res.data.data : doc);
      showSuccessResultBar('Status updated');
    } catch (err) {
      handleError(err, 'Unexpected error while updating status');
    }
  };

  const markInProgress = async (doc: IDocument) => {
    try {
      const res = await API.patch(`document/${doc.id}`, {
        status: 'in_progress',
      });
      onUpdate(res ? res.data.data : doc);
      showSuccessResultBar('Status updated');
    } catch (err) {
      handleError(err, 'Unexpected error while updating status');
    }
  };

  const markNeedsReview = async (doc: IDocument) => {
    try {
      const res = await API.patch(`document/${doc.id}`, { status: 'review' });
      onUpdate(res ? res.data.data : doc);
      showSuccessResultBar('Status updated');
    } catch (err) {
      handleError(err, 'Unexpected error while updating status');
    }
  };

  const markCompleted = async (doc: IDocument) => {
    try {
      const res = await API.patch(`document/${doc.id}`, {
        status: 'completed',
      });
      onUpdate(res ? res.data.data : doc);
      showSuccessResultBar('Status updated');
    } catch (err) {
      handleError(err, 'Unexpected error while updating status');
    }
  };

  useEffect(() => {
    let currAssignee = null;

    if (documentData.assigneeId) {
      currAssignee = {
        id: documentData.assigneeId,
        name: documentData.assigneeName,
      };
    }

    setIsDocumentAssigned(!!currAssignee);
    setAssignee(currAssignee);
  }, [documentData.assigneeId, documentData.assigneeName]);

  useEffect(() => {
    // An 'invalid' assignee is one who's been assigned to a task already but is no longer in the list of allowed assignees.
    // 'assignee = null' corresponds to 'unassigned', which is considered valid.
    // If 'assignees' isn't passed in as a prop, it defaults to [] (ie, length = 0) and we don't worry about checking the validity.
    setIsValidAssignee(
      assignee === null ||
        assignees.length === 0 ||
        assignees.some((a) => a.id === assignee.id)
    );
  }, [assignee, assignees]);

  useEffect(() => {
    let currDocuments: IIdNameDto[] = [];

    if (documentData.documents && documentData.documents.length > 0) {
      currDocuments = documentData.documents;
    }

    setDocuments(currDocuments);
  }, [documentData.documents]);

  function statusButtons(status: string, doc: IDocument) {
    switch (status) {
      case 'active':
        return (
          <>
            <Button
              color="primary"
              size="small"
              onClick={() => markInProgress(doc)}
              disabled={!auth.isGranted({ permission: 'evidence:edit' }) && !auth.isGranted({ permission: 'evidence:status' })}

            >
              Mark In Progress
            </Button>
            <Button
              color="primary"
              size="small"
              onClick={() => markCompleted(doc)}
              disabled={!auth.isGranted({ permission: 'evidence:edit' }) && !auth.isGranted({ permission: 'evidence:status' })}
            >
              Mark Completed
            </Button>
          </>
        );
      case 'in_progress':
        return (
          <>
            <Button
              color="primary"
              size="small"
              onClick={() => markNeedsReview(doc)}
              disabled={!auth.isGranted({ permission: 'evidence:edit' }) && !auth.isGranted({ permission: 'evidence:status' })}
            >
              Mark Needs Review
            </Button>
            <Button
              color="primary"
              size="small"
              onClick={() => markCompleted(doc)}
              disabled={!auth.isGranted({ permission: 'evidence:edit' }) && !auth.isGranted({ permission: 'evidence:status' })}
            >
              Mark Completed
            </Button>
          </>
        );

      case 'review':
        return (
          <>
            <Button
              color="primary"
              size="small"
              onClick={() => markCompleted(doc)}
              disabled={!auth.isGranted({ permission: 'evidence:edit' }) && !auth.isGranted({ permission: 'evidence:status' })}
            >
              Mark Completed
            </Button>
          </>
        );

      case 'completed':
        return (
          <>
            <Button
              color="primary"
              size="small"
              onClick={() => markNotStarted(doc)}
              disabled={!auth.isGranted({ permission: 'evidence:edit' }) && !auth.isGranted({ permission: 'evidence:status' })}
            >
              Mark Not Started
            </Button>
            <Button
              color="primary"
              size="small"
              onClick={() => markInProgress(doc)}
              disabled={!auth.isGranted({ permission: 'evidence:edit' }) && !auth.isGranted({ permission: 'evidence:status' })}
            >
              Mark In Progress
            </Button>
          </>
        );
    }
  }


  return (
    <>
      <Card>
        <CardHeader
          action={
            <>
              <IconButton
                onClick={({ currentTarget }) => setMenuAnchorEl(currentTarget)}
              >
                <MoreVertIcon />
              </IconButton>
              {isDialog && <CloseButton onClick={onCloseDialog} />}
            </>
          }
          className={classes.cardHeader}
          title={
            <>
              {formatFileTitle(documentData.name || 'Document details')}{' '}
              {documentData.documentTags ? (
                documentData.documentTags
                  .map((tag) => (
                    <Chip
                      className={classes.tagChip}
                      label={tag.name}
                      size="small"
                    ></Chip>
                  ))
                  .sort()
              ) : (
                <></>
              )}
            </>
          }
          subheader={
            <>
              <Grid>
                {isDocumentAssigned ? (
                  <>
                    <span>
                      <Tooltip title="Reassign this evidence">
                        <Link
                          className={!auth.isGranted({ permission: 'evidence:edit' }) && !auth.isGranted({ permission: 'evidence:assign' }) ? classes.disabledButtonLink : classes.buttonLink}
                          component="button"
                          onClick={() => setToShowAssignDialog(true)}
                          disabled={!auth.isGranted({ permission: 'evidence:edit' }) && !auth.isGranted({ permission: 'evidence:assign' })}

                        >
                          <Typography>
                            Assigned to:
                          </Typography>
                        </Link>
                      </Tooltip>{' '}
                      {documentData.assigneeName}
                    </span>
                    {!isValidAssignee && (
                      <Tooltip title="This user no longer has access to evidence">
                        <ErrorIcon className={classes.invalidAssigneeIcon} />
                      </Tooltip>
                    )}
                  </>
                ) : (
                  <span>
                    <Tooltip title="Assign this evidence">
                      <Link
                          className={!auth.isGranted({ permission: 'evidence:edit' }) && !auth.isGranted({ permission: 'evidence:assign' }) ? classes.disabledButtonLink : classes.buttonLink}
                          component="button"
                        onClick={() => setToShowAssignDialog(true)}
                        disabled={!auth.isGranted({ permission: 'evidence:edit' }) && !auth.isGranted({ permission: 'evidence:assign' })}
                      >
                        <Typography>
                          Unassigned
                        </Typography>
                      </Link>
                    </Tooltip>
                  </span>
                )}
              </Grid>
              <Grid>
                {documentData.status === 'active' ? (
                  <>
                    <span>Status: Not Started</span>
                  </>
                ) : null}
                {documentData.status === 'in_progress' ? (
                  <>
                    <span>Status: In Progress</span>
                  </>
                ) : null}
                {documentData.status === 'completed' ? (
                  <>
                    <span>
                      Status: Completed by: {documentData.updatedByName}
                    </span>
                  </>
                ) : null}
                {documentData.status === 'review' ? (
                  <>
                    <span>Status: Need Review</span>
                  </>
                ) : null}
              </Grid>
            </>
          }
        />
        <Menu
          anchorEl={menuAnchorEl}
          keepMounted
          open={Boolean(menuAnchorEl)}
          onClose={handleCloseMenu}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {/* Only show edit if the document is not marked as completed */}
          <MenuItem
            onClick={handleClickMenuItem(() => setToShowEditDialog(true))}
            disabled={documentData.status === 'completed' || !auth.isGranted({ permission: 'evidence:edit' })}
          >
            Edit
          </MenuItem>
          <MenuItem
            onClick={handleClickMenuItem(() => setToShowArchiveDialog(true))}
            disabled={!auth.isGranted({ permission: 'evidence:edit' })}
          >
            Archive
          </MenuItem>
          <MenuItem
            onClick={DocService.documentDownloadHandler(documentData.id)}
            disabled={!auth.isGranted({ permission: 'evidence:download' })}
          >
            Download
          </MenuItem>
          <MenuItem onClick={() => setToShowLinkDialog(true)}>
            Get Link
          </MenuItem>
          <MenuItem onClick={() => setToShowAttachDialog(true)} disabled={!auth.isGranted({ permission: 'evidence:edit' })}>
            Attach Document
          </MenuItem>
        </Menu>
        <CardContent>
          {/* {documentData.documentTags ? documentData.documentTags.map(tag => <Chip className={classes.tagChip} label={tag.name} size="small"></Chip>).sort() : <></>} */}
          <Grid container alignItems="flex-start" spacing={4}>
            {/* Creator */}
            <Grid item xs={12} sm={8} md={9} className={classes.primaryColumn}>
              <DetailSectionHeading>
                Additional Information
              </DetailSectionHeading>
              <div className={classes.referencesContainer}>
                <Grid container>
                  <Grid item xs={2}>
                    Created By:
                  </Grid>
                  <Grid item xs={10} className={classes.referenceResponse}>
                    {documentData.createdByName ? (
                      <Grid container>{documentData.createdByName}</Grid>
                    ) : (
                      <MissingDataText message="None" />
                    )}
                  </Grid>
                  <Grid item xs={2}>
                    Original File Name:
                  </Grid>
                  <Grid item xs={10} className={classes.referenceResponse}>
                    {documentData?.originalFileName}
                  </Grid>
                  <Grid item xs={2}>
                    Link to Document:
                  </Grid>
                  <Grid item xs={10} className={classes.referenceResponse}>
                    <Link
                      href={urlLink}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {urlLink}
                    </Link>
                  </Grid>
                </Grid>
              </div>
              {/* Notes */}
              <DetailSectionHeading>Description</DetailSectionHeading>
              <div className={classes.referencesContainer}>
                {documentData.description ? (
                  <Grid container>{documentData.description}</Grid>
                ) : (
                  <MissingDataText message="None" />
                )}
              </div>
              <DetailSectionHeading>Additional Resources</DetailSectionHeading>
              <div className={classes.referencesContainer}>
                {documents.length > 0 ? (
                  documents.map((doc) => (
                    <Grid
                      key={doc.id}
                      container
                      justify="space-between"
                      wrap="nowrap"
                    >
                      <Grid item>
                        <Link
                          className={classes.buttonLink}
                          onClick={DocService.documentDownloadHandler(doc.id)}
                        >
                          {doc.name}
                        </Link>
                        <Tooltip title="Remove this attachment">
                          <IconButton
                            classes={{
                              root: classes.smallIconButton,
                              label: classes.smallIconButtonLabel,
                            }}
                            onClick={handleClickRemoveDocument(doc)}
                          >
                            <DeleteIcon
                              className={classes.smallIconButtonIcon}
                            />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  ))
                ) : (
                  <MissingDataText message="None" />
                )}
              </div>
              {statusButtons(
                documentData.status ? documentData.status : 'active',
                documentData
              )}
              {/* Comments */}
              <Grid item>
                <DocumentComments
                  auth={auth}
                  comments={documentData.comments}
                  documentId={documentData.id}
                  onUpdate={onUpdate}
                  readOnly={!auth.isGranted({ permission: 'evidence:edit'})}
                />
              </Grid>
            </Grid>
            {/* Secondary column */}
            <Grid
              item
              xs={12}
              sm={4}
              md={3}
              className={classes.secondaryColumn}
            >
              {/* Links */}
              <SecondaryField
                title="Uploaded At:"
                value={formatDate(documentData?.createdAt) || ''}
              />
              <SecondaryField
                title="Updated At:"
                value={formatDate(documentData?.updatedAt) || ''}
              />
              <SecondaryField
                title="File Type:"
                value={
                  FILE_EX_LOOKUP.hasOwnProperty(
                    getFileExtension(documentData?.name)
                  )
                    ? FILE_EX_LOOKUP[getFileExtension(documentData?.name)]
                    : 'Unknown'
                }
              />
              <SecondaryField
                title="File Size:"
                value={formatFileSize(documentData?.size || 'N/A')}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {/* Edit dialog */}
      <DocumentEditDialog
        open={toShowEditDialog}
        onClose={() => setToShowEditDialog(false)}
        onUpdate={onUpdate}
        documentData={documentData}
        allDocNames={allDocNames}
        allDocTags={allDocTags}
      />
      {/* Archive dialog */}
      <ArchiveDialog
        open={toShowArchiveDialog}
        onClose={handleCloseArchiveDialog}
        onArchive={handleConfirmArchive}
        title={`Archive the document '${truncateString(
          documentData?.name,
          30
        )}'?`}
        text={
          documentData?.category === 'questionnaire' ||
          documentData?.category === 'evidence/task' ||
          documentData?.category === 'evidence/stakeholder'
            ? `Document is attached to a ${documentData.category
                .split('/')
                .splice(-1)}. `
            : undefined
        }
      />
      <DocumentLinkDialog
        open={toShowLinkDialog}
        onClose={() => setToShowLinkDialog(false)}
        title={`Link to '${truncateString(documentData?.name, 40)}'`}
        docId={documentData?.id}
        evidence={true}
      />
      <DocumentAssignmentDialog
        assignees={assignees}
        onClose={() => setToShowAssignDialog(false)}
        onUpdate={onUpdate}
        open={toShowAssignDialog}
        documents={[documentData]}
      />
      <DocumentAttachDialog
        onClose={() => setToShowAttachDialog(false)}
        onUpdate={onUpdate}
        open={toShowAttachDialog}
        evidenceData={[documentData]}
      />
    </>
  );
}

export default EvidenceDetails;
