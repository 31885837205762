import {
  Button,
  Card,
  CardContent,
  Collapse,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Tooltip
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import * as Sentry from '@sentry/browser';
import React, { useEffect, useState } from 'react';
import { showErrorResultBar, showSuccessResultBar } from '../components/ResultSnackbar';
import OrgIncomingAccessTable from '../components/OrgIncomingAccessTable';
import OrgOutgoingAccessTable from '../components/OrgOutgoingAccessTable';
import API from '../services/ApiService';
import Auth from '../services/AuthService';
import { AxiosResponse } from 'axios';
import CloseIcon from '@material-ui/icons/Close';
import { geminiPurple } from '../theme';
import OrgShareApproveAccessDialog from '../components/dialogs/OrgShareApproveAccessDialog';
import { IOrgRBACJoinDto, IRoleDto, IUserRBACDto } from '../../../backend/src/org-share/interfaces';
import VerticalAlignBottom from '@material-ui/icons/VerticalAlignBottom';
import VerticalAlignTop from '@material-ui/icons/VerticalAlignTop';
import { History } from 'history';
import { handleError } from '../helpers';

const useStyles = makeStyles({
  headerCard: {
    width: '100%',
    margin: 0,
  },
  myAlert: {
    color: 'black',
    backgroundColor: geminiPurple[100],
    '& .MuiAlert-icon': {
      color: geminiPurple[600],
    }
  }
});

export interface TheOrgSharePageProps {
  auth: Auth;
  history: History;
}

function TheOrgSharePage({ auth, history }: TheOrgSharePageProps) {
  const classes = useStyles();
  const [ isLoadingUsers, setIsLoadingUsers ] = useState(false);
  const [ selectedTabIdx, setSelectedTabIdx ] = useState(auth.hasTier(5) ? 0 : 1);
  const [ inboundOrgs, setInboundOrgs ] = useState<IOrgRBACJoinDto[]>([]);
  const [ outboundOrgs, setOutboundOrgs ] = useState<IOrgRBACJoinDto[]>([]);
  const [ showSnackBar, setShowSnackbar ] = useState<boolean[]>([]);
  const [ allRoles, setAllRoles ] = useState<IRoleDto[]>([]);
  const [ toShowConfirmMarkApproved, setToShowConfirmMarkApproved ] = useState(false);

  useEffect(() => {
    setIsLoadingUsers(true);

    API.get('org-share/inbound')
      .then(res => setInboundOrgs(res.data?.data ?? []))
      .catch(err => handleError(err,'Error fetching inbound shared orgs'))
      .finally(() => setIsLoadingUsers(false));

      API.get('org-share/outbound')
      .then(res => setOutboundOrgs(res.data?.data ?? []))
      .catch(err => handleError(err,'Error fetching outbound outbound shared orgs'))
      .finally(() => setIsLoadingUsers(false));

  }, []);

  useEffect(() => {
    const alerts = outboundOrgs.map((org) => org.status === 'pending');
    setShowSnackbar(alerts);
  }, [outboundOrgs]);

  const updateUser = (orgRBACId: string, userIdx: number | null, apiResponse: AxiosResponse<any>, successMsg: string) => {
      const orgIdx = inboundOrgs.map(o => o.id).indexOf(orgRBACId);
      if (orgIdx > -1) {
          if (userIdx) {
              const updatedUser: IUserRBACDto = (apiResponse.data && apiResponse.data.data) ? apiResponse.data.data : undefined;
              const updatedOrgs = inboundOrgs.slice();
              updatedOrgs[orgIdx].users[userIdx] = updatedUser;
              setInboundOrgs(updatedOrgs);
              showSuccessResultBar(successMsg);
          } else {
              const newUser: IUserRBACDto = (apiResponse.data && apiResponse.data.data) ? apiResponse.data.data : undefined;
              const updatedOrgs = inboundOrgs.slice();
              updatedOrgs[orgIdx].users.push(newUser);
              setInboundOrgs(updatedOrgs);
              showSuccessResultBar(successMsg);
          }
      }
  };

  const removeUser = (orgRBACId: string, userIdx: number, successMsg: string) => {
      const orgIdx = inboundOrgs.map(o => o.id).indexOf(orgRBACId);
      const updatedOrgs = inboundOrgs.slice();
      if (orgIdx > -1) {
          updatedOrgs[orgIdx].users.splice(userIdx, 1);
          setInboundOrgs(updatedOrgs);
          showSuccessResultBar(successMsg);
      }
  };

  const updateOutboundOrg = (apiResponse: AxiosResponse<any>, successMsg: string) => {
      const orgUpdateDto = (apiResponse.data && apiResponse.data.data) ? apiResponse.data.data : undefined;
      const orgIdx = outboundOrgs.map(o => o.id).indexOf(orgUpdateDto.id);
      const updatedOrgs = outboundOrgs.slice();
      if (orgIdx > -1) {
          updatedOrgs[orgIdx] = orgUpdateDto;
          setOutboundOrgs(updatedOrgs);
          showSuccessResultBar(successMsg);
      }
  };

  const updateInboundOrg = (apiResponse: AxiosResponse<any>, successMsg: string) => {
      const orgUpdateDto = (apiResponse.data && apiResponse.data.data) ? apiResponse.data.data : undefined;
      const orgIdx = inboundOrgs.map(o => o.id).indexOf(orgUpdateDto.id);
      const updatedOrgs = inboundOrgs.slice();
      if (orgIdx > -1) {
          updatedOrgs[orgIdx] = orgUpdateDto;
          setInboundOrgs(updatedOrgs);
          showSuccessResultBar(successMsg);
      }
  };

  const addInboundOrg = (apiResponse: AxiosResponse<any>) => {
      const orgCopy = inboundOrgs.slice();
      const newOrg = (apiResponse.data && apiResponse.data.data) ? apiResponse.data.data : undefined;
      orgCopy.push(newOrg);
      setInboundOrgs(orgCopy);
  };

  const addOutboundOrg = (apiResponse: AxiosResponse<any>) => {
      const orgCopy = outboundOrgs.slice();
      const newOrg =(apiResponse.data && apiResponse.data.data) ? apiResponse.data.data : undefined;
      orgCopy.push(newOrg);
      setOutboundOrgs(orgCopy);
  };

  const handleClose = (idx: number) => {
      const snackbarCopy = showSnackBar;
      snackbarCopy[idx] = false;
      setShowSnackbar(snackbarCopy);
  };

  const handleApproveAccess = async (orgRBACId: string, roleIds: string[]) => {
    const orgShareRequestDto = {
        status: 'active',
        roleIds: roleIds
    };
    try {
      const res = await API.patch(`org-share/${orgRBACId}`, orgShareRequestDto);
      updateOutboundOrg(res, 'Org access shared. The org owner will be notified of the updated access.');
    } catch (err) {
      handleError(err, 'Error sharing org access');
    }
  };

  const generateAlert = () => {
    return (
      <>
      {outboundOrgs.map((orgData, idx) =>
      <>
      {orgData.status === 'pending' ?
        <Grid xs={12} item key={idx}>
          <Collapse in={showSnackBar[idx]}>
            <Alert
              severity="info"
              className={classes.myAlert}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {handleClose(idx);}}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {orgData.toOrg.name} has requested access to the {orgData.fromOrg.name} account.
              <Button color="primary" size="small" onClick={() => {setToShowConfirmMarkApproved(true);}}>
                Approve Access
              </Button>
              <Button color="secondary" size="small" onClick={() => {handleClose(idx);}}>
                Deny Access
              </Button>
            </Alert>
          </Collapse>
        </Grid>
      :
        <></>
      }
      <OrgShareApproveAccessDialog
        allRoles={allRoles}
        onClose={() => {setToShowConfirmMarkApproved(false);}}
        open={toShowConfirmMarkApproved}
        orgData={orgData}
        handleSave={handleApproveAccess}
      />
      </>
      )}
      </>
    );
  };

  useEffect(() => {
    API.get('org-share/roles')
      .then(res => setAllRoles(res.data?.data ?? []))
      .catch(err => handleError(err, 'Error fetching available user roles'));
  }, []);

  return (
    <>
      {generateAlert()}
      <Tabs
        value={selectedTabIdx}
        onChange={(e, tabIdx) => setSelectedTabIdx(tabIdx)}
      >
        {!auth.hasTier(5) || (!auth.isGranted({ permission: 'org_share:inbound' } && !(inboundOrgs.length > 0))) ? (
        <Tooltip title={!auth.hasTier(5) ? 'Upgrade subscription to enable incoming access' : 'You do not have permissions to view incoming access'}>
          <div>
            <Tab
              label={'Incoming Access'}
              icon={<VerticalAlignBottom />}
              disabled={true}
            />
          </div>
        </Tooltip>
        ):(
          <Tab
            label={'Incoming Access'}
            icon={<VerticalAlignBottom />}
          />
       )}
      {auth.isGranted({ permission: 'org_share:outbound' }) ? (
        <Tab
          label={'Outgoing Access'}
          icon={<VerticalAlignTop />}
        />
      ) :
      <Tooltip title={'You do not have permissions to view outgoing access'}>
          <div>
          <Tab
            label={'Outgoing Access'}
            icon={<VerticalAlignTop />}
            disabled={true}
          />
        </div>
      </Tooltip>
      }
      </Tabs>
      <Card className={classes.headerCard}>
        <CardContent>
          {selectedTabIdx === 0 && auth.hasTier(5) &&
          <OrgIncomingAccessTable
            isLoading={isLoadingUsers}
            orgs={inboundOrgs}
            auth={auth}
            updateUser={updateUser}
            onRemoveUser={removeUser}
            addInboundOrg={addInboundOrg}
            updateInboundOrg={updateInboundOrg}
            allRoles={allRoles}
            history={history}
          />
          }
          {selectedTabIdx === 1 &&
          <OrgOutgoingAccessTable
              isLoading={isLoadingUsers}
              orgs={outboundOrgs}
              auth={auth}
              addOutboundOrg={addOutboundOrg}
              updateOutboundOrg={updateOutboundOrg}
              allRoles={allRoles}
              handleApproveAccess={handleApproveAccess}
          />
          }
        </CardContent>
      </Card>
    </>
  );
}

TheOrgSharePage.requiredAuthZ = {
  tier: 1,
  permission: 'org_share',
  primaryOrg: true
};
TheOrgSharePage.routePath = '/org-share';
TheOrgSharePage.title = 'Org Sharing';

export default TheOrgSharePage;
