import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Typography,
  Tooltip,
  ListItemIcon,
  withStyles,
  IconButton,
  createStyles,
  ListItem as MuiListItem,
} from '@material-ui/core';

//import { LimitReachedDialog } from '../components/dialogs';
import { List, TextField, Icon, Grid } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import VisibilityIcon from '@material-ui/icons//Visibility';
import GetAppIcon from '@material-ui/icons/GetApp';
import ListItemText from '@material-ui/core/ListItemText';
import Pagination from '@material-ui/lab/Pagination';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFileOutlined';
import LockIcon from '@material-ui/icons/Lock';
import API from '../services/ApiService';
import * as DocService from '../services/DocService';

export const ListItem = withStyles({
  root: {
    '@media (hover: none)': {
      '&:hover': {
        backgroundColor: '#fafafa',
      },
    },
    '& .MuiListItemText-primary': {
      color: '#adadad',
    },
    '& .MuiListItemText-secondary': {
      color: '#bfbfbf',
    },
    '& .MuiListItemIcon-root': {
      color: '#bfbfbf',
    },
  },
})(MuiListItem);

const styles = (theme) => createStyles({
  disabledIconButtonIcon: {
    fontSize: '24px',
    color: '#bfbfbf',
  },
  buttonIcon: {
    fontSize: '24px',
    //color: '#bfbfbf',
  },
});

function DocList({ docs, onClickDoc, handleOpen, auth, classes }) {
  const rowsPerPage = 10;
  const pageCount = Math.ceil(docs.length / rowsPerPage);

  const [page, setPage] = React.useState(1);

  const handleChange = (event, value) => {
    setPage(value);
  };
  // return array.slice((page_number - 1) * page_size, page_number * page_size);
  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <List>
        {docs
          .slice((page - 1) * rowsPerPage, page * rowsPerPage)
          .map((doc, index) =>
            doc.tier <= auth.getTier() ? (
              <Grid key={doc.id} container alignItems="baseline">
                <Grid item xs={11}>
                  <MuiListItem>
                    <ListItemIcon>
                      <InsertDriveFileIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={doc.name}
                      secondary={doc.description}
                    />
                  </MuiListItem>
                </Grid>
                <Grid item xs={1}>
                  <div>
                  {auth.isFreeTier() ?
                    <Tooltip title="Upgrade subscription to download resource">
                        <IconButton
                          aria-label="download resource"
                          onClick={onClickDoc(doc.id)}
                          size="small"
                          disabled={true}
                        >
                          <GetAppIcon fontSize="small" className={classes.disabledIconButtonIcon} />
                        </IconButton>

                    </Tooltip>
                  :
                    <Tooltip title="Download resource">
                      <IconButton
                        aria-label="download resource"
                        onClick={onClickDoc(doc.id)}
                        size="small"
                      >
                        <GetAppIcon fontSize="small" className={classes.buttonIcon} />
                      </IconButton>
                    </Tooltip>
                  }
                  <Tooltip title="View resource">
                    <IconButton
                      aria-label="view resource"
                      component={Link}
                      to={`/resources/${doc.id}`}
                      target="_blank" rel="noopener noreferrer"
                      size="small"
                    >
                      <VisibilityIcon fontSize="small" className={classes.buttonIcon} />
                    </IconButton>
                  </Tooltip>
                  </div>
                </Grid>
              </Grid>
            ) : (
              <>
                <Grid key={doc.id} container alignItems="baseline">
                <Grid item xs={11}>
                    <ListItem key={doc.id}>
                      <ListItemIcon>
                        <LockIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={doc.name}
                        secondary={doc.description}
                      />
                    </ListItem>
                  </Grid>
                  <Grid item xs={1}>
                    <Tooltip title="Upgrade subscription to download resource">
                      <span>
                        <IconButton
                          aria-label="download resource"
                          onClick={onClickDoc(doc.id)}
                          size="small"
                          disabled={true}
                          className={classes.disabledIconButtonIcon}
                        >
                          <GetAppIcon fontSize="small" className={classes.disabledIconButtonIcon} />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip title="Upgrade subscription to view resource">
                      <span>
                        <IconButton
                          aria-label="view resource"
                          component={Link}
                          to={`/resources/${doc.id}`}
                          target="_blank" rel="noopener noreferrer"
                          size="small"
                          disabled={true}
                        >
                          <VisibilityIcon fontSize="small" className={classes.disabledIconButtonIcon} />
                        </IconButton>
                      </span>
                    </Tooltip>
                </Grid>
                </Grid>
              </>
            )
          )}
      </List>
      <Pagination
        count={pageCount}
        page={page}
        onChange={handleChange}
        color="primary"
      />
    </div>
  );
}

const SearchBar = ({ filterData, templates, setFilteredTemplates }) => (
  <form>
    <TextField
      style={{ width: '250px' }}
      id="search-bar"
      className="text"
      onInput={(e) => {
        filterData(e.target.value, templates, setFilteredTemplates);
      }}
      variant="outlined"
      placeholder="Search..."
      size="small"
    />
    <Icon style={{ marginTop: '15px' }} type="submit" aria-label="search">
      <SearchIcon />
    </Icon>
  </form>
);

function TheResourcePage({ auth, classes }) {
  const [templates, setTemplates] = useState([]);
  const [filteredTemplates, setFilteredTemplates] = useState(templates);
  // const [isDialogOpen, setIsDialogOpen] = useState(false);

  // const handleOpenUpgrade = () => {
  //   setIsDialogOpen(true);
  // };

  // const handleCloseUpgrade = () => {
  //   setIsDialogOpen(false);
  // };

  const filterData = (query, data, setFilteredTemplates) => {
    setFilteredTemplates(
      data.filter(
        (d) =>
          d.name.toLowerCase().includes(query) ||
          d.description.toLowerCase().includes(query)
      )
    );
  };

  useEffect(() => {
    API.get('document/template').then((res) => {
      const templateDocs = res.data;
      const sortedTemplates = templateDocs.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      setTemplates(sortedTemplates);
      setFilteredTemplates(sortedTemplates);
    });
  }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={8}>
          <Typography
            variant="h4"
            component="h4"
            style={{ paddingLeft: '1.5rem', margin: '1.2rem 0' }}
          >
            Resources
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <SearchBar
            setFilteredTemplates={setFilteredTemplates}
            templates={templates}
            filterData={filterData}
          />
        </Grid>
      </Grid>
      {/* <LimitReachedDialog
        open={isDialogOpen}
        onClose={handleCloseUpgrade}
        text={
          'Your subscription plan does not include access to this document. You will need to upgrade your plan in order to download it.'
        }
      ></LimitReachedDialog> */}
      <DocList
        docs={filteredTemplates}
        onClickDoc={DocService.documentTemplateDownloadHandler}
        // handleOpen={handleOpenUpgrade}
        auth={auth}
        classes={classes}
      />
    </>
  );
}

TheResourcePage.title = 'Resources';
TheResourcePage.requiredAuthZ = {
  tier: 1,
  permission: 'documents',
};

export default withStyles(styles, { withTheme: true })(TheResourcePage);
