import React from 'react';
import { Redirect, Route } from 'react-router-dom';

function AuthRoute({ auth, render, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (auth.isAuthenticated() && !auth.accessRevoked()) {
          if ((!auth.isRegistered() || auth.isNotRegistered()) && props.location.pathname !== '/registration-closed') {
            return (
              <Redirect to={{
                // pathname: '/register',
                pathname: '/registration-closed',
                state: { from: props.location },
              }} />
            );
          }
          return (render({ ...props, auth }));
        } else {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location },
              }}
            />
          );
        }
      }}
    />
  );
}

export default AuthRoute;
