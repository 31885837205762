import { AppBar, Dialog, DialogContent, StandardProps, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { IPolicyDocDto } from '../../../../backend/src/policy-doc/interfaces';
import API from '../../services/ApiService';
import Auth from '../../services/AuthService';
import CloseButton from '../buttons/CloseButton';
import EditButton from '../buttons/EditButton';
import Page from '../Page';

const useStyles = makeStyles({
  appBar: {
    position: 'relative',
  },

  flex: {
    flex: 1,
  },
  root: {
    'margin': '2em',
    'fontFamily': '"Georgia", "serif"',
    'fontSize': '1rem',
    'textAlign': 'justify',

    '& a[href]:after': {
      content: '"" !important',
    },

    '& abbr[title]:after': {
      content: '" " !important',
    },

    '& h1': {
      borderBottomWidth: 'medium',
      borderBottomStyle: 'solid',
      borderBottomLeftRadius: '10px 0',
      borderBottomRightRadius: '10px 0',
      borderBottomColor: '#340cc2',
    },

    '& h1:not(:nth-of-type(1))': {
      pageBreakBefore: 'always',
    },

    '& h1, h2, h3, h4, h5, h6': {
      color: '#000000',
      fontWeight: 'bold',
    },

    '& table': {
      borderCollapse: 'collapse',
      borderSpacing: 0,
      border: 0,
    },

    '& thead': {
      backgroundColor: '#e0e0e0',
      fontWeight: 'bold',
      color: '#000000',
      paddingTop: '0.25em',
      paddingBottom: '0.25em',
    },

    '& table td, th': {
      paddingLeft: '1em',
      paddingRight: '1em',
    },

    '& tbody tr:nth-child(even)': {
      background: '#eee',
    },

    '& p': {
      widows: 2,
      orphans: 2,
    },
  },
});

export type PolicyDocDialogClassKey =
  'appBar'
  | 'flex'
  | 'root';

export interface PolicyDocDialogProps extends StandardProps<{}, PolicyDocDialogClassKey> {
  auth: Auth;
  actions: React.ReactElement[];
  open: boolean;
  onClose: () => void;
  onEdit: () => void;
  policyDoc: IPolicyDocDto;
}

function PolicyDocDialog({ auth, actions, open, onClose, onEdit, policyDoc }: PolicyDocDialogProps) {
  const classes = useStyles();
  const [content, setContent] = useState<string>('');

  useEffect(() => {
    if (policyDoc && open) {
      API
        .get(`policyDoc/${policyDoc!.versionId}/content`)
        .then(res => {
          setContent(res.data.data.content);
        });
    }
  }, [policyDoc, open]);

  function handleClose() {
    onClose();
    setContent('');
  }

  return (
    <Dialog
      fullScreen
      disableEscapeKeyDown={false}
      open={open}
      scroll="paper"
      onClose={handleClose}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <CloseButton onClick={handleClose} />
          <Typography variant="h6" color="inherit" className={classes.flex}>
            {policyDoc && policyDoc.name}
          </Typography>
          {actions}
          {onEdit && auth.isGranted({ permission: 'policies:manage' }) &&
            <EditButton
              onClick={onEdit}
              disableColor={false} />
          }
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Page>
          <div
            className={classes.root}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </Page>
      </DialogContent>
    </Dialog>
  );
}

PolicyDocDialog.title = 'Policy Document';

export default PolicyDocDialog;
