import { EditorView } from 'prosemirror-view';
import React, { useEffect, useRef } from 'react';
import 'prosemirror-view/style/prosemirror.css';
import './style/spio-md-editor.css';

export interface EditorElementProps {
  view: EditorView;
}

// A div container for the ProseMirror editor to attach itself to.
export function EditorElement({ view }: EditorElementProps) {
  const viewRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (viewRef.current) {
      viewRef.current.appendChild(view.dom);
    }
  }, [ view ]);

  return <div className="spio-md-editor" ref={viewRef} />;
}
