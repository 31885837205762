import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import {
  PolicyCoverageChart,
  TaskStatusByGroupTab,
  TrainingCoverageChart,
  UpNextTasks,
  ChartDocumentsByCategory
} from '../components/widgets';
import API from '../services/ApiService';
import TheSubscriptionPage from './TheSubscriptionPage';

function TheDashboardPage({ auth, history }) {
  const [isLoadingTasks, setIsLoadingTasks] = useState(false);
  const [isLoadingDocs, setIsLoadingDocs] = useState(false);
  const [reloadDocs, setReloadDocs] = useState(true);
  const [isLoadingUpNextTasks, setIsLoadingUpNextTasks] = useState(false);
  // const [taskCategoriesSummary, setTaskCategoriesSummary] = useState([]);
  const [docTagsSummary, setDocTagsSummary] = useState([]);
  const [docOverallSummary, setDocOverallSummary] = useState([]);
  const [taskOverallSummary, setTaskOverallSummary] = useState([]);
  const [taskGroupsSummary, setTaskGroupsSummary] = useState([]);
  const [upNextTasks, setUpNextTasks] = useState([]);
  const [reloadTasks, setReloadTasks] = useState(true);
  const [collections, setCollections] = useState([]);

  useEffect(() => {
    // If the status of a task changes then we need to reload all of the task widgets.
    if (reloadTasks) {
      setIsLoadingTasks(true);
      setIsLoadingUpNextTasks(true);

      API.get('task/dashboard')
        .then(res => {
          const data = (res.data && res.data.data) ? res.data.data : {};
          // setTaskCategoriesSummary(data.byCategory || []);
          setCollections(data.collections || []);
          setTaskOverallSummary(data.overall || []);
          setTaskGroupsSummary(data.byGroup || []);
          setUpNextTasks(data.upNext || []);
        })
        .finally(_ => {
          setReloadTasks(false);
          setIsLoadingTasks(false);
          setIsLoadingUpNextTasks(false);
        });
    }
  }, [reloadTasks]);

  useEffect(() => {
    if (reloadDocs) {
      setIsLoadingDocs(true);
      API.get('document/dashboard')
        .then(res => {
          const data = (res.data && res.data.data) ? res.data.data : {};
          setDocTagsSummary(data.byTag || []);
          setDocOverallSummary(data.overall || []);
        })
        .finally(_ => {
          setReloadDocs(false);
          setIsLoadingDocs(false);
        });
    }
  }, [reloadDocs]);

  const reloadUpNextTasks = async () => {
    // If e.g. only the assignment of a task changes then we only need to reload the Up Next widget.
    setIsLoadingUpNextTasks(true);

    API.get('task/dashboard/upNext')
      .then(res => {
        const data = (res.data && res.data.data) ? res.data.data : {};
        setUpNextTasks(data.upNext || []);
      })
      .finally(_ => {
        setIsLoadingUpNextTasks(false);
      });
  };

  return (
    <Grid container>
      {auth.isGranted(TaskStatusByGroupTab.requiredAuthZ) &&
        <Grid item xs={12}>
          <TaskStatusByGroupTab
            auth={auth}
            history={history}
            taskOverallSummary={taskOverallSummary}
            taskGroupsSummary={taskGroupsSummary}
            collections={collections}
          />
        </Grid>
      }

      {auth.isGranted(UpNextTasks.requiredAuthZ) &&
        <Grid item xs={12} md={6}>
          <UpNextTasks
            auth={auth}
            history={history}
            isLoading={isLoadingUpNextTasks}
            onUpdateAssignment={reloadUpNextTasks}
            onUpdateStatus={() => setReloadTasks(true)}
            setTasks={setUpNextTasks}
            tasks={upNextTasks}
          />
        </Grid>
      }

      {auth.isGranted(ChartDocumentsByCategory.requiredAuthZ) &&
        <Grid item xs={12} md={6}>
          <ChartDocumentsByCategory
            auth={auth}
            isLoading={isLoadingDocs}
            docTagsSummary={docTagsSummary}
            docOverallSummary={docOverallSummary}
          />
        </Grid>
      }

      {auth.isGranted(PolicyCoverageChart.requiredAuthZ) && !auth.isFreeTier() &&
        <Grid item xs={12} md={6}>
          <PolicyCoverageChart />
        </Grid>
      }

      {auth.isGranted(TrainingCoverageChart.requiredAuthZ) && !auth.isFreeTier() &&
        <Grid item xs={12} md={6}>
          <TrainingCoverageChart />
        </Grid>
      }

      {auth.isFree() &&
        <Grid item xs={12}>
          <TheSubscriptionPage auth={auth} />
        </Grid>
      }
    </Grid>
  );
}

TheDashboardPage.title = 'Dashboard';
TheDashboardPage.requiredAuthZ = {
  tier: 1,
  permission: 'dashboard',
};

export default TheDashboardPage;
