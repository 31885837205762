import {
  Button,
  Accordion,
  AccordionActions,
  AccordionDetails,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import * as Sentry from '@sentry/browser';
import moment from 'moment';
import { useState } from 'react';
import { IStakeholderDto, IStakeholderQuestionnaireDto, IStakeholderQuestionnaireTemplateDto } from '../../../backend/src/stakeholder/interfaces';
import { formatDate } from '../helpers';
import API from '../services/ApiService';
import Auth from '../services/AuthService';
import AccordionIconSummary from './AccordionIconSummary';
import { showErrorResultBar, showSuccessResultBar } from './ResultSnackbar';
import { handleError } from '../helpers';

const useStyles = makeStyles({
  questionnaireEntryHeading: {
    flexBasis: '40%',
    flexShrink: 0,
  },
  questionnaireSecondaryHeading: {
    flexBasis: '25%',
    flexShrink: 0,
  },
  questionnaireQuestion: {
    fontWeight: 'bold',
  },
  questionnaireAnswer: {
    marginInlineStart: '1rem',
  },
  stakeholderResponseText: {
    color: 'blue',
  },
  questionnaireEntryActions: {
    justifyContent: 'flex-start',
    paddingTop: 0,
  },
});

const questionnaireStatusMap = {
  open: 'Awaiting Response',
  in_progress: 'In Progress',
  saved: 'Saved',
  closed: 'Response received',
  canceled: 'Canceled',
};

const isQuestionnaireCanceled = (questionnaire: IStakeholderQuestionnaireDto) => {
  return questionnaire.status === 'canceled';
};

const isQuestionnaireExpired = (questionnaire: IStakeholderQuestionnaireDto) => {
  return moment().isAfter(questionnaire.expiresAt);
};

const isQuestionnairePending = (questionnaire: IStakeholderQuestionnaireDto) => {
  return questionnaire.status === 'open' || questionnaire.status === 'in_progress' || questionnaire.status === 'saved';
};

const isResponseList = (response: string | object) => {
  return typeof response === 'object';
};

const getQuestionnaireLinkInfoText = (questionnaire: IStakeholderQuestionnaireDto) => {
  if (isQuestionnaireCanceled(questionnaire)) {
    return 'Link canceled';
  } else if (isQuestionnaireExpired(questionnaire)) {
    return 'Link expired:';
  } else {
    return 'Link expires:';
  }
};

const getQuestionnaireSubheading = (questionnaire: IStakeholderQuestionnaireDto) => {
  const statusStr = questionnaireStatusMap[questionnaire.status];

  if (isQuestionnaireCanceled(questionnaire)) {
    return `Canceled (${formatDate(questionnaire.updatedAt)})`;
  } else if (questionnaire.response?.anonymous === true) {
  return '';
}
  else if (questionnaire.status === 'closed') {
    const updatedAt = questionnaire.response && questionnaire.response.updatedAt;
    const updatedAtStr = !!updatedAt ? ` (${formatDate(updatedAt)})` : '';

    return `${statusStr}${updatedAtStr}`;
  } else if (isQuestionnaireExpired(questionnaire)) {
    return `Canceled (link expired on ${formatDate(questionnaire.expiresAt)})`;
  } else if (questionnaire.status === 'in_progress') {
    const updatedAt = questionnaire.updatedAt;
    const updatedAtStr = !!updatedAt ? ` Accessed (on ${formatDate(updatedAt)})` : '';

    return `${statusStr}${updatedAtStr}`;
  } else if (questionnaire.status === 'saved') {
    const updatedAt = questionnaire.updatedAt;
    const updatedAtStr = !!updatedAt ? ` (progress saved on ${formatDate(updatedAt)})` : '';

    return `${statusStr}${updatedAtStr}`;
  } else {
    return statusStr;
  }
};

const getQuestionnaireType = (questionnaire: IStakeholderQuestionnaireDto) => {
  return `${questionnaire.questionnaireTemplate.name} (v${questionnaire.questionnaireTemplate.version})`;
};

export interface StakeholderQuestionnaireResponseProps {
  onUpdateStakeholder?: (newStakeholderData: IStakeholderDto) => void;
  questionnaire: IStakeholderQuestionnaireDto;
  stakeholderData: IStakeholderDto;
  questionnaireTemplate: IStakeholderQuestionnaireTemplateDto;
  auth: Auth
}

export default function StakeholderQuestionnaireResponse({ onUpdateStakeholder, questionnaire, stakeholderData, questionnaireTemplate, auth }: StakeholderQuestionnaireResponseProps) {
  const classes = useStyles();
  const [orgName, setOrgName] = useState('');

  if (orgName === '') {
    setOrgName(auth.getOrgName());
  }

  const handleCancelQuestionnaire = async () => {
    try {
      const res = await API.patch(`stakeholder/${stakeholderData.id}/cancel-questionnaire/${questionnaire.id}`);
      const updatedStakeholder: IStakeholderDto = res.data.data;
      const updatedQuestionnaire = updatedStakeholder.questionnaires.find(q => q.id === questionnaire.id);

      if (updatedQuestionnaire && updatedQuestionnaire.status === 'canceled') {
        showSuccessResultBar('Questionnaire link canceled successfully');
      }
      if (onUpdateStakeholder) {
        onUpdateStakeholder(updatedStakeholder);
      }
    } catch (err) {
      handleError(err, 'Error canceling questionnaire link');
    }
  };

  // TODO: This is not a good way to do this, should be optimized more
  let STAKEHOLDER_QUESTIONS = [];

  const questionnaires = questionnaireTemplate.template['blocks'];

  for (let bb = 0; bb < questionnaires.length; bb++) {
    for (let qq = 0; qq < questionnaires[bb].questions.length; qq++) {
      if (Object.keys(questionnaires[bb].questions[qq]).indexOf('id') > -1) {
        STAKEHOLDER_QUESTIONS.push(
          {
            'id': questionnaires[bb].questions[qq].id,
            'text': questionnaires[bb].questions[qq].text.replace('<COMPANY>', orgName),
            'label': questionnaires[bb].questions[qq].label
          }
        );
      } else if (Object.keys(questionnaires[bb].questions[qq]).indexOf('issues') > -1) {
        for (let ii = 0; ii < questionnaires[bb].questions[qq].issues.length; ii++) {
          STAKEHOLDER_QUESTIONS.push(
            {
              'id': questionnaires[bb].questions[qq].issues[ii].id,
              'text': questionnaires[bb].questions[qq].issues[ii].text.replace('<COMPANY>', orgName),
              'label': questionnaires[bb].questions[qq].label
            }
          );
        };
      }
    }
  };

  return (
    <Accordion
      elevation={2}
      disabled={questionnaire.response?.anonymous ? questionnaire.response.anonymous : false}
    >
      <AccordionIconSummary>
        <Typography variant="body2" className={classes.questionnaireEntryHeading}>
          {getQuestionnaireType(questionnaire)}
        </Typography>
        {questionnaire.response?.anonymous === true ? (
          <Typography
          className={classes.questionnaireSecondaryHeading}
          variant="body2"
        >
         Anonymous Response
        </Typography>
        ) : (
          <Typography
          className={classes.questionnaireSecondaryHeading}
          variant="body2"
        >
          Sent ({formatDate(questionnaire.createdAt)})
        </Typography>
        )}
        <Typography variant="body2">
          {getQuestionnaireSubheading(questionnaire)}
        </Typography>

      </AccordionIconSummary>
      <AccordionDetails>
        <Grid container>
          <Grid
            item
            xs={12}
          >
            <Typography
              className={classes.questionnaireQuestion}
            >
              Sent to:
            </Typography>
            <Typography
              className={classes.questionnaireAnswer}
            >
              {questionnaire.sentTo}
            </Typography>
          </Grid>
          {(!!questionnaire.response && questionnaire.status === 'closed') ? (
            <>
              {STAKEHOLDER_QUESTIONS.map(question => {
                return (
                  <Grid
                    item
                    xs={12}
                    key={question.id}
                  >
                    <Typography
                      className={classes.questionnaireQuestion}
                    >
                      {question.text}
                    </Typography>
                    <Typography
                      gutterBottom
                      className={classes.questionnaireAnswer}
                    >
                      {!!question.label && `${question.label}: `}
                      {isQuestionnairePending(questionnaire) && (
                        <Typography
                          gutterBottom
                          className={classes.questionnaireAnswer}
                        >
                          {moment(questionnaire.expiresAt).fromNow()}
                        </Typography>
                      )}

                      {isResponseList(questionnaire.response.response[question.id]) ? (
                        <span className={classes.stakeholderResponseText}>
                          {questionnaire.response.response[question.id].join(', ')}
                        </span>
                      ) : (
                        <span className={classes.stakeholderResponseText}>
                          {questionnaire.response.response[question.id]}
                        </span>
                      )}
                    </Typography>
                  </Grid>
                );
              })}
            </>
          ) : (
            <>
              <Grid
                item
                xs={12}
              >
                <Typography
                  className={classes.questionnaireQuestion}
                >
                  {getQuestionnaireLinkInfoText(questionnaire)}
                </Typography>
                {isQuestionnairePending(questionnaire) && (
                  <Typography
                    gutterBottom
                    className={classes.questionnaireAnswer}
                  >
                    {moment(questionnaire.expiresAt).fromNow()}
                  </Typography>
                )}
              </Grid>
            </>
          )
          }
        </Grid>
      </AccordionDetails>
      {!isQuestionnaireExpired(questionnaire) && isQuestionnairePending(questionnaire) && (
        <AccordionActions
          className={classes.questionnaireEntryActions}
        >
          <Button
            size="small"
            color="primary"
            onClick={handleCancelQuestionnaire}
          >
            Cancel link
          </Button>
        </AccordionActions>
      )}
    </Accordion>
  );
}
