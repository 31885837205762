import {
  createStyles,
  TableCell,
  TableRow,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { History } from 'history';
import React, { useEffect, useState } from 'react';
import { IMaterialIssueContextDto } from '../../../backend/src/material-issue-context/interfaces';
import DataTableTitleWithButton from '../components/DataTableTitleWithButton';
import MaterialityEditDialog, { rankMap, materialIssueCategorySelection, IssueRankStr } from '../components/dialogs/MaterialityEditDialog';
import MaterialityIssueDetails, { issueStatusMapAbbreviated, typeMap } from '../components/MaterialityIssueDetails';
import { showErrorResultBar } from '../components/ResultSnackbar';
import SpioDataTable, { SortDirection, SpioDataTableColumn } from '../components/SpioDataTable';
import { formatDate, truncateString } from '../helpers';
import alphaHCircleImg from '../images/alpha-h-circle.svg';
import alphaLCircleImg from '../images/alpha-l-circle.svg';
import alphaMCircleImg from '../images/alpha-m-circle.svg';
import API from '../services/ApiService';
import Auth from '../services/AuthService';
import { IMaterialIssueDto } from '../../../backend/src/material-issue/interfaces/material-issue-dto.interface';

const styles = createStyles({
  iconContainer: {
    paddingLeft: '1rem',
    textAlign: 'left',
  },
  scoreContainer: {
    paddingLeft: '1rem',
    textAlign: 'left',
  },
});

export interface IIssueRankStrInfo {
  icon: JSX.Element;
  score: number;
}

// TODO: Prefer to use the new column option 'filterOptions.renderValue'
export const rankStrMap: {[ key in IssueRankStr ]: IIssueRankStrInfo} = {
  High: {
    icon: <img src={alphaHCircleImg} width="24" height="24" alt="high" />,
    score: rankMap.high.score,
  },
  Medium: {
    icon: <img src={alphaMCircleImg} width="24" height="24" alt="medium" />,
    score: rankMap.medium.score,
  },
  Low: {
    icon: <img src={alphaLCircleImg} width="24" height="24" alt="low" />,
    score: rankMap.low.score,
  },
  Unknown: {
    icon: <HelpIcon color="disabled" />,
    score: rankMap.unknown.score,
  },
};

const scoreCompare = (order: SortDirection) => {
  // Sort probability and impact based on a score.
  const reverseFactor = (order === 'desc' ? 1 : -1);

  return (a: { data: any }, b: { data: any }) => {
    const valA = a.data;
    const valB = b.data;
    const scoreA = rankStrMap[valA as IssueRankStr]?.score || 0;
    const scoreB = rankStrMap[valB as IssueRankStr]?.score || 0;
    const sortFactor = (scoreA < scoreB ? -1 : 1);

    return reverseFactor * sortFactor;
  };
};

const getTableColumns = (tableData: ITableDatum[], { classes }: WithStyles<typeof styles>): SpioDataTableColumn[] => [
  {
    name: 'id',
    label: 'ID',
    options: {
      display: 'false',
      filter: false,
    },
  },
  {
    name: 'materialIssueName',
    label: 'Material Issue',
  },
  {
    name: 'createdAtStr',
    label: 'Date Added',
    options: {
      display: 'false',
      filter: false,
    },
  },
  {
    name: 'statusUpdatedAtStr',
    label: 'Last Status Update',
    options: {
      customFilterListOptions: { render: v => `Last Status Update: ${v}` },
      display: 'false',
    },
  },
  {
    name: 'typeStr',
    label: 'Risk or Opportunity',
  },
  {
    name: 'issueCategoryStr',
    label: 'Issue Category',
    options: {
      // display: 'false'
    }
  },
  {
    name: 'materialRelevance',
    label: 'Material Relevance',
    options: {
      customFilterListOptions: { render: v => `Material Relevance: ${v}` },
      filter: false,
    },
  },
  {
    name: 'issueTypeStr',
    label: 'Issue Type',
    options: {
      customBodyRenderLite: dataIndex => truncateString(tableData[dataIndex]?.issueType || ''),
      customFilterListOptions: { render: v => `Issue Type: ${v}` },
    },
  },
  {
    name: 'statusStr',
    label: 'Status',
  },
  {
    name: 'details',
    label: 'Details',
    options: {
      customBodyRenderLite: dataIndex => truncateString(tableData[dataIndex]?.details || ''),
      display: 'false',
      filter: false,
    },
  },
  {
    name: 'owner',
    label: 'Owner',
    options: {
      customFilterListOptions: { render: v => `Owner: ${v}` },
      display: false,
      filter: true,
    },
  },
  {
    name: 'probabilityStr',
    label: 'Probability',
    options: {
      customBodyRenderLite: dataIndex => (
        <div className={classes.iconContainer}>
          {rankStrMap[tableData[dataIndex]?.probabilityStr as IssueRankStr]?.icon}
        </div>
      ),
      customFilterListOptions: { render: v => `Probability: ${v}` },
      sortCompare: scoreCompare,
    },
  },
  {
    name: 'impactStr',
    label: 'Impact',
    options: {
      customBodyRenderLite: dataIndex => (
        <div className={classes.iconContainer}>
          {rankStrMap[tableData[dataIndex]?.impactStr as IssueRankStr]?.icon}
        </div>
      ),
      customFilterListOptions: { render: v => `Impact: ${v}` },
      sortCompare: scoreCompare,
    },
  },
  {
    name: 'issueScore',
    label: 'Materiality Score',
    options: {
      customBodyRenderLite: dataIndex => (
        <div className={classes.scoreContainer}>
          {tableData[dataIndex]?.issueScore}
        </div>
      ),
      filter: false,
    },
  },
  {
    name: 'preparationPlan',
    label: 'Preparation Plan',
    options: {
      customBodyRenderLite: dataIndex => truncateString(tableData[dataIndex]?.preparationPlan || ''),
      customFilterListOptions: { render: v => `Preparation Plan: ${v}` },
      display: 'false',
      filter: false,
    },
  },
  {
    name: 'actionPlan',
    label: 'Action Plan',
    options: {
      customBodyRenderLite: dataIndex => truncateString(tableData[dataIndex]?.actionPlan || ''),
      customFilterListOptions: { render: v => `Action Plan: ${v}` },
      display: 'false',
      filter: false,
    },
  },
];

export interface TheMaterialityRegisterPageProps extends WithStyles<typeof styles> {
  auth: Auth;
  history: History;
}

interface ITableDatum extends IMaterialIssueContextDto {
  createdAtStr: string;
  impactStr: string;
  probabilityStr: string;
  issueTypeStr: string;
  statusUpdatedAtStr: string;
  issueScore: string;
}

function TheMaterialityRegisterPage({ auth, classes, history }: TheMaterialityRegisterPageProps) {
  const [ isDialogOpen, setIsDialogOpen ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ issues, setIssues ] = useState<IMaterialIssueContextDto[]>([]);
  const [ tableData, setTableData ] = useState<ITableDatum[]>([]);
  const [ materialityRegisterLimit, setMaterialityRegisterLimit ] = useState(0);
  const [ allMaterialIssues, setAllMaterialIssues ] = useState<IMaterialIssueDto[]>([]);

  useEffect(() => {
    setIsLoading(true);

    API.get('materiality')
      .then(res => setIssues((res.data && res.data.data) || []))
      .catch(() => {
        showErrorResultBar('Unexpected error loading materiality register data');
      })
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    setIsLoading(true);

    API.get('material-issue/allActiveIssues')
      .then(res => setAllMaterialIssues((res.data && res.data.data) || []))
      .catch(() => {
        showErrorResultBar('Unexpected error loading material issues');
      })
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    setMaterialityRegisterLimit(auth.getMaterialityRegisterLimit());
  }, [auth]);

  const materialityRegisterLimitReached = (!isLoading && issues.length < materialityRegisterLimit ? false : true);
  useEffect(() => {
    setTableData(issues.map(d => Object({
      ...d,
      issueCategoryStr: materialIssueCategorySelection[d.materialIssue?.issueCategory || 'unknown'],
      issueTypeStr: d.issueType ?? '',
      createdAtStr: formatDate(d.createdAt),
      impactStr: rankMap[d.impact].text,
      probabilityStr: rankMap[d.probability].text,
      statusStr: issueStatusMapAbbreviated[d.status] || 'Unknown',
      typeStr: typeMap[d.riskOpportunityType] || 'Risk',
      statusUpdatedAtStr: formatDate(d.statusUpdatedAt),
      materialIssueName: d.materialIssue?.name || '',
      issueScore: (d.materialIssue?.orgMaterialIssues && d.materialIssue?.orgMaterialIssues.length > 0) ? d.materialIssue.orgMaterialIssues[0].stakeholderScore ? Math.round(d.materialIssue.orgMaterialIssues[0].stakeholderScore*10)/10 : 'N/A' : 'N/A',
    })));
  }, [ issues ]);

  const handleArchive = (selectedIdx: number) => () => {
    const updatedData = issues.slice();
    updatedData.splice(selectedIdx, 1);
    setIssues(updatedData);
  };

  const handleCreate = (issue: IMaterialIssueContextDto) => {
    const updatedData = [ issue ].concat(issues);
    setIssues(updatedData);
  };

  const handleUpdate = (selectedIdx: number) => (issue: IMaterialIssueContextDto) => {
    const updatedData = issues.slice();
    updatedData[selectedIdx] = issue;
    setIssues(updatedData);
  };

  return (<>
    <SpioDataTable
      title={<DataTableTitleWithButton
        onButtonClick={() => setIsDialogOpen(true)}
        title="Materiality Register"
        limitReached={materialityRegisterLimitReached}
        disabled={!auth.isGranted({permission:'materiality_register:edit'})}
      />}
      columns={getTableColumns(tableData, { classes })}
      data={tableData}
      options={{
        print: false,
        filterType: 'multiselect',
        selectableRows: 'none',
        textLabels: {
          body: {
            noMatch: isLoading ? 'Loading...' : 'No records found',
            toolTip: 'Sort',
          },
        },
        expandableRows: true,
        expandableRowsOnClick: true,
        renderExpandableRow: (rowData, rowMeta) => {
          const colSpan = rowData.length + 1;
          const myData = issues[rowMeta.dataIndex];

          return myData ? (
            <TableRow>
              <TableCell colSpan={colSpan}>
                <MaterialityIssueDetails
                  auth={auth}
                  history={history}
                  issueData={myData}
                  onArchive={handleArchive(rowMeta.dataIndex)}
                  onUpdate={handleUpdate(rowMeta.dataIndex)}
                  allMaterialIssues={allMaterialIssues}
                />
              </TableCell>
            </TableRow>
          ) : null;
        },
      }}
    />
    <MaterialityEditDialog
      open={isDialogOpen}
      issueData={null}
      onClose={() => setIsDialogOpen(false)}
      onUpdate={handleCreate}
      allMaterialIssues={allMaterialIssues}
    />
  </>);
}

TheMaterialityRegisterPage.title = 'Materiality Register';
TheMaterialityRegisterPage.requiredAuthZ = {
  tier: 3,
  permission: 'materiality_register',
};

export default withStyles(styles)(TheMaterialityRegisterPage);
