import {
  Card,
  CardHeader,
  Typography,
  Grid,
  withStyles,
  WithStyles,
  createStyles,
  Theme
} from '@material-ui/core';import * as Sentry from '@sentry/browser';
import React, { useEffect, useState } from 'react';
import { ICreateOrgDto, IOrgDto, IUpdateOrgDto } from '../../../backend/src/org/interfaces';
import { OrgGeneralSettings } from '../components/org';
import { showErrorResultBar, showSuccessResultBar } from '../components/ResultSnackbar';
import API, { ErrorCodes as ApiErrorCodes } from '../services/ApiService';
import Auth from '../services/AuthService';
import { handleError } from '../helpers';

const styles = (theme: Theme) => createStyles({
  switchField: {
    marginLeft: 0,
  },
  headerCard: {
    width: '100%',
    margin: 0,
  },
  primaryCards: {
    paddingBottom: '1.5rem',
  },
  orgInfo: {
    marginLeft: '1.5rem',
  },
  leftIcon: {
    marginRight: '0.5rem',
  },
  addOrgButton: {
    marginTop: 0,
    marginBottom: 0,
  },
  stylizedName: {
    color: '#000000'
  }
});

export interface IOrgForm {
  autoEnroll: boolean;
  kmsKeyArn: string | null;
  name: string;
  notifications: boolean;
  ownerId?: string;
  policyApprover: string;
  policyOwner: string;
}

export interface IOrg extends IOrgForm {
  tier: number;
}

export interface TheOrganizationPageProps extends WithStyles<typeof styles> {
  auth: Auth;
}

function TheOrganizationPage({ auth, classes }: TheOrganizationPageProps) {
  const [org, setOrg] = useState<IOrg>({
    autoEnroll: !auth.getIsEmailFree() && auth.isGranted({ tier: 3}),
    kmsKeyArn: '',
    name: '',
    notifications: false,
    policyApprover: '',
    policyOwner: '',
    tier: 0,
  });
  const [selectedTabIdx, setSelectedTabIdx] = useState<number>(0);
  const [orgInfo, setOrgInfo] = useState<any>();

  const isRegistered = auth.getOrgId() !== undefined;

  useEffect(() => {
    if (isRegistered) {
      API.get('org/orgInfo')
      .then((res) => {
        setOrgInfo(res.data ? res.data.data : []);

      });
      // .finally(() => setIsLoading(false));
    } else {
    }
  }, [auth, isRegistered]);

  useEffect(() => {
    if (isRegistered) {
      API.get(`org/${auth.getOrgId()}`)
        .then((res) => {
          const theOrg: IOrgDto = (res.data && res.data.data) ? res.data.data : {};

          if (!theOrg.policyOwner) {
            theOrg.policyOwner = auth.getUserName();
          }

          if (!theOrg.policyApprover) {
            theOrg.policyApprover = auth.getUserName();
          }

          setOrg(prevOrg => ({ ...prevOrg, ...theOrg }));
        })
        .catch(err => {
          Sentry.captureException(err);
          showErrorResultBar('An error occurred while loading your settings');
        });
    } else {
      const defaultOwner: string = auth.getUserName();

      setOrg(prevOrg => ({ ...prevOrg, policyOwner: defaultOwner, policyApprover: defaultOwner }));
    }
  }, [auth, isRegistered]);

  const saveOrg = async (formValues: IOrgForm) => {
    try {
      if (org.ownerId === undefined) {
        const createOrg: ICreateOrgDto = {
          ...formValues,
          ownerId: auth.getUserId(),
          kmsKeyArn: formValues.kmsKeyArn === '' ? null : formValues.kmsKeyArn,
        };

        await API.post('org', createOrg);

        await auth.renewSession();
      } else {
        const updateOrg: IUpdateOrgDto = {
          ...formValues,
          kmsKeyArn: formValues.kmsKeyArn === '' ? null : formValues.kmsKeyArn,
        };

        const res = await API.patch(`org/${auth.getOrgId()}`, updateOrg);

        setOrg(res.data.data);
        showSuccessResultBar('Settings successfully updated.');
      }
    } catch (err) {
      const axiosError = err as any;
      switch (axiosError?.response?.data.statusCode) {
        case ApiErrorCodes.DUPLICATE_VALUE:
          const dupDetails = axiosError.response.data.data;
          const errorMessage = `That organization ${dupDetails.field} is already taken.\nPlease choose another.`;
          showErrorResultBar(errorMessage);
          break;

        default:
          showErrorResultBar('An error occurred while saving your settings.');
      }
    }
  };

  // const handleTabChange = (_: any, value: number) => {
  //   setSelectedTabIdx(value);
  // };

  return (
    <>
      {/* <Tabs value={selectedTabIdx} onChange={handleTabChange}>
        <Tab
          label="General"
        />
      </Tabs>
      {selectedTabIdx === 0 && */}
        {(auth.isPrimaryOrg() || auth.isGranted({ permission: 'admin:change_org'})) &&
          <Grid className={classes.primaryCards}>
          <Card className={classes.headerCard}>
            <CardHeader
              title={isRegistered ? 'Organization Information' : (<span>Welcome to Gemini</span>)}
            />
            <Grid className={classes.orgInfo}>
              <Grid container>
                <Grid item xs={2}>
                  <Typography variant="body2">
                    Organization name:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body1">
                    {orgInfo?.name || ''}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={2}>
                  <Typography variant="body2">
                    Owner email:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body1">
                  {orgInfo?.ownerEmail || ''}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Card>
          </Grid>
        }
        {auth.isGranted({permission: 'org:edit'}) &&
          <OrgGeneralSettings
            auth={auth}
            isRegistered={isRegistered}
            onClickSave={saveOrg}
            org={org}
          />
        }

      {/* }
      {selectedTabIdx === 1 &&
        <OrgSubscriptionSettings
          org={org}
        />
      }
      {selectedTabIdx === 2 &&
        <OrgBillingSettings
          org={org}
        />
      } */}
    </>
  );
}

TheOrganizationPage.requiredAuthZ = {
  tier: 1,
  permission: 'org',
};
TheOrganizationPage.routePath = '/org';
TheOrganizationPage.title = 'Organization Settings';

export default withStyles(styles, { withTheme: true })(TheOrganizationPage);
