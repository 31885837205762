import {
  Card,
  CardContent,
  CardHeader,
  createStyles,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  StandardProps,
  Theme,
  Tooltip,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import { TypographyProps } from '@material-ui/core/Typography';
import HelpIcon from '@material-ui/icons/HelpOutline';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles } from '@material-ui/styles';
import * as Sentry from '@sentry/browser';
import classnames from 'classnames';
import { History } from 'history';
import React, { useState } from 'react';
import { IssueStatus, RiskOpportunityType } from '../../../backend/src/material-issue-context/enums';
import { MaterialIssueCategory } from '../../../backend/src/material-issue/enums';
import { IMaterialIssueContextDto } from '../../../backend/src/material-issue-context/interfaces';
import { IMaterialIssueDto } from '../../../backend/src/material-issue/interfaces/material-issue-dto.interface';
import { formatDate } from '../helpers';
import API from '../services/ApiService';
import Auth from '../services/AuthService';
import { CloseButton } from './buttons';
import { ArchiveDialog, MaterialityEditDialog } from './dialogs';
import { HELPER_TEXT, rankMap, issueStatusSelections, materialIssueCategorySelection, typeSelections, issueStatusSelectionsAbbreviated } from './dialogs/MaterialityEditDialog';
import { showErrorResultBar, showSuccessResultBar } from './ResultSnackbar';
// TODO move this to shared space since also used in Stakeholder?
import { MissingDataText, SecondaryField } from './VendorDetailsInfo';
import { handleError } from '../helpers';

const styles = (theme: Theme) => createStyles({
  buttonLink: {
    'color': 'inherit',
    'textDecoration': 'underline',
    'cursor': 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  cardHeader: {
    paddingBottom: 0,
  },
  dialogCardContent: {
    margin: theme.spacing(2),
    overflowY: 'auto',
  },
  dialogCardRoot: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    margin: '0',
    padding: '0',
  },
  helpIcon: {
    'fontSize': '18px',
    'marginBottom': '-1px',
    'marginLeft': theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  linkItem: {
    '& a': {
      color: theme.typography.body1.color,
      cursor: 'pointer',
    },
    '&:nth-child(n+2)': {
      paddingTop: '4px',
    },
  },
  primaryColumn: {
    color: theme.typography.body1.color,
    fontSize: '14px',
  },
  referencesContainer: {
    paddingLeft: '1rem',
    paddingBottom: '0.8rem',
  },
  referenceResponse: {
    color: 'rgba(0,0,0,0.54)',
    whiteSpace: 'pre-line',
  },
  secondaryColumn: {
    backgroundColor: '#f5f5f5',
    opacity: '0.8',
    fontSize: '0.8125rem',
  },
});

const useDetailSectionHeadingStyles = makeStyles({
  referenceHeading: {
    fontSize: '1rem',
  },
});

export interface DetailSectionHeadingProps extends StandardProps<TypographyProps, 'children'> { }

export function DetailSectionHeading({ children }: DetailSectionHeadingProps) {
  const classes = useDetailSectionHeadingStyles();

  return (
    <Typography
      variant="h6"
      className={classes.referenceHeading}
      gutterBottom
    >
      {children}
    </Typography>
  );
}

export const issueStatusMap: { [key in IssueStatus]: string } = {
  ...issueStatusSelections,
  archived: 'Archived',
};

export const typeMap: { [key in RiskOpportunityType]: string } = {
  ...typeSelections,
};

export const issueStatusMapAbbreviated: { [key in IssueStatus]: string } = {
  ...issueStatusSelectionsAbbreviated,
  archived: 'Archived',
};

export const materialIssueCategoryMap: { [key in MaterialIssueCategory]: string } = {
  ...materialIssueCategorySelection,
};

export interface MaterialityIssueDetailsProps extends WithStyles<typeof styles> {
  auth: Auth;
  disableTaskClickThrough?: boolean;
  history: History;
  isDialog?: boolean;
  onArchive: () => void;
  onCloseDialog?: () => void;
  onUpdate: (issue: IMaterialIssueContextDto) => void;
  issueData: IMaterialIssueContextDto;
  allMaterialIssues: IMaterialIssueDto[];
}

function MaterialityIssueDetails(props: MaterialityIssueDetailsProps) {
  const {
    auth,
    classes,
    disableTaskClickThrough = false,
    history,
    isDialog = false,
    onArchive,
    onCloseDialog = () => undefined,
    onUpdate,
    issueData,
    allMaterialIssues
  } = props;

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [toShowArchiveDialog, setToShowArchiveDialog] = useState(false);
  const [toShowEditDialog, setToShowEditDialog] = useState(false);

  const handleArchive = async () => {
    try {
      await API.patch(`materiality/${issueData.id}`, { status: 'archived' });
      onArchive();
      showSuccessResultBar('Entry archived');
    } catch (err) {
      handleError(err, `Unexpected error while archiving: ${err || 'unspecified error'}`);
    }
  };

  const handleClickMenuItem = (handler: () => void) => () => {
    handleCloseMenu();
    handler();
  };

  const handleCloseArchiveDialog = () => setToShowArchiveDialog(false);

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const handleConfirmArchive = async () => {
    handleArchive();
    handleCloseArchiveDialog();
  };

  return (
    <>
      <Card className={classnames(isDialog && classes.dialogCardRoot)}>
        <CardHeader
          action={<>
            <IconButton onClick={({ currentTarget }) => setMenuAnchorEl(currentTarget)}>
              <MoreVertIcon />
            </IconButton>
            {isDialog && <CloseButton onClick={onCloseDialog} />}
          </>}
          className={classnames(!isDialog && classes.cardHeader)}
          title={issueData.materialIssue?.name ? `${issueData.materialIssue.name || ''} - ${issueData.materialRelevance}` : issueData.materialRelevance}
          subheader={`Status: ${issueStatusMap[issueData.status]}`}
        />
        <Menu
          anchorEl={menuAnchorEl}
          keepMounted
          open={Boolean(menuAnchorEl)}
          onClose={handleCloseMenu}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <MenuItem onClick={handleClickMenuItem(() => setToShowEditDialog(true))} disabled={!auth.isGranted({permission:'materiality_register:edit'})}>
            Edit
          </MenuItem>
          <MenuItem onClick={handleClickMenuItem(() => setToShowArchiveDialog(true))} disabled={!auth.isGranted({permission:'materiality_register:edit'})}>
            Archive
          </MenuItem>
        </Menu>
        {isDialog && <Divider />}
        <CardContent className={classnames(isDialog && classes.dialogCardContent)}>
          <Grid container alignItems="flex-start" spacing={4}>
            {/* Primary column */}
            <Grid item xs={12} sm={8} lg={isDialog ? 8 : 9} className={classes.primaryColumn}>
              {/* Details */}
              <DetailSectionHeading>Description</DetailSectionHeading>
              <div className={classes.referencesContainer}>
                <Grid container>
                <Grid item xs={3}>Material Relevance:</Grid>
                  <Grid item xs={9} className={classes.referenceResponse}>
                    {issueData.materialRelevance || <MissingDataText message="None specified" />}
                  </Grid>
                <Grid item xs={3}>Risk or Opportunity:</Grid>
                  <Grid item xs={9} className={classes.referenceResponse}>
                    {typeSelections[issueData.riskOpportunityType] || ''}
                  </Grid>
                  <Grid item xs={3}>Issue Category:</Grid>
                  <Grid item xs={9} className={classes.referenceResponse}>
                    {materialIssueCategorySelection[issueData.materialIssue?.issueCategory || 'unknown']}
                  </Grid>
                  <Grid item xs={3}>Material Issue:</Grid>
                  <Grid item xs={9} className={classes.referenceResponse}>
                    {issueData.materialIssue?.name || <MissingDataText message="None specified" />}
                  </Grid>
                  <Grid item xs={3}>Issue Type:</Grid>
                  <Grid item xs={9} className={classes.referenceResponse}>
                    {issueData.issueType || <MissingDataText message="None specified" />}
                  </Grid>
                  <Grid item xs={3}>Key Indicator Description:</Grid>
                  <Grid item xs={9} className={classes.referenceResponse}>
                    {issueData.details || <MissingDataText message="None" />}
                  </Grid>
                </Grid>
              </div>
              {/* Preparation plan */}
              <DetailSectionHeading>
                Preparation plan
                <Tooltip title={HELPER_TEXT.preparationPlan}>
                  <HelpIcon color="action" className={classes.helpIcon} />
                </Tooltip>
              </DetailSectionHeading>
              <div className={classes.referencesContainer}>
                {issueData.preparationPlan ? (
                  <span className={classes.referenceResponse}>{issueData.preparationPlan}</span>
                ) : (
                  <MissingDataText message="None" />
                )}
              </div>
              {/* Action plan */}
              <DetailSectionHeading>
                Action plan
                <Tooltip title={HELPER_TEXT.actionPlan}>
                  <HelpIcon color="action" className={classes.helpIcon} />
                </Tooltip>
              </DetailSectionHeading>
              <div className={classes.referencesContainer}>
                {issueData.actionPlan ? (
                  <span className={classes.referenceResponse}>{issueData.actionPlan}</span>
                ) : (
                  <MissingDataText message="None" />
                )}
              </div>
            </Grid>
            {/* Secondary column */}
            <Grid item xs={12} sm={4} lg={isDialog ? 4 : 3} className={classes.secondaryColumn}>
              {/* Lifecyle Dates */}
              <DetailSectionHeading>Lifecyle Dates</DetailSectionHeading>
              <div className={classes.referencesContainer}>
                <SecondaryField
                  title="Reported:"
                  value={formatDate(issueData.reportedAt) || ''}
                />
                <SecondaryField
                  title="Reviewed:"
                  value={formatDate(issueData.reviewedAt) || ''}
                />
                <SecondaryField
                  title="Status updated:"
                  value={formatDate(issueData.statusUpdatedAt) || ''}
                />
                <SecondaryField
                  title="Last updated:"
                  value={formatDate(issueData.updatedAt) || ''}
                />
              </div>
              {/* Owner */}
              <DetailSectionHeading>Owner</DetailSectionHeading>
              <div className={classes.referencesContainer}>
                {issueData.owner || <MissingDataText message="None specified" />}
              </div>
              {/* Analysis */}
              <DetailSectionHeading>Analysis</DetailSectionHeading>
              <div className={classes.referencesContainer}>
                <SecondaryField
                  title="Materiality score: "
                  value={(issueData.materialIssue?.orgMaterialIssues && issueData.materialIssue?.orgMaterialIssues.length > 0) ? issueData.materialIssue.orgMaterialIssues[0].stakeholderScore ? issueData.materialIssue.orgMaterialIssues[0].stakeholderScore : 'Not Included' : 'Not Included'}
                />
                <SecondaryField
                  title="Probability:"
                  value={rankMap[issueData.probability].text || rankMap.unknown.text}
                />
                <SecondaryField
                  title="Impact:"
                  value={rankMap[issueData.impact].text || rankMap.unknown.text}
                />
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {/* Edit dialog */}
      <MaterialityEditDialog
        open={toShowEditDialog}
        issueData={issueData}
        onClose={() => setToShowEditDialog(false)}
        onUpdate={onUpdate}
        allMaterialIssues={allMaterialIssues}
      />
      {/* Archive dialog */}
      <ArchiveDialog
        open={toShowArchiveDialog}
        onClose={handleCloseArchiveDialog}
        onArchive={handleConfirmArchive}
        title={`Archive the issue '${issueData?.materialIssue}'?`}
      />
    </>
  );
}

export default withStyles(styles, { name: 'MaterialityIssueDetails', withTheme: true })(MaterialityIssueDetails);
