import {
    TableCell,
    TableRow,
    withStyles,
    WithStyles,
    createStyles,
    Theme,
    createTheme,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
  MUIDataTableExpandButton,
  MUIDataTableColumn
} from 'mui-datatables';
import { IInboundQuestionnaireDto } from '../../../backend/src/inbound-questionnaire/interfaces';
import { IMaterialityAssessmentResponseCountDto } from '../../../backend/src/material-issue/interfaces/material-assessment-response-count-dto.interface';
import { IMaterialityAssessmentResponseIssuesDto } from '../../../backend/src/material-issue/interfaces/material-assessment-response-dto.interface';
import MaterialityAssessmentIssuesDetails from './MaterialityAssessmentIssuesDetails';
import SpioDataTable, {
  SpioDataTableColumn,
} from '../components/SpioDataTable';
import {
    ExpandButton,
  } from 'mui-datatables';

const styles = (theme: Theme) =>
  createStyles({
    [theme.breakpoints.up('md')]: {
      commentCell: {
        textAlign: 'center',
      },
      commentCellText: {
        paddingLeft: '2px',
      },
    },
});

const theme = createTheme({
    overrides: {
      MUIDataTableSelectCell: {
        expandDisabled: {
          // Soft hide the button.
          visibility: 'hidden',
        },
      },
    },
  });

  const components = {
    ExpandButton: function(props: MUIDataTableExpandButton) {
      if (props.dataIndex === 0) return <div style={{ width: '24px' }} />;
      return <ExpandButton {...props} />;
    },
  };

  const getTableColumns = (tableData: ITableDatum[], issues: IMaterialityAssessmentResponseIssuesDto[], responseCount: IMaterialityAssessmentResponseCountDto): SpioDataTableColumn[] => {
    let columns =
    [
        {
            name: 'id',
            label: 'ID',
            options: {
                display: false,
                filter: false,
            },
        },
        {
            name: 'issueName',
            label: 'Material Issue Name',
        },
        {
            name: 'issueCategory',
            label: 'Issue Category',
            options: {
                display: false,
                filter: true,
            },
        },
        {
            name: 'allSummary',
            label: 'All',
            options: {
                display: true,
                filter: false,
            },
        }
    ];

    if (Object.keys(issues[0].filters).length > 0) {
        for (const filter of Object.keys(issues[0].filters)) {
            for (const option of Object.keys(issues[0].filters[filter])) {
              let optionName = option;
              if (option === 'Other' || option === 'other') { // the table will confuse identical names, so rename "other"
                optionName = `${option} (${filter})`;
              }
              if (columns.filter((column: MUIDataTableColumn) => column.options?.display).length < 9 && responseCount?.filters[filter][option] !== 0) {
                columns.push({
                  name: optionName,
                  label: optionName,
                  options: {
                      display: true,
                      filter: false,
                  },
              });
              } else {
                columns.push({
                  name: optionName,
                  label: optionName,
                  options: {
                      display: false,
                      filter: false,
                  },
              });
              }
            }
        }
    };

     return columns;
};

interface MaterialityAssessmentIssuesTableProps extends WithStyles<typeof styles> {
  issues: IMaterialityAssessmentResponseIssuesDto[];
  responseCount: IMaterialityAssessmentResponseCountDto;
  filterOptions: {[key: string]: string}[] | [];
}

interface ITableDatum extends IInboundQuestionnaireDto {
  category: string;
  name: string;
}

function MaterialityAssessmentIssuesTable(props: MaterialityAssessmentIssuesTableProps) {
  const {
    issues,
    responseCount,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState<ITableDatum[]>([]);

  useEffect(() => {
    const issueTableData = () => {
        return issues.map((i, idx) => {
            const filteredResponses: { [key: string]: number | string }  = { };
            for (const filter of Object.keys(issues[0].filters)) {
                for (const option of Object.keys(issues[0].filters[filter])) {
                    let optionName = option;
                    if (option === 'Other' || option === 'other') { // the table will confuse identical names, so rename "other"
                      optionName = `${option} (${filter})`;
                    }
                    filteredResponses[optionName] =  i.filters[filter][option] ? Math.round((i.filters[filter][option] || 0)*10)/10 : '-';
                }
            }
            return Object({
                ...i,
                issueName: i.info.name,
                issueCategory: i.info.category,
                allSummary: i.all ? Math.round(i.all*10)/10 : '-',
                ...filteredResponses
            });
        });
    };

    const responseCountSummary: { [key: string]: number | null }  = { };
    for (const filter of Object.keys(issues[0].filters)) {
        for (const option of Object.keys(issues[0].filters[filter])) {
          let optionName = option;
          if (option === 'Other' || option === 'other') { // the table will confuse identical names, so rename "other"
            optionName = `${option} (${filter})`;
          }
            responseCountSummary[optionName] = responseCount?.filters[filter][option];
        }
    }

    const allTabledata = issueTableData();
    allTabledata.unshift(
        Object({
            issueName: 'Number of Responses',
            allSummary: responseCount?.all,
            ...responseCountSummary
        }));

    setTableData(allTabledata);
  }, [responseCount, issues]);

  return (
    <>
      <SpioDataTable
        title="Material Issues"
        columns={getTableColumns(tableData, issues, responseCount)}
        data={tableData}
        components={components}
        options={{
          print: false,
          filterType: 'multiselect',
          selectableRows: 'none',
          textLabels: {
            body: {
              noMatch: isLoading ? 'Loading...' : 'No records found',
              toolTip: 'Sort',
            },
          },
          expandableRows: true,
          expandableRowsOnClick: true,
          expandableRowsHeader: false,
          isRowExpandable: (dataIndex, expandedRows) => {
            if (dataIndex === 0) return false;
            return true;
          },
          setRowProps: (row, dataIndex, rowIndex) => {
            if (dataIndex === 0) {
                return {
                    style: { background: '#F8F8F8'}
                };
            } else {
                return {};
            }
          },
          renderExpandableRow: (rowData, rowMeta) => {
            const colSpan = rowData.length + 1;
            const myData = issues[rowMeta.dataIndex - 1];

            return myData ? (
              <TableRow>
                <TableCell colSpan={colSpan}>
                    <MaterialityAssessmentIssuesDetails
                        issueDetails={myData}
                        allIssues={issues}
                    />
                </TableCell>
              </TableRow>
            ) : null;
          },
          customSort: (data, colIndex, order) => {
            // add custom sort to always keep number of responses as first row
            return data.slice(0,1).concat(data.slice(1,).sort((a, b) => {
            a = a.data[colIndex] || '';
            b = b.data[colIndex] || '';
            if (order === 'asc') {
            return a.toString().localeCompare(b, undefined, { numeric: true });
            } else {
            return b.toString().localeCompare(a, undefined, { numeric: true });
            }
            }));
            }
        }}
      />
    </>
  );
}

export default withStyles(styles, { withTheme: true })(MaterialityAssessmentIssuesTable);
