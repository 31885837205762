import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React, { forwardRef } from 'react';

export default forwardRef<any, SvgIconProps>(function AwsGovCloud(props: SvgIconProps, ref) {
  return (
    <SvgIcon {...props} ref={ref}>
      <path d="M19.35,10.04C18.67,6.59 15.64,4 12,4C9.11,4 6.6,5.64 5.35,8.04C2.34,8.36 0,10.91 0,14C0,17.31 2.69,20 6,20L19,20C21.76,20 24,17.76 24,15C24,12.36 21.95,10.22 19.35,10.04Z" />
      <path d="M0,0L24,0L24,24L0,24L0,0ZM0,0L24,0L24,24L0,24L0,0Z" fill="none" />
      <g transform="matrix(1,0,0,1,-7.5,-4.5)">
          <path d="M22,16L22,14.5C22,13.12 20.88,12 19.5,12C18.12,12 17,13.12 17,14.5L17,16C16.45,16 16,16.45 16,17L16,21C16,21.55 16.45,22 17,22L22,22C22.55,22 23,21.55 23,21L23,17C23,16.45 22.55,16 22,16ZM21,16L18,16L18,14.5C18,13.67 18.67,13 19.5,13C20.33,13 21,13.67 21,14.5L21,16Z" fill="white" />
      </g>
    </SvgIcon>
  );
});
