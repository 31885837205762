import { withStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import AuthRoute from '../components/AuthRoute';
import TheAppBar from '../components/TheAppBar';
import TheTermsFooter from '../components/TheTermsFooter';
import TheNavDrawer from '../pages/TheNavDrawer';
import { useHubSpotChat } from '../hooks';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },

  content: {
    flexGrow: 1,
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },

  main: {
    flex: '1 0 auto',
    padding: theme.spacing(3),
    [theme.breakpoints.up('lg')]: {
      maxWidth: '85vw', // Adjust the width when the screen is large
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '100vw', // Full width when the screen is small
    },
    overflowX: 'hidden', // Prevent horizontal scroll
  },
});

function TheDashboardLayout(props) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [badges] = useState({});
  const [activeOrgId, setActiveOrgId] = useState(props.auth.getOrgId());
  const { auth, classes, component: Component, history, title, ...rest } = props;
  const isAuthorized = auth.isGranted(Component.requiredAuthZ);
  useHubSpotChat(auth);

  function handleDrawerToggle() {
    setIsDrawerOpen(!isDrawerOpen);
  }

  function handleSwitchOrg(activeOrgId) {
    setActiveOrgId(activeOrgId);
  }

  return (
    <AuthRoute
      auth={auth}
      {...rest}
      render={(matchProps) => (
        <div className={classes.root}>
          <TheNavDrawer
            {...matchProps}
            open={isDrawerOpen}
            onClose={handleDrawerToggle}
            container={props.container}
            badges={badges}
          />

          <div className={classes.content}>
            <TheAppBar
              auth={auth}
              handleDrawerToggle={handleDrawerToggle}
              history={history}
              onSwitchOrg={handleSwitchOrg}
              title={title || Component.title}
            />

            <main className={classes.main}>
              {isAuthorized ? (
                <Component
                  key={activeOrgId}
                  {...matchProps}
                />
              ) : (
                <>
                { auth.isPrimaryOrg() ? (
                  <Redirect to="/subscription" />
                ) : (
                  <Redirect to="/dashboard" />
                )}
                </>
              )}
            </main>

            <TheTermsFooter />
          </div>
        </div>
      )}
    />
  );
}

export default withRouter(withStyles(styles, { withTheme: true })(TheDashboardLayout));
