import React, { useState, useEffect } from 'react';
import {
    Card,
    CardContent,
    createStyles,
    Grid,
    Link,
    makeStyles,
    Tab,
    TabProps,
    Tabs,
    Theme,
    Typography,
    withStyles,
    Button,
} from '@material-ui/core';
import { History } from 'history';
import { ITaskCollectionDto, ITaskStatusCount, ITaskStatusCountByGroup } from '../../../../backend/src/task/interfaces';
import Auth from '../../services/AuthService';
import { TaskStatusByGroup } from '../widgets';
import { Add as AddIcon } from '@material-ui/icons';
import { truncateString } from '../../helpers';
import { grey } from '@material-ui/core/colors';
import { InformationDialog } from '../dialogs';

export interface ITaskStatusByGroupTabProps {
  auth: Auth;
  history: History;
  taskOverallSummary: ITaskStatusCount[][];
  taskGroupsSummary: ITaskStatusCountByGroup[][];
  collections: ITaskCollectionDto[];
}

interface IButtonInTabProps {
  onClick: () => void;
  children: React.ReactNode;
  className: string;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      overflow: 'hidden',
    },
    tabsContainer: {
      marginTop: '-5px',
      maxHeight: '60px',
      overflowX: 'auto',
      whiteSpace: 'nowrap',
      display: 'flex',
    },
    widgetContainer: {
      marginTop: '15px',
      marginLeft: '15px',
    },
    cardContent: {
      overflowX: 'auto',
      maxWidth: 'calc(100vw - 64px)',
      '&:last-child': {
        paddingBottom: '12px',
      },
    },
    clickableLink: {
      cursor: 'pointer',
    },
    addButton: {
      backgroundColor: grey[100],
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.shape.borderRadius,
      fontSize: '1.25rem',
      fontWeight: theme.typography.fontWeightRegular,
      '&:hover': {
        backgroundColor: grey[300],
      },
    },
  })
);

const StyledTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      fontSize: '1.25rem',
      fontWeight: theme.typography.fontWeightRegular,
      letterSpacing: '0.0075em',
      paddingTop: '0',
      textTransform: 'none',
      margin: theme.spacing(0, 1),
      minWidth: 'auto',
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(0.5, 2),
      backgroundColor: grey[100],
      transition: 'background-color 0.3s',
      '&:hover': {
        backgroundColor: grey[300],
      },
    },
    selected: {
      fontWeight: theme.typography.fontWeightMedium,
      backgroundColor: grey[200],
    },
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
)((props: TabProps) => <Tab {...props} />);

const countToNumber = (countRaw?: number | string) => {
  return typeof countRaw === 'string' ? parseInt(countRaw, 10) : (countRaw || 0);
};

function TaskStatusByGroupTab({ auth, history, taskOverallSummary, taskGroupsSummary, collections }: ITaskStatusByGroupTabProps) {
  const [collectionOverallSummary, setCollectionOverallSummary] = useState<ITaskStatusCount[]>([]);
  const [collectionGroupsSummary, setCollectionGroupsSummary] = useState<ITaskStatusCountByGroup[]>([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const classes = useStyles();
  const [percentComplete, setPercentComplete] = useState(0);
  const [showAddCollectionDialog, setShowAddCollectionDialog] = useState(false);

  useEffect(() => {
    if (selectedTab === 0) {
      const flattened: ITaskStatusCount[] = taskOverallSummary.flat();
      const aggregated = flattened.reduce<Record<string, number>>((acc, { status, count }) => {
        if (!acc[status]) {
          acc[status] = 0;
        }
        const numericCount = typeof count === 'string' ? parseInt(count, 10) : count;
        acc[status] += numericCount;
        return acc;
      }, {} as Record<string, number>);

      const allTaskOverallSummary: ITaskStatusCount[] = Object.entries(aggregated).map(([status, count]) => ({
        status: status,
        count: count,
      }));

      let c = 0;
      const allGroupsSummary: ITaskStatusCountByGroup[] = [];
      for (const collection of collections) {
        const collectionStatus = taskOverallSummary[c];
        for (const stats of collectionStatus) {
          allGroupsSummary.push(
            Object({
              id: collection.id,
              grouporder: c + 1,
              groupname: collection.name,
              status: stats.status,
              count: stats.count,
            })
          );
        }
        c += 1;
      }
      setCollectionGroupsSummary(allGroupsSummary);
      setCollectionOverallSummary(allTaskOverallSummary);
    } else {
      setCollectionOverallSummary(taskOverallSummary[selectedTab - 1]);
      setCollectionGroupsSummary(taskGroupsSummary[selectedTab - 1]);
    }
  }, [selectedTab, taskGroupsSummary, taskOverallSummary]);

  useEffect(() => {
    if (collectionOverallSummary !== undefined) {
      const complete = collectionOverallSummary.find((d) => d.status === 'completed');
      const nbComplete = countToNumber(complete?.count);
      const nbTotal = collectionOverallSummary.reduce((sum, curr) => sum + countToNumber(curr.count), 0);
      const percent = nbTotal === 0 ? 0 : Math.round((100 * nbComplete) / nbTotal);

      setPercentComplete(percent);
    }
  }, [collectionOverallSummary]);

  const ButtonInTabs = ({ onClick, children, className }: IButtonInTabProps) => {
    return <Button className={className} onClick={onClick} children={children} />;
  };

  const onClickCollection = () => {
    const collectionId = collections[selectedTab].id;
    const filterViewValue = collectionId;

    if (filterViewValue) {
      auth.setTasksSearch(null);
      auth.setTasksView(filterViewValue);
    }
    history.push('/tasks');
  };

  const onClickCalendar = () => {
    if (selectedTab === 0) {
      history.push('/tasks/report');
    } else {
      const collectionId = collections[selectedTab - 1].id;
      history.push(`/tasks/report/${collectionId}`);
    }
  };

  return (
    <div className={classes.container}>
      <Card>
        <Tabs
          className={classes.tabsContainer}
          value={selectedTab}
          onChange={(_, value) => setSelectedTab(value)}
          variant="scrollable" // Enable scrollable variant
          scrollButtons="auto" // Show scroll buttons when needed
        >
          <StyledTab label={'All'} />
          {collections.map((collection) => (
            <StyledTab key={collection.id} label={truncateString(collection.abbreviatedName, 15, false)} />
          ))}
          <ButtonInTabs onClick={() => setShowAddCollectionDialog(true)} className={classes.addButton}>
            <AddIcon color="inherit" />
          </ButtonInTabs>
        </Tabs>
        <Grid className={classes.widgetContainer}>
          {selectedTab > 0 && (
            <Typography variant={'h6'}>
              <Link color="inherit" onClick={onClickCollection} className={classes.clickableLink}>
                {collections[selectedTab - 1].name}: {percentComplete}% complete
              </Link>
            </Typography>
          )}
        </Grid>
        <CardContent className={classes.cardContent}>
          {collectionOverallSummary && collectionGroupsSummary && (
            <TaskStatusByGroup
              auth={auth}
              history={history}
              taskOverallSummary={collectionOverallSummary}
              taskGroupsSummary={collectionGroupsSummary}
              allTasks={selectedTab === 0}
            />
          )}
        </CardContent>
        <Grid className={classes.widgetContainer}>
          <Typography variant={'body1'}>
            <Link color="inherit" onClick={onClickCalendar} className={classes.clickableLink}>
              See calendar view of tasks
            </Link>
          </Typography>
        </Grid>
      </Card>
      <InformationDialog
        title={'Contact Us'}
        text={'This is an upcoming feature. Please contact us to add a new collection of tasks at this time.'}
        open={showAddCollectionDialog}
        onClose={() => setShowAddCollectionDialog(false)}
      />
    </div>
  );
}

TaskStatusByGroupTab.requiredAuthZ = {
  tier: 1,
  permission: 'tasks',
};

export default TaskStatusByGroupTab;
