import { Button, DialogActions, DialogContent, StandardProps, Typography } from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/styles';
import { History } from 'history';
import React, { useEffect, useState } from 'react';
import { IAzureCallbackQueryParams, IAzureOrg } from '../../../../backend/src/user-audit/interfaces';
import API, { ErrorCodes as ApiErrorCodes } from '../../services/ApiService';
import { showErrorResultBar, showSuccessResultBar } from '../ResultSnackbar';
import StyledDialogTitle from '../StyledDialogTitle';
import ResponsiveDialog from './ResponsiveDialog';

const useStyles = makeStyles({
  dialogContent: {
    minWidth: '400px',
  },
  embeddedInstructions: {
    border: '1px solid rgb(33, 43, 54, 0.8)',
    fontSize: '0.9rem',
    padding: '1rem',
  },
  newlineLink: {
    paddingLeft: '1rem',
  },
  clientId: {
    paddingLeft: '1rem',
    fontSize: '0.8rem',
  },
  instructionList: {
    color: 'rgb(33, 43, 54, 1.0)',
    fontSize: '0.95rem',
    lineHeight: '1.5rem',
    marginBottom: '24px',
  },
  noteText: {
    color: 'rgb(33, 43, 54, 1.0)',
    fontSize: '0.75rem',
    lineHeight: '0.85rem',
  },
  instructionListSmall: {
    marginTop: '4px',
  },
});

interface AzureAllowAccessFormProps {
  isSubmitting: boolean;
  onCancel: () => void;
  onFetchOauthUrl: () => Promise<void>;
}

function AzureAllowAccessForm({ isSubmitting, onCancel, onFetchOauthUrl }: AzureAllowAccessFormProps) {
  const classes = useStyles();

  return (
    <>
      <DialogContent>
        <Typography
          variant="body1"
          gutterBottom
        >
          You need to be an Azure AD account administrator to set up the user audit.
          Clicking the <em>Connect</em> button below will take you to an Azure sign-in page.
          Choose the user tied to the Azure AD account you wish to audit,
          then allow us the permission to:
        </Typography>
        <ol className={classes.instructionList}>
          <li>Sign in and read your user profile</li>
          <li>Read all groups</li>
          <li>Read all group memberships</li>
          <li>Read all directory RBAC settings</li>
          <li>Read all users' full profiles</li>
        </ol>
        <Typography
          className={classes.noteText}
          gutterBottom
        >
          Note: Other managers on your team will be able to run audits, but
          they <b>will not</b> have direct access to your Azure AD account.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          size="small"
          disabled={isSubmitting}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          size="small"
          color="primary"
          disabled={isSubmitting}
          onClick={onFetchOauthUrl}
        >
          Connect to Azure AD
        </Button>
      </DialogActions>
    </>
  );
}

function ProcessingRequestDialogContent() {
  const classes = useStyles();

  return (
    <DialogContent
      className={classes.dialogContent}
    >
      <Typography
        variant="body1"
      >
        Please be patient while we process your request.
        It sometimes takes up to a minute for Azure to register a new app.
      </Typography>
    </DialogContent>
  );
}

export interface UserAuditAzureSetupDialogProps extends StandardProps<DialogProps, 'children'> {
  callbackQueryParams: IAzureCallbackQueryParams;
  handleClose: () => void;
  handleNewOrg: (newOrg: IAzureOrg) => void;
  history: History;
  isLoaded: boolean;
}

export default function UserAuditAzureSetupDialog(props: UserAuditAzureSetupDialogProps) {
  const { callbackQueryParams, open, handleClose, handleNewOrg, history, isLoaded } = props;

  const [ isSubmitting, setIsSubmitting ] = useState<boolean>(false);
  const [ tenantId, setTenantId ] = useState<string>('');
  const [ isOAuthCodePosted, setIsOAuthCodePosted ] = useState<boolean>(false);

  // Retrieves then navigates to the Azure sign in url:
  const onFetchOauthUrl = async () => {
    setIsSubmitting(true);

    return API
      .post('userAudit/azure/org')
      .then((res) => {
        const url = res.data.data;
        window.location.replace(url);
      })
      .catch(() => {
        showErrorResultBar('Unexpected error signing in to Azure');
        setIsSubmitting(false);
      });
  };

  // Handle the callback query params (if supplied):
  useEffect(() => {
    if (callbackQueryParams) {
      if (callbackQueryParams.error) {
        showErrorResultBar('Unexpected error registering with Azure. Please try again.');
        history.push('azure'); // clear the query parameters from the url
        handleClose();
      } else if (callbackQueryParams.tenant) {
        setTenantId(callbackQueryParams.tenant);
      }
    }
  }, [ callbackQueryParams, handleClose, history ]);

  // Handle the oauth code (from the query params):
  useEffect(() => {
    if (isLoaded && tenantId && !isOAuthCodePosted) {
      setIsOAuthCodePosted(true);
      let errorMsg: string;
      let successMsg: string;

      API
        .post('userAudit/azure/oauth', callbackQueryParams)
        .then((res) => {
          const createdOrg: IAzureOrg = res.data.data;
          successMsg = `${createdOrg.name} successfully created`;
          handleNewOrg(createdOrg);
        })
        .catch((err) => {
          const errData = (err.response && err.response.data) || {};
          const statusCode = errData.statusCode;
          const rawMessage = errData.message;

          if (statusCode === ApiErrorCodes.DUPLICATE_VALUE) {
            errorMsg = 'This Azure account has already been set up for auditing';
          } else if (rawMessage && typeof rawMessage === 'string') {
            errorMsg = rawMessage;
          } else {
            errorMsg = 'Unexpected error while setting up Azure org. Please try again.';
          }
        })
        .finally(() => {
          if (errorMsg) {
            showErrorResultBar(errorMsg);
          } else if (successMsg) {
            showSuccessResultBar(successMsg);
          }

          // Clear the query parameters from the url:
          history.push('azure');
          handleClose();
        });
    }
  }, [ handleClose, handleNewOrg, history, isOAuthCodePosted, isLoaded, tenantId, callbackQueryParams ]);

  const onCancel = () => {
    handleClose();
  };

  const clearData = () => {
    setTenantId('');
    setIsOAuthCodePosted(false);
  };

  return (
    <ResponsiveDialog
      open={open}
      onClose={onCancel}
      onExited={clearData}
      disableBackdropClick
    >
      <StyledDialogTitle
        onClose={onCancel}
      >
        Azure AD Account Setup
      </StyledDialogTitle>
      {!tenantId ? (
        <AzureAllowAccessForm
          isSubmitting={isSubmitting}
          onCancel={onCancel}
          onFetchOauthUrl={onFetchOauthUrl}
        />
      ) : (
        <ProcessingRequestDialogContent />
      )}
    </ResponsiveDialog>
  );
}
