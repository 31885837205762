import { ButtonProps } from '@material-ui/core/Button';
import React, { useState } from 'react';
import { IDocument } from '../../../../backend/src/document/interfaces/document.interface';
import { DocumentCategory } from '../../../../backend/src/document/document-category.enum';
import { FileUploadButton } from '../buttons';
import { FileUploadDialog } from '.';

export interface FileUploadButtonDialogProps {
  buttonProps?: ButtonProps;
  buttonText: string;
  dialogHeaderText?: string;
  documentCategory?: DocumentCategory;
  documentOrgId?: string;
  showCategorySelection?: boolean;
  showIcon?: boolean;
  onAddDocuments?: (docs: IDocument[]) => void | Promise<void>;
  multipleFiles?: boolean;
  allTags?: string[];
  currentDocuments?: INameSizeDto[];
}

export interface INameSizeDto {
  name: string;
  size: string;
}

export default function FileUploadButtonDialog(props: FileUploadButtonDialogProps) {
  const {
    buttonProps = {},
    buttonText,
    dialogHeaderText,
    documentCategory,
    documentOrgId,
    onAddDocuments,
    showCategorySelection = false,
    showIcon = false,
    multipleFiles = false,
    allTags = [],
    currentDocuments = []
  } = props;

  const [ isOpen, setIsOpen ] = useState(false);

  return (
    <>
      <FileUploadButton
        showIcon={showIcon}
        onClick={() => setIsOpen(true)}
        {...buttonProps}
      >
        {buttonText}
      </FileUploadButton>
      <FileUploadDialog
        dialogHeaderText={dialogHeaderText || buttonText}
        documentCategory={documentCategory !== undefined ? documentCategory : 'evidence'}
        documentOrgId={documentOrgId}
        onAddDocuments={onAddDocuments}
        onClose={() => setIsOpen(false)}
        open={isOpen}
        showCategorySelection={showCategorySelection}
        multipleFiles={multipleFiles}
        allTags={allTags}
        currentDocuments={currentDocuments}
      />
    </>
  );
}
