import { Card, CardContent, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import * as Sentry from '@sentry/browser';
import React, { useEffect, useState } from 'react';
import { ISubscriptionDto, ISubscriptionPlanDto } from '../../../../backend/src/subscription/interfaces';
import { showErrorResultBar, showSuccessResultBar } from '../ResultSnackbar';
import { IOrg } from '../../pages/TheOrganizationPage';
import API from '../../services/ApiService';
import { BillingInfoCard } from '../BillingInfoCard';
import { PaymentMethodCard } from '../PaymentMethodCard';
import Stripe from 'stripe';

const useStyles = makeStyles({
  headerCard: {
    width: '100%',
    margin: 0,
  },
});

export interface OrgBillingSettingsProps {
  org: IOrg;
}

// noinspection JSUnusedLocalSymbols
export default function OrgBillingSettings({ org }: OrgBillingSettingsProps) {
  const classes = useStyles();

  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ payMethod, setPayMethod ] = useState<Stripe.PaymentMethod>();
  const [ subscriptionPlan, setSubscriptionPlan ] = useState<ISubscriptionPlanDto>();

  // Initial page load
  useEffect(() => {
    setIsLoading(true);

    API.get('subscription')
      .then((res) => {
        const { paymentMethod, subscription }: ISubscriptionDto = (res.data && res.data.data) ? res.data.data : {};
        setPayMethod(paymentMethod);
        setSubscriptionPlan(subscription!);
      })
      .catch((err) => {
        showErrorResultBar('Unexpected error fetching results');
        Sentry.captureException(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const onConfirmDelete = async () => {
    API.post('subscription/cancel')
      .then((res) => {
        const theData: ISubscriptionPlanDto = (res.data && res.data.data) ? res.data.data : {};
        showSuccessResultBar('Your subscription has been canceled');
        setSubscriptionPlan(theData);
      })
      .catch((err) => {
        showErrorResultBar('Unexpected error while canceling subscription');
        Sentry.captureException(err);
      });
  };

  return (
    <Card className={classes.headerCard}>
      <CardContent>
        <Grid
          container
          alignItems="stretch"
        >
          <Grid
            item
            xs={12}
            md={6}
          >
            <PaymentMethodCard
              isLoading={isLoading}
              paymentMethod={payMethod}
              onEditClick={() => null}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
          >
            <BillingInfoCard
              isLoading={isLoading}
              onConfirmDelete={onConfirmDelete}
              subscriptionPlan={subscriptionPlan}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
