import { Button, DialogActions, DialogContent, MenuItem, StandardProps, TextField } from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/styles';
import * as Sentry from '@sentry/browser';
import React, { useEffect, useState } from 'react';
import { ITaskAssigneeDto } from '../../../../backend/src/task/interfaces';
import { showErrorResultBar, showSuccessResultBar } from '../ResultSnackbar';
import StyledDialogTitle from '../StyledDialogTitle';
import ResponsiveDialog from './ResponsiveDialog';

const useStyles = makeStyles({
  dialogContent: {
    minWidth: '400px',
  },
  dropdownItemEmail: {
    fontSize: '0.8rem',
  },
});

// This gets used as the 'value' for the 'Unassigned' dropdown choice.
export const UNASSIGNED_ID = 'unassigned';

const assigneeToId = (assignee: ITaskAssigneeDto | null) => {
  return (assignee && assignee.id) ? assignee.id : UNASSIGNED_ID;
};

export interface TaskAssignmentDialogProps extends StandardProps<DialogProps, 'children'> {
  assignees: ITaskAssigneeDto[];
  onClose: () => void;
  title: string;
  defaultAssigneeId: string | undefined;
  defaultAssigneeName: string | undefined;
  handleAssignment: (assigneeId: string) => void
}

export default function TaskAssignmentDialog(props: TaskAssignmentDialogProps) {
  const classes = useStyles();
  const { assignees, onClose, handleAssignment, title, open, defaultAssigneeName, defaultAssigneeId } = props;
  const [ assignee, setAssignee ] = useState<ITaskAssigneeDto | null>(null);
  const [ isSubmitting, setIsSubmitting ] = useState<boolean>(false);
  const [ isValidAssignee, setIsValidAssignee ] = useState(true);
  const [ selectedAssigneeId, setSelectedAssigneeId ] = useState<string>(UNASSIGNED_ID);

  // In the case of a single task, use its assignee as the default selection.
  useEffect(() => {
    if (defaultAssigneeId) {
      setAssignee({ id: defaultAssigneeId, name: defaultAssigneeName });
    } else {
      setAssignee(null);
    }
  }, [ defaultAssigneeId, defaultAssigneeName ]);

  // The assigneeId is used by the select dropdown.
  useEffect(() => {
    setSelectedAssigneeId(assigneeToId(assignee));
  }, [ assignee ]);

  // In the case of a single task with an invalid (old) assignee, still show them in the list.
  useEffect(() => {
    setIsValidAssignee(assignee === null || assignees.some(a => a.id === assignee.id));
  }, [ assignee, assignees ]);

  const handleChangeDropdown = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelection = event.target.value;

    setSelectedAssigneeId(newSelection);
  };

  const handleAssignmentClick = async (selectedAssigneeId: string) => {

    try {
      setIsSubmitting(true);
      handleAssignment(selectedAssigneeId);
      onClose();
      showSuccessResultBar('Assignments updated.');
    } catch (err) {
      Sentry.captureException(err);
      showErrorResultBar('Unable to make assignment, please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const resetData = () => {
    setSelectedAssigneeId(assigneeToId(assignee));
  };

  return (
    <ResponsiveDialog
      disableBackdropClick={true}
      open={open}
      onClose={onClose}
      onExited={resetData}
    >
      <StyledDialogTitle
        onClose={onClose}
      >
        Assign {title}
      </StyledDialogTitle>
      <DialogContent
        className={classes.dialogContent}
      >
        <TextField
          id="task-assignee"
          select
          label="Assign to"
          value={selectedAssigneeId}
          onChange={handleChangeDropdown}
          helperText={'Please select a team member for assignment'}
        >
          <MenuItem
            value={UNASSIGNED_ID}
          >
            Unassigned
          </MenuItem>
          {assignees.map(currAssignee => (
            <MenuItem
              key={currAssignee.id}
              value={currAssignee.id}
            >
              {currAssignee.name}
              {currAssignee.name !== currAssignee.email &&
                <span className={classes.dropdownItemEmail}>&nbsp;({currAssignee.email})</span>
              }
            </MenuItem>
          ))}
          {!isValidAssignee && assignee &&
            <MenuItem
              value={assignee.id}
            >
              {assignee.name}
              <span className={classes.dropdownItemEmail}>&nbsp;(No longer has access to tasks)</span>
            </MenuItem>
          }
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          size="small"
          disabled={isSubmitting}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          size="small"
          color="primary"
          disabled={isSubmitting}
          onClick={() =>{handleAssignmentClick(selectedAssigneeId);}}
        >
          Assign
        </Button>
      </DialogActions>
    </ResponsiveDialog>
  );
}
