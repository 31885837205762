import {
  Grid
} from '@material-ui/core';
import TopMaterialIssuesOverview from '../components/TopMaterialIssuesOverview';
import MaterialityAssessmentResponseNumberChart from './widgets/MaterialityAssessmentResponseNumberChart';
import MaterialityAssessmentBarChart from './widgets/MaterialityAssessmentBarChart';
import { geminiPurple } from '../theme.js';
import { IMaterialityAssessmentResponseCountDto } from '../../../backend/src/material-issue/interfaces/material-assessment-response-count-dto.interface';
import { IMaterialityAssessmentResponseIssuesDto } from '../../../backend/src/material-issue/interfaces/material-assessment-response-dto.interface';

export interface MaterialityAssessmentOverviewProps {
  isLoading: boolean;
  issues: IMaterialityAssessmentResponseIssuesDto[];
  responseCounts: IMaterialityAssessmentResponseCountDto;
  filterOptions: { [key: string]: string }[] | [];
  selectedQuestionnaire: string;
}

function MaterialityAssessmentOverview({
  isLoading,
  issues,
  filterOptions,
  responseCounts,
  selectedQuestionnaire
}: MaterialityAssessmentOverviewProps) {

  const chartColors = [
    geminiPurple[200], geminiPurple[300], geminiPurple[400], geminiPurple[500],
    geminiPurple[600], geminiPurple[700], geminiPurple[800], geminiPurple[900]
  ];

  // const chartColors = [
  //   '#979Ef6', '#4adede', '#1aa7ec','#1e2f97', geminiPurple[500],
  //   geminiPurple[600], geminiPurple[700],  geminiPurple[800], geminiPurple[900]
  // ];

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <TopMaterialIssuesOverview
          isLoading={isLoading}
          issues={issues}
          filterOptions={filterOptions}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <MaterialityAssessmentResponseNumberChart
          selectedQuestionnaire={selectedQuestionnaire}
          responseCounts={responseCounts}
          filterOptions={filterOptions}
          chartColors={chartColors}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <MaterialityAssessmentBarChart
          selectedQuestionnaire={selectedQuestionnaire}
          filterOptions={filterOptions}
          issues={issues}
          chartColors={chartColors}
          responseCounts={responseCounts}
        />
      </Grid>
    </Grid>
  );
}

export default MaterialityAssessmentOverview;
