import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import * as Sentry from '@sentry/browser';
import { sortBy } from 'lodash';
import { ICoverageReportWidgetDto } from '../../../../backend/src/user/interfaces';
import API from '../../services/ApiService';
import WidgetTitle from '../WidgetTitle';
import { HorizontalBarCoverageChart } from '.';

const useStyles = makeStyles({
  cardContent: {
    minHeight: '300px',
  },
});

// This keeps the bar width relatively constant even if the nb of rows is small:
function getAspectRatio(nbRows: number) {
  return 5 / (0.25 * nbRows + 0.5);
}

function PolicyCoverageChart() {
  const classes = useStyles();
  const [anyApproved, setAnyApproved] = useState(false);
  const [anyPending, setAnyPending] = useState(false);
  const [chartData, setChartData] = useState<ICoverageReportWidgetDto>({ total: 0, data: [] });

  useEffect(() => {
    API.get('policyDoc/coverage')
      .then((res) => {
        const { chartData: newChartData, pending }: { chartData?: ICoverageReportWidgetDto, pending?: string[] } = res.data?.data || {};
        if (newChartData) {
          setAnyApproved(newChartData.data.length > 0);
          // Add the 'pending' Policies (those not yet approved) back into the list, appended with an asterisk:
          if (pending) {
            newChartData.data.push(...pending.map(name => ({ count: 0, name: `${name}*` })));
            newChartData.data = sortBy(newChartData.data, ['name ']);
          }

          // The Policy names all end with ' Policy'; remove this for display in the widget:
          newChartData.data = newChartData.data.map(datum => ({ ...datum, name: datum.name.replace(' Policy', '') }));
          setChartData(newChartData);
        }

        setAnyPending(!!pending && pending.length > 0);
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
  }, []);

  return (
    <Card>
      <WidgetTitle to="/policies">Policy Acknowledgement</WidgetTitle>
      <CardContent className={classes.cardContent}>
        {chartData.data.length > 0 && (
          <HorizontalBarCoverageChart
            aspectRatio={getAspectRatio(chartData.data.length)}
            completedLabel="Acknowledged"
            data={chartData}
          />
        )}
        {anyPending && (
          <Typography align="right" variant="caption">
            * Policies marked with an asterisk are not yet approved
          </Typography>
        )}
      </CardContent>
      {anyApproved && (
        <WidgetTitle
          to="/policies/report"
          variant="subtitle1"
        >
          See detailed report
        </WidgetTitle>
      )}
    </Card>
  );
}

PolicyCoverageChart.requiredAuthZ = {
  tier: 1,
  permission: 'policies:report',
};

export default PolicyCoverageChart;
