import {
    Card,
    CardContent,
    CardHeader,
    createStyles,
    Grid,
    Hidden,
    IconButton,
    Link,
    Menu,
    MenuItem,
    Theme,
    Tooltip,
    Typography,
    withStyles,
    WithStyles,
} from '@material-ui/core';
import AttachIcon from '@material-ui/icons/AttachFile';
import DeleteIcon from '@material-ui/icons/Delete';
import HelpIcon from '@material-ui/icons/HelpOutline';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles } from '@material-ui/styles';
import * as Sentry from '@sentry/browser';
import React, { useEffect, useState } from 'react';
import { IDocument } from '../../../backend/src/document/interfaces/document.interface';
import { StakeholderReason, StakeholderStatus, StakeholderType } from '../../../backend/src/stakeholder/enums';
import { IStakeholderAttachment, IStakeholderDto } from '../../../backend/src/stakeholder/interfaces';
import * as Helpers from '../helpers';
import API from '../services/ApiService';
import Auth from '../services/AuthService';
import * as DocService from '../services/DocService';
import { FileUploadDialog, RemoveFileDialog } from './dialogs';
import { stakeholderTypeSelections, stakeholderReasonSelections, stakeholderStatusSelections } from './dialogs/StakeholderEditDialog';
import { showErrorResultBar, showSuccessResultBar } from './ResultSnackbar';
import StakeholderQuestionnaireResponse from './StakeholderQuestionnaireResponse';
import { handleError } from '../helpers';

const styles = (theme: Theme) => createStyles({
    buttonLink: {
        'color': 'inherit',
        'textDecoration': 'underline',
        'cursor': 'pointer',
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
    cardHeader: {
        paddingBottom: 0,
    },
    helpIcon: {
        'fontSize': '18px',
        'marginBottom': '-1px',
        'marginLeft': theme.spacing(1),
        '&:hover': {
            cursor: 'pointer',
        },
    },
    linkItem: {
        '& a': {
            color: theme.typography.body1.color,
            cursor: 'pointer',
        },
        '&:nth-child(n+2)': {
            paddingTop: '4px',
        },
    },
    primaryColumn: {
        color: theme.typography.body1.color,
        fontSize: '14px',
    },
    referenceHeading: {
        fontSize: '1rem',
        color: theme.typography.body1.color,
    },
    referencesContainer: {
        paddingLeft: '1rem',
        paddingBottom: '0.8rem',
    },
    referenceResponse: {
        color: 'rgba(0,0,0,0.54)',
        whiteSpace: 'pre-line',
    },
    secondaryColumn: {
        backgroundColor: '#f5f5f5',
        opacity: '0.8',
        fontSize: '0.8125rem',
    },
    smallIconButton: {
        'height': '14px',
        'width': '14px',
        'marginTop': '-6px',
        'marginLeft': '0.5rem',
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
    smallIconButtonIcon: {
        fontSize: '18px',
    },
    smallIconButtonLabel: {
        height: '0',
    },
});

const useMissingInfoStyles = makeStyles({
    missingDataText: {
        color: 'rgba(0, 0, 0, 0.54)',
    },
});

export interface IStakeholderUrlInfo {
    value: string;
    text: string;
    href?: string | null;
}

export const stakeholderUrlMap: IStakeholderUrlInfo[] = [
    {
        value: 'urlMain',
        text: 'Main website',
    },
    {
        value: 'urlCompliance',
        text: 'Compliance page',
    },
    {
        value: 'urlPrivacy',
        text: 'Privacy policy',
    },
    {
        value: 'urlESG',
        text: 'ESG policy',
    },
    {
        value: 'urlSupport',
        text: 'Support page',
    },
    {
        value: 'urlTerms',
        text: 'Terms of service',
    },
];

export const stakeholderReasonMap: { [key in StakeholderReason]: string } = {
    ...stakeholderReasonSelections,
};

export const stakeholderStatusMap: { [key in StakeholderStatus]: string } = {
    ...stakeholderStatusSelections,
    archived: 'Archived',
};

export const stakeholderTypeMap: { [key in StakeholderType]: string } = {
    ...stakeholderTypeSelections,
};


const getStatusString = (stakeholderData: IStakeholderDto) => {
    const { dateEngaged, status } = stakeholderData;
    const statusStr = (status && stakeholderStatusMap[status]) || 'Unknown';

    let dateStr = '';
    if (status === 'engaged' && dateEngaged) {
        dateStr = ` (on ${Helpers.formatDate(dateEngaged)})`;
    }

    return `Status: ${statusStr}${dateStr}`;
};

export function MissingDataText({ message }: { message?: string }) {
    const classes = useMissingInfoStyles();

    return <em className={classes.missingDataText}>{message || 'None provided'}</em>;
}

export interface SecondaryFieldProps {
    title: string;
    value: number | string;
}

export function SecondaryField({ title, value }: SecondaryFieldProps) {
    return (<>
        <Hidden smUp>
            <Grid container>
                <Grid item xs={3}>{title}</Grid>
                <Grid item xs={9}>{value}</Grid>
            </Grid>
        </Hidden>
        <Hidden xsDown>
            <Grid container justifyContent="space-between" wrap="nowrap">
                <Grid item>{title}</Grid>
                <Grid item>{value}</Grid>
            </Grid>
        </Hidden>
    </>);
}

export interface StakeholderDetailsProps extends WithStyles<typeof styles> {
    stakeholderData: IStakeholderDto;
    onClickArchive: () => void;
    onClickEdit: () => void;
    onClickSelfFillQuestionnaire: () => void;
    onClickSendQuestionnaire: () => void;
    onUpdateStakeholder: (newStakeholderData: IStakeholderDto) => void;
    auth: Auth
}

function StakeholderDetailsInfo(props: StakeholderDetailsProps) {
    const {
        classes,
        onClickArchive,
        onClickEdit,
        onClickSelfFillQuestionnaire,
        onClickSendQuestionnaire,
        onUpdateStakeholder,
        stakeholderData,
        auth,
    } = props;

    const [documentToArchive, setDocumentToArchive] = useState<IStakeholderAttachment | null>(null);
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [toShowFileUploadDialog, setToShowFileUploadDialog] = useState(false);
    const [toShowRemoveEvidenceDialog, setToShowRemoveEvidenceDialog] = useState(false);
    const [urls, setUrls] = useState<IStakeholderUrlInfo[]>([]);

    useEffect(() => {
        const mUrls: IStakeholderUrlInfo[] = [];

        if (stakeholderData && stakeholderData.urls) {
            const rawUrls: { [key: string]: string | null } = Object.assign(stakeholderData.urls);

            stakeholderUrlMap.forEach((urlInfo) => {
                const href = rawUrls[urlInfo.value];
                if (href) {
                    mUrls.push({ ...urlInfo, href });
                }
            });
        }

        setUrls(mUrls);
    }, [stakeholderData]);

    const handleAttachFile = async (newDoc: IDocument[]) => {
        for (const doc of newDoc) {
            try {
                const res = await API.post(`stakeholder/${stakeholderData.id}/documents/${doc.id}`);
                onUpdateStakeholder(res.data.data);
                showSuccessResultBar('File attached to Stakeholder');
            } catch (err) {
                handleError(err, 'Error attaching file to Stakeholder');
            }
        }

    };

    const handleClickRemoveAttachment = (document: IStakeholderAttachment) => () => {
        setDocumentToArchive(document);
        setToShowRemoveEvidenceDialog(true);
    };

    const handleRemoveAttachment = async (docId: string) => {
        try {
            const res = await API.delete(`stakeholder/${stakeholderData.id}/documents/${docId}`);
            onUpdateStakeholder(res.data.data);
            showSuccessResultBar('File archived.');

            return true;
        } catch (err) {
            Sentry.captureException(err);
            showErrorResultBar('Unexpected error while archiving file. Please try again.');

            return false;
        }
    };

    const handleCloseMenu = () => {
        setMenuAnchorEl(null);
    };

    const handleClickMenuItem = (handler: () => void) => () => {
        handleCloseMenu();
        handler();
    };

    return (
        <>
            <Card>
                <CardHeader
                    action={<>
                        <IconButton onClick={({ currentTarget }) => setMenuAnchorEl(currentTarget)}>
                            <MoreVertIcon />
                        </IconButton>
                    </>}
                    className={classes.cardHeader}
                    title={stakeholderData.stakeholderName || 'Stakeholder Details'}
                    subheader={getStatusString(stakeholderData)}
                />
                <Menu
                    anchorEl={menuAnchorEl}
                    keepMounted
                    open={Boolean(menuAnchorEl)}
                    onClose={handleCloseMenu}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <MenuItem onClick={handleClickMenuItem(onClickEdit)} disabled={!auth.isGranted({ permission: 'stakeholder_engagement:edit'})}>
                        Edit
                    </MenuItem>
                    <MenuItem onClick={handleClickMenuItem(() => setToShowFileUploadDialog(true))} disabled={!auth.isGranted({ permission: 'stakeholder_engagement:edit'})}>
                        Attach a File
                    </MenuItem>
                    <MenuItem onClick={handleClickMenuItem(onClickSendQuestionnaire)} disabled={!auth.isGranted({ permission: 'stakeholder_engagement:edit'})}>
                        Send Questionnaire
                    </MenuItem>
                    <MenuItem onClick={handleClickMenuItem(onClickSelfFillQuestionnaire)} disabled={!auth.isGranted({ permission: 'stakeholder_engagement:edit'})}>
                        Self-fill Questionnaire
                    </MenuItem>
                    <MenuItem onClick={handleClickMenuItem(onClickArchive)} disabled={!auth.isGranted({ permission: 'stakeholder_engagement:edit'})}>
                        Archive
                    </MenuItem>
                </Menu>
                <CardContent>
                    <Grid container alignItems="flex-start" spacing={4}>
                        {/* Primary column */}
                        <Grid item xs={12} sm={8} md={9} className={classes.primaryColumn}>
                            {/* Data */}
                            <Typography
                                variant="h6"
                                className={classes.referenceHeading}
                                gutterBottom
                            >
                                Stakeholder Details
                                <Tooltip
                                    title={<>
                                        <div>Information about this stakeholder and how you are engaged.</div>
                                    </>}
                                >
                                    <HelpIcon color="action" className={classes.helpIcon} />
                                </Tooltip>
                            </Typography>
                            <div className={classes.referencesContainer}>
                                <Grid container>
                                    <Grid item xs={3}>Type:</Grid>
                                    <Grid item xs={9} className={classes.referenceResponse}>
                                        {(stakeholderData.type && stakeholderTypeMap[stakeholderData.type]) || 'Unknown'}
                                    </Grid>
                                    <Grid item xs={3}>Reason for Engagement:</Grid>
                                    <Grid item xs={9} className={classes.referenceResponse}>
                                        {(stakeholderData.reason && stakeholderReasonMap[stakeholderData.reason]) || 'Unknown'}
                                    </Grid>
                                    <Grid item xs={3}>Status:</Grid>
                                    <Grid item xs={9} className={classes.referenceResponse}>
                                        {(stakeholderData.status && stakeholderStatusMap[stakeholderData.status]) || 'Unknown'}
                                    </Grid>
                                </Grid>
                            </div>
                            {/* Notes */}
                            <Typography
                                variant="h6"
                                className={classes.referenceHeading}
                                gutterBottom
                            >
                                Additional notes
                            </Typography>
                            <div className={classes.referencesContainer}>
                                {stakeholderData.notes ? (
                                    <span className={classes.referenceResponse}>{stakeholderData.notes}</span>
                                ) : (
                                    <MissingDataText message="None" />
                                )}
                            </div>
                        </Grid>
                        {/* Secondary column */}
                        <Grid item xs={12} sm={4} md={3} className={classes.secondaryColumn}>
                            {/* Internal tracking */}
                            <Typography
                                variant="h6"
                                className={classes.referenceHeading}
                                gutterBottom
                            >
                                Internal Tracking
                            </Typography>
                            <div className={classes.referencesContainer}>
                                <SecondaryField title="Date Engaged:" value={Helpers.formatDate(stakeholderData.dateEngaged) || 'n/a'} />
                                <SecondaryField title="Last review date:" value={Helpers.formatDate(stakeholderData.reviewedAt) || 'n/a'} />
                                <SecondaryField title="Retirement date:" value={Helpers.formatDate(stakeholderData.retiredAt) || 'n/a'} />
                                <SecondaryField title="Owner:" value={stakeholderData.ownerEmail || 'n/a'} />
                                <SecondaryField title="Department:" value={stakeholderData.department || 'n/a'} />
                                <SecondaryField title="External ID:" value={stakeholderData.externalId || 'n/a'} />
                            </div>
                            {/* Contact info */}
                            <Typography
                                variant="h6"
                                className={classes.referenceHeading}
                                gutterBottom
                            >
                                Stakeholder Contact
                            </Typography>
                            <div className={classes.referencesContainer}>
                                {(stakeholderData.contactEmail || stakeholderData.contactName || stakeholderData.contactPhone || stakeholderData.contactTitle) ? (
                                    <>
                                        <SecondaryField title="Email:" value={stakeholderData.contactEmail || 'n/a'} />
                                        <SecondaryField title="Name:" value={stakeholderData.contactName || 'n/a'} />
                                        <SecondaryField title="Phone:" value={stakeholderData.contactPhone || 'n/a'} />
                                        <SecondaryField title="Title/Role:" value={stakeholderData.contactTitle || 'n/a'} />
                                    </>
                                ) : (
                                    <MissingDataText />
                                )}
                            </div>
                            {/* URLs */}
                            <Typography
                                variant="h6"
                                className={classes.referenceHeading}
                                gutterBottom
                            >
                                Known Links
                            </Typography>
                            <div className={classes.referencesContainer}>
                                {urls.length === 0 ? (
                                    <MissingDataText />
                                ) : (
                                    urls.map(urlInfo => (
                                        <div
                                            key={urlInfo.value}
                                            className={classes.linkItem}
                                        >
                                            <Link
                                                className={classes.buttonLink}
                                                href={urlInfo.href || ''}
                                                rel="noopener noreferrer"
                                                target="_blank"
                                            >
                                                {urlInfo.text}
                                            </Link>
                                        </div>
                                    ))
                                )}
                            </div>
                            {/* Attachments */}
                            <Typography
                                variant="h6"
                                className={classes.referenceHeading}
                                gutterBottom
                            >
                                Attachments
                                <Tooltip
                                    title="Attach a file"
                                >
                                    <IconButton
                                        classes={{
                                            root: classes.smallIconButton,
                                            label: classes.smallIconButtonLabel,
                                        }}
                                        onClick={() => setToShowFileUploadDialog(true)}
                                        disabled={!auth.isGranted({ permission: 'stakeholder_engagement:edit'})}
                                    >
                                        <AttachIcon className={classes.smallIconButtonIcon} />
                                    </IconButton>
                                </Tooltip>
                            </Typography>
                            <div className={classes.referencesContainer}>
                                {stakeholderData.documents.length > 0 ? (
                                    stakeholderData.documents.map(doc => (
                                        <Grid key={doc.id} container justifyContent="space-between">
                                            <Grid item>
                                                <Link
                                                    className={classes.buttonLink}
                                                    onClick={DocService.documentDownloadHandler(doc.id)}
                                                >
                                                    {Helpers.truncateString(doc.name)}
                                                </Link>
                                            </Grid>
                                            <Grid item>
                                                <Tooltip
                                                    title="Remove this attachment"
                                                >
                                                    <IconButton
                                                        classes={{
                                                            root: classes.smallIconButton,
                                                            label: classes.smallIconButtonLabel,
                                                        }}
                                                        onClick={handleClickRemoveAttachment(doc)}
                                                    >
                                                        <DeleteIcon className={classes.smallIconButtonIcon} />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    ))
                                ) : (
                                    <MissingDataText message="None" />
                                )}
                            </div>
                        </Grid>
                        {/* Questionnaires */}
                        <Grid item xs={12}>
                            {stakeholderData.questionnaires && stakeholderData.questionnaires.length > 0 && <>
                                <Typography
                                    variant="h6"
                                    className={classes.referenceHeading}
                                    gutterBottom
                                >
                                    Stakeholder Questionnaires
                                </Typography>
                                <div className={classes.referencesContainer}>
                                    {stakeholderData.questionnaires.map((questionnaire) => (
                                        <StakeholderQuestionnaireResponse
                                            key={questionnaire.id}
                                            onUpdateStakeholder={onUpdateStakeholder}
                                            questionnaire={questionnaire}
                                            stakeholderData={stakeholderData}
                                            questionnaireTemplate={questionnaire.questionnaireTemplate}
                                            auth={auth}
                                        />
                                    ))}
                                </div>
                            </>}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <FileUploadDialog
                dialogHeaderText={`Attach a file to '${stakeholderData.stakeholderName}'`}
                documentCategory="evidence/stakeholder"
                onAddDocuments={handleAttachFile}
                onClose={() => setToShowFileUploadDialog(false)}
                open={toShowFileUploadDialog}
                currentDocuments={[]}
            />
            {documentToArchive &&
                <RemoveFileDialog
                    document={documentToArchive}
                    onClose={() => setToShowRemoveEvidenceDialog(false)}
                    onExited={() => setDocumentToArchive(null)}
                    onRemoveFile={handleRemoveAttachment}
                    open={toShowRemoveEvidenceDialog}
                />
            }
        </>
    );
}

export default withStyles(styles, { withTheme: true })(StakeholderDetailsInfo);
