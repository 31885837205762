import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Grid
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { Formik } from 'formik';
import Auth from '../services/AuthService';
import { IRoleDto, IUserRBACDto, IOrgRBACJoinDto } from '../../../backend/src/org-share/interfaces';
import { SaveButton } from './buttons';
import { RouterLink } from './RouterLink';
import DeleteIcon from '@material-ui/icons/Delete';
import { IAvailableRoles } from './OrgIncomingAccessDetails';

const useStyles = makeStyles({
  cardHeader: {
    paddingTop: '1rem',
    paddingBottom: 0,
  },
  selectedRoleContainer: {
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
  },
  roleSelectionContainer: {
    paddingTop: '.5rem',
    paddingBottom: '.5rem',
    borderRadius: '4px',
  },
  roleSelectionName: {
    fontWeight: 500,
  },
  roleSelectionDescription: {
    paddingLeft: '0.5rem',
    fontWeight: 'normal',
  },
  inputContainer: {
    margin: '0 2rem',
  },
  leftIcon: {
    marginRight: '0.5rem',
  },
  disabled: {
    color: '#bfbfbf',
    backgroundColor: '#fafafa'
  },
  infoText: {
    paddingLeft: '1rem',
  },
});

export interface RoleSelectionLabelProps {
  isSelected?: boolean;
  roleSelection: IRoleDto;
}

export function RoleSelectionLabel({ isSelected = false, roleSelection }: RoleSelectionLabelProps) {
  const classes = useStyles();

  return (
    <>
      <Typography
        className={classes.roleSelectionName}
        variant="subtitle1"
      >
        {roleSelection.name}
        {/* {isSelected && ' (current role)'} */}
      </Typography>
      <Typography
        className={classes.roleSelectionDescription}
        variant="subtitle2"
      >
        {roleSelection.description}
      </Typography>
      {/* <Typography
        className={classes.roleSelectionDescription}
        variant="subtitle2"
      >
        {roleSelection.privileges.join('; ')}.
      </Typography> */}
    </>
  );
}

function UserDetailsForSelf({ userData }: { userData: any }) {
  const classes = useStyles();

  return (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        title={`${userData.userName} (You)`}
        titleTypographyProps={{
          variant: 'h6',
        }}
      />
      <CardContent>
        If you need to update your own role settings please ask another Admin, or feel free to <RouterLink to="/support">contact us</RouterLink>.
      </CardContent>
    </Card>
  );
}

export interface OrgIncomingAccessUserDetailsProps {
  userData: IUserRBACDto;
  auth: Auth;
  availableRoles: IAvailableRoles[];
  orgData: IOrgRBACJoinDto;
  onClickSave: (values: Record<string, any>) => Promise<void>;
  onRemoveUser: () => void;
}

export function OrgIncomingAccessUserDetails({ userData, auth, availableRoles, orgData, onClickSave, onRemoveUser }: OrgIncomingAccessUserDetailsProps) {
  const classes = useStyles();
  const [ currRole, setCurrRole ] = useState<IRoleDto | undefined>(userData.role);

  useEffect(() => {
    setCurrRole(userData.role);
  }, [userData]);

  if (userData?.userId === auth.getUserId() && !auth.isGranted({permission: 'org_share:admin'})) {
    return <UserDetailsForSelf userData={userData} />;
  } else {
    return (
      <Formik
        enableReinitialize
        initialValues={{
          role: currRole?.id,
        }}
        onSubmit={async (values, { setSubmitting }) => {
            await onClickSave(values);
            setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleReset,
          handleSubmit,
          isSubmitting,
        }) => (
        <>
          <Card>
            <CardHeader
              className={classes.cardHeader}
              title={`Change ${userData.userName}'s access to ${orgData.fromOrg.name}`}
              titleTypographyProps={{
                variant: 'h6',
              }}
              subheaderTypographyProps={{
                variant: 'button',
              }}
            />
            <Grid className={classes.infoText}>
              <Typography variant="body2">
                Visit the <RouterLink to="/permissions">Permissions page</RouterLink> for more information on permissions assigned to each role.
              </Typography>
            </Grid>
            <CardContent>
              <div className={classes.inputContainer}>
                <FormControl
                  margin="dense"
                >
                  <RadioGroup
                    id="role"
                    name="role"
                    value={values.role}
                    onChange={handleChange}
                  >
                    {availableRoles.map((role, idx) => (
                      <FormControlLabel
                        key={idx}
                        disabled={!role.allowed}
                        className={classNames(
                          classes.roleSelectionContainer,
                          role.id === values.role && classes.selectedRoleContainer,
                        )}
                        value={role.id}
                        control={<Radio color="primary"/>}
                        label={<RoleSelectionLabel
                        isSelected={role.id === currRole?.id}
                        roleSelection={role}
                        />}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </div>
            </CardContent>
            <CardActions>
              <Button
                disabled={isSubmitting || values.role === currRole || !auth.isGranted({ permission: 'user_management:edit'})}
                onClick={handleReset}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                size="small"
                color="primary"
                onClick={onRemoveUser}
              >
                <DeleteIcon className={classes.leftIcon} />
                Remove User
              </Button>
              <SaveButton
                disabled={isSubmitting || values.role === currRole || Object.keys(errors).length > 0 || !auth.isGranted({ permission: 'user_management:edit'})}
                onClick={handleSubmit}
                text="Update role"
              />
            </CardActions>
          </Card>
        </>
        )}
      </Formik>
    );
  }
}
