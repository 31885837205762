import { Accordion, AccordionDetails, ListItem, ListItemIcon, Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import * as React from 'react';
import { useState } from 'react';
import DownloadButton from '../components/buttons/DownloadButton';
import AccordionIconSummary from '../components/AccordionIconSummary';
import * as DocService from '../services/DocService';

interface IDocData {
  docKey: string;
  title: string;
  description: string;
}

const marketingBundleDocData: IDocData[] = [
  {
    docKey: 'policy_summary',
    title: 'Policy Summary',
    description: 'Enumerates your policies and provides a brief description of the purpose of each',
  },

  {
    docKey: 'faq',
    title: 'Frequently Asked Questions',
    description: 'Provides concise answers to the most commonly asked questions about your security program',
  },

  {
    docKey: 'security_program_overview',
    title: 'Security Program Overview',
    description: 'Overview of your entire security program summarizing key activities and controls that are often of interest to your partners and customers',
  },

  {
    docKey: 'nist_overview',
    title: 'NIST Overview',
    description: 'Overview of NIST 800-53 and CSF as it relates to your security program',
  },
];

function TheMarketingBundlePage() {
  const [expanded, setExpanded] = useState(true);

  return (
    <>
      <Accordion
        elevation={2}
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
      >
        <AccordionIconSummary
          title="Security Related Marketing Documents">
          <Typography variant="body2">
            The Marketing Bundle gives a high-level overview of your ESG program in Gemini. This is a great
            resource to give to stakeholders and partners asking about your ESG plans. The Marketing Bundle does
            not describe your current state of ESG progress. If you would like a document describing your current state of
            ESG progress, please request an attestation letter.
          </Typography>
        </AccordionIconSummary>

        <AccordionDetails>
          <List>
            {marketingBundleDocData.map(doc => (
              <ListItem
                key={doc.docKey}
                button
                onClick={DocService.marketingBundleDocDownloadHandler(doc.docKey)}
              >
                <ListItemText
                  primary={doc.title}
                  secondary={doc.description}
                />
                <ListItemIcon>
                  <DownloadButton />
                </ListItemIcon>
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

TheMarketingBundlePage.title = 'Marketing';
TheMarketingBundlePage.requiredAuthZ = {
  tier: 3,
  permission: 'marketing_bundle',
};

export default TheMarketingBundlePage;
