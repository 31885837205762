import { Button, Link, makeStyles, Typography } from '@material-ui/core';
import PageActions from '../components/PageActions';
import Page from '../components/Page';
import ESGReportImage from '../components/ESGReportImage';
import { GEMINI_SUPPORT_EMAIL } from '../helpers';
import Auth from '../services/AuthService';

const useStyles = makeStyles({
    welcomeText: {
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: '1.5rem',
        maxWidth: '40rem',
        paddingLeft: '1em',
        paddingRight: '1em',
        paddingTop: '1em'
    },
    secondButton: {
        marginLeft: '1rem',
    },
    siteName: {
        color: '#000000',
    }
});

function TheESGReportsPageManagedTier() {
    const classes = useStyles();

    return (
        <>
            <Typography
                className={classes.welcomeText}
                paragraph
                variant="body1"
            >
                Contact your Gemini account manager to get started using our Impact and ESG Reporting service.
            </Typography>
        </>
    );
}

function TheESGReportsPageBaseTier() {
    const classes = useStyles();

    return (
        <>
            <Typography
                className={classes.welcomeText}
                paragraph
                variant="body1"
            >
                We offer our Impact and ESG Reporting service as a part of our ESG as a Service consulting package.
                To get reports that are compliant with emerging climate and ESG disclosure regulations,
                contact us to upgrade your plan.
            </Typography>
        </>
    );
}

export interface TheESGReportsPageProps {
    auth: Auth;
}

function TheESGReportsPage({ auth }: TheESGReportsPageProps) {
    const classes = useStyles();
    let text;

    if (auth.isGranted({ tier: 8 })) {
        // Use 'tasks' as a proxy for 'OrgManager' permissions
        text = <TheESGReportsPageManagedTier />;
    } else {
        text = <TheESGReportsPageBaseTier />;
    }

    return (
        <>
            <Page center>
                <ESGReportImage />
                <Typography
                    className={classes.welcomeText}
                    paragraph
                    variant="body1"
                >
                    Communicate your performance using a variety of reporting templates
                    - from ESG reports to climate risk disclosures - that are comprehensive,
                    clear, and auditable. Our reports are aligned with global disclosure standards
                    and frameworks like SASB, TCFD, GRI, UNPRI, and the UNSDG's.
                </Typography>
                {text}
                <PageActions center>
                    <   Link href={`mailto:${GEMINI_SUPPORT_EMAIL}`}>

                        <Button
                            variant="contained"
                            className={classes.secondButton}
                        >
                            Contact Us
                        </Button>
                    </Link>
                </PageActions>
            </Page>
        </>
    );
}

TheESGReportsPage.title = 'Impact Mapping';
TheESGReportsPage.requiredAuthZ = {
    tier: 0,
    permission: 'dashboard',
};

export default TheESGReportsPage;
