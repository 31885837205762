import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useTracking } from './hooks';
import Theme from './theme';
import { TheDashboardLayout, TheLoginLayout, TheQuestionnaireLayout } from './layouts';
import {
  The404Page,
  TheAttestationPage,
  TheAuthCallback,
  TheBetaPage,
  TheDashboardPage,
  TheDocDiscoveryPage,
  TheDocumentsPage,
  TheESGReportsPage,
  TheEvidencePage,
  TheInboundQuestionnairePage,
  TheLoginPage,
  TheLogoutPage,
  TheMarketingBundlePage,
  TheMaterialityAssessmentPage,
  TheMaterialityRegisterPage,
  TheMetricDashboardPage,
  TheMetricDetailPage,
  TheMetricSummaryPage,
  TheMetricTrackingPage,
  TheNetworkScanPage,
  TheNetworkScanRequestPage,
  TheOrganizationPage,
  TheOrgSharePage,
  TheOutboundQuestionnairePage,
  TheOutboundQuestionnaireResponsePage,
  ThePdfViewPage,
  ThePermissionsPage,
  ThePolicyAckReportPage,
  ThePolicyPage,
  TheQuestionnaireResponseDetailPage,
  TheRegisterPage,
  TheRegistrationClosedPage,
  TheResourcePage,
  TheStakeholderPage,
  TheStakeholderQuestionnairePage,
  TheSubscriptionPage,
  TheSupportPage,
  TheTasksPage,
  TheTasksReportPage,
  TheTrainingPage,
  TheTrainingReportPage,
  TheUserAuditAwsPage,
  TheUserAuditAzurePage,
  TheUserAuditGithubPage,
  TheUserAuditGooglePage,
  TheUserAuditPage,
  TheUserManagementPage,
  TheVendorQuestionnairePage,
  TheVendorTrackingPage,
  TheVerifyEmailPage,
  TheWelcomePage,
} from './pages';
import { SpioPickersUtils } from './SpioPickersUtils';
import * as Sentry from '@sentry/react';

const App = (props) => {
  const commonProps = {
    auth: props.auth,
    exact: true,
  };

  useTracking();

  return (
    <Sentry.ErrorBoundary failback={'An error has occurred'}>
      <MuiPickersUtilsProvider utils={SpioPickersUtils}>
        <MuiThemeProvider theme={Theme}>
          <CssBaseline />
          <Switch>
            <TheLoginLayout {...commonProps} path="/logout" component={TheLogoutPage} />

            {props.auth.isRegistered() && <Redirect exact from="/register" to="/welcome" />}
            {/* <TheLoginLayout {...commonProps} path="/register" component={TheRegisterPage} /> */}
            {props.auth.isRegistered() && <Redirect exact from="/registration-closed" to="/welcome" />}
            <TheLoginLayout {...commonProps} path="/registration-closed" component={TheRegistrationClosedPage} />

            {(props.auth.isOrgManager() || props.auth.isOrgOwner() || props.auth.isOrgEmployee()) && !props.auth.isFirstVisit && <Redirect exact from="/" to="/dashboard" />}
            {props.auth.isPolicyManager() && !props.auth.isFirstVisit && <Redirect exact from="/" to="/policies" />}
            {props.auth.isDataEntry() && !props.auth.isFirstVisit && <Redirect exact from="/" to="/evidence" />}
            {props.auth.isOrgTrainee() && <Redirect exact from="/" to="/training" />}
            {props.auth.isAuthenticated() && <Redirect exact from="/" to="/welcome" />}

            <Redirect exact from="/" to="/login" />

            <TheDashboardLayout {...commonProps} path="/attestation" component={TheAttestationPage} />
            <TheDashboardLayout {...commonProps} path="/beta/:feature?" component={TheBetaPage} title="Beta Notice" />
            <TheDashboardLayout {...commonProps} path="/dashboard" component={TheDashboardPage} />
            <TheDashboardLayout {...commonProps} path="/document-discovery" component={TheDocDiscoveryPage} />
            <TheDashboardLayout {...commonProps} path="/deliverables" component={TheDocumentsPage} />
            <TheDashboardLayout {...commonProps} path="/deliverables/:document" component={ThePdfViewPage} />
            <TheDashboardLayout {...commonProps} path="/evidence" component={TheEvidencePage} />
            <TheDashboardLayout {...commonProps} path="/evidence/:doc" component={ThePdfViewPage} />
            <TheDashboardLayout {...commonProps} path="/marketingBundle" component={TheMarketingBundlePage} />
            <TheDashboardLayout {...commonProps} path="/materiality-assessment" component={TheMaterialityAssessmentPage} />
            <TheDashboardLayout {...commonProps} path="/materiality-register" component={TheMaterialityRegisterPage} />
            <TheDashboardLayout {...commonProps} path="/metricDashboard/" component={TheMetricDashboardPage} />
            <TheDashboardLayout {...commonProps} path="/metricTracking" component={TheMetricTrackingPage} />
            <TheDashboardLayout {...commonProps} path="/metricTracking/metric/:id" component={TheMetricDetailPage} />
            <TheDashboardLayout {...commonProps} path="/metricTracking/metric/:id/summary" component={TheMetricSummaryPage} />
            <TheDashboardLayout {...commonProps} path="/org-share" component={TheOrgSharePage} />
            <TheDashboardLayout {...commonProps} path="/outbound-questionnaire" component={TheOutboundQuestionnairePage} />
            <TheDashboardLayout {...commonProps} path="/outbound-questionnaire/responses/:id" component={TheQuestionnaireResponseDetailPage} />
            <TheDashboardLayout {...commonProps} path="/permissions" component={ThePermissionsPage} />
            <TheDashboardLayout {...commonProps} path="/policies" component={ThePolicyPage} />
            <TheDashboardLayout {...commonProps} path="/policies/:policy" component={ThePdfViewPage} />
            <TheDashboardLayout {...commonProps} path="/reporting" component={TheESGReportsPage} />
            <TheDashboardLayout {...commonProps} path="/resources" component={TheResourcePage} />
            <TheDashboardLayout {...commonProps} path="/resources/:resource" component={ThePdfViewPage} />
            <TheDashboardLayout {...commonProps} path="/scans" component={TheNetworkScanPage} />
            <TheDashboardLayout {...commonProps} path="/subscription" component={TheSubscriptionPage} />
            <TheDashboardLayout {...commonProps} path="/support" component={TheSupportPage} />
            <TheDashboardLayout {...commonProps} path="/tasks" component={TheTasksPage} />
            <TheDashboardLayout {...commonProps} path="/tasks/report/:collectionId?" component={TheTasksReportPage} />
            <TheDashboardLayout {...commonProps} path="/tasks/:view?" component={TheTasksPage} />
            <TheDashboardLayout {...commonProps} path="/training" component={TheTrainingPage} />
            <TheDashboardLayout {...commonProps} path="/training/:vid" component={TheTrainingPage} />
            <TheDashboardLayout {...commonProps} path="/welcome" component={TheWelcomePage} />
            <TheDashboardLayout {...commonProps} path={TheInboundQuestionnairePage.routePath} component={TheInboundQuestionnairePage} />
            <TheDashboardLayout {...commonProps} path={TheNetworkScanRequestPage.routePath} component={TheNetworkScanRequestPage} />
            <TheDashboardLayout {...commonProps} path={TheOrganizationPage.routePath} component={TheOrganizationPage} />
            <TheDashboardLayout {...commonProps} path={ThePolicyAckReportPage.routePath} component={ThePolicyAckReportPage} />
            <TheDashboardLayout {...commonProps} path={TheStakeholderPage.routePath} component={TheStakeholderPage} />
            <TheDashboardLayout {...commonProps} path={TheTrainingReportPage.routePath} component={TheTrainingReportPage} />
            <TheDashboardLayout {...commonProps} path={TheUserAuditAwsPage.routePath} component={TheUserAuditAwsPage} />
            <TheDashboardLayout {...commonProps} path={TheUserAuditAzurePage.routePath} component={TheUserAuditAzurePage} />
            <TheDashboardLayout {...commonProps} path={TheUserAuditGithubPage.routePath} component={TheUserAuditGithubPage} />
            <TheDashboardLayout {...commonProps} path={TheUserAuditGooglePage.routePath} component={TheUserAuditGooglePage} />
            <TheDashboardLayout {...commonProps} path={TheUserAuditPage.routePath} component={TheUserAuditPage} />
            <TheDashboardLayout {...commonProps} path={TheUserManagementPage.routePath} component={TheUserManagementPage} />
            <TheDashboardLayout {...commonProps} path={TheVendorTrackingPage.routePath} component={TheVendorTrackingPage} />
            <TheLoginLayout {...commonProps} path="/callback" component={TheAuthCallback} />
            <TheLoginLayout {...commonProps} path="/login" component={TheLoginPage} />
            <TheLoginLayout {...commonProps} path="/verify" component={TheVerifyEmailPage} />
            <TheQuestionnaireLayout {...commonProps} path="/outbound-questionnaire/:id" component={TheOutboundQuestionnaireResponsePage} />
            <TheQuestionnaireLayout {...commonProps} path="/stakeholder-questionnaire/:id" component={TheStakeholderQuestionnairePage} />
            <TheQuestionnaireLayout {...commonProps} path="/vendor-questionnaire/:id" component={TheVendorQuestionnairePage} />

            {props.auth.isAuthenticated()
              ? <TheDashboardLayout {...commonProps} path="/error/404" component={The404Page} />
              : <TheLoginLayout {...commonProps} path="/error/404" component={The404Page} />
            }

            <Route path="/signup" render={() => props.auth.signup('/welcome')} />
            <Route render={() => <Redirect to="/error/404" />} />
          </Switch>
        </MuiThemeProvider>
      </MuiPickersUtilsProvider>
    </Sentry.ErrorBoundary>
  );
};

export default App;
