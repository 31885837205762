import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { FileWithPath, useDropzone } from 'react-dropzone';

const useStyles = makeStyles({
  root: {
    alignItems: 'center',
    backgroundColor: '#fafafa',
    borderColor: '#ccc',
    borderRadius: 2,
    borderStyle: 'dashed',
    borderWidth: 2,
    color: '#415468',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    fontSize: '1rem',
    outline: 'none',
    padding: '20px',
    transition: 'border .24s ease-in-out',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  active: {
    borderColor: '#2196f3',
  },
  accept: {
    borderColor: '#00e676',
  },
  reject: {
    borderColor: '#ff1744',
  },
});

export interface StyledDropzoneProps {
  disabled?: boolean;
  onUpdateFile: (files: FileWithPath[]) => void;
  placeholder?: string;
  maxFileUpload: number;
  setErrors?: (errors: string[] | null) => void;
}

export default function StyledDropzone({ disabled = false, onUpdateFile, placeholder, maxFileUpload, setErrors}: StyledDropzoneProps) {
  const classes = useStyles();
  const [filesRejected, setFilesRejected] = useState(false);

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    fileRejections
  } = useDropzone({
    disabled,
    maxFiles: maxFileUpload,
    minSize: 0,
    maxSize: 1073741824,
  });

  if (fileRejections.length > 0 && setErrors && !filesRejected){
    // get the set of all errors
    const allErrors: string[] = [];
    fileRejections.forEach(element => element.errors.forEach(e => allErrors.push(e.message)));
    const errors = [...new Set(allErrors)];

    setErrors(errors);
    setFilesRejected(true);
  }

  //TODO: there is a propagation error here so this was a hacky solution to be able to upload multiple or single files
  useEffect(() => {
    let file = null;
    if (maxFileUpload > 1) {
      file = acceptedFiles;
      if (file) {
        onUpdateFile(file);
        if (setErrors) {
          setErrors(null);
          setFilesRejected(false);
        }
      }
    } else {
      file = acceptedFiles.pop();
      if (file) {
        onUpdateFile([file]);
        if (setErrors) {
          setErrors(null);
          setFilesRejected(false);
        }
      }
    }
  }, [ acceptedFiles, onUpdateFile ]);

  return (
    <div
      className={classNames(
        classes.root,
        isDragAccept && classes.accept,
        isDragActive && classes.active,
        isDragReject && classes.reject,
      )}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <p>{placeholder || 'Drop file(s) here, or click to select file(s)'}</p>
    </div>
  );
}
