import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import StyledDialogTitle from '../StyledDialogTitle';
import { Link as RouterLink } from 'react-router-dom';

export interface InformationDialogProps extends DialogProps {
  onClose: () => void;
  text?: string;
  title: string;
}

function InformationDialog({ text, onClose, title, ...dialogProps }: InformationDialogProps) {


  return (
    <Dialog {...dialogProps}>
      <StyledDialogTitle>{title}</StyledDialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          size="small"
          onClick={onClose}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default InformationDialog;
