import {
    Card,
    CardHeader,
    createStyles,
    Grid,
    Tab,
    TabProps,
    Tabs,
    Theme,
    withStyles,
  } from '@material-ui/core';
  import { makeStyles } from '@material-ui/styles';
  import React, { useEffect, useState } from 'react';
  import { ChartOptions } from 'chart.js';
  import BarChartDownload from './BarChartDownload';
  import { createStringBreaks } from '../../helpers';
  import { IMaterialityAssessmentResponseCountDto } from '../../../../backend/src/material-issue/interfaces/material-assessment-response-count-dto.interface';
  import { IMaterialityAssessmentResponseIssuesDto } from '../../../../backend/src/material-issue/interfaces/material-assessment-response-dto.interface';

  const useStyles = makeStyles({
    chartContainer: {
      paddingTop: '2rem',
      paddingBottom: '2rem',
      minHeight: '360px',
    },
    tabsContainer: {
      marginTop: '-10px',
    },
  });

  const StyledTab = withStyles((theme: Theme) => createStyles({
    root: {
      fontSize: '1rem',
      fontWeight: theme.typography.fontWeightRegular,
      letterSpacing: '0.0075em',
      paddingTop: '0',
      textTransform: 'none',
      minWidth: 200,
      width: 200,
    },
    selected: {
      fontWeight: theme.typography.fontWeightMedium,
    },
  }))((props: TabProps) => <Tab {...props} />);

  export interface MaterialityAssessmentBarChartProps {
    selectedQuestionnaire: string;
    filterOptions: {[key: string]: string}[] | [];
    issues: IMaterialityAssessmentResponseIssuesDto[];
    chartColors: string[];
    responseCounts: IMaterialityAssessmentResponseCountDto;
  }

  function MaterialityAssessmentBarChart({
    selectedQuestionnaire,
    filterOptions,
    issues,
    chartColors,
    responseCounts
  }: MaterialityAssessmentBarChartProps) {
    const classes = useStyles();

    const barChartOptions: ChartOptions = {
      plugins: {
        title: {
          display: true,
          text: 'Chart.js Bar Chart - Stacked'
        },
      },
      responsive: true,
      scales: {
        xAxes: [{
          stacked: false,
          ticks: {
            fontColor: 'rgba(0, 0, 0, 0.87)',
            fontSize: 13,
          },
          scaleLabel: {
            display: true,
            labelString: 'Material Issue',
            fontSize: 16,
          }
        }],
        yAxes: [{
          stacked: false,
          ticks: {
            beginAtZero: true,
            precision: 0,
            max: 5.5
          },
          scaleLabel: {
            display: true,
            fontSize: 16,
            labelString: 'Rating - Least Important (1) to Most Important (5)'
          }
        }],
      }
    };

    const [ selectedTab, setSelectedTab ] = useState(0);
    const [ selectedFilter, setSelectedFilter ] = useState<string>('');
    const [ filterSelections, setFilterSelections ] = useState<string[]>([]);
    const [ chartData, setChartData ] = useState<any>({});

    const renderDataset = () => {
      const dataset = [{
        label: 'All Responses',
        data: issues ? issues.slice(0, 5).map((i) => Math.round(i.all*100)/100) : [],
        backgroundColor: chartColors[0],
      }];

      if (issues && selectedFilter && Object.keys(issues[0].filters).includes(selectedFilter)) {
        for (const filterOption of Object.keys(issues[0].filters[selectedFilter])) {
          // only display if there are responses for that filter option
          if (responseCounts?.filters[selectedFilter][filterOption] !== 0){
            dataset.push({
              label: filterOption,
              data: issues ? issues.slice(0, 5).map((i) => Math.round((i.filters[selectedFilter][filterOption] || 0)*100)/100) : [],
              backgroundColor: chartColors[dataset.length],
            });
          };
        }
      }

      return dataset;
    };

    const renderLabelsWithBreaks = () => {
      if (issues) {
        return issues.slice(0, 5).map((i) => createStringBreaks(i.info?.name));
      } else {
        return [];
      }
    };

    useEffect(() => {
      setChartData({
        labels:  renderLabelsWithBreaks(),
        datasets: renderDataset(),
      });
    }, [issues, selectedFilter]);

    useEffect(() => {
      setFilterSelections(filterOptions.map(f => f.filter).filter((v, i, a) => a.indexOf(v) === i));
    }, [filterOptions]);

    useEffect(() => {
      setSelectedTab(0);
    }, [selectedQuestionnaire]);

    useEffect(() => {
      setSelectedFilter(filterSelections[selectedTab]);
    }, [selectedTab, filterSelections]);

    return (
        <Card>
            <CardHeader
            title= {'Top Material Issues Scores'}
            className={classes.tabsContainer}
            titleTypographyProps={{variant:'h6' }}
            />
            <Tabs
                className={classes.tabsContainer}
                value={selectedTab}
                onChange={(_, value) => setSelectedTab(value)}
                scrollButtons="on"
                variant="scrollable"
            >
              {filterSelections.map(filters =>
                <StyledTab label={filters} wrapped />
              )}
            </Tabs>
            <Grid container className={classes.chartContainer}>
              <BarChartDownload data={chartData} options={barChartOptions} />
            </Grid>
        </Card>
    );
  }

export default MaterialityAssessmentBarChart;
