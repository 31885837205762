import React, { useState } from 'react';
import { Button, Link, makeStyles, Typography } from '@material-ui/core';
import * as Sentry from '@sentry/browser';
import PageActions from '../components/PageActions';
import { ITrackViewStart, ITrainingViewDto, ITrainingViewUpdateDto } from '../../../backend/src/training/interfaces';
import Page from '../components/Page';
import { VideoDialog } from '../components/dialogs';
import DocDiscoveryImage from '../components/DocDiscoveryImage';
import { GEMINI_SUPPORT_EMAIL, DemoVids } from '../helpers';
import Auth from '../services/AuthService';
import API from '../services/ApiService';

const useStyles = makeStyles({
  welcomeText: {
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '1.5rem',
    maxWidth: '40rem',
    paddingLeft: '1em',
    paddingRight: '1em',
  },
  secondButton: {
    marginLeft: '1rem',
  },
  siteName: {
    color: '#000000',
  }
});

function TheDocDiscoveryPageManagedTier() {
  const classes = useStyles();

  return (
    <>
      <Typography
        className={classes.welcomeText}
        paragraph
        variant="body1"
      >
        Contact your Gemini account manager to get started using our Document Discovery service.
      </Typography>
    </>
  );
}

function TheDocDiscoveryPageBaseTier() {
  const classes = useStyles();

  return (
    <>
      <Typography
        className={classes.welcomeText}
        paragraph
        variant="body1"
      >
        Document Discovery is a service we provide as a part of ESG as a Service consulting package.
        Contact us for more information.
      </Typography>
    </>
  );
}

export interface TheDocDiscoveryPageProps {
  auth: Auth;
}

function TheDocDiscoveryPage({ auth }: TheDocDiscoveryPageProps) {
  let text;
  const classes = useStyles();

  const [videoPlayerOpen, setVideoPlayerOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const [videoView, setVideoView] = useState<ITrainingViewDto | null>(null);

  const handleStartVideo = async () => {
    const trackViewInfo: ITrackViewStart = (await API.post(`training/${DemoVids.DocDiscovery}/view`))?.data?.data;

    if (trackViewInfo) {
      setVideoUrl(trackViewInfo.url);
      setVideoView(trackViewInfo.view);
      setVideoPlayerOpen(true);
    }
  };

  const handleUpdateVideo = async (updateInfo: ITrainingViewUpdateDto) => {
    try {
      if (videoView) {
        await API.patch(`training/view/${videoView.id}`, updateInfo);
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  const handleCloseVideo = () => {
    setVideoPlayerOpen(false);
    setVideoUrl(null);
    setVideoView(null);
  };

  // Handle the case where the video link expires and we need to reset:
  const handlePlaybackError = (error: any) => {
    Sentry.captureException(error);
    handleStartVideo();
  };

  if (auth.isGranted({ tier: 8 })) {
    // Use 'tasks' as a proxy for 'OrgManager' permissions
    text = <TheDocDiscoveryPageManagedTier />;
  } else {
    text = <TheDocDiscoveryPageBaseTier />;
  }

  return (
    <Page center>
      <DocDiscoveryImage />
      <Typography
        className={classes.welcomeText}
        paragraph
        variant="body1"
      >
        Streamline the process of identifying, collecting, and
        organizing your ESG data and documentation using our Doc Discovery tool. Our
        algorithms automatically sort your docs, spreadsheets, and PDF’s into relevant
        and irrelevant evidence, then organizes the relevant files into their
        appropriate data category. Remove the time, labor, and errors associated with
        manual data collection and establish ESG data baselines, discover blind spots,
        and identify areas for improvement with greater speed and accuracy.
      </Typography>
      {text}
      <PageActions center>
        <   Link href={`mailto:${GEMINI_SUPPORT_EMAIL}`}>

          <Button
            variant="contained"
            className={classes.secondButton}
          >
            Contact Us
          </Button>
        </Link>
        <Button
          variant="contained"
          onClick={handleStartVideo}
        >
          Watch Demo
        </Button>
        {videoUrl && videoView &&
          <VideoDialog
            open={videoPlayerOpen}
            videoViewInfo={{
              name: 'Document Discovery Demo',
              played: videoView.played,
              url: videoUrl,
            }}
            onClose={handleCloseVideo}
            onError={handlePlaybackError}
            onUpdateVideo={handleUpdateVideo}
          />
        }
      </PageActions>
    </Page>
  );
}

TheDocDiscoveryPage.title = 'Document Discovery';
TheDocDiscoveryPage.requiredAuthZ = {
  tier: 1,
  permission: 'dashboard',
};

export default TheDocDiscoveryPage;
