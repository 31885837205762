import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import * as Sentry from '@sentry/browser';
import React from 'react';
import BuildingBlocksImage from '../components/BuildingBlocksImage';
import Page from '../components/Page';
import PageActions from '../components/PageActions';
import { showErrorResultBar, showSuccessResultBar } from '../components/ResultSnackbar';
import API from '../services/ApiService';
import { BodyText } from '../components/BodyText';

function TheAttestationPage() {
  async function handleClick() {
    try {
      await API.post('org/requestAttestation');

      showSuccessResultBar('Your request has been logged. We\'ll be in touch soon!');
    } catch (err) {
      Sentry.captureException(err);
      showErrorResultBar('Something went wrong processing your request. Try again later.');
    }
  }

  return (
    <Page center>
      <BuildingBlocksImage />
      <Typography variant="h6">
        Attestation Letter Request
      </Typography>
      <BodyText>
        An attestation letter summarizes your ESG program as implemented in Gemini. It can be
        provided to partners, customers, etc to provide a statement describing your policies and efforts to ensure
        ESG-forward operations. Attestation letters are available from Gemini upon request.
      </BodyText>

      <BodyText>
        If you'd like to request an attestation letter for your organization, click the button below.
      </BodyText>

      <PageActions center>
        <Button variant="contained" color="primary" onClick={handleClick}>
          Request Attestation
        </Button>
      </PageActions>
    </Page>
  );
}

TheAttestationPage.title = 'Attestation Statement';
TheAttestationPage.requiredAuthZ = {
  tier: 1,
  permission: 'attestation',
};

export default TheAttestationPage;
