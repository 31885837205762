import { createStyles, Theme, Typography, WithStyles, withStyles } from '@material-ui/core';
import React from 'react';

const styles = (theme: Theme) => createStyles({
  caption: {
    color: theme.typography.body1.color,
    fontSize: '8pt',
    lineHeight: '0.8',
    paddingTop: '1px',
  },

  circle: {
    height: '24px',
    width: '24px',
    borderRadius: '50%',
    display: 'inline-block',
    border: 'solid 2px',
    borderColor: '#979797',
    justifyContent: 'center',
    fontWeight: 'bold',
    fontSize: '12pt',
  },

  root: {
    justifyContent: 'center',
    textAlign: 'center',
    color: '#979797',
  }

});

function ConsiderIcon({ classes }: WithStyles<typeof styles>) {
  return (
    <div className={classes.root}>
      <div className={classes.circle}>
        ?
      </div>

      <Typography display="block" variant="caption" className={classes.caption}>Consider</Typography>
    </div>
  );
}

export default withStyles(styles, { withTheme: true })(ConsiderIcon);
