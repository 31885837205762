import Page from '../components/Page';
import BuildingBlocksImage from '../components/BuildingBlocksImage';
import { Typography } from '@material-ui/core';
import React from 'react';
import { BodyText } from '../components/BodyText';
import Auth from '../services/AuthService';

export interface The404PageProps {
  auth: Auth;
}

export default function The404Page({ auth }: The404PageProps) {
  return (
    <Page center>
      <BuildingBlocksImage />
      <Typography variant="h2">
        Page not found
      </Typography>
      <BodyText align="center">
        Oops! The page you requested could not be located.<br /><br />

        {auth.isAuthenticated()
          ? 'Please select a link from the navigation bar to proceed.'
          : 'Please login to proceed.'
        }
      </BodyText>
    </Page>
  );
}
