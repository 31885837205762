import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    Grid,
    List,
    ListItem,
    ListItemText,
    StandardProps,
    TextField,
    Typography,
} from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { FileWithPath } from 'react-dropzone';
import StyledDialogTitle from '../StyledDialogTitle';
import ResponsiveDialog from './ResponsiveDialog';
import { Formik } from 'formik';
import { IIdNameVersionDto } from '../../../../backend/src/common/id-name-version-dto.interface';

export interface AttachExistingDocumentDialogProps extends StandardProps<DialogProps, 'children'> {
  attachableDocuments: IIdNameVersionDto[]
  onClose: () => void;
  attachDocument: (newDoc: IIdNameVersionDto[]) => Promise<void>;
  versions?: boolean;
}

export interface FileWithPathProgress extends FileWithPath {
  uploadProgress: number;
}

export default function AttachExistingDocumentDialog(props: AttachExistingDocumentDialogProps) {
  const {
    open,
    attachableDocuments,
    onClose,
    attachDocument,
    versions = false
  } = props;
  const [filteredDocuments, setFilteredDocuments] = useState<IIdNameVersionDto[]>(attachableDocuments);
  const [query, setQuery] = useState('');
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  useEffect(() => {
    setFilteredDocuments(attachableDocuments);
  }, [attachableDocuments]);

  const handleSave = async () => {
    if (selectedIndex !== null) {
        attachDocument([filteredDocuments[selectedIndex]]);
        onClose();
        setQuery('');
        setFilteredDocuments(attachableDocuments);
        setSelectedIndex(null);
    }
};

  const handleListItemClick = (event: React.MouseEvent<HTMLElement, MouseEvent>, index: number) => {
    setSelectedIndex(index);
  };

  const handleFilter = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {

    let unAttachedFiltered = attachableDocuments;

    const results = unAttachedFiltered.filter((filteredEvidence) => {
        if (e.target.value === '' && query.length === 1) {
          setQuery(e.target.value);
          return filteredEvidence.name
            .toLowerCase()
            .includes(e.target.value.toLowerCase());
        } else if (e.target.value === '' && query.length > 0) {
          setQuery(query);
          return filteredEvidence.name
            .toLowerCase()
            .includes(query.toLowerCase());
        } else {
          setQuery(e.target.value);
          return filteredEvidence.name
            .toLowerCase()
            .includes(e.target.value.toLowerCase());
        }
      });
      setFilteredDocuments(results);
    };

  return (
    <ResponsiveDialog
      disableBackdropClick={true}
      fullWidth
      open={open}
      onClose={onClose}
      maxWidth="sm"

    >
      <StyledDialogTitle onClose={onClose}>Attach Document</StyledDialogTitle>
      <Formik
        enableReinitialize
        initialValues={[]}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          handleSave();
          onClose();
          setQuery('');
          setFilteredDocuments(attachableDocuments);
          setSelectedIndex(null);
          setSubmitting(false);
        }}
        onReset={(_) => {
          setQuery('');
          setFilteredDocuments(attachableDocuments);
          setSelectedIndex(null);
          onClose();
        }}
      >
        {({
          handleBlur,
          handleReset,
          isSubmitting,
        }) => (
          <>
            <DialogContent>
              <Grid
                container
                justifyContent="space-between"
                alignItems="flex-end"
                style={{ marginBottom: '2rem' }}
              >
                <Grid item xs={9}>
                  <TextField
                    autoFocus
                    id="evidence-name"
                    label="Filter by Name"
                    name="filter"
                    type="text"
                    value={query}
                    onBlur={handleBlur}
                    onChange={(e) => handleFilter(e)}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" color="primary">
                  Available Documents
                </Typography>
                <Box
                    mb={2}
                    display="flex"
                    flexDirection="column"
                    height="300px" // fixed the height
                    style={{
                        overflow: 'hidden',
                        overflowY: 'scroll' // added scroll
                    }}
                >
                    <List dense>
                    {filteredDocuments.length > 0 &&
                        filteredDocuments.map((doc, index) => {
                        return (
                            <ListItem
                                key={index}
                                button
                                selected={selectedIndex === index}
                                onClick={(event) => handleListItemClick(event, index)}
                            >
                            <ListItemText
                                primary={doc.name}
                                primaryTypographyProps={{ color: 'textPrimary' }}
                                secondary={`Version: ${doc.version}`}
                                secondaryTypographyProps={{ color: 'textSecondary' }}
                            />
                            </ListItem>
                        );
                        })}
                    </List>
                </Box>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                size="small"
                disabled={isSubmitting}
                onClick={handleReset}
              >
                Cancel
              </Button>
              <Button
                disabled={isSubmitting || selectedIndex===null}
                color="primary"
                variant="contained"
                onClick={handleSave}
                >
                Attach Document
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </ResponsiveDialog>
  );
}
