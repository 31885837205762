import { createStyles, Link, Theme, Typography, WithStyles, withStyles } from '@material-ui/core';
import React from 'react';
import { StaticLinks } from '../helpers';

const styles = (theme: Theme) => createStyles({
  termsLinkSeparator: {
    padding: theme.spacing(1),
  },

  footer: {
    padding: theme.spacing(2),
    backgroundColor: 'transparent',
    flexShrink: 0,
  },
});

const TermsLinkSeparator = withStyles(styles, { withTheme: true })(({ classes }: WithStyles<typeof styles>) => {
  return (
    <span className={classes.termsLinkSeparator}>
      &middot;
    </span>
  );
});

function TheTermsFooter({ classes }: WithStyles<typeof styles>) {
  return (
    <footer className={classes.footer}>
      <Typography
        variant="body2"
        color="textSecondary"
        align="center"
      >
        &copy; {(new Date()).getFullYear()} Rho Impact
        <TermsLinkSeparator />
        <Link
          color="inherit"
          href={StaticLinks.terms}
          target="_blank"
        >
          Terms
        </Link>
        <TermsLinkSeparator />
        <Link
          color="inherit"
          href={StaticLinks.saasTerms}
          target="_blank"
        >
          SaaS Terms
        </Link>
        <TermsLinkSeparator />
        <Link
          color="inherit"
          href={StaticLinks.privacy}
          target="_blank"
        >
          Privacy
        </Link>
        <TermsLinkSeparator />
        {process.env.REACT_APP_VERSION}
        {process.env.REACT_APP_AUTH_DOMAIN?.includes('stag') &&
          <>
            <TermsLinkSeparator />
            Public Beta
          </>
        }
        {/* <TermsLinkSeparator />
        Server v{server_version} */}

      </Typography>
    </footer>
  );
}

export default withStyles(styles)(TheTermsFooter);
