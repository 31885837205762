import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import StyledDialogTitle from '../StyledDialogTitle';
import { Link as RouterLink } from 'react-router-dom';

export interface LimitReachedDialogProps extends DialogProps {
  onClose: () => void;
  text?: string;
}

function LimitReachedDialog({ text, onClose, ...dialogProps }: LimitReachedDialogProps) {


  return (
    <Dialog {...dialogProps}>
      <StyledDialogTitle>Upgrade Plan</StyledDialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          component={RouterLink}
          to={'/subscription'}
        >
          Go to Upgrades Page
        </Button>
        <Button
          color="primary"
          size="small"
          onClick={onClose}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default LimitReachedDialog;
