import React, { useEffect, useState } from 'react';
import API from '../services/ApiService';
import GanttChart from '../components/GanttChart';
import {
  showErrorResultBar,
  showSuccessResultBar,
} from '../components/ResultSnackbar';
import * as Sentry from '@sentry/browser';
import { useParams } from 'react-router-dom';
import {
  ITasksBulkUpdateDto,
} from '../../../backend/src/task/interfaces';
import { Grid, Card, makeStyles } from '@material-ui/core';
import { IGanttTask } from '../components/GanttChart';
import Auth from '../services/AuthService';
import UnassignedTaskTable from '../components/widgets/UnassignedTaskTable';

export interface TaskReportProps {
  auth: Auth;
}

const useStyles = makeStyles({
  cardContent: {
    'marginLeft': '4rem',
  }
});

function TheTasksReportPage({ auth }: TaskReportProps) {
  const { collectionId } = useParams<{ collectionId?: string }>();
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(true);
  const [taskData, setTaskData] = useState<IGanttTask[]>([]);
  const [unassignedTasks, setUnassignedTasks] = useState<IGanttTask[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    if (collectionId) {
      API.get(`task/report/${collectionId}`)
        .then((res) => {
          const data: IGanttTask[] = res.data?.data || [];
          setTaskData(
            data.filter(t => t.start && t.end).map(d =>
              Object({
                name: d.name,
                id: d.id,
                start: d.start ? new Date(d.start) : null,
                end: d.end ? new Date(d.end): null,
                progress: d.progress,
                isDisabled: false,
                type: d.type,
                project: d.projectCollection,
                projectGroup: d.projectGroup,
                projectCollection: d.projectCollection,
                dependencies: d.dependencies,
                projectType: d.projectType,
                hideChildren: false,
                displayOrder: d.displayOrder,
              })
            )
          );
          setUnassignedTasks(
            data.filter(t => !t.start || !t.end || t.type === 'project').map(d =>
              Object({
                name: d.name,
                id: d.id,
                start: d.start ? new Date(d.start) : null,
                end: d.end ? new Date(d.end): null,
                progress: d.progress,
                isDisabled: false,
                type: d.type,
                project: d.projectCollection,
                projectGroup: d.projectGroup,
                projectCollection: d.projectCollection,
                dependencies: d.dependencies,
                projectType: d.projectType,
                hideChildren: false,
                displayOrder: d.displayOrder,
              })
            )
          );
        })
        .catch((err) => {
          showErrorResultBar('Unexpected error loading task data');
          Sentry.captureException(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      API.get('task/report')
        .then((res) => {
          const data: IGanttTask[] = res.data?.data || [];
          setTaskData(
            data.filter(t => (t.start && t.end)).map(d =>
              Object({
                name: d.name,
                id: d.id,
                start: d.start ? new Date(d.start): null,
                end: d.end ? new Date(d.end): null,
                progress: d.progress,
                isDisabled: false,
                type: d.type,
                project: d.projectCollection,
                projectGroup: d.projectGroup,
                projectCollection: d.projectCollection,
                dependencies: d.dependencies,
                projectType: d.projectType,
                hideChildren: false,
                displayOrder: d.displayOrder,
              })
            )
          );
          setUnassignedTasks(
            data.filter(t => !t.start || !t.end || t.type === 'project').map(d =>
              Object({
                name: d.name,
                id: d.id,
                start: d.start ? new Date(d.start) : null,
                end: d.end ? new Date(d.end): null,
                progress: d.progress,
                isDisabled: false,
                type: d.type,
                project: d.projectCollection,
                projectGroup: d.projectGroup,
                projectCollection: d.projectCollection,
                dependencies: d.dependencies,
                projectType: d.projectType,
                hideChildren: false,
                displayOrder: d.displayOrder,
              })
            )
          );
        })
        .catch((err) => {
          showErrorResultBar('Unexpected error loading task data');
          Sentry.captureException(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [collectionId]);

  const handleUpdateTasks = async (taskInfo: IGanttTask[]) => {

    const updateDto: ITasksBulkUpdateDto =
    {
      taskUpdates: taskInfo.filter(t => t.type === 'task').map(t => Object({
        id: t.id,
        startDate: t.start,
        dueDate: t.end,
      }))
    };

      try {
        setIsSubmitting(true);
        setTaskData(taskData.map(t => {
          const task = taskInfo.find(ti => ti.id === t.id);
          if (task) {
            return {
              ...t,
              start: task.start,
              end: task.end,
            };
          }
          return t;
        }));
        await API.post('task/dates', updateDto);
        showSuccessResultBar('Tasks updated.');

      } catch (err) {
        Sentry.captureException(err);
        showErrorResultBar('Error updating tasks.');
        setIsSubmitting(false);
      }
    };

  return (
    <>
      {isLoading && <div>Loading...</div>}
      {taskData.length > 0 && !isLoading &&
      <Card>
        <Grid className={classes.cardContent}>
          <GanttChart
            taskData={taskData}
            handleUpdateTasks={handleUpdateTasks}
          />
        </Grid>
      </Card>
      }
      {unassignedTasks.length > 0 && !isLoading &&
        <Grid item xs={12} md={12}>
          <UnassignedTaskTable
            auth={auth}
            isLoading={false}
            setTasks={setUnassignedTasks}
            tasks={unassignedTasks}
            setGanttTasks={setTaskData}
            ganttTasks={taskData}
          />
        </Grid>
      }
    </>
  );
}

TheTasksReportPage.requiredAuthZ = {
  tier: 1,
  permission: 'tasks',
};

TheTasksReportPage.routePath = '/tasks/report';
TheTasksReportPage.title = 'Tasks Report';

export default TheTasksReportPage;
