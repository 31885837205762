import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  createStyles,
  Divider,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import Auth from '../services/AuthService';
import { OrgIncomingAccessUserDetails } from './OrgIncomingAccessUserDetails';
import API from '../services/ApiService';
import { showErrorResultBar } from '../components/ResultSnackbar';
import * as Sentry from '@sentry/browser';
import AddOrgShareUserDialog from './dialogs/AddOrgShareUserDialog';
import { ConfirmationDialog } from './dialogs';
import { SecondaryField } from './VendorDetailsInfo';
import { IOrgRBACJoinDto, IRoleDto, IUserRBACDto } from '../../../backend/src/org-share/interfaces';
import { IOrgDto } from '../../../backend/src/org/interfaces';
import { AxiosResponse } from 'axios';
import { handleError } from '../helpers';

const useStyles = makeStyles((theme) =>
  createStyles({
    cardHeader: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    tableContainer: {
      paddingLeft: '1rem',
      paddingRight: '1rem',
      paddingTop: '1rem',
      paddingBottom: '1rem',
    },
    button: {
      paddingLeft: '1rem',
      marginLeft: '1rem',
    },
    tableRow: {
      '&$selected, &$selected:hover': {
        backgroundColor: '#fafafa'
      }
    },
    primaryColumn: {
      color: theme.typography.body1.color,
      fontSize: '14px',
    },
    secondaryColumn: {
      backgroundColor: '#f5f5f5',
      opacity: '0.8',
      fontSize: '0.8125rem',
    },
    referenceResponse: {
      color: 'rgba(0,0,0,0.54)',
      //whiteSpace: 'pre-line',
    },
    selected: {}
  })
);

export interface OrgIncomingAccessDetailsProps {
  orgData: IOrgRBACJoinDto;
  auth: Auth;
  allRoles: IRoleDto[];
  updateUser: (orgRBACId: string, userIdx: number | null, apiResponse: AxiosResponse<any>, successMsg: string) => void;
  onRemoveUser: (orgRBACId: string, userIdx: number, successMsg: string) => void;
  updateInboundOrg: (apiResponse: AxiosResponse<any>, successMsg: string) => void;
  orgAccess: IRoleDto | null;
  handleSwitchOrg: (org: IOrgDto) => (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

interface ITableDatum {
  userName: string;
  userRole: string;
}

export interface IAvailableRoles extends IRoleDto {
  allowed: boolean;
}

export default function OrgIncomingAccessDetails({ orgData, auth, allRoles, updateUser, onRemoveUser, updateInboundOrg, orgAccess, handleSwitchOrg }: OrgIncomingAccessDetailsProps) {
  const classes = useStyles();
  const [ userTableData, setUserTableData ] = useState<ITableDatum[]>([]);
  const [ currUserIdx, setCurrUserIdx ] = useState<number | null>(null);
  const [ currUserData, setCurrUserData ] = useState<IUserRBACDto | null>(null);
  const [ availableRoles, setAvailableRoles ] = useState<IAvailableRoles[]>([]);
  const [ toShowConfirmMarkRevoked, setToShowConfirmMarkRevoked ] = useState(false);
  const [ availableUsers, setAvailableUsers ] = useState<IUserRBACDto[]>([]);
  const [ allUsers, setAllUsers ] = useState<IUserRBACDto[]>([]);
  const [ users, setUsers ] = useState<IUserRBACDto[]>([]);
  const [ toShowAddUserDialog, setToShowAddUserDialog ] = useState(false);

  useEffect(() => {
    setUserTableData(
      users.map(user =>
        Object({
          ...user,
          userName: user.userName || '',
          userRole: user.role?.name || '',
        })
      )
    );
  }, [users]);

  useEffect(() => {
    setUsers(orgData.users);
  }, [orgData]);

  useEffect(() => {
    if (currUserIdx !== null) {
      setCurrUserData(orgData.users[currUserIdx]);
    }
  }, [orgData, currUserIdx]);

  useEffect(() => {
    API
      .get('org-share/users')
      .then(res => {
        setAllUsers(res.data.data);
      })
      .catch(Sentry.captureException);
  }, []);

  useEffect(() => {
    // remove users that already have access to org from the available users to add
    setAvailableUsers(allUsers.filter(au => !users.map(u => u.userId).includes(au.id || '')));
  }, [allUsers, users]);

  useEffect(() => {
    setAvailableRoles(allRoles.map(r => Object({ ...r, allowed: (orgData.roles.map((orgRole) => orgRole.id).includes(r.id) || r.admin || r.defaultRole)})));
  }, [allRoles, orgData]);

  const handleConfirmMarkRevoked = (confirmed: boolean) => {
    if (confirmed) {
      updateStatus('revoked');
    }
    setToShowConfirmMarkRevoked(false);
  };

  const clickUpdateUserRole = (userIdx: number | null) => async (values: any) => {
    // TODO: fix type
    const newRole = values.role;
    if (userIdx !== null) {
      try {
        const user = orgData.users[userIdx];
        const uploadData = {
          orgRBACId: orgData.id,
          roleId: newRole,
          userId: user.userId,
          userName: user.userName,
        };
        const res = await API.post('org-share/user', uploadData);
        updateUser(orgData.id, userIdx, res, 'User role updated');
        const updatedUsers = users.slice();
        updatedUsers[userIdx] = res.data.data;
        setUsers(updatedUsers);

      } catch (err) {
        handleError(err, 'Error updating user permissions');
      }
    } else {
      showErrorResultBar('No user selected');
    }
  };

  const clickRemoveUser = (userIdx: number | null) => async () => {
    if (userIdx !== null) {
      const removeData = {
        orgRBACId: orgData.id,
        userId: orgData.users[userIdx].userId
      };
      try {
        await API.post('org-share/removeUser', removeData);
        onRemoveUser(orgData.id, userIdx, 'User removed');
        setCurrUserIdx(null);
        setCurrUserData(null);

        const updatedUsers = users.slice();
        updatedUsers.splice(userIdx, 1);
        setUsers(updatedUsers);
      } catch (err) {
        handleError(err, 'Error removing user');
      }
    } else {
      showErrorResultBar('No user selected');
    }
  };

  const clickAddUser = (newUser: IUserRBACDto) => {
    const updatedUsers = users.slice();
    updatedUsers.push(newUser);
    setUsers(updatedUsers);
  };

  const updateStatus = async (status: string) => {
    try {
      const uploadData = {
        orgRBACId: orgData.id,
        status: status
      };
      const res = await API.patch(`org-share/${orgData.id}`, uploadData);
      if (status === 'active') {
        updateInboundOrg(res, 'Org access successfully approved');
      } else if (status === 'revoked') {
        updateInboundOrg(res, 'Org access successfully revoked');
      } else if (status === 'denied') {
        updateInboundOrg(res, 'Org access successfully denied');
      } else if (status === 'pending') {
        updateInboundOrg(res, 'Org access successfully requested');
      }
    } catch (err) {
      handleError(err, 'Error updating org access');
    }
  };

  const cancelRequest = async () => {
    // try {
    //   const res = await API.delete(`org-share/${orgData.id}`);
    // } catch (err) {
    //   handleError('Error updating org access')(err);
    // }
    updateStatus('denied');
  };

  const getDetailsByStatus = (status: string, accessType: string) => {
    switch (status) {
      case 'pending':
        return (
          <Card>
          <CardHeader
            className={classes.cardHeader}
            title={orgData.fromOrg.name}
            subheader={`Pending ${orgData.accessType} Access`}
          />
          <CardContent>
            <Grid container alignItems="flex-start" spacing={4}>
              <Grid item xs={12} sm={7} md={8} className={classes.primaryColumn}>
                <Typography variant="body1">
                  You have requested access to {orgData.fromOrg.name}. They have been notified of your request.
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={5}
                md={4}
                className={classes.secondaryColumn}
              >
                <SecondaryField
                  title="Access requested on:"
                  value={orgData.requestedDate.toString() || 'N/A'}
                />
                <SecondaryField
                  title="Access requested by:"
                  value={orgData.requestedByName || 'N/A'}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
            <Grid container>
              <Grid item>
                <CardActions>
                  <Button color="inherit" variant="outlined" size="small" onClick={() => {cancelRequest();}} disabled={!(orgAccess?.admin || false)}>
                    Cancel Request
                  </Button>
                </CardActions>
              </Grid>
            </Grid>
          </Card>
        );
      case 'active':
        return (
          <>
          <Card>
          <CardHeader
            className={classes.cardHeader}
            title={orgData.fromOrg.name}
            subheader={`${orgData.accessType} Access`}
          />
          <CardContent>
            <Grid container alignItems="flex-start" spacing={4}>
              <Grid item xs={12} sm={7} md={8} className={classes.primaryColumn}>
                <Grid container>
                  <Grid item xs={3}>Access description:</Grid>
                    <Grid item xs={9} className={classes.referenceResponse}>
                      As a manager, your organization has access to switch directly into the {orgData.fromOrg.name} account.
                      You have access up to the granted permissions.
                    </Grid>
                    <Grid item xs={3}>Permissions granted:</Grid>
                      <Grid item xs={9} className={classes.referenceResponse}>
                        {orgData.roles.map(role => role.name).join(', ')}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={5}
                    md={4}
                    className={classes.secondaryColumn}
                  >
                    <SecondaryField
                      title="Access granted on:"
                      value={orgData.approvedDate.toString() || 'N/A'}
                    />
                    <SecondaryField
                      title="Access granted by:"
                      value={orgData.approvedByName || 'N/A'}
                    />
                  </Grid>
                  <Grid container className={classes.tableContainer}>
                    <Grid item xs={5}>
                      <Typography variant="h6">
                        Users
                        <Button
                          className={classes.button}
                          color="primary"
                          variant="outlined"
                          onClick={() => setToShowAddUserDialog(true)}
                          disabled={!(orgAccess?.admin || false)}
                        >
                          + Add User
                        </Button>
                      </Typography>
                      <TableContainer>
                        <Table
                          aria-label="simple table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell>User Name</TableCell>
                              <TableCell align="left">Role</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                          {orgData.users.length > 0 ?
                          <>
                            {orgData.users.map((user, idx) => (
                              <TableRow
                                hover
                                key={idx}
                                onClick={() => {
                                  setCurrUserIdx(idx);
                                }}
                                selected={currUserIdx === idx}
                                classes={{ selected: classes.selected }}
                                className={classes.tableRow}
                              >
                                  <TableCell component="th" scope="row">
                                  <Typography variant="body2">
                                      {user.userName}
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="left">
                                    <Typography  variant="body2">
                                      {user.role?.name || 'No assigned role'}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              ))}
                                </>
                              :
                                <></>
                              }
                            </TableBody>
                          </Table>
                        </TableContainer>
                        {orgData.users.length > 0 ?
                          <></>
                        :
                          <Grid container
                            direction="column"
                            alignItems="center"
                            style={{marginTop: '10px' }}
                          >
                            <Typography variant="body2">
                              No Users
                            </Typography>
                          </Grid>
                        }
                    </Grid>
                    <Grid item xs={7}>
                      {orgAccess?.admin ?
                      <>
                        {currUserData ?
                          <OrgIncomingAccessUserDetails
                            auth={auth}
                            userData={currUserData}
                            availableRoles={availableRoles}
                            orgData={orgData}
                            onClickSave={clickUpdateUserRole(currUserIdx)}
                            onRemoveUser={clickRemoveUser(currUserIdx)}
                          />
                        :
                          <Grid container
                            direction="column"
                            alignItems="center"
                            justify="center"
                            style={{ minHeight: '25vh' }}
                          >
                            {orgData.users.length > 0 ?
                            <Typography variant="body1">
                              Select User to update role in shared organization
                            </Typography>
                            :
                            <Typography variant="body1">
                              Add User to update roles in shared organization
                            </Typography>
                            }
                          </Grid>
                        }
                      </>
                      :
                        <>
                           <Grid container
                            direction="column"
                            alignItems="center"
                            justify="center"
                            className={classes.tableContainer}
                            style={{ minHeight: '25vh' }}
                          >
                            <Typography variant="body1">
                              You need to be granted admin access to this organization in order to add or update user permissions.
                              Contact your organization owner or another user that has admin permissions for this organization to update access.
                            </Typography>
                          </Grid>
                        </>
                      }
                    </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <Grid container>
                  <Grid item>
                    <CardActions>
                      <Button color="primary" variant="contained" size="small" onClick={handleSwitchOrg(orgData.fromOrg)} disabled={orgAccess === null}>
                        Switch to Org
                      </Button>
                      <Button color="inherit" size="small" variant="outlined" onClick={() => {setToShowConfirmMarkRevoked(true);}} disabled={!(orgAccess?.admin || false)}>
                        Revoke Access
                      </Button>
                    </CardActions>
                  </Grid>
                </Grid>
                <ConfirmationDialog
                  onResponse={handleConfirmMarkRevoked}
                  open={toShowConfirmMarkRevoked}
                  title="Revoke access?"
                >
                  {`Are you sure you want to revoke ${orgData.toOrg.name}'s access to ${orgData.fromOrg.name}?`}
                </ConfirmationDialog>
              </Card>
              <AddOrgShareUserDialog
                users={availableUsers}
                onClose={() => setToShowAddUserDialog(false)}
                onUpdate={updateUser}
                open={toShowAddUserDialog}
                orgData={orgData}
                roles={availableRoles}
                addUser={clickAddUser}
              />
            </>
          );
        case 'revoked':
            return (
              <>
                <Card>
                <CardHeader
                  className={classes.cardHeader}
                  title={orgData.fromOrg.name}
                  subheader={'Access Revoked'}
                />
                <CardContent>
                  <Grid container alignItems="flex-start" spacing={4}>
                    <Grid item xs={12} sm={7} md={8} className={classes.primaryColumn}>
                      <Typography variant="body1">
                        {orgData.toOrg.name}'s access to {orgData.fromOrg.name} has been revoked. If you need access to this organization's account, you will need to re-request it.
                      </Typography>
                      </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={5}
                        md={4}
                        className={classes.secondaryColumn}
                      >
                        <SecondaryField
                          title="Access revoked on:"
                          value={orgData.approvedDate.toString() || 'N/A'}
                        />
                        <SecondaryField
                          title="Access revoked by:"
                          value={orgData.approvedByName || 'N/A'}
                        />
                      </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                  <Grid container>
                    <Grid item>
                      <CardActions>
                        <Button color="primary" variant="contained" size="small" onClick={() => {updateStatus('pending');}} disabled={!(orgAccess?.admin || false)}>
                          Re-Request Access
                        </Button>
                      </CardActions>
                    </Grid>
                  </Grid>
                </Card>
              </>
            );
            case 'denied':
              return (
                <>
                  <Card>
                  <CardHeader
                    className={classes.cardHeader}
                    title={orgData.fromOrg.name}
                    subheader={'Access Denied'}
                  />
                  <CardContent>
                    <Grid container alignItems="flex-start" spacing={4}>
                      <Grid item xs={12} sm={7} md={8} className={classes.primaryColumn}>
                        <Typography variant="body1">
                          {orgData.toOrg.name}'s access to {orgData.fromOrg.name} has been denied or cancelled. If this is in error and you need access to this organization's account, you will need to re-request it.
                        </Typography>
                        </Grid>
                      <Grid
                          item
                          xs={12}
                          sm={5}
                          md={4}
                          className={classes.secondaryColumn}
                        >
                          <SecondaryField
                            title="Access requested on:"
                            value={orgData.requestedDate.toString() || 'N/A'}
                          />
                          <SecondaryField
                            title="Access requested by:"
                            value={orgData.requestedByName || 'N/A'}
                          />
                        </Grid>
                    </Grid>
                  </CardContent>
                  <Divider />
                    <Grid container>
                      <Grid item>
                        <CardActions>
                          <Button color="primary" variant="contained" size="small" onClick={() => {updateStatus('pending');}} disabled={!(orgAccess?.admin || false)}>
                            Re-Request Access
                          </Button>
                        </CardActions>
                      </Grid>
                    </Grid>
                  </Card>
                </>
              );
      default:
        return (
            <Card>
                <CardHeader
                  className={classes.cardHeader}
                  title={orgData.toOrg.name}
                  subheader={`${orgData.accessType} Access`}
                />
            </Card>
        );
    }
  };

  return (getDetailsByStatus(orgData.status, orgData.accessType));

}
