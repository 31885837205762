import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { difference, intersection, sortBy, union } from 'lodash';
import moment from 'moment';
import React from 'react';
import { UserAuditMemberStatus, UserAuditStatus } from '../../../../backend/src/user-audit/enums';
import { IProviderOrg } from '../../../../backend/src/user-audit/interfaces';
import * as helpers from '../../helpers';
import { SortDirection } from '../SpioDataTable';

const COLORS = {
  added: '#c9f0d4',
  addedHover: '#9ed99e',
  deleted: '#ffdcdf',
  deletedHover: '#f2bbc0',
  modified: '#d1ac53',
};

export const useUserAuditTableStyles = makeStyles({
  tableCard: {
    marginRight: 0,
    marginLeft: 0,
  },
  tableCardHeaderAction: {
    marginTop: 0,
  },
  dataTable: {
    '& td,th': {
      paddingRight: 0,
      paddingLeft: '12px',
    },
    'overflowX': 'auto',
  },
  deletedUserRow: {
    'backgroundColor': COLORS.deleted,
    '&:hover': {
      backgroundColor: `${COLORS.deletedHover} !important`,
    },
  },
  newUserRow: {
    'backgroundColor': COLORS.added,
    '&:hover': {
      backgroundColor: `${COLORS.addedHover} !important`,
    },
  },
  added: {
    'backgroundColor': COLORS.added,
    '&::before': {
      paddingRight: '8px',
      content: '"+"',
      fontFamily: 'monospace',
    },
  },
  deleted: {
    'backgroundColor': COLORS.deleted,
    '&::before': {
      paddingRight: '8px',
      content: '"-"',
      fontFamily: 'monospace',
    },
  },
  addedColor: {
    backgroundColor: COLORS.added,
  },
  deletedColor: {
    backgroundColor: COLORS.deleted,
  },
  modified: {
    backgroundColor: COLORS.modified,
  },
  namesDiffContainer: {
    paddingLeft: '0.5rem',
    marginBottom: '0.3rem',
  },
  detailedListItem: {
    fontSize: '0.775rem',
  },
});

export const boolToString = (val: boolean | null | undefined, defaultStr?: string) => {
  if (val === undefined) {
    // the value wasn't recorded; return 'undefined' so it's not included in the diff
    return undefined;
  } else if (val === null) {
    return defaultStr ?? null;
  } else {
    return val ? 'True' : 'False';
  }
};

export const BOOLEAN_FILTER_SELECTIONS = [ 'True', 'False' ];

export const getAgeInDays = (aDate?: Date | string | null): number | null => {
  try {
    return aDate ? moment().diff(aDate, 'days') : null;
  } catch {
    return null;
  }
};

export function StatusCell({ status }: { status?: UserAuditMemberStatus }) {
  const statusStr = status ?? '';
  let str = '';

  switch (statusStr) {
    case 'added':
      str = '[+]';
      break;
    case 'deleted':
      str = '[-]';
      break;
    case 'modified':
      str = '[+/-]';
      break;
    case 'unchanged':
      str = '';
      break;
    case 'unsynced':
      str = '';
      break;
    default:
      break;
  }

  return <code>{str}</code>;
}

export const statusDownload = (status?: UserAuditMemberStatus) => {
  return (!status || status === 'unsynced' || status === 'unchanged') ? '' : status.toUpperCase();
};

export const numberNullSortCompare = (order: SortDirection) => {
  // By default the table's sort treats nulls equivalent to zero.
  // Here we force all of the nulls to the bottom of the table, regardless of the sort direction.

  let reverseFactor = (order === 'desc' ? -1 : 1);
  let sortFactor = 1;

  return (a: { data: any }, b: { data: any }) => {
    const valA: number | null = a.data;
    const valB: number | null = b.data;

    if (valA === null && valB === null) {
      reverseFactor = -1;
      sortFactor = 1;
    } else if (valA == null) {
      reverseFactor = 1;
      sortFactor = 1;
    } else if (valB === null) {
      reverseFactor = 1;
      sortFactor = -1;
    } else {
      sortFactor = (valA < valB ? -1 : 1);
    }

    return reverseFactor * sortFactor;
  };
};

export const diffTableCellSortCompare = (order: SortDirection) => {
  const reverseFactor = (order === 'desc' ? -1 : 1);

  return (a: { data: any }, b: { data: any }) => {
    const nameA: string = a.data[0]?.toString().toLowerCase();
    const nameB: string = b.data[0]?.toString().toLowerCase();
    const sortFactor = (nameA < nameB ? -1 : 1);

    return reverseFactor * sortFactor;
  };
};

export function DiffTableCell({ values, isModified }: { values: (string | null | undefined)[]; isModified: boolean }) {
  const classes = useUserAuditTableStyles();
  const currValue = values[0];
  const prevValue = values[1];

  return (isModified && prevValue !== undefined && currValue !== prevValue) ? (
    <div>
      <div className={classes.added}>
        {currValue ?? ''}
      </div>
      <div className={classes.deleted}>
        {prevValue ?? ''}
      </div>
    </div>
  ) : (
    <div>
      {currValue ?? ''}
    </div>
  );
}

export const diffTableCellDownload = (values: (string | null | undefined)[], isModified: boolean ) => {
  const currValue = values[0];
  const prevValue = values[1];

  return (isModified && prevValue !== undefined && currValue !== prevValue)
    ? `ADDED: ${currValue ?? ''}\nDELETED: ${prevValue ?? ''}`
    : currValue ?? '';
};

// 'Names' here is any string array.
export interface INameArrayDetailsProps {
  label: string;
  isModified: boolean;
  currNames: string[];
  prevNames: string[];
  toShowNames: boolean;
  toShowLabel?: boolean;
}

export function NameArrayDetails({ label, isModified, currNames, prevNames, toShowNames, toShowLabel = true }: INameArrayDetailsProps) {
  const classes = useUserAuditTableStyles();

  if (currNames.length === 0 && prevNames.length === 0) {
    return null;
  }

  const addedNames = isModified ? difference(currNames, prevNames) : [];
  const removedNames = isModified ? difference(prevNames, currNames) : [];
  const areNamesModified = isModified && (addedNames.length > 0 || removedNames.length > 0);

  let addedNamesStr = '';
  let removedNamesStr = '';
  let sep = '';

  if (areNamesModified) {
    addedNamesStr = addedNames.length > 0 ? `+${addedNames.length}` : '';
    removedNamesStr = removedNames.length > 0 ? `-${removedNames.length}` : '';
    sep = (addedNamesStr && removedNamesStr) ? '/' : '';
  }

  const allNames = sortBy(union(currNames, prevNames), v => v ? v.toString().toLowerCase() : v);

  return (
    <div>
      {areNamesModified && !toShowNames && (
        <code>
          [
          {addedNamesStr && <span className={classes.addedColor}>{addedNamesStr}</span>}
          {sep}
          {removedNamesStr && <span className={classes.deletedColor}>{removedNamesStr}</span>}
          ]
        </code>
      )}
      {toShowLabel && (
        <span>{label}: {currNames.length}</span>
      )}
      {toShowNames && (
        <div className={classes.namesDiffContainer}>
          {allNames.map((name) => (
            <Typography
              key={name}
              variant="body2"
              className={classNames(
                classes.detailedListItem,
                areNamesModified && addedNames.includes(name) && classes.added,
                areNamesModified && removedNames.includes(name) && classes.deleted,
              )}
            >
              {name}
            </Typography>
          ))}
        </div>
      )}
    </div>
  );
}

export const nameArrayDownload = (isModified: boolean, currNamesRaw: string[] | null | undefined, prevNamesRaw: string[] | null | undefined): string => {
  const currNames = currNamesRaw ?? [];
  const prevNames = prevNamesRaw ?? [];

  if (currNames.length === 0 && prevNames.length === 0) {
    return '';
  }

  const allNames = sortBy(union(currNames, prevNames), v => v ? v.toString().toLowerCase() : v);
  const addedNames = isModified ? difference(currNames, prevNames) : [];
  const removedNames = isModified ? difference(prevNames, currNames) : [];
  const areNamesModified = isModified && (addedNames.length > 0 || removedNames.length > 0);

  let allNamesStrings = allNames;

  if (areNamesModified) {
    allNamesStrings = allNames.map((name) => {
      let prefixStr = '';
      if (addedNames.includes(name)) {
        prefixStr = 'ADDED: ';
      } else if (removedNames.includes(name)) {
        prefixStr = 'DELETED: ';
      }

      return `${prefixStr}${name}`;
    });
  }

  return allNamesStrings.join('\n');
};

export const filterLogicStringArray = (rawItemVals: string | string[], filterVals: any[]) => {
  // The typescript def'n assumes the first arg to 'logic' is a string, which isn't the case here.
  const itemVals = (typeof rawItemVals === 'string') ? [ rawItemVals ] : rawItemVals;

  return intersection(itemVals, filterVals).length === 0;
};

export const filterLogicStringDoubleArray = (rawItemVals: string | string[][], filterVals: any[]) => {
  const itemVals = (typeof rawItemVals === 'string') ? [ rawItemVals ] : rawItemVals.flat();

  return intersection(itemVals, filterVals).length === 0;
};

export interface IKeyedStringArrays {
  [role: string]: string[];
}

export const filterLogicKeyedStringArrays = (rawItemVals: string | IKeyedStringArrays[], filterVals: any[]) => {
  const itemVals = (typeof rawItemVals === 'string') ? [ rawItemVals ] : rawItemVals.flatMap(item => Object.values(item).flat());

  return intersection(itemVals, filterVals).length === 0;
};

export const getOrgAuditStatus = (org: IProviderOrg | undefined): UserAuditStatus => {
  const audit = org && org.syncs[0];
  const statusEnum = audit ? audit.status : 'new';

  return statusEnum;
};

export const userAuditStatusMap = {
  new: 'New',
  pending: 'Needs confirmation',
  confirmed: 'Confirmed',
};

export const getTableSubtitleText = (org: IProviderOrg | undefined) => {
  if (!org) {
    return '';
  }

  const status = getOrgAuditStatus(org);
  const statusStr = userAuditStatusMap[status];
  const latestAudit = org.syncs[0];
  const prevAudit = org.syncs[1];

  switch (status) {
    case 'confirmed':
      return `${statusStr} (data of ${helpers.formatDate(latestAudit?.confirmedAt) ?? ''})`;

    case 'new':
      return 'Never synced';

    case 'pending':
      const syncedAtStr = helpers.formatDate(latestAudit?.syncedAt) ?? '';
      const confirmedAtStr = helpers.formatDate(prevAudit?.confirmedAt) ?? '';

      return `${syncedAtStr} (pending)${confirmedAtStr ? ` vs ${confirmedAtStr} (confirmed)` : ''}`;

    default:
      return '';
  }
};
