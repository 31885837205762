import React, { useState, useEffect } from 'react';
import { RouterLink } from '../components/RouterLink';
import {
  // Accordion,
  // AccordionSummary,
  // AccordionDetails,
  Breadcrumbs,
  Box,
  Button,
  Backdrop,
  FormControl,
  InputLabel,
  Select,
  Tab,
  Tabs,
  Paper,
  Grid,
  CircularProgress,
  MenuItem,
  Typography,
  IconButton,
  withStyles,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import * as Sentry from '@sentry/browser';
import API from '../services/ApiService';
import SpioDataTable from '../components/SpioDataTable';
import {
  showErrorResultBar,
  showSuccessResultBar,
} from '../components/ResultSnackbar';
import { formatDate } from '../helpers';
import { MetricSummaryTableSelectedRowsToolbar } from '../components/MetricSummaryTableSelectedRowsToolbar';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const boolToYesNo = (bool) => {
  if (bool === true || bool === 'true') {
    return 'Yes';
  } else if (bool === false || bool === 'false') {
    return 'No';
  } else {
    return bool;
  }
};

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="textSecondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

const styles = (theme) => ({
  breadcrumb: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  backdrop: {
    // color: theme.palette.primary.main,
    // color: 'white',
    zIndex: theme.zIndex.drawer + 1,
  },
  circularProgress: {
    zIndex: theme.zIndex.drawer + 2,
    position: 'absolute',
    left: '50%',
    top: '50%',
  },
  fields: {
    paddingLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  primary_results: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing(2, 1),
    marginTop: theme.spacing(3),
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  primaryCards: {
    padding: theme.spacing(1, 1),
  },
  formControl: {
    marginTop: '15px',
  },
});

const tableColumns = (tableData) => [
  {
    name: 'onDashboard',
    label: 'Added To Dashboard',
    options: {
      customFilterListOptions: {
        render: (v) => (v === 'True' ? 'Added to Dashboard' : 'Not on Dashboard'),
      },
      customBodyRenderLite: (dataIndex) => {
        return tableData[dataIndex]?.saved ? (
          <DoneIcon color="primary" style={{ marginLeft: '2rem' }} />
        ) : null;
      },
    },
  },
  {
    name: 'metricSummaryName',
    label: 'Metric Summary Name',
    options: {
      filter: false,
      display: true,
    },
  },
  {
    name: 'valueWithUnit',
    label: 'Value',
    options: {
      customBodyRender: (v) => v,
      filter: true,
      display: true,
    },
  },
  {
    name: 'aggregation',
    label: 'Calculation Type',
    options: {
      customBodyRender: (v) => capitalizeFirstLetter(v.replaceAll('_', ' ')),
      display: 'true',
      filter: true,
    },
  },
  {
    name: 'secondaryMetricFieldName',
    label: 'Secondary Field Name',
    options: {
      filter: true,
      display: true,
      customBodyRenderLite: (dataIndex) => {
        let secondaryMetricFieldName =
          tableData[dataIndex]?.secondaryMetricFieldName || [];
        return secondaryMetricFieldName.map((name, idx) => (
          <div key={idx}>{capitalizeFirstLetter(name)}</div>
        ));
      },
    },
  },
  {
    name: 'secondaryMetricFieldValue',
    label: 'Secondary Field Value',
    options: {
      filter: true,
      display: true,
      customBodyRenderLite: (dataIndex) => {
        let secondaryMetricFieldValue =
          tableData[dataIndex]?.secondaryMetricFieldValue || [];
        return secondaryMetricFieldValue.map((name, idx) => (
          <div key={idx}>{capitalizeFirstLetter(boolToYesNo(name))}</div>
        ));
      },
    },
  },
  {
    name: 'calculationDegree',
    label: 'Calculation Degree',
    options: {
      filter: true,
      display: true,
    },
  },
];

export const getMetricSummaryName = (row) => {
  let secondaryName = '';
  if (row.secondaryMetricFields) {
    if (
      row.secondaryMetricFields[0].fieldValue === true ||
      row.secondaryMetricFields[0].fieldValue === 'true'
    ) {
      secondaryName = ' that is ' + row.secondaryMetricFields[0].name;
    } else if (
      row.secondaryMetricFields[0].fieldValue === false ||
      row.secondaryMetricFields[0].fieldValue === 'false'
    ) {
      secondaryName = ' that is not ' + row.secondaryMetricFields[0].name;
    } else {
      secondaryName = ' that is ' + row.secondaryMetricFields[0].fieldValue;
    }

    if (row.secondaryMetricFields.length > 1) {
      if (
        row.secondaryMetricFields[1].fieldValue === true ||
        row.secondaryMetricFields[1].fieldValue === 'true'
      ) {
        secondaryName =
          secondaryName + ' and is ' + row.secondaryMetricFields[1].name;
      } else if (
        row.secondaryMetricFields[1].fieldValue === false ||
        row.secondaryMetricFields[1].fieldValue === 'false'
      ) {
        secondaryName =
          secondaryName + ' and is not ' + row.secondaryMetricFields[1].name;
      } else {
        secondaryName =
          secondaryName + ' and is ' + row.secondaryMetricFields[1].fieldValue;
      }
    }
  }

  if (row.aggregation === 'sum') {
    return capitalizeFirstLetter(
      ('Total ' + row.primaryMetricField.name + secondaryName)
        .replaceAll('_', ' ')
        .toLowerCase()
    );
  } else if (row.aggregation === 'count') {
    return capitalizeFirstLetter(
      ('Number of Entries for ' + row.primaryMetricField.name + secondaryName)
        .replaceAll('_', ' ')
        .toLowerCase()
    );
  } else if (row.aggregation === 'mean') {
    return capitalizeFirstLetter(
      ('Average ' + row.primaryMetricField.name + secondaryName + ' per entry')
        .replaceAll('_', ' ')
        .toLowerCase()
    );
  } else if (row.aggregation === 'percent_sum') {
    return capitalizeFirstLetter(
      ('Percentage of total ' + row.primaryMetricField.name + secondaryName)
        .replaceAll('_', ' ')
        .toLowerCase()
    );
  } else if (row.aggregation === 'percent_count') {
    return capitalizeFirstLetter(
      (
        'Percentage of number of entries ' +
        row.primaryMetricField.name +
        secondaryName
      )
        .replaceAll('_', ' ')
        .toLowerCase()
    );
  } else {
    return null;
  }
};

const TheMetricOverviewPage = ({ match, classes, auth, history }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [metric, setMetric] = useState([]);
  const [metricFields, setMetricFields] = useState([]);
  const [metricSummary, setMetricSummary] = useState([]);
  const [primaryFields, setPrimaryFields] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [selectedTabIdx, setSelectedTabIdx] = useState(0);
  const [startDateTime, setStartDateTime] = React.useState(null);
  const [endDateTime, setEndDateTime] = React.useState(null);
  const [orgProcessId, setOrgProcessId] = React.useState('');
  const [rowsSelected, setRowsSelected] = useState([]);

  const handleEndDateChange = async (date) => {
    setEndDateTime(date);

    const payloadParams = {
      startDatetime: startDateTime ? formatDate(startDateTime) : null,
      endDatetime: formatDate(date),
      orgProcessId: orgProcessId.length > 0 ? orgProcessId : null,
    };

    if (date < startDateTime && startDateTime !== null) {
      showErrorResultBar('End Date must be after Start Date');
      setEndDateTime(null);
    } else {
      const res = await API.get(
        `/org-metric-calculated-value/${match.params.id}/summary`,
        { params: payloadParams }
      );

      try {
        setIsLoading(true);
        setMetricSummary(res.data?.data ?? []);
        setIsLoading(false);
      } catch (err) {
        showErrorResultBar('Unexpected error loading metric fields');
        Sentry.captureException(err);
      }
    }
  };

  function handleEndClr(e) {
    e.stopPropagation();
    handleEndDateChange(null);
  }

  const handleStartDateChange = async (date) => {
    setStartDateTime(date);

    const payloadParams = {
      startDatetime: formatDate(date),
      endDatetime: endDateTime ? formatDate(endDateTime) : null,
      orgProcessId: orgProcessId.length > 0 ? orgProcessId : null,
    };

    if (date > endDateTime && endDateTime !== null) {
      showErrorResultBar('Start Date must be before End Date');
      setStartDateTime(null);
    } else {
      const res = await API.get(
        `/org-metric-calculated-value/${match.params.id}/summary`,
        { params: payloadParams }
      );

      try {
        setIsLoading(true);
        setMetricSummary(res.data?.data ?? []);
        setIsLoading(false);
      } catch (err) {
        showErrorResultBar('Unexpected error loading metric fields');
        Sentry.captureException(err);
      }
    }
  };

  function handleStartClr(e) {
    e.stopPropagation();
    handleStartDateChange(null);
  }

  const handleFacilityChange = async (event) => {
    const facilityID = event.target.value;
    setOrgProcessId(facilityID);

    const payloadParams = {
      startDatetime: startDateTime ? formatDate(startDateTime) : null,
      endDatetime: endDateTime ? formatDate(endDateTime) : null,
      orgProcessId: facilityID.length > 0 ? facilityID : null,
    };

    const res = await API.get(
      `/org-metric-calculated-value/${match.params.id}/summary`,
      { params: payloadParams }
    );

    try {
      setIsLoading(true);
      setMetricSummary(res.data?.data ?? []);
      setIsLoading(false);
    } catch (err) {
      showErrorResultBar('Unexpected error loading metric fields');
      Sentry.captureException(err);
    }
  };

  const addMetricToDashboard = async (index, dashboardList, flag) => {
    const accountingMetricId = metricFields[0].accounting_metric_id;
    let trackRequestPayload = [];
    let payloadBody = {};

    if (flag) {
      if (dashboardList.length < 1) {
        showErrorResultBar(
          'The items have already been added to the Dashboard'
        );
        setRowsSelected([]);
        return;
      }

      dashboardList.forEach((dashboardItem) => {
        const fieldID = dashboardItem.metricSummary.primaryMetricField.id;
        const primaryMetricIndex = metricFields.findIndex(
          (metricField) => fieldID === metricField.metric_field_id
        );
        const primaryMetricFieldId =
          metricFields[primaryMetricIndex].metric_field_id;

        if (dashboardItem.metricSummary.secondaryMetricFieldName) {
          let secondaryMetricFields = [];
          dashboardItem.metricSummary.secondaryMetricFieldName.map(
            (secondaryMetricField, index) => {
              const secondaryMetricIndex = metricFields.findIndex(
                (metricField) =>
                  capitalizeFirstLetter(secondaryMetricField) ===
                  metricField.name
              );
              const secondaryMetricFieldId =
                metricFields[secondaryMetricIndex].metric_field_id;
              secondaryMetricFields.push({
                metricFieldId: secondaryMetricFieldId,
                fieldValue:
                  dashboardItem.metricSummary.secondaryMetricFieldValue[index],
              });
            }
          );
          payloadBody = {
            accountingMetricId: accountingMetricId,
            primaryMetricFieldId: primaryMetricFieldId,
            aggregation: dashboardItem.metricSummary.aggregation,
            secondaryMetricFields: secondaryMetricFields,
          };
        } else {
          payloadBody = {
            accountingMetricId: accountingMetricId,
            primaryMetricFieldId: primaryMetricFieldId,
            aggregation: dashboardItem.metricSummary.aggregation,
            secondaryMetricFields: [],
          };
        }
        trackRequestPayload.push({
          payloadBody: payloadBody,
          idx: dashboardItem.idx,
        });
      });
      try {
        const trackRequests = trackRequestPayload.map((req) => {
          let tempData = tableData;
          tempData[req.idx].onDashboard = tempData[req.idx].saved ? 'False' : 'True';
          tempData[req.idx].saved = !tempData[req.idx].saved;
          setTableData(tempData);
          return API.post('/org-metric-summary', req.payloadBody);
        });
        setIsLoading(true);
        await Promise.all(trackRequests);

        setIsLoading(false);
        showSuccessResultBar('Added to Dashboard');
        setRowsSelected([]);
      } catch (err) {
        const errMessage =
          err?.response?.data?.error ?? 'Error adding metrics to Dashboard';
        showErrorResultBar(errMessage);
        Sentry.captureException(err);
      }
    } else {
      if (dashboardList.length < 1) {
        showErrorResultBar('The items were not tracked on the Dashboard');
        setRowsSelected([]);
        return;
      }

      dashboardList.forEach((dashboardItem) => {
        const fieldName = capitalizeFirstLetter(
          dashboardItem.metricSummary.primaryMetricField.name
        ).replaceAll('_', ' ');
        const primaryMetricIndex = metricFields.findIndex(
          (metricField) => fieldName === metricField.name
        );
        const primaryMetricFieldId =
          metricFields[primaryMetricIndex].metric_field_id;
        if (dashboardItem.metricSummary.secondaryMetricFieldName) {
          let secondaryMetricFields = [];
          dashboardItem.metricSummary.secondaryMetricFieldName.map(
            (secondaryMetricField, index) => {
              const secondaryMetricIndex = metricFields.findIndex(
                (metricField) =>
                  capitalizeFirstLetter(secondaryMetricField) ===
                  metricField.name
              );
              const secondaryMetricFieldId =
                metricFields[secondaryMetricIndex].metric_field_id;
              secondaryMetricFields.push({
                metricFieldId: secondaryMetricFieldId,
                fieldValue:
                  dashboardItem.metricSummary.secondaryMetricFieldValue[index],
              });
            }
          );
          payloadBody = {
            accountingMetricId: accountingMetricId,
            primaryMetricFieldId: primaryMetricFieldId,
            aggregation: dashboardItem.metricSummary.aggregation,
            secondaryMetricFields: secondaryMetricFields,
          };
        } else {
          payloadBody = {
            accountingMetricId: accountingMetricId,
            primaryMetricFieldId: primaryMetricFieldId,
            aggregation: dashboardItem.metricSummary.aggregation,
            secondaryMetricFields: [],
          };
        }
        trackRequestPayload.push({
          payloadBody: payloadBody,
          idx: dashboardItem.idx,
        });
      });

      try {
        setIsLoading(true);
        const trackRequests = trackRequestPayload.map((req) => {
          let tempData = tableData;
          tempData[req.idx].onDashboard = tempData[req.idx].saved ? 'False' : 'True';
          tempData[req.idx].saved = !tempData[req.idx].saved;
          setTableData(tempData);
          return API.delete('/org-metric-summary', { data: req.payloadBody });
        });
        await Promise.all(trackRequests);
        setIsLoading(false);
        showSuccessResultBar('Removed from the Dashboard');
        setRowsSelected([]);
      } catch (err) {
        const errMessage =
          err?.response?.data?.error ?? 'Error adding metrics to Dashboard';
        showErrorResultBar(errMessage);
        Sentry.captureException(err);
      }
    }
  };

  useEffect(() => {
    async function fetchMetricDetails() {
      const res = await API.get('org-metric/allMetrics');
      try {
        setMetric(
          res.data.data.find((metric) => metric.id === match.params.id)
        );
      } catch (err) {
        showErrorResultBar('Unexpected error loading metric');
        Sentry.captureException(err);
      }
    }

    async function fetchMetricFields() {
      const res = await API.get(`org-metric/${match.params.id}/metricFields`);
      try {
        setMetricFields(res.data?.data ?? []);
      } catch (err) {
        showErrorResultBar('Unexpected error loading metric fields');
        Sentry.captureException(err);
      }
    }

    async function fetchMetricFacilities() {
      const res = await API.get('org-process');
      try {
        if (res.data.data) {
          setFacilities(
            res.data.data.map((facility) => ({
              name: facility.name,
              id: facility.id,
            }))
          );
        }
      } catch (err) {
        showErrorResultBar('Unexpected error loading facilities');
      }
    }

    async function fetchMetricSummary() {
      const res = await API.get(
        `/org-metric-calculated-value/${match.params.id}/summary`
      );
      try {
        setMetricSummary(res.data?.data ?? []);
      } catch (err) {
        showErrorResultBar('Unexpected error loading metric fields');
        Sentry.captureException(err);
      }
    }

    setIsLoading(true);
    fetchMetricDetails();
    fetchMetricFields();
    fetchMetricSummary();
    fetchMetricFacilities();
    setIsLoading(false);

  }, [match.params.id]);

  useEffect(() => {
    if (metricSummary.length > 0) {
      setPrimaryFields(
        metricSummary
          .map((row) => row.primaryMetricField.name)
          .filter((x, i, a) => a.indexOf(x) === i)
      );
    }
  }, [metricSummary]);

  useEffect(() => {
    if (metricSummary.length > 0) {
      let filteredData = metricSummary
        .filter(
          (row) => row.primaryMetricField.name === primaryFields[selectedTabIdx]
        )
        .filter((row) => row.aggregation !== 'std');
      setTableData(
        //  TODO: move this to function
        filteredData.map((d, idx) =>
          Object({
            ...d,
            onDashboard: d.saved ? 'True' : 'False',
            valueWithUnit:
              d.aggregation === 'percent_sum' ||
              d.aggregation === 'percent_count'
                ? Math.round(d.value) + '%'
                : d.unit
                ? (d.unit.abbreviation === 'unitless' ? d.value : d.value + ' ' + d.unit.abbreviation)
                : d.value,
            secondaryMetricFieldName: d.secondaryMetricFields
              ? d.secondaryMetricFields.map((m) => m.name.replaceAll('_', ' '))
              : null,
            secondaryMetricFieldValue: d.secondaryMetricFields
              ? d.secondaryMetricFields.map((m) => m.fieldValue)
              : null,
            metricSummaryName: getMetricSummaryName(d),
            calculationDegree: d.secondaryMetricFields
              ? d.secondaryMetricFields.length
              : 0,
          })
        )
      );
    } else {
      setTableData([]);
    }
  }, [selectedTabIdx, metricSummary, primaryFields]);

  if (isLoading) {
    return (
      <>
        <Backdrop className={classes.backdrop} open={isLoading} timeout={500} />
        <CircularProgressWithLabel
          className={classes.circularProgress}
          color="primary"
          size={60}
        />
      </>
    );
  }

  return (
    <>
    <Grid container justify="space-between" style={{ marginTop: '2rem' }}>
      <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
        <RouterLink color="inherit" to="/metricTracking">
          Tracked Metrics
        </RouterLink>
        {metric.id && (
          <RouterLink
            color="inherit"
            to={`/metricTracking/metric/${metric.id}`}
          >
            {metric.description}
          </RouterLink>
        )}
        <Typography color="textPrimary">Summary</Typography>
      </Breadcrumbs>

        <Grid item xs={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              history.push({
                pathname: '/metricDashboard',
              })
            }
          >
            Go To Dashboard
          </Button>
        </Grid>
      </Grid>
      <Tabs
        value={selectedTabIdx}
        onChange={(e, tabIdx) => setSelectedTabIdx(tabIdx)}
        variant="scrollable" // Enable scrollable variant
        scrollButtons="auto" // Show scroll buttons when needed
      >
        {primaryFields.map((primary, index) => {
          return <Tab key={index} label={primary.replaceAll('_', ' ')} />;
        })}
      </Tabs>
      <Paper>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          style={{ padding: '0 1rem' }}
        >
          <Grid item xs={3}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              margin="normal"
              id="startDate"
              label="Start Date"
              value={startDateTime}
              onChange={(e) => handleStartDateChange(e)}
              InputProps={
                startDateTime
                  ? {
                      startAdornment: (
                        <IconButton onClick={(e) => handleStartClr(e)}>
                          <ClearIcon />
                        </IconButton>
                      ),
                    }
                  : {}
              }
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              // format="MM/dd/yyyy"
              margin="normal"
              id="endDate"
              label="End Date"
              value={endDateTime}
              onChange={(e) => handleEndDateChange(e)}
              InputProps={
                endDateTime
                  ? {
                      startAdornment: (
                        <IconButton onClick={(e) => handleEndClr(e)}>
                          <ClearIcon />
                        </IconButton>
                      ),
                    }
                  : {}
              }
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <FormControl className={classes.formControl}>
              <InputLabel
                shrink
                id="demo-simple-select-placeholder-label-label"
              >
                Facility
              </InputLabel>
              <Select
                labelId="Location Filter"
                id="location-filter"
                displayEmpty
                value={orgProcessId}
                onChange={(e) => {
                  handleFacilityChange(e);
                }}
              >
                <MenuItem value={''}>All</MenuItem>
                {facilities.map((facility) => (
                  <MenuItem value={facility.id} key={facility.id}>
                    {facility.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Paper>
      <Grid container style={{ marginTop: '2rem', display: 'block' }}>
        <SpioDataTable
          title={metric.description}
          data={tableData}
          columns={tableColumns(tableData)}
          options={{
            download: true,
            expandableRows: false,
            filterType: 'multiselect',
            rowsSelected,
            // onRowClick: (_, { dataIndex: idx }) =>
            //   onOpenPolicyDoc(metricTableData[idx]),
            print: false,
            rowsPerPage: 25,
            textLabels: {
              body: {
                noMatch: 'Loading...',
                toolTip: 'Sort',
              },
            },
            selectableRows: 'multiple',
            selectableRowsHeader: !isLoading,
            onRowSelectionChange: (_, allRowsSelected) => {
              setRowsSelected(allRowsSelected.map((row) => row.dataIndex));
            },
            customToolbarSelect: (selectedRows) => (
              <MetricSummaryTableSelectedRowsToolbar
                onCancel={() => setRowsSelected([])}
                onUpdate={addMetricToDashboard}
                selectedRows={selectedRows}
                metricSummary={tableData}
                auth={auth}
              />
            ),
          }}
        />
      </Grid>
    </>
  );
};

TheMetricOverviewPage.title = 'Metric Summary';
TheMetricOverviewPage.requiredAuthZ = {
  tier: 3,
  permission: 'esg_metrics:summary',
};

export default withStyles(styles, { withTheme: true })(TheMetricOverviewPage);
