import { createStyles, StyledComponentProps } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import BuildingBlocksImage from '../components/BuildingBlocksImage';
import Page from '../components/Page';
import PageActions from '../components/PageActions';
import { showErrorResultBar, showSuccessResultBar } from '../components/ResultSnackbar';
import API from '../services/ApiService';

const styles = createStyles({
  primaryText: {
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '50rem',
    paddingLeft: '1em',
    paddingRight: '1em',
  },
});

interface TheBetaPageRouteParams {
  feature: string | undefined;
}

export interface TheBetaPageProps extends RouteComponentProps<TheBetaPageRouteParams>, StyledComponentProps {
}

function TheBetaPage({ classes, match }: TheBetaPageProps) {
  const feature = match.params.feature;

  async function handleInviteRequest() {
    try {
      await API.post('org/requestBetaInvite', {
        feature,
        },
      );

      showSuccessResultBar('Your request has been logged. We\'ll be in touch soon!');
    } catch (err) {
      showErrorResultBar('Something went wrong processing your request. Try again later.');
      throw err;
    }
  }

  return (
    <Page center>
      <BuildingBlocksImage />
      <Typography variant="h6">
        {feature ? feature : 'Feature'} is in Beta
      </Typography>
      <Typography className={classes!.primaryText} variant="body1">
        This feature is beta status and still being developed. Access to use it is currently by invitation only.
      </Typography>
      <Typography className={classes!.primaryText} variant="body1">
        If you'd like to be considered for invitation, click the button below.
      </Typography>
      <PageActions center>
        <Button variant="contained" color="primary" onClick={handleInviteRequest}>
          Request Invite
        </Button>
      </PageActions>
    </Page>
  );
}

export default withStyles(styles, { name: 'TheBetaPage', withTheme: false })(TheBetaPage);
