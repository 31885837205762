import {
  Card,
  CardContent,
  Divider,
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { blueGrey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/styles';
// import classNames from 'classnames';
import { uniq } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Doughnut as DoughnutChart } from 'react-chartjs-2';
import { IDocumentStatusCount, IDocumentStatusCountByTag } from '../../../../backend/src/document/interfaces';
import Auth from '../../services/AuthService';
import WidgetTitle from '../WidgetTitle';
import { ChartOptions } from 'chart.js';
import { geminiPurple } from '../../theme';

const OVERALL_CATEGORY = 'All Documents';

const useStyles = makeStyles({
  selectDivider: {
    padding: '10px',
  },
  reportLink: {
    'color': 'inherit',
    'cursor': 'pointer',
    'textDecoration': 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  disabledReportLink: {
    color: 'gray',
  },
  menuItemSubText: {
    color: 'gray',
    fontStyle: 'italic',
    marginLeft: 'auto',
    marginRight: '1rem',
  },
  hidden: {
    display: 'none',
  },
  selectionClickableDiv: {
    '& .hideWhenSelected': {
      display: 'none',
    },
  },
});

const doughnutChartOptions: ChartOptions = {
  legend: {
    position: 'right',
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem: any, data: any) {
        const dataset = data.datasets[tooltipItem.datasetIndex];
        const currentValue = parseFloat(dataset.data[tooltipItem.index]);
        let total = 0;
        for (let i = 0; i < dataset.data.length; i++) {
          total += parseFloat(dataset.data[i]);
        }
        const percentage = (currentValue / total * 100).toFixed(1);
        return `${currentValue} (${percentage}%)`;
      },
    }
  }
};

interface IndexSignature {
  [key: string]: any;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 8.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const NoDataFound = () => {
  return (
    <Card
      elevation={0}
    >
      <CardContent>
        <Typography
          variant="h5"
          component="p"
        >
          No documents found
        </Typography>
      </CardContent>
    </Card>
  );
};

interface TagSelectProps {
  currTag: string;
  handleUpdateTag: (event: React.ChangeEvent<{ value: unknown }>) => void;
  otherTag: string[];
}

const TagSelect = ({ currTag, handleUpdateTag, otherTag }: TagSelectProps) => {
  const classes = useStyles();

  return (
    <FormControl>
      <InputLabel
        htmlFor="select-document-tags"
      >
        Tag
      </InputLabel>
      <Select
        name="document-tag"
        className={classes.selectionClickableDiv}
        value={currTag}
        input={<Input
          id="select-document-tags"
        />}
        MenuProps={MenuProps}
        onChange={handleUpdateTag}
      >
        <MenuItem
          value={OVERALL_CATEGORY}
          divider
        >
          {OVERALL_CATEGORY}
        </MenuItem>
        <Divider />
        <li>
          <Typography
            className={classes.selectDivider}
            color="textSecondary"
            variant="caption"
          >
            Tags
          </Typography>
        </li>
        {otherTag.map(tag => (
          <MenuItem
            key={tag}
            value={tag}
          >
            {tag}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export interface ChartDocumentsByCategoryProps {
  auth: Auth;
  isLoading: boolean;
  docTagsSummary: IDocumentStatusCountByTag[];
  docOverallSummary: IDocumentStatusCount[];
}

function ChartDocumentsByCategory({
  auth,
  isLoading,
  docTagsSummary,
  docOverallSummary,
}: ChartDocumentsByCategoryProps) {
  const [otherTags, setOtherTags] = useState<string[]>([]);
  const [currTag, setCurrTag] = useState<string>(OVERALL_CATEGORY);
  const [currDocumentSummary, setCurrDocumentSummary] = useState<IDocumentStatusCount[]>(docOverallSummary);
  const [chartData, setChartData] = useState<any>({});

  const classes = useStyles();

  // Set the sorted, unique list of tag categories not included in the TOP_CATEGORIES:
  useEffect(() => {
    const otherDocTags = uniq(docTagsSummary.map(t => t.tag_name)).sort();
    setOtherTags(otherDocTags);
  }, [docTagsSummary]);

  useEffect(() => {
    auth.setDashboardTasksFilter(currTag);
  }, [auth, currTag]);

  useEffect(() => {
    if (currTag === OVERALL_CATEGORY) {
      setCurrDocumentSummary(docOverallSummary);
    } else {
      setCurrDocumentSummary(docTagsSummary.filter(summary => summary.tag_name === currTag));
    }
  }, [currTag, docTagsSummary, docOverallSummary]);

  useEffect(() => {
    setChartData({
      labels: currDocumentSummary.map(t => statusDisplayMapping[t.status].title),
      datasets: [{
        data: currDocumentSummary.map(t => t.count),
        backgroundColor: currDocumentSummary.map(t => statusDisplayMapping[t.status].backgroundColor),
        hoverBackgroundColor: currDocumentSummary.map(t => statusDisplayMapping[t.status].hoverBackgroundColor),
      }],
    });
  }, [currDocumentSummary]);

  const statusDisplayMapping: IndexSignature = {
    active: {
      title: 'Not Started',
      backgroundColor: blueGrey[100],
      hoverBackgroundColor: blueGrey[300],
    },
    in_progress: {
      title: 'Started',
      backgroundColor: geminiPurple[200],
      hoverBackgroundColor: geminiPurple[200],
    },
    review: {
      title: 'Needs Review',
      backgroundColor: geminiPurple[500],
      hoverBackgroundColor: geminiPurple[500],
    },
    completed: {
      title: 'Completed',
      backgroundColor: geminiPurple[700],
      hoverBackgroundColor: geminiPurple[700],
    },
  };

  const handleUpdateTag = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCurrTag(event.target.value as string);
  };

  return (
    <Card>
      <WidgetTitle to="/document">Evidence Review</WidgetTitle>
      {currDocumentSummary.length === 0 && !isLoading ? (
        <NoDataFound />
      ) : (
        <DoughnutChart data={chartData} options={doughnutChartOptions} />
      )}
      <Grid container>
        <Grid item xs={12}>
          <TagSelect
            currTag={currTag}
            handleUpdateTag={handleUpdateTag}
            otherTag={otherTags}
          />
        </Grid>
      </Grid>
    </Card>
  );
}

ChartDocumentsByCategory.requiredAuthZ = {
  tier: 1,
  permission: 'documents',
};

export default ChartDocumentsByCategory;
