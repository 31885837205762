import React, { useEffect, useState } from 'react';
import { TableCell, TableRow, withStyles, ListItem as MuiListItem } from '@material-ui/core';
import { LimitReachedDialog } from '../components/dialogs';
import { FileUploadButtonDialog } from '../components/dialogs';
import SpioDataTable from '../components/SpioDataTable';
import { formatDate, formatFileTitle, formatFileSize, truncateString } from '../helpers';
import API from '../services/ApiService';
import DocumentDetails from '../components/DocumentDetails';
import { DownloadButton } from '../components/buttons';
import * as DocService from '../services/DocService';
// import NewReleasesIcon from '@material-ui/icons/NewReleases';
import { showErrorResultBar } from '../components/ResultSnackbar';
import * as Sentry from '@sentry/browser';

export const ListItem = withStyles({
  root: {
    '@media (hover: none)': {
      '&:hover': {
        backgroundColor: '#fafafa',
      },
    },
    '& .MuiListItemText-primary': {
      color: '#adadad',
    },
    '& .MuiListItemText-secondary': {
      color: '#bfbfbf',
    },
    '& .MuiListItemIcon-root': {
      color: '#bfbfbf',
    },
  },
})(MuiListItem);

export const UNASSIGNED_DATA_STR = '(blank)';

export const DOCUMENT_CATEGORY_MAP = {
  'report': 'Report',
  'evidence/task': 'Task',
  'evidence/stakeholder': 'Stakeholder',
  'questionnaire': 'Questionnaire',
  'custom_policy': 'Policy'
};

const getTableColumns = (tableData) => [
  {
    name: 'id',
    label: 'ID',
    options: {
      display: 'false',
      filter: false,
    },
  },
  {
    name: 'name',
    label: 'Document Name',
    options: {
      customBodyRenderLite: dataIndex => truncateString(formatFileTitle(tableData[dataIndex]?.name || '')),
      filter: false
    },
  },
  {
    name: 'createdByName',
    label: 'Created By',
    options: {
      customFilterListOptions: { render: (v) => `Created By: ${v}` },
      display: false,
      filter: true
    },
  },
  {
    name: 'updatedByName',
    label: 'Updated By',
    options: {
      customFilterListOptions: { render: (v) => `Updated By: ${v}` },
      display: false,
      filter: true
    },
  },
  {
    name: 'createdAt',
    label: 'Date Created',
    options: {
      customBodyRenderLite: dataIndex => formatDate(tableData[dataIndex]?.createdAt || ''),
      customFilterListOptions: { render: (v) => `Date Created: ${v}` },
      filter: false,
    },
    display: true
  },
  {
    name: 'updatedAt',
    label: 'Last Updated',
    options: {
      customBodyRenderLite: dataIndex => formatDate(tableData[dataIndex]?.updatedAt || ''),
      customFilterListOptions: { render: (v) => `Last Updated: ${v}` },
      filter: false,
      display: true
    },
    // display: 'false',
  },
  // {
  //   name: 'mappedCategory',
  //   label: 'Category',
  //   options: {
  //     //hint: 'Tool were document was uploaded' ,
  //     customFilterListOptions: {
  //     },
  //     display: true,
  //     filter: true
  //   },
  // },
  {
    name: 'tagNames',
    label: 'Tags',
    options: {
      download: true,
      display: 'true',
      customBodyRenderLite: dataIndex => {
        let tagNames = tableData[dataIndex]?.tagNames || [];
        if (tagNames.length > 2) {
          tagNames = tagNames.slice(0, 2);
          tagNames.push('...');
        }
        tagNames = tagNames.map(t => t.length < 21 ? t : `${t.substring(0, 20)}\u2026`);
        return tagNames.map(tagName => <div key={tagName}>{tagName}</div>);
      },
      filter: true,
      searchable: true,
      setCellProps: () => Object({ nowrap: 'true' }),
      sort: false,
    },
  },
  {
    // The underlying column value should be a string or a number; here we (arbitrarily) use the id.
    name: 'id',
    label: 'Actions',
    options: {
      filter: false,
      searchable: false,
      sort: false,
      setCellProps: () => ({ nowrap: 'true' }),
      customBodyRenderLite: dataIndex => tableData[dataIndex]?.actions,
    },
  },
];

function TheDocumentsPage({ auth, match }) {
  const userDoc = match.params.doc;
  const [ deliverables, setDeliverables ] = useState([]);
  const [ isLimitDialogOpen, setIsLimitDialogOpen ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ tableData, setTableData ] = useState([]);
  const [ allDocTags, setAllDocTags ] = useState([]);
  const [rowsSelected, setRowsSelected] = useState([]);

  // const handleOpenUpgrade = () => {
  //   setIsLimitDialogOpen(true);
  // };

  const handleCloseUpgrade = () => {
    setIsLimitDialogOpen(false);
  };

  const handleArchive = (selectedIdx) => () => {
    const updatedData = deliverables.slice();
    updatedData.splice(selectedIdx, 1);
    setDeliverables(updatedData);
  };

  useEffect(() => {
    setIsLoading(true);
    // check if a specific document id is provided before fetching documents so we don't have to load unnecessary documents
    if (userDoc) {
      API.get(`document/${userDoc}`)
      .then((res) => {
        const updatedDocs = [res.data.data];
        setDeliverables(updatedDocs);
      })
      .catch((err) => {
        showErrorResultBar('No document found');
        Sentry.captureException(err);
      })
      .finally(() => setIsLoading(false));
    } else {
      API.get('document/deliverables')
      .then((res) => {
        const updatedDocs = res.data;
        setDeliverables(updatedDocs);
      })
      .finally(() => setIsLoading(false));
    }
  }, []);

  useEffect(() => {
    API.get('document-tag')
      .then((res) => {
        setAllDocTags(res.data ? res.data.data : []);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const handleClickDownloadDocument = (id) => (e) => {
    DocService.documentManagementDownload(id);
    e.stopPropagation();
  };

  const updateTable = (docs) => {
    setTableData(docs.map((d, idx) => Object({
      ...d,
      tagNames: d.documentTags ? d.documentTags.map(tag => tag.name).sort() : [],
      actions: <DownloadButton key={`download_${idx}`} onClick={handleClickDownloadDocument(d.id)} disabled={!auth.isGranted({permission:'documents:download'})} />,
      mappedCategory: DOCUMENT_CATEGORY_MAP[d.category],
      historyId: d.history.filter(docHist => docHist.version === 'latest').length > 0 ? d.history.filter(docHist => docHist.version === 'latest')[0].id : null,
      latestVersion: d.history.filter(docHist => docHist.version === 'latest').length > 0 ? d.history.filter(docHist => docHist.version === 'latest')[0] : null,
    })));
  };

  useEffect(() => {
    updateTable(deliverables);
  }, [ deliverables ]);

  const getStorageSize = () => {
    return deliverables.map((r) => r.size? parseFloat(r.size) : 0).reduce((a, b) => a + b, 0);
  };

  const handleUpdate = (selectedIdx) => (doc) => {
    const updatedData = deliverables.slice();
    updatedData[selectedIdx] = doc;
    updateTable(deliverables);
    setDeliverables(updatedData);

    // get updated available tags in case one was removed or added
    API.get('document-tag')
    .then((res) => {
      setAllDocTags(res.data ? res.data.data : []);
    });
  };

  function onAddDocuments (newDocs) {
    let dataCopy = deliverables.slice();
    if (newDocs[0].orgId === auth.getOrgId()) {
      dataCopy = newDocs.concat(dataCopy);
    }
    updateTable(dataCopy);
    setDeliverables(dataCopy);
};

  // const handleUpdateDocs = async (idxs, doc) => {
  //   const dataCopy = deliverables.slice();
  //   idxs.forEach((idx) => {
  //     dataCopy[idx] = {
  //       ...dataCopy[idx],
  //       updatedByName: doc.updatedByName,
  //       updatedById: doc.updatedById,
  //     };
  //   });
  //   setDeliverables(dataCopy);
  //   updateTable(dataCopy);
  //   setRowsSelected([]);
  // };

  return (
    <>
      <LimitReachedDialog
        open={isLimitDialogOpen}
        onClose={handleCloseUpgrade}
        text={
          'Your subscription plan does not include access to this document. You will need to upgrade your plan in order to download it.'
        }
      ></LimitReachedDialog>
      <SpioDataTable
        title={
          auth.isGranted({ permission: 'documents:upload' }) ? (
            <FileUploadButtonDialog
              buttonText="Upload New Document(s)"
              onAddDocuments={onAddDocuments}
              documentCategory={'deliverable'}
              showIcon
              showCategorySelection={false}
              multipleFiles={true}
              buttonProps= {userDoc ? { disabled: true } : null}
              currentDocuments = {deliverables.map(r => Object({name: r.name, size: r.size}))}
            />
          ) : (
            'Deliverables'
          )
        }
        columns={getTableColumns(tableData)}
        data={tableData}
        options={{
          print: false,
          filterType: 'multiselect',
          selectableRows: 'none',
          textLabels: {
            body: {
              noMatch: isLoading ? 'Loading...' : 'No records found',
              toolTip: 'Sort',
            },
          },
          expandableRows: true,
          expandableRowsOnClick: true,
          renderExpandableRow: (rowData, rowMeta) => {
            const colSpan = rowData.length + 1;
            const myData = tableData[rowMeta.dataIndex];
            return myData ? (
              <TableRow>
                <TableCell colSpan={colSpan}>
                  <DocumentDetails
                    auth={auth}
                    documentData={myData}
                    onUpdate={handleUpdate(rowMeta.dataIndex)}
                    allDocTags={allDocTags.map(t => t.name)}
                    onArchive={handleArchive(rowMeta.dataIndex)}
                    allDocNames={deliverables.map(doc => doc.name)}
                  />
                </TableCell>
              </TableRow>
            ) : null;
          },
        }}
      />
      {formatFileSize(getStorageSize())} Used
    </>
  );
}

TheDocumentsPage.title = 'Deliverables';
TheDocumentsPage.requiredAuthZ = {
  tier: 1,
  permission: 'documents',
};

export default TheDocumentsPage;
