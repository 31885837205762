import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormGroup,
  FormControlLabel,
  Typography,
  Switch,
} from '@material-ui/core';
import { showErrorResultBar } from '../ResultSnackbar';
import React, { useEffect, useState } from 'react';
import API from '../../services/ApiService';

function MetricDialog({ metric, fields, open, onClose }) {
  // COPY OF FIELDS TO MANIPULATE ON THE DIALOG
  const [localFields, setLocalFields] = useState([]);
  // LOCAL STATE TRACKING THE CHECKED STATUS OF
  // EACH FIELD
  const [checkedState, setCheckedState] = React.useState({});

  useEffect(() => {
    setLocalFields(fields);

    // POPULATE LOCAL STATE
    // FOR UI UPDATES
    let tempCheckedState = {};
    fields.forEach((field) => {
      tempCheckedState[field.name] = field.tracked;
    });
    setCheckedState(tempCheckedState);
  }, [fields]);

  function handleClose() {
    onClose(localFields);
  }

  const handleChange = async (field, index) => {
    // UPDATE LOCAL STATE FOR ALL FIELDS
    // FILTER ONLY THE TRACKED FIELDS
    // FOR THE PAYLOAD
    let tempLocalFields = localFields;
    tempLocalFields[index].tracked = !field.tracked;
    setLocalFields(tempLocalFields);
    const trackedFields = tempLocalFields
      .filter((field) => field.tracked)
      .map((field) => {
        return {
          id: field.metric_field_id,
          name: field.name,
        };
      });

    try {
      await API.patch(`org-metric/${metric.id}`, {
        fields: trackedFields,
      });
    } catch (err) {
      showErrorResultBar('Unexpected error adding the fields');
    }

    // UPDATED LOCAL FOR UI CHANGES
    setCheckedState({
      ...checkedState,
      [field.name]: tempLocalFields[index].tracked,
    });
  };

  return (
    <Dialog
      fullWidth
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
      <Typography variant="h5" color="primary">
        {metric.description}
        </Typography>
      </DialogTitle>
      <Typography variant="subtitle1" color="textSecondary" style={{paddingLeft: '1.3rem', marginBottom: '1.5rem'}}>Fields Available for Tracking:</Typography>
      <DialogContent dividers>
        <FormControl component="fieldset">
          <FormGroup>
            {fields &&
              fields.map((field, index) => {
                return (
                  <FormControlLabel
                    key={index}
                    control={
                      <Switch
                      color="primary"
                        checked={checkedState[field.name]}
                        onChange={() => handleChange(field, index)}
                        name={field.name}
                      />
                    }
                    label={field.name}
                  />
                );
              })}
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

MetricDialog.title = 'Metric Details';

export default MetricDialog;
