import {
  Card,
  createTheme,
  createStyles,
  DialogContent,
  MuiThemeProvider,
  Tab,
  TabProps,
  Tabs,
  Theme,
  withStyles,
} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import { makeStyles } from '@material-ui/styles';
import * as Sentry from '@sentry/browser';
import * as PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { UNASSIGNED_TASK_STR } from '../TaskTable';
import API from '../../services/ApiService';
import Auth from '../../services/AuthService';
import { ResponsiveDialog } from '../dialogs';
import { FILTER_VIEW_SELECTIONS, OPEN_TASKS_FILTERS, FILTER_VIEW_IDENTIFIERS } from '../FilterViewSelections';
import { showErrorResultBar, showSuccessResultBar } from '../ResultSnackbar';
import SpioDataTable, { SpioDataTableColumn } from '../SpioDataTable';
import TaskDetails from '../TaskDetails';
import WidgetTitle from '../WidgetTitle';
import { RouteComponentProps } from 'react-router';
import { Moment } from 'moment';
import { IIdNameDto } from '../../../../backend/src/common/id-name-dto.interface';
import {
  ITaskDto,
  ITaskGroupDto,
  ITasksResponse,
} from '../../../../backend/src/task/interfaces';
import { IIdNameVersionDto } from '../../../../backend/src/common/id-name-version-dto.interface';

const dtTheme = () => createTheme({
  overrides: {
    MuiTableCell: {
      root: {
        paddingLeft: '0.5rem',
        paddingRight: '0.5rem',
      },
    },
  },
});

const useStyles = makeStyles({
  dialogContent: {
    padding: '0',
    // overflowY: 'hidden',
    // '&:first-child': {
    //   paddingTop: '0',
    // },
    overflow: 'auto',
    // Set a maxHeight or keep it responsive
    // maxHeight: '80vh', // Adjust as needed
},
  tableContainer: {
    marginLeft: '1rem',
    marginBottom: '1rem',
    minHeight: '200px',
  },
  toggleViewLink: {
    'color': 'inherit',
    'cursor': 'pointer',
    'textDecoration': 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  tabsContainer: {
    marginTop: '-5px',
  },
});

const StyledTab = withStyles((theme: Theme) => createStyles({
  root: {
    fontSize: '1.25rem',
    fontWeight: theme.typography.fontWeightRegular,
    letterSpacing: '0.0075em',
    paddingTop: '0',
    textTransform: 'none',
  },
  selected: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}))((props: TabProps) => <Tab {...props} />);

const statusMap = {
  not_started: {
    icon: <NewReleasesIcon />,
    text: 'Not Started',
  },
  completed: {
    icon: <DoneIcon />,
    text: 'Completed',
  },
  past_due: {
    icon: <PriorityHighIcon />,
    text: 'Past Due',
  },
  in_progress: {
    icon: <TimelapseIcon />,
    text: 'In Progress',
  },
  not_relevant: {
    icon: <RemoveCircle />,
    text: 'Not Relevant',
  },
};

const tableHeaders: SpioDataTableColumn[] = [
  {
    name: 'status',
    label: 'Status',
    options: {
      customBodyRender: (value: string) => Object?.values(statusMap)?.find(m => m.text === value)?.icon,
    },
  },
  {
    name: 'name',
    label: 'Task',
  },
  {
    name: 'dueDate',
    label: 'Due',
  },
];

export interface UpNextTasksProps extends RouteComponentProps {
  auth: Auth;
  isLoading: boolean;
  onUpdateAssignment: () => void;
  onUpdateStatus: () => void;
  setTasks: (tasks: ITaskDto[]) => void;
  tasks: ITaskDto[];
}

function UpNextTasks({
  auth,
  history,
  isLoading,
  onUpdateAssignment,
  onUpdateStatus,
  setTasks,
  tasks,
}: UpNextTasksProps) {
  const classes = useStyles();

  const [tasksToDisplay, setTasksToDisplay] = useState<ITaskDto[]>([]);
  const [assignees, setAssignees] = useState([]);
  const [selectedIdx, setSelectedIdx] = useState(0);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(auth.getDashboardTopTasksTab());
  const [allTaskTags, setAllTaskTags] = useState<IIdNameDto[]>([]);
  const [groups, setGroups] = useState<ITaskGroupDto[]>([]);
  const [collections, setCollections] = useState<IIdNameDto[]>([]);
  const [ attachableDocuments, setAttachableDocuments ] = useState<IIdNameVersionDto[]>([]);

  useEffect(() => {
    API
      .get('task/allowedAssignees')
      .then(res => {
        setAssignees(res.data.data);
      })
      .catch(Sentry.captureException);
  }, []);

  useEffect(() => {
    API.get('task/tags')
      .then(res => {
        setAllTaskTags(res.data.data.filter((tag: IIdNameDto) => !tag.name.includes('round')));
      })
      .catch(_ => {
        showErrorResultBar('Unexpected error fetching tags');
      });
  }, []);

  useEffect(() => {
    API.get('task/groups')
      .then(res => {
        setGroups(res.data.data);
      })
      .catch(_ => {
        showErrorResultBar('Unexpected error fetching tags');
      });

    API.get('task/collections')
      .then(res => {
        setCollections(res.data.data);
      })
      .catch(_ => {
        showErrorResultBar('Unexpected error fetching tags');
      });

    API
      .get('document/names')
      .then(res => {
        const docs = res.data;
        setAttachableDocuments(docs);
      })
      .catch((err) => {
        showErrorResultBar('Unexpected error loading attachable documents');
        Sentry.captureException(err);
      });
  }, []);



  useEffect(() => {
    const desiredAssigneeId = selectedTab === 0 ? auth.getUserId() : null;
    setTasksToDisplay(tasks.filter(t => t.assigneeId === desiredAssigneeId || (t.subTasks && t.subTasks.length > 0 && t.subTasks.some(st => st.assigneeId === desiredAssigneeId && !st.completed))));
  }, [auth, selectedTab, tasks]);

  // Update the cached tab selection:
  useEffect(() => {
    auth.setDashboardTopTasksTab(selectedTab);
  }, [auth, selectedTab]);

  const markComplete = async () => {
    const res = await API.post(`task/${tasksToDisplay[selectedIdx].id}/complete`);

    if (res.status === 200) {
      onUpdateStatus();
      setIsDetailsOpen(false);
    }
  };

  const markStarted = async () => {
    const res = await API.post(`task/${tasksToDisplay[selectedIdx].id}/start`);

    if (res.status === 200) {
      onUpdateStatus();
      setIsDetailsOpen(false);
    }
  };

  const markNotRelevant = async () => {
    const res = await API.post(`task/${tasksToDisplay[selectedIdx].id}/notRelevant`);

    if (res.status === 200) {
      onUpdateStatus();
      setIsDetailsOpen(false);
    }
  };

  const collapseRows = async () => {

  };

  const markRelevant = async () => {
    const res = await API.post(`task/${tasksToDisplay[selectedIdx].id}/relevant`);

    if (res.status === 200) {
      onUpdateStatus();
      setIsDetailsOpen(false);
    }
  };

  const handleCompletedDateChanged = async (newCompletedDate: Moment | null) => {
    try {
      const taskId = tasksToDisplay[selectedIdx].id;
      const res = await API.put(`task/${taskId}`, { completedAt: newCompletedDate });

      const taskIdx = tasks.findIndex(task => task.id === taskId);
      if (taskIdx !== -1) {
        const updatedTasks = tasks.slice();
        updatedTasks[taskIdx].dueDate = res.data.data.dueDate;
        setTasks(updatedTasks);
      }

      showSuccessResultBar('Completed date updated successfully.');
    } catch (err) {
      Sentry.captureException(err);
      showErrorResultBar('Unexpected error while updating the completed date');
    }
  };

  const handleDueDateChanged = async (newDueDate: Moment | null) => {
    // TODO: Consider making this a full reload (but leave dialog open?) since adding a due date will usually change the 'top tasks'
    try {
      const taskId = tasksToDisplay[selectedIdx].id;
      const res = await API.put(`task/${taskId}`, { dueDate: newDueDate });

      const taskIdx = tasks.findIndex(task => task.id === taskId);
      if (taskIdx !== -1) {
        const updatedTasks = tasks.slice();
        updatedTasks[taskIdx].dueDate = res.data.data.dueDate;
        setTasks(updatedTasks);
      }

      showSuccessResultBar('Due date updated successfully.');
    } catch (err) {
      Sentry.captureException(err);
      showErrorResultBar('Unexpected error while updating the due date');
    }
  };

  const handleStartDateChanged = async (newStartDate: Moment | null) => {
    // TODO: Consider making this a full reload (but leave dialog open?) since adding a due date will usually change the 'top tasks'
    try {
      const taskId = tasksToDisplay[selectedIdx].id;
      const res = await API.put(`task/${taskId}`, { startDate: newStartDate });

      const taskIdx = tasks.findIndex(task => task.id === taskId);
      if (taskIdx !== -1) {
        const updatedTasks = tasks.slice();
        updatedTasks[taskIdx].dueDate = res.data.data.dueDate;
        setTasks(updatedTasks);
      }

      showSuccessResultBar('Start date updated successfully.');
    } catch (err) {
      Sentry.captureException(err);
      showErrorResultBar('Unexpected error while updating the start date');
    }
  };

  const handleUpdateTaskProperties = async (updatedTaskInfo: ITasksResponse) => {
    // Reassignment requires a reload of the Up Next tasks; adding comments/docs does not.

    if (updatedTaskInfo.assigneeId !== undefined) {
      onUpdateAssignment();
      setIsDetailsOpen(false);
    } else {
      const taskIdx = tasks.findIndex(task => task.id === tasksToDisplay[selectedIdx].id);
      if (taskIdx !== -1) {
        const updatedTasks = tasks.slice();
        updatedTasks[taskIdx] = {
          ...updatedTasks[taskIdx],
          ...updatedTaskInfo,
        };
        setTasks(updatedTasks);
      }
    }
  };

  const handleDeleteTask = async () => {
    // don't allow delete from dashboard
  };

  const handleUpdateTask = async () => {
    // don't allow edit task from dashboard
  };

  const openDetails = (idx: number) => {
    setSelectedIdx(idx);
    setIsDetailsOpen(true);
  };

  const onClose = () => setIsDetailsOpen(false);

  const handleClickTitle = (e: React.MouseEvent) => {
    e.preventDefault();
    auth.setTasksSearch(null);

    if (selectedTab === 0) {
      auth.setTasksView(FILTER_VIEW_SELECTIONS([])[FILTER_VIEW_IDENTIFIERS.MY_OPEN_TASKS].value);
      auth.setTasksFilters({});
    } else {
      auth.setTasksView(FILTER_VIEW_SELECTIONS([])[FILTER_VIEW_IDENTIFIERS.CUSTOM_TASKS].value);
      auth.setTasksFilters({
        statusStr: OPEN_TASKS_FILTERS,
        assigneeName: [UNASSIGNED_TASK_STR],
      });
    }

    history.push('/tasks');
  };

  return (
    <Card>
      <Tabs
        className={classes.tabsContainer}
        value={selectedTab}
        onChange={(_, value) => setSelectedTab(value)}
      >
        <StyledTab label="My Top Tasks" />
        <StyledTab label="Top Unassigned Tasks" />
      </Tabs>
      <div className={classes.tableContainer}>
        <MuiThemeProvider theme={dtTheme}>
          <SpioDataTable
            title=""
            data={tasksToDisplay.map(t => Object({
              ...t,
              status: statusMap[t.status].text,
            }))}
            columns={tableHeaders}
            options={{
              download: false,
              elevation: 0,
              filter: false,
              pagination: false,
              print: false,
              search: false,
              selectableRows: 'none',
              sort: false,
              viewColumns: false,
              textLabels: {
                body: {
                  noMatch: isLoading ?
                    'Loading tasks...' :
                    (selectedTab === 0 ? 'No upcoming tasks assigned to me' : 'No unassigned upcoming tasks'),
                },
              },
              onRowClick: (_, rowMeta) => openDetails(rowMeta.dataIndex),
            }}
          />
        </MuiThemeProvider>
      </div>
      <WidgetTitle
        onClick={handleClickTitle}
        to="/training/report"
        variant="subtitle1"
      >
        See all {selectedTab === 0 ? 'of my' : 'unassigned'} tasks
      </WidgetTitle>
      <ResponsiveDialog
        fullWidth
        maxWidth="md"
        onClose={onClose}
        open={isDetailsOpen}
      >
        <DialogContent
          className={classes.dialogContent}
        >
          <TaskDetails
            assignees={assignees}
            auth={auth}
            history={history}
            isDialog
            markComplete={markComplete}
            markStarted={markStarted}
            onCloseDialog={onClose}
            onCompletedDateChange={handleCompletedDateChanged}
            markNotRelevant={markNotRelevant}
            markRelevant={markRelevant}
            onDueDateChange={handleDueDateChanged}
            onStartDateChange={handleStartDateChanged}
            onUpdateTaskProperties={handleUpdateTaskProperties}
            onUpdateTask={handleUpdateTask}
            taskData={tasksToDisplay[selectedIdx]}
            onDeleteTask={handleDeleteTask}
            tagOptions={allTaskTags}
            collections={collections}
            groups={groups}
            tasks={tasks}
            attachableDocuments={attachableDocuments}
            collapseRows={collapseRows}
          />
        </DialogContent>
      </ResponsiveDialog>
    </Card>
  );
}

UpNextTasks.propTypes = {
  auth: PropTypes.instanceOf(Auth).isRequired,
  history: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onUpdateAssignment: PropTypes.func,
  onUpdateStatus: PropTypes.func,
  setTasks: PropTypes.func,
  tasks: PropTypes.array.isRequired,
};

UpNextTasks.defaultProps = {
  onUpdateAssignment: Function,
  onUpdateStatus: Function,
  setTasks: Function,
};

UpNextTasks.requiredAuthZ = {
  tier: 1,
  permission: 'tasks',
};

export default UpNextTasks;
