import { TextField, FormControl, Tooltip } from '@material-ui/core';
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import { BaseTextFieldProps } from '@material-ui/core/TextField';
import Autocomplete, { AutocompleteProps } from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/styles';
import { FormikProps, FormikValues } from 'formik';
import React, { useState, useEffect } from 'react';
import { helperAndErrorText } from '.';
import AddIcon from '@material-ui/icons/Add';
import { AddEvidenceDialog } from '../dialogs';
import API from '../../services/ApiService';
import { showErrorResultBar } from '../ResultSnackbar';
import { closeSingleQuote } from 'prosemirror-inputrules';

const useStyles = makeStyles({
  inputField: {
    marginRight: 0,
    marginLeft: 0,
  },
  flexSpan: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
});

interface FormikAttachEvidenceFieldProps<Values extends FormikValues>
  extends BaseTextFieldProps {
  autocompleteProps: Omit<
    AutocompleteProps<
      string,
      boolean | undefined,
      boolean | undefined,
      boolean | undefined
    >,
    'renderInput'
  >;
  field: keyof Values;
  formikProps: FormikProps<Values>;
  helperTextStr?: string; // can be overridden with the usual 'helperText' prop
  options: any[];
}

export default function FormikAttachEvidenceField<Values extends FormikValues>(
  props: FormikAttachEvidenceFieldProps<Values>
) {
  const classes = useStyles();
  const {
    autocompleteProps,
    children,
    field,
    formikProps,
    helperTextStr,
    options,
    variant = 'filled',
    ...otherTextFieldProps
  } = props;
  const { errors, handleChange, handleBlur } = formikProps;
  const fieldStr = field.toString();
  const [value, setValue] = useState<any[]>([]);
  const [autoOptions, setAutoOptions] = useState<any[]>([]);
  const [evidenceTags, setEvidenceTags] = useState<any[]>([]);
  const [attachedEvidences, setAttachedEvidences] = useState<any[]>([]);
  const [attachEvidenceDialog, setAttachEvidenceDialog] = useState(false);

  const handleSaveEvidence = (evidences: any) => {
    if (!evidences[0]) {
      evidences.shift();
    }
    formikProps.setFieldValue(fieldStr, evidences); // handles clearing and selection of an option
    setValue(evidences); // seems necessary for the option filtering to work
  };

  useEffect(() => {
    async function fetchEvidenceTags() {
      const res = await API.get('document-tag');
      try {
        if (res.data.data) {
          setEvidenceTags(
            res.data.data.map((tag: any) => ({
              name: tag.name,
              id: tag.id,
            }))
          );
        }
      } catch (err) {
        showErrorResultBar('Unexpected error loading facilities');
      }
    }
    fetchEvidenceTags();
    setAutoOptions(options);
    setAttachedEvidences(formikProps.values.documents);
  }, []);

  const handleOpen = () => {
    setAttachEvidenceDialog(true);
  };

  return (
    <>
      <div className={classes.flexSpan}>
        <div style={{ width: '95%' }}>
          <Autocomplete
            autoComplete
            openOnFocus
            // {...autocompleteProps}
            freeSolo={false}
            id={fieldStr}
            options={autoOptions}
            value={value}
            getOptionLabel={(option) => option.name || ''}
            onChange={(e, v) => {
              let tempArray = value || [];
              tempArray.push(v);
              const unAttached: any = options.filter(
                (el: any) => !tempArray.includes(el)
              );
              formikProps.setFieldValue(fieldStr, tempArray); // handles clearing and selection of an option
              setValue(tempArray); // seems necessary for the option filtering to work
              setAttachedEvidences(tempArray);
              setAutoOptions(unAttached);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                className={classes.inputField}
                name={fieldStr}
                onBlur={handleBlur}
                onChange={handleChange} // handles free input
                //fullWidth
                variant={variant as any}
                error={!!errors[field]}
                helperText={helperAndErrorText(
                  helperTextStr,
                  errors[field]?.toString()
                )}
                {...otherTextFieldProps}
              >
                {children}
              </TextField>
            )}
          />
        </div>
        <div style={{ paddingTop: '10pt' }}>
          <Tooltip title="Attach Evidence">
            <IconButton
              aria-label="Attach Evidence"
              key={'add_evidence'}
              onClick={handleOpen}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <AddEvidenceDialog
        open={attachEvidenceDialog}
        onClose={() => {
          setAttachEvidenceDialog(false);
        }}
        attachedEvidences={attachedEvidences}
        handleSave={handleSaveEvidence}
        evidences={options}
        tags={evidenceTags}
      />
    </>
  );
}
