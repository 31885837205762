import moment from 'moment';
import _ from 'lodash';
import { showErrorResultBar } from '../src/components/ResultSnackbar';
import * as Sentry from '@sentry/browser';

export function formatDate(date) {
  if (date) {
    return moment(date).local().format('YYYY-MM-DD');
  }
}

export function createStringBreaks(string, maxLength = 20) {
  let arr = string.split(' ');
  for (let i = 1; i < arr.length; i++) {
    if (arr[i].length >= maxLength || arr[i].length + arr[i - 1].length >= maxLength) {
      continue;
    }
    if (arr[i].length < maxLength && arr[i].length + arr[i - 1].length <= maxLength) {
      arr[i] = arr[i - 1] + ' ' + arr[i];
    }
    arr[i - 1] = '';
  }
  arr = arr.filter((string) => string);

  return arr;
}

export const formatFileTitle = (fileName) => {
  return fileName.includes('.') ? fileName.replace('.' + fileName.split('.').slice(-1)[0], '') : fileName;
};

export function getFileExtension(fileName) {
  return fileName.includes('.') ? fileName.split('.').slice(-1)[0] : '';
}

export const formatFileSize = (bytes, dp = 1) => {
  const thresh = 1000;

  if (isNaN(bytes)) {
    return bytes;
  } else {
    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }

    const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let u = -1;
    const r = 10 ** (dp);

    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


    return bytes.toFixed(dp) + ' ' + units[u];
  }
};

export function formatDateTime(datetime) {
  if (datetime) {
    return moment(datetime).local().format('YYYY-MM-DD h:mm:ss a');
  }
}

export function formatSeconds(totalSeconds = 0) {
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = Math.ceil(totalSeconds % 60);

  return _.padStart(minutes.toString(), 2, '0') + ':' + _.padStart(seconds.toString(), 2, '0');
}

// TODO Come back to this and decide what we do/don't want to have
// in terms of videos
export const TrainingVids = {
  WelcomeToSPIO: 'c6c98ce7-b77b-412f-9235-4ecfb4b8b7a3', // short intro to the UI
  SecurityAwareness: '31776523-985a-4f40-b78d-55714f6970b8', // general security awareness training (full version)
  IntroToSPIO: '588dccb6-d1ba-4c3f-993f-815d5c9ba94a', // longer intro to the overall program
};

export const DemoVids = {
  // TODO update video to doc discovery demo rather in ESG intro
  DocDiscovery: '60cdcc09-2fcc-4bb6-8634-cc3375067a98', // short intro to doc discovery
};

export const GEMINI_SUPPORT_EMAIL = 'support@rhoimpact.com';

export const StaticLinks = {
  marketing: 'https://rhoimpact.com',
  privacy: 'https://rhoimpact.com/privacy-policy',
  esg: 'https://rhoimpact.com/esg-policy',
  security: 'https://rhoimpact.com/security-policy',
  terms: 'https://rhoimpact.com/terms-of-service',
  saasTerms: 'https://terms.rhogemini.com/',
  scheduleDemo: 'https://calendly.com/rho-demos',
};

/**
 * Truncates a string that exceeds the max length by splitting and inserting a horizontal ellipsis (\u2026)
 * @param {string|undefined} s          The string to be truncated
 * @param {number} maxLength  Maximum length after which the string is truncated
 * @returns {string}          Original string if shorter than maxLength, truncated string otherwise
 */
export function truncateString(s, maxLength = 32, stringEnd = true) {
  if (!s) {
    return '';
  }

  const len = s.length;

  if (stringEnd) {
    return (len < maxLength ? s : `${s.substring(0, maxLength - 12)}\u2026${s.substring(len - 10)}`);
  } else {
    return (len < maxLength ? s : `${s.substring(0, maxLength)}\u2026`);
  }
}

export function convertLocalDate(date) {

  const momentDate = moment(date);

  if (date.isValid()) {
    return momentDate.local();
  } else {
    return null;
  }
}

export function handleError(err, defaultMsg = 'Unexpected error') {
  const errorMsg = err.response?.data?.error ?? defaultMsg;
  showErrorResultBar(errorMsg);
  Sentry.captureException(err);
}

export function intervalToString(interval) {
  const parts = [
    'years',
    'months',
    'days',
    'hours',
    'minutes',
    'mins',
    'seconds',
    'secs',
  ];

  let outputParts = [];

  if (!interval) {
    return undefined;
  }

  parts.forEach(p => {
    if (interval[p]) {
      outputParts.push(`${interval[p]} ${p}`);
    }
  });

  return outputParts.join(', ');
}

export function errorParser(err, defaultMsg) {
  let errorMsg = '';

  if (err && err.response && err.response.data) {
    const errorData = err.response.data;
    const errorTypeStr = errorData.error || '';
    const errorDetailStr = errorData.message || '';
    const sep = (errorTypeStr && errorDetailStr) ? ': ' : '';

    errorMsg = `${errorTypeStr}${sep}${errorDetailStr}`;
  }

  return errorMsg || defaultMsg;
}

export function preventNonNumericInput(event) {
  // Some browsers allow non-numerical input for type="number", which we prevent here.
  // (Note that 'key' will be 'undefined' on mobile, but there a numerical keypad should pop up.)
  const { key } = event;

  if (key && !key.match(/^[0-9]+$/)) {
    event.preventDefault();
  }
}

export const sanitizeForCSV = (cellContent) => {
  // Ensure all double quotes are escaped ("" is an escaped double quote)
  // and prefix any leading special characters with a single quote.
  // Note that you'll still want to surround the content with double quotes,
  // so the usage will be: `"${sanitizeForCSV(text)}"`
  return typeof cellContent === 'string'
    ? cellContent.replaceAll('"', '""').replace(/^\+|^-|^=|^@/, "'$&")
    : cellContent;
};
