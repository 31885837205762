import React, { useEffect, useState } from 'react';
import { blueGrey, grey, red } from '@material-ui/core/colors';
import {
  createStyles,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Theme,
  Tooltip,
  Typography,
  withStyles,
  WithStyles,
  TabProps,
  Tab,
  Box
} from '@material-ui/core';
import { StepIconProps } from '@material-ui/core/StepIcon';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { range } from 'lodash';
import { History } from 'history';
import { Doughnut as DoughnutChart } from 'react-chartjs-2';
import { ITaskStatusCount, ITaskStatusCountByGroup } from '../../../../backend/src/task/interfaces';
import { TaskStatus } from '../../../../backend/src/task/task-status.enum';
import Auth from '../../services/AuthService';
import { geminiPurple } from '../../theme';

const MAX_STEPS_PER_ROW = 10; // Maximum steps per row

const STATUSES: TaskStatus[] = ['completed', 'in_progress', 'past_due', 'not_started'];

const styles = (theme: Theme) => createStyles({
  cardContent: {
    'overflowX': 'auto',
    'maxWidth': 'calc(100vw - 64px)',
    '&:last-child': {
      paddingBottom: '12px',
    },
  },
  [theme.breakpoints.down('sm')]: {
    hidePaddingDn: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  stepLabel: {
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'overline #cccccc',
    },
  },
  groupTitle: {
    whiteSpace: 'nowrap',
  },
  groupDescription: {
    color: 'rgba(0, 0, 0, 0.65)',
    fontSize: '1rem',
    fontWeight: 'bold',
    lineHeight: '1.5rem',
    marginTop: '8px',
  },
  groupDescriptionHeader: {
    color: 'rgba(0, 0, 0, 0.65)',
    fontSize: '.8rem',
    fontWeight: 'bold',
    lineHeight: '1.5rem',
  },
});

const StyledTab = withStyles((theme: Theme) => createStyles({
  root: {
    fontSize: '1.25rem',
    fontWeight: theme.typography.fontWeightRegular,
    letterSpacing: '0.0075em',
    paddingTop: '0',
    paddingBottom: '0',
    textTransform: 'none',
  },
  selected: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}))((props: TabProps) => <Tab {...props} />);

export interface ITaskStatusChartInfo {
  title: string;
  backgroundColor: string;
  hoverBackgroundColor: string;
  className: 'completedColor' | 'inProgressColor' | 'notStartedColor' | 'pastDueColor';
}

export const STATUS_CHART_INFO_MAP: Record<TaskStatus, ITaskStatusChartInfo> = {
  not_relevant: {
    title: 'Not Relevant',
    backgroundColor: grey[400],
    hoverBackgroundColor: grey[600],
    className: 'completedColor',
  },
  not_started: {
    title: 'Not Started',
    backgroundColor: blueGrey[50],
    hoverBackgroundColor: blueGrey[100],
    className: 'notStartedColor',
  },
  in_progress: {
    title: 'In Progress',
    backgroundColor: geminiPurple[400],
    hoverBackgroundColor: geminiPurple[500],
    className: 'inProgressColor',
  },
  past_due: {
    title: 'Past Due',
    backgroundColor: red[400],
    hoverBackgroundColor: red[600],
    className: 'pastDueColor',
  },
  completed: {
    title: 'Completed',
    backgroundColor: geminiPurple[600],
    hoverBackgroundColor: geminiPurple[800],
    className: 'completedColor',
  },
};

const GroupSummaryStepStyles = makeStyles(createStyles({
  root: {
    backgroundColor: blueGrey[100],
    zIndex: 1,
    width: 50,
    height: 50,
    borderRadius: '50%',
  },
  active: {},
  tooltip: {
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
    color: '#fff',
    fontSize: '12px',
    padding: '6px',
  },
  tooltipHeader: {
    fontWeight: 'bold',
    marginBottom: '2px',
  },
  tooltipColorBox: {
    display: 'inline-block',
    width: '12px',
    height: '12px',
    border: '1px solid #fff',
    marginRight: '5px',
  },
  completedColor: {
    backgroundColor: STATUS_CHART_INFO_MAP.completed.backgroundColor,
  },
  inProgressColor: {
    backgroundColor: STATUS_CHART_INFO_MAP.in_progress.backgroundColor,
  },
  notStartedColor: {
    backgroundColor: STATUS_CHART_INFO_MAP.not_started.hoverBackgroundColor,
  },
  pastDueColor: {
    backgroundColor: STATUS_CHART_INFO_MAP.past_due.hoverBackgroundColor,
  },
  tooltipStatusCount: {
    float: 'right',
    marginLeft: '2px',
  },
}));

const doughnutChartOptions = {
  legend: {
    display: false,
  },
  maintainAspectRatio: false,
  cutoutPercentage: 0,
  tooltips: {
    enabled: false,
  },
};

function GroupSummaryStep({ active, group }: StepIconProps) {
  const classes = GroupSummaryStepStyles();

  const summary = group.data || {};
  const statusesForTooltip = STATUSES.filter(status => summary[status] > 0);
  const statusesForChart = STATUSES;

  const taskData = {
    labels: statusesForChart.map(status => STATUS_CHART_INFO_MAP[status].title),
    width: 50,
    height: 50,
    datasets: [
      {
        data: statusesForChart.map(status => summary[status]),
        backgroundColor: statusesForChart.map(status => STATUS_CHART_INFO_MAP[status].backgroundColor),
        hoverBackgroundColor: statusesForChart.map(status => STATUS_CHART_INFO_MAP[status].hoverBackgroundColor),
        borderWidth: 0,
      },
    ],
  };

  return (
    <Tooltip
      placement="top-end"
      classes={{ tooltip: classes.tooltip }}
      title={
        <>
          <div className={classes.tooltipHeader}>
            {group.title} Tasks
          </div>
          {statusesForTooltip.map(status => (
            <div key={status}>
              <div className={classNames(classes.tooltipColorBox, classes[STATUS_CHART_INFO_MAP[status].className])} />
              {STATUS_CHART_INFO_MAP[status].title}:
              <span className={classes.tooltipStatusCount}>
                {summary[status]}
              </span>
            </div>
          ))}
        </>
      }
    >
      <div
        className={classNames(
          classes.root,
          active && classes.active,
        )}
      >
        <DoughnutChart data={taskData} options={doughnutChartOptions} />
      </div>
    </Tooltip>
  );
}

interface IGroupInfo {
  data: IStatusSummary;
  description: string;
  title: string;
}

interface IStatusSummary extends Record<TaskStatus, number> {
  total: number;
  isComplete: boolean;
  isInactive: boolean;
}

const countToNumber = (countRaw?: number | string) => {
  return typeof countRaw === 'string' ? parseInt(countRaw, 10) : (countRaw || 0);
};

const groupSet = (data: ITaskStatusCountByGroup[]): string[] => {
  const groups = Array.from(new Set(data.map(d => d.groupname)));
  return groups;
};

const transformData = (data: ITaskStatusCountByGroup[]): IGroupInfo[] => (
  range(1, groupSet(data).length + 1).map((i) => {
    const groups = groupSet(data);
    const statusSummary: IStatusSummary = {
      total: 0,
      isComplete: false,
      isInactive: false,
      completed: 0,
      in_progress: 0,
      not_started: 0,
      past_due: 0,
      not_relevant: 0,
    };

    STATUSES.forEach((status) => {
      const summary = data.find(d => d.status === status && d.groupname === groups[i-1]);
      const count = countToNumber(summary?.count);

      statusSummary[status] = count;
      statusSummary.total += count;
    });

    statusSummary.isInactive = statusSummary.not_started === statusSummary.total;
    statusSummary.isComplete = !!statusSummary.total && statusSummary.completed === statusSummary.total;

    return {
      data: statusSummary,
      description: groups[i-1],
      title: `Group ${i}`,
    };
  })
);

export interface ITaskStatusByGroupProps extends WithStyles<typeof styles> {
  auth: Auth;
  history: History;
  taskOverallSummary: ITaskStatusCount[];
  taskGroupsSummary: ITaskStatusCountByGroup[];
  allTasks: boolean;
}

function TaskStatusByGroup({ auth, classes, history, taskOverallSummary, taskGroupsSummary, allTasks }: ITaskStatusByGroupProps) {
  const [groups, setGroups] = useState<IGroupInfo[]>([]);
  const [activeGroupIdx, setActiveGroupIdx] = useState(-1);
  const [percentComplete, setPercentComplete] = useState(0);

  useEffect(() => {
    setGroups(transformData(taskGroupsSummary));
  }, [taskGroupsSummary]);

  useEffect(() => {
    const idxRaw = groups.findIndex(group => !group.data.isComplete);
    const idx = idxRaw === -1 ? groups.length - 1 : idxRaw;

    setActiveGroupIdx(idx);
  }, [groups]);

  useEffect(() => {
    const complete = taskOverallSummary.find(d => d.status === 'completed');
    const nbComplete = countToNumber(complete?.count);
    const nbTotal = taskOverallSummary.reduce((sum, curr) => sum + countToNumber(curr.count), 0);
    const percent = nbTotal === 0 ? 0 : Math.round(100. * nbComplete / nbTotal);

    setPercentComplete(percent);
  }, [taskOverallSummary]);

  const StyledStepConnector = withStyles({
    alternativeLabel: {
      top: 22,
    },
    active: {
      '& $line': {
        borderColor: geminiPurple[600],
      },
    },
    completed: {
      '& $line': {
        borderColor: geminiPurple[600],
      },
    },
    line: {
      borderTopWidth: 3,
      borderColor: allTasks ? 'white' : blueGrey[100],
      borderRadius: 1,
    },
  })(StepConnector);

  const onClickGroup = (groupIdx: number) => () => {
    const groupId = Array.from(new Set(taskGroupsSummary.map(d => d.id)))[groupIdx];
    const filterViewValue = groupId;

    if (filterViewValue) {
      auth.setTasksSearch(null);
      auth.setTasksView(filterViewValue);
    }
    history.push('/tasks');
  };

  const splitIntoRows = (items: any[], maxPerRow: number) => {
    const rows = [];
    for (let i = 0; i < items.length; i += maxPerRow) {
      rows.push(items.slice(i, i + maxPerRow));
    }
    return rows;
  };

  const rows = splitIntoRows(groups, MAX_STEPS_PER_ROW);

  return (
    <div className={classes.root}>
      {rows.map((row, rowIndex) => (
        <Stepper
          key={rowIndex}
          activeStep={activeGroupIdx}
          alternativeLabel
          connector={<StyledStepConnector />}
          className={classes.hidePaddingDn}
        >
          {row.map((group, idx) => (
            <Step key={group.title}>
              <StepLabel
                StepIconComponent={GroupSummaryStep}
                StepIconProps={{ group }}
                onClick={onClickGroup(idx)}
                className={classes.stepLabel}
              >
                <Typography
                  className={classes.groupDescription}
                  variant="caption"
                >
                  <span dangerouslySetInnerHTML={{ __html: `${group.description}` }} />
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      ))}
    </div>
  );
}

TaskStatusByGroup.requiredAuthZ = {
  tier: 1,
  permission: 'tasks',
};

export default withStyles(styles, { withTheme: true })(TaskStatusByGroup);
