import {
  Card,
  createTheme,
  Grid,
  Link,
  MuiThemeProvider,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import * as Sentry from '@sentry/browser';
import * as PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import API from '../../services/ApiService';
import Auth from '../../services/AuthService';
import { showErrorResultBar, showSuccessResultBar } from '../ResultSnackbar';
import SpioDataTable, { SpioDataTableColumn } from '../SpioDataTable';
import { IGanttTask } from '../GanttChart';
import { formatDate } from '../../helpers'; // Ensure formatDate is imported
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment, { Moment } from 'moment';
import { SpioPickersUtils } from '../../SpioPickersUtils';
import grey from '@material-ui/core/colors/grey';

const dtTheme = () => createTheme({
  overrides: {
    MuiTableCell: {
      root: {
        paddingLeft: '0.5rem',
        paddingRight: '0.5rem',
      },
    },
  },
});

const useStyles = makeStyles({
  dialogContent: {
    padding: '0',
    overflow: 'auto',
  },
  tableContainer: {
    marginLeft: '1rem',
    marginBottom: '1rem',
    minHeight: '200px',
  },
  toggleViewLink: {
    color: 'inherit',
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  tabsContainer: {
    marginTop: '-5px',
  },
  customToolbar: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    marginLeft: '-32px',
  },
  toolbarTextContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  customRow: {

  },
  customRowSpecial: {
    backgroundColor: grey[200],
  },
});

export interface UnassignedTaskTableProps {
  isLoading: boolean;
  setGanttTasks: (tasks: IGanttTask[]) => void;
  setTasks: (tasks: IGanttTask[]) => void;
  tasks: IGanttTask[];
  ganttTasks: IGanttTask[];
}

function UnassignedTaskTable({
  isLoading,
  setTasks,
  tasks,
  ganttTasks,
  setGanttTasks,
}: UnassignedTaskTableProps) {
  const classes = useStyles();

  const [tasksToDisplay, setTasksToDisplay] = useState<IGanttTask[]>([]);
  const [datePickerData, setDatePickerData] = useState<{ open: boolean; index: number | null; type: 'start' | 'end' | null }>({
    open: false,
    index: null,
    type: null,
  });

  const handleDatePickerOpen = (index: number, type: 'start' | 'end') => {
    setDatePickerData({ open: true, index, type });
  };

  const handleDatePickerClose = () => {
    setDatePickerData({ open: false, index: null, type: null });
  };

  const handleDateChange = async (date: Moment | null) => {
    if (!date || datePickerData.index === null || !datePickerData.type) return;

    const taskIndex = datePickerData.index;
    const taskId = tasks[taskIndex].id;

    const dateType = datePickerData.type === 'start' ? 'start' : 'end';

    const updatedTask = {
      ...tasks[taskIndex],
      [dateType]: date.toDate(), // Convert moment date to JavaScript Date object
    };

    try {
      await API.put(`task/${taskId}`, { [dateType === 'start' ? 'startDate' : 'dueDate']: date.toDate() });

      // If both start and end dates are set, move task from unassigned to ganttTasks
      if (updatedTask.start && updatedTask.end) {
        const updatedUnassignedTasks = tasks.filter((_, index) => index !== taskIndex);
        setTasks(updatedUnassignedTasks);

        const updatedGanttTasks = [...ganttTasks, updatedTask].sort((a, b) => {
          if (a.displayOrder < b.displayOrder) {
            return -1;
          }
          if (a.displayOrder > b.displayOrder) {
            return 1;
          }
          return 0;
        });
        setGanttTasks(updatedGanttTasks);
        // handleUpdateProjectStartAndEndDates(updatedGanttTasks, updatedTask);
      } else {
        const updatedTasks = [...tasks];
        updatedTasks[taskIndex] = updatedTask;
        setTasks(updatedTasks);
      }

      showSuccessResultBar(`${dateType === 'start' ? 'Start' : 'End'} date updated successfully.`);
    } catch (err) {
      Sentry.captureException(err);
      showErrorResultBar('Unexpected error while updating the date');
    }

    handleDatePickerClose();
  };

  const tableHeaders: SpioDataTableColumn[] = [
    {
      name: 'name',
      label: 'Task',
    },
    {
      name: 'start',
      label: 'Start',
      options: {
        display: 'true',
        customBodyRenderLite: (dataIndex) => (
          tasksToDisplay[dataIndex] && tasksToDisplay[dataIndex].type === 'task' ? (
          <>
            <Link component="button" onClick={() => handleDatePickerOpen(dataIndex, 'start')}>
              {tasksToDisplay[dataIndex]?.start ? formatDate(tasksToDisplay[dataIndex].start) : 'Select Date'}
            </Link>
          </>
          ) : null
        ),
      },
    },
    {
      name: 'end',
      label: 'End',
      options: {
        display: 'true',
        customBodyRenderLite: (dataIndex) => (
          tasksToDisplay[dataIndex] && tasksToDisplay[dataIndex].type === 'task' ? (
          <>
            <Link component="button" onClick={() => handleDatePickerOpen(dataIndex, 'end')}>
              {tasksToDisplay[dataIndex]?.end ? formatDate(tasksToDisplay[dataIndex].end) : 'Select Date'}
            </Link>
          </>
          ) : null
        ),
      },
    },
  ];

  const CustomToolbar = () => {
    return (
      <Toolbar className={classes.customToolbar}>
        <Grid container direction="column">
          <Typography variant="h6">Unassigned Tasks</Typography>
          <Typography variant="subtitle1">Assign start and due dates to tasks to view on Gantt chart</Typography>
        </Grid>
      </Toolbar>
    );
  };

  useEffect(() => {
    setTasksToDisplay(tasks);
  }, [tasks]);

  return (
    <Card>
      <div className={classes.tableContainer}>
        <MuiThemeProvider theme={dtTheme}>
          <SpioDataTable
            title={<CustomToolbar />}
            data={tasksToDisplay.map((t) => ({
              ...t,
              startDate: formatDate(t.start),
              dueDate: formatDate(t.end),
            }))}
            columns={tableHeaders}
            options={{
              download: false,
              setRowProps: (row, dataIndex) => {
                if (tasks[dataIndex]?.type !== 'task') {
                  return { className: classes.customRowSpecial };
                }
                return { className: classes.customRow };
              },
              elevation: 0,
              filter: false,
              pagination: false,
              print: false,
              search: false,
              selectableRows: 'none',
              sort: false,
              viewColumns: false,
              textLabels: {
                body: {
                  noMatch: isLoading
                    ? 'Loading tasks...'
                    : 'No unassigned tasks',
                },
              },
            }}
          />
        </MuiThemeProvider>
      </div>
      <MuiPickersUtilsProvider utils={SpioPickersUtils}>
        <DatePicker
          open={datePickerData.open}
          onClose={handleDatePickerClose}
          value={datePickerData.index !== null && datePickerData.type ? moment(tasksToDisplay[datePickerData.index][datePickerData.type]) : moment()}
          onChange={(date) => handleDateChange(date)}
          TextFieldComponent={() => null}
          minDate={datePickerData.type === 'end' && datePickerData.index !== null && tasksToDisplay[datePickerData.index]?.start ? moment(tasksToDisplay[datePickerData.index].start) : undefined}
          maxDate={datePickerData.type === 'start' && datePickerData.index !== null && tasksToDisplay[datePickerData.index]?.end ? moment(tasksToDisplay[datePickerData.index].end) : undefined}
        />
      </MuiPickersUtilsProvider>
    </Card>
  );
}

UnassignedTaskTable.propTypes = {
  auth: PropTypes.instanceOf(Auth).isRequired,
  isLoading: PropTypes.bool.isRequired,
  onUpdateAssignment: PropTypes.func,
  onUpdateStatus: PropTypes.func,
  setTasks: PropTypes.func,
  tasks: PropTypes.array.isRequired,
};

UnassignedTaskTable.defaultProps = {
  onUpdateAssignment: Function,
  onUpdateStatus: Function,
  setTasks: Function,
};

UnassignedTaskTable.requiredAuthZ = {
  tier: 1,
  permission: 'tasks',
};

export default UnassignedTaskTable;
