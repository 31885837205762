import { createStyles, DialogTitle, StandardProps, Theme, Typography, withStyles } from '@material-ui/core';
import { DialogTitleProps } from '@material-ui/core/DialogTitle';
import { ModalProps } from '@material-ui/core/Modal';
import React from 'react';
import CloseButton from './buttons/CloseButton';

export type StyledDialogTitleClassKey =
  | 'closeButton'
  | 'root';

export interface StyledDialogTitleProps extends StandardProps<DialogTitleProps, StyledDialogTitleClassKey> {
  onClose?: ModalProps['onClose'];
}

const styles = createStyles((theme: Theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

function StyledDialogTitle({ classes, onClose, children }: StyledDialogTitleProps) {
  return (
    <DialogTitle
      className={classes!.root}
      disableTypography
    >
      <Typography variant="h6">{children}</Typography>
      {onClose &&
      <CloseButton
        className={classes!.closeButton}
        onClick={e => onClose(e, 'escapeKeyDown')} />
      }
    </DialogTitle>
  );
}

export default withStyles(styles, { withTheme: true })(StyledDialogTitle);
