import { Button } from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';
import React, { useRef, useState } from 'react';
import API from '../services/ApiService';
import { showErrorResultBar, showSuccessResultBar } from './ResultSnackbar';
import StyledDialogTitle from './StyledDialogTitle';
import * as Sentry from '@sentry/browser';

const useStyles = makeStyles({
  buttonsContainer: {
    lineHeight: '96px',
    paddingRight: '2rem',
  },
});

export function MetricTableSelectedRowsToolbar(props) {
  const classes = useStyles();
  const { onCancel, selectedRows, metrics, onUpdate, auth } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const selectedDataRef = useRef([]);

  const handleUpdateStatus = async (updateInfo) => {
    const selectedData = selectedDataRef.current;

    const metricIds = selectedData.map( row => {
      return (row.metric.id);
    });

    if (metricIds.length === 0) {
      const msg = updateInfo.status
        ? `All of the selected metrics are currently tracked ${
            updateInfo.status === 'in_progress' ? 'started' : 'tracked'
          }.`
        : 'None of the selected metrics needed to be updated.';
      showSuccessResultBar(msg);
      onCancel();

      return;
    }

    setIsSubmitting(true);
    selectedDataRef.current = [];
  };

  const getAllSelectedData = () => {
    const allSelectedIdxs = selectedRows.data.map((row) => row.dataIndex);

    return allSelectedIdxs.map((idx) => ({ idx, metric: metrics[idx] }));
  };

  const handleClickTrackMetric = async () => {
    setIsSubmitting(true);
    const mSelectedData = getAllSelectedData().filter(
      ({ metric }) => metric.tracked !== true
    );
    selectedDataRef.current = mSelectedData;
    mSelectedData.forEach((row) => {
      try {
        API.post(`org-metric/${row.metric.id}/track`);
        showSuccessResultBar('Metrics tracked.');
      } catch (err) {
        Sentry.captureException(err);
        showErrorResultBar('Error updating metric tracking.');
        setIsSubmitting(false);
      } finally {
        onUpdate(mSelectedData.map(({ idx }) => idx), { tracked: true });
        setIsSubmitting(false);
        selectedDataRef.current = [];
      }});
  };

  const handleClickUnTrackMetric = async () => {
    const mSelectedData = getAllSelectedData().filter(
      ({ metric }) => metric.tracked !== false
    );
    selectedDataRef.current = mSelectedData;
    mSelectedData.forEach((row) => {
      try {
        API.post(`org-metric/${row.metric.id}/untrack`);
        showSuccessResultBar('Metrics untracked.');
      } catch (err) {
        Sentry.captureException(err);
        showErrorResultBar('Error updating metric tracking.');
        setIsSubmitting(false);
      } finally {
        onUpdate(mSelectedData.map(({ idx }) => idx), { tracked: false });
        setIsSubmitting(false);
        selectedDataRef.current = [];
      }});
  };

  return (
    <>
      <div className={classes.buttonsContainer}>
        <Button
          color="primary"
          variant="contained"
          onClick={handleClickTrackMetric}
          disabled={isSubmitting || !auth.isGranted({ permission: 'esg_metrics:edit'})}
          style={{ marginRight: '1.2rem' }}
        >
          Track Metric
        </Button>
        <Button
          color="primary"
          variant="outlined"
          onClick={handleClickUnTrackMetric}
          disabled={isSubmitting || !auth.isGranted({ permission: 'esg_metrics:edit'})}
        >
          Untrack Metric
        </Button>
      </div>
    </>
  );
}
