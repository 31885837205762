import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  StandardProps,
  Typography,
} from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/styles';
import * as Sentry from '@sentry/browser';
import React from 'react';
import { IPolicyDocDto } from '../../../../backend/src/policy-doc/interfaces';
import { errorParser } from '../../helpers';
import API from '../../services/ApiService';
import { showErrorResultBar, showSuccessResultBar } from '../ResultSnackbar';
import { RouterLink } from '../RouterLink';
import StyledDialogTitle from '../StyledDialogTitle';

const useStyles = makeStyles({
  leftIcon: {
    marginRight: '0.5rem',
  },
});

export interface PolicyDiscardDialogProps extends StandardProps<DialogProps, 'children'> {
  onClose: () => void;
  onUpdate: (newData: IPolicyDocDto) => void;
  policy: IPolicyDocDto;
}

export default function PolicyDiscardDialog({ onClose, onUpdate, open, policy }: PolicyDiscardDialogProps) {
  const classes = useStyles();

  const handleDiscardPolicyVersion = async () => {
    try {
      const res = await API.delete(`policyDoc/${policy.versionId}`);
      onUpdate(res.data.data);
      showSuccessResultBar('Policy reverted to last approved version');
    } catch (err) {
      showErrorResultBar(errorParser(err, 'Unexpected error while discarding policy draft'));
      Sentry.captureException(err);
    } finally {
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <StyledDialogTitle>Delete this version of the {policy.name}?</StyledDialogTitle>
      <DialogContent>
        <Typography
          variant="body1"
          gutterBottom
        >
          Any changes you have made will be lost.
          The policy will revert to the last approved version.
        </Typography>
        {!!policy.customPolicy &&
        <Typography
          variant="body1"
        >
          The custom policy file associated with this version will be archived.
          You will need to <RouterLink to="/support">contact us</RouterLink> in
          order to retrieve it.
        </Typography>
        }
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          size="small"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={handleDiscardPolicyVersion}
        >
          <DeleteIcon className={classes.leftIcon} />
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
