import React, { useEffect, useState } from 'react';
import { Document, Page, Outline } from 'react-pdf/dist/esm/entry.webpack';
import {
  Box,
  Button,
  Chip,
  Grid,
  Paper,
  Typography,
  withStyles,
} from '@material-ui/core';
import { formatFileTitle } from '../helpers';
import API from '../services/ApiService';
import * as DocService from '../services/DocService';
import { showErrorResultBar } from '../components/ResultSnackbar';
import * as Sentry from '@sentry/browser';

const styles = (theme) => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    marginBottom: theme.spacing(5),
    marginTop: theme.spacing(2),
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginLeft: theme.spacing(1),
    },
    '&:hover': {
      '& > *': {
        transform: 'translateX(10px)',
        transition: 'all 0.2s ease-out',
      },
    },
  },
  tagChip: {
    margin: '4px',
    color: theme.palette.primary.main,
    background: theme.palette.grey[200],
  },
});

function ThePdfViewPage({ auth, match, classes }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfFile, setPdfFile] = useState();
  const [metadata, setMetadata] = useState();
  const userDoc = match.params.doc;
  const [loadingFailed, setLoadingFailed] = useState(false);

  useEffect(() => {
    async function fetchData() {
      let pdfPayload;
      if (match.path.includes('policies')) {
        pdfPayload = await DocService.downloadPolicyPDF(match.params.policy);
      } else if (match.path.includes('evidence')) {
        if (
          auth.isGranted({ permission: 'evidence:view' }) ||
          auth.isGranted({ permission: 'evidence:download' })
        ) {
          pdfPayload = await DocService.downloadEvidencePDF(userDoc);
        } else {
          setLoadingFailed(true);
        }
      } else if (match.path.includes('deliverables')) {
        if (
          auth.isGranted({ permission: 'documents:view' }) ||
          auth.isGranted({ permission: 'documents:download' })
        ) {
          pdfPayload = await DocService.downloadDocumentVersionPDF(
            match.params.document
          );
        } else {
          setLoadingFailed(true);
        }
      } else if (match.path.includes('resources')) {
        if (
          auth.isGranted({ permission: 'resources:view' }) ||
          auth.isGranted({ permission: 'resources:download' })
        ) {
          pdfPayload = await DocService.viewResourcePDF(
            match.params.resource
          );
        } else {
          setLoadingFailed(true);
        }
      }
      setPdfFile(pdfPayload);
    }

    async function fetchFileMetadata() {
      if (match.path.includes('policies')) {
        API.get('policyDoc').then((res) => {
          let obj = res.data.data.find((o) => o.id === match.params.policy);
          setMetadata(obj);
        });
      } else if (match.path.includes('evidence')) {
        const metaData = await API.get(`document/evidenceDetails/${userDoc}`);
        setMetadata(metaData.data.data);
      }
    }
    fetchFileMetadata();
    fetchData();
  }, []);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function onItemClick({ pageNumber: itemPageNumber }) {
    setPageNumber(itemPageNumber);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const handleError = () => {
    const getExtenstion = /(?:\.([^.]+))?$/;
    const ext = getExtenstion.exec(pdfFile)[1];

    return (
      <Box
        sx={{
          display: 'block',
          textAlign: 'center',
        }}
      >
        <Typography color="textSecondary" variant="body1" display="block">
          Failed to load the document.
        </Typography>
        <Typography color="textSecondary" variant="body1" display="block">
          Your document is in <code>.{ext}</code> format, this feature currently
          support PDF Files only.
        </Typography>
      </Box>
    );
  };

  function removeTextLayerOffset() {
    const textLayers = document.querySelectorAll(
      '.react-pdf__Page__textContent'
    );
    textLayers.forEach((layer) => {
      const { style } = layer;
      style.top = '0';
      style.left = '0';
      style.transform = '';
    });
  }

  if (pdfFile) {
    return (
      <>
        {metadata && (
          <Paper className={classes.root}>
            <Grid item>
              <Typography
                color="primary"
                variant="h6"
                className={classes.title}
              >
                {formatFileTitle(metadata.name)}
                {metadata.documentTags &&
                  metadata.documentTags.map((tag, index) => (
                    <Chip
                      key={index}
                      className={classes.tagChip}
                      label={tag.name}
                      size="small"
                    />
                  ))}
              </Typography>
              <Typography color="textPrimary" variant="subtitle1" gutterBottom>
                {metadata.status === 'active' ? (
                  <>
                    <span>Status: Not Started</span>
                  </>
                ) : null}
                {metadata.status === 'in_progress' ? (
                  <>
                    <span>Status: In Progress</span>
                  </>
                ) : null}
                {metadata.status === 'completed' ? (
                  <>
                    <Typography component="span" color="primary">
                      Status: Completed by: {metadata.updatedByName}
                    </Typography>
                  </>
                ) : null}
                {metadata.status === 'review' ? (
                  <>
                    <span>Status: Need Review</span>
                  </>
                ) : null}
              </Typography>
              {metadata.assigneeName ? (
                <>
                  <Typography
                    color="textSecondary"
                    variant="caption"
                    display="block"
                  >
                    Assigned to:
                    {metadata.assigneeName}
                  </Typography>
                </>
              ) : (
                <Typography
                  color="textSecondary"
                  variant="caption"
                  display="block"
                >
                  Unassigned
                </Typography>
              )}
            </Grid>
          </Paper>
        )}
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <Box
            style={{
              maxHeight: metadata ? '55vh' : '75vh' ,
              overflow: 'scroll',
              overflowX: 'hidden',
            }}
          >
            <Document
              file={pdfFile}
              onLoadSuccess={onDocumentLoadSuccess}
              onContextMenu={(e) => e.preventDefault()}
              error={handleError}
            >
              <Outline onItemClick={onItemClick} />
              <Page
                pageNumber={pageNumber}
                width={1000}
                onLoadSuccess={removeTextLayerOffset}
              />
            </Document>
          </Box>
          {pdfFile && (
            <Grid item xs={12} sx={{ px: 'auto' }}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
              >
                <div>
                  <p>
                    Page {pageNumber || (numPages ? 1 : '--')} of{' '}
                    {numPages || '--'}
                  </p>
                </div>
                <div>
                  <Button
                    variant="outlined"
                    color="primary"
                    type="button"
                    disabled={pageNumber <= 1}
                    onClick={previousPage}
                  >
                    Previous
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    disabled={pageNumber >= numPages}
                    onClick={nextPage}
                  >
                    Next
                  </Button>
                </div>
              </Grid>
            </Grid>
          )}
        </Grid>
      </>
    );
  }
  if (loadingFailed) {
    return (
      <Box
        sx={{
          display: 'block',
          textAlign: 'center',
        }}
      >
        <Typography color="textSecondary" variant="body1" display="block">
          You do not have access to view this file.
        </Typography>
        <Typography color="textSecondary" variant="body1" display="block">
          Contact your admin to update your permissions if you need access.
        </Typography>
      </Box>
    );
  } else {
    return <div>Loading...</div>;
  }
}

ThePdfViewPage.title = 'View PDF';
ThePdfViewPage.requiredAuthZ = {
  tier: 1,
  permission: 'documents',
};

export default withStyles(styles, { withTheme: true })(ThePdfViewPage);
