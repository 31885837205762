import {
  Button,
  Accordion,
  AccordionActions,
  AccordionDetails,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import * as Sentry from '@sentry/browser';
import moment from 'moment';
import React from 'react';
import { IVendorDto, IVendorQuestionnaireDto } from '../../../backend/src/vendor/interfaces';
import { formatDate } from '../helpers';
import API from '../services/ApiService';
import AccordionIconSummary from './AccordionIconSummary';
import { showErrorResultBar, showSuccessResultBar } from './ResultSnackbar';
import { VENDOR_QUESTIONS } from './VendorQuestionnaireDetails';
import { handleError } from '../helpers';

const useStyles = makeStyles({
  questionnaireEntryHeading: {
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  questionnaireQuestion: {
    fontWeight: 'bold',
  },
  questionnaireAnswer: {
    marginInlineStart: '1rem',
  },
  vendorResponseText: {
    color: 'blue',
  },
  questionnaireEntryActions: {
    justifyContent: 'flex-start',
    paddingTop: 0,
  },
});

const questionnaireStatusMap = {
  open: 'Awaiting Response',
  in_progress: 'In Progress',
  closed: 'Response received',
  canceled: 'Canceled',
};

const isQuestionnaireCanceled = (questionnaire: IVendorQuestionnaireDto) => {
  return questionnaire.status === 'canceled';
};

const isQuestionnaireExpired = (questionnaire: IVendorQuestionnaireDto) => {
  return moment().isAfter(questionnaire.expiresAt);
};

const isQuestionnairePending = (questionnaire: IVendorQuestionnaireDto) => {
  return questionnaire.status === 'open' || questionnaire.status === 'in_progress';
};

const getQuestionnaireLinkInfoText = (questionnaire: IVendorQuestionnaireDto) => {
  if (isQuestionnaireCanceled(questionnaire)) {
    return 'Link canceled';
  } else if (isQuestionnaireExpired(questionnaire)) {
    return 'Link expired:';
  } else {
    return 'Link expires:';
  }
};

const getQuestionnaireSubheading = (questionnaire: IVendorQuestionnaireDto) => {
  const statusStr = questionnaireStatusMap[questionnaire.status];

  if (isQuestionnaireCanceled(questionnaire)) {
    return `Canceled (${formatDate(questionnaire.updatedAt)})`;
  } else if (questionnaire.status === 'closed') {
    const updatedAt = questionnaire.response && questionnaire.response.updatedAt;
    const updatedAtStr = !!updatedAt ? ` (${formatDate(updatedAt)})` : '';

    return `${statusStr}${updatedAtStr}`;
  } else if (isQuestionnaireExpired(questionnaire)) {
    return `Canceled (link expired on ${formatDate(questionnaire.expiresAt)})`;
  } else if (questionnaire.status === 'in_progress') {
    const updatedAt = questionnaire.updatedAt;
    const updatedAtStr = !!updatedAt ? ` (accessed on ${formatDate(updatedAt)})` : '';

    return `${statusStr}${updatedAtStr}`;
  } else {
    return statusStr;
  }
};

export interface VendorQuestionnaireResponseProps {
  onUpdateVendor: (newVendorData: IVendorDto) => void;
  questionnaire: IVendorQuestionnaireDto;
  vendorData: IVendorDto;
}

export default function VendorQuestionnaireResponse({ onUpdateVendor, questionnaire, vendorData }: VendorQuestionnaireResponseProps) {
  const classes = useStyles();

  const handleCancelQuestionnaire = async () => {
    try {
      const res = await API.patch(`vendor/${vendorData.id}/cancel-questionnaire/${questionnaire.id}`);
      const updatedVendor: IVendorDto = res.data.data;
      const updatedQuestionnaire = updatedVendor.questionnaires.find(q => q.id === questionnaire.id);

      if (updatedQuestionnaire && updatedQuestionnaire.status === 'canceled') {
        showSuccessResultBar('Questionnaire link canceled successfully');
      }

      onUpdateVendor(updatedVendor);
    } catch (err) {
      handleError(err, 'Unexpected error while canceling questionnaire link');
    }
  };

  return (
    <Accordion
      elevation={2}
    >
      <AccordionIconSummary>
        <Typography
          className={classes.questionnaireEntryHeading}
          variant="body2"
        >
          Sent ({formatDate(questionnaire.createdAt)})
        </Typography>
        <Typography variant="body2">
          {getQuestionnaireSubheading(questionnaire)}
        </Typography>
      </AccordionIconSummary>

      <AccordionDetails>
        <Grid container>
          <Grid
            item
            xs={12}
          >
            <Typography
              className={classes.questionnaireQuestion}
            >
              Sent to:
            </Typography>
            <Typography
              className={classes.questionnaireAnswer}
            >
              {questionnaire.sentTo}
            </Typography>
          </Grid>
          { !!questionnaire.response ? (
              <>
                {VENDOR_QUESTIONS.map(question => {
                  return (
                    <Grid
                      item
                      xs={12}
                      key={question.id}
                    >
                      <Typography
                        className={classes.questionnaireQuestion}
                      >
                        {question.text}
                      </Typography>
                      <Typography
                        gutterBottom
                        className={classes.questionnaireAnswer}
                      >
                        {!!question.label && `${question.label}: ` }
                        <span className={classes.vendorResponseText}>
                          {questionnaire.response.response[question.id]}
                        </span>
                      </Typography>
                    </Grid>
                  );
                })}
              </>
              ) : (
                <>
                  <Grid
                    item
                    xs={12}
                  >
                    <Typography
                      className={classes.questionnaireQuestion}
                    >
                      {getQuestionnaireLinkInfoText(questionnaire)}
                    </Typography>
                    {isQuestionnairePending(questionnaire) && (
                      <Typography
                        gutterBottom
                        className={classes.questionnaireAnswer}
                      >
                        {moment(questionnaire.expiresAt).fromNow()}
                      </Typography>
                    )}
                  </Grid>
                </>
              )
            }
        </Grid>
      </AccordionDetails>
      {!isQuestionnaireExpired(questionnaire) && isQuestionnairePending(questionnaire) && (
        <AccordionActions
          className={classes.questionnaireEntryActions}
        >
          <Button
            size="small"
            color="primary"
            onClick={handleCancelQuestionnaire}
          >
            Cancel link
          </Button>
        </AccordionActions>
      )}
    </Accordion>
  );
}
