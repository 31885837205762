import * as Sentry from '@sentry/browser';
import { useState } from 'react';
import { IStakeholderDto } from '../../../backend/src/stakeholder/interfaces';
// import { StakeholderQuestionnaireTemplate} from '../../../backend/src/stakeholder/stakeholder-questionnaire-template.entity';
import API from '../services/ApiService';
import Auth from '../services/AuthService';
import { ArchiveDialog, StakeholderEditDialog, StakeholderSelfFillDialog, StakeholderSendQuestionnaireDialog } from './dialogs';
import { showErrorResultBar, showSuccessResultBar } from './ResultSnackbar';
import StakeholderDetailsInfo from './StakeholderDetailsInfo';
import { handleError } from '../helpers';

export interface StakeholderDetailsProps {
    onArchive: () => void;
    onUpdateStakeholder: (newStakeholderData: IStakeholderDto) => void;
    stakeholderData: IStakeholderDto;
    questionnaireTemplates: Array<any>;
    auth: Auth;
}

export default function StakeholderDetails({ onArchive, onUpdateStakeholder, stakeholderData, questionnaireTemplates, auth }: StakeholderDetailsProps) {
    const [toShowArchiveDialog, setToShowArchiveDialog] = useState(false);
    const [toShowEditDialog, setToShowEditDialog] = useState(false);
    const [toShowSelfFillDialog, setToShowSelfFillDialog] = useState(false);
    const [toShowSendQuestionnaireDialog, setToShowSendQuestionnaireDialog] = useState(false);

    const handleCloseSelfFillDialog = (updatedStakeholder?: IStakeholderDto) => {
        setToShowSelfFillDialog(false);

        if (updatedStakeholder) {
            onUpdateStakeholder(updatedStakeholder);
        }
    };

    const handleArchive = async () => {
        stakeholderData.status = 'archived';

        try {
            await API.patch(`stakeholder/${stakeholderData.id}`, stakeholderData);
            onArchive();
            showSuccessResultBar('Entry archived');
        } catch (err) {
            handleError(err, 'Error archiving entry');
        } finally {
            setToShowArchiveDialog(false);
        }
    };

    return !stakeholderData ? null : (
        <>
            <StakeholderDetailsInfo
                onClickArchive={() => setToShowArchiveDialog(true)}
                onClickEdit={() => setToShowEditDialog(true)}
                onClickSelfFillQuestionnaire={() => setToShowSelfFillDialog(true)}
                onClickSendQuestionnaire={() => setToShowSendQuestionnaireDialog(true)}
                onUpdateStakeholder={onUpdateStakeholder}
                stakeholderData={stakeholderData}
                auth={auth}
            />
            <ArchiveDialog
                open={toShowArchiveDialog}
                onArchive={handleArchive}
                onClose={() => setToShowArchiveDialog(false)}
                title={`Archive the stakeholder '${stakeholderData?.stakeholderName}'?`}
            />
            <StakeholderEditDialog
                onClose={() => setToShowEditDialog(false)}
                onSave={onUpdateStakeholder}
                open={toShowEditDialog}
                stakeholderData={stakeholderData}
            />
            <StakeholderSelfFillDialog
                open={toShowSelfFillDialog}
                handleClose={handleCloseSelfFillDialog}
                stakeholder={stakeholderData}
                questionnaireTemplates={questionnaireTemplates}
            />
            <StakeholderSendQuestionnaireDialog
                open={toShowSendQuestionnaireDialog}
                onClose={() => setToShowSendQuestionnaireDialog(false)}
                onUpdateStakeholder={onUpdateStakeholder}
                stakeholderData={stakeholderData}
                questionnaireTemplates={questionnaireTemplates}
            />
        </>
    );
}
