import {
  createStyles,
  Button,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Link,
  Popper,
  TextField,
  Theme,
  Tooltip,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import { PopperProps } from '@material-ui/core/Popper';
import EditIcon from '@material-ui/icons/Edit';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import classnames from 'classnames';
import { EditorView } from 'prosemirror-view';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { getMarkRange } from '../lib/getMarkRange';
import { isLinkActive } from '../lib/markdownMenuItems';
import { normalizeLink } from '../lib/normalizeLink';

const styles = (theme: Theme) => createStyles({
  popper: {
    zIndex: theme.zIndex.modal,
  },
  hidden: {
    display: 'none',
  },
  link: {
    width: '100%',
    maxWidth: '500px',
    marginRight: '1rem',
    fontWeight: 500,
    textOverflow: 'ellipsis',
  },
  card: {
    margin: 0,
    padding: 0,
    paddingRight: '0.5rem',
    paddingLeft: '0.5rem',
    minWidth: '450px',
  },
  editLinkCardContent: {
    paddingLeft: 0,
  },
  iconButton: {
    padding: '0.5rem',
    margin: 0,
  },
  icon: {
    fontSize: '1.5rem',
  },
});

// When a user clicks on an existing link, then we want a Popper to appear just under (or above) the link.
// One issue is that we can't rely on the window.selection to define the anchorEl for the Popper, b/c the
// user probably won't have selected the entire link. (And we want the Popper's left edge stuck at the beginning of the link.)
// B/c of this, we have to handle updating the Popper's vertical position (and hiding it, if necessary) when the container scrolls.
// TODO: Investigate setting the window.selection dynamically based on the mark selection and using that as the anchorEl.
//
// Note that Material-ui is still using Popper v1, which is deprecated.
// Popper v2 would seem to make some of this easier (auto-hiding, setting a diff't parent container), via the popperOptions.
// Popper v1's options didn't seem to work well here. Trying the following led to a 'bound is undefined' error:
  // popperOptions={container ? {
  //   modifiers: [
  //     {
  //       name: 'preventOverflow',
  //       options: {
  //         boundariesElement: container,
  //       },
  //     },
  //   ],
  // } : {}}
// TODO: Try to integrate the actual PopperJS, rather than using Material-ui's wrapper.

export interface ExistingLinkDialogProps extends WithStyles<typeof styles> {
  container: HTMLDivElement | null;
  view: EditorView;
}

function ExistingLinkDialog({ classes, container, view }: ExistingLinkDialogProps) {
  const [ isLink, setIsLink ] = useState(false);
  const [ href, setHref ] = useState('');
  const [ markFrom, setMarkFrom ] = useState(0);
  const [ anchorEl, setAnchorEl ] = useState<PopperProps['anchorEl']>(null);
  const [ scrollTop, setScrollTop ] = useState(0);
  const [ toHide, setToHide ] = useState(false);
  const [ isEditing, setIsEditing ] = useState(false);
  const [ inputHref, setInputHref ] = useState('');

  const { dispatch, state } = view;

  // The Rect bounding the entire link (not just the cursor-selected area).
  const getBoundingClientRectForLink = useCallback((): any => {
    // TODO: fix this any, it used to be ClientRect
    const { left, right, top, bottom } = view.coordsAtPos(markFrom);

    // Popper expects a ClientRect, but ProseMirror's 'coordsAtPos' leaves off the height and width.

    return { left, right, top, bottom, height: bottom - top, width: right - left };
  }, [ markFrom, view ]);

  // Every time the cursor changes, check to see if it's on a link.
  // Note that 'isLinkActive' currently only returns true for empty selections w/in links (see the note at its def'n).
  useEffect(() => {
    const mIsLink = !!isLinkActive(state);
    setIsLink(mIsLink);
  }, [ state ]);

  // If the cursor is on a link, then store the href for that link and calculate the
  // starting position of the link (stored in 'markFrom'):
  useEffect(() => {
    if (isLink) {
      const sel = state.selection;
      const range = getMarkRange(sel.$from, state.schema.marks.link);
      if (range && range.mark) {
        setHref(normalizeLink(range.mark.attrs.href));
        setMarkFrom(range.from + 1); // offset a little else the pos'n may be wrong for links at the beginning of lines
      }
    }
  }, [ isLink, state ]);

  // The ClientRect passed to Popper. It shouldn't change if we click around within the same link,
  // but it should change if we click on a different link. (Note that isLink won't change in that
  // case -- ie, clicking from one link to another.)
  // Note that we need to recalculate it after scrolling, else it stays stuck to the same window location.
  useEffect(() => {
    setAnchorEl({
      clientHeight: getBoundingClientRectForLink().height,
      clientWidth: getBoundingClientRectForLink().width,
      getBoundingClientRect: getBoundingClientRectForLink,
    });
  }, [ scrollTop, getBoundingClientRectForLink ]);

  // The Popper doesn't know to hide itself when it scrolls out of view, so we take care of it by hand
  // by changing its css 'display' to 'none'.
  useEffect(() => {
    if (!container || !isLink) {
      return;
    }

    // We could cache the container's top/bottom, but we'd need to check for window resize events to know to update it.
    const { top: containerTop, bottom: containerBottom } = container.getBoundingClientRect();
    const { top: linkTop, bottom: linkBottom } = getBoundingClientRectForLink();

    if (!toHide && (linkTop < containerTop || linkBottom > containerBottom)) {
      setToHide(true);
    } else if (toHide && (linkTop > containerTop && linkBottom < containerBottom)) {
      setToHide(false);
    }
  }, [ isLink, toHide, getBoundingClientRectForLink, container, scrollTop ]);

  // Add an 'onscroll' handler to the containing div, so that as it scrolls we can update
  // the position of the Popper (else it stays in a fixed position relative the window).
  // Because this can make scrolling a little janky, only add this handler when we're actually
  // on a link, and remove it when we're not.
  useEffect(() => {
    if (!container) {
      return;
    }

    // Remove any existing 'onscroll' handler on the containing div:
    if (!!container && !!container.onscroll) {
      container.onscroll = null;
    }

    // If a link is now selected, add an 'onscroll' handler to be able to update
    // its absolute position as the container scrolls.
    if (!!container && !container.onscroll && isLink) {
      container.onscroll = () => setScrollTop(container.scrollTop);
    }
  }, [ container, isLink ]);

  // Handles the case that we click outside the link
  // (clicking onto a separate link is a special case that's handled by 'markFrom', below).
  useEffect(() => {
    if (!isLink) {
      handleCancelEditLink();
    }
  }, [ isLink ]);

  // Handles the case that we click from one link to a different link
  // (ie, isLink doesn't change, but the position (markFrom) does).
  useEffect(() => {
    handleCancelEditLink();
  }, [ markFrom ]);

  const handleClickEditLink = () => {
    setInputHref(href);
    setIsEditing(true);
  };

  const handleCancelEditLink = () => {
    setIsEditing(false);
    setInputHref('');
  };

  const handleChangeInputLink = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setInputHref(e.target.value.trim());
  };

  const handleApplyInputLink = () => {
    const sel = state.selection;
    const markType = state.schema.marks.link;
    const range = getMarkRange(sel.$from, markType);

    if (range) {
      const { from, to, mark } = range;

      dispatch(
        state.tr
          .removeMark(from, to, mark)
          .addMark(from, to, markType.create({ href: inputHref })),
      );
    }

    setIsEditing(false);
  };

  const handleRemoveLink = () => {
    const sel = state.selection;
    const range = getMarkRange(sel.$from, state.schema.marks.link);

    if (range) {
      const { from, to, mark } = range;
      dispatch(state.tr.removeMark(from, to, mark));
    }
  };

  return (
    <Popper
      className={classnames(classes.popper, toHide && classes.hidden)}
      anchorEl={anchorEl}
      open={isLink}
      placement="bottom-start"
    >
      <Card
        className={classes.card}
      >
        {isEditing ? (
          <>
            <CardContent
              className={classes.editLinkCardContent}
            >
              <TextField
                id="input-href"
                autoFocus
                value={inputHref}
                fullWidth
                margin="dense"
                label="Link"
                placeholder="Enter a link"
                variant="outlined"
                onChange={handleChangeInputLink}
              />
            </CardContent>
            <CardActions>
              <Button
                  color="primary"
                  size="small"
                  onClick={handleCancelEditLink}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  size="small"
                  variant="contained"
                  onClick={handleApplyInputLink}
                  disabled={!inputHref}
                >
                  Apply
                </Button>
            </CardActions>
          </>
        ) : (
          <CardActions>
            <Link
              className={classes.link}
              color="inherit"
              href={href}
              target="_blank"
              rel="noopener noreferrer"
            >
              {href}
            </Link>
            <Tooltip title="Edit link">
              <IconButton
                className={classes.iconButton}
                onClick={handleClickEditLink}
              >
                <EditIcon className={classes.icon} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Remove link">
              <IconButton
                className={classes.iconButton}
                onClick={handleRemoveLink}
              >
                <LinkOffIcon className={classes.icon} />
              </IconButton>
            </Tooltip>
          </CardActions>
        )}
      </Card>
    </Popper>
  );
}

export default withStyles(styles, { withTheme: true })(ExistingLinkDialog);
