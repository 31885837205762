import {
  Button,
  Chip,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
  } from '@material-ui/core';
import { FormGridRow, FormikTextField, FormikRadioField } from '../forms';
import React, { ReactElement, useState } from 'react';
import { DialogProps } from '@material-ui/core/Dialog';
import { Autocomplete } from '@material-ui/lab';
import ResponsiveDialog from './ResponsiveDialog';
import StyledDialogTitle from '../StyledDialogTitle';
import { Formik } from 'formik';
import SaveButton from '../buttons/SaveButton';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/styles';
import { showErrorResultBar, showSuccessResultBar } from '../ResultSnackbar';
import * as Sentry from '@sentry/browser';
import API from '../../services/ApiService';
import { IIdNameDto } from '../../../../backend/src/common/id-name-dto.interface';
import {
  ITaskGroupDto,
  ITaskDto,
} from '../../../../backend/src/task/interfaces';
import { DatePicker } from '@material-ui/pickers';
import moment, { Moment } from 'moment';
import { handleError } from '../../helpers';

const useStyles = makeStyles({
    root: {
        margin: 0,
        padding: 0
    },
    switchField: {
      marginLeft: 0,
      marginRight: 0,
    },
    inputField: {
        marginRight: 0,
        marginLeft: 0,
      },
    tagField: {
        marginLeft: -10,
        marginRight: 0,
      },
    selectContainer: {
        marginTop: 35,
      },
  });

export const HELPER_TEXT = {
    name: 'Name of the task.',
    description: 'Description, instructions, etc. (Supports HTML tags).',
    tags: 'Tags associated with task.',
    group: 'Select the group of tasks that the new task belongs to.',
    collection: 'Select the collection of tasks that the new task belongs to.',
    nextTask: 'Select the existing task that the new task should come before or after.'
};

export interface AddTaskDialogProps extends DialogProps {
  children?: string | ReactElement;
  title?: string;
  onSave: (newTask: ITaskDto) => Promise<void> | void;
  onClose: () => void;
  open: boolean;
  collections: IIdNameDto[];
  groups: ITaskGroupDto[];
  tagOptions: IIdNameDto[];
  taskData: ITaskDto | null;
  tasks: ITaskDto[];
  collapseRows: () => void;

}

const getTaskSet = (tasks: ITaskDto[], collection: IIdNameDto | string | null, groupId: string | null) => {
  if (collection && groupId) {
    if (collection instanceof Object) {
      return tasks.filter(t => t.group?.id === groupId && t.collection?.id === collection.id).filter(t => t.status !== 'not_relevant').map(task => Object({id: task.id, name: task.name}));
    } else {
      return [];
    }
  } else {
    return [];
  }
};

const initCollection = (collections: IIdNameDto[], taskData: ITaskDto | null) => {
  return taskData?.collection ? collections[collections.map(c => c.id).indexOf(taskData?.collection?.id || '')] : '';
};

const initGroup = (groupSet: ITaskGroupDto[], taskData: ITaskDto | null) => {
  const groupIdx = groupSet.map(g => g.id).indexOf(taskData?.group?.id || '');
  if (groupIdx > -1) {
    return groupSet[groupIdx];
  } else {
    return '';
  }
};

const initGroupSet = (groups: ITaskGroupDto[], taskData: ITaskDto | null) => {
  if (taskData?.collection) {
    return groups.filter(g => g.taskCollection?.id === taskData.collection?.id);
  } else {
    return [];
  }
};

const initTaskSet = (tasks: ITaskDto[], taskData: ITaskDto | null) => {
  if (taskData?.collection && taskData?.group) {
    return (getTaskSet(tasks, taskData.collection, taskData.group?.id));
  } else {
    return [];
  }
};

const initNextTask = (taskSet: IIdNameDto[], taskData: ITaskDto | null) => {
    const taskIdx = taskSet.map(t => t.id).indexOf(taskData?.id || '');
    if (taskIdx < 0) {
      return '';
    } else if (taskIdx === 0) {
      return taskSet[1];
    } else {
      return taskSet[taskIdx - 1];
    }
};

const initTaskOrder = (taskSet: IIdNameDto[] | '', taskData: ITaskDto | null) => {
  if (taskData && taskSet instanceof Array) {
    const taskIdx = taskSet.map(t => t.id).indexOf(taskData.id);
    if (taskIdx < 1) {
      return 'before';
    } else {
      return 'after';
    }
  } else {
    return 'after';
  }
};

function AddTaskDialog({
  children,
  collections,
  groups,
  onSave,
  onClose,
  open,
  tagOptions,
  taskData,
  tasks,
  title,
  collapseRows,
  ...dialogProps
}: AddTaskDialogProps) {
  const classes = useStyles();
  const [ tags, setTags ] = useState<IIdNameDto[]>(taskData?.taskTags || [] );
  const [ groupSet, setGroupSet ] = useState<ITaskGroupDto[]>(taskData ? initGroupSet(groups, taskData) : []);
  const [ taskSet, setTaskSet ] = useState<IIdNameDto[]>(taskData ? initTaskSet(tasks, taskData) : []);
  const [ dueDate, setDueDate ] = useState<Moment | null>(taskData && taskData.dueDate ? moment(taskData.dueDate) : null);
  const [ startDate, setStartDate ] = useState<Moment | null>(taskData && taskData.startDate ? moment(taskData.startDate) : null);
  const [ showSubmitErrors, setShowSubmitErors ] = useState<boolean>(false);

  const taskSchema = Yup.object().shape({
    name: Yup.string().max(100, 'Maximum of 100 characters').required('Required'),
    description: Yup.string().required('Required'),
    collection: Yup.mixed().test(
      'collection-validation',
      'Please select a valid collection.',
      async function (value: any) {
        if (value && collections.map(c => c.id).includes(value.id)) {
          return true;
        }
        return this.createError({
          path: 'collection',
          message: 'Please select a valid collection.',
        });
      }
    )
    .required('Select a collection.'),
    group: Yup.mixed().test(
      'group-validation',
      'Please select a valid group.',
      async function (value: any) {
        if (value && groups.map(g => g.id).includes(value.id)) {
          return true;
        }
        return this.createError({
          path: 'group',
          message: 'Please select a valid group.',
        });
      }
    ),
    startDate: Yup.date().max(
      Yup.ref('dueDate'),
      'Start date must be before due date.'
    ),
    dueDate: Yup.date().min(
        Yup.ref('startDate'),
        'Due date must be after start date.'
      )
  })
  .test({
    test: async function(values: Record<string, any>) {
      let isValid = false;
      if (getTaskSet(tasks, values.collection, values.group?.id).filter(t => t.id !== taskData?.id).length > 0) {
        if ((values.nextTask) !== '' && (values.nextTask) !== undefined) {
            isValid = true;
        }
      } else {
        isValid = true;
      }

      if(isValid) return true;
      return this.createError({
        path: 'nextTask',
        message: 'Please select the existing task that the new task with preceed or follow.',
      });
    }
  });

  const handleSave = async (formValues: Record<string, any>) => {
    const taskForm = {
      description: formValues.description,
      name: formValues.name,
      nextTask: formValues.nextTask?.id || null,
      group: formValues.group,
      collection: taskData ? taskData.collection : formValues.collection,
      taskOrder: formValues.taskOrder,
      taskTags: tags,
      dueDate: dueDate,
      startDate: startDate
    };

    try {
      const res = taskData === null ?
        (await API.post('task', taskForm)).data.data :
        (await API.patch(`task/${taskData.id}`, taskForm)).data.data;
      onSave(res);
      showSuccessResultBar('Task saved successfully');
    } catch (err) {
      handleError(err, 'Error saving task');
    }
  };

  const invalidForm = () => {
    setShowSubmitErors(true);
    showErrorResultBar('One or more errors on form.');
  };

  const resetForm = () => {
    setTags(taskData?.taskTags || []);
    setDueDate(taskData && taskData.dueDate? moment(taskData.dueDate) : null);
    setStartDate(taskData && taskData.startDate ? moment(taskData.startDate) : null);
    setShowSubmitErors(false);
  };

  const handleClose = () => {
    resetForm();
    onClose();
    collapseRows();
  };

  return (
    <>
    <ResponsiveDialog
      disableBackdropClick
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
    >
      <StyledDialogTitle onClose={onClose}>
        {taskData === null ? 'Create new task' : 'Edit task'}
      </StyledDialogTitle>
      <Formik
        enableReinitialize={taskData === null}
        initialValues={{
          name: taskData ? taskData.name : '',
          description: taskData ? taskData.description : '',
          group: initGroup(groupSet, taskData),
          collection: initCollection(collections, taskData),
          nextTask: initNextTask(taskSet, taskData),
          taskOrder: initTaskOrder(taskSet, taskData),
        }}
        validationSchema={taskSchema}
        onSubmit={async (values, { setSubmitting }) => {
          await handleSave(values);
          setSubmitting(false);
          handleClose();
        }}
      >
        {formikProps => (
          <>
            <DialogContent>
              <Grid container>
                <FormGridRow
                  divider
                  title="Task Information"
                  subtitle="Standard information for this Task."
                >
                  <FormikTextField
                    autoFocus
                    field="name"
                    formikProps={formikProps}
                    helperTextStr={HELPER_TEXT.name}
                    label="Name"
                    required
                  />
                  <FormikTextField
                    autoFocus
                    multiline
                    minRows={2}
                    maxRows={5}
                    field="description"
                    formikProps={formikProps}
                    helperTextStr={HELPER_TEXT.description}
                    label="Description"
                    required
                  />
                </FormGridRow>
                <FormGridRow
                  divider
                  title="Task Order"
                  subtitle="Select the group and task that the new task will come before or after (if applicable)."
                >
                  {!taskData &&
                  <FormControl
                    className={classes.switchField}
                    variant={'filled'}
                  >
                      <InputLabel>
                        {'Collection'}
                      </InputLabel>
                      <Select
                        id={'Collection'}
                        name={'collection'}
                        value={formikProps.values['collection']}
                        onChange={(e: any) =>
                            {
                              setGroupSet(groups.filter(g => g.taskCollection?.id === e.target.value.id || ''));
                              formikProps.setValues({
                                ...formikProps.values,
                                'collection': e.target.value,
                                'group': '',
                                'nextTask': ''
                            });
                            }}
                        fullWidth
                      >
                        {collections.map((item) => (
                          <MenuItem value={item as any} key={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {HELPER_TEXT.collection ? <FormHelperText>{HELPER_TEXT.collection}</FormHelperText> : null}
                      {formikProps.errors.collection && showSubmitErrors ? <FormHelperText error={true}>{formikProps.errors.collection}</FormHelperText> : null}
                    </FormControl>
                  }
                    <FormControl
                      className={classes.switchField}
                      variant={'filled'}
                    >
                      <InputLabel>
                        {'Group'}
                      </InputLabel>
                      <Select
                        id={'Group'}
                        name={'group'}
                        value={formikProps.values['group']}
                        onChange={(e: any) =>
                            {
                              formikProps.setValues({
                                ...formikProps.values,
                                'group': e.target.value,
                                'nextTask': ''
                              });
                              setTaskSet(getTaskSet(tasks, formikProps.values.collection, e.target.value.id));
                            }}
                        fullWidth
                      >
                        {groupSet.map((item) => (
                          <MenuItem value={item as any} key={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {HELPER_TEXT.group ? <FormHelperText>{HELPER_TEXT.group}</FormHelperText> : null}
                      {formikProps.errors.group && showSubmitErrors ? <FormHelperText error={true}>{formikProps.errors.group}</FormHelperText> : null}
                    </FormControl>
                  {taskSet.length > 0 && !(taskSet.length === 1 && taskSet.map(t => t.id).includes(taskData?.id || '')) &&
                    <Grid container alignItems="flex-start" spacing={4}>
                      <Grid item xs={12} sm={2} md={4}>
                        <FormikRadioField
                            field="taskOrder"
                            formikProps={formikProps}
                            label="Task should be"
                            selections={{before: 'before', after: 'after'}}
                          />
                      </Grid>
                      <Grid item xs={12} sm={10} md={8} className={classes.selectContainer}>
                        <FormControl
                          className={classes.switchField}
                          variant={'filled'}
                        >
                          <InputLabel>
                            {'Existing Task'}
                          </InputLabel>
                          <Select
                            id={'nextTask'}
                            name={'nextTask'}
                            value={formikProps.values['nextTask']}
                            onChange={formikProps.handleChange}
                            fullWidth
                          >
                            {taskSet.map((item) => (
                                  <MenuItem value={item as any} key={item.id} disabled={item.id === taskData?.id}>
                                  {item.name}
                                  </MenuItem>
                             ))
                            }
                          </Select>
                          {HELPER_TEXT.nextTask ? <FormHelperText>{HELPER_TEXT.nextTask}</FormHelperText> : null}
                          {formikProps.errors.nextTask && showSubmitErrors ? <FormHelperText error={true}>{formikProps.errors.nextTask}</FormHelperText> : null}
                        </FormControl>
                      </Grid>
                    </Grid>
                  }
                </FormGridRow>
                <FormGridRow
                  divider
                  title="Optional Task Information"
                  subtitle="Additional optional information for this Task."
                >
                  <Autocomplete
                    multiple
                    id="tags-filled"
                    options={tagOptions.sort()}
                    getOptionLabel={(option) => option.name || ''}
                    getOptionSelected={(option, value) => option.name === value.name }
                    value={tags}
                    className={classes.tagField}
                    renderTags={(tagValue, getTagProps) =>
                        tagValue.map((item, index) => (
                        <Chip
                          variant="outlined"
                          key={item.id}
                          label={item.name}
                          {...getTagProps({ index })}
                        />
                    ))
                    }
                    onChange={(e, value) => {setTags(value);}}
                    renderInput={(params) => (
                      <TextField
                          {...params}
                          variant="filled"
                          label="Tags"
                          helperText={HELPER_TEXT.tags}
                      />
                    )}
                  />
                  {!taskData &&
                    <>
                      <DatePicker
                          label="Start Date"
                          placeholder="YYYY-MM-DD"
                          maxDate={dueDate ? dueDate : moment('1/1/2060', 'MM-DD-YYYY')}
                          value={startDate}
                          onChange={date => setStartDate(date)}
                          helperText={'Select when this task should be started. You can modify this value later.'}
                      />
                      <DatePicker
                          label="Due Date"
                          placeholder="YYYY-MM-DD"
                          minDate={startDate ? startDate : moment('1/1/2000', 'MM-DD-YYYY')}
                          value={dueDate}
                          onChange={date => setDueDate(date)}
                          helperText={'Select when this task is due. You can modify this value later.'}
                      />
                  </>
                  }
                </FormGridRow>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                disabled={formikProps.isSubmitting}
                onClick={handleClose}
                color="primary"
              >
                {taskData === null ? 'Cancel' : 'Close'}
              </Button>
              <SaveButton
                disabled={formikProps.isSubmitting}
                onClick={e => {
                  formikProps.isValid ?
                  formikProps.handleSubmit(e)
                  : invalidForm();
                }}
              />
            </DialogActions>
          </>
        )}
      </Formik>
    </ResponsiveDialog>
  </>
  );
}

export default AddTaskDialog;
