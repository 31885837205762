import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControl,
  Input,
  InputLabel,
  Link,
  MenuItem,
  Select,
  StandardProps,
  TextField,
  Typography,
} from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/styles';
import { Formik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import {
  IGithubOrg,
  IGithubOrgsUploadDto,
  IGithubRawOrg,
  IGithubTokenUploadDto,
} from '../../../../backend/src/user-audit/interfaces';
import API from '../../services/ApiService';
import { SaveButton } from '../buttons';
import { showErrorResultBar, showSuccessResultBar } from '../ResultSnackbar';
import StyledDialogTitle from '../StyledDialogTitle';
import ResponsiveDialog from './ResponsiveDialog';

const useStyles = makeStyles({
  instructionList: {
    color: 'rgb(33, 43, 54, 0.8)',
    fontSize: '0.95rem',
    lineHeight: '1.5rem',
  },

  instructionNestedList: {
    listStyleType: 'lower-alpha',
  },
});

const TokenSchema = Yup.object().shape({
  token: Yup
    .string()
    .label('GitHub token')
    .matches(/^gh[pousr]_[A-Za-z0-9]{36}$/, 'GitHub access tokens are 40 characters and start with gh')
    .required('Required'),
});

const GithubOrgSchema = Yup.object().shape({
  ids: Yup
    .array()
    .of(Yup.string())
    .label('GitHub organization(s)')
    .required('Required'),
});

interface UploadTokenFormProps {
  onCancel: () => void;
  onValidateToken: (formValues: IGithubTokenUploadDto) => Promise<void>;
}

function UploadTokenForm({ onCancel, onValidateToken }: UploadTokenFormProps) {
  const classes = useStyles();

  return (
    <Formik
      enableReinitialize
      initialValues={{
        token: '',
      }}
      validationSchema={TokenSchema}
      onSubmit={async (values, { setSubmitting }) => {
        await onValidateToken(values);
        setSubmitting(false);
      }}
      onReset={_ => {
        onCancel();
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        handleReset,
        handleSubmit,
        isSubmitting,
      }) => (
        <>
          <DialogContent>
            <Typography
              variant="body1"
            >
              For each GitHub organization you wish to audit, we will ask for an access token.
              If you have already created a token for us, you may enter it below. Otherwise follow the
              steps to create a new token. If possible the GitHub organization owner should set this up, since
              some fields are not available to non-owners.
            </Typography>
            <ol className={classes.instructionList}>
              <li>In GitHub, navigate to your <Link href="https://github.com/settings/tokens" target="_blank">Personal Access Tokens</Link>:
                <ol className={classes.instructionNestedList}>
                  <li>Log in to <Link href="https://github.com" target="_blank">GitHub</Link></li>
                  <li>In the upper right corner, click your profile picture to display a dropdown menu,
                    then click <Link href="https://github.com/settings/profile" target="_blank">Settings</Link></li>
                  <li>Click on <Link href="https://github.com/settings/apps" target="_blank">Developer Settings</Link></li>
                  <li>Click on <Link href="https://github.com/settings/tokens" target="_blank">Personal Access Tokens</Link></li>
                </ol>
              </li>
              <li>Click the <em>Generate new token</em> button.</li>
              <li>In the <em>Note</em> section name the token, e.g., <em>spio-user-audit</em>.</li>
              <li>We'll need <b>read:org</b> and <b>user:email</b> permissions, so click those.</li>
              <li>Click the <em>Generate token</em> button.</li>
              <li>Copy the token and enter it below.</li>
            </ol>
            <Typography
              variant="body1"
              gutterBottom
            >
              After the token is validated, you will choose a GitHub organization for auditing.
            </Typography>
            <Typography
              variant="caption"
              gutterBottom
            >
              Note: Your token will be stored encrypted.
              Other managers on your team will be able to run the audit functionality, but
              they <b>will not</b> have direct access to this token.
            </Typography>
            <TextField
              name="token"
              value={values.token || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              margin="dense"
              multiline
              // label="Token"
              placeholder="Enter token here"
              helperText={errors.token}
              error={!!errors.token}
            />
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              size="small"
              disabled={isSubmitting}
              onClick={handleReset}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              color="primary"
              disabled={isSubmitting || Object.keys(errors).length > 0}
              onClick={() => handleSubmit()}
            >
              Validate token
            </Button>
          </DialogActions>
        </>
      )}
    </Formik>
  );
}

interface ChooseGithubOrgFormProps {
  githubOrgs: IGithubRawOrg[];
  onCancel: () => void;
  onSaveGithubOrg: (formValues: IGithubOrgsUploadDto) => Promise<void>;
  token: string;
}

function ChooseGithubOrgForm({ githubOrgs, onCancel, onSaveGithubOrg, token }: ChooseGithubOrgFormProps) {
  return (
    githubOrgs.length === 0 ? (
      <>
        <DialogContent>
          <Typography
            variant="body1"
          >
            No GitHub organizations were found for the provided token.
            Make sure the token has <b>read:org</b> permission for the relevant organization.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            size="small"
            onClick={onCancel}
          >
            Cancel
          </Button>
        </DialogActions>
      </>
    ) : (
    <Formik
      enableReinitialize
      initialValues={{
        ids: [] as string[],
        token,
      }}
      validationSchema={GithubOrgSchema}
      onSubmit={async (values, { setSubmitting }) => {
        await onSaveGithubOrg(values);
        setSubmitting(false);
      }}
      onReset={_ => {
        onCancel();
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        handleReset,
        handleSubmit,
        isSubmitting,
      }) => (
        <>
          <DialogContent>
            <Typography
              variant="body1"
              gutterBottom
            >
              The provided token has access to {githubOrgs.length} GitHub organization{githubOrgs.length > 1 ? 's' : ''}.
              Choose the organization(s) you wish to audit from the drop-down list below.
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
            >
              If you include an organization you're already auditing here, then its associated
              token will be overwritten with the token you've just provided.
            </Typography>
            <FormControl>
              <InputLabel
                htmlFor="select-github-org"
              >
                GitHub Organization(s)
              </InputLabel>
              <Select
                multiple
                name="ids"
                value={values.ids || []}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                renderValue={(selectedIds) => (
                  githubOrgs
                    .filter(org => (selectedIds as string[]).includes(org.id))
                    .map(org => org.login)
                    .join(', ')
                )}
                input={<Input
                  id="select-github-org"
                  error={!!errors.ids}
                />}
              >
                {githubOrgs.map(githubOrg => (
                  <MenuItem
                    key={githubOrg.id}
                    value={githubOrg.id}
                  >
                    <Checkbox checked={(values.ids).indexOf(githubOrg.id) > -1} />
                    {githubOrg.login}{githubOrg.name !== githubOrg.login ? ` (i.e., "${githubOrg.name}")` : ''}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              size="small"
              disabled={isSubmitting}
              onClick={handleReset}
            >
              Cancel
            </Button>
            <SaveButton
              disabled={isSubmitting || Object.keys(errors).length > 0}
              onClick={handleSubmit}
            />
          </DialogActions>
        </>
      )}
    </Formik>
  ));
}

export interface UserAuditGithubSetupDialogProps extends StandardProps<DialogProps, 'children'> {
  handleClose: () => void;
  handleNewOrgs: (newOrgs: IGithubOrg[]) => void;
}

function UserAuditGithubSetupDialog({ open, handleClose, handleNewOrgs }: UserAuditGithubSetupDialogProps) {
  const [ token, setToken ] = useState<string>('');
  const [ githubOrgs, setGithubOrgs ] = useState<IGithubRawOrg[]>([]);

  const onValidateToken = async (formValues: IGithubTokenUploadDto) => {
    let errorMsg: string;

    API.post('userAudit/github/token', formValues)
      .then((res) => {
        if (!res.data || !res.data.data) {
          errorMsg = 'Unexpected error retrieving orgs';
        } else {
          // Token is valid; store it.
          setToken(formValues.token);
          setGithubOrgs(res.data.data);
        }
      })
      .catch((err) => {
        errorMsg = (err.response && err.response.data && err.response.data.error) || 'Unexpected error while checking token';
      })
      .finally(() => {
        if (errorMsg) {
          showErrorResultBar(errorMsg);
        }
      });
  };

  const onSaveGithubOrg = async (formValues: IGithubOrgsUploadDto) => {
    let errorMsg: string;
    let successMsg: string;

    API.post('userAudit/github/orgs', formValues)
      .then((res) => {
        if (!res.data || !res.data.data) {
          errorMsg = 'Unexpected error saving GitHub org details';
        }

        const createdOrgs = res.data.data.created || [];
        const updatedOrgs = res.data.data.updated || [];
        const nbCreated = createdOrgs.length;
        const nbUpdated = updatedOrgs.length;
        const createdMsg = nbCreated > 0 ? `${nbCreated} organization${nbCreated === 1 ? '' : 's'} added` : '';
        const updatedMsg = nbUpdated > 0 ? `${nbUpdated} organization${nbUpdated === 1 ? '' : 's'} updated` : '';
        const sep = createdMsg && updatedMsg ? ' and ' : '';
        successMsg = `${createdMsg}${sep}${updatedMsg}`;

        handleNewOrgs(createdOrgs);
        onCancel();
      })
      .catch((err) => {
        errorMsg = (err.response && err.response.data && err.response.data.error) || 'Unexpected error while saving GitHub org';
      })
      .finally(() => {
        if (errorMsg) {
          showErrorResultBar(errorMsg);
        } else if (successMsg) {
          showSuccessResultBar(successMsg);
        }
      });
  };

  const onCancel = () => {
    handleClose();
  };

  const clearData = () => {
    setToken('');
    setGithubOrgs([]);
  };

  return (
    <ResponsiveDialog
      open={open}
      onClose={onCancel}
      onExited={clearData}
      disableBackdropClick
    >
      <StyledDialogTitle
        onClose={onCancel}
      >
        GitHub Organization Setup
      </StyledDialogTitle>
      {!token ? (
          <UploadTokenForm
            onCancel={onCancel}
            onValidateToken={onValidateToken}
          />
        ) : (
          <ChooseGithubOrgForm
            githubOrgs={githubOrgs}
            onCancel={onCancel}
            onSaveGithubOrg={onSaveGithubOrg}
            token={token}
          />
      )}
    </ResponsiveDialog>
  );
}

export default UserAuditGithubSetupDialog;
