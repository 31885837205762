import { Button, DialogActions, DialogContent, DialogContentText, StandardProps } from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import React from 'react';
import { DeleteButton } from '../buttons';
import { RouterLink } from '../RouterLink';
import StyledDialogTitle from '../StyledDialogTitle';
import ResponsiveDialog from './ResponsiveDialog';

export interface ArchiveDialogProps extends StandardProps<DialogProps, 'children'> {
  onClose: () => void;
  onArchive: () => void;
  title: string;
  text?: string;
  archive?: boolean;
}

export default function ArchiveDialog(props: ArchiveDialogProps) {
  const { open, onArchive, onClose, title, text, archive = true } = props;

  return (
    <ResponsiveDialog
      open={open}
      onClose={onClose}
    >
      <StyledDialogTitle>
        {title}
      </StyledDialogTitle>
      <DialogContent>
        <DialogContentText>
          {text &&
          text }
          {archive &&
            <>
              You will need to <RouterLink to="/support">contact us</RouterLink> in
              order to retrieve it.
            </>
          }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          size="small"
          onClick={onClose}
        >
          Cancel
        </Button>
        <DeleteButton
          onClick={onArchive}
          text={archive ? 'Archive' : 'Remove'}
        />
      </DialogActions>
    </ResponsiveDialog>
  );
}
