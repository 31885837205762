import { Button, DialogActions, DialogContent, Grid, MenuItem, StandardProps } from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/styles';
import { Formik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import {
  StakeholderReason,
  StakeholderStatus,
  StakeholderType,
} from '../../../../backend/src/stakeholder/enums';
import { IStakeholderDto, IStakeholderUpdateDto } from '../../../../backend/src/stakeholder/interfaces';
import API from '../../services/ApiService';
import { SaveButton } from '../buttons';
import { FormGridRow, FormikDatePicker, FormikTextField } from '../forms';
import { showSuccessResultBar } from '../ResultSnackbar';
import StyledDialogTitle from '../StyledDialogTitle';
import ResponsiveDialog from './ResponsiveDialog';
import { handleError } from '../../helpers';

const useStyles = makeStyles({
  inputField: {
    marginRight: 0,
    marginLeft: 0,
  },
  showMoreButton: {
    marginRight: 'auto',
  },
});

export const stakeholderReasonSelections: { [key in StakeholderReason]: string } = {
  materiality_survey: 'Materiality Survey',
  supplier_compliance: 'Supplier Compliance',
  policy_training: 'Policy Training',
  reporting: 'Reporting',
  project_engagement: 'Project Engagement',
  other: 'Other',
};

export const stakeholderTypeSelections: { [key in StakeholderType]: string } = {
  supplier: 'Supplier',
  customer: 'Customer',
  employee: 'Employee',
  ngo: 'NGO',
  community_member: 'Community Member',
  investor: 'Investor',
  other: 'Other'
};

export const stakeholderStatusSelections: Omit<{ [key in StakeholderStatus]: string }, 'archived'> = {
  pending: 'Pending',
  engaged: 'Engaged',
  needs_followup: 'Follow-up Required',
  objected: 'Objections Identified',
};

interface IFormValues extends Omit<IStakeholderUpdateDto, 'urls'> {
  urlMain?: string | null;
  urlPrivacy?: string | null;
  urlTerms?: string | null;
  urlCompliance?: string | null;
  urlSupport?: string | null;
  urlESG?: string | null;
}

const getInitialFormValues = (stakeholder: IStakeholderDto | null): IFormValues => ({
  contactEmail: stakeholder?.contactEmail || '',
  contactName: stakeholder?.contactName || '',
  contactPhone: stakeholder?.contactPhone || '',
  contactTitle: stakeholder?.contactTitle || '',
  dateEngaged: stakeholder?.dateEngaged || new Date(),
  department: stakeholder?.department || '',
  externalId: stakeholder?.externalId || '',
  notes: stakeholder?.notes || '',
  ownerEmail: stakeholder?.ownerEmail || '',
  reason: stakeholder?.reason || 'other',
  retiredAt: stakeholder?.retiredAt || null,
  reviewedAt: stakeholder?.reviewedAt || new Date(),
  type: stakeholder?.type || 'other',
  status: stakeholder?.status || 'engaged',
  urlCompliance: stakeholder?.urls?.urlCompliance || '',
  urlMain: stakeholder?.urls?.urlMain || '',
  urlPrivacy: stakeholder?.urls?.urlPrivacy || '',
  urlESG: stakeholder?.urls?.urlESG || '',
  urlSupport: stakeholder?.urls?.urlSupport || '',
  urlTerms: stakeholder?.urls?.urlTerms || '',
  stakeholderName: stakeholder?.stakeholderName || '',
});

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const StakeholderSchema = Yup.object().shape({
  contactEmail: Yup
    .string()
    .label('Contact Email')
    .email('Invalid Email Format.'),
  contactName: Yup
    .string()
    .label('Contact Name')
    .max(255),
  contactPhone: Yup
    .string()
    .matches(phoneRegExp, 'Phone number is not valid.')
    .label('Contact Phone'),
  contactTitle: Yup
    .string()
    .label('Contact Title'),
  dateEngaged: Yup
    .date()
    .nullable()
    .label('Date Engaged'),
  department: Yup
    .string()
    .label('Department'),
  externalId: Yup
    .string()
    .label('External ID')
    .max(255),
  notes: Yup
    .string()
    .label('Notes'),
  ownerEmail: Yup
    .string()
    .label('Owner Email')
    .email('Invalid email format.'),
  reason: Yup
    .mixed()
    .label('Reason')
    .oneOf(Object.keys(stakeholderReasonSelections) as StakeholderReason[]),
  retiredAt: Yup
    .date()
    .nullable()
    .label('Retirement date'),
  reviewedAt: Yup
    .date()
    .label('Last review date')
    .required(),
  type: Yup
    .mixed()
    .label('Type')
    .oneOf(Object.keys(stakeholderTypeSelections) as StakeholderType[]),
  status: Yup
    .mixed()
    .label('Status')
    .oneOf(Object.keys(stakeholderStatusSelections) as StakeholderStatus[]),
  urlCompliance: Yup
    .string()
    .matches(/^https?:\/\//i, 'The url must begin with "http://" or "https://')
    .url('Invalid url')
    .label('Compliance URL'),
  urlMain: Yup
    .string()
    .matches(/^https?:\/\//i, 'The url must begin with "http://" or "https://')
    .url('Invalid url')
    .label('Main Site URL'),
  urlPrivacy: Yup
    .string()
    .matches(/^https?:\/\//i, 'The url must begin with "http://" or "https://')
    .url('Invalid url')
    .label('Privacy Policy URL'),
  urlESG: Yup
    .string()
    .matches(/^https?:\/\//i, 'The url must begin with "http://" or "https://')
    .url('Invalid url')
    .label('ESG Policy URL'),
  urlSupport: Yup
    .string()
    .matches(/^https?:\/\//i, 'The url must begin with "http://" or "https://')
    .url('Invalid url')
    .label('Support URL'),
  urlTerms: Yup
    .string()
    .matches(/^https?:\/\//i, 'The url must begin with "http://" or "https://')
    .url('Invalid url')
    .label('Terms of Service URL'),
  stakeholderName: Yup
    .string()
    .label('Stakeholder Name')
    .max(255)
    .trim()
    .required('Required'),
});

const createUploadData = (formValues: IFormValues): IStakeholderUpdateDto => {
  return {
    contactEmail: formValues.contactEmail || null,
    contactName: formValues.contactName || null,
    contactPhone: formValues.contactPhone || null,
    contactTitle: formValues.contactTitle || null,
    dateEngaged: formValues.dateEngaged || undefined,
    department: formValues.department || null,
    externalId: formValues.externalId || null,
    notes: formValues.notes || null,
    ownerEmail: formValues.ownerEmail || null,
    reason: formValues.reason || 'other',
    retiredAt: formValues.retiredAt || null,
    reviewedAt: formValues.reviewedAt || undefined,
    type: formValues.type || 'other',
    status: formValues.status || 'engaged',
    urls: {
      urlCompliance: formValues.urlCompliance || null,
      urlMain: formValues.urlMain || null,
      urlPrivacy: formValues.urlPrivacy || null,
      urlESG: formValues.urlESG || null,
      urlSupport: formValues.urlSupport || null,
      urlTerms: formValues.urlTerms || null,
    },
    stakeholderName: formValues.stakeholderName,
  };
};

export interface StakeholderEditDialogProps extends StandardProps<DialogProps, 'children'> {
  onClose: () => void;
  onSave: (newStakeholder: IStakeholderDto) => void;
  stakeholderData: IStakeholderDto | null;
}

export default function StakeholderEditDialog({ onClose, onSave, open, stakeholderData }: StakeholderEditDialogProps) {
  const classes = useStyles();
  const [toShowAllFields, setToShowAllFields] = useState(false);

  const handleSave = async (formValues: IStakeholderUpdateDto) => {
    try {
      const res = stakeholderData === null ?
        await API.post('stakeholder', formValues) :
        await API.patch(`stakeholder/${stakeholderData.id}`, formValues);
      onSave(res.data?.data);
      showSuccessResultBar('Stakeholder saved successfully');

      //if (stakeholderData === null) {
      handleClose();
      //}
    } catch (err) {
      handleError(err, 'Unexpected error saving stakeholder');
    }
  };

  const handleClose = () => {
    onClose();
    setToShowAllFields(false);
  };

  return (
    <ResponsiveDialog
      disableBackdropClick
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleClose}
    >
      <StyledDialogTitle onClose={handleClose}>
        {stakeholderData === null ? 'Create New stakeholder' : 'Edit Stakeholder'}
      </StyledDialogTitle>
      <Formik
        initialValues={getInitialFormValues(stakeholderData)}
        validateOnChange={false} // normally all fields are validated on any change to any field; this is too slow here
        validationSchema={StakeholderSchema}
        onReset={handleClose}
        onSubmit={async (values, { setSubmitting }) => {
          await handleSave(createUploadData(values));
          setSubmitting(false);
        }}
      >
        {formikProps => (
          <>
            <DialogContent>
              <Grid container>
                {/* 'Tracking info' section */}
                <FormGridRow
                  divider
                  title="Stakeholder Information"
                  subtitle="Standard tracking information for this Stakeholder."
                >
                  <FormikTextField
                    autoFocus
                    field="stakeholderName"
                    formikProps={formikProps}
                    label="Stakeholder Name"
                    placeholder="Stakeholder Name"
                    required
                  />
                  <FormikTextField
                    field="type"
                    formikProps={formikProps}
                    helperTextStr="Stakeholder 'type'."
                    label="Stakeholder Type"
                    select
                  >
                    {Object.entries(stakeholderTypeSelections).map(([key, label]) => (
                      <MenuItem
                        key={key}
                        value={key}
                      >
                        {label}
                      </MenuItem>
                    ))}
                  </FormikTextField>
                  <FormikTextField
                    field="reason"
                    formikProps={formikProps}
                    helperTextStr="Reason for engagement."
                    label="Engagement Reason"
                    select
                  >
                    {Object.entries(stakeholderReasonSelections).map(([key, label]) => (
                      <MenuItem
                        key={key}
                        value={key}
                      >
                        {label}
                      </MenuItem>
                    ))}
                  </FormikTextField>
                  <FormikTextField
                    field="status"
                    formikProps={formikProps}
                    helperTextStr="Status of Stakeholder: Engaged; Pending; Follow-up Required; Objections Identified"
                    label="Stakeholder Status"
                    select
                  >
                    {Object.entries(stakeholderStatusSelections).map(([key, label]) => (
                      <MenuItem
                        key={key}
                        value={key}
                      >
                        {label}
                      </MenuItem>
                    ))}
                  </FormikTextField>
                  <FormikDatePicker
                    field="dateEngaged"
                    formikProps={formikProps}
                    helperTextStr="When you began working with the Stakeholder."
                    initValue={stakeholderData?.dateEngaged}
                    label="Date Engaged"
                    required
                  />
                  {(stakeholderData !== null || toShowAllFields) && (
                    <FormikDatePicker
                      field="reviewedAt"
                      formikProps={formikProps}
                      helperTextStr="Refer to your Stakeholder Engagement Policy for the frequency of Stakeholder reviews."
                      initValue={stakeholderData?.reviewedAt}
                      label="Last Review Date"
                      required
                    />
                  )}
                  {toShowAllFields && (
                    <FormikDatePicker
                      field="retiredAt"
                      formikProps={formikProps}
                      helperTextStr="When your company ceased working with the Stakeholder."
                      initValue={stakeholderData?.retiredAt}
                      label="Retirement Date"
                      minDate={formikProps.values.dateEngaged}
                      minDateMessage="Should not be before the date engaged."
                      nullable
                    />
                  )}
                  {toShowAllFields && (
                    <FormikTextField
                      field="ownerEmail"
                      formikProps={formikProps}
                      helperTextStr="Email of person on your team responsible for this Stakeholder."
                      label="Owner email"
                    />
                  )}
                  {toShowAllFields && (
                    <FormikTextField
                      field="department"
                      formikProps={formikProps}
                      helperTextStr="The internal department(s) using this Stakeholder."
                      label="Department"
                    />
                  )}
                  {toShowAllFields && (
                    <FormikTextField
                      field="externalId"
                      formikProps={formikProps}
                      label="External ID"
                    />
                  )}
                </FormGridRow>
                {/* 'Contact info' section */}
                <FormGridRow
                  divider
                  subtitle="The Point of Contact for this Stakeholder."
                  title="Stakeholder PoC"
                >
                  <FormikTextField
                    field="contactEmail"
                    formikProps={formikProps}
                    label="Email"
                  />
                  <FormikTextField
                    field="contactName"
                    formikProps={formikProps}
                    label="Name"
                  />
                  {toShowAllFields && <>
                    <FormikTextField
                      field="contactPhone"
                      formikProps={formikProps}
                      label="Phone"
                      placeholder="###-###-####"
                    />
                  </>}
                  {toShowAllFields && <>
                    <FormikTextField
                      field="contactTitle"
                      formikProps={formikProps}
                      label="Job title or role"
                    />
                  </>}
                </FormGridRow>
                {/* 'URLs' section */}
                <FormGridRow
                  divider
                  title="URLs"
                >
                  <FormikTextField
                    field="urlMain"
                    formikProps={formikProps}
                    label="Main website"
                    placeholder="https://example.com"
                  />
                  {toShowAllFields && <>
                    <FormikTextField
                      field="urlCompliance"
                      formikProps={formikProps}
                      label="Compliance"
                      placeholder="https://example.com/compliance"
                    />
                    <FormikTextField
                      field="urlPrivacy"
                      formikProps={formikProps}
                      label="Privacy policy"
                      placeholder="https://example.com/privacy_policy"
                    />
                    <FormikTextField
                      field="urlESG"
                      formikProps={formikProps}
                      label="ESG policy"
                      placeholder="https://example.com/esg_policy"
                    />
                    <FormikTextField
                      field="urlSupport"
                      formikProps={formikProps}
                      label="Support page"
                      placeholder="https://example.com/support"
                    />
                    <FormikTextField
                      field="urlTerms"
                      formikProps={formikProps}
                      label="Terms of service"
                      placeholder="https://example.com/terms_of_service"
                    />
                  </>}
                </FormGridRow>
                {/* 'Notes' section */}
                <FormGridRow
                  title="Additional notes"
                >
                  <FormikTextField
                    field="notes"
                    formikProps={formikProps}
                    label="Notes"
                    multiline
                  />
                </FormGridRow>
              </Grid>
            </DialogContent>
            <DialogActions disableSpacing>
              <Button
                className={classes.showMoreButton}
                disabled={formikProps.isSubmitting}
                onClick={() => setToShowAllFields(prevValue => !prevValue)}
                color="primary"
              >
                Show {toShowAllFields ? 'less' : 'more'}
              </Button>
              <Button
                disabled={formikProps.isSubmitting}
                onClick={formikProps.handleReset}
                color="primary"
              >
                {stakeholderData === null ? 'Cancel' : 'Close'}
              </Button>
              <SaveButton
                disabled={formikProps.isSubmitting || Object.values(formikProps.errors).filter(v => !!v).length > 0}
                onClick={formikProps.handleSubmit}
              />
            </DialogActions>
          </>
        )}
      </Formik>
    </ResponsiveDialog>
  );
}
