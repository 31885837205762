import {
    Card,
    CardHeader,
    createTheme,
    createStyles,
    MuiThemeProvider,
    Tab,
    TabProps,
    Tabs,
    Theme,
    withStyles,
  } from '@material-ui/core';
  import { makeStyles } from '@material-ui/styles';
  import React, { useEffect, useState } from 'react';
  import SpioDataTable, {
    SpioDataTableColumn,
  } from './SpioDataTable';
  import { IMaterialityAssessmentResponseIssuesDto } from '../../../backend/src/material-issue/interfaces/material-assessment-response-dto.interface';

  const dtTheme = () => createTheme({
    overrides: {
      MuiTableCell: {
        root: {
          paddingLeft: '0.5rem',
          paddingRight: '0.5rem',
        },
      },
    },
  });

  const useStyles = makeStyles({
    dialogContent: {
      padding: '0',
      overflowY: 'hidden',
      '&:first-child': {
        paddingTop: '0',
      },
    },
    tableContainer: {
      marginLeft: '1rem',
      marginBottom: '1rem',
      minHeight: '200px',
    },
    toggleViewLink: {
      'color': 'inherit',
      'cursor': 'pointer',
      'textDecoration': 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    tabsContainer: {
      marginTop: '-10px',
    },
    titleContainer: {

    }
  });

  const StyledTab = withStyles((theme: Theme) => createStyles({
    root: {
      fontSize: '1rem',
      fontWeight: theme.typography.fontWeightRegular,
      letterSpacing: '0.0075em',
      paddingTop: '0',
      textTransform: 'none',
      minWidth: 125, // a number of your choice
      width: 125, // a number of your choice
    },
    selected: {
      fontWeight: theme.typography.fontWeightMedium,
    },
  }))((props: TabProps) => <Tab {...props} />);

  const tableHeaders: SpioDataTableColumn[] = [
    {
      name: 'issueName',
      label: 'Issue',
    },
    {
      name: 'score',
      label: 'Score',
    },
  ];

  interface ITableDatum {
    issueCategory: string;
    issueName: string;
  }

  export interface TopMaterialIssuesOverviewProps {
    isLoading: boolean;
    issues: IMaterialityAssessmentResponseIssuesDto[];
    filterOptions: {[key: string]: string}[] | [];
  }

  function TopMaterialIssuesOverview({
    isLoading,
    issues,
    filterOptions,
  }: TopMaterialIssuesOverviewProps) {
    const classes = useStyles();

    function onViewChange(event: React.ChangeEvent<{ value: unknown }>) {
      const newSelection = event.target.value as string;
      setSelectedFilter(newSelection);
    }

    const [ rankedIssues, setRankedIssues ] = useState([]);
    const [ selectedTab, setSelectedTab ] = useState(0);
    const [ selectedFilter, setSelectedFilter ] = useState<string>('All');
    const [ tableData, setTableData ] = useState<ITableDatum[]>([]);

    useEffect(() => {
        selectedTab === 0 &&
            setTableData(issues?.slice(0,5).map((i) => Object({
                issueName: i.info?.name,
                score: Math.round(i.all*10)/10,
            })));
        selectedTab === 1 &&
            setTableData(issues?.filter((issue) => issue.info.category === 'Environmental').slice(0,5).map((i) => Object({
                issueName: i.info?.name,
                score: Math.round(i.all*10)/10,
            })));
        selectedTab === 2 &&
            setTableData(issues?.filter((issue) => issue.info.category === 'Social').slice(0,5).map((i) => Object({
                issueName: i.info?.name,
                score: Math.round(i.all*10)/10,
            })));
        selectedTab === 3 &&
            setTableData(issues.filter((issue) => issue.info.category === 'Governance').slice(0,5).map((i) => Object({
                issueName: i.info?.name,
                score: Math.round(i.all*10)/10,
            })));
    }, [issues, selectedTab]);

    return (
        <Card>
            <CardHeader
            title= {<>
            Top Material Issues
            {/* <FormControl>
                <Select
                    labelId="questionnaire-select"
                    id="questionnaire-select"
                    value={selectedFilter}
                    label="Questionnaire"
                    onChange={onViewChange}
                    disableUnderline
                >
                    <MenuItem key={'All'} value={'All'}>
                        {'All'}
                    </MenuItem>
                    {Object.values(filterOptions).map((q) => (
                    <MenuItem key={q.option} value={q.option}>
                        {q.option}
                    </MenuItem>
                    ))}
                </Select>
            </FormControl> */}
            </>}
            //className={classes.tabsContainer}
            titleTypographyProps={{variant:'h6' }}
            />

            <Tabs
                className={classes.tabsContainer}
                value={selectedTab}
                onChange={(_, value) => setSelectedTab(value)}
            >
                <StyledTab label="All" />
                <StyledTab label="Environmental" />
                <StyledTab label="Social" />
                <StyledTab label="Governance" />
            </Tabs>
            <div className={classes.tableContainer}>
                <MuiThemeProvider theme={dtTheme}>
                <SpioDataTable
                    title=""
                    data={tableData}
                    columns={tableHeaders}
                    options={{
                    download: false,
                    elevation: 0,
                    filter: false,
                    pagination: false,
                    print: false,
                    search: false,
                    selectableRows: 'none',
                    sort: false,
                    viewColumns: false,
                    textLabels: {
                        body: {
                        noMatch: isLoading ?
                            'Loading tasks...' :
                            (selectedTab === 0 ? 'No upcoming tasks assigned to me' : 'No unassigned upcoming tasks'),
                        },
                    },
                    // onRowClick: (_, rowMeta) => openDetails(rowMeta.dataIndex),
                    }}
                />
                </MuiThemeProvider>
            </div>
        </Card>
    );
  }

export default TopMaterialIssuesOverview;
