import {
    Card,
    CardContent,
    CardHeader,
    createStyles,
    Grid,
    makeStyles,
    Typography
  } from '@material-ui/core';
  import React, { useState } from 'react';
  import API from '../services/ApiService';
  import Auth from '../services/AuthService';
  import { showErrorResultBar, showSuccessResultBar } from './ResultSnackbar';
  import { DetailSectionHeading } from './MaterialityIssueDetails';
  import { IStakeholderDto, IStakeholderQuestionnaireDto, IStakeholderQuestionnaireResponse, IStakeholderQuestionnaireTemplateDto } from '../../../backend/src/stakeholder/interfaces';
  import { MissingDataText, SecondaryField } from './VendorDetailsInfo';
  import { getFileExtension, formatFileTitle, formatDate, formatFileSize, truncateString } from '../helpers';
  import { stakeholderTypeMap } from '../components/StakeholderDetailsInfo';
  import { StakeholderType } from '../../../backend/src/stakeholder/enums/stakeholder-type.enum';
  //import { IStakeholderQuestionnaireResponseDto } from '../../../backend/src/stakeholder/interfaces';
  import { StakeholderQuestionProps } from '../pages/TheQuestionnaireResponseDetailPage';

  const useStyles = makeStyles((theme) =>
    createStyles({
      buttonLink: {
        color: 'inherit',
        textDecoration: 'underline',
        cursor: 'pointer',
        '&:hover': {
          color: theme.palette.primary.main,
        },
      },
      cardHeader: {
        paddingBottom: 0,
      },
      linkItem: {
        '& a': {
          color: theme.typography.body1.color,
          cursor: 'pointer',
        },
        '&:nth-child(n+2)': {
          paddingTop: '4px',
        },
        fontSize: '0.8125rem',
      },
      primaryColumn: {
        color: theme.typography.body1.color,
        fontSize: '14px',
      },
      referencesContainer: {
        paddingLeft: '1rem',
        paddingBottom: '0.8rem',
      },
      referenceResponse: {
        color: 'rgba(0,0,0,0.54)',
        whiteSpace: 'pre-line',
      },
      secondaryColumn: {
        backgroundColor: '#f5f5f5',
        opacity: '0.8',
        fontSize: '0.8125rem',
      },
      smallIconButton: {
        height: '14px',
        width: '14px',
        marginTop: '-6px',
        marginLeft: '0.5rem',
        '&:hover': {
          color: theme.palette.primary.main,
        },
      },
      smallIconButtonIcon: {
        fontSize: '18px',
      },
      smallIconButtonLabel: {
        height: '0',
      },
      tagChip: {
      margin: '4px',
      color: theme.palette.primary.main,
      background: theme.palette.grey[200]
    },
    questionnaireQuestion: {
      fontWeight: 'bold',
    },
    questionnaireAnswer: {
      marginInlineStart: '1rem',
    },
    stakeholderResponseText: {
      color: 'blue',
    },
    questionnaireEntryActions: {
      justifyContent: 'flex-start',
      paddingTop: 0,
    },
    })
  );

  export interface OutboundQuestionnaireResponseDetailsProps {
    auth: Auth;
    questionnaire: IStakeholderQuestionnaireDto;
    stakeholderData: IStakeholderDto | null;
    questionnaireTemplate: IStakeholderQuestionnaireTemplateDto;
    response: IStakeholderQuestionnaireResponse;
    StakeholderQuestions: StakeholderQuestionProps[];
  }

  function OutboundQuestionnaireResponseDetails(props: OutboundQuestionnaireResponseDetailsProps) {
    const {
      auth,
      questionnaire,
      stakeholderData,
      questionnaireTemplate,
      response,
      StakeholderQuestions
    } = props;

    const classes = useStyles();

    const respondentType = (type: StakeholderType) => {
      return stakeholderTypeMap[type];
    };

    const isResponseList = (response: string | object) => {
      return typeof response === 'object';
    };

    return (
      <>
        <Card>
          <CardHeader
            className={classes.cardHeader}
            title={`${formatFileTitle(stakeholderData?.stakeholderName || 'Anonymous')} Response`}
            subheader={stakeholderData?.contactEmail ?? null}
          />
          <CardContent>
          {/* {documentData.documentTags ? documentData.documentTags.map(tag => <Chip className={classes.tagChip} label={tag.name} size="small"></Chip>).sort() : <></>} */}
            <Grid container alignItems="flex-start" spacing={4}>
              {/* Creator */}
              <Grid item xs={12} sm={8} md={9} className={classes.primaryColumn}>
              <DetailSectionHeading>Questionnaire Responses</DetailSectionHeading>
                <div className={classes.referencesContainer}>
                <>
              {StakeholderQuestions.map(question => {
                return (
                  <Grid
                    item
                    xs={12}
                    key={question.id}
                  >
                    <Typography
                      className={classes.questionnaireQuestion}
                    >
                      {question.text}
                    </Typography>
                    <Typography
                      gutterBottom
                      className={classes.questionnaireAnswer}
                    >
                      {!!question.label && `${question.label}: `}
                      {isResponseList(questionnaire.response.response[question.id]) ? (
                        <span className={classes.stakeholderResponseText}>
                          {questionnaire.response.response[question.id].join(', ')}
                        </span>
                      ) : (
                        <span className={classes.stakeholderResponseText}>
                          {questionnaire.response.response[question.id]}
                        </span>
                      )}
                    </Typography>
                  </Grid>
                );
              })}
            </>
                </div>
              </Grid>
              {/* Secondary column */}
              <Grid
                item
                xs={12}
                sm={4}
                md={3}
                className={classes.secondaryColumn}
              >
                {/* Links */}
                <SecondaryField
                    title="Response Date:"
                    value={formatDate(response.createdAt) || ''}
                />
                <SecondaryField
                    title="Response Type:"
                    value={questionnaire.sentTo === 'Self-filled' ? 'Self-filled' : 'Email Response'}
                />
                <SecondaryField
                    title="Respondent Type:"
                    value={stakeholderData?.type ? (respondentType(stakeholderData?.type)) : 'Unidentified'}
                />
                <SecondaryField
                    title="Questionnaire Version:"
                    value={questionnaireTemplate.version}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </>
    );
  }

  export default OutboundQuestionnaireResponseDetails;
