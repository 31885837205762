import { IconButton, Tooltip } from '@material-ui/core';
import blue from '@material-ui/core/colors/blue';
import { makeStyles } from '@material-ui/styles';
import classnames from 'classnames';
import { EditorView } from 'prosemirror-view';
import React from 'react';
import { MarkdownMenuItem } from '../lib/markdownMenuItems';

const useStyles = makeStyles({
  menuButton: {
    height: '30px',
    minWidth: '30px',
    lineHeight: '30px',
    padding: '0 1px',
    borderRadius: '2px',
    borderWidth: '1px',
    margin: '0 1px',
  },
  active: {
    'backgroundColor': blue[50],
    'color': blue[700],
    '&:hover': {
      backgroundColor: blue[50],
    },
  },
});

export interface MenuButtonProps {
  item: MarkdownMenuItem;
  onAddLink: () => void;
  view: EditorView;
}

export function MenuButton({ item, onAddLink, view }: MenuButtonProps) {
  const classes = useStyles();
  const { state, dispatch } = view;

  const handleClick = () => {
    if (item.isLink) {
      onAddLink();
    } else {
      item.run(state, dispatch);
    }
  };

  return (
    <Tooltip title={item.title}>
      <span>
        <IconButton
          className={classnames(
            classes.menuButton,
            item.active && item.active(state) && classes.active,
          )}
          disabled={item.enable && !item.enable(state)}
          disableRipple
          onClick={handleClick}
        >
          {item.content}
        </IconButton>
      </span>
    </Tooltip>
  );
}
