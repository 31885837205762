import {
  Card,
  CardContent,
  Tab,
  Tabs,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import * as Sentry from '@sentry/browser';
import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { RoleUpdate } from '../../../backend/src/auth/enums';
import { IUserBlockUpdateDto, IUserDownloadDto, IUserInviteDto, IUserRoleUpdateDto } from '../../../backend/src/user/interfaces';
import { showErrorResultBar, showSuccessResultBar } from '../components/ResultSnackbar';
import UserManagementUsersTable from '../components/UserManagementUsersTable';
import UserInvitesTable from '../components/UserInvitesTable';
import API from '../services/ApiService';
import Auth from '../services/AuthService';

const useStyles = makeStyles({
  headerCard: {
    width: '100%',
    margin: 0,
  },
});

const getItemsCountStrForTab = (items: object[]) => {
  return items.length ? ` (${items.length})` : '';
};

const handleError = (defaultMsg: string = 'unspecified error') => (err: any) => {
  showErrorResultBar(`${err.response?.data?.error || defaultMsg}`);
  Sentry.captureException(err);
};

export interface TheUserManagementPageProps {
  auth: Auth;
}

function TheUserManagementPage({ auth }: TheUserManagementPageProps) {
  const classes = useStyles();
  const [ invites, setInvites ] = useState<IUserInviteDto[]>([]);
  const [ isLoadingInvites, setIsLoadingInvites ] = useState(false);
  const [ isLoadingUsers, setIsLoadingUsers ] = useState(false);
  const [ selectedTabIdx, setSelectedTabIdx ] = useState(0);
  const [ users, setUsers ] = useState<IUserDownloadDto[]>([]);
  const [ userSeats, setUserSeats ] = useState(0);

  function getUserSeats() {
    API.get('user/seats')
    .then(res => setUserSeats(res.data?.data ?? []))
    .catch(handleError('Error fetching seats'))
    .finally(() => setIsLoadingInvites(false));
  }

  // Initial page load:
  useEffect(() => {
    setIsLoadingInvites(true);
    setIsLoadingUsers(true);

    API.get('user')
      .then(res => setUsers(res.data?.data ?? []))
      .catch(handleError('Error fetching user list'))
      .finally(() => setIsLoadingUsers(false));

    API.get('user/invite')
      .then(res => setInvites(res.data?.data ?? []))
      .catch(handleError('Error fetching invite list'))
      .finally(() => setIsLoadingInvites(false));

      API.get('user/seats')
      .then(res => setUserSeats(res.data?.data ?? []))
      .catch(handleError('Error fetching seats'))
      .finally(() => setIsLoadingInvites(false));
  }, []);

  const toggleUserBlock = async (userIdx: number) => {
    try {
      const user = getUserFromUsers(userIdx);
      const uploadData: IUserBlockUpdateDto = {
        toBlock: !user.blocked,
        userId: user.id!,
      };
      const res = await API.patch('user/block', uploadData);
      updateUsers(userIdx, res, `User ${user.blocked ? 'reenabled' : 'disabled'}`);
      getUserSeats();

    } catch (err) {
      handleError('Error updating user')(err);
    }
  };

  const updateUserRole = async (userIdx: number, newRole: RoleUpdate) => {
    try {
      const user = getUserFromUsers(userIdx);
      const uploadData: IUserRoleUpdateDto = {
        newRole,
        userId: user.id!,
      };
      const res = await API.patch('user/role', uploadData);
      updateUsers(userIdx, res, 'User role updated');
    } catch (err) {
      handleError('Error updating user')(err);
    }
  };

  const getUserFromUsers = (userIdx: number) => {
    const user = users[userIdx];

    if (!user || !user.id) {
      throw new Error('Unexpected error: Cannot update the selected user');
    }

    return user;
  };

  const updateUsers = (userIdx: number, apiResponse: AxiosResponse<any>, successMsg: string) => {
    const updatedUser: IUserDownloadDto = (apiResponse.data && apiResponse.data.data) ? apiResponse.data.data : undefined;

    const updatedUsers = users.slice();
    updatedUsers[userIdx] = updatedUser;

    setUsers(updatedUsers);
    showSuccessResultBar(successMsg);
  };

  const handleAddInvites = (newInvites: IUserInviteDto[]) => {
      setInvites(invites.concat(newInvites));
      setUserSeats(userSeats + 1);
  };

  const handleDeleteInvite = (inviteId: string) => {
    const updatedInvites =  invites.filter(invite => invite.id !== inviteId);
    setInvites(updatedInvites);
    setUserSeats(userSeats - 1);
  };

  return (
    <>
      <Tabs
        value={selectedTabIdx}
        onChange={(e, tabIdx) => setSelectedTabIdx(tabIdx)}
      >
        <Tab
          label={`Users${getItemsCountStrForTab(users)}`}
        />
        <Tab
          label={`Invites${getItemsCountStrForTab(invites)}`}
          disabled={auth.getIsEmailFree() || !auth.isGranted({ tier: 3 })}
        />
      </Tabs>
      <Card className={classes.headerCard}>
        <CardContent>
          {selectedTabIdx === 0 &&
          <UserManagementUsersTable
            isLoading={isLoadingUsers}
            selfUserId={auth.getUserId()}
            toggleUserBlock={toggleUserBlock}
            updateUserRole={updateUserRole}
            users={users}
            limitReached={auth.seatLimitReached(userSeats)}
            auth={auth}
          />
          }
          {selectedTabIdx === 1 &&
          <UserInvitesTable
            invites={invites}
            isLoading={isLoadingInvites}
            onAddInvites={handleAddInvites}
            onDeleteInvite={handleDeleteInvite}
            limitReached={auth.seatLimitReached(userSeats)}
            invitesLeft={auth.seatLimit() - userSeats}
            auth={auth}
          />
          }
        </CardContent>
      </Card>
    </>
  );
}

TheUserManagementPage.requiredAuthZ = {
  tier: 1,
  permission: 'user_management',
};
TheUserManagementPage.routePath = '/users';
TheUserManagementPage.title = 'User Management';

export default TheUserManagementPage;
