import { Dialog, DialogContent, Typography, Link } from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import React, { ReactElement, useState, useEffect } from 'react';
import StyledDialogTitle from '../StyledDialogTitle';
import API from '../../services/ApiService';
import { showErrorResultBar } from '../ResultSnackbar';
import * as Sentry from '@sentry/browser';

const defaultTitle = 'Are you sure?';

export interface OutboundQuestionnaireLinkDialogProps extends DialogProps {
  children?: string | ReactElement;
  title?: string;
  token: string;
  onClose: () => void;
  status: string;
}

function OutboundQuestionnaireLinkDialog({
  children,
  onClose,
  token,
  title,
  status,
  ...dialogProps
}: OutboundQuestionnaireLinkDialogProps) {
  const [urlLink, setUrlLink] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    setIsSubmitting(true);
    if (status === 'open') {
      API.get(`outbound-questionnaire/${token}/link`)
      .then((res) => {
        const url = res.data.data;
        setUrlLink(url);
      })
      .catch((err) => {
        showErrorResultBar('Unexpected error getting document link');
        Sentry.captureException(err);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
    }
  }, [token, status]);

  return (
    <Dialog {...dialogProps} disableEscapeKeyDown={true}>
      <StyledDialogTitle onClose={onClose}>
        {title || defaultTitle}
      </StyledDialogTitle>
      <DialogContent
      //className={classes.dialogContent}
      >
        <Typography variant="body1" gutterBottom>
          Below is the direct link to share this questionnaire. Anyone
          will be able to submit responses, so be careful who you
          send the link to.
        </Typography>
        <Typography
          variant="body1"
          //className={classes.selfFillLink}
          gutterBottom
        >
          <Link href={urlLink} rel="noopener noreferrer" target="_blank">
            {urlLink}
          </Link>
        </Typography>
      </DialogContent>
    </Dialog>
  );
}

export default OutboundQuestionnaireLinkDialog;
