import {
  Checkbox,
  createStyles,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
 } from '@material-ui/core';
 import { FormControlProps } from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/styles';
import { FormikProps, FormikValues } from 'formik';
import React from 'react';
import { geminiPurple } from '../../theme';
import { IRoleDto } from '../../../../backend/src/org-share/interfaces';

const useStyles = makeStyles((theme) =>
  createStyles({
    check: {
        color: geminiPurple[700],
        bordercolor: geminiPurple[200]
    },
    error: {
      paddingTop: '0.5rem',
      color: 'red',
      fontSize: '12px'
    },
    roleSelectionDescription: {
      paddingLeft: '0.5rem',
      fontWeight: 'normal',
    },
    roleSelectionName: {
      fontWeight: 500,
    },
  })
);

interface RoleSelectionLabelProps {
  isSelected?: boolean;
  roleSelection: IRoleDto;
}

export function RoleSelectionLabel({roleSelection }: RoleSelectionLabelProps) {
    const classes = useStyles();

    return (
      <>
        <Typography
          className={classes.roleSelectionName}
          variant="subtitle1"
        >
          {roleSelection.name}
        </Typography>
        <Typography
          className={classes.roleSelectionDescription}
          variant="subtitle2"
        >
          {roleSelection.description}
        </Typography>
      </>
    );
  }

interface FormikSelectPermissionsProps<Values extends FormikValues> extends FormControlProps {
  formikProps: FormikProps<Values>;
  helperTextStr?: string; // can be overridden with the usual 'helperText' prop
  options?: string[];
  allRoles: IRoleDto[];
}

export default function FormikSelectPermissions<Values extends FormikValues>(props: FormikSelectPermissionsProps<Values>) {
  const classes = useStyles();
  const { children, formikProps, helperTextStr, options, allRoles, variant = 'filled', ...otherTextFieldProps } = props;

  const handleUpdate = async (idx: number, props: FormikProps<Values>) => {
    const valueCopy = props.values.checked ? props.values.checked : Array(allRoles.length).fill(false);
    valueCopy[idx] = !valueCopy[idx];
    props.setFieldValue('checked', valueCopy, true);
    props.validateField('checked');
  };

  return (
    <>
        <FormControl
            margin="dense"
        >
            <FormGroup>
                {allRoles.map((role, idx) => (
                <>
                  {(role.admin || role.defaultRole) ?
                    <></>
                  :
                    <FormControlLabel
                        key={idx}
                        value={formikProps.values.checked[idx]}
                        onChange={() => handleUpdate(idx, formikProps)}
                        control={
                        <Checkbox
                            //defaultChecked={formikProps.values.checked[idx]}
                            color="default"
                            className={classes.check}
                            checked={formikProps.values.checked[idx]}
                        />
                        }
                        label={<RoleSelectionLabel
                        roleSelection={role}
                        />}
                    />
                  }
                </>
              ))}
              </FormGroup>
            {formikProps.errors.checked &&
                <Typography className={classes.error}>
                    {formikProps.errors.checked}
                </Typography>
            }
        </FormControl>
  </>
  );
}
