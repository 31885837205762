import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  StandardProps,
  TextField,
  Typography,
} from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/styles';
import * as Sentry from '@sentry/browser';
import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { IPolicyApprovalDto, IPolicyDocDto } from '../../../../backend/src/policy-doc/interfaces';
import API from '../../services/ApiService';
import { SaveButton } from '../buttons';
import { showErrorResultBar, showSuccessResultBar } from '../ResultSnackbar';
import StyledDialogTitle from '../StyledDialogTitle';
import { handleError } from '../../helpers';

const useStyles = makeStyles({
  formGrid: {
    paddingLeft: '16px',
    paddingRight: '32px',
  },
});

// The date validation is done internally by DatePicker, not via Yup.
// TODO: Investigate the error handling integration with Formik/Yup.
const FormSchema = Yup.object().shape({
  approvedAt: Yup
    .date()
    .label('Approval date')
    .required('Required'),
  approver: Yup
    .string()
    .label('Policy Approver')
    .required('Required')
    .max(255),
  changeLog: Yup
    .string()
    .label('Changes')
    .max(500)
    .nullable(),
  inForceAt: Yup
    .date()
    .label('In force date')
    .required('Required'),
  owner: Yup
    .string()
    .label('Policy Owner')
    .required('Required')
    .max(255),
  version: Yup
    .string()
    .label('Version identifier')
    .max(40)
    .required('Required'),
});

export interface PolicyApprovalDialogProps extends StandardProps<DialogProps, 'children'> {
  onApprove: (updatedPolicy: IPolicyDocDto) => void;
  onClose: () => void;
  policy: IPolicyDocDto;
}

export default function PolicyApprovalDialog({ onApprove, onClose, open, policy }: PolicyApprovalDialogProps) {
  const classes = useStyles();

  const handleSave = async (formValues: IPolicyApprovalDto) => {
    try {
      const res = await API.post(`policyDoc/${policy.versionId}/approve`, formValues);
      onApprove(res.data.data);
      showSuccessResultBar('Policy successfully approved');
      onClose();
    } catch (err) {
      handleError(err, 'Unexpected error while updating policy');
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      disableBackdropClick
    >
      <StyledDialogTitle onClose={onClose}>
        Approval Details for the {policy.name}
      </StyledDialogTitle>
      <Formik
        enableReinitialize
        initialValues={{
          approvedAt: new Date(),
          approver: policy.approver ?? '',
          changeLog: '',
          inForceAt: new Date(),
          owner: policy.owner ?? '',
          version: '',
        }}
        validationSchema={FormSchema}
        onSubmit={async (values, { setSubmitting }) => {
          await handleSave(values);
          setSubmitting(false);
        }}
        onReset={_ => {
          onClose();
        }}
      >
        {({
            values,
            errors,
            handleChange,
            handleBlur,
            handleReset,
            handleSubmit,
            isSubmitting,
            isValid,
            setFieldError,
            setFieldValue,
          }) => (
          <>
            <DialogContent>
              <Typography
                variant="body1"
                gutterBottom
              >
                {/* TODO: Auto-populate with 'Initial Policy' if this is the first approval. */}
                Please enter a version number (1.0, 2.0, etc.) and a brief description of the changes in this version
                (or 'Initial Policy' if this is the first approval).
                Then confirm the official approval date
                and the in-force date (the date the policy goes into effect).
              </Typography>
              {!policy.customPolicy &&
              <Typography
                variant="body1"
              >
                The information you enter will automatically be added to the Policy Tracking section of your policy.
              </Typography>
              }
              <Grid container spacing={2} className={classes.formGrid}>
                <Grid item xs={12}>
                  <TextField
                    id="version"
                    name="version"
                    label="Version number"
                    value={values.version}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="1.0"
                    helperText={errors.version}
                    error={!!errors.version}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="changeLog"
                    name="changeLog"
                    label="Version change log"
                    value={values.changeLog}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    multiline
                    placeholder="Brief description of changes"
                    helperText={errors.changeLog}
                    error={!!errors.changeLog}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="owner"
                    name="owner"
                    label="Owner"
                    value={values.owner}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Policy Owner"
                    helperText={errors.owner}
                    error={!!errors.owner}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="approver"
                    name="approver"
                    label="Approver"
                    value={values.approver}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Policy Approver"
                    helperText={errors.approver}
                    error={!!errors.approver}
                  />
                </Grid>
                <Grid item xs={12}>
                  {/* TODO: Refactor to use the FormikDatePicker and FormikTextField */}
                  <KeyboardDatePicker
                    id="approvedAt"
                    name="approvedAt"
                    label="Approval date"
                    placeholder="YYYY-MM-DD"
                    value={values.approvedAt}
                    onChange={val => setFieldValue('approvedAt', val)}
                    onBlur={(e) => {
                      if (!e.target.value) {
                        setFieldValue('approvedAt', new Date());
                      }
                    }}
                    onError={(error) => {
                      if (error !== errors.approvedAt) {
                        setFieldError('approvedAt', error?.toString() ?? '');
                      }
                    }}
                    format="YYYY-MM-DD"
                    invalidDateMessage="Invalid date format"
                    // helperText={errors.approvedAt}
                    error={!!errors.approvedAt}
                  />
                </Grid>
                <Grid item xs={12}>
                  <KeyboardDatePicker
                    id="inForceAt"
                    name="inForceAt"
                    label="In-force date"
                    placeholder="YYYY-MM-DD"
                    value={values.inForceAt}
                    onChange={val => setFieldValue('inForceAt', val, true)}
                    onBlur={(e) => {
                      if (!e.target.value) {
                        setFieldValue('inForceAt', new Date());
                      }
                    }}
                    onError={(error) => {
                      if (error !== errors.inForceAt) {
                        setFieldError('inForceAt', error?.toString() ?? '');
                      }
                    }}
                    format="YYYY-MM-DD"
                    invalidDateMessage="Invalid date format"
                    // helperText={errors.inForceAt}
                    error={!!errors.inForceAt}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                size="small"
                disabled={isSubmitting}
                onClick={handleReset}
              >
                Cancel
              </Button>
              <SaveButton
                disabled={isSubmitting || Object.values(errors).filter(v => !!v).length > 0}
                onClick={handleSubmit}
              />
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
}
