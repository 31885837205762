import { Dialog, DialogContent, Typography, Link } from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import React, { ReactElement, useState, useEffect } from 'react';
import StyledDialogTitle from '../StyledDialogTitle';
import API from '../../services/ApiService';
import { showErrorResultBar } from '../ResultSnackbar';
import * as Sentry from '@sentry/browser';

const defaultTitle = 'Are you sure?';

export interface DocumentLinkDialogProps extends DialogProps {
  children?: string | ReactElement;
  title?: string;
  docId: string;
  onClose: () => void;
  evidence?: boolean;
}

function DocumentLinkDialog({
  children,
  onClose,
  docId,
  title,
  evidence = false,
  ...dialogProps
}: DocumentLinkDialogProps) {
  const [urlLink, setUrlLink] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    setIsSubmitting(true);
    // check if a specific document id is provided before fetching documents so we don't have to load unnecessary documents
    let linkType = 'documents';
    if (evidence) {
      linkType = 'evidence';
    }
    API.get(`document/${docId}/link?${linkType}`)
      .then((res) => {
        const url = res.data;
        setUrlLink(url);
      })
      .catch((err) => {
        showErrorResultBar('Unexpected error getting document link');
        Sentry.captureException(err);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }, [docId]);

  return (
    <Dialog {...dialogProps} disableEscapeKeyDown={true}>
      <StyledDialogTitle onClose={onClose}>
        {title || defaultTitle}
      </StyledDialogTitle>
      <DialogContent
      //className={classes.dialogContent}
      >
        <Typography variant="body1" gutterBottom>
          Below is the direct link to this document. Only users within your
          organization that can access documents will be able to
          access the document via this link.
        </Typography>
        <Typography
          variant="body1"
          //className={classes.selfFillLink}
          gutterBottom
        >
          <Link href={urlLink} rel="noopener noreferrer" target="_blank">
            {urlLink}
          </Link>
        </Typography>
      </DialogContent>
    </Dialog>
  );
}

export default DocumentLinkDialog;
