import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  StandardProps,
  Typography,
} from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import * as Sentry from '@sentry/browser';
import React, { useState } from 'react';
import { IPolicyDocDto, IPolicyNewVersionUploadDto } from '../../../../backend/src/policy-doc/interfaces';
import { errorParser } from '../../helpers';
import API from '../../services/ApiService';
import { showErrorResultBar, showSuccessResultBar } from '../ResultSnackbar';
import StyledDialogTitle from '../StyledDialogTitle';

enum VersionInfo {
  USE_EXISTING = 'existing',
  USE_LATEST = 'latest',
}

const BUTTON_TEXT_USE_EXISTING = 'Clone the existing policy';
const BUTTON_TEXT_USE_LATEST = 'Use the latest policy template';

function IntroText({ policy }: { policy: IPolicyDocDto }) {
  if (!!policy.customPolicy) {
    // Approved policy has a custom policy attached
    return <>
      Currently this policy is defined by a custom uploaded file.
      Whether you choose to <em>{BUTTON_TEXT_USE_LATEST}</em> or
      to <em>{BUTTON_TEXT_USE_EXISTING}</em>, in either case
      the existing uploaded file will not automatically be associated with the new version.
      If you wish to reuse the custom uploaded file, you will first need to download it
      and then reattach it to the new policy version.
    </>;
  } else if (policy.isEdited) {
    // Approved policy has been edited
    return <>
      The current version of this policy is an edited version of our template policy.
      If you choose to <em>{BUTTON_TEXT_USE_LATEST}</em> then the edits will be lost.
      Choose to <em>{BUTTON_TEXT_USE_EXISTING}</em> to preserve the edits.
    </>;
  } else {
    // Default: approved policy was based on the template (without edits)
    return <>
      The current policy may be based on a previous version of our template.
      We recommend choosing to <em>{BUTTON_TEXT_USE_LATEST}</em> to ensure you have our latest version.
    </>;
  }
}

export interface PolicyNewVersionDialogProps extends StandardProps<DialogProps, 'children'> {
  onClose: () => void;
  onUpdate: (newData: IPolicyDocDto) => void;
  policy: IPolicyDocDto;
}

export default function PolicyNewVersionDialog({ onClose, onUpdate, open, policy }: PolicyNewVersionDialogProps) {
  const [ versionInfo, setVersionInfo ] = useState(VersionInfo.USE_LATEST);

  // const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const handleRadioChange = (e: React.ChangeEvent<any>, value: string) => {
    // const value = (event.target as HTMLInputElement).value;
    setVersionInfo(value === VersionInfo.USE_LATEST ? VersionInfo.USE_LATEST : VersionInfo.USE_EXISTING);
  };

  const handleCreateNewVersion = async () => {
    try {
      const newVersionInfo: IPolicyNewVersionUploadDto = { toUseLatest: versionInfo === VersionInfo.USE_LATEST };
      const res = await API.post(`policyDoc/${policy.versionId}/newVersion`, newVersionInfo);
      onUpdate(res.data.data);
      showSuccessResultBar('New policy version created');
    } catch (err) {
      showErrorResultBar(errorParser(err, 'Unexpected error while creating new policy version'));
      Sentry.captureException(err);
    } finally {
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <StyledDialogTitle>Review/Update the {policy.name}</StyledDialogTitle>
      <DialogContent>
        <Typography
          variant="body1"
          gutterBottom
        >
          <IntroText policy={policy} />
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
        >
          You will have the option to discard this new draft version and revert to the previously approved version.
        </Typography>
        <Typography
          variant="body1"
        >
          <b>When creating the new policy version (please choose):</b>
        </Typography>
        <FormControl
          margin="dense"
        >
          <RadioGroup
            name="newVersionInfo"
            value={versionInfo}
            onChange={handleRadioChange}
          >
            <FormControlLabel
              value={VersionInfo.USE_LATEST}
              control={<Radio />}
              label={BUTTON_TEXT_USE_LATEST}
            />
            <FormControlLabel
              value={VersionInfo.USE_EXISTING}
              control={<Radio />}
              label={BUTTON_TEXT_USE_EXISTING}
            />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          size="small"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={handleCreateNewVersion}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
