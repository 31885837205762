import {
    Checkbox,
    ClickAwayListener,
    Grid,
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    MenuItem,
    MenuList,
    Paper,
    Popover,
    Typography,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import MoreVert from '@material-ui/icons/MoreVert';
import * as Helpers from '../helpers';
import {
    TaskAssignmentDialog,
} from './dialogs';
import {
    ISubTaskDto,
    ITaskAssigneeDto,
    ISubTaskUpdateDto
} from '../../../backend/src/task/interfaces';
import { UNASSIGNED_ID } from './dialogs/AssignmentDialog';
import SubtaskReorderDialog from './dialogs/SubtaskReorderDialog';

const useStyles = makeStyles({
    leftButton: {
      marginLeft: 'auto',
    },
    checkBox: {
      color: 'purple'
    },
    italic: {
      fontStyle: 'italic'
    }
});

export interface ISubTaskInputData {
    id?: string;
    title: string;
    description: string;
}

export interface SubTaskListItemProps {
    subtask: ISubTaskDto;
    subtasks: ISubTaskDto[];
    checked: boolean | undefined;
    readOnly: boolean;
    // handleToggle: () => void;
    taskStatus: string;
    assignees: ITaskAssigneeDto[];
    handleUpdateSubtask: (updateDto: ISubTaskUpdateDto) => void;
    setSubtaskToDelete: (subtaskIdx: string | undefined) => void;
    setSubtaskToComplete: (subtaskIdx: string | undefined) => void;
    setSubTaskIdEditing: (subtaskIdx: string) => void;
}

export default function SubTaskListItem(props: SubTaskListItemProps) {
    const {
      subtask,
      checked,
      readOnly,
      taskStatus,
      assignees,
      handleUpdateSubtask,
      setSubtaskToDelete,
      setSubtaskToComplete,
      setSubTaskIdEditing,
      subtasks,
    } = props;
    const classes = useStyles();
    const [ menuAnchorEl, setMenuAnchorEl ] = useState<null | HTMLElement>(null);
    const [ open, setOpen ] = React.useState(false);
    const [ toShowAssignDialog, setToShowAssignDialog ] = useState(false);
    const [ toShowReorderDialog, setToShowReorderDialog ] = useState(false);

    const handleCloseMenu = () => {
      setMenuAnchorEl(null);
      setOpen(false);
    };

    const handleClickMenuItem = (handler: () => void) => () => {
      handleCloseMenu();
      handler();
    };

    const handleOpen = (currentTarget: any) => {
      setMenuAnchorEl(currentTarget);
      setOpen((prevOpen) => !prevOpen);
    };

    const handleAssignment = (selectedAssigneeId: string) => {
        let updateDto: ISubTaskUpdateDto = {
            assigneeId: selectedAssigneeId
          };
        if (selectedAssigneeId === UNASSIGNED_ID) {
            updateDto = {
                assigneeId: null
            };
        }
      handleUpdateSubtask(updateDto);
    };

    return (
        <>
            <ListItem key={subtask.id} role="listitem" button onClick={() => checked ? null : setSubtaskToComplete(subtask.id)} disabled={taskStatus==='completed' || readOnly}>
                <ListItemIcon>
                    <Checkbox
                        checked={checked || false}
                        tabIndex={-1}
                        disableRipple
                        color={'primary'}
                        disabled={readOnly || checked}
                    />
                </ListItemIcon>
                <ListItemText primary={<Typography component={'div'}>{subtask.title}</Typography>} secondary={<Grid><Typography component={'div'}>{subtask.description}</Typography>{subtask.assigneeName && !subtask.completed && <Typography className={classes.italic} component={'div'}>Assigned to: {subtask.assigneeName}</Typography>}{subtask.completed && <Typography className={classes.italic} component={'div'}> Completed on: {Helpers.formatDate(subtask.completedAt)} by: {subtask.completedByName}</Typography>}</Grid>} />
                <ListItemSecondaryAction>
                    <IconButton disabled={readOnly} edge="end" aria-label="edit" onClick={({currentTarget}) => {handleOpen(currentTarget);}}>
                        <MoreVert />
                    </IconButton>
                    <Popover
                        open={open}
                        anchorEl={menuAnchorEl}
                        onClose={handleCloseMenu}
                        anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'center',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleCloseMenu}>
                                <MenuList
                                    autoFocusItem={open}
                                    id="composition-menu"
                                    aria-labelledby="composition-button"
                                >
                                    <MenuItem key={`${subtask.id}_edit`} disabled={readOnly || subtask.completed} onClick={handleClickMenuItem(() => setSubTaskIdEditing(subtask.id))}>Edit</MenuItem>
                                    <MenuItem key={`${subtask.id}_assign`}disabled={readOnly || subtask.completed} onClick={handleClickMenuItem(() => setToShowAssignDialog(true))}>Assign</MenuItem>
                                    <MenuItem key={`${subtask.id}_move`} disabled={readOnly} onClick={handleClickMenuItem(() => setToShowReorderDialog(true))}>Move</MenuItem>
                                    <MenuItem key={`${subtask.id}_delete`} disabled={readOnly} onClick={handleClickMenuItem(() => setSubtaskToDelete(subtask.id))}>Delete</MenuItem>

                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Popover>
                </ListItemSecondaryAction>
            </ListItem>
            <TaskAssignmentDialog
                assignees={assignees}
                defaultAssigneeId={subtask.assigneeId}
                defaultAssigneeName={subtask.assigneeName}
                onClose={() => setToShowAssignDialog(false)}
                open={toShowAssignDialog}
                handleAssignment={handleAssignment}
                title={'Task'}
            />
            <SubtaskReorderDialog
                subtasks={subtasks}
                subtaskData={subtask}
                onClose={() => setToShowReorderDialog(false)}
                open={toShowReorderDialog}
                onSave={handleUpdateSubtask}
            />
        </>
    );
}
