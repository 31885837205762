import React, { useState } from 'react';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Button,
  createStyles,
  Grid,
  Link,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import Collections from '@material-ui/icons/Collections';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionIconSummary from './AccordionIconSummary';
import Auth from '../services/AuthService';
import { IDocument, IDocumentHistory } from '../../../backend/src/document/interfaces';
import {formatDate, formatFileTitle } from '../helpers';
import * as DocService from '../services/DocService';
import { FileUploadDialog } from './dialogs';
import { ArchiveDialog, InformationDialog } from './dialogs';
import { truncateString } from '../helpers';

const AccordionSummary = withStyles({
  root: {
    'borderBottom': '1px solid rgba(0, 0, 0, .125)',
    'marginBottom': -1,
    'minHeight': 0,
    '&$expanded': {
      minHeight: 0,
    },
  },
  content: {
    'margin': '2px 0',
    '&$expanded': {
      margin: '2px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const styles = (theme: Theme) => createStyles({
  addCommentLink: {
    cursor: 'pointer',
    display: 'inline-block',
    paddingTop: '1rem',
    paddingLeft: '1rem',
  },
  questionnaireEntryHeading: {
    flexBasis: '35%',
    flexShrink: 0,
  },
  addVersion: {
    paddingLeft: '.1rem',
    paddingTop: '1rem',
    paddingBottom: '.75rem',
  },
  questionnaireSecondaryHeading: {
    flexBasis: '20%',
    flexShrink: 0,
  },
  referenceResponse: {
    color: 'rgba(0,0,0,0.54)',
    whiteSpace: 'pre-line',
  },
  questionnaireFinalHeading: {
    flexWrap: 'wrap'
  },
  questionnaireEntryActions: {
    justifyContent: 'flex-start',
    paddingTop: 0,
  },
  commentsExpansionSummary: {
    'padding': 0,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  commentsExpansionDetails: {
    padding: 0,
  },
  commentIcon: {
    fontSize: '0.875rem',
  },
  commentNumberText: {
    paddingLeft: '2px',
    fontSize: '0.875rem',
  },
  commentHeading: {
    fontWeight: 'bold',
    marginTop: '0.5rem',
    color: 'inherit',
  },
  commentNbHeading: {
    marginLeft: '1rem',
    marginTop: '0.5rem',
    color: 'inherit',
  },
  disabledButtonLink: {
    color: '#bfbfbf',
    cursor: 'pointer',
    '&:hover': {
      color: '#bfbfbf',
    },
    paddingLeft: '.1rem',
    paddingTop: '1rem',
    paddingBottom: '.75rem',
  },
});

export interface DocumentVersionProps extends WithStyles<typeof styles> {
  auth: Auth;
  versions?: IDocumentHistory[];
  previousVersionId: string;
  onUpdate: (doc: IDocument) => void;
  handleArchiveVersion: (versionId: string) => void;
  documentData: IDocument;
}

function DocumentVersions(props: DocumentVersionProps) {
  const {
    auth,
    classes,
    versions = [],
    previousVersionId,
    onUpdate,
    handleArchiveVersion,
    documentData
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [toShowArchiveDialog, setToShowArchiveDialog] = useState(false);
  const [toShowArchiveErrorDialog, setToShowArchiveErrorDialog] = useState(false);
  const [archiveVersionIdx, setArchiveVersionIdx] = useState(0);

  function onAddDocuments (newDocs: IDocument[]) {
    onUpdate(newDocs[0]);
  }

  const handleCloseArchiveDialog = () => setToShowArchiveDialog(false);

  const handleConfirmArchive = () => () => {
    handleArchiveVersion(versions[archiveVersionIdx].id);
    handleCloseArchiveDialog();
  };

  const handleArchive = (idx: number) => () => {
    setArchiveVersionIdx(idx);

    if ((versions[idx].tasks || []).length > 0) {
      setToShowArchiveErrorDialog(true);
    } else {
      setToShowArchiveDialog(true);
    }
  };

  const handleAddVersion = () => {
    setIsOpen(true);
  };

  return (
    <>
    <Accordion
      elevation={0}
    >
      <AccordionSummary
        className={classes.commentsExpansionSummary}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography
          variant="body1"
          className={classes.commentHeading}
        >
          Previous Versions
        </Typography>
        {versions.length > 1 &&
          <Typography
            variant="body1"
            className={classes.commentNbHeading}
          >
            <Collections className={classes.commentIcon} />
            <span className={classes.commentNumberText}>
                {versions.filter(v => v.status === 'active').filter(v => v.version !== 'latest').length}
            </span>
          </Typography>
        }
      </AccordionSummary>
      <AccordionDetails
        className={classes.commentsExpansionDetails}
      >
        <Grid container>
          <Grid item xs={12}>
          <Link
                component="button"
                className={!auth.isGranted({ permission: 'documents:upload' })  || documentData.category === 'custom_policy' || documentData.category === 'questionnaire' ? classes.disabledButtonLink : classes.addVersion}
                disabled={!auth.isGranted({ permission: 'documents:upload' }) || documentData.category === 'custom_policy' || documentData.category === 'questionnaire'}
                variant="body2"
                onClick={handleAddVersion}
              >
                Upload new version
              </Link>
            {versions.map((version, idx) => (
              <>
              {version.status === 'active' && version.version !== 'latest' &&
                <Accordion
                    elevation={2}
                    >
                    <AccordionIconSummary>
                      <Grid item xs={5}>
                        <Typography variant="body2">
                            {`${formatFileTitle(version.name)} (v${version.version})`}
                        </Typography>
                      </Grid>
                      <Grid item xs={5} alignItems={'flex-start'}>
                      <Typography variant="body2">
                            {version.createdByName}
                      </Typography>
                      </Grid>
                      <Grid item xs={2} alignItems={'flex-end'}>
                        <Typography variant="body2">
                            {formatDate(version.createdAt)}
                        </Typography>
                      </Grid>
                    </AccordionIconSummary>
                    <AccordionDetails>
                    <Grid container>
                    {version.originalFileName &&
                      <>
                        <Grid item xs={2}>
                            <Typography variant="body2">
                                Original File Name:
                            </Typography>
                          </Grid>
                          <Grid item xs={10}>
                            <Typography variant="body2" className={classes.referenceResponse}>
                                {version.originalFileName}
                            </Typography>
                        </Grid>
                      </>
                    }
                    {version.changeDescription &&
                      <>
                        <Grid item xs={2}>
                            <Typography variant="body2">
                                Update description:
                            </Typography>
                          </Grid>
                          <Grid item xs={10}>
                            <Typography variant="body2" className={classes.referenceResponse}>
                                {version.changeDescription}
                            </Typography>
                        </Grid>
                      </>
                    }
                    </Grid>
                    </AccordionDetails>
                    <AccordionActions className={classes.questionnaireEntryActions}>
                      <Button
                          size="small"
                          color="primary"
                          onClick={DocService.documentDownloadVersionHandler(version.id)}
                          disabled={!auth.isGranted({ permission: 'documents:download' })}
                      >
                          Download Version
                      </Button>
                      <Button
                          size="small"
                          color="inherit"
                          onClick={handleArchive(idx)}
                          disabled={!auth.isGranted({ permission: 'documents:edit' })}
                      >
                          Archive Version
                      </Button>
                    </AccordionActions>
                  </Accordion>
                }
              </>
            ))}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
    <ArchiveDialog
        open={toShowArchiveDialog}
        onClose={handleCloseArchiveDialog}
        onArchive={handleConfirmArchive()}
        title={`Archive v${versions[archiveVersionIdx].version} of the document '${truncateString(versions[archiveVersionIdx].name, 30 )}'?`}
      />
    <FileUploadDialog
        dialogHeaderText={'Upload New Version'}
        documentCategory="report"
        onAddDocuments={onAddDocuments}
        onClose={() => setIsOpen(false)}
        open={isOpen}
        currentDocuments={[]}
        version={true}
        previousVersionId={previousVersionId}
    />
    <InformationDialog
      open={toShowArchiveErrorDialog}
      onClose={() => {setToShowArchiveErrorDialog(false);}}
      title={'Cannot Archive Document'}
      text={'This document version is attached to a task and cannot be archived. First, remove the document from task to archive.'}
    />
    </>
  );
}

export default withStyles(styles, { withTheme: true })(DocumentVersions);
