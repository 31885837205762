import { Theme, WithStyles, withStyles } from '@material-ui/core';
import React from 'react';
import CircleCheckIcon from './CircleCheckIcon';
import { createStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => createStyles({
  icon: {
    color: theme.palette.success.main,
    stroke: theme.palette.success.main,
  },
});

export type ApprovedIconProps = WithStyles<typeof styles>;

function ApprovedIcon({ classes }: ApprovedIconProps) {
  return (
    <CircleCheckIcon
      className={classes.icon}
      text="Approved"
    />
  );
}

export default withStyles(styles, { withTheme: true })(ApprovedIcon);
