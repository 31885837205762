import {
  Card,
  CardContent,
  CardHeader,
  createStyles,
  Grid,
  Menu,
  MenuItem,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  makeStyles,
  Typography,
  Button,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React, { useState, useEffect } from 'react';
import API from '../services/ApiService';
import { MissingDataText, SecondaryField } from './VendorDetailsInfo';
import MetricDialog from '../components/dialogs/MetricDialog';
import { showErrorResultBar } from './ResultSnackbar';

const useStyles = makeStyles((theme) =>
  createStyles({
    buttonLink: {
      color: 'inherit',
      textDecoration: 'underline',
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    cardHeader: {
      paddingBottom: 0,
    },
    table: {
      minWidth: 650,
    },
    linkItem: {
      '& a': {
        color: theme.typography.body1.color,
        cursor: 'pointer',
      },
      '&:nth-child(n+2)': {
        paddingTop: '4px',
      },
      fontSize: '0.8125rem',
    },
    primaryColumn: {
      color: theme.typography.body1.color,
      fontSize: '14px',
    },
    referencesContainer: {
      paddingBottom: '0.8rem',
    },
    referenceResponse: {
      color: 'rgba(0,0,0,0.54)',
      whiteSpace: 'pre-line',
    },
    secondaryColumn: {
      backgroundColor: '#f5f5f5',
      opacity: '0.8',
      fontSize: '0.8125rem',
    },
    smallIconButton: {
      height: '14px',
      width: '14px',
      marginTop: '-6px',
      marginLeft: '0.5rem',
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    smallIconButtonIcon: {
      fontSize: '18px',
    },
    smallIconButtonLabel: {
      height: '0',
    },
    trackedTypography: {
      color: theme.palette.primary.dark,
      fontSize: '14px',
    },
    untrackedTypography: {
      color: 'primary',
      fontSize: '14px',
    },
  })
);

const MuiButton = withStyles({
  root: {
    '&.Mui-disabled': {
      pointerEvents: 'auto',
    },
  },
})(Button);

const ButtonWithTooltip = ({ tooltipText, disabled, onClick, ...other }) => {
  const adjustedButtonProps = {
    disabled: disabled,
    component: disabled ? 'div' : undefined,
    onClick: disabled ? undefined : onClick,
  };


  if (!tooltipText) {
   return  <MuiButton {...other} {...adjustedButtonProps} />;
  }
  return (
    <Tooltip title={tooltipText}>
      <div>
        <MuiButton {...other} {...adjustedButtonProps} />
      </div>
    </Tooltip>
  );
};

export const checkMetricsSelected = (fieldsList) => {
  return fieldsList.every((metricField) => metricField.tracked !== true);
};

function MetricDetails({ metricData, history, fieldData, auth }) {
  const classes = useStyles();

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [fields, setFields] = useState([]);
  const [fieldList, setFieldList] = useState([]);
  const [isMetricDialogOpen, setMetricDialogOpen] = useState(false);
  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(null);

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
    setSubMenuAnchorEl(null);
  };

  const handleClickMenuItem = () => {
    handleCloseMenu();
    history.push({
      pathname: `/metricTracking/metric/${metricData.id}`,
    });
  };

  const handleClickSummaryView = () => {
    handleCloseMenu();
    history.push({
      pathname: `/metricTracking/metric/${metricData.id}/summary`,
    });
  };

  const handleFieldSelect = () => {
    handleCloseMenu();
    setMetricDialogOpen(true);
  };

  const updateMetricDetailsTrackedStatus = (updatedFieldList) => {
    setMetricDialogOpen(false);
    setFieldList(updatedFieldList);
  };

  useEffect(() => {
    async function fetchMetricFields() {
      const res = await API.get(`org-metric/${metricData.id}/metricFields`);
      try {
        setFields(res.data?.data ?? []);
        setFieldList(res.data?.data ?? []);
      } catch (err) {
        showErrorResultBar('Unexpected error loading metric fields');
      }
    }
    fetchMetricFields();

  }, [metricData.id]);

  return (
    <>
      <MetricDialog
        open={isMetricDialogOpen}
        onClose={updateMetricDetailsTrackedStatus}
        fields={fields}
        metric={metricData}
      />
      <Card>
        <CardHeader
          className={classes.cardHeader}
          title={metricData.description || 'Metric details'}
          subheader={metricData.tracked ? 'Tracked' : 'Untracked'}
          action={
            <>
              <IconButton
                onClick={({ currentTarget }) => setMenuAnchorEl(currentTarget)}
              >
                <MoreVertIcon />
              </IconButton>
            </>
          }
        />
        <CardContent>
          <Grid container alignItems="flex-start" spacing={4}>
            {/* Primary column */}
            <Grid item xs={12} sm={8} md={9} className={classes.primaryColumn}>
              {/* {metricData.description && (
                <Typography>{metricData.description}</Typography>
              )} */}
              <Menu
                anchorEl={menuAnchorEl}
                keepMounted
                open={Boolean(menuAnchorEl)}
                onClose={handleCloseMenu}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <MenuItem
                  onClick={handleClickMenuItem}
                  disabled={checkMetricsSelected(fields) || !auth.isGranted({permission: 'esg_metrics:edit'})}
                >
                  <span>View or Enter Data</span>
                </MenuItem>
                <MenuItem onClick={handleClickSummaryView} disabled={checkMetricsSelected(fields) || !auth.isGranted({permission: 'esg_metrics:summary'})}>
                  <span>View Metric Summary</span>
                </MenuItem>
                <MenuItem onClick={handleFieldSelect} disabled={!auth.isGranted({permission: 'esg_metrics:edit'})}>
                  <span>Update Tracked Fields</span>
                </MenuItem>
              </Menu>
              {/* Requestor info */}
              <div className={classes.referencesContainer}>
                {fields ? (
                  <Grid container>
                    <Grid item xs={10} className={classes.viewButton}>
                      <TableContainer>
                        <Table
                          className={classes.table}
                          aria-label="simple table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell>Available Field</TableCell>
                              <TableCell align="left">Field Status</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {fieldList.map((field, index) => (
                              <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                  {field.metric_field_name ? field.metric_field_name : field.name}
                                </TableCell>
                                <TableCell align="left">
                                  <Typography
                                    className={
                                      field.tracked
                                        ? classes.trackedTypography
                                        : classes.untrackedTypography
                                    }
                                  >
                                    {field.tracked ? 'Tracked' : 'Not Tracking'}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                ) : (
                  <MissingDataText />
                )}
              </div>
            </Grid>
            {/* Secondary column */}
            <Grid
              item
              xs={12}
              sm={4}
              md={3}
              className={classes.secondaryColumn}
            >
              {/* <SecondaryField
                title="Tracked Status:"
                value={metricData.tracked ? 'Tracked' : 'Untracked'}
              /> */}
              <Grid item xs={12} className={classes.viewButton}>
                <Tooltip title="Add" placement="top">
                  <ButtonWithTooltip
                    color="primary"
                    tooltipText={checkMetricsSelected(fields) ? 'You need to be tracking fields to enter data' : null}
                    size="small"
                    onClick={handleClickMenuItem}
                    disabled={checkMetricsSelected(fields)}
                  >
                    View or Enter Metric Data
                  </ButtonWithTooltip>
                </Tooltip>
              </Grid>
              <Grid item xs={12} className={classes.viewButton}>
                <ButtonWithTooltip
                  color="primary"
                  tooltipText={checkMetricsSelected(fields) ? 'You need to be tracking fields to enter data' : null}
                  size="small"
                  style={{ marginTop: '1rem' }}
                  onClick={handleClickSummaryView}
                  disabled={checkMetricsSelected(fields) || !auth.isGranted({ permission: 'esg_metrics:summary'})}
                >
                  View Metric Summary
                </ButtonWithTooltip>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

export default MetricDetails;
