import { createStyles, Theme, withStyles , Button} from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import classNames from 'classnames';
import React from 'react';

const styles = createStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(2),
  },
  disabled: {
    margin: theme.spacing(2),
    color: '#bfbfbf',
    backgroundColor: '#fafafa'
  },
}));

function CaptionedAddButton({ classes, className, onClick, disabled, children, ...rest }: ButtonProps) {
  return (
    (disabled ?
        <>
        <Button
        className={classNames(classes!.disabled, className)}
        variant="outlined"
        onClick={onClick}
        disabled={true}
        {...rest}

      >
        <AddIcon />
        {children}
      </Button>
      </>
    :
    <>
    <Button
      className={classNames(classes!.root, className)}
      variant="outlined"
      onClick={onClick}
      {...rest}

    >
      <AddIcon />
      {children}
    </Button>
    </>
    ));
}

export default withStyles(styles, { withTheme: true })(CaptionedAddButton);
