import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  createStyles,
  Divider,
  Grid,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Theme,
  Tooltip,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core';
// import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AttachIcon from '@material-ui/icons/AttachFile';
import LinkIcon from '@material-ui/icons/Link';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import ClearIcon from '@material-ui/icons/Clear';
// import EditIcon from '@material-ui/icons/Edit';
import ErrorIcon from '@material-ui/icons/Error';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { DatePicker } from '@material-ui/pickers';
import * as Sentry from '@sentry/browser';
import classnames from 'classnames';
import { History } from 'history';
import { sortBy } from 'lodash';
import moment, { Moment } from 'moment';
import { IIdNameVersionDto } from '../../../backend/src/common/id-name-version-dto.interface';
import { IIdNameDto } from '../../../backend/src/common/id-name-dto.interface';
import {
  ICreateTaskLinkDto,
  ISubTaskDto,
  ITaskAssigneeDto,
  ITaskDto,
  ITaskGroupDto,
  ITaskLinkDto,
  ITasksResponse,
  ITasksUpdateDto
} from '../../../backend/src/task/interfaces';
import * as Helpers from '../helpers';
import API from '../services/ApiService';
import Auth from '../services/AuthService';
import * as DocService from '../services/DocService';
import { CloseButton } from './buttons';
import {
  ArchiveDialog,
  AttachExistingDocumentDialog,
  ConfirmationDialog,
  LimitReachedDialog,
  RemoveFileDialog,
  TaskAssignmentDialog,
} from './dialogs';
import { TASK_STATUS_MAP } from './FilterViewSelections';
import { showErrorResultBar, showSuccessResultBar } from './ResultSnackbar';
import { DetailSectionHeading } from './MaterialityIssueDetails';
import { RouterLink } from './RouterLink';
import TaskComments from './TaskComments';
import SubTasks from './SubTasks';
import AddTaskDialog from './dialogs/AddTaskDialog';
import AttachTaskLinkDialog from './dialogs/AttachTaskLinkDialog';
import { UNASSIGNED_ID } from './dialogs/AssignmentDialog';
import { convertLocalDate } from '../helpers';

const styles = (theme: Theme) =>
  createStyles({
    dialogCardRoot: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      margin: '0',
      padding: '0',
    },
    dialogCardContent: {
      margin: theme.spacing(2),
      overflowY: 'auto',
    },
    dialogCardActions: {
      paddingTop: '16px',
    },
    smallIconButton: {
      height: '14px',
      width: '14px',
      marginTop: '-6px',
      marginLeft: '0.2rem',
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    smallIconButtonLabel: {
      height: '0',
    },
    smallIconButtonIcon: {
      fontSize: '18px',
    },
    disabledIconButtonIcon: {
      fontSize: '18px',
      color: '#bfbfbf',
    },
    prependSmallIcon: {
      fontSize: '14px',
      height: '14px',
      marginRight: '5px',
      marginBottom: '-1px',
    },
    cardHeader: {
      paddingBottom: '0',
    },
    hidden: {
      display: 'none',
    },
    invalidAssigneeIcon: {
      marginLeft: '4px',
      verticalAlign: 'middle',
    },
    buttonLink: {
      color: 'inherit',
      textDecoration: 'underline',
      fontSize: '14px',
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    disabledButtonLink: {
      color: '#bfbfbf',
      textDecoration: 'underline',
      cursor: 'pointer',
      '&:hover': {
        color: '#bfbfbf',
      },
    },
    referenceRouterLink: {
      color: theme.typography.body1.color,
      display: 'block',
      marginTop: '4px',
      textDecoration: 'underline',
      '&:first-of-type': {
        marginTop: 0,
      },
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    referencesContainer: {
      paddingLeft: '1rem',
      paddingBottom: '0.8rem',
    },
    secondaryColumn: {
      backgroundColor: '#f5f5f5',
      opacity: '0.8',
      fontSize: '0.8125rem',
    },
    tagChip: {
      fontSize: '0.7rem',
      height: '20px',
      margin: theme.spacing(0.25),
    },
    tagLabel: {
      paddingLeft: '8px',
      paddingRight: '8px',
    },
    taskDescription: {
      color: theme.typography.body1.color,
    },
  });

export interface TaskDetailsProps extends WithStyles<typeof styles> {
  assignees?: ITaskAssigneeDto[];
  attachableDocuments: IIdNameVersionDto[];
  auth: Auth;
  collapseRows: () => void;
  collections: IIdNameDto[];
  editTask?: boolean;
  groups: ITaskGroupDto[];
  history: History;
  isCardView?: boolean;
  isDialog?: boolean;
  markComplete: () => Promise<void>;
  markNotRelevant: () => Promise<void>;
  markRelevant: () => Promise<void>;
  markStarted: () => Promise<void>;
  onCloseDialog?: () => void;
  onCompletedDateChange: (newDate: Moment | null) => Promise<void>;
  onDeleteTask: () => void | Promise<void>;
  onDueDateChange: (newDate: Moment | null) => Promise<void>;
  onStartDateChange: (newDate: Moment | null) => Promise<void>;
  onUpdateTask: (updatedTaskInfo: ITaskDto) => Promise<void>;
  onUpdateTaskProperties: (updatedTaskInfo: ITasksResponse) => void | Promise<void>;
  tagOptions: IIdNameDto[];
  taskData: ITaskDto;
  tasks: ITaskDto[];
}

function TaskDetails(props: TaskDetailsProps) {
  const {
    // history,
    // markNotRelevant,
    assignees = [],
    attachableDocuments,
    auth,
    classes,
    collapseRows,
    collections,
    editTask = false,
    groups,
    isCardView = false,
    isDialog = false,
    markComplete,
    markRelevant,
    markStarted,
    onCloseDialog = () => undefined,
    onCompletedDateChange,
    onDeleteTask,
    onDueDateChange,
    onStartDateChange,
    onUpdateTask,
    onUpdateTaskProperties,
    tagOptions,
    taskData,
    tasks,
  } = props;

  const [assignee, setAssignee] = useState<ITaskAssigneeDto | null>(null);
  const [documents, setDocuments] = useState(taskData.documentHistory || []);
  const [documentToArchive, setDocumentToArchive] = useState<IIdNameDto | null>(null);
  const [linkToRemove, setLinkToRemove] = useState<ITaskLinkDto | null>(null);
  const [isTaskAssigned, setIsTaskAssigned] = useState(false);
  const [isValidAssignee, setIsValidAssignee] = useState(true);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [toShowAssignDialog, setToShowAssignDialog] = useState(false);
  const [toShowDueDatePickerDialog, setToShowDueDatePickerDialog] = useState(false);
  const [toShowStartDatePickerDialog, setToShowStartDatePickerDialog] = useState(false);
  const [toShowFileUploadDialog, setToShowFileUploadDialog] = useState(false);
  const [toShowAttachLinkDialog, setToShowAttachLinkDialog] = useState(false);
  // const [toShowPercentCompleteDialog, setToShowPercentCompleteDialog] = useState(false);
  const [toShowRemoveEvidenceDialog, setToShowRemoveEvidenceDialog] = useState(false);
  const [toShowRemoveLinkDialog, setToShowRemoveLinkDialog] = useState(false);
  const [toShowCompletedDateDialog, setToShowCompletedDateDialog] = useState(false);
  const [displayedDueDate, setDisplayedDueDate] = useState<Moment | null>(moment(taskData.dueDate));
  const [displayedStartDate, setDisplayedStartDate] = useState<Moment | null>(moment(taskData.startDate));
  const [displayedCompletedDate, setDisplayedCompletedDate] = useState<Moment | null>(moment(taskData.completedAt));
  const [isDocumentLimitDialogOpen, setIsDocumentLimitDialogOpen] = useState(false);
  const [readOnly, setreadOnly] = useState(false);
  const [ toShowConfirmDeleteTask, setToShowConfirmDeleteTask ] = useState(false);
  const [ isDialogOpen, setIsDialogOpen ] = useState(false);
  const [ docSet, setDocSet ] = useState<IIdNameVersionDto[]>([]);

  useEffect(() => {
    let currAssignee = null;

    if (taskData.assigneeId) {
      currAssignee = { id: taskData.assigneeId, name: taskData.assigneeName };
    }

    setIsTaskAssigned(!!currAssignee);
    setAssignee(currAssignee);
    setDisplayedCompletedDate(moment(taskData.completedAt));
  }, [taskData.assigneeId, taskData.assigneeName, taskData.completedAt]);

  useEffect(() => {
    setreadOnly(!auth.isGranted({permission:'tasks:edit'}));
  }, [auth]);

  useEffect(() => {
    // An 'invalid' assignee is one who's been assigned to a task already but is no longer in the list of allowed assignees.
    // 'assignee = null' corresponds to 'unassigned', which is considered valid.
    // If 'assignees' isn't passed in as a prop, it defaults to [] (ie, length = 0) and we don't worry about checking the validity.
    setIsValidAssignee(
      assignee === null ||
        assignees.length === 0 ||
        assignees.some((a) => a.id === assignee.id)
    );
  }, [assignee, assignees]);

  useEffect(() => {
    const docs = attachableDocuments.map(doc => ({id: doc.id, name: doc.name, version: doc.version} as IIdNameVersionDto)).filter( ( el ) => !documents.map(doc => doc.id).includes( el.id ) );
    setDocSet(docs);
  }, [documents]);

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
    setSubMenuAnchorEl(null);
  };

  const handleClickMenuItem =
    (handler: (event: React.MouseEvent) => void) =>
    (event: React.MouseEvent) => {
      handleCloseMenu();
      handler(event);
    };

  const handleProgressUpdate = async (formValues: {
    percentComplete: number;
    subTasks?: ISubTaskDto[];
  }) => {
    try {
      await API.put(`task/${taskData.id}`, formValues);
      onUpdateTaskProperties({...formValues, status: 'in_progress'});
    } catch (err) {
      Sentry.captureException(err);
      showErrorResultBar('Unexpected error while updating task progress.');
    }
  };

  const updateTaskProgress = (
    checked: (boolean | undefined)[],
    subTaskUpdates?: ISubTaskDto[]
  ) => {

    const progress =
      Math.round(
        (checked.filter((value) => value === true).length / checked.length) *
          100
      ) || 0;

    if (subTaskUpdates === undefined) {
      handleProgressUpdate({ percentComplete: progress });
    } else {
      handleProgressUpdate({
        percentComplete: progress,
        subTasks: subTaskUpdates,
      });
    }
  };

  const handleDueDateChange = (newDate: Moment | null) => {
    if (onDueDateChange) {
      onDueDateChange(newDate);
    }
  };

  const handleStartDateChange = (newDate: Moment | null) => {
    if (onStartDateChange) {
      onStartDateChange(newDate);
    }
  };

  const handleCompletedDateChange = (newDate: Moment | null) => {
    if (onCompletedDateChange) {
      onCompletedDateChange(newDate);
    }
  };

  const handleClickRemoveEvidence = (document: IIdNameDto) => () => {
    setDocumentToArchive(document);
    setToShowRemoveEvidenceDialog(true);
  };

  const handleClickRemoveLink = (link: ITaskLinkDto) => () => {
    setLinkToRemove(link);
    setToShowRemoveLinkDialog(true);
  };

  const handleAttachLink = async (taskLinkForm: ICreateTaskLinkDto) => {
    try {
        const res = (await API.post(`task/${taskData.id}/link`, taskLinkForm)).data.data;
        const updatedLinks = taskData.links?.concat(res);
        onUpdateTaskProperties({ links: updatedLinks });
        showSuccessResultBar('Task link added');
      } catch (err) {
        showErrorResultBar('Unexpected error adding task link');
        Sentry.captureException(err);
      }
  };

  const handleAttachDocument = async (newDoc: IIdNameVersionDto[]) => {
    for (const doc of newDoc) {
      try {
        await API.put(`task/${taskData.id}/documents/${doc.id}`);

        const updatedDocs = documents.concat({
          id: doc.id,
          name: doc.name || '',
          version: doc.version || 'latest',
        } as IIdNameVersionDto);
        setDocuments(updatedDocs);
        onUpdateTaskProperties({ documents: updatedDocs });
        showSuccessResultBar('Document attached successfully.');
      } catch (err) {
        Sentry.captureException(err);
        showErrorResultBar('Unexpected error while attaching the document');
      }
    }
  };

  const handleRemoveEvidence = async (docId: string) => {
    try {
      await API.delete(`task/${taskData.id}/documents/${docId}`);
      const updatedDocs = documents.slice().filter((doc) => doc.id !== docId);
      setDocuments(updatedDocs);
      onUpdateTaskProperties({ documents: updatedDocs });
      showSuccessResultBar('Document removed from task.');

      return true;
    } catch (err) {
      Sentry.captureException(err);
      showErrorResultBar(
        'Unexpected error while archiving evidence. Please try again.'
      );

      return false;
    }
  };

  const handleRemoveLink = async (link: ITaskLinkDto) => {
    setToShowRemoveLinkDialog(false);
    try {
      await API.delete(`task/${taskData.id}/link/${link.id}`);
      const updatedLinks = taskData.links?.slice().filter((l) => l.id !== link.id);
      onUpdateTaskProperties({ links: updatedLinks });
      showSuccessResultBar('Link removed from task.');

      return true;
    } catch (err) {
      Sentry.captureException(err);
      showErrorResultBar(
        'Unexpected error while removing Link. Please try again.'
      );
      return false;
    }
  };

  const handleConfirmDeleteTask = async (isConfirmed: boolean) => {
    setToShowConfirmDeleteTask(false);

    if (isConfirmed) {
      onDeleteTask();
    }
  };

  const handleAssignTasks = async (selectedAssigneeId: string) => {
    const newAssigneeId = selectedAssigneeId === UNASSIGNED_ID ? null : selectedAssigneeId;
    // Don't update if the assignee hasn't changed.
    const tasksToUpdate = tasks.filter(task => task.assigneeId !== newAssigneeId);

    if (tasksToUpdate.length === 0) {
      onUpdateTaskProperties({});
      showSuccessResultBar('None of the selected task assignments needed updating.');

      return;
    }

    const updateDto: ITasksUpdateDto = {
      ids: [taskData.id],
      assigneeId: newAssigneeId
    };

    const res: ITasksResponse = (await API.patch('task', updateDto)).data.data;
    onUpdateTaskProperties(res);
  };

  return (
    <>
      {/* Not Relevant Task */}
      {taskData.status === 'not_relevant' ?
      <Card className={classnames(isDialog && classes.dialogCardRoot)}>
        <CardHeader
          action={
            <>
              <IconButton
                onClick={({ currentTarget }) => setMenuAnchorEl(currentTarget)}
              >
                <MoreVertIcon />
              </IconButton>
              {isDialog && <CloseButton onClick={onCloseDialog} />}
            </>
          }
          className={classnames(!isDialog && classes.cardHeader)}
          title={taskData.name}
        />
        <Menu
          anchorEl={menuAnchorEl}
          keepMounted
          open={Boolean(menuAnchorEl)}
          onClose={handleCloseMenu}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <MenuItem
            onClick={markRelevant}
          >
            Mark relevant
          </MenuItem>
        </Menu>
        {isDialog && <Divider />}
        <CardContent
          className={classnames(isDialog && classes.dialogCardContent)}
        >
          <Grid container spacing={4}>
            {/* Primary column */}
            <Grid
              item
              xs={12}
              sm={isCardView ? 12 : 8}
              md={isCardView ? 12 : isDialog ? 8 : 9}
              container
              direction="column"
              spacing={0}
              justifyContent="space-between"
            >
              {/* Description */}
              <Grid item>
                {taskData.description && (
                  <Typography
                    className={classes.taskDescription}
                    dangerouslySetInnerHTML={{
                      __html: taskData.description.replace(/\n/g, '</p><p>'),
                    }}
                    variant="body2"
                  />
                )}
              </Grid>
              {/*Global Documents*/}
              <LimitReachedDialog
                open={isDocumentLimitDialogOpen}
                onClose={() => setIsDocumentLimitDialogOpen(false)}
                text={
                  'Your subscription plan does not include access to this document. You will need to upgrade your plan in order to download it.'
                }
              ></LimitReachedDialog>
              <Grid item>
                {taskData.globalDocuments &&
                taskData.globalDocuments.length > 0 ? (
                  <>
                    <br />
                    <b>Additional Resources:</b>
                    <br />
                    {taskData.globalDocuments.map((gd) => (
                      <>
                        {gd.tier <= auth.getTier() ? (
                          <>
                          <Link
                            component="button"
                            className={classes.buttonLink}
                            onClick={DocService.documentTemplateDownloadHandler(
                              gd.id
                            )}
                          >
                            {gd.name}

                          </Link>
                          <br />
                          </>
                        ) : (
                          <>
                          <Link
                            component="button"
                            className={classes.disabledButtonLink}
                            onClick={() => setIsDocumentLimitDialogOpen(true)}
                          >
                            {gd.name}
                          </Link>
                          <br />
                          </>
                        )}
                      </>
                    ))}
                  </>
                ) : null}
              </Grid>
            </Grid>
            {/* Secondary column */}
            <Grid
              className={classes.secondaryColumn}
              item
              xs={12}
              sm={isCardView ? 12 : 4}
              md={isCardView ? 12 : isDialog ? 4 : 3}
            >
              {/* Training videos */}
              {taskData.trainingVids && taskData.trainingVids.length > 0 && (
                <>
                  <DetailSectionHeading>Training videos</DetailSectionHeading>
                  <div className={classes.referencesContainer}>
                    {taskData.trainingVids.map((video) => (
                      <RouterLink
                        key={video.id}
                        to={`/training/${video.id}`}
                        className={classes.referenceRouterLink}
                      >
                        {Helpers.truncateString(video.name)}
                      </RouterLink>
                    ))}
                  </div>
                </>
              )}
              {/* Tags */}
              <DetailSectionHeading>Tags</DetailSectionHeading>
              <div className={classes.referencesContainer}>
                {sortBy(taskData.taskTags, ['name']).map((taskTag) => (
                  <Chip
                    classes={{ label: classes.tagLabel }}
                    className={classes.tagChip}
                    key={taskTag.id}
                    label={taskTag.name}
                  />
                ))}
              </div>
            </Grid>
          </Grid>
        </CardContent>
        {isDialog && <Divider />}
        <CardActions
          className={classnames(isDialog && classes.dialogCardActions)}
        >
          <Button
            color="primary"
            size="small"
            onClick={markRelevant}
            disabled={readOnly}
          >
            Mark relevant
          </Button>
        </CardActions>
      </Card>
      :

      /* Relevant Task */

      <Card className={classnames(isDialog && classes.dialogCardRoot)}>
      <CardHeader
        action={
          <>
            <IconButton
              onClick={({ currentTarget }) => setMenuAnchorEl(currentTarget)}
            >
              <MoreVertIcon />
            </IconButton>
            {isDialog && <CloseButton onClick={onCloseDialog} />}
          </>
        }
        className={classnames(!isDialog && classes.cardHeader)}
        title={taskData.name}
        subheader={
          isTaskAssigned ? (
            <>
              <span>
                <Tooltip title="Reassign this task">
                  <Link
                    className={readOnly ? classes.disabledButtonLink : classes.buttonLink}
                    component="button"
                    disabled={readOnly}
                    onClick={() => setToShowAssignDialog(true)}
                  >
                    <Typography>Assigned to:</Typography>
                  </Link>
                </Tooltip>{' '}
                {taskData.assigneeName}
              </span>
              {!isValidAssignee && (
                <Tooltip title="This user no longer has access to tasks">
                  <ErrorIcon className={classes.invalidAssigneeIcon} />
                </Tooltip>
              )}
            </>
          ) : (
            <Tooltip title="Assign this task">
              <Link
                className={readOnly ? classes.disabledButtonLink : classes.buttonLink}
                component="button"
                onClick={() => setToShowAssignDialog(true)}
                disabled={readOnly}

              >
              <Typography>Unassigned</Typography>
              </Link>
            </Tooltip>
          )
        }
      />
      <Menu
        anchorEl={menuAnchorEl}
        keepMounted
        open={Boolean(menuAnchorEl)}
        onClose={handleCloseMenu}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem
          disabled={assignees.length === 0 || readOnly}
          onClick={handleClickMenuItem(() => setToShowAssignDialog(true))}
        >
          {isTaskAssigned ? (
            <span>Reassign task</span>
          ) : (
            <span>Assign task</span>
          )}
        </MenuItem>
        <MenuItem
          disabled={readOnly || (taskData.status === 'completed')}
          onClick={handleClickMenuItem(() => setToShowFileUploadDialog(true))}
        >
          Attach evidence
        </MenuItem>
        {/* { taskData.globalTask &&
        <MenuItem
          disabled={readOnly}
          onClick={markNotRelevant}
        >
          Mark as not relevant
        </MenuItem>
        } */}
        <MenuItem
          disabled={taskData.status === 'completed' || readOnly}
          onClick={handleClickMenuItem(() =>
            setToShowStartDatePickerDialog(true)
          )}
        >
          Change start date
        </MenuItem>
        <MenuItem
          disabled={taskData.status === 'completed' || readOnly}
          onClick={handleClickMenuItem(() =>
            setToShowDueDatePickerDialog(true)
          )}
        >
          Change due date
        </MenuItem>
        {/* <MenuItem
          disabled={taskData.status === 'completed' || readOnly}
          onClick={handleClickMenuItem(() =>
            setToShowPercentCompleteDialog(true)
          )}
        >
          Update percent complete
        </MenuItem> */}
        <MenuItem
          disabled={taskData.status === 'completed' || readOnly}
          onClick={handleClickMenuItem(() =>
            setToShowConfirmDeleteTask(true)
          )}
        >
          Delete task
        </MenuItem>
        {editTask &&
          <MenuItem
            disabled={taskData.status === 'completed' || readOnly}
            onClick={handleClickMenuItem(() =>
              setIsDialogOpen(true)
            )}
          >
            Edit task
          </MenuItem>
        }
      </Menu>
      {isDialog && <Divider />}
      <CardContent
        className={classnames(isDialog && classes.dialogCardContent)}
      >
        <Grid container spacing={4}>
          {/* Primary column */}
          <Grid
            item
            xs={12}
            sm={isCardView ? 12 : 8}
            md={isCardView ? 12 : isDialog ? 8 : 9}
            container
            direction="column"
            spacing={0}
            justifyContent="space-between"
          >
            {/* Description */}
            <Grid item>
              {taskData.description && (
                <Typography
                  className={classes.taskDescription}
                  dangerouslySetInnerHTML={{
                    __html: taskData.description.replace(/\n/g, '</p><p>'),
                  }}
                  variant="body2"
                />
              )}
            </Grid>
            {/*Global Documents*/}
            <LimitReachedDialog
              open={isDocumentLimitDialogOpen}
              onClose={() => setIsDocumentLimitDialogOpen(false)}
              text={
                'Your subscription plan does not include access to this document. You will need to upgrade your plan in order to download it.'
              }
            ></LimitReachedDialog>
            <Grid item>
              {taskData.globalDocuments &&
              taskData.globalDocuments.length > 0 ? (
                <>
                  <br />
                  <b>Additional Resources:</b>
                  <br />
                  {taskData.globalDocuments.map((gd) => (
                    <>
                      {gd.tier <= auth.getTier() ? (
                        <>
                        <Link
                          component="button"
                          className={classes.buttonLink}
                          onClick={DocService.documentTemplateDownloadHandler(
                            gd.id
                          )}
                        >
                          {gd.name}

                        </Link>
                        <br />
                        </>
                      ) : (
                        <>
                        <Link
                          component="button"
                          className={classes.disabledButtonLink}
                          onClick={() => setIsDocumentLimitDialogOpen(true)}
                        >
                          {gd.name}
                        </Link>
                        <br />
                        </>
                      )}
                    </>
                  ))}
                </>
              ) : null}
            </Grid>
            {/* Subtasks */}
            <Grid item>
              <SubTasks
                auth={auth}
                taskId={taskData.id}
                subTasks={taskData.subTasks}
                onUpdateSubTasks={(subTasks) => onUpdateTaskProperties({ subTasks })}
                updateProgress={updateTaskProgress}
                taskStatus={taskData.status}
                markStarted={markStarted}
                readOnly={readOnly}
                assignees={assignees}
              />
            </Grid>
            {/* Comments */}
            <Grid item>
              <TaskComments
                auth={auth}
                comments={taskData.comments}
                onUpdateComments={(comments) => onUpdateTaskProperties({ comments })}
                taskId={taskData.id}
                readOnly={readOnly}
              />
            </Grid>
          </Grid>
          {/* Secondary column */}
          <Grid
            className={classes.secondaryColumn}
            item
            xs={12}
            sm={isCardView ? 12 : 4}
            md={isCardView ? 12 : isDialog ? 4 : 3}
          >
              <>
                <DetailSectionHeading>Task Information</DetailSectionHeading>
                <div className={classes.referencesContainer}>
                  <Grid container justifyContent="space-between">
                    <Grid item>Collection:</Grid>
                    <Grid item>{taskData.collection?.name}</Grid>
                  </Grid>
                  <Grid container justifyContent="space-between">
                    <Grid item>Group:</Grid>
                    <Grid item>{taskData.group?.name}</Grid>
                  </Grid>
                </div>
              </>
            {/* Status */}
            <DetailSectionHeading>
              Status: {TASK_STATUS_MAP[taskData.status]}
            </DetailSectionHeading>
            <div className={classes.referencesContainer}>
              {taskData.status === 'completed' && taskData.completedAt && (
                <Grid container justifyContent="space-between">
                  <Grid item>Completed:</Grid>
                  <Grid item>
                    <Tooltip title="Update the completed date">
                      <Link
                        component="button"
                        onClick={() => setToShowCompletedDateDialog(true)}
                        disabled={readOnly}
                        className={readOnly ? classes.disabledButtonLink : classes.buttonLink}
                      >
                        <CalendarIcon className={classes.prependSmallIcon} />
                        {Helpers.formatDate(taskData.completedAt)}
                      </Link>
                    </Tooltip>
                    <DatePicker
                      className={classes.hidden}
                      onAccept={handleCompletedDateChange}
                      onChange={(date) => setDisplayedCompletedDate(date)}
                      onClose={() => setToShowCompletedDateDialog(false)}
                      open={toShowCompletedDateDialog}
                      placeholder="YYYY-MM-DD"
                      value={displayedCompletedDate}
                    />
                  </Grid>
                </Grid>
              )}
              {taskData.status === 'in_progress' && (
                <Grid container justifyContent="space-between">
                  <Grid item>Percent complete:</Grid>
                  <Grid item>
                    {/* <Tooltip
                      title="Update the percent complete"
                    >
                      <Link
                        component="a"
                        onClick={() => setToShowPercentCompleteDialog(true)}
                        className={classes.buttonLink}
                      >
                        <EditIcon className={classes.prependSmallIcon} />
                        {taskData.percentComplete}%
                      </Link>
                    </Tooltip> */}
                    {taskData.percentComplete}%
                  </Grid>
                </Grid>
              )}
              <Grid container justifyContent="space-between">
                <Grid item>Start date:</Grid>
                <Grid item>
                  {taskData.status === 'completed' ? (
                    taskData.startDate ? Helpers.formatDate(taskData.startDate) : 'None'
                  ) : (
                    <>
                      <Tooltip title="Change the start date">
                        <Link
                          component="button"
                          onClick={() => setToShowStartDatePickerDialog(true)}
                          disabled={readOnly}
                          className={readOnly ? classes.disabledButtonLink : classes.buttonLink}
                        >
                          <CalendarIcon
                            className={classes.prependSmallIcon}
                          />
                          {Helpers.formatDate(taskData.startDate)}
                        </Link>
                      </Tooltip>
                      <DatePicker
                        className={classes.hidden}
                        onAccept={handleStartDateChange}
                        onChange={(date) => setDisplayedStartDate(date)}
                        onClose={() => setToShowStartDatePickerDialog(false)}
                        open={toShowStartDatePickerDialog}
                        placeholder="YYYY-MM-DD"
                        value={displayedStartDate}
                        maxDate={taskData.dueDate ? taskData.dueDate : moment('1/1/2060', 'MM-DD-YYYY')}
                      />
                    </>
                  )}
                </Grid>
              </Grid>
              <Grid container justifyContent="space-between">
                <Grid item>Due date:</Grid>
                <Grid item>
                  {taskData.status === 'completed' ? (
                    Helpers.formatDate(taskData.dueDate)
                  ) : (
                    <>
                      <Tooltip title="Change the due date">
                        <Link
                          component="button"
                          onClick={() => setToShowDueDatePickerDialog(true)}
                          disabled={readOnly}
                          className={readOnly ? classes.disabledButtonLink : classes.buttonLink}
                        >
                          <CalendarIcon
                            className={classes.prependSmallIcon}
                          />
                          {Helpers.formatDate(taskData.dueDate)}
                        </Link>
                      </Tooltip>
                      <DatePicker
                        className={classes.hidden}
                        onAccept={handleDueDateChange}
                        onChange={(date) => setDisplayedDueDate(date)}
                        onClose={() => setToShowDueDatePickerDialog(false)}
                        open={toShowDueDatePickerDialog}
                        placeholder="YYYY-MM-DD"
                        value={displayedDueDate}
                        minDate={taskData.startDate ? taskData.startDate : moment('1/1/2000', 'MM-DD-YYYY')}
                      />
                    </>
                  )}
                </Grid>
              </Grid>
              {taskData.startedAt && (
                <Grid container justifyContent="space-between">
                  <Grid item>In Progress:</Grid>
                  <Grid item>{Helpers.formatDate(taskData.startedAt)}</Grid>
                </Grid>
              )}
            </div>
            {/* Training videos */}
            {taskData.trainingVids && taskData.trainingVids.length > 0 && (
              <>
                <DetailSectionHeading>Training videos</DetailSectionHeading>
                <div className={classes.referencesContainer}>
                  {taskData.trainingVids.map((video) => (
                    <RouterLink
                      key={video.id}
                      to={`/training/${video.id}`}
                      className={classes.referenceRouterLink}
                    >
                      {Helpers.truncateString(video.name)}
                    </RouterLink>
                  ))}
                </div>
              </>
            )}
            {/* Attachments */}
            <DetailSectionHeading>
              Attachments
              <Tooltip title="Attach evidence">
                <IconButton
                  classes={{
                    root: classes.smallIconButton,
                    label: classes.smallIconButtonLabel,
                  }}
                  onClick={() => setToShowFileUploadDialog(true)}
                  disabled={readOnly || taskData.status === 'completed'}
                >
                  <AttachIcon className={classes.smallIconButtonIcon} />
                </IconButton>
              </Tooltip>
            </DetailSectionHeading>
            <div className={classes.referencesContainer}>
              {documents.length > 0 ? (
                documents.map((doc) => (
                  <Grid
                    key={doc.id}
                    container
                    justifyContent="space-between"
                    wrap="nowrap"
                  >
                    <Grid item>
                      <Link
                        component="button"
                        onClick={DocService.documentDownloadVersionHandler(doc.id)}
                        disabled={readOnly}
                        className={readOnly ? classes.disabledButtonLink : classes.buttonLink}
                      >
                        {doc.version === 'latest' ? `${Helpers.truncateString(doc.name)}` : `${Helpers.truncateString(doc.name)} (v${doc.version})`}
                      </Link>
                    </Grid>
                      <Grid item>
                        <Tooltip title="Remove this attachment">
                          <IconButton
                            classes={{
                              root: classes.smallIconButton,
                              label: classes.smallIconButtonLabel,
                            }}
                            onClick={handleClickRemoveEvidence(doc)}
                            disabled={taskData.status === 'completed'}
                          >
                            <ClearIcon
                              className={classes.smallIconButtonIcon}
                            />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                  </Grid>
                ))
              ) : (
                <em>None</em>
              )}
            </div>
            {/* Links */}
            <DetailSectionHeading>
              Links
              <Tooltip title="Attach new link">
                <IconButton
                  classes={{
                    root: classes.smallIconButton,
                    label: classes.smallIconButtonLabel,
                  }}
                  onClick={() => setToShowAttachLinkDialog(true)}
                  disabled={readOnly || taskData.status === 'completed'}
                >
                  <LinkIcon className={classes.smallIconButtonIcon} />
                </IconButton>
              </Tooltip>
            </DetailSectionHeading>
            <div className={classes.referencesContainer}>
              {taskData.links ? (
                taskData.links.map((link) => (
                  <Grid
                    key={link.id}
                    container
                    justifyContent="space-between"
                    wrap="nowrap"
                  >
                    <Grid item>
                    <Link
                      color="inherit"
                      href={link.url}
                      target="_blank"
                    >
                        {link.name}
                      </Link>
                    </Grid>
                      <Grid item>
                        <Tooltip title="Remove this link">
                          <IconButton
                            classes={{
                              root: classes.smallIconButton,
                              label: classes.smallIconButtonLabel,
                            }}
                            onClick={handleClickRemoveLink(link)}
                            disabled={taskData.status === 'completed'}
                          >
                            <ClearIcon
                              className={classes.smallIconButtonIcon}
                            />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                  </Grid>
                ))
              ) : (
                <em>None</em>
              )}
            </div>
            {/* Tags */}
            <DetailSectionHeading>Tags</DetailSectionHeading>
            <div className={classes.referencesContainer}>
              {sortBy(taskData.taskTags, ['name']).map((taskTag) => (
                <Chip
                  classes={{ label: classes.tagLabel }}
                  className={classes.tagChip}
                  key={taskTag.id}
                  label={taskTag.name}
                />
              ))}
            </div>
          </Grid>
        </Grid>
      </CardContent>
      {isDialog && <Divider />}
      <CardActions
        className={classnames(isDialog && classes.dialogCardActions)}
      >
        {taskData.status === 'completed' &&
          <Button
            color="primary"
            size="small"
            onClick={markStarted}
            disabled={readOnly}
          >
            Reopen
          </Button>
        }
        {taskData.status !== 'completed' &&
          <Button
            color="primary"
            size="small"
            onClick={markComplete}
            disabled={readOnly}
          >
            Complete
          </Button>
        }
      </CardActions>
    </Card>
      }
      {/* <FileUploadDialog
        dialogHeaderText="Attach Evidence"
        documentCategory="evidence/task"
        onAddDocuments={handleAttachEvidence}
        onClose={() => setToShowFileUploadDialog(false)}
        open={toShowFileUploadDialog}
        currentDocuments={[]}
      /> */}
      <AttachExistingDocumentDialog
        attachableDocuments={docSet}
        open={toShowFileUploadDialog}
        onClose={() => setToShowFileUploadDialog(false)}
        attachDocument={handleAttachDocument}
      />
      <AttachTaskLinkDialog
        open={toShowAttachLinkDialog}
        taskData={taskData}
        onClose={() => setToShowAttachLinkDialog(false)}
        onSave={handleAttachLink}
      />
      {documentToArchive && (
        <RemoveFileDialog
          document={documentToArchive}
          onClose={() => setToShowRemoveEvidenceDialog(false)}
          onExited={() => setDocumentToArchive(null)}
          onRemoveFile={handleRemoveEvidence}
          open={toShowRemoveEvidenceDialog}
          remove={true}
        />
      )}
      {/* Archive dialog */}
      {toShowRemoveLinkDialog}
      {linkToRemove &&
        <ArchiveDialog
          open={toShowRemoveLinkDialog}
          onClose={() => setToShowRemoveLinkDialog(false)}
          onArchive={() => handleRemoveLink(linkToRemove)}
          title={'Remove link?'}
          archive={false}
          text={`Are you sure you want to remove the link to ${linkToRemove.name} from the task "${taskData.name}"? `}
        />
      }
      <TaskAssignmentDialog
        assignees={assignees}
        defaultAssigneeId={taskData.assigneeId}
        defaultAssigneeName={taskData.assigneeName}
        onClose={() => setToShowAssignDialog(false)}
        open={toShowAssignDialog}
        handleAssignment={handleAssignTasks}
        title={'Task'}
      />
        <ConfirmationDialog
          onResponse={handleConfirmDeleteTask}
          open={toShowConfirmDeleteTask}
          title="Are you sure you want to delete this task?"
        >
           You will lose all information associated with this task.
        </ConfirmationDialog>
        {groups.length > 0 &&
          <AddTaskDialog
            open={isDialogOpen}
            onClose={()=>{setIsDialogOpen(false);}}
            tagOptions={tagOptions}
            collections={collections.map(c => Object({id: c.id, name: c.name}))}
            groups={groups}
            tasks={tasks}
            onSave={onUpdateTask}
            taskData={taskData}
            collapseRows={collapseRows}
          />
        }
    </>
  );
}

export default withStyles(styles, { withTheme: true })(TaskDetails);
