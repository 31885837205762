import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import React, { ReactElement } from 'react';
import StyledDialogTitle from '../StyledDialogTitle';

const defaultTitle = 'Are you sure?';

export interface ConfirmationDialogOrResetProps extends DialogProps {
  children?: string | ReactElement;
  onNo: () => void;
  onYes: () => void;
  title?: string;
}

function ConfirmationDialogOrReset({ children, onYes, onNo, title, ...dialogProps }: ConfirmationDialogOrResetProps) {
  return (
    <Dialog {...dialogProps} disableEscapeKeyDown={true}>
      <StyledDialogTitle>{title || defaultTitle}</StyledDialogTitle>

      {children &&
      <DialogContent>
        <DialogContentText>{children}</DialogContentText>
      </DialogContent>
      }

      <DialogActions>
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={() => onYes()}
        >
          <CheckIcon />
          Yes
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={() => onNo()}
        >
          <CloseIcon />
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialogOrReset;
