import { Dialog, useMediaQuery, useTheme } from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import React from 'react';

export default function ResponsiveDialog({ children, ...dialogProps }: DialogProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      fullScreen={fullScreen}
      {...dialogProps}
    >
      {children}
    </Dialog>
  );
}
