import React from 'react';
import { Card, Grid, Link, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { GEMINI_SUPPORT_EMAIL } from '../helpers';

const supportReferences = [
  {
    label: 'Sustainability Accounting Standards Board (SASB) Standards',
    url: 'https://www.sasb.org/standards/',
  },

  {
    label: 'Task Force on Climate-related Financial Disclosures (TCFD)',
    url: 'https://www.fsb-tcfd.org/',
  },

];

function TheSupportPage() {
  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <Card>
          <Typography variant="h6">Common Support Questions</Typography>
          <Typography paragraph variant="body1">
            The following are some references that pertain to frequently asked
            questions.
          </Typography>
          <List subheader={<Typography variant="body1">References</Typography>}>
            {supportReferences.map(r =>
              <Link key={r.label} href={r.url}>
                <ListItem dense={true}>
                  <ListItemText primary={r.label} />
                </ListItem>
              </Link>
            )}
          </List>
        </Card>
      </Grid>
      <Grid container item md={6}>
        <Card>
          <Typography variant="h6">Contact Us</Typography>
          <br />
          <Typography paragraph variant="body1">
            Send us an email: <Link href={`mailto:${GEMINI_SUPPORT_EMAIL}`}>{GEMINI_SUPPORT_EMAIL}</Link>
          </Typography>
          <Typography paragraph variant="body1">
            We'll get back to you as soon as we can!
          </Typography>
        </Card>
      </Grid>
    </Grid>
  );
}

TheSupportPage.title = 'Support';
TheSupportPage.requiredAuthZ = {
  tier: 0,
  permission: 'support',
};

export default TheSupportPage;
