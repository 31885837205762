import { Button, Link, makeStyles, Typography, createStyles, Theme } from '@material-ui/core';
import * as Sentry from '@sentry/browser';
import React, { useState } from 'react';
import { ITrackViewStart, ITrainingViewDto } from '../../../backend/src/training/interfaces';
import { Link as RouterLink } from 'react-router-dom';
import BuildingBlocksImage from '../components/BuildingBlocksImage';
import Page from '../components/Page';
import PageActions from '../components/PageActions';
import { showErrorResultBar } from '../components/ResultSnackbar';
import StylizedName from '../components/StylizedName';
import { GEMINI_SUPPORT_EMAIL, StaticLinks, TrainingVids } from '../helpers';
import API from '../services/ApiService';
import Auth from '../services/AuthService';

const useStyles = makeStyles((theme: Theme) => createStyles({
  welcomeText: {
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '1.5rem',
    maxWidth: '40rem',
    paddingLeft: '1em',
    paddingRight: '1em',
  },
  welcomeTextBold: {
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '1.5rem',
    maxWidth: '40rem',
    paddingLeft: '1em',
    paddingRight: '1em',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  secondButton: {
    marginLeft: '1rem',
  },
  siteName: {
    color: '#000000',
  }
}));

const OrgManagerWelcome = () => {
  const classes = useStyles();
  const [videoPlayerOpen, setVideoPlayerOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const [videoView, setVideoView] = useState<ITrainingViewDto | null>(null);

  const handleStartVideo = async () => {
    const trackViewInfo: ITrackViewStart = (await API.post(`training/${TrainingVids.WelcomeToSPIO}/view`))?.data?.data;

    if (trackViewInfo) {
      setVideoUrl(trackViewInfo.url);
      setVideoView(trackViewInfo.view);
      setVideoPlayerOpen(true);
    }
  };

  // const handleUpdateVideo = async (updateInfo: ITrainingViewUpdateDto) => {
  //   try {
  //     if (videoView) {
  //       await API.patch(`training/view/${videoView.id}`, updateInfo);
  //     }
  //   } catch (err) {
  //     Sentry.captureException(err);
  //   }
  // };

  // const handleCloseVideo = () => {
  //   setVideoPlayerOpen(false);
  //   setVideoUrl(null);
  //   setVideoView(null);
  // };

  // Handle the case where the video link expires and we need to reset:
  // const handlePlaybackError = (error: any) => {
  //   Sentry.captureException(error);
  //   handleStartVideo();
  // };

  return (
    <>
      <Typography
        className={classes.welcomeText}
        paragraph
        variant="body1"
      >
        Watch the training videos to get started or head straight to your Dashboard.
      </Typography>
      <PageActions center>
        <Button
          component={RouterLink}
          to={'/training/'}
          variant="contained"
        >
          Go To Training
        </Button>
        <Button
          component={RouterLink}
          to={'/dashboard/'}
          variant="contained"
        >
          Go To Dashboard
        </Button>
      </PageActions>
    </>
  );
};

const OrgTraineeWelcome = ({ auth }: { auth: Auth }) => {
  const classes = useStyles();
  const role = auth.getUserRole().toLowerCase();

  return (
    <>
      <Typography
        className={classes.welcomeTextBold}
        paragraph
        variant="body1"
      >
        Your organization is already registered for Gemini. You have automatically been assigned the {role} role and as such, only have access to a subset of the tools.
        Please contact your organization administrator to update your role if additional access is required.
      </Typography>
      <Typography
        className={classes.welcomeText}
        paragraph
        variant="body1"
      >
        Navigate to the <Link
          component={RouterLink}
          to="/training"
          variant="body1"
        >
          Training
        </Link> tab to access your training videos.
        <br />
        Or click the button below to start your training!
      </Typography>
      <PageActions center>
        <Button
          component={RouterLink}
          to={'/training/'}
          variant="contained"
        >
          Go To Training
        </Button>
      </PageActions>
    </>
  );
};

const NoPermissionsWelcome = ({ auth }: { auth: Auth }) => {
  const classes = useStyles();
  const role = auth.getUserRole().toLowerCase();

  return (
    <>
      <Typography
        className={classes.welcomeTextBold}
        paragraph
        variant="body1"
      >
        You don't currently have permissions to view details of this account. Please contact your admin to update your permissions if you should have access.
      </Typography>
    </>
  );
};

const OnDemandWelcome = ({ auth }: { auth: Auth }) => {
  const classes = useStyles();
  const [videoPlayerOpen, setVideoPlayerOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const [videoView, setVideoView] = useState<ITrainingViewDto | null>(null);

  // Required to access the 'Request Demo' endpoint:
  const hasOrgPermission = auth.isGranted({ permission: 'org:edit' });

  const handleRequestDemo = async () => {
    try {
      hasOrgPermission && await API.post('org/requestDemo');
    } catch (err) {
      Sentry.captureException(err);
      showErrorResultBar('Something went wrong requesting a demo. Please try again later');
    }
  };

  const handleStartVideo = async () => {
    // This is the same video the program managers see but it's under a diff't id to allow 'On Demand' users to see it:
    const trackViewInfo: ITrackViewStart = (await API.post(`training/${TrainingVids.IntroToSPIO}/view`))?.data?.data;

    if (trackViewInfo) {
      setVideoUrl(trackViewInfo.url);
      setVideoView(trackViewInfo.view);
      setVideoPlayerOpen(true);
    }
  };

  // const handleUpdateVideo = async (updateInfo: ITrainingViewUpdateDto) => {
  //   try {
  //     if (videoView) {
  //       await API.patch(`training/view/${videoView.id}`, updateInfo);
  //     }
  //   } catch (err) {
  //     Sentry.captureException(err);
  //   }
  // };

  // const handleCloseVideo = () => {
  //   setVideoPlayerOpen(false);
  //   setVideoUrl(null);
  //   setVideoView(null);
  // };

  // Handle the case where the video link expires and we need to reset:
  // const handlePlaybackError = (error: any) => {
  //   Sentry.captureException(error);
  //   handleStartVideo();
  // };

  return (
    <>
      <Typography
        className={classes.welcomeText}
        paragraph
        variant="body1"
      >
        We help startups and small to medium-size businesses affordably build
        robust ESG programs without having to hire a CIO.
      </Typography>
      <Typography
        className={classes.welcomeText}
        paragraph
        variant="body1"
      >
        Please schedule a demo below for a walk-through and to learn more about <StylizedName className={classes.siteName} />.
        We will get back to you ASAP.
      </Typography>
      <PageActions center>
        <Button
          variant="contained"
          onClick={handleStartVideo}
        >
          Play Intro Video
        </Button>
        <Link
          href={StaticLinks.scheduleDemo}
          target="_blank"
          rel="noreferrer"
        >
          <Button
            variant="contained"
            onClick={handleRequestDemo}
            className={classes.secondButton}
          >
            Schedule Demo
          </Button>
        </Link>
      </PageActions>
      {/* {videoUrl && videoView &&
        <VideoDialog
          open={videoPlayerOpen}
          videoViewInfo={{
            name: 'Overview of RhoImpact.com',
            played: videoView.played,
            url: videoUrl,
          }}
          onClose={handleCloseVideo}
          onError={handlePlaybackError}
          onUpdateVideo={handleUpdateVideo}
        />
      } */}
    </>
  );
};

const DefaultWelcome = () => {
  const classes = useStyles();

  // A back-up in case the user is missing permissions.
  return (
    <>
      <Typography
        className={classes.welcomeText}
        paragraph
        variant="body1"
      >
        Interested in learning more?
        Send us an email and we'll get back to you as soon as we can!
        <br />
        <Link href={`mailto:${GEMINI_SUPPORT_EMAIL}`}>{GEMINI_SUPPORT_EMAIL}</Link>
      </Typography>
    </>
  );
};

export interface TheWelcomePageProps {
  auth: Auth;
}

function TheWelcomePage({ auth }: TheWelcomePageProps) {
  let welcome;
  const classes = useStyles();

  if (auth.isGranted({ tier: 1, permission: 'tasks' })) {
    // Use 'tasks' as a proxy for 'OrgManager' permissions
    welcome = <OrgManagerWelcome />;
  } else if (auth.isGranted({ permission: 'training:learn' })) {
    welcome = <OrgTraineeWelcome auth={auth} />;
  } else if (auth.isGranted({ permission: 'is_role:default_org_share' })) {
    welcome = <NoPermissionsWelcome auth={auth} />;
  } else if (auth.isGranted({ permission: 'training:preview' })) {
    welcome = <OnDemandWelcome auth={auth} />;
  } else {
    welcome = <DefaultWelcome />;
  }

  return (
    <Page center>
      <BuildingBlocksImage />
      <Typography
        variant="h4"
        gutterBottom
      >
        Welcome to <StylizedName className={classes.siteName} />!
      </Typography>

      {welcome}
    </Page>
  );
}

TheWelcomePage.title = 'Welcome';

export default TheWelcomePage;
