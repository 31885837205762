import { Button, DialogActions, DialogContent, Link, StandardProps, Typography } from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/styles';
import { History } from 'history';
import React, { useEffect, useState } from 'react';
import { IGoogleOrg, IGoogleOauthCallbackDto } from '../../../../backend/src/user-audit/interfaces';
import API, { ErrorCodes as ApiErrorCodes } from '../../services/ApiService';
import { showErrorResultBar, showSuccessResultBar } from '../ResultSnackbar';
import StyledDialogTitle from '../StyledDialogTitle';
import ResponsiveDialog from './ResponsiveDialog';

const useStyles = makeStyles({
  dialogContent: {
    minWidth: '400px',
  },
  embeddedInstructions: {
    border: '1px solid rgb(33, 43, 54, 0.8)',
    fontSize: '0.9rem',
    padding: '1rem',
  },
  newlineLink: {
    paddingLeft: '1rem',
  },
  clientId: {
    paddingLeft: '1rem',
    fontSize: '0.8rem',
  },
  instructionList: {
    color: 'rgb(33, 43, 54, 1.0)',
    fontSize: '0.95rem',
    lineHeight: '1.5rem',
  },
});

interface GoogleAllowAccessFormProps {
  isSubmitting: boolean;
  onCancel: () => void;
  onFetchOauthUrl: () => Promise<void>;
}

function GoogleAllowAccessForm({ isSubmitting, onCancel, onFetchOauthUrl }: GoogleAllowAccessFormProps) {
  const classes = useStyles();

  return (
    <>
      <DialogContent>
        <Typography
          variant="body1"
          gutterBottom
        >
          You need to be a G Suite account admin to enroll in user audit for the account.
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
        >
          Clicking the button below will take you to a Google sign in page.
          Choose the email account tied to the G Suite account you wish to audit,
          then allow us the permission to:
        </Typography>
        <ol className={classes.instructionList}>
          <li>View users on your domain</li>
          <li>View delegated admin roles for your domain</li>
        </ol>
        <Typography
          variant="body1"
          gutterBottom
        >
          If you encounter a hitch in the setup process, you may need to clear these
          permissions before attempting to re-enroll in the user audit:
        </Typography>
        <ol className={classes.instructionList}>
          <li>Navigate to <Link href="https://myaccount.google.com/permissions"
            target="_blank">myaccount.google.com/permissions</Link></li>
          <li>Click on <em>Gemini User Audit</em></li>
          <li>Click the <em>Remove Access</em> button</li>
        </ol>
        <Typography
          variant="caption"
          gutterBottom
        >
          Note: All security tokens are stored only in encrypted form.
          Other managers on your team will be able to run audits, but
          they <b>can not</b> retrieve the security tokens.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          size="small"
          disabled={isSubmitting}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          size="small"
          color="primary"
          disabled={isSubmitting}
          onClick={onFetchOauthUrl}
        >
          Connect to Google
        </Button>
      </DialogActions>
    </>
  );
}

function ProcessingRequestDialogContent() {
  const classes = useStyles();

  return (
    <DialogContent
      className={classes.dialogContent}
    >
      <Typography
        variant="body1"
      >
        Processing your request...
      </Typography>
    </DialogContent>
  );
}

export interface UserAuditGoogleSetupDialogProps extends StandardProps<DialogProps, 'children'> {
  oauthCallbackQueryParams: IGoogleOauthCallbackDto;
  handleClose: () => void;
  handleNewOrg: (newOrg: IGoogleOrg) => void;
  history: History;
  isLoaded: boolean;
}

export default function UserAuditGoogleSetupDialog(props: UserAuditGoogleSetupDialogProps) {
  const { oauthCallbackQueryParams, open, handleClose, handleNewOrg, history, isLoaded } = props;

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [oauthCode, setOAuthCode] = useState<string>('');
  const [isOAuthCodePosted, setIsOAuthCodePosted] = useState<boolean>(false);

  // Handle the oauth callback query params (if supplied):
  useEffect(() => {
    if (oauthCallbackQueryParams && oauthCallbackQueryParams.code) {
      setOAuthCode(oauthCallbackQueryParams.code);
    }
  }, [oauthCallbackQueryParams]);

  // Handle the oauth code (from the query params):
  useEffect(() => {
    if (isLoaded && oauthCode && !isOAuthCodePosted) {
      setIsOAuthCodePosted(true);
      let errorMsg: string;
      let successMsg: string;

      API
        .post('userAudit/google/oauth', oauthCallbackQueryParams)
        .then((res) => {
          if (!res.data || !res.data.data) {
            errorMsg = 'Unexpected error saving Google org details';
          }

          const createdOrg: IGoogleOrg = res.data.data;
          successMsg = `${createdOrg.name} successfully created`;

          handleNewOrg(createdOrg);
        })
        .catch((err) => {
          const errData = (err.response && err.response.data) || {};
          const statusCode = errData.statusCode;
          const rawMessage = errData.message;

          if (statusCode === ApiErrorCodes.DUPLICATE_VALUE) {
            errorMsg = 'This G Suite account has already been set up for auditing';
          } else if (rawMessage && typeof rawMessage === 'string') {
            errorMsg = rawMessage;
          } else {
            errorMsg = 'Unexpected error while setting up Google org';
          }
        })
        .finally(() => {
          if (errorMsg) {
            showErrorResultBar(errorMsg);
          } else if (successMsg) {
            showSuccessResultBar(successMsg);
          }

          // Clear the query parameters from the url:
          history.push('google');
          handleClose();
        });
    }
  }, [handleClose, handleNewOrg, history, isOAuthCodePosted, isLoaded, oauthCode, oauthCallbackQueryParams]);

  // Retrieves then navigates to the Google sign in url:
  const onFetchOauthUrl = () => {
    setIsSubmitting(true);

    return API
      .post('userAudit/google/org')
      .then((res) => {
        const url = res.data.data;
        window.location.replace(url);
      })
      .catch(() => {
        showErrorResultBar('Unexpected error signing in to Google');
        setIsSubmitting(false);
      });
  };

  const onCancel = () => {
    handleClose();
  };

  const clearData = () => {
    setOAuthCode('');
    setIsOAuthCodePosted(false);
  };

  return (
    <ResponsiveDialog
      open={open}
      onClose={onCancel}
      onExited={clearData}
      disableBackdropClick
    >
      <StyledDialogTitle
        onClose={onCancel}
      >
        G Suite Account Setup
      </StyledDialogTitle>
      {!oauthCode ? (
        <GoogleAllowAccessForm
          isSubmitting={isSubmitting}
          onCancel={onCancel}
          onFetchOauthUrl={onFetchOauthUrl}
        />
      ) : (
        <ProcessingRequestDialogContent />
      )}
    </ResponsiveDialog>
  );
}
