import auth0 from 'auth0-js';
import axios from 'axios';
import jwt from 'jsonwebtoken';
import * as globToRegExp from 'glob-to-regexp';
import * as Sentry from '@sentry/react';
import { API_ROOT } from './ApiService';
import * as jwksClient from 'jwks-rsa';
import { promisify } from 'util';

const customClaimPrefix = process.env.REACT_APP_AUTH0_CUSTOM_CLAIM_PREFIX;
const CLIENT_ORIGIN = `${window.location.protocol}//${window.location.host}`;
const DEFAULT_LOGIN_REDIRECT_URL = '/';
const DEFAULT_SIGNUP_REDIRECT_URL = '/welcome';
const SESSION_STORAGE_KEYS = {
  AUTH_RESULT: 'authResult',
  IS_LOGGED_IN: 'isLoggedIn',
  LOGIN_REDIRECT_URL: 'SPIO.redirectUrl',
  TASKS_FILTERS: 'SPIO.tasksFilters',
  TASKS_SEARCH: 'SPIO.tasksSearch',
  TASKS_VIEW: 'SPIO.tasksView',
};

const LOCAL_STORAGE_KEYS = {
  DASHBOARD_TASKS_FILTER: 'SPIO.dashboardTasksFilter',
  DASHBOARD_TOP_TASKS_TAB: 'SPIO.dashboardTopTasksTab',
};

// tiers and volume limit by tool
export const TIER_LABELS = {
  FREE_TIER: 1,
  STANDARD_TIER: 3,
  ENTERPRISE_TIER: 5,
};

export const TIER_NAMES = {
  1: 'Free',
  3: 'Standard',
  5: 'Enterprise'
};

const MATERIALITY_REGISTER_VOLUME = [0, 1000, 1000, 1000];
const STAKEHOLDER_VOLUME = [0, 10000, 10000, 10000];
const QUESTIONNAIRE_VOLUME = [0, 1000, 1000, 1000];
export const USER_SEAT_VOLUME = [0, 1, 1, 25, 25, 1000];

export default class Auth {
  accessToken;
  appMetadata = {};
  currentOrg;
  primaryOrg;
  allOrgs;
  userMetadata = {};
  expiresAt;
  permissions = [];
  profile;
  state = {};
  tokenRenewalTimeout;
  user;
  isFirstVisit;
  Ready;

  auth0 = new auth0.WebAuth({
    domain: process.env.REACT_APP_AUTH_DOMAIN,
    audience: process.env.REACT_APP_AUTH_API_IDENTIFIER,
    clientID: process.env.REACT_APP_AUTH_CLIENT_ID,
    redirectUri: `${CLIENT_ORIGIN}${process.env.REACT_APP_ROUTER_BASENAME || ''}/callback`,
    responseType: 'token id_token',
    scope: 'openid profile email',
  });

  constructor() {
    this.login = this.login.bind(this);
    this.signup = this.signup.bind(this);
    this.logout = this.logout.bind(this);
    this.fetchOrgDetails = this.fetchOrgDetails.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.isOnDemand = this.isOnDemand.bind(this);
    this.isOrgManager = this.isOrgManager.bind(this);
    this.isOrgTrainee = this.isOrgTrainee.bind(this);
    this.isRegistered = this.isRegistered.bind(this);
    this.getAccessToken = this.getAccessToken.bind(this);
    this.getEmail = this.getEmail.bind(this);
    this.getHubSpotVisitorIdToken = this.getHubSpotVisitorIdToken.bind(this);
    this.getPermissions = this.getPermissions.bind(this);
    this.renewSession = this.renewSession.bind(this);
    this.scheduleRenewal = this.scheduleRenewal.bind(this);
    this.clearRenewal = this.clearRenewal.bind(this);
    this.setSessionVariables = this.setSessionVariables.bind(this);
    this.getResellerLogo = this.getResellerLogo.bind(this);

    const storedTokens = sessionStorage.getItem(SESSION_STORAGE_KEYS.AUTH_RESULT);
    if (storedTokens) {
      this.Ready = this.verifyTokensAndSave(JSON.parse(storedTokens));
    } else {
      this.Ready = Promise.resolve(true);
    }
  }

  handleAuthentication() {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (err || !authResult || !authResult.idToken || !authResult.accessToken) {
          Sentry.captureException(err);

          return reject(err);
        }

        resolve(this.verifyTokensAndSave(authResult));
      });
    });
  }

  isAuthenticated() {
    return new Date().getTime() < this.expiresAt;
  }

  isRegistered() {
    return this.getOrgId() !== undefined && this.getTier() > 0;
  }

  isNotRegistered() {
    return this.appMetadata.roles?.includes('Not Registered');
  }

  accessRevoked() {
    if (this.getOrgId() === undefined || this.allOrgs.length < 1 || this.allOrgs === undefined) {
      return false;
    }
    return !this.allOrgs.includes(this.getOrgId());
  }

  isFreeTier() {
    return this.getTier() <= TIER_LABELS.FREE_TIER;
  }

  login(redirectUrl = DEFAULT_LOGIN_REDIRECT_URL) {
    sessionStorage.setItem(SESSION_STORAGE_KEYS.LOGIN_REDIRECT_URL, redirectUrl);

    this.auth0.authorize();
  }

  signup(redirectUrl = DEFAULT_SIGNUP_REDIRECT_URL) {
    sessionStorage.setItem(SESSION_STORAGE_KEYS.LOGIN_REDIRECT_URL, redirectUrl);

    this.auth0.authorize({ screen_hint: 'signup' });
  }

  logoutAndRedirectToVerifyEmail() {
    this.logout(`${process.env.REACT_APP_ROUTER_BASENAME || ''}/verify`);
  }

  logout(redirectPath = '/') {
    // Remove tokens and expiry time
    this.accessToken = null;
    this.expiresAt = 0;

    // Clear session & local storage
    Object.values(SESSION_STORAGE_KEYS).forEach(key => sessionStorage.removeItem(key));
    Object.values(LOCAL_STORAGE_KEYS).forEach(key => localStorage.removeItem(key));

    // Clear the automatic token renewal
    this.clearRenewal();

    // Logs the user out of auth0.
    // If you don't do this, the cookie at the auth0 endpoint
    // allows them to directly log in again without re-authenticating.
    const returnTo = `${CLIENT_ORIGIN}${redirectPath}`;

    this.auth0.logout({
      client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
      returnTo,
    });
  }

  getAccessToken() {
    return this.accessToken;
  }

  getEmail() {
    return this.user.email;
  }

  getIsEmailFree() {
    return this.appMetadata.isEmailFree;
  }

  getHubSpotVisitorIdToken() {
    return this.user.hubSpotVisitorIdToken;
  }

  getRedirectUrlAfterLogin() {
    const storedRedirect = sessionStorage.getItem(SESSION_STORAGE_KEYS.LOGIN_REDIRECT_URL) || DEFAULT_LOGIN_REDIRECT_URL;

    sessionStorage.removeItem(SESSION_STORAGE_KEYS.LOGIN_REDIRECT_URL);

    return storedRedirect;
  }

  hasBilling() {
    // TODO: update this when customers have billing
    return false;
  }

  getOrgId() {
    return this.appMetadata.activeOrgId || this.appMetadata.orgId;
  }

  getOrgPrimaryId() {
    return this.appMetadata.orgId;
  }

  getPermissions() {
    return this.permissions;
  }

  getPrimaryOrgId() {
    return this.appMetadata.orgId;
  }

  getDashboardTasksFilter() {
    const filterStr = localStorage.getItem(LOCAL_STORAGE_KEYS.DASHBOARD_TASKS_FILTER);

    try {
      const filter = JSON.parse(filterStr);
      return filter || undefined;
    } catch {
      return '';
    }
  }

  setDashboardTasksFilter(newFilter) {
    localStorage.setItem(LOCAL_STORAGE_KEYS.DASHBOARD_TASKS_FILTER, JSON.stringify(newFilter));
  }

  getDashboardTopTasksTab() {
    // 0 for My Tasks (the default), 1 for Unassigned tasks
    const valueStr = localStorage.getItem(LOCAL_STORAGE_KEYS.DASHBOARD_TOP_TASKS_TAB);

    try {
      const value = JSON.parse(valueStr);

      return (value === 0 || value === 1) ? value : 0;
    } catch {
      return 0;
    }
  }

  setDashboardTopTasksTab(newFilter) {
    localStorage.setItem(LOCAL_STORAGE_KEYS.DASHBOARD_TOP_TASKS_TAB, JSON.stringify(newFilter));
  }

  getTasksSearch() {
    const itemStr = sessionStorage.getItem(SESSION_STORAGE_KEYS.TASKS_SEARCH);

    try {
      const item = JSON.parse(itemStr);
      return item || null;
    } catch {
      return null;
    }
  }

  setTasksSearch(searchStr) {
    if (!searchStr) {
      sessionStorage.removeItem(SESSION_STORAGE_KEYS.TASKS_SEARCH);
    } else {
      sessionStorage.setItem(SESSION_STORAGE_KEYS.TASKS_SEARCH, JSON.stringify(searchStr));
    }
  }

  getTasksView() {
    const tasksViewStr = sessionStorage.getItem(SESSION_STORAGE_KEYS.TASKS_VIEW);

    try {
      const tasksView = tasksViewStr && JSON.parse(tasksViewStr);
      return tasksView || '';
    } catch (err) {
      Sentry.captureException(err);

      return '';
    }
  }

  setTasksView(newTasksView) {
    sessionStorage.setItem(SESSION_STORAGE_KEYS.TASKS_VIEW, JSON.stringify(newTasksView));
  }

  getTasksFilters() {
    const tasksFiltersStr = sessionStorage.getItem(SESSION_STORAGE_KEYS.TASKS_FILTERS);

    try {
      const tasksFilters = JSON.parse(tasksFiltersStr);
      return tasksFilters || {};
    } catch {
      return {};
    }
  }

  setTasksFilters(newTasksFilters) {
    sessionStorage.setItem(SESSION_STORAGE_KEYS.TASKS_FILTERS, JSON.stringify(newTasksFilters));
  }

  getUserAvatarLink() {
    return this.user.picture;
  }

  getUserId() {
    return this.user && this.user.sub;
  }

  getUserName() {
    return this.profile.name;
  }

  isGranted(requiredAuthZ) {
    if (requiredAuthZ) {
      let hasRequiredTier = true;
      if (requiredAuthZ.tier) {
        hasRequiredTier = this.hasTier(requiredAuthZ.tier);
      }
      let isPrimaryOrg = true;
      if (requiredAuthZ.primaryOrg) {
        isPrimaryOrg = this.isPrimaryOrg() || this.getPermissions().includes('admin:change_org');
      }
      const requiredPermission = requiredAuthZ.permission;
      const hasRequiredPermission = requiredPermission ? this.getPermissions().includes(requiredPermission) : true;
      return hasRequiredPermission && hasRequiredTier && isPrimaryOrg;
    }

    return true;
  }

  hasTier(requiredTier) {
    const intTier = parseInt(requiredTier, 10);
    const effectiveTier = Number.isNaN(intTier) ? 0 : intTier;

    return this.getTier() >= effectiveTier;
  }

  hasMatchingPermissions(requiredAuthZ) {
    if (requiredAuthZ) {
      const hasRequiredTier = this.hasTier(requiredAuthZ.tier);
      const permissionsRegExp = globToRegExp(requiredAuthZ.permission);

      return hasRequiredTier && this.getPermissions().some(p => permissionsRegExp.test(p));
    } else {
      return true;
    }
  }

  isOnDemand() {
    return this.isGranted({ permission: 'training:preview' });
  }

  isOrgManager() {
    return this.isGranted({ permission: 'is_role:org_manager' });
  }

  isOrgOwner() {
    return this.isGranted({ permission: 'is_role:org_owner' });
  }

  isOrgTrainee() {
    return this.isGranted({ permission: 'is_role:org_trainee' });
  }

  isOrgEmployee() {
    return this.isGranted({ permission: 'is_role:org_employee' });
  }

  isDataEntry() {
    return this.isGranted({ permission: 'is_role:data_entry' });
  }

  isPolicyManager() {
    return this.isGranted({ permission: 'policies:manage' });
  }

  getUserRole() {
    if (this.isOrgOwner()) {
      return 'Organization Owner';
    } else if (this.isOrgManager()) {
      return 'Organization Manager';
    } else if (this.isOrgEmployee()) {
      return 'Employee';
    } else if (this.isDataEntry()) {
      return 'Data Entry';
    } else if (this.isOrgTrainee()) {
      return 'Trainee';
    } else {
      return 'Policy Manager';
    }
  }

  getOrgOwner() {
    if (this.user.sub === this.currentOrg.ownerId) {
      return true;
    } else {
      return false;
    }
  }

  isFree() {
    return this.getTier() === 0;
  }

  getOrgName() {
    return (this.currentOrg && this.currentOrg.name) || '';
  }

  getPrimaryOrgName() {
    return (this.primaryOrg && this.primaryOrg.name) || '';
  }

  isPrimaryOrg() {
    if (this.appMetadata.activeOrgId) {
      return this.appMetadata.activeOrgId === this.appMetadata.orgId;
    } else {
      return true;
    }
  }

  getTier() {
    return (this.currentOrg && this.currentOrg.tier) || 0;
  }

  getMaterialityRegisterLimit() {
    let orgTier = this.getTier();
    if (orgTier >= MATERIALITY_REGISTER_VOLUME.length) {
      orgTier = MATERIALITY_REGISTER_VOLUME.length - 1;
    };
    const materiality_volume = MATERIALITY_REGISTER_VOLUME[orgTier] + this.currentOrg.materialityVolume || 0;
    return materiality_volume;
  };

  getInboundQuestionnaireLimit() {
    let orgTier = this.getTier();
    if (orgTier >= QUESTIONNAIRE_VOLUME.length) {
      orgTier = QUESTIONNAIRE_VOLUME.length - 1;
    };
    const questionnaireVolume = QUESTIONNAIRE_VOLUME[orgTier] + this.currentOrg.questionnaireVolume || 0;

    return questionnaireVolume;
  };

  getStakeholderLimit() {
    let orgTier = this.getTier();
    if (orgTier >= STAKEHOLDER_VOLUME.length) {
      orgTier = STAKEHOLDER_VOLUME.length - 1;
    };
    const stakeholderVolume = STAKEHOLDER_VOLUME[orgTier] + this.currentOrg.stakeholderVolume || 0;

    return stakeholderVolume;
  };

  seatLimitReached(userSeats) {
    let orgTier = this.getTier();
    if (orgTier >= USER_SEAT_VOLUME.length) {
      orgTier = USER_SEAT_VOLUME.length - 1;
    };
    const userVolume = USER_SEAT_VOLUME[orgTier] + this.currentOrg.userVolume || 0;
    return userSeats >=userVolume;
  }

  seatLimit() {
    let orgTier = this.getTier();
    if (orgTier >= USER_SEAT_VOLUME.length) {
      orgTier = USER_SEAT_VOLUME.length - 1;
    };
    const userVolume = USER_SEAT_VOLUME[orgTier] + this.currentOrg.userVolume || 0;
    return userVolume;
  }

  getResellerLogo() {
    return this.currentOrg.resellerLogoSrc;
  }

  async setSession(authResult) {
    this.setSessionStorage(authResult);
    await this.setSessionVariables(authResult);
    await this.fetchOrgDetails();
    await this.scheduleRenewal();
  }

  setSessionStorage(authResult) {
    sessionStorage.setItem(SESSION_STORAGE_KEYS.IS_LOGGED_IN, 'true');
    sessionStorage.setItem(SESSION_STORAGE_KEYS.AUTH_RESULT, JSON.stringify(authResult));
  }

  async setSessionVariables(authResult) {
    const encodedAccessToken = authResult.accessToken;
    const decodedAccessToken = jwt.decode(encodedAccessToken);

    this.user = jwt.decode(authResult.idToken);
    this.user.createdAt = this.user[`${customClaimPrefix}createdAt`] || '';
    this.user.hubSpotVisitorIdToken = this.user[`${customClaimPrefix}hubSpotVisitorIdToken`];
    this.appMetadata = this.user[`${customClaimPrefix}appMetadata`] || {};
    this.userMetadata = this.user[`${customClaimPrefix}userMetadata`] || {};
    this.expiresAt = decodedAccessToken.exp * 1000;
    this.profile = authResult.idTokenPayload;
    this.accessToken = encodedAccessToken; // sent as the Bearer token in API requests
    this.permissions = decodedAccessToken['permissions'];

    // overwrite permissions if org is signed into a non-primary account and is not a system admin, i.e. has change_org permission
    if (!(this.isPrimaryOrg() || (this.permissions.includes('admin:change_org')))) {
      this.permissions = (await axios.get(`${API_ROOT}/org/orgPermissions`, {
        headers: {
          Authorization: `Bearer ${this.getAccessToken()}`,
        },
      })).data?.data || [];
    }
    this.user.isOrgManager = this.isOrgManager();

    if (localStorage.getItem('returningVisitor')) {
      this.isFirstVisit = false;
    } else {
      this.isFirstVisit = true;
      localStorage.setItem('returningVisitor', 'true');
    }
    Sentry.configureScope(scope => {
      scope.setUser({
        id: this.user.sub,
        email: this.user.email,
        name: this.user.name,
        orgId: this.getOrgId(),
        primaryOrgId: this.getPrimaryOrgId()
      });
    });
  }

  async fetchOrgDetails() {
    if ((this.permissions.includes('org') || this.permissions.includes('org:read')) && this.getOrgId()) {
      const orgRes = await axios.get(`${API_ROOT}/org/${this.getOrgId()}`, {
        headers: {
          Authorization: `Bearer ${this.getAccessToken()}`,
        },
      });
      this.currentOrg = orgRes.data.data;
    }

    if (this.getOrgPrimaryId()) {
      const orgRes = await axios.get(`${API_ROOT}/org/${this.getOrgPrimaryId()}`, {
        headers: {
          Authorization: `Bearer ${this.getAccessToken()}`,
        },
      });

      this.primaryOrg = orgRes.data.data;

      const allOrgRes = await axios.get(`${API_ROOT}/org`, {
        headers: {
          Authorization: `Bearer ${this.getAccessToken()}`,
        },
      });

      this.allOrgs = allOrgRes.data.data?.map(org => org.id) || [];
    }
  }

  async renewSession() {
    return new Promise((resolve, _) => {
      this.auth0.checkSession({}, async (err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          resolve(await this.setSession(authResult));
        } else if (err) {
          Sentry.captureException(err);
          this.logout();
          alert(`Could not get a new token (${err.error}: ${err.error_description}).`);
          resolve();
        }
      });
    });
  }

  async scheduleRenewal() {
    const MS_BEFORE_EXPIRY_TO_RENEW = 300000; // renews the token 5 minutes before it expires
    const msToExpiry = this.expiresAt - Date.now();

    if (msToExpiry > 0) {
      this.clearRenewal();

      if (msToExpiry <= MS_BEFORE_EXPIRY_TO_RENEW) {
        // If we refresh the browser close to expiry, then renew the session immediately.
        await this.renewSession();
      } else {
        // Else schedule a renewal for a bit before the session expires.
        const timeout = msToExpiry - MS_BEFORE_EXPIRY_TO_RENEW;

        this.tokenRenewalTimeout = setTimeout(async () => {
          await this.renewSession();
        }, timeout);
      }
    }
  }

  clearRenewal() {
    clearTimeout(this.tokenRenewalTimeout);
  }

  async verifyTokensAndSave(authResult) {
    if (await this.verifyWellFormedTokens(authResult)) {
      await this.setSession(authResult);
    } else {
      this.logout();
    }
  }

  async verifyWellFormedTokens(authResult) {
    const client = jwksClient({
      jwksUri: `https://${process.env.REACT_APP_AUTH_DOMAIN}/.well-known/jwks.json`,
      cache: true,
      strictSsl: false,
    });

    async function getKey(header) {
      const getPubKey = promisify(client.getSigningKey);
      const key = await getPubKey(header.kid);

      return key.getPublicKey();
    }

    try {
      const tokenHeader = jwt.decode(authResult.idToken, { complete: true }).header;
      const publicKey = await getKey(tokenHeader);

      jwt.verify(authResult.idToken, publicKey, { ignoreExpiration: false });
      jwt.verify(authResult.accessToken, publicKey, { ignoreExpiration: false });
    } catch (err) {
      Sentry.captureException(err);

      return false;
    }

    return true;
  }
}
