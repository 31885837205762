import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import * as Sentry from '@sentry/browser';
import { MUIDataTableTextLabels } from 'mui-datatables';
import React, { useCallback, useEffect, useState } from 'react';
import { IUserInviteDto } from '../../../backend/src/user/interfaces';
import API from '../services/ApiService';
import { formatDate } from '../helpers';
import { UserInviteDialog } from './dialogs';
import { showErrorResultBar, showSuccessResultBar } from './ResultSnackbar';
import SpioDataTable, { SpioDataTableColumn } from './SpioDataTable';
import StyledDataTableTitle from './StyledDataTableTitle';
import { roleDownloadMap } from './UserManagementUserDetails';
import { LimitReachedDialog } from '../components/dialogs';
import Auth from '../services/AuthService';
import { handleError } from '../helpers';

const getTableColumns = (tableData: ITableDatum[]): SpioDataTableColumn[] => {
  return [
    {
      name: 'email',
      label: 'Email',
      options: {
        filterOptions: { fullWidth: true }
      },
    },
    {
      name: 'createdAtStr',
      label: 'Sent',
      options: {
        filterOptions: { fullWidth: true }
      },
    },
    {
      name: 'roleStr',
      label: 'Role',
      options: {
        filterOptions: { fullWidth: true }
      },
    },
    // The underlying column value should be a string or a number; here we (arbitrarily) use the id.
    {
      name: 'id',
      label: 'Actions',
      options: {
        customBodyRenderLite: dataIndex => tableData[dataIndex]?.actions || '',
        download: false,
        filter: false,
        searchable: false,
        sort: false,
      },
    },
  ];
};

export interface UserInvitesTableProps {
  invites: IUserInviteDto[];
  isLoading: boolean;
  onAddInvites: (invites: IUserInviteDto[]) => void;
  onDeleteInvite: (inviteId: string) => void;
  limitReached: boolean;
  invitesLeft: number;
  auth: Auth;
}

interface ITableDatum extends IUserInviteDto {
  actions: string | Array<React.ReactElement<any>>;
  createdAtStr: string;
  roleStr: string;
}

// const handleError = (defaultMsg: string = 'unspecified error') => (err: any) => {
//   showErrorResultBar(`${err.response?.data?.error || defaultMsg}`);
//   Sentry.captureException(err);
// };

export default function UserInvitesTable(props: UserInvitesTableProps) {
  const { invites, isLoading, onAddInvites, onDeleteInvite, limitReached, invitesLeft, auth } = props;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLimitDialogOpen, setIsLimitDialogOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tableData, setTableData] = useState<ITableDatum[]>([]);

  const handleClose = () => {
    setIsLimitDialogOpen(false);
  };

  const handleDeleteInvite = useCallback((invite: IUserInviteDto) => async () => {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);

    try {
      await API.delete(`user/invite/${invite.id}`);
      onDeleteInvite(invite.id);
      showSuccessResultBar(`Invitation deleted for ${invite.email}`);
    } catch (err) {
      handleError(err, `Unexpected error deleting invite: ${err || 'unspecified error'}`);
    } finally {
      setIsSubmitting(false);
    }
  }, [isSubmitting, onDeleteInvite]);

  useEffect(() => {
    setTableData(invites.map((invite) => Object({
      ...invite,
      createdAtStr: formatDate(invite.createdAt),
      roleStr: roleDownloadMap[invite.role].text || invite.role,
      actions: (
        <IconButton
          aria-label="delete invitation"
          onClick={handleDeleteInvite(invite)}
          disabled={!auth.isGranted({ permission: 'user_management:edit'})}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      ),
    })));
  }, [handleDeleteInvite, invites, auth]);

  return (<>
    <SpioDataTable
      title={<StyledDataTableTitle
        buttonText="Invite new users"
        isLoading={isLoading}
        title="Invited users"
        onClickNewEntry={() => setIsDialogOpen(true)}
        onClickLimit={() => setIsLimitDialogOpen(true)}
        limitReached={limitReached}
        disabled={!auth.isGranted({ permission: 'user_management:edit'})}
      />}
      columns={getTableColumns(tableData)}
      data={tableData}
      options={{
        download: false,
        elevation: 0,
        filterType: 'multiselect',
        print: false,
        selectableRows: 'none',
        textLabels: {
          body: {
            noMatch: isLoading ? 'Loading...' : 'No pending invitations',
          },
        } as MUIDataTableTextLabels,
      }}
    />
    <UserInviteDialog
      open={isDialogOpen}
      onAddInvites={onAddInvites}
      onClose={() => setIsDialogOpen(false)}
      invitesLeft={invitesLeft}
      auth={auth}
    />
    <LimitReachedDialog
      open={isLimitDialogOpen}
      onClose={handleClose}
      text={'You have reached the maximum seats available for your subscription plan. In order to invite additional users, either upgrade your subscription or disable existing users.'}
    >
    </LimitReachedDialog>
  </>);
}
