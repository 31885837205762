import React, { useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Formik } from 'formik';
import * as PropTypes from 'prop-types';

const styles = theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      width: '60%',
    },
    textAlign: 'left',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  questionTextContainer: {
    marginTop: theme.spacing(1),
  },
  inputContainer: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  actionsContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
});

const RadioYesNo = ({ classes, formikProps, question }) => {
  const { values, handleChange } = formikProps;

  return (
    <>
      <Typography className={classes.questionTextContainer}>
        {question.text}
      </Typography>
      <div className={classes.inputContainer}>
        <FormControl
          component="fieldset"
          margin="dense"
        >
          <RadioGroup
            id={question.id}
            name={question.id}
            value={values[question.id]}
            onChange={handleChange}
          >
            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="No" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
      </div>
    </>
  );
};

const TextInputQuestion = ({ classes, formikProps, question }) => {
  const { values, handleBlur, handleChange } = formikProps;

  return (
    <>
      { !!question.text &&
        <Typography className={classes.questionTextContainer}>
          {question.text}
        </Typography>
      }
      <div className={classes.inputContainer}>
        <TextField
          id={question.id}
          name={question.id}
          value={values[question.id]}
          label={question.label || ''}
          placeholder={question.placeholder || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          fullWidth={true}
          margin="dense"
          InputLabelProps={{
            shrink: (!!question.placeholder) || (!!values[question.id]),
          }}
        />
      </div>
    </>
  );
};

const QuestionBlockClientData = {
  label: 'Client Data Handled',
  questions: [
    {
      id: '131ad28c-dc03-46d2-b455-27a28f78792f',
      text: 'What is the most sensitive Client data your system will process?',
      label: 'Data type(s) processed',
      placeholder: 'Models, accounts, PII, PCI, etc.',
    },
  ],
  component: (props) => TextInputQuestion({ question: props.questions[0], ...props}),
};

const QuestionBlockLiability = {
  label: 'Liability',
  questions: [
    {
      id: 'b1ba35a0-df01-4e3e-85d6-a413e349b379',
      text: 'Is the Vendor accepting legal responsibility for proper handling of Client data?',
    },
  ],
  component: (props) => RadioYesNo({ question: props.questions[0], ...props}),
};

const QuestionBlockSecurityProgram = {
  label: 'Vendor Security Program',
  questions: [
    {
      id: '9277140a-3af0-4ef1-9fce-b16602f59f5c',
      text: 'Does the Vendor have security policies?',
    },
    {
      id: '1fff0307-b31f-4f2a-b562-309e22286d2e',
      text: 'Does the Vendor have a designated security leader?',
    },
    {
      id: 'ff316392-07a0-4268-b77c-17b7855527a4',
      text: 'If \'Yes\', please provide their contact info and their overall role in the company.',
      label: 'Security leader name',
    },
    {
      id: '4169ef03-7670-41b1-b406-32ff593337fc',
      label: 'Security leader email',
    },
    {
      id: 'df873bf2-fc2e-4c8a-b2cd-c3372319176e',
      label: 'Security leader role in company',
    },
  ],
  component: (props) => {
    const { classes, formikProps, questions } = props;

    return (
      <>
        <RadioYesNo
          classes={classes}
          formikProps={formikProps}
          question={questions[0]}
        />
        <RadioYesNo
          classes={classes}
          formikProps={formikProps}
          question={questions[1]}
        />
        <TextInputQuestion
          classes={classes}
          formikProps={formikProps}
          question={questions[2]}
        />
        <TextInputQuestion
          classes={classes}
          formikProps={formikProps}
          question={questions[3]}
        />
        <TextInputQuestion
          classes={classes}
          formikProps={formikProps}
          question={questions[4]}
        />
      </>
    );
  },
};

const QuestionBlockIAM = {
  label: 'IAM',
  questions: [
    {
      id: '93a22e1d-c510-4db5-8c7b-c268498cb0a9',
      text: 'Does the Vendor limit access to Client data to named accounts protected with multi-factor authentication?',
    },
    {
      id: '45522b49-6219-4b07-80ab-3340509c3d01',
      text: 'What is the identity system or systems?',
      label: 'IAM System',
    },
    {
      id: '99b996a6-af45-48aa-bdf8-0258e54f59da',
      text: 'Does the Vendor perform monthly user audits?',
    },
    {
      id: '74685879-d356-4163-9be9-b0cf671101a1',
      text: 'How do subcontractors and the Vendor’s vendors fit into this answer?',
      label: 'Vendor/Contractor Coverage',
    },
  ],
  component: (props) => {
    const { classes, formikProps, questions } = props;

    return (
      <>
        <RadioYesNo
          classes={classes}
          formikProps={formikProps}
          question={questions[0]}
        />
        <TextInputQuestion
          classes={classes}
          formikProps={formikProps}
          question={questions[1]}
        />
        <RadioYesNo
          classes={classes}
          formikProps={formikProps}
          question={questions[2]}
        />
        <TextInputQuestion
          classes={classes}
          formikProps={formikProps}
          question={questions[3]}
        />
      </>
    );
  },
};

const QuestionBlockAuditTrails = {
  label: 'Audit Trails',
  questions: [
    {
      id: '2305e6fd-08be-4a79-819b-d1f389efa1d0',
      text: 'Does the Vendor maintain audit trails to identify user access to Client data?',
    },
  ],
  component: (props) => RadioYesNo({ question: props.questions[0], ...props}),
};

const QuestionBlockSecurityTraining = {
  label: 'Security Training',
  questions: [
    {
      id: '424fe333-a794-482f-8a38-a9e324c28d36',
      text: 'Does the Vendor train employees on security? (Min: phishing, sharing passwords, data classification.)',
    },
  ],
  component: (props) => RadioYesNo({ question: props.questions[0], ...props}),
};

const QuestionBlockVulnScanning = {
  label: 'Vulnerability Scanning',
  questions: [
    {
      id: '077a6275-3f2b-4be3-9e3a-60edf7401143',
      text: 'Does the Vendor scan for vulnerabilities in their systems (internal and external)?',
    },
    {
      id: 'df3be2cf-9ca4-4c76-a01a-9db5769f3642',
      text: 'Please provide a summary of the results.',
      label: 'Number of high severity items'
    },
    {
      id: '1a125c05-646a-47cf-8f33-5abcd88e4e9b',
      label: 'Number of medium severity items'
    },
    {
      id: 'ce38909b-98c9-4b2a-8391-92ddc1603d6d',
      label: 'Number of low severity items'
    },
  ],
  component: (props) => {
    const { classes, formikProps, questions } = props;

    return (
      <>
        <RadioYesNo
          classes={classes}
          formikProps={formikProps}
          question={questions[0]}
        />
        <TextInputQuestion
          classes={classes}
          formikProps={formikProps}
          question={questions[1]}
        />
        <TextInputQuestion
          classes={classes}
          formikProps={formikProps}
          question={questions[2]}
        />
        <TextInputQuestion
          classes={classes}
          formikProps={formikProps}
          question={questions[3]}
        />
      </>
    );
  },
};

const QuestionBlockCloudSecurity = {
  label: 'Cloud Security',
  questions: [
    {
      id: 'b74ff6bc-eb2d-433b-a672-76ca70359033',
      text: 'Does the Vendor system run in the cloud?',
    },
    {
      id: '3e8d74c3-8c06-43b5-bc31-93272c0fee28',
      text: 'How are cloud security configurations actively managed?',
      label: 'Cloud security management',
      placeholder: 'Expert help, AWS best practices, specific tools, etc.',
    },
  ],
  component: (props) => {
    const { classes, formikProps, questions } = props;

    return (
      <>
        <RadioYesNo
          classes={classes}
          formikProps={formikProps}
          question={questions[0]}
        />
        <TextInputQuestion
          classes={classes}
          formikProps={formikProps}
          question={questions[1]}
        />
      </>
    );
  },
};

const QuestionBlockDataEncryption = {
  label: 'Data Encryption',
  questions: [
    {
      id: 'e073290a-7969-4847-9f9f-55956fed96ad',
      text: 'Is Client data encrypted with TLS 1.2+ in transit everywhere in the Vendor system?',
    },
    {
      id: 'bd56b0a6-06db-4360-8de8-af764aced14f',
      text: 'Is Client data always encrypted at rest with AES-256 or comparable encryption?',
    },
    {
      id: '851ead4c-8c7e-44f5-9988-fff6cea72fef',
      label: 'Mechanism',
      placeholder: 'E.g. AWS TDE'
    },
    {
      id: '3c8085b1-97f4-4955-9a07-cb4b506c8b04',
      label: 'Algorithm',
      placeholder: 'E.g. AES-256'
    },
  ],
  component: (props) => {
    const { classes, formikProps, questions } = props;

    return (
      <>
        <RadioYesNo
          classes={classes}
          formikProps={formikProps}
          question={questions[0]}
        />
        <RadioYesNo
          classes={classes}
          formikProps={formikProps}
          question={questions[1]}
        />
        <TextInputQuestion
          classes={classes}
          formikProps={formikProps}
          question={questions[2]}
        />
        <TextInputQuestion
          classes={classes}
          formikProps={formikProps}
          question={questions[3]}
        />
      </>
    );
  },
};

const QuestionBlockBCP = {
  label: 'Business Continuity Plan',
  questions: [
    {
      id: '174a7782-295f-4e54-bbee-4ff065ff5243',
      text: 'Does the Vendor have a Business Continuity Plan (BCP) in place?',
    },
    {
      id: '0af9317d-b9f6-4103-80fb-713541d9405f',
      text: 'What are the Vendor’s Recovery Time Objective and Recovery Point Objective?',
      label: 'Recovery Time Objective'
    },
    {
      id: 'd68e17bf-a456-4906-810a-b4f2e1bd6182',
      label: 'Recovery Point Objective'
    },
    {
      id: '28507951-7968-4168-bb8a-98e35e36a17f',
      text: 'Has the BCP program been tested?',
    },
  ],
  component: (props) => {
    const { classes, formikProps, questions } = props;

    return (
      <>
        <RadioYesNo
          classes={classes}
          formikProps={formikProps}
          question={questions[0]}
        />
        <TextInputQuestion
          classes={classes}
          formikProps={formikProps}
          question={questions[1]}
        />
        <TextInputQuestion
          classes={classes}
          formikProps={formikProps}
          question={questions[2]}
        />
        <RadioYesNo
          classes={classes}
          formikProps={formikProps}
          question={questions[3]}
        />
      </>
    );
  },
};

const getStepBlocks = () => {
  return [
    QuestionBlockClientData,
    QuestionBlockLiability,
    QuestionBlockSecurityProgram,
    QuestionBlockIAM,
    QuestionBlockAuditTrails,
    QuestionBlockSecurityTraining,
    QuestionBlockVulnScanning,
    QuestionBlockCloudSecurity,
    QuestionBlockDataEncryption,
    QuestionBlockBCP,
  ];
};

const StepActions = withStyles(styles, { name: 'StepActions', withTheme: true })(props => {
  const { classes, activeStep, nbSteps, handleBack, handleNext } = props;

  return (
    <div className={classes.actionsContainer}>
      <div>
        <Button
          size="small"
          disabled={activeStep === 0}
          onClick={handleBack}
          className={classes.button}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handleNext}
          className={classes.button}
          disabled={activeStep === nbSteps - 1}
        >
          {activeStep === nbSteps - 1 ? 'Finished!' : 'Next'}
        </Button>
      </div>
    </div>
  );
});

const QuestionnaireStepper = withStyles(styles, { name: 'QuestionnaireStepper', withTheme: true })(props => {
  const { classes, formikProps } = props;
  const [activeStep, setActiveStep] = useState(0);
  const [isComplete, setIsComplete] = useState(false);

  const steps = getStepBlocks();

  function handleNext() {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  }

  function handleBack() {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }

  useEffect(() => {
    setIsComplete(prevIsComplete => prevIsComplete || activeStep === steps.length - 1);
  }, [ activeStep, steps ]);

  return (
    <>
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
      >
        {steps.map((step, index) => {
          const Component = step.component;
          const questions = step.questions;

          return (
            <Step key={step.label}>
              <StepLabel>{step.label}</StepLabel>
              <StepContent>
                <Component
                  classes={classes}
                  index={index}
                  formikProps={formikProps}
                  questions={questions}
                />
                <StepActions
                  activeStep={activeStep}
                  handleBack={handleBack}
                  handleNext={handleNext}
                  nbSteps={steps.length}
                />
              </StepContent>
            </Step>
          );
        }
      )}
      </Stepper>
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography>
            Please note that once you submit, you will not be able to go back
            and change your responses.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            disabled={!isComplete}
            onClick={formikProps.handleSubmit}
            className={classes.button}
          >
            Submit
          </Button>
        </Paper>
    </>
  );
});

export const VENDOR_QUESTIONS = getStepBlocks().reduce((acc, curr) => acc.concat(curr.questions), []);
const initValues = VENDOR_QUESTIONS.reduce((acc, curr) => {
  acc[curr.id] = '';
  return acc;
}, {});

function VendorQuestionnaireDetails({ classes, submitResponses }) {
  return (
    <Paper
      className={classes.root}
      elevation={2}
    >
      <Formik
        initialValues={initValues}
        onSubmit={async (values, { setSubmitting }) => {
          await submitResponses(values);
          setSubmitting(false);
        }}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {formikProps => (
          <QuestionnaireStepper
            formikProps={formikProps}
          />
        )}
      </Formik>
    </Paper>
  );
}

VendorQuestionnaireDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  isValidToken: PropTypes.bool,
  submitResponses: PropTypes.func,
};

VendorQuestionnaireDetails.defaultProps = {
  submitResponses: () => {},
  // onClickSave: () => {},
};

export default withStyles(styles, { withTheme: true })(VendorQuestionnaireDetails);
