import { makeStyles } from '@material-ui/styles';
import { EditorView } from 'prosemirror-view';
import React from 'react';
import { MarkdownMenuItemGroups } from '../lib/markdownMenuItems';
import { MenuButtonGroup } from './MenuButtonGroup';
import { MenuDropdownGroup } from './MenuDropdownGroup';

const useStyles = makeStyles({
  group: {
    '&:nth-of-type(n+2)': {
      borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
    },
    'lineHeight': '30px',
    'padding': '0 4px',
  },
});

export interface MenuBarProps {
  menu: MarkdownMenuItemGroups;
  onAddLink: () => void;
  view: EditorView;
}

export function MenuBar({ menu, onAddLink, view }: MenuBarProps) {
  const classes = useStyles();

  return (
    <div>
      {menu.map(menuGroup => (
        <span
          key={menuGroup.key}
          className={classes.group}
        >
          {menuGroup.isDropdown ? (
            <MenuDropdownGroup view={view} items={menuGroup.items} />
          ) : (
            <MenuButtonGroup view={view} items={menuGroup.items} onAddLink={onAddLink} />
          )}
        </span>
      ))}
    </div>
  );
}
