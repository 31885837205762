import React, { useEffect, useState } from 'react';
import {
    createStyles,
    Link,
    makeStyles,
    Typography,
    DialogActions,
    DialogContent,
    Paper
} from '@material-ui/core';
import {  FormikTextField, FormikSwitchField } from '../components/forms';
import { showErrorResultBar } from '../components/ResultSnackbar';
import { Formik } from 'formik';
import StakeholderQuestionnaireDetails from '../components/StakeholderQuestionnaireDetails';
import API from '../services/ApiService';
import { RouteComponentProps } from 'react-router';
import { SaveButton } from '../components/buttons';
import * as Yup from 'yup';
import { handleError } from '../helpers';

const useStyles = makeStyles(theme => createStyles({
    root: {
        [theme.breakpoints.up('md')]: {
          width: '60%',
        },
        textAlign: 'left',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
      resetContainer: {
        padding: theme.spacing(3),
      },
}));

const ResponseSchema = Yup.object().shape({
    stakeholderName: Yup.string().required('Required.'),
    contactEmail: Yup.string().email(({ value }) => ` ${value} is not a valid email. Please ensure there are no spaces or additional characters included in your email.`).required()
      .required('Required.'),
  });

const LoadingMessage = () => {
    return (
        <Typography variant="h6">
            Loading form...
        </Typography>
    );
};

const ClosedQuestionnaireMessage = () => {
    return (
        <>
            <Typography variant="h6">
                Questionnaire Closed.
            </Typography>
            <Typography
                variant="body1"
                gutterBottom
            >
                We are no longer accepting responses to this questionnaire.
            </Typography>
            <Typography variant="body1">
                If you think this is an error, please contact us at <Link href="mailto:support@rhoimpact.com">support@rhoimpact.com</Link>.
            </Typography>
        </>
    );
};

const InvalidTokenMessage = () => {
    return (
        <>
            <Typography variant="h6">
                Invalid token.
            </Typography>
            <Typography
                variant="body1"
                gutterBottom
            >
                The form may already have been submitted or the link may have expired.
            </Typography>
            <Typography variant="body1">
                Contact us at <Link href="mailto:support@rhoimpact.com">support@rhoimpact.com</Link> if
                you are receiving this message in error.
            </Typography>
        </>
    );
};

const ThankYouMessage = () => {
    return (
        <>
            <Typography variant="h2">
                Thank you!
            </Typography>
            <br />
            <br />
            <Typography
                variant="h5"
                gutterBottom
            >
                We have received your responses. If you provided your email, you will receive a confirmation email containing your responses.
            </Typography>
            <Typography variant="body1">
                Contact <Link href="mailto:support@rhoimpact.com">support@rhoimpact.com</Link> if
                you have any problems or concerns.
            </Typography>
        </>
    );
};

export interface TheOutboundQuestionnaireResponsePageParams {
    id: string;
}

function TheOutboundQuestionnaireResponsePage({ match }: RouteComponentProps<TheOutboundQuestionnaireResponsePageParams>) {
    const classes = useStyles();
    const tokenId = match.params.id;
    const [questionnaireTokenId, setQuestionnaireTokenId] = useState();
    const [isValidToken, setIsValidToken] = useState(false);
    const [allowAnonymous, setAllowAnonymous] = useState(false);
    const [anonymousResponse, setAnonymousResponse] = useState(false);
    const [isClosedToken, setIsClosedToken] = useState(false);
    const [isRespondentInfo, setIsRespondentInfo] = useState(true);
    const [questionnaireTemplate, setQuestionnaireTemplate] = useState();
    const [savedValues, setSavedValues] = useState();
    const [orgName, setOrgName] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [instructions, setInstructions] = useState(null);

    const RespondentInfoPage = () => {
        return (
            <Paper square elevation={0} className={classes.resetContainer}>
            <Typography
                variant="h5"
                gutterBottom
            >
                Before starting the questionnaire, please tell us a little more information about yourself.
            </Typography>
            {instructions ?
            <Typography
                variant="inherit"
                gutterBottom
            >
                {instructions}
            </Typography>
             : null}
      <Formik
        enableReinitialize
        initialValues={{
          stakeholderName: '',
          contactEmail: '',
          anonymousResponse: false
        }}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={ResponseSchema}
        //onReset={onClose}
        onSubmit={async (values, { setSubmitting }) => {
            await handleSave(values);
            setIsRespondentInfo(false);
          }}
      >
        {formikProps => (
          <>
            <DialogContent>
              {/* 'Risk identification' section */}
              <FormikTextField
                    autoFocus
                    field="stakeholderName"
                    formikProps={formikProps}
                    //helperTextStr={'test'}
                    label="Name"
                  />
                  <FormikTextField
                    autoFocus
                    field="contactEmail"
                    formikProps={formikProps}
                    //helperTextStr={'test'}
                    label="Email"
                  />
                <FormikSwitchField
                field="anonymousResponse"
                formikProps={formikProps}
                helperTextStr="By selecting, your responses will remain anonymous, although it will be visible that you have responded to the questionnaire. If this is disabled, the requesting organization does not allow anonymous responses to this questionnaire. Please note that regardless of if you choose to make your responses anonymous or not, this data will only be reviewed by our third-party ESG support team and will not be shared publicly"
                label="Keep responses anonymous?"
                disabled={!allowAnonymous}
                />
            </DialogContent>
            <DialogActions>
            <SaveButton
                text={'Go to questionnaire'}
                disabled={formikProps.isSubmitting}
                onClick={formikProps.handleSubmit}
                className={classes.button}
              />
            </DialogActions>
          </>
        )}
        </Formik>
        </Paper>
    );};

    useEffect(() => {
        (async () => {
            setIsLoading(true);

            try {
                const res = await API.get(`stakeholder-questionnaire/multi-response/${tokenId}`);
                const isValid = (res.data && res.data.data) ? res.data.data.isValid: false;
                const isClosed = (res.data && res.data.data) ? res.data.data.isClosed : false;
                const questionnaireTemplate = (res.data.data && res.data.data.questionnaire.questionnaireTemplate) ? res.data.data.questionnaire.questionnaireTemplate.template : false;
                const orgName = (res.data && res.data.data) ? res.data.data.orgName : 'your company';
                const savedValues = (res.data && res.data.savedValues) ? res.data.savedValues : null;
                const orgAllowAnonymous = (res.data.data && res.data.data.questionnaire.allowAnonymous) ? res.data.data.questionnaire.allowAnonymous : false;
                const instructions = (res.data.data && res.data.data.questionnaire.questionnaireTemplate.instructions) ? res.data.data.questionnaire.questionnaireTemplate.instructions : null;

                setIsValidToken(isValid);
                setIsClosedToken(isClosed);
                setQuestionnaireTemplate(questionnaireTemplate);
                setSavedValues(savedValues);
                setOrgName(orgName);
                setAllowAnonymous(orgAllowAnonymous);
                setInstructions(instructions);
            } catch {
                setIsValidToken(false);
            } finally {
                setIsLoading(false);
            }
        })();
    }, [tokenId]);

    const handleSave = async (formValues: Record<string, any>) => {
        try {
            const res = await API.post(`stakeholder-questionnaire/multi-response/${tokenId}/create-stakeholder`, formValues);
            setQuestionnaireTokenId((res.data && res.data.data.token) ? res.data.data.token : null);
            setAnonymousResponse(formValues.anonymousResponse);
        } catch {
            setIsValidToken(false);
        } finally {
            setIsLoading(false);
        }
    };

    const submitResponses = async (formValues: Record<string, any>, anonymousResponse: boolean) => {
        let errorMsg;

        try {
            await API.post(`stakeholder-questionnaire/${questionnaireTokenId}`, {formValues: formValues, anonymousResponse: anonymousResponse});
            setIsSuccess(true);
        } catch (err) {
            handleError(err, 'Error submitting questionnaire');
        }

        if (errorMsg) {
            showErrorResultBar(errorMsg);
        }
    };

    let page;

    if (isLoading) {
        page = <LoadingMessage />;
    } else if (isSuccess) {
        page = <ThankYouMessage />;
    } else if (isRespondentInfo && isValidToken) {
        page = (
          <RespondentInfoPage />
        );
    } else if (isClosedToken && !isValidToken) {
        page = (
          <ClosedQuestionnaireMessage />
        );
    } else if (isValidToken) {
        page = (
            <StakeholderQuestionnaireDetails
                isValidToken
                tokenId={questionnaireTokenId}
                submitResponses={submitResponses}
                saveResponses={null}
                savedValues={savedValues}
                questionnaireTemplate={questionnaireTemplate}
                orgName={orgName}
                anonymousResponse={anonymousResponse}
            />
        );
    } else {
        page = <InvalidTokenMessage />;
    }

    return (
        <div className={classes.root}>
            {page}
        </div>
    );
}

TheOutboundQuestionnaireResponsePage.title = 'Outbound Questionnaire';

export default TheOutboundQuestionnaireResponsePage;
