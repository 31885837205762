import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import { createStyles, Theme, WithStyles } from '@material-ui/core';
import Auth from '../services/AuthService';
import { RouteComponentProps } from 'react-router';
import logoWithText from '../images/logo-horizontal-rho-gemini-v0-white-white-533x83.png';
import TheTermsFooter from '../components/TheTermsFooter';

const styles = (theme: Theme) => createStyles({
  content: {
    flexGrow: 1,
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },

  grow: {
    flexGrow: 1,
  },

  logo: {
    height: '40px',
    marginTop: '10px;',
  },

  main: {
    flex: '1 0 auto',
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(3),
    },
  },

  root: {
    display: 'flex',
    flexDirection: 'row',
  },
});

export interface TheLoginLayoutProps extends WithStyles<typeof styles>, RouteProps {
  component: React.ComponentType<RouteComponentProps<any> | { auth: Auth }>;
  auth: Auth;
}

function TheLoginLayout({ classes, auth, component: Component, ...rest }: TheLoginLayoutProps) {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <div className={classes.root}>
          <div className={classes.content}>
            <AppBar position="static">
              <Toolbar>
                <div className={classes.grow}>
                  <img src={logoWithText} alt="Gemini" className={classes.logo} />
                </div>

                {auth.isAuthenticated() ?
                  <Button
                    color="inherit"
                    onClick={() => auth.logout()}
                  >
                    Logout
                  </Button>
                  :
                  <Button
                    color="inherit"
                    onClick={() => auth.login()}
                  >
                    Login
                  </Button>
                }
              </Toolbar>
            </AppBar>

            <main className={classes.main}>
              {Component &&
                <Component {...matchProps} auth={auth} />
              }
            </main>

            <TheTermsFooter />
          </div>
        </ div>
      )}
    />
  );
}

export default withStyles(styles, { withTheme: true })(TheLoginLayout);
