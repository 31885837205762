import { Card, CardContent } from '@material-ui/core';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import { makeStyles } from '@material-ui/styles';
import { History } from 'history';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { IGoogleOrg, IGoogleOauthCallbackDto, IProviderOrgIdsUploadDto, IUserAuditIdsUploadDto } from '../../../backend/src/user-audit/interfaces';
import UserAuditGoogleSetupDialog from '../components/dialogs/UserAuditGoogleSetupDialog';
import ProviderHeader from '../components/ProviderHeader';
import ProviderOrgSummary from '../components/ProviderOrgSummary';
import { showErrorResultBar } from '../components/ResultSnackbar';
import UserAuditTableGoogle from '../components/user-audit/UserAuditGoogleTable';
import { getOrgAuditStatus } from '../components/user-audit/UserAuditTableHelpers';
import API from '../services/ApiService';

const useTheUserAuditGooglePageStyles = makeStyles({
  headerCard: {
    width: '100%',
    margin: 0,
  },
});

interface TheUserAuditGooglePageProps {
  history: History;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function TheUserAuditGooglePage({ history }: TheUserAuditGooglePageProps) {
  const classes = useTheUserAuditGooglePageStyles();
  const queryParams = useQuery();
  const oauthCode = queryParams.get('code');
  const oauthState = queryParams.get('state');
  const oauthCallbackQueryParams: IGoogleOauthCallbackDto = {
    code: oauthCode || '',
    state: oauthState || '',
  };

  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ refreshingOrgIdx, setRefreshingOrgIdx ] = useState<number>(-1);
  const [ isSetupDialogOpen, setIsSetupDialogOpen ] = useState<boolean>(!!oauthCode);
  const [ orgs, setOrgs ] = useState<IGoogleOrg[]>([]);
  const [ selectedOrgIdx, setSelectedOrgIdx ] = useState<number>(0);
  const [ selectedOrg, setSelectedOrg ] = useState<IGoogleOrg>();

  // Initial page load:
  useEffect(() => {
    setIsLoading(true);

    API.get('userAudit/google/syncs')
      .then(res => {
        const theData: IGoogleOrg[] = (res.data && res.data.data) ? res.data.data : [];
        setOrgs(theData);
      })
      .catch(() => {
        showErrorResultBar('Unexpected error fetching audit results');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    setSelectedOrg(orgs[selectedOrgIdx]);
  }, [ orgs, selectedOrgIdx ]);

  const clickAddOrg = () => {
    setIsSetupDialogOpen(true);
  };

  const handleNewOrg = (newOrg: IGoogleOrg) => {
    const updatedOrgs = orgs.concat(newOrg);
    setOrgs(updatedOrgs);
  };

  const handleSelectOrgIdx = (idx: number) => {
    setSelectedOrgIdx(idx);
  };

  const clickSyncAllOrgs = async () => {
    setIsLoading(true);

    const ids = orgs.map(org => org.id);
    const uploadDto: IProviderOrgIdsUploadDto = { ids };

    return API.post('userAudit/google/sync', uploadDto)
      .then((res) => {
        const updatedOrgDataArray: IGoogleOrg[] = (res.data && res.data.data) ? res.data.data : [];
        setOrgs(updatedOrgDataArray);
      })
      .catch(() => {
        showErrorResultBar('Unexpected error syncing');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSyncOrg = async (idx: number) => {
    setSelectedOrgIdx(idx);
    setRefreshingOrgIdx(idx);

    const org = orgs[idx];
    if (!org) {
      setRefreshingOrgIdx(-1);

      return;
    }

    const uploadDto: IProviderOrgIdsUploadDto = { ids: [ org.id ] };

    return API.post('userAudit/google/sync', uploadDto)
      .then((res) => {
        const updatedOrgDataArray: IGoogleOrg[] = (res.data && res.data.data) ? res.data.data : [];
        const updatedOrg = updatedOrgDataArray[0];

        if (updatedOrg) {
          const updatedOrgs = orgs.slice();
          updatedOrgs[idx] = updatedOrg;
          setOrgs(updatedOrgs);
        }
      })
      .catch(() => {
        showErrorResultBar('Unexpected error syncing');
      })
      .finally(() => {
        setRefreshingOrgIdx(-1);
      });
  };

  const handleConfirmSelected = async () => {
    const status = getOrgAuditStatus(selectedOrg);
    const audit = selectedOrg && selectedOrg.syncs[0];
    const auditId = audit && audit.id;

    if (status !== 'pending' || !auditId) {
      return;
    }

    setRefreshingOrgIdx(selectedOrgIdx);
    const uploadDto: IUserAuditIdsUploadDto = { auditIds: [ auditId ] };

    return API.post('userAudit/google/confirm', uploadDto)
      .then((res) => {
        const updatedOrgDataArray: IGoogleOrg[] = (res.data && res.data.data) ? res.data.data : [];
        const updatedOrg = updatedOrgDataArray[0];

        if (updatedOrg) {
          const updatedOrgs = orgs.slice();
          updatedOrgs[selectedOrgIdx] = updatedOrg;
          setOrgs(updatedOrgs);
        }
      })
      .catch(() => {
        showErrorResultBar('Unexpected error confirming user audit');
      })
      .finally(() => {
        setRefreshingOrgIdx(-1);
      });
  };

  return (
    <>
      <Card
        className={classes.headerCard}
      >
        <ProviderHeader
          hasOrgs={orgs.length > 0}
          isLoading={isLoading}
          onClickSyncAllOrgs={clickSyncAllOrgs}
          onClickAddOrg={clickAddOrg}
          provider="google"
          title="G Suite Accounts"
        />
        <CardContent>
          <ProviderOrgSummary
            handleSelectOrgIdx={handleSelectOrgIdx}
            handleSyncOrg={handleSyncOrg}
            isLoading={isLoading}
            orgs={orgs}
            refreshingOrgIdx={refreshingOrgIdx}
            selectedOrgIdx={selectedOrgIdx}
          />
          <UserAuditTableGoogle
            handleConfirmSelected={handleConfirmSelected}
            isLoading={isLoading || refreshingOrgIdx === selectedOrgIdx}
            orgs={orgs}
            selectedOrgIdx={selectedOrgIdx}
          />
        </CardContent>
      </Card>
      <UserAuditGoogleSetupDialog
        oauthCallbackQueryParams={oauthCallbackQueryParams}
        open={isSetupDialogOpen}
        handleClose={() => setIsSetupDialogOpen(false)}
        handleNewOrg={handleNewOrg}
        history={history}
        isLoaded={!isLoading}
      />
    </>
  );
}

TheUserAuditGooglePage.icon = <PermIdentityIcon />;
TheUserAuditGooglePage.requiredAuthZ = {
  tier: 1,
  permission: 'user_audit',
};
TheUserAuditGooglePage.routePath = '/userAudit/google';
TheUserAuditGooglePage.title = 'User Audit: Google';

export default TheUserAuditGooglePage;
