import { TextField } from '@material-ui/core';
import { BaseTextFieldProps } from '@material-ui/core/TextField';
import Autocomplete, { AutocompleteProps } from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/styles';
import { FormikProps, FormikValues } from 'formik';
import React, { useState } from 'react';
import { helperAndErrorText } from '.';

const useStyles = makeStyles({
  inputField: {
    marginRight: 0,
    marginLeft: 0,
  },
  flexSpan: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
  }
});

interface FormikAutocompleteIdNameFieldProps<Values extends FormikValues> extends BaseTextFieldProps {
  field: keyof Values;
  formikProps: FormikProps<Values>;
  helperTextStr?: string; // can be overridden with the usual 'helperText' prop
  options: any[];
}

export default function FormikAutocompleteIdNameField<Values extends FormikValues>(props: FormikAutocompleteIdNameFieldProps<Values>) {
  const classes = useStyles();
  const { children, field, formikProps, helperTextStr, options, variant = 'filled', ...otherTextFieldProps } = props;
  const { errors, values, handleChange, handleBlur } = formikProps;
  const fieldStr = field.toString();
  const [ value, setValue ] = useState<any>(values[field]);

  return (
    <>
        <Autocomplete
          autoComplete
          openOnFocus
          freeSolo={false}
          id={fieldStr}
          options={options}
          value={value}
          getOptionLabel={(option) => option.name || ''}
          onChange={(e, v) => {
            formikProps.setFieldValue(fieldStr, v); // handles clearing and selection of an option
            setValue(v); // seems necessary for the option filtering to work
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              className={classes.inputField}
              name={fieldStr}
              onBlur={handleBlur}
              onChange={handleChange} // handles free input
              //fullWidth
              variant={variant as any}
              error={!!errors[field]}
              helperText={helperAndErrorText(helperTextStr, errors[field]?.toString())}
              {...otherTextFieldProps}
            >
              {children}
            </TextField>
          )}
        />

  </>
  );
}
