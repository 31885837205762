import React, { useEffect } from 'react';
import Auth from '../services/AuthService';
import { Typography } from '@material-ui/core';

export interface TheLogoutPageProps {
  auth: Auth;
}

export function TheLogoutPage({ auth }: TheLogoutPageProps) {
  useEffect(() => {
    auth.logout();
  }, [ auth ]);

  return (
    <>
      <Typography variant="h3" align="center">Logout in progress.</Typography>
    </>
  );
}
