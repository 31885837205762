import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    createStyles,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    Link,
    makeStyles,
    Menu,
    MenuItem,
    Switch,
    Typography,
    Tooltip
  } from '@material-ui/core';
  import MoreVertIcon from '@material-ui/icons/MoreVert';
  import {
    OutboundQuestionnaireLinkDialog,
  } from './dialogs';
  import React, { useState, useEffect } from 'react';
  import API from '../services/ApiService';
  import Auth from '../services/AuthService';
  import { History } from 'history';
  import { DatePicker } from '@material-ui/pickers';
  import CalendarIcon from '@material-ui/icons/CalendarToday';
  import moment, { Moment } from 'moment';
  import { SecondaryField } from './VendorDetailsInfo';
  import * as Helpers from '../helpers';
  import { IOutboundQuestionnaireDetailsDto } from '../../../backend/src/outbound-questionnaire/interfaces/outbound-questionnaire-details-dto.interface';

  const useStyles = makeStyles((theme) =>
    createStyles({
      buttonLink: {
        color: 'inherit',
        textDecoration: 'underline',
        cursor: 'pointer',
        '&:hover': {
          color: theme.palette.primary.main,
        },
      },
      cardHeader: {
        paddingBottom: 0,
      },
      hidden: {
        display: 'none',
      },
      linkItem: {
        '& a': {
          color: theme.typography.body1.color,
          cursor: 'pointer',
        },
        '&:nth-child(n+2)': {
          paddingTop: '4px',
        },
        fontSize: '0.8125rem',
      },
      prependSmallIcon: {
        fontSize: '14px',
        height: '14px',
        marginRight: '5px',
        marginBottom: '-1px',
      },
      primaryColumn: {
        color: theme.typography.body1.color,
        fontSize: '14px',
      },
      referencesContainer: {
        paddingLeft: '1rem',
        paddingBottom: '0.8rem',
      },
      referenceResponse: {
        color: 'rgba(0,0,0,0.54)',
        whiteSpace: 'pre-line',
      },
      secondaryColumn: {
        backgroundColor: '#f5f5f5',
        opacity: '0.8',
        fontSize: '0.8125rem',
      },
      smallIconButton: {
        height: '14px',
        width: '14px',
        marginTop: '-6px',
        marginLeft: '0.5rem',
        '&:hover': {
          color: theme.palette.primary.main,
        },
      },
      switchField: {
        marginLeft: 0,
      },
      smallIconButtonIcon: {
        fontSize: '18px',
      },
      viewButton: {
        fontSize: '18px',
        textAlign: 'center',
        alignItems: 'center'
      },
      smallIconButtonLabel: {
        height: '0',
      },
      tagChip: {
      margin: '4px',
      color: theme.palette.primary.main,
      background: theme.palette.grey[200]
    },
    questionnaireDescription: {
      color: theme.typography.body1.color,
      fontSize: '14px',
    },
    disabledButtonLink: {
      color: '#bfbfbf',
      textDecoration: 'underline',
      cursor: 'pointer',
      '&:hover': {
        color: '#bfbfbf',
      },
    },
    })
  );

  export interface OutboundQuestionnaireDetailsProps {
    auth: Auth;
    isDialog?: boolean;
    onCloseDialog?: () => void;
    questionnaireData: IOutboundQuestionnaireDetailsDto;
    history: History;
    markOpen: () => void;
    markClose: () => void;
    questionnaireStatus: string;
    onCloseDateChange: (newDate: Moment | null) => Promise<void>;
    onAnonymousToggle: (anonymous: boolean | null) => Promise<void>;
    onMultipleResponseToggle: (multipleResponse: boolean | null) => Promise<void>;
  }

  function OutboundQuestionnaireDetails(props: OutboundQuestionnaireDetailsProps) {
    const {
      isDialog = false,
      questionnaireData,
      history,
      markOpen,
      markClose,
      questionnaireStatus,
      onCloseDateChange,
      onAnonymousToggle,
      onMultipleResponseToggle,
      auth
    } = props;

    const classes = useStyles();
    const [toShowEditDialog, setToShowEditDialog] = useState(false);
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [toShowLinkDialog, setToShowLinkDialog] = useState(false);
    const [questionnaire, setQuestionnaire] = useState(false);
    const [anonymousQuestionnaire, setAnonymousQuestionnaire] = useState<boolean>(questionnaireData?.outboundQuestionnaire[0]?.allowAnonymous ? questionnaireData.outboundQuestionnaire[0]?.allowAnonymous : false);
    const [multipleResponseQuestionnaire, setMultipleResponseQuestionnaire] = useState<boolean>(questionnaireData?.outboundQuestionnaire[0]?.allowMultipleResponses ? questionnaireData.outboundQuestionnaire[0]?.allowMultipleResponses : false);
    const [displayedCloseDate, setDisplayedCloseDate] = useState<Moment | null>(questionnaireData.outboundQuestionnaire[0]?.closeDate ? moment(questionnaireData.outboundQuestionnaire[0]?.closeDate) : null);
    const [toShowCloseDatePickerDialog, setToShowCloseDatePickerDialog] = useState(false);
    const [readOnly, setreadOnly] = useState(false);

    useEffect(() => {
      setreadOnly(!auth.isGranted({permission:'outbound_questionnaire:edit'}));
    }, [auth]);

    useEffect(() => {
      API.get(`outbound-questionnaire/questionnaire/${questionnaireData.id}`)
        .then((res) => setQuestionnaire(res.data?.data))
        .catch((err) => {
        });
    }, [setQuestionnaire, questionnaireData]);

    const handleClickMenuItem = (handler: () => void) => () => {
        handleCloseMenu();
        handler();
      };

    const handleClickAllResponseView = () => {
        handleCloseMenu();
        history.push({
          pathname: `/outbound-questionnaire/responses/${questionnaireData.id}`,
        });
    };

    const handleCloseMenu = () => {
      setMenuAnchorEl(null);
    };

    const handleCloseDateChange = (newDate: Moment | null) => {
      if (onCloseDateChange) {
        onCloseDateChange(newDate);
      }
    };

    const clickToggleAnonymous = () => {
      onAnonymousToggle(!anonymousQuestionnaire);
      setAnonymousQuestionnaire(!anonymousQuestionnaire);
    };

    const clickToggleMultipleResponse = () => {
      onMultipleResponseToggle(!multipleResponseQuestionnaire);
      setMultipleResponseQuestionnaire(!multipleResponseQuestionnaire);
    };

    return (
      <>
      <Card>
        <CardHeader
          className={classes.cardHeader}
          title={questionnaireData.name || 'Questionnaire details'}
          subheader={ questionnaireStatus === 'closed' ?  'Closed (not accepting responses)'  : 'Open (accepting responses)'}
          action={
            <>
              <IconButton
                onClick={({ currentTarget }) => setMenuAnchorEl(currentTarget)}
              >
                <MoreVertIcon />
              </IconButton>
            </>
          }
        />
        <CardContent>
          <Grid container alignItems="flex-start" spacing={4}>
            <Grid item xs={12} sm={8} md={9} className={classes.primaryColumn}>
                <Typography
                  className={classes.questionnaireDescription}
                >
                  {questionnaireData.description}
                </Typography>
                { questionnaireStatus === 'open' ?
                <>
                  <Grid>
                    <Tooltip
                        placement="bottom-start"
                        title="This will allow respondents to remain anonymous if they so choose. You will still be able to see who has responded to the questionnaire. If you change this later, any anonymous responses will still be anonymous."
                      >
                        <FormControlLabel
                          className={classes.switchField}
                          control={
                            <Switch
                              checked={anonymousQuestionnaire}
                              onChange={clickToggleAnonymous}
                              name="Allow Anonymous"
                              color="primary"
                              disabled={readOnly}
                            />
                          }
                          label={<Typography variant="body2" color="textSecondary">Allow Anonymous Responses</Typography>}
                        />
                    </Tooltip>
                  </Grid>
                  <Grid>
                    <Tooltip
                        placement="bottom-start"
                        title="This will allow a single stakeholder to respond more than once to the questionnaire. If you do not allow multiple responses, only the most recent response for each stakeholder will be visible and included in any results."
                      >
                        <FormControlLabel
                          className={classes.switchField}
                          control={
                            <Switch
                              checked={multipleResponseQuestionnaire}
                              onChange={clickToggleMultipleResponse}
                              name="Allow Multiple Responses"
                              color="primary"
                              disabled={readOnly}
                            />
                          }
                          label={<Typography variant="body2" color="textSecondary">Allow Multiple Responses</Typography>}
                        />
                    </Tooltip>
                  </Grid>
                  Link to Questionnaire:
                  <Button
                    color="primary"
                    size="small"
                    onClick={() => setToShowLinkDialog(true)}
                    // className={classes.questionnaireDescription}
                  >
                    Get Link
                  </Button>
                </>
                :
                  null
                }
              </Grid>
            {/* Secondary column */}
            <Grid
                item
                xs={12}
                sm={4}
                md={3}
                className={classes.secondaryColumn}
              >
              <SecondaryField
                title="Number of Responses:"
                value={questionnaireData.outboundQuestionnaire[0] ? questionnaireData.outboundQuestionnaire[0]?.allowMultipleResponses ? questionnaireData.multipleResponseCount : questionnaireData.singleResponseCount : '0'}
              />
              <Grid
                item
                xs={12}
                className={classes.viewButton}
              >
                <Button
                  color="primary"
                  size="small"
                  onClick={handleClickAllResponseView}
                  disabled={!auth.isGranted({permission:'outbound_questionnaire:responses'})}
                  // className={classes.questionnaireDescription}
                >
                  View Responses
                </Button>
               </Grid>
                { questionnaireStatus === 'open' ?
                <>
                  <SecondaryField
                      title="Date opened:"
                      value={questionnaireData.outboundQuestionnaire[0]?.openedAt?.toString() || 'N/A'}
                  />
                  <Grid container justify="space-between">
                  <Grid item>Close date:</Grid>
                  <Grid item>
                      <>
                        <Tooltip title="Change the date the questionnaire will automatically close">
                          <Link
                            component="button"
                            onClick={() => setToShowCloseDatePickerDialog(true)}
                            className={readOnly ? classes.disabledButtonLink : classes.buttonLink}
                            disabled={readOnly}
                          >
                            <CalendarIcon
                              className={classes.prependSmallIcon}
                            />
                            {questionnaireData.outboundQuestionnaire[0]?.closeDate ? Helpers.formatDate(questionnaireData.outboundQuestionnaire[0].closeDate) : 'Set Close Date'}
                          </Link>
                        </Tooltip>
                        <DatePicker
                          className={classes.hidden}
                          disablePast
                          onAccept={handleCloseDateChange}
                          onChange={(date) => setDisplayedCloseDate(date)}
                          onClose={() => setToShowCloseDatePickerDialog(false)}
                          open={toShowCloseDatePickerDialog}
                          placeholder="YYYY-MM-DD"
                          value={displayedCloseDate}
                        />
                      </>
                  </Grid>
                </Grid>
                </>
                :
                <SecondaryField
                  title="Date Closed:"
                  value={questionnaireData.outboundQuestionnaire[0]?.closedAt?.toString() || 'N/A'}
                />
              }
                <SecondaryField
                  title="Current Version:"
                  value={questionnaireData.version || 'N/A'}
                />
              </Grid>
              <Grid item xs={12} sm={8} md={9} className={classes.primaryColumn}>
              <Menu
                anchorEl={menuAnchorEl}
                keepMounted
                open={Boolean(menuAnchorEl)}
                onClose={handleCloseMenu}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <MenuItem onClick={handleClickAllResponseView} disabled={!auth.isGranted({permission: 'outbound_questionnaire:responses' })}>
                  <span>View All Responses</span>
                </MenuItem>
                <MenuItem disabled={true} onClick={handleClickMenuItem(() => setToShowEditDialog(true))}>
                  <span>View Summary Statistics (Coming Soon)</span>
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </CardContent>
        {isDialog && <Divider />}
        <CardActions
          // className={classnames(isDialog && classes.dialogCardActions)}
        >
          {questionnaireStatus === 'closed' ? (
            <Button color="primary" size="small" onClick={markOpen} disabled={readOnly}>
              Open Questionnaire
            </Button>
          ) : (
            <Button
              color="primary"
              size="small"
              onClick={markClose}
              disabled={readOnly}
            >
              Close Questionnaire
            </Button>
          )}
        </CardActions>
      </Card>
      <OutboundQuestionnaireLinkDialog
        open={toShowLinkDialog}
        onClose={() => setToShowLinkDialog(false)}
        title={`Share Questionnaire ${questionnaireData.name}`}
        token={questionnaireData.outboundQuestionnaire[0]?.token}
        status={questionnaireStatus}
      />
      </>
    );
  }

  export default OutboundQuestionnaireDetails;
