import { createStyles, makeStyles, Theme, Typography} from '@material-ui/core';
import React, {useState} from 'react';
import { CaptionedAddButton } from './buttons';
import { LimitReachedDialog } from '../components/dialogs';

const useStyles = makeStyles((theme: Theme) => createStyles({
  titleContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  titleText: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

export interface DataTableTitleWithButtonProps {
  buttonTitle?: string;
  onButtonClick: () => void;
  title: string;
  limitReached: boolean;
  disabled?: boolean;
}

export default function DataTableTitleWithButton(props: DataTableTitleWithButtonProps) {
  const classes = useStyles();
  const { buttonTitle = 'New Entry', onButtonClick, title, limitReached, disabled = false } = props;
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpen = () => {
    setIsDialogOpen(true);
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  return (
    <div className={classes.titleContainer}>
      <Typography
        variant="h6"
        className={classes.titleText}
      >
        { title }
      </Typography>
      {(limitReached) ? (
        <>
      <CaptionedAddButton
        onClick={handleOpen}
        disabled={true}
      >
        New Entry
      </CaptionedAddButton>
      <LimitReachedDialog
        open={isDialogOpen}
        onClose={handleClose}
        text={'You have reached the maximum entries for the ' + title + ' tool based on your subscription plan. To add more entries, either archive existing items or upgrade your subscription.'}
      >
      </LimitReachedDialog>
      </>
      ) : (
        <CaptionedAddButton
          onClick={onButtonClick}
          disabled={disabled}
        >
          { buttonTitle }
        </CaptionedAddButton>
      )}
    </div>
  );
}
